import ContactActions from '@/data/DataServer/Contact';
import Auth from '@common/AuthManager/Auth.renderer';
import { chats } from '@digital-sun-solutions/cloud-functions';
import { LinkedInChat } from './LinkedInChat';
import { getSelf } from '@/data/LinkedIn/Account';
import { SSCChat } from './SSCChat';
import WebViewLinkedIn from '@common/Webview.renderer/WebviewLinkedIn';
import TemplateActions from '@/data/DataServer/Templates';

export default class ChatBuilder {
  static async getChat(profileID: string, conversationID?: string | null) {
    const self = await getSelf(true, true);
    const contact = await ContactActions.getContact(profileID);
    if (!contact) {
      if (!conversationID) {
        conversationID = (await WebViewLinkedIn.getFullProfile(profileID))
          ?.conversationID;
      }
      return new LinkedInChat({
        conversationID,
        ownPublicIdentifier: self.publicIdentifier
      });
    } else {
      const conversationID = contact.conversationID;
      if (!conversationID) return null;
      const res = await Auth.execRoute((token) =>
        chats.get({ conversationID }, { token })
      );
      if (res.code !== 200) return null;
      const templates = await TemplateActions.getTemplatesOfPipelineStep(
        contact.getActiveStep()?.id
      );
      return new SSCChat({
        conversationID,
        ownPublicIdentifier: self.publicIdentifier,
        contact,
        template: templates?.[0].templates[0]
      });
    }
  }
}
