import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Popover,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { language } from '@/index';
import ContactActions from '@/data/DataServer/Contact';
import { HexColorPicker } from 'react-colorful';
import { ArrowBack, ColorLens } from '@mui/icons-material';
import MainConfig from '@common/config/MainConfig';
import TagChip from '@/components/Contact/TagChip';
import { Colors } from '@idot-digital/generic-helpers';
import { FancyButton, Loading, OptionalTooltip } from 'ui-utils';
import posthog from 'posthog-js';

const MAX_TAG_NAME_LENGTH = 30;

export default function TagSettings() {
  return (
    <Box p={2}>
      <Typography variant="h4" mb={2}>
        {language.text.edit_tags}
      </Typography>
      <TagsInner />
    </Box>
  );
}

function TagsInner() {
  const { tags, isLoading, error } = ContactActions.useTags();

  const getDefaultColor = (omitColor?: string) =>
    MainConfig.tagColors.find(
      (color) =>
        omitColor !== color && !tags?.some((tag) => tag.color === color)
    ) ?? MainConfig.tagColors[0];

  const [name, setName] = React.useState('');
  const [color, setColor] = React.useState('');

  React.useEffect(() => {
    if (!color && tags) setColor(getDefaultColor());
  }, [tags]);

  const colorAnchor = React.useRef<HTMLButtonElement>(null);
  const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
  const [colorPickerPage, setColorPickerPage] = React.useState<
    'default' | 'custom'
  >('default');

  const createTagRef = React.useRef<HTMLButtonElement>(null);

  const theme = useTheme();
  const contrastColor = Colors.getContrastColor(color, {
    dark: theme.palette.text.primary,
    light: '#fff',
    background: theme.palette.background.default
  });

  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
  const [tagNameToDelete, setConfirmDeleteTag] = React.useState('');

  if (error)
    return <Typography color="error">{language.text.generic_error}</Typography>;

  if (isLoading) return <Loading />;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box>
        <Typography mb={2}>{language.text.add_tag}</Typography>
        <Box display="flex" gap={2} alignItems="flex-end">
          <TextField
            autoFocus
            variant="standard"
            value={name}
            onChange={(e) =>
              setName(e.target.value.slice(0, MAX_TAG_NAME_LENGTH))
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter') createTagRef.current?.click();
            }}
            label={language.text.tag_name}
            sx={{
              width: 350
            }}
            InputProps={{
              endAdornment: (
                <Typography variant="caption" sx={{ width: '2rem' }}>
                  {name.length}/{MAX_TAG_NAME_LENGTH}
                </Typography>
              )
            }}
          />
          <Box
            ref={colorAnchor}
            component="button"
            sx={{
              'height': 36,
              'width': 36,
              'backgroundColor': color,
              'borderRadius': '50%',
              'outline': 'none',
              'border': 'none',
              'cursor': 'pointer',
              'boxShadow': 1,
              '&:hover': {
                filter: 'brightness(0.9)'
              },
              'position': 'relative'
            }}
            onClick={() => setColorPickerOpen(true)}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: contrastColor,
                borderRadius: '50%',
                height: 12,
                width: 12
              }}
            />
          </Box>
          <Popover
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            anchorEl={colorAnchor.current}
            open={colorPickerOpen}
            onClose={() => setColorPickerOpen(false)}>
            {colorPickerPage === 'default' ? (
              <Box
                p={2}
                display="grid"
                gridTemplateColumns="repeat(8, 2rem)"
                gap={1}>
                {MainConfig.tagColors.map((color) => (
                  <Box
                    key={color}
                    component="button"
                    sx={{
                      'height': 36,
                      'width': 36,
                      'backgroundColor': color,
                      'borderRadius': '50%',
                      'outline': 'none',
                      'border': 'none',
                      'boxShadow': 1,
                      'cursor': 'pointer',
                      '&:hover': {
                        filter: 'brightness(0.9)'
                      }
                    }}
                    onClick={() => {
                      setColor(color);
                      setColorPickerOpen(false);
                    }}
                  />
                ))}
                <IconButton
                  onClick={() => setColorPickerPage('custom')}
                  sx={{
                    height: 36,
                    width: 36,
                    border: (theme) =>
                      `${theme.spacing(0.5)} solid ${
                        MainConfig.tagColors.includes(color)
                          ? 'transparent'
                          : color
                      }`
                  }}>
                  <ColorLens />
                </IconButton>
              </Box>
            ) : (
              <>
                <IconButton onClick={() => setColorPickerPage('default')}>
                  <ArrowBack />
                </IconButton>
                <HexColorPicker
                  color={color}
                  onChange={(color) => setColor(color)}
                />
              </>
            )}
          </Popover>
          <OptionalTooltip
            title={
              tags.some((tag) => tag.name === name)
                ? language.text.tag_name_already_taken
                : undefined
            }>
            <span>
              <FancyButton
                ref={createTagRef}
                fType={{ promise: true }}
                variant="contained"
                color="primary"
                disabled={
                  !name || !color || tags.some((tag) => tag.name === name)
                }
                onClick={async () => {
                  posthog.capture('tag-created', { name });
                  await ContactActions.createTag({ name, color });
                  setName('');
                  setColor((color) => getDefaultColor(color));
                }}>
                {language.text.add_tag}
              </FancyButton>
            </span>
          </OptionalTooltip>
        </Box>
      </Box>
      <Divider orientation="horizontal" />
      <Box display="flex" flexWrap="wrap" gap={2}>
        {tags.map((tag) => (
          <TagChip
            key={tag.name}
            tag={tag}
            onDelete={() => {
              posthog.capture('tag-deleted', { name: tag.name });
              setConfirmDeleteTag(tag.name);
              setConfirmDeleteOpen(true);
            }}
          />
        ))}
      </Box>
      {tags.length === 0 && (
        <Typography fontStyle="italic">{language.text.no_tags}</Typography>
      )}
      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}>
        <DialogTitle>{language.text.delete_tag}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {language.text.delete_tag_confirm}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDeleteOpen(false)}
            variant="contained"
            color="neutral">
            {language.text.cancel}
          </Button>
          <FancyButton
            fType={{ promise: true }}
            variant="contained"
            color="error"
            onClick={async () => {
              await ContactActions.deleteTag(tagNameToDelete);
              setConfirmDeleteOpen(false);
            }}>
            {language.text.delete}
          </FancyButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
