import { Button, Typography } from '@mui/material';
import { Category } from '@mui/icons-material';
import { language } from '@/index';
import { ContactType } from '@common/types/enums';

export interface ChatContactRecategorizeButtonProps {
  type?: ContactType;
  onClick: () => void;
}

export default function ChatContactRecategorizeButton(
  props: ChatContactRecategorizeButtonProps
) {
  return (
    <Button
      startIcon={<Category />}
      color={
        !props.type
          ? 'primary'
          : props.type === ContactType.AUDIENCE_HOLDER
            ? 'secondary'
            : props.type === ContactType.PERSONAL
              ? 'tertiary'
              : props.type === ContactType.CUSTOMER
                ? 'success'
                : props.type === ContactType.NO_MATCH
                  ? 'error'
                  : props.type === ContactType.POTENTIAL_CUSTOMER
                    ? 'primary'
                    : props.type === ContactType.UNCATEGORIZED
                      ? 'neutral'
                      : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- have error case when no literals match
                        props.type === ContactType.MARKED
                        ? 'neutral'
                        : 'primary'
      }
      variant="contained"
      fullWidth
      onClick={() => props.onClick()}>
      <span style={{ lineHeight: 1.2 }}>
        <span>{language.text.recategorize}</span>
        <br />
        <Typography variant="caption" color="inherit">
          {!props.type
            ? language.text.loading
            : props.type === ContactType.AUDIENCE_HOLDER
              ? language.text.audience_holder
              : props.type === ContactType.PERSONAL
                ? language.text.personal_contact
                : props.type === ContactType.CUSTOMER
                  ? language.text.customer
                  : props.type === ContactType.NO_MATCH
                    ? language.text.no_match
                    : props.type === ContactType.POTENTIAL_CUSTOMER
                      ? language.text.potential_customer
                      : props.type === ContactType.UNCATEGORIZED
                        ? language.text.uncategorized
                        : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- have error case when no literals match
                          props.type === ContactType.MARKED
                          ? language.text.marked
                          : language.text.error}
        </Typography>
      </span>
    </Button>
  );
}
