import updateLocale from 'dayjs/plugin/updateLocale';
import posthog from 'posthog-js';
import dayjs from 'dayjs';
import de from 'dayjs/locale/de';
import * as Sentry from '@sentry/electron/renderer';
import log from 'electron-log';
import { GenericComponents } from 'ui-utils';
import MessageBus from '@common/MessageBus/MessageBus.renderer';
import LanguageManager from '@common/LanguageManager/LanguageManager';
import { LinkedInWebviewElement } from '@common/Webview.renderer/Base/ContentScriptTypes';
import MainConfig from '@common/config/MainConfig';
import { genUploader } from 'uploadthing/client';
import { FileRouter } from 'uploadthing/types';
import tracking from 'tracking';
import Auth from '@common/AuthManager/Auth.renderer';

function init() {
  const { uploadFiles } = genUploader<FileRouter>({
    package: 'social-selling-cockpit-client',
    url: 'https://api.social-selling-cockpit.co' + '/api/uploadthing'
  });

  // To upload files to the server
  // const response = await uploadFiles('imageUploader', {
  //   files: [f]
  // });
  // -> URL for the file f is returned in response[0].url

  Sentry.init({
    ...MainConfig.logging.sentry,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkCaptureBodies: true,
        networkDetailAllowUrls: ['https://api.social-selling-cockpit.co']
      })
    ],
    enabled: !window.electron.isDev
  });

  //Initialize Posthog for analytics
  posthog.init(MainConfig.logging.posthog.token, {
    api_host: MainConfig.logging.posthog.apiHost,
    autocapture: false,
    capture_pageview: false,
    capture_pageleave: false,
    persistence: 'localStorage+cookie'
  });

  // when CTRL+clicking on a link (opening in new tab) the action will be prevented by the main thred and a message sent to open the link in the webview itself
  // https://stackoverflow.com/a/74985512
  // open external url in webview
  window.api.handle('webview:openURL', (_, data) => {
    const webview = document.querySelector<LinkedInWebviewElement>('webview');
    if (webview) webview.src = data;
  });

  // log all uncaught errors
  window.onerror = (message, source, lineno, colno, error): void => {
    log.error(
      `Error in Renderer: ${typeof message === 'string' ? message : '[EVENT]'} at ${source ?? '[UNKNOWN SOURCE]'}:${lineno ?? '[UNKNOWN LINE]'}:${colno ?? '[UNKNOWN COL]'}, name: ${error?.name ?? '[UNDEFINED ERROR]'}, cause: ${(error?.cause as string | undefined) ?? '[UNDEFINED ERROR]'}, message: ${error?.message ?? '[UNDEFINED ERROR]'}, stack: ${error?.stack ?? '[UNDEFINED ERROR]'}`
    );
  };

  // make week start on monday
  dayjs.extend(updateLocale);
  dayjs.updateLocale('en', {
    weekStart: 1
  });
  dayjs.updateLocale('de', {
    weekStart: 1
  });
  dayjs.locale(de);

  const language = LanguageManager.getInstance();
  GenericComponents.setLanguageManager(language);
  // TODO: fix types
  //@ts-expect-error -- Types should be correct
  GenericComponents.setMessageBus(MessageBus.getInstance());

  Auth.on('auth-renderer:new-session-token', ({ sessionToken, userID }) => {
    tracking.init(sessionToken);
    Sentry.setTag('usedID', userID);
  });

  return { language, uploadFiles };
}

export default init;
