import HUD from '@/components/HUD/HUD';
import { language } from '@/index';
import { APP_PATHS } from '@/globals/Paths';
import LinkedInWebview from '@common/Webview.renderer/Base/LinkedInWebview';
import LinkedInActions from '@common/Webview.renderer/LinkedInActions';
import { CheckCircle, Clear, ErrorOutline } from '@mui/icons-material';
import { Box, Button, IconButton, Popover, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ProgressBar } from 'ui-utils';
import * as Sentry from '@sentry/electron/renderer';
import { LinkedInWebviewElement } from '@common/Webview.renderer/Base/ContentScriptTypes';
import { setAdvancedLoadActive } from '@common/LinkedInEventHandlers/handleRequestInterceptorData.renderer';
import { CSAction, CSActionOptions } from 'webview-preload';

export interface LinkedInLoadProps {
  onCompleted?: () => void;
  actions: {
    action: CSAction;
    actionData?: CSActionOptions['data'];
    /**
     * Progress to be set after the action is finished
     */
    progressAfterFinished?: number;
    /**
     * handle how the progress changes during this action
     * @param currentProgress current progress
     * @param info info from content script, sent via `load:info`
     * @returns the new progress
     */
    infoHandler?: (currentProgress: number, info: string) => number;
    /**
     * Callback to be executed after the action is finished
     */
    onCompleted?: (currentProgress: number) => void;
    /**
     * Behavior when an error occurs during this action
     */
    errorBehavior?: 'continue' | 'finish' | 'display_error';
  }[];
  texts: {
    title: string; // language.text.loading_posts
    confirm_leave: string; // language.text.confirm_leave_load_posts
    finished: string; // language.text.post_loading_finished
    error_title: string; // error_while_loading_posts
    error_description: string; // error_while_loading_posts_description
  };
  hideProgress?: boolean;
  startURL?: string;
}

export default function LinkedInLoad(props: LinkedInLoadProps) {
  const navigate = useNavigate();
  // extra precautions to prevent executing the action multiple times
  const actionStarted = React.useRef(false);
  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const leaveButtonRef = React.useRef<HTMLButtonElement | null>(null);
  const [leaveConfirmOpen, setLeaveConfirmOpen] = React.useState(false);

  const [progress, setProgress] = React.useState(0);
  const lastProgress = React.useRef(0);

  const [finished, setFinished] = React.useState(false);
  const [error, setError] = React.useState(false);

  const [webview, setWebview] = React.useState<
    LinkedInWebviewElement | undefined
  >(undefined);

  const startAction = React.useCallback(
    async (newWebview?: LinkedInWebviewElement) => {
      if (actionStarted.current) return;
      actionStarted.current = true;
      if (!newWebview) newWebview = webview;
      if (!newWebview) return;
      for (const {
        action,
        actionData,
        progressAfterFinished,
        infoHandler,
        onCompleted,
        errorBehavior
      } of props.actions) {
        try {
          console.debug(`Starting action '${action}'`);
          await LinkedInActions.executeAction(
            newWebview,
            action,
            actionData,
            (info) => {
              if (!info || !mounted.current) return;
              if (infoHandler)
                setProgress((current) => {
                  lastProgress.current = current;
                  return infoHandler(current, info);
                });
            }
          );
          console.debug(`Finished action '${action}'`);

          if (!mounted.current) return;

          setProgress((current) => {
            const newProgress = progressAfterFinished ?? current;
            onCompleted?.(newProgress);
            return newProgress;
          });
        } catch (err) {
          if (!mounted.current) return;
          Sentry.captureException(err);
          if (errorBehavior === 'finish') {
            props.onCompleted?.();
            setFinished(true);
            setProgress(1);
            return;
          } else if (errorBehavior === 'display_error') {
            setError(true);
            return;
          }
        }
      }
      if (mounted.current) {
        props.onCompleted?.();
        setFinished(true);
        setProgress(1);
      }
    },
    [webview]
  );

  // tell request interceptor to commit saveEvent data
  React.useEffect(() => {
    setAdvancedLoadActive(true);
    return () => {
      setAdvancedLoadActive(false);
    };
  }, []);

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        boxSizing: 'border-box',
        pt: 16,
        pb: 6
      }}>
      <HUD
        topContent={
          <Box
            display="grid"
            gridTemplateColumns="1fr auto 1fr"
            alignItems="center"
            justifyItems="flex-end"
            height="100%"
            width="100%">
            <Box />
            <Typography
              variant="h4"
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                color: (theme) => theme.palette.secondary.main
              }}>
              {props.texts.title}
            </Typography>
            <IconButton
              ref={leaveButtonRef}
              size="large"
              onClick={() => {
                if (finished) navigate(`/${APP_PATHS.Manual}`);
                else setLeaveConfirmOpen(true);
              }}>
              <Clear color="secondary" />
            </IconButton>
            <Popover
              open={leaveConfirmOpen}
              onClose={() => setLeaveConfirmOpen(false)}
              anchorEl={leaveButtonRef.current}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}>
              <Box p={2}>
                <Typography maxWidth="500px" mb={1}>
                  {props.texts.confirm_leave}
                </Typography>
                <Box
                  display="grid"
                  gridTemplateColumns="1fr 1fr"
                  gap={1}
                  justifyContent="end">
                  <Button
                    onClick={() => setLeaveConfirmOpen(false)}
                    variant="contained"
                    color="neutral">
                    {language.text.cancel}
                  </Button>
                  <Button
                    onClick={() => navigate(`/${APP_PATHS.Manual}`)}
                    variant="contained"
                    color="primary">
                    {language.text.back_to_manual_mode}
                  </Button>
                </Box>
              </Box>
            </Popover>
          </Box>
        }
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          px: 6,
          py: 2,
          boxSizing: 'border-box',
          height: '100%',
          width: '100%',
          maxWidth: '80vw',
          m: 'auto'
        }}>
        <Box flexGrow={1} width="100%">
          <Box
            sx={{
              borderWidth: 4,
              borderColor: 'text.secondary',
              borderStyle: 'solid',
              borderRadius: 2,
              width: '100%',
              height: '100%',
              boxShadow: 7,
              overflow: 'hidden',
              position: 'relative'
            }}>
            <LinkedInWebview
              startURL={props.startURL}
              onWebviewReady={(webview) => {
                setWebview(webview);
                startAction(webview);
              }}
              hideReloadButton
              hideHomeButton
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                bgcolor: 'rgba(255, 255, 255, 0.4)'
              }}
            />
            {(finished || error) && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 2,
                  p: 2,
                  boxSizing: 'border-box',
                  background: (theme) => theme.palette.background.paper,
                  zIndex: 10
                }}>
                {finished && (
                  <>
                    <CheckCircle color="primary" sx={{ fontSize: '4rem' }} />
                    <Typography variant="h4">{props.texts.finished}</Typography>
                    <Button
                      size="large"
                      onClick={() => navigate(`/${APP_PATHS.Manual}`)}
                      variant="contained"
                      color="primary">
                      <Typography>
                        {language.text.back_to_manual_mode}
                      </Typography>
                    </Button>
                  </>
                )}
                {error && (
                  <>
                    <Typography variant="h4">
                      <ErrorOutline
                        color="error"
                        sx={{ mr: 1, verticalAlign: 'middle' }}
                      />
                      {props.texts.error_title}
                    </Typography>
                    <Typography maxWidth="700px">
                      {props.texts.error_description}
                    </Typography>
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gap: 2
                      }}>
                      <Button
                        size="large"
                        onClick={() => navigate(`/${APP_PATHS.Manual}`)}
                        variant="contained"
                        color="primary">
                        <Typography>
                          {language.text.back_to_manual_mode}
                        </Typography>
                      </Button>
                      <Button
                        size="large"
                        onClick={() => {
                          actionStarted.current = false;
                          setError(false);
                          lastProgress.current = 0;
                          setProgress(0);
                          setFinished(false);
                          startAction();
                        }}
                        variant="contained"
                        color="primary">
                        <Typography>{language.text.try_again}</Typography>
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            )}
          </Box>
        </Box>
        {!props.hideProgress && (
          <ProgressBar
            getLabel={() => ''}
            to={progress}
            from={lastProgress.current}
            speedModifier={2}
            colors={{
              bar: 'primary.light',
              background: 'background.paper',
              label: 'primary.contrastText'
            }}
            sx={{
              borderRadius: 2,
              boxShadow: 7
            }}
          />
        )}
      </Box>
    </Box>
  );
}
