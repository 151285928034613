import React from 'react';
import PromiseButton from '../PromiseButton/PromiseButton';
import ProgressButton, { ProgressButtonProps } from './ProgressButton';

export type ProgressPromiseButtonProps = ProgressButtonProps<
  typeof PromiseButton
>;

const ProgressPromiseButton = React.forwardRef(
  (props: ProgressPromiseButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    return <ProgressButton component={PromiseButton} {...props} ref={ref} />;
  }
);

export default ProgressPromiseButton;
