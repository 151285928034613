import {
  CreatedBeforeIterator,
  CreatedBeforeResponse
} from './CreatedBeforeIterator';
import { ConversationId, LinkedInEvent } from 'linkedin-domain-types';
import { Options, trace } from '../client/Utils';

type FetchMessages = ({
  conversationId,
  createdBefore,
  options
}: {
  conversationId: ConversationId;
  createdBefore: Date;
  options: Options;
}) => Promise<Iterable<LinkedInEvent>>;

/**
 * Iterates over all messages in a conversation returning them as single entities
 */
@trace()
export class MessageIterator implements AsyncIterator<LinkedInEvent> {
  /**
   * The iterator that fetches pages of messages
   * @private the iterator that fetches pages of messages
   */
  private pageIterator: MessagePageIterator;

  /**
   * The pages of messages that have been fetched
   * @private the pages of messages that have been fetched
   */
  private page: LinkedInEvent[] = [];

  /**
   * The index of the current message in the pages array
   * @private the index of the current message in the pages array
   */
  private index = 0;

  /**
   * Creates a new MessageIterator
   * @param params the parameters
   */
  constructor(params: {
    fetchMessages: FetchMessages;
    conversationId: ConversationId;
    createdBefore?: Date;
    options: Options;
  }) {
    this.pageIterator = new MessagePageIterator(params);
  }

  /**
   * Returns the next message and advances the iterator
   * @returns the next message
   */
  async next(): Promise<IteratorResult<LinkedInEvent, LinkedInEvent>> {
    if (this.index >= this.page.length) {
      const { value, done } = await this.pageIterator.next();
      if (done) {
        return { done, value: {} as LinkedInEvent };
      }
      this.page.push(...value);
    }

    return { value: this.page[this.index++], done: false };
  }

  /**
   * Returns the previous message and moves the iterator back
   * @returns the previous message
   */
  async prev(): Promise<IteratorResult<LinkedInEvent, LinkedInEvent>> {
    if (this.index <= 0) {
      const { value, done } = await this.pageIterator.prev();
      if (done) {
        return { done, value: {} as LinkedInEvent };
      }
      this.page.unshift(...value);
    }

    return { value: this.page[--this.index], done: false };
  }

  /**
   * Restarts the iterator
   */
  restart(): void {
    this.pageIterator.restart();
    this.page = [];
    this.index = 0;
  }
}

@trace()
class MessagePageIterator extends CreatedBeforeIterator<LinkedInEvent> {
  private readonly fetchMessages: FetchMessages;

  private readonly conversationId: ConversationId;

  protected fieldName = 'createdAt' as const;

  private readonly options: Options;

  constructor({
    fetchMessages,
    conversationId,
    createdBefore,
    options
  }: {
    fetchMessages: FetchMessages;
    conversationId: ConversationId;
    createdBefore?: Date;
    options: Options;
  }) {
    super({ createdBefore });

    this.fetchMessages = fetchMessages;
    this.options = options;
    this.conversationId = conversationId;
  }

  protected async fetch(): Promise<
    Iterable<LinkedInEvent & CreatedBeforeResponse>
  > {
    return this.fetchMessages({
      conversationId: this.conversationId,
      createdBefore: this.createdBefore,
      options: this.options
    });
  }
}
