import { Objects } from '@idot-digital/generic-helpers';
import { CS_LANGUAGE_KEYS, LanguageObject, Languages } from './LanguageTypes';
import lang_de from '@common/config/Language/de';
import lang_en from '@common/config/Language/en';
import { CSLanguageObject } from 'webview-preload';

const languages = {
  de: lang_de,
  en: lang_en
} satisfies { [key in Languages]: LanguageObject };

export default class LanguageManager {
  private static instance: LanguageManager;

  public static getInstance(): LanguageManager {
    if (!LanguageManager.instance)
      LanguageManager.instance = new LanguageManager();

    return LanguageManager.instance;
  }

  private language: Languages = 'de';

  public setLanguage(language: Languages): void {
    this.language = language;
  }

  public getLanguage(): Languages {
    return this.language;
  }

  public getAvailableLanguages(): Languages[] {
    return Objects.keys(languages);
  }

  public getLanguageString(language?: Languages): string {
    if (!language) language = this.language;
    switch (language) {
      case 'de':
        return 'Deutsch';
      case 'en':
        return 'English';
    }
    return 'Unknown language';
  }

  public get text(): LanguageObject {
    return languages[this.language];
  }

  public getCSText(): CSLanguageObject {
    return Objects.pick(this.text, CS_LANGUAGE_KEYS);
  }

  public static isValidLanguage(language: string): language is Languages {
    return Object.keys(languages).includes(language);
  }
}
