import React from 'react';
import ContactFilter from '@/components/Contact/ContactFilter';
import FilterIcon from '@/components/Contact/FilterIcon';
import type { ChatFilter } from '@/data/DataServer/Chat';
import { language } from '@/index';
import { Objects } from '@idot-digital/generic-helpers';
import { Check, Clear, Delete, MarkAsUnread } from '@mui/icons-material';
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography
} from '@mui/material';

export interface ChatFilterProps {
  filter: ChatFilter;
  onChange: (filter: ChatFilter) => void;
  disabled?: boolean;
}

export default function ChatFilter(props: ChatFilterProps) {
  return (
    <ContactFilter
      filter={props.filter}
      onChange={(filter) => props.onChange(mergeFilter(props.filter, filter))}
      hide={['unanswered']}
      localStorageKey="chatListFilter"
      disabled={props.disabled}>
      <FilterIcon
        disabled={props.disabled}
        icon={<MarkAsUnread />}
        active={
          props.filter.values?.unread !== undefined ||
          props.filter.values?.unanswered !== undefined
        }>
        {(['unread', 'unanswered'] as const).flatMap((type, typeIndex, types) =>
          [undefined, true, false].map((value, valIndex, values) => (
            <MenuItemContent
              // last of each value but not very last type
              divider={
                valIndex === values.length - 1 && typeIndex !== types.length - 1
              }
              key={`${type}|${value ?? 'undefined'}`}
              type={type}
              menuValue={value}
              text={
                value === undefined
                  ? language.text.no_filter
                  : language.text[`${value ? 'no_' : ''}${type}_messages`]
              }
              filter={props.filter}
              onChange={props.onChange}
            />
          ))
        )}
      </FilterIcon>
      <FilterIcon icon={<Clear />} closeOnSelect disabled={props.disabled}>
        <Box p={2}>
          <Typography>{language.text.confirm_clear_filter}</Typography>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            width="100%"
            mt={1}>
            <Button
              onClick={() =>
                props.onChange({
                  ...props.filter,
                  values: undefined,
                  dates: undefined,
                  has: undefined,
                  tags: undefined
                })
              }
              variant="outlined"
              color="error"
              startIcon={<Delete />}>
              {language.text.clear_filter}
            </Button>
          </Box>
        </Box>
      </FilterIcon>
    </ContactFilter>
  );
}

function mergeFilter(filter: ChatFilter, newFilter: ChatFilter): ChatFilter {
  return {
    ...filter,
    ...newFilter,
    ...((filter.dates ?? newFilter.dates) && {
      dates: {
        ...((filter.dates?.connection ?? newFilter.dates?.connection) && {
          connection: {
            ...filter.dates?.connection,
            ...newFilter.dates?.connection
          }
        })
      }
    }),
    ...((filter.has ?? newFilter.has) && {
      has: {
        ...filter.has,
        ...newFilter.has
      }
    }),
    ...((filter.tags ?? newFilter.tags) && {
      tags: {
        ...filter.tags,
        ...newFilter.tags
      }
    }),
    ...((filter.values ?? newFilter.values) && {
      values: {
        ...filter.values,
        ...newFilter.values
      }
    })
  };
}

function MenuItemContent(props: {
  filter: ChatFilter;
  type: 'unread' | 'unanswered';
  menuValue: true | false | undefined;
  text: string;
  onChange: (filter: ChatFilter) => void;
  divider?: boolean;
}) {
  const selected = props.filter.values?.[props.type] === props.menuValue;
  return (
    <MenuItem
      value={`${props.type}|${props.menuValue ?? 'undefined'}`}
      divider={props.divider}
      onClick={() => {
        const newValue =
          props.filter.values?.[props.type] === props.menuValue
            ? undefined
            : props.menuValue;
        if (newValue !== undefined)
          props.onChange({
            ...props.filter,
            values: {
              ...props.filter.values,
              [props.type]: newValue
            }
          });
        else
          props.onChange({
            ...props.filter,
            values: props.filter.values
              ? Objects.deleteKeys(props.filter.values, [props.type])
              : undefined
          });
      }}>
      {selected && (
        <ListItemIcon>
          <Check />
        </ListItemIcon>
      )}
      <ListItemText inset={!selected}>{props.text}</ListItemText>
    </MenuItem>
  );
}
