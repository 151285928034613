import { Arrays as ArraysBase } from '@idot-digital/generic-helpers';

export class Arrays extends ArraysBase {
  static split<T>(array: T[], size: number): T[][] {
    return array.reduce<T[][]>(
      (acc, _, i) => (i % size ? acc : [...acc, array.slice(i, i + size)]),
      []
    );
  }
}
