import { Country } from './CountryCode';

export interface UserSearchFilters {
  currentCompany?: string | string[];
  pastCompany?: string | string[];
  company?: string;
  geoUrn?: string | string[];
  industry?: string | string[];
  network?: LinkedInNetworkType | LinkedInNetworkType[];
  profileLanguage?: Country | Country[];
  school?: string | string[];
  connectionOf?: string;
  contactInterest?: LinkedInContractInterest | LinkedInContractInterest[];
  serviceCategory?: string | string[];
  firstName?: string;
  lastName?: string;
  title?: string;
}

export interface JobSearchFilters {
  companies?: string | string[];
  experience?: LinkedInExperienceType | LinkedInExperienceType[];
  jobType?: LinkedInJobType | LinkedInJobType[];
  jobTitle?: string | string[];
  industries?: string | string[];
  location?: string;
  remote?: boolean;
  listed_at?: number;
  distance?: number;
}

export enum LinkedInExperienceType {
  INTERN = '1',
  ENTRY_LEVEL = '2',
  ASSOCIATE = '3',
  MID_SENIOR_LEVEL = '4',
  DIRECTOR = '5',
  EXECUTIVE = '6'
}

export enum LinkedInJobType {
  FULL_TIME = 'F',
  PART_TIME = 'P',
  CONTRACT = 'C',
  INTERNSHIP = 'I',
  TEMPORARY = 'T',
  VOLUNTEER = 'V',
  OTHER = 'O'
}

export type BlendedSearchFilters =
  | UserSearchFilters
  | { resultType?: SearchType };

export enum SearchType {
  PEOPLE = 'PEOPLE',
  JOBS = 'JOBS',
  COMPANIES = 'COMPANIES'
}

export enum LinkedInNetworkType {
  F = 'F',
  S = 'S',
  O = 'O'
}

export enum LinkedInContractInterest {
  PRO_BONO = 'proBono',
  BOARD_MEMBER = 'boardMember'
}
