import queryClient from '@/other/QueryClient';
import {
  MessageTemplate,
  SetTemplate,
  TemplateData
} from '@common/types/ApiTypes';
import { PartialParameters, createQueryHook } from './QueryHelper';
import PrintableError from '@common/PrintableError/PrintableError';
import Auth from '@common/AuthManager/Auth.renderer';
import { getPredefinedTemplateNames } from '@common/PipelineManager/MessageTemplates.renderer';
import { templates } from '@digital-sun-solutions/cloud-functions';
import { Pipelines } from '@common/PipelineManager/Pipelines';
import { Objects } from '@idot-digital/generic-helpers';
import TEMPLATES from '@common/config/MessageTemplates';
import log from 'electron-log';

/*------------ Server functions --------------*/
async function getAllTemplates(): Promise<TemplateData[]> {
  const res = await Auth.execRoute((token) => templates.getAll({}, { token }));

  if (res.code !== 200) {
    log.error(`Error in listTemplates (${res.code}): ${res.data}`);
    throw new PrintableError("Couldn't get templates due to server error");
  }

  const templateNames: {
    [key: string]: {
      name: string;
      description: string;
    };
  } = getPredefinedTemplateNames();

  const templateIDs = Array.from(
    new Set(Objects.keys(res.data).concat(Objects.keys(TEMPLATES)))
  );

  return templateIDs.map((templateID, i1) => {
    const info = templateNames[templateID];
    const base: (Pick<MessageTemplate, 'name' | 'text'> &
      Partial<MessageTemplate>)[] =
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- res-data[templateID] can be undefined
      res.data[templateID] ??
      TEMPLATES[templateID as keyof typeof TEMPLATES] ??
      [];
    const variants = base.map((variant, i2) => ({
      variantID: -i1 * 10 - i2 - 1,
      templateID,
      ...variant
    }));

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- catch error if it is null
    if (!info)
      return {
        templateID,
        templates: variants,
        name: '[MISSING]',
        description: '[MISSING]',
        responseRate: undefined
      };
    return {
      templateID,
      templates: variants,
      name: info.name,
      description: info.description
    };
  });
}
getAllTemplates.getQueryKey = () => ['templates', 'all'];

async function getTemplatesOfPipelineStep(
  stepID: string | undefined | null
): Promise<TemplateData[] | null> {
  if (!stepID) return null;
  const step = Pipelines.findStep(stepID);
  if (!step || (step.type !== 'message' && step.type !== 'buildRelationship'))
    return null;
  const templateIDs =
    step.type === 'message'
      ? [step.templateID]
      : [step.welcomeMessage, step.relationshipMessage];
  const templates = await getAllTemplates();
  const stepTemplates = templates.filter((t) =>
    templateIDs.includes(t.templateID)
  );

  return stepTemplates;
}
getTemplatesOfPipelineStep.getQueryKey = (
  ...args: PartialParameters<typeof getTemplatesOfPipelineStep>
) => ['templates', 'pipeline', ...(args[0] ? [args[0]] : [])];

async function getBuildRelationTemplates(): Promise<MessageTemplate[]> {
  const pipelineDef = Pipelines.getPipelineDefinition();
  const buildRelationStep = pipelineDef.main_flow.find(
    (s) => s.type === 'buildRelationship'
  );
  if (!buildRelationStep) return [];
  const templateIDs = [
    buildRelationStep.welcomeMessage,
    buildRelationStep.relationshipMessage
  ];

  const templates = await getAllTemplates();
  const stepTemplates = templates.filter((t) =>
    templateIDs.includes(t.templateID)
  );
  return stepTemplates.flatMap((t) => t.templates);
}
getBuildRelationTemplates.getQueryKey = () => ['templates', 'buildRelation'];

async function getTemplate(
  templateID: TemplateData['templateID'] | null | undefined
) {
  if (!templateID) return null;
  const templates = await getAllTemplates();
  const template = templates.find((t) => t.templateID === templateID);
  return template ?? null;
}
getTemplate.getQueryKey = (...args: PartialParameters<typeof getTemplate>) => [
  'templates',
  'single',
  ...(args[0] ? [args[0]] : [])
];

async function invalidateTemplateCache() {
  await queryClient.invalidateQueries('templates', { exact: false });
}

async function setTemplate(
  template: SetTemplate
): Promise<MessageTemplate['variantID']> {
  // name can be maximum 30 characters long
  template.name = template.name.slice(0, 30);

  const res = await Auth.execRoute((token) =>
    templates.setVariant(template, { token })
  );
  if (res.code !== 200) {
    log.error(`Error in setTemplate (${res.code}): ${res.data}`);
    throw new PrintableError('Internal server error while setting template');
  }
  await invalidateTemplateCache();
  return res.data as number;
}

async function deleteTemplate(id: MessageTemplate['variantID']) {
  const res = await Auth.execRoute((token) =>
    templates.deleteVariant(
      {
        variantID: id
      },
      { token }
    )
  );
  if (res.code !== 200) {
    log.error(`Error in deleteTemplate (${res.code}): ${res.data}`);
    throw new PrintableError('Internal server error while deleting template');
  }
  await invalidateTemplateCache();
}

async function getUncategorizedTemplate() {
  const templates = await getAllTemplates();
  return templates.find((t) => t.templateID === 'uncategorized')?.templates;
}
getUncategorizedTemplate.getQueryKey = () => ['templates', 'uncategorized'];

async function getFollowUpTemplates() {
  const templates = await getAllTemplates();
  const template = templates.find(
    (t) => t.templateID === 'follow-up-kennenlernen'
  );
  if (template) return [template];
  return [];
}
getFollowUpTemplates.getQueryKey = () => ['templates', 'follow-up'];

/*------------ React Query hooks --------------*/

const useTemplates = createQueryHook(getAllTemplates, 'templates');

const useBuildRelationTemplates = createQueryHook(
  getBuildRelationTemplates,
  'templates',
  {
    initialData: []
  }
);

const useTemplatesOfPipelineStep = createQueryHook(
  getTemplatesOfPipelineStep,
  'templates'
);

const useTemplate = createQueryHook(getTemplate, 'template', {
  disableWhenUndefined: true
});

const useUncagorizedTemplates = createQueryHook(
  getUncategorizedTemplate,
  'templates'
);

const useFollowUpTemplates = createQueryHook(getFollowUpTemplates, 'templates');

const TemplateActions = {
  getAllTemplates,
  getTemplatesOfPipelineStep,
  setTemplate,
  deleteTemplate,
  getBuildRelationTemplates,
  getTemplate,
  getUncategorizedTemplate,
  getFollowUpTemplates,

  useTemplates,
  useBuildRelationTemplates,
  useTemplatesOfPipelineStep,
  useTemplate,
  useUncagorizedTemplates,
  useFollowUpTemplates
};

export default TemplateActions;
