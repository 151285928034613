export type SoundName = 'level-up';

const NAME_MAP: { [key in SoundName]: string } = {
  'level-up': 'level-up.wav'
};

const BASE_PATH = './sounds/';

export default class Sounds {
  static play(name: SoundName, volume = 0.5) {
    const audio = new Audio(`${BASE_PATH}${NAME_MAP[name]}`);
    audio.volume = volume;
    return audio.play();
  }
}
