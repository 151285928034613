import { Dates } from '@idot-digital/generic-helpers';

export default class PostLoaderHelper {
  private static LOCAL_STORAGE_KEY = 'post-loader:last-time-completed';
  private static getLastTimeCompleted() {
    const lastTimeCompleted = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    if (!lastTimeCompleted) {
      return null;
    }
    return new Date(lastTimeCompleted);
  }
  static isDue() {
    const lastTimeCompleted = this.getLastTimeCompleted();
    if (!lastTimeCompleted) {
      return true;
    }
    return !Dates.onSameDay(lastTimeCompleted);
  }
  static setLastTimeCompleted() {
    localStorage.setItem(this.LOCAL_STORAGE_KEY, new Date().toISOString());
  }
}
