import React from 'react';
import { Box, Button, SxProps, Typography } from '@mui/material';
import { QuestionMark } from '@mui/icons-material';
import { language } from '@/index';
import MainConfig from '@common/config/MainConfig';
import { OptionalTooltip, TerminalIcon, UpdateIcon } from 'ui-utils';

export interface StaticLoginLinksProps {
  loggedIn?: boolean;
  continueFct?: () => void;
}

export default function StaticLoginLinks(props: StaticLoginLinksProps) {
  const [clock, setClock] = React.useState({});
  React.useEffect(() => {
    const interval = setInterval(() => {
      setClock({});
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const buttons = React.useMemo<
    {
      icon: React.FC<{ sx: SxProps }>;
      action: () => void;
      label: string;
      disabled?: boolean;
      disabledTooltip?: string;
    }[]
  >(
    () => [
      {
        icon: TerminalIcon,
        action: () =>
          window.api.send('url:open_ext', MainConfig.loginScreenURLs.terminal),
        label: language.text.terminal,
        disabled: !MainConfig.loginScreenURLs.terminal
      },
      {
        icon: UpdateIcon,
        action: () =>
          window.api.send('url:open_ext', MainConfig.loginScreenURLs.update),
        label: language.text.changelog,
        disabled: !MainConfig.loginScreenURLs.update
      },
      {
        icon: QuestionMark,
        action: () =>
          window.api.send('url:open_ext', MainConfig.loginScreenURLs.faqCall),
        label: language.text.faq_call,
        disabled:
          !MainConfig.loginScreenURLs.faqCall ||
          (() => {
            const now = new Date();
            const day = now.getDay();
            // not monday
            if (day !== 1) return true;
            const hour = now.getHours();
            const minutes = now.getMinutes();
            const minutesOfDay = hour * 60 + minutes;
            // between 17:45 and 18:15
            if (minutesOfDay < 1065 || minutesOfDay > 1095) return true;
            return false;
          })(),
        disabledTooltip: language.text.faq_call_wrong_time
      }
    ],
    [props.continueFct, props.loggedIn, clock]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      {buttons.map(
        ({ icon: Icon, action, label, disabled, disabledTooltip }) => (
          <OptionalTooltip
            key={label}
            title={
              disabled
                ? (disabledTooltip ?? language.text.we_are_working_on_it)
                : undefined
            }
            placement="top">
            <Box>
              <Button
                disabled={disabled}
                onClick={() => {
                  if (!disabled) action();
                }}
                sx={{
                  cursor: !disabled ? 'pointer' : 'not-allowed',
                  textAlign: 'center',
                  ml: (theme) => theme.spacing(4),
                  mr: (theme) => theme.spacing(4),
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                <Box
                  sx={{
                    p: 1,
                    borderRadius: '50%',
                    aspectRatio: 1,
                    background: (theme) =>
                      disabled
                        ? theme.palette.neutral.main
                        : theme.palette.primary.main,
                    color: (theme) => theme.palette.primary.contrastText,
                    mb: 1
                  }}>
                  <Icon
                    sx={{
                      fontSize: '3rem',
                      display: 'block',
                      color: 'inherit'
                    }}
                  />
                </Box>
                <Typography
                  variant="body1"
                  textAlign="center"
                  sx={{
                    fontSize: {
                      md: '1em',
                      lg: '1.1em',
                      xl: '1.3em'
                    },
                    fontWeight: 'bold',
                    color: (theme) =>
                      disabled
                        ? theme.palette.action.disabled
                        : theme.palette.text.primary
                  }}>
                  {label}
                </Typography>
              </Button>
            </Box>
          </OptionalTooltip>
        )
      )}
    </Box>
  );
}
