import {
  DashProfileUrn,
  LinkedInConversation,
  LinkedInConversationCategory,
  LinkedInMessengerConversation,
  ProfileId
} from 'linkedin-domain-types';
import { CreatedBeforeIterator } from './CreatedBeforeIterator';
import { CursorIterator } from './CursorIterator';
import { Options, trace } from '../client/Utils';

type FetchConversations = ({
  recipients,
  createdBefore,
  options
}: {
  recipients?: ProfileId | ProfileId[];
  createdBefore?: Date;
  options: Options;
}) => Promise<Iterable<LinkedInConversation>>;

export type FetchConversationsV2 = ({
  mailboxUrn,
  keyword,
  categories,
  firstDegreeConnections,
  nextCursor,
  count,
  options
}: {
  mailboxUrn: DashProfileUrn;
  keyword: string;
  categories?: LinkedInConversationCategory[];
  firstDegreeConnections?: boolean;
  nextCursor?: string;
  count?: number;
  options: Options;
}) => Promise<{
  conversations: Iterable<LinkedInMessengerConversation>;
  nextCursor: string;
}>;

/**
 * Iterates over all conversations in a LinkedIn account returning them as single entities
 */
@trace()
export class ConversationIterator
  implements AsyncIterator<LinkedInConversation>
{
  /**
   * The iterator that fetches pages of conversations
   * @private the iterator that fetches pages of conversations
   */
  private iterator: ConversationPageIterator;

  /**
   * The pages of conversations that have been fetched
   * @private the pages of conversations that have been fetched
   */
  private pages: LinkedInConversation[] = [];

  /**
   * The index of the current conversation in the pages array
   * @private the index of the current conversation in the pages array
   */
  private index = 0;

  /**
   * Creates a new ConversationIterator
   * @param fetchConversations the function that fetches conversations
   * @param recipients the recipients to filter by (does currently not work)
   * @param createdBefore the date to filter by
   * @param options the options to use for the request
   */
  constructor({
    fetchConversations,
    recipients,
    createdBefore,
    options
  }: {
    fetchConversations: FetchConversations;
    recipients?: ProfileId | ProfileId[];
    createdBefore?: Date;
    options: Options;
  }) {
    this.iterator = new ConversationPageIterator({
      fetchConversations,
      recipients,
      createdBefore,
      options
    });
  }

  /**
   * Returns the next conversation and advances the iterator
   * @returns the next conversation
   */
  async next(): Promise<
    IteratorResult<LinkedInConversation, LinkedInConversation>
  > {
    if (this.index === this.pages.length) {
      const res = await this.iterator.next();
      if (res.done) {
        return { done: true, value: {} as LinkedInConversation };
      }
      this.pages.push(...res.value);
    }
    return { done: false, value: this.pages[this.index++] };
  }

  /**
   * Returns the previous conversation and moves the iterator back
   * @returns the previous conversation
   */
  async prev(): Promise<
    IteratorResult<LinkedInConversation, LinkedInConversation>
  > {
    if (this.index === 0) {
      const res = await this.iterator.prev();
      if (res.done) {
        return { done: true, value: {} as LinkedInConversation };
      }
      this.pages.unshift(...res.value);
      this.index = this.pages.length;
    }
    return { done: false, value: this.pages[--this.index] };
  }

  /**
   * Restarts the iterator
   */
  restart(): void {
    this.iterator.restart();
    this.pages = [];
    this.index = 0;
  }
}

@trace()
export class ConversationV2Iterator
  implements AsyncIterator<LinkedInMessengerConversation>
{
  private iterator: ConversationV2PageIterator;

  private pages: LinkedInMessengerConversation[] = [];

  private index = 0;

  constructor(params: {
    fetchConversations: FetchConversationsV2;
    mailboxUrn: DashProfileUrn;
    keyword: string;
    categories?: LinkedInConversationCategory[];
    firstDegreeConnections?: boolean;
    nextCursor?: string;
    count?: number;
    options: Options;
  }) {
    this.iterator = new ConversationV2PageIterator(params);
  }

  async next(): Promise<
    IteratorResult<LinkedInMessengerConversation, LinkedInMessengerConversation>
  > {
    if (this.index === this.pages.length) {
      const res = await this.iterator.next();
      if (res.done) {
        return { done: true, value: {} as LinkedInMessengerConversation };
      }
      this.pages.push(...res.value);
    }
    return { done: false, value: this.pages[this.index++] };
  }

  restart(): void {
    this.iterator.restart();
    this.pages = [];
    this.index = 0;
  }

  /**
   * Returns the cursor of the current page
   */
  getCursor(): string {
    return this.iterator.getCursor();
  }
}

/**
 * Iterates over all conversations in a LinkedIn account returning them as pages
 */
@trace()
class ConversationPageIterator extends CreatedBeforeIterator<LinkedInConversation> {
  /**
   * The function that fetches conversations pages from LinkedIn
   * @private the function that fetches conversations
   */
  private readonly fetchConversations: FetchConversations;

  /**
   * The recipients to filter by
   * @private the recipients to filter by
   */
  private readonly recipients?: ProfileId | ProfileId[];

  /**
   * the options to use for requests
   */
  private readonly options: Options;

  /**
   * Creates a new ConversationPageIterator
   * @param fetchConversations the function that fetches conversations
   * @param recipients the recipients to filter by (does currently not work)
   * @param createdBefore the date to filter by
   * @param options the options to use for the request
   */
  constructor({
    fetchConversations,
    recipients,
    createdBefore,
    options
  }: {
    fetchConversations: FetchConversations;
    recipients?: ProfileId | ProfileId[];
    createdBefore?: Date;
    options: Options;
  }) {
    super({ createdBefore });

    this.options = options;

    this.recipients = recipients;

    this.fetchConversations = fetchConversations;
  }

  /**
   * Fetches the next page of conversations
   * @protected fetches the next page of conversations
   */
  protected async fetch(): Promise<Iterable<LinkedInConversation>> {
    return this.fetchConversations({
      ...(this.createdBefore === undefined
        ? {}
        : { createdBefore: new Date(this.createdBefore) }),
      ...(this.recipients && { recipients: this.recipients }),
      options: this.options
    });
  }
}

@trace()
class ConversationV2PageIterator extends CursorIterator<LinkedInMessengerConversation> {
  private readonly fetchConversations: FetchConversationsV2;

  private readonly mailboxUrn: DashProfileUrn;

  private readonly keyword: string;

  private readonly categories?: LinkedInConversationCategory[];

  private readonly firstDegreeConnections?: boolean;

  private readonly count?: number;

  private readonly options: Options;

  constructor({
    fetchConversations,
    mailboxUrn,
    keyword,
    categories,
    firstDegreeConnections,
    nextCursor,
    count,
    options
  }: {
    fetchConversations: FetchConversationsV2;
    mailboxUrn: DashProfileUrn;
    keyword: string;
    categories?: LinkedInConversationCategory[];
    firstDegreeConnections?: boolean;
    nextCursor?: string;
    count?: number;
    options: Options;
  }) {
    super({ cursor: nextCursor });
    this.fetchConversations = fetchConversations;
    this.mailboxUrn = mailboxUrn;
    this.keyword = keyword;
    this.categories = categories;
    this.firstDegreeConnections = firstDegreeConnections;
    this.count = count;
    this.options = options;
  }

  protected async fetch(): Promise<Iterable<LinkedInMessengerConversation>> {
    const res = await this.fetchConversations({
      mailboxUrn: this.mailboxUrn,
      keyword: this.keyword,
      categories: this.categories,
      firstDegreeConnections: this.firstDegreeConnections,
      nextCursor: this.cursor,
      count: this.count,
      options: this.options
    });
    this.cursor = res.nextCursor;
    return res.conversations;
  }

  getCursor(): string {
    return this.cursor;
  }

  restart(): void {
    this.cursor = '';
  }
}
