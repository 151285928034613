import { TaskBuilder, TaskOptionsConfig } from 'focus-mode-scheduler';
import ConnectProfileTask from './ConnectProfileTask';
import { NextTask } from 'focus-mode-scheduler/src/Task/TaskBuilder';
import Auth from '@common/AuthManager/Auth.renderer';
import { contacts, util } from '@digital-sun-solutions/cloud-functions';
import { language } from '@/index';
import { MarkedProfile } from '@/data/Classes/Contact';
import PrintableError from '@common/PrintableError/PrintableError';
import { Iterators } from '@idot-digital/generic-helpers';
import { BindProps, MapProps } from 'ui-utils';
import ConnectProfileTaskUI from './ConnectProfileTaskUI';
import OnboardingVideo from '../_general/OnboardingVideo/OnboardingVideo';

export default class ConnectProfileTaskBuilder extends TaskBuilder {
  protected task = ConnectProfileTask;

  protected remainingConnections: number | null = null;
  protected async getRemainingConnections(): Promise<number> {
    if (this.remainingConnections !== null) return this.remainingConnections;
    const res = await Auth.execRoute((token) =>
      util.number_of_remaining_connection_requests({}, { token })
    );
    if (res.code !== 200) return 0;
    this.remainingConnections = Number(res.data);
    return this.remainingConnections;
  }

  protected iterator: AsyncGenerator<MarkedProfile> | null = null;
  protected async getIterator(): Promise<AsyncGenerator<MarkedProfile>> {
    if (this.iterator) return this.iterator;
    const res = await Auth.execRoute((token) =>
      contacts.marked.get({}, { token })
    );

    if (res.code !== 200)
      throw new PrintableError(`Could not get marked contacts: ${res.data}`);

    this.iterator = Iterators.fromArray(
      // reverse to show newset first
      res.data.map((c) => new MarkedProfile(c)).reverse()
    );

    return this.iterator;
  }

  async getTask(): Promise<NextTask<ConnectProfileTask>> {
    const { value, done } = await (await this.getIterator()).next();
    if (done) return null;

    const task = new ConnectProfileTask(value, () => {
      if (this.remainingConnections) this.remainingConnections--;
    });
    const UI = BindProps(ConnectProfileTaskUI, { task });
    return {
      task,
      UI
    };
  }

  public getStaticUI(): React.FC<{ loading?: boolean }> | null {
    return MapProps(
      BindProps(OnboardingVideo, {
        onboardingStep: 'focus:connectProfile',
        completeOn: 'finish',
        offset: { y: 60 }
      }),
      ({ loading }: { loading?: boolean }) => ({ pause: loading })
    );
  }

  public async getOptionsConfig(): Promise<TaskOptionsConfig | null> {
    const maxRemainingConnections = await this.getRemainingConnections();
    return {
      items: {
        defaultValue: maxRemainingConnections,
        min: 1,
        max: 60,
        scale: 'logarithmic',
        step: 1
      },
      time: {
        defaultValue: 10,
        max: 60,
        min: 2,
        scale: 'logarithmic',
        step: 1
      },
      defaultValue: {
        type: 'items',
        items: maxRemainingConnections
      },
      texts: {
        taskName: language.text.connect,
        lowerText: language.text.max_connections_explanation
      }
    };
  }
}
