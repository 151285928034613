import { Dates as DatesHelper } from '@idot-digital/generic-helpers';

export class Dates extends DatesHelper {
  static getDatesBetween(
    startDate: Date,
    endDate: Date,
    interval: {
      interval: 'hour' | 'day' | 'week' | 'month' | 'year';
      intervalFactor: number;
    } = {
      interval: 'day',
      intervalFactor: 1
    }
  ): Date[] {
    const days = [];
    let current = new Date(startDate);
    current.setHours(0, 0, 0, 0);
    while (
      current < endDate ||
      Dates.onSame(interval.interval, current, endDate)
    ) {
      days.push(current);
      current = Dates.add(
        new Date(current),
        interval.intervalFactor,
        interval.interval
      );
    }
    return days;
  }
  static onSame(
    type: 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year',
    date1: Date,
    date2?: Date
  ): boolean {
    if (!date2) date2 = new Date();
    switch (type) {
      case 'minute':
        if (date1.getMinutes() !== date2.getMinutes()) return false;
        return Dates.onSame('hour', date1, date2);
      case 'hour':
        if (date1.getHours() !== date2.getHours()) return false;
        return Dates.onSame('day', date1, date2);
      case 'day':
        if (date1.getDate() !== date2.getDate()) return false;
        return Dates.onSame('month', date1, date2);
      case 'week':
        return Dates.onSame(
          'day',
          date1,
          // set week day to week day of date1
          new Date(
            new Date(date2).setDate(
              date2.getDate() - date2.getDay() + date1.getDay()
            )
          )
        );
      case 'month':
        if (date1.getMonth() !== date2.getMonth()) return false;
        return Dates.onSame('year', date1, date2);
      case 'year':
        return date1.getFullYear() === date2.getFullYear();
      default:
        return false;
    }
  }
}
