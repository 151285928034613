import React from 'react';
import { Box, Typography } from '@mui/material';
import Menu from '../Items/Menu';
import { language } from '@/index';
import {
  ChevronIcon,
  GenericVideoModal,
  MedalIcon,
  ParachuteIcon,
  PilotIcon,
  StrategyIcon,
  WalkieTalkieIcon
} from 'ui-utils';
import posthog from 'posthog-js';
import * as Sentry from '@sentry/electron/renderer';

export default function SSCTraining() {
  const [open, setOpen] = React.useState(false);
  const [currentVideoID, setCurrentVideoID] = React.useState('');
  const [currentVideoTitle, setCurrentVideoTitle] = React.useState('');

  const menus = [
    {
      topTitle: `${language.text.module} 0`,
      title: language.text.welcome,
      svg: PilotIcon,
      videoID: 'bDST0sv_RpQ',
      videoTitle: `${language.text.welcome} - ${language.text.pilot_training}`
    },
    {
      topTitle: `${language.text.module} 1`,
      title: language.text.messages,
      svg: ChevronIcon,
      videoID: 'K1wQNnMvXtg',
      videoTitle: `${language.text.messages} - ${language.text.pilot_training}`
    },
    {
      topTitle: `${language.text.module} 2`,
      title: language.text.content,
      svg: StrategyIcon,
      videoID: '1mASZK_c-wA',
      videoTitle: `${language.text.content} - ${language.text.pilot_training}`
    },
    {
      topTitle: `${language.text.module} 3`,
      title: language.text.comments,
      svg: WalkieTalkieIcon,
      videoID: 'mXOgpxiDj1Q',
      videoTitle: `${language.text.comments} - ${language.text.pilot_training}`
    },
    {
      topTitle: `${language.text.module} 4`,
      title: language.text.sell,
      svg: ParachuteIcon,
      videoID: 'FK-3hmUy3ug',
      videoTitle: `${language.text.sell} - ${language.text.pilot_training}`
    },
    {
      topTitle: `${language.text.module} 5`,
      title: language.text.certificat,
      disabled: true,
      svg: MedalIcon,
      subtitle: language.text.coming_soon
    }
  ];

  return (
    <Box>
      <Typography
        variant="h5"
        sx={{
          textAlign: 'center',
          fontWeight: 'bolder'
        }}>
        {language.text.pilot_training}
      </Typography>
      <Box
        sx={{
          m: 2,
          display: 'grid',
          gap: 2,
          gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
          gridAutoRows: 'minmax(200px, 1fr)'
        }}>
        {menus.map((menu, i) => (
          <Menu
            key={i}
            icon={<menu.svg sx={{ height: 'auto', width: '100%' }} />}
            onClick={() => {
              if (!(menu.videoID && menu.videoTitle)) {
                Sentry.captureException(
                  new Error(
                    '[SSCTraining.tsx] No videoID or videoTitle provided. This should not happen.'
                  )
                );
                return;
              }
              posthog.capture(`ssc-training-${menu.videoID}-started`);
              setCurrentVideoID(menu.videoID);
              setCurrentVideoTitle(menu.videoTitle);
              setOpen(true);
            }}
            topTitle={menu.topTitle}
            title={menu.title}
            disabled={menu.disabled}
            subtitle={menu.subtitle}
          />
        ))}
      </Box>
      <GenericVideoModal
        open={open}
        onClose={() => {
          posthog.capture(`ssc-training-${currentVideoID}-closed`);
          setOpen(false);
        }}
        videoID={currentVideoID}
        title={currentVideoTitle}
        onEnd={() => {
          posthog.capture(`ssc-training-${currentVideoID}-ended`);
        }}
      />
    </Box>
  );
}
