import MessageBus from '@common/MessageBus/MessageBus.renderer';
import { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import AutocompleteInput from './AutoCompleteInput/AutocompleteInput';
import { FullEventData } from '@common/MessageBus/MessageBusUtilTypes';

type Message = FullEventData & { date: Date };

interface MessagesListProps {
  messageClicked: (key: Message) => void;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  searchQuery: string;
}

export default function MessagesList({
  messageClicked,
  searchQuery,
  setSearchQuery
}: MessagesListProps) {
  const forceRerender = useReducer(() => ({}), {})[1];

  const [messages, setMessages] = useState<Message[]>([]);
  const [paused, setPaused] = useState(false);
  const [filterStorage, setFilterStorage] = useState(false);
  const [selectedMessageID, setSelectedMessageID] = useState<null | string>(
    null
  );
  const [filterString, setFilterString] = useState('');

  const unregisterMessageHandler = useRef<(() => void) | null>(null);

  const filteredMessages = useMemo(() => {
    let filteredMessages = messages;
    if (filterString.length > 0) {
      filteredMessages = filteredMessages.filter((message) =>
        message.eventName
          .toLocaleLowerCase()
          .startsWith(filterString.toLocaleLowerCase())
      );
    }
    if (searchQuery.length > 0) {
      filteredMessages = filteredMessages.filter((message) =>
        findStringinObject(message, searchQuery)
      );
    }
    return filteredMessages;
  }, [filterString, messages, searchQuery]);

  const channels = useMemo(
    () => [...new Set(messages.map((message) => message.eventName))],
    [messages]
  );

  useEffect(() => {
    const interval = setInterval(forceRerender, 5 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (paused) {
      if (unregisterMessageHandler.current !== null) {
        unregisterMessageHandler.current();
        unregisterMessageHandler.current = null;
      }
    } else {
      if (unregisterMessageHandler.current !== null) {
        unregisterMessageHandler.current();
      }
      unregisterMessageHandler.current = MessageBus.getInstance().debugGetAll(
        (m) => {
          if (
            filterStorage &&
            !m.eventName
              .toLocaleLowerCase()
              .startsWith(filterString.toLocaleLowerCase())
          ) {
            console.log('filtered', m);
            return;
          }
          setMessages((messages) => [
            {
              ...m,
              date: new Date()
            },
            ...messages
          ]);
        }
      );
    }
  }, [paused, filterStorage, filterString]);

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          gridTemplateRows: 'auto',
          gap: '8px'
        }}>
        <button
          style={{ fontFamily: 'monospace' }}
          onClick={() => {
            setMessages([]);
          }}>
          Clear All
        </button>
        <button
          style={{ fontFamily: 'monospace' }}
          onClick={() => {
            setMessages(filteredMessages);
          }}>
          Clear hidden
        </button>
        <button
          style={{ fontFamily: 'monospace' }}
          onClick={() => setPaused(!paused)}>
          {paused ? 'Resume' : 'Pause'}
        </button>
        <button
          style={{ fontFamily: 'monospace' }}
          onClick={() => setFilterStorage(!filterStorage)}>
          {filterStorage ? 'Save all' : 'Save selected'}
        </button>
      </div>
      <AutocompleteInput
        style={{
          marginTop: '8px',
          marginBottom: '8px',
          padding: '2px',
          fontFamily: 'monospace'
        }}
        placeholder="event-filter"
        onChange={(value) => {
          setFilterString(value);
        }}
        value={filterString}
        options={channels}
      />
      <input
        style={{
          marginTop: '8px',
          marginBottom: '8px',
          padding: '2px',
          fontFamily: 'monospace'
        }}
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="autocomplete-input"
        placeholder="Search in JSON"
      />

      <table
        style={{
          borderCollapse: 'collapse',
          width: '100%'
        }}>
        <thead style={{ borderBottom: '1px solid black' }}>
          <tr>
            <th> </th>
            <th>eventName</th>
            <th>date</th>
          </tr>
        </thead>
        <tbody>
          {filteredMessages.map((message) => (
            <tr
              key={message.id}
              style={{
                borderBottom: '1px solid black',
                height: '10px',
                backgroundColor:
                  selectedMessageID === message.id ? '#bbb' : '#eee'
              }}
              onMouseDown={() => {
                messageClicked(message);
                setSelectedMessageID(message.id);
              }}>
              <td style={{ filter: 'grayscale(1)' }}>
                {/* {message.persistence === MessagePersistence.PERSISTENT
                  ? '💾'
                  : ''} */}
              </td>
              <td>{message.eventName}</td>
              <td title={message.date.toISOString()}>
                {approxDateDistance(message.date, new Date())}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

function approxDateDistance(a: Date, b: Date): string {
  const difference = b.getTime() - a.getTime();
  switch (true) {
    case difference < 10 * 1000:
      return '<10s';
    case difference < 60 * 1000:
      return `~${Math.floor(difference / 10000)}0s`;
    case difference < 5 * 60 * 1000:
      return `~${Math.floor(difference / (60 * 1000))}min`;
    default:
      return '> 5 min';
  }
}

export function findStringinObject(
  obj: unknown,
  search: string,
  depth = 0
): boolean {
  if (depth > 50) return false;
  if (obj === null || obj === undefined) return false;
  if (typeof obj === 'string') {
    return obj.includes(search);
  } else if (Array.isArray(obj)) {
    for (const item of obj) {
      if (findStringinObject(item, search, depth + 1)) {
        return true;
      }
    }
  } else if (typeof obj === 'object') {
    const safeObj = obj as { [key: string]: unknown };
    return Object.values(safeObj).some((value) =>
      findStringinObject(value, search, depth + 1)
    );
  }
  return false;
}
