import React from 'react';
import { Box, Typography } from '@mui/material';

export type MenuProps = {
  icon: React.ReactNode;
  topTitle?: React.ReactNode;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
};

export default function Menu(props: MenuProps) {
  return (
    <Box
      onClick={props.disabled ? undefined : props.onClick}
      sx={{
        'borderRadius': 1,
        'border': (theme) => `1px solid ${theme.palette.divider}`,
        'p': 2,
        'display': 'flex',
        'flexDirection': 'column',
        'justifyContent': 'center',
        'alignItems': 'center',
        'cursor': props.disabled ? 'not-allowed' : 'pointer',
        'boxSizing': 'border-box',
        'backgroundColor': (theme) => theme.palette.background.default,
        'transition': (theme) =>
          theme.transitions.create(['background-color', 'box-shadow']),
        '&:hover': {
          backgroundColor: (theme) => theme.palette.action.hover,
          boxShadow: (theme) => theme.shadows[3]
        }
      }}>
      {props.topTitle && (
        <Typography
          sx={{
            mt: 0.5,
            fontSize: '0.9em',
            textAlign: 'center',
            fontWeight: 'bold'
          }}>
          {props.topTitle}
        </Typography>
      )}
      <Box
        sx={{
          width: '65px',
          height: '65px',
          padding: (theme) => theme.spacing(1, 4),
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
        {props.icon}
      </Box>
      <Typography
        sx={{
          textAlign: 'center',
          fontWeight: 'bold'
        }}>
        {props.title}
      </Typography>
      {props.subtitle && (
        <Typography
          sx={{
            mt: 0.5,
            fontSize: '0.9em',
            textAlign: 'center'
          }}>
          {props.subtitle}
        </Typography>
      )}
    </Box>
  );
}
