import { ImageViewModel, LinkedInVectorImage, TextViewModel } from './Common';
import {
  ActivityUrn,
  CompanyUrn,
  DashSaveStateUrn,
  DashSubscribeActionUrn,
  DashUpdateActionsUrn,
  DashUpdateUrn,
  DigitalMediaAssetUrn,
  FollowingInfoUrn,
  HidePostActionUrn,
  LinkedInArticleUrn,
  SaveActionUrn,
  SocialActivityCountsUrn,
  SocialDetailUrn,
  SubscribeActionUrn,
  UgcPostUrn,
  UpdateSummaryUrn,
  UpdateV2ActionsUrn,
  UpdateV2Urn
} from './Urns';
import {
  LinkedInSocialActivityCounts,
  LinkedInSocialDetail,
  LinkedInTrackingData
} from './Feed';
import { LinkedInFeedAction, LinkedInFeedHidePostAction } from './FeedActions';
import { LinkedInDocument } from './DocumentContent';
import { LinkedInVideoPlayMetadata } from './VideoContent';

const FEED_RENDER_TYPE_PREFIX = 'com.linkedin.voyager.feed.render';

export const UPDATE_SUMMARY_TYPE =
  `${FEED_RENDER_TYPE_PREFIX}.UpdateSummary` as const;

export interface LinkedInUpdateSummary {
  '$type': typeof UPDATE_SUMMARY_TYPE;
  '*socialCounts': SocialActivityCountsUrn;
  'socialCounts'?: LinkedInSocialActivityCounts;
  'actorImage': ImageViewModel;
  'contentSubtitle': TextViewModel;
  'contentSubtitle2'?: TextViewModel;
  'contentSubtitle3'?: TextViewModel;
  'interstitial'?: unknown;
  'contentEntityControlName'?: unknown;
  'actorName': TextViewModel;
  'actionTarget': URL;
  'contentTitle': TextViewModel;
  'contextHeader'?: unknown;
  'showPlayButton': boolean;
  'actorDescription'?: TextViewModel;
  'urn': ActivityUrn;
  'actorActionTarget': URL;
  'entityUrn': UpdateSummaryUrn;
  'actionSummary'?: TextViewModel;
  'contentFormatType'?: unknown;
  'contentImageControlName'?: unknown;
  'createdTime': number;
  'contentImage'?: LinkedInVectorImage;
  'commentary': TextViewModel;
}

export const UPDATE_ACTION_TYPE =
  `${FEED_RENDER_TYPE_PREFIX}.UpdateActions` as const;

export interface LinkedInUpdateActions {
  $type: typeof UPDATE_ACTION_TYPE;
  dashEntityUrn: DashUpdateActionsUrn;
  entityUrn: UpdateV2ActionsUrn;
  actions: LinkedInFeedAction[];
}

export const FOLLOW_ACTION_TYPE =
  `${FEED_RENDER_TYPE_PREFIX}.FollowAction` as const;

export interface LinkedInFollowAction {
  '$type': typeof FOLLOW_ACTION_TYPE;
  'unmuteTrackingActionType': 'undoMute';
  'muteTrackingActionType': 'muteMember';
  '*followingInfo': FollowingInfoUrn;
  'trackingActionType': 'undoMute' | 'muteMember';
  'type': 'MUTE_TOGGLE';
}

export const CONFIRMATION_ACTION_TYPE =
  `${FEED_RENDER_TYPE_PREFIX}.ConfirmationAction` as const;

export interface LinkedInConfirmationAction {
  $type: typeof CONFIRMATION_ACTION_TYPE;
  description: TextViewModel;
  undoable: boolean;
  title: TextViewModel;
}

export const SUBSCRIBE_ACTION_TYPE =
  `${FEED_RENDER_TYPE_PREFIX}.SubscribeAction` as const;

export interface LinkedInSubscribeAction {
  $type: typeof SUBSCRIBE_ACTION_TYPE;
  subscribed: boolean;
  dashEntityUrn: DashSubscribeActionUrn;
  subscribeConfirmationMessage: unknown;
  entityUrn: SubscribeActionUrn;
  subscriberCount: number;
  unsubscribeConfirmationMessage: unknown;
  type: unknown;
}

export const SAVE_ACTION_TYPE =
  `${FEED_RENDER_TYPE_PREFIX}.SaveAction` as const;

export interface LinkedInSaveAction {
  $type: typeof SAVE_ACTION_TYPE;
  dashEntityUrn: DashSaveStateUrn;
  entityUrn: SaveActionUrn;
  saved: boolean;
}

type ContentComponent =
  | LinkedInArticleComponent
  | LinkedInDocumentComponent
  | LinkedInVideoComponent
  | LinkedInImageComponent
  | LinkedInCelebrationComponent;

export const UPDATEV2_TYPE = `${FEED_RENDER_TYPE_PREFIX}.UpdateV2` as const;

export interface LinkedInUpdateV2 {
  '$type': typeof UPDATEV2_TYPE;
  'dashEntityUrn': DashUpdateUrn;
  'showSocialDetail': unknown;
  'socialContent': unknown;
  'footer': unknown;
  'desktopPromoUpdate': unknown;
  'carouselContent': unknown;
  'content': ContentComponent;
  'updateMetadata': LinkedInUpdateMetadata;
  'detailHeader': unknown;
  'entityUrn': UpdateV2Urn;
  'leadGenFormContent': unknown;
  'annotation': unknown;
  'contextualHeader': unknown;
  'resharedUpdate': unknown;
  'interstitial': unknown;
  'contextualDescriptionV2': unknown;
  'leadGenFormContentV2': unknown;
  'contextualDescription': unknown;
  'aggregatedContent': unknown;
  'actor': LinkedInActorComponent;
  'relatedContent': unknown;
  '*socialDetail': SocialDetailUrn;
  'socialDetail'?: LinkedInSocialDetail;
  'header': unknown;
  'highlightedComments': unknown;
  'commentary': LinkedInTextComponent;
  'additionalContents': unknown;
}

export const ARTICLE_COMPONENT_TYPE =
  `${FEED_RENDER_TYPE_PREFIX}.ArticleComponent` as const;

export interface LinkedInArticleComponent {
  '$type': typeof ARTICLE_COMPONENT_TYPE;
  'headlineBackgroundColor': 'DEFAULT';
  'largeImage': ImageViewModel;
  'authorNavigationContext': LinkedInFeedNavigationContext;
  'author': TextViewModel;
  'description': TextViewModel;
  'type': 'FIRST_PARTY';
  'title': TextViewModel;
  'followAction': LinkedInFollowAction;
  'subtitleImage': ImageViewModel;
  'urn': LinkedInArticleUrn;
  '*subscribeAction': SubscribeActionUrn;
  'subscribeAction'?: LinkedInSubscribeAction;
  'swapTitleAndSubtitle': boolean;
  'subtitle': TextViewModel;
  'navigationContext': LinkedInFeedNavigationContext;
  'showSmallTitle': boolean;
}

export const DOCUMENT_COMPONENT_TYPE =
  `${FEED_RENDER_TYPE_PREFIX}.DocumentComponent` as const;

export interface LinkedInDocumentComponent {
  $type: typeof DOCUMENT_COMPONENT_TYPE;
  showDownloadCTA: boolean;
  document: LinkedInDocument;
}

export const UPDATE_METADATA_TYPE =
  `${FEED_RENDER_TYPE_PREFIX}.UpdateMetadata` as const;

export interface LinkedInUpdateMetadata {
  '$type': typeof UPDATE_METADATA_TYPE;
  'urn': ActivityUrn;
  'actionsPosition': 'ACTOR_COMPONENT';
  'actionTriggerEnabled': boolean;
  '*updateActions': UpdateV2ActionsUrn;
  'updateActions'?: LinkedInUpdateActions;
  'detailPageType': 'FEED_DETAIL';
  '*hidePostAction': HidePostActionUrn;
  'hidePostAction'?: LinkedInFeedHidePostAction;
  'shareAudience': 'PUBLIC' | 'CONNECTIONS';
  'shareUrn': UgcPostUrn;
  'excludedFromSeen': boolean;
  'rootShare': boolean;
  'trackingData': LinkedInTrackingData;
}

export const TEXT_COMPONENT_TYPE =
  `${FEED_RENDER_TYPE_PREFIX}.TextComponent` as const;

export interface LinkedInTextComponent {
  $type: typeof TEXT_COMPONENT_TYPE;
  numLines: number;
  text: TextViewModel;
}

export const ACTOR_COMPONENT_TYPE =
  `${FEED_RENDER_TYPE_PREFIX}.ActorComponent` as const;

export interface LinkedInActorComponent {
  $type: typeof ACTOR_COMPONENT_TYPE;
  urn: CompanyUrn;
  image: ImageViewModel;
  name: TextViewModel;
  subDescription: TextViewModel;
  navigationContext: LinkedInFeedNavigationContext;
  description: TextViewModel;
}

export const FEED_NAVIGATION_CONTEXT_TYPE =
  `${FEED_RENDER_TYPE_PREFIX}.FeedNavigationContext` as const;

export interface LinkedInFeedNavigationContext {
  $type: typeof FEED_NAVIGATION_CONTEXT_TYPE;
  actionTarget: string;
  trackingActionType: string;
  accessibilityText: string;
}

export const IMAGE_COMPONENT_TYPE =
  `${FEED_RENDER_TYPE_PREFIX}.ImageComponent` as const;

export interface LinkedInImageComponent {
  $type: typeof IMAGE_COMPONENT_TYPE;
  images: ImageViewModel[];
  showTemplateCTA: boolean;
}

export const VIDEO_COMPONENT_TYPE =
  `${FEED_RENDER_TYPE_PREFIX}.VideoComponent` as const;

export interface LinkedInVideoComponent {
  '$type': typeof VIDEO_COMPONENT_TYPE;
  'headlineBackgroundColor': string;
  'mediaDisplayVariant': string;
  '*videoPlayMetadata': DigitalMediaAssetUrn;
  'videoPlayMetadata'?: LinkedInVideoPlayMetadata;
  'tapTargets': unknown[];
  'title'?: TextViewModel;
  'descriptionContainerNavigationContext'?: LinkedInFeedNavigationContext;
  'navigationContext'?: LinkedInFeedNavigationContext;
  'sponsoredLandingUrl'?: string;
  'showSmallTitle'?: boolean;
  'inlineCtaButton'?: LinkedInButtonComponent;
}

export const BUTTON_COMPONENT_TYPE =
  `${FEED_RENDER_TYPE_PREFIX}.ButtonComponent` as const;

export interface LinkedInButtonComponent {
  $type: typeof BUTTON_COMPONENT_TYPE;
  text: string;
  navigationContext: LinkedInFeedNavigationContext;
}

export const CELEBRATION_COMPONENT_TYPE =
  `${FEED_RENDER_TYPE_PREFIX}.CelebrationComponent` as const;

export interface LinkedInCelebrationComponent {
  $type: typeof CELEBRATION_COMPONENT_TYPE;
  image: ImageViewModel;
  headline: TextViewModel;
}
