import React, { RefObject } from 'react';
import useEventListener from './ElementEventListener';

function useHover<T extends HTMLElement = HTMLElement>(): [
  RefObject<T>,
  boolean
] {
  const ref = React.useRef<T>(null);
  const [value, setValue] = React.useState(false);

  const handleMouseEnter = () => {
    setValue(true);
  };
  const handleMouseLeave = () => {
    setValue(false);
  };

  useEventListener('mouseenter', handleMouseEnter, ref);
  useEventListener('mouseleave', handleMouseLeave, ref);

  return [ref, value];
}

export default useHover;
