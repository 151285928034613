import { Routes, Route, Navigate } from 'react-router-dom';
import { SETTINGS_MENU_LIST } from './Settings';

export default function Settings() {
  return (
    <Routes>
      <Route index element={<Navigate to={SETTINGS_MENU_LIST[0].path} />} />
      {SETTINGS_MENU_LIST.map((item) => (
        <Route
          key={item.path}
          path={`${item.path}/*`}
          element={<item.element />}
        />
      ))}
    </Routes>
  );
}
