import {
  ContentSeriesUrn,
  DashFollowingStateUrn,
  DashGeoUrn,
  DashIndustryUrn,
  DashRegionUrn,
  DashRingStatusUrn,
  DigitalMediaAssetUrn,
  FollowingInfoUrn,
  MiniCompanyUrn,
  MiniProfileUrn,
  RingStatusUrn
} from './Urns';
import { LinkedInMiniProfile } from './Identity';
import { Country } from './CountryCode';
import { LinkedInMiniCompany } from './Entities';

const COMMON_PREFIX = 'com.linkedin.common';
const VOYAGER_COMMON_PREFIX = 'com.linkedin.voyager.common';
const DASH_COMMON_PREFIX = 'com.linkedin.voyager.dash.common';

export type PublicIdentifier = string;
export type TrackingId = string;
export type CompanyName = string;
export type CompanyUniversalName = string;

export interface LinkedInUserMetadata {
  client: {
    deviceType: string;
    browserLanguage: string;
    isUserLoggedIn: boolean;
    countryCode: string;
    isInternalRequest: boolean;
    language?: string;
  };
  compliance: {
    isCCPAOptIn: boolean;
    isLinkedInEmployee: boolean;
    isFunctionalOptIn: boolean;
    isGeoOptIn: boolean;
    isGDPROptIn: boolean;
    isAdvertisingOptIn: boolean;
    isAnalyticsAndResearchOptIn: boolean;
  };
  emails?: {
    sha1: string[];
    sha256: string[];
  };
  id?: {
    dmp: string;
  };
  primaryEmail?: {
    sha1: string;
    sha256: string;
  };
  preference?: {
    language: string;
  };
}

export type LinkedInConversationCategory =
  | 'INBOX'
  | 'SPAM'
  | 'ARCHIVE'
  | 'PRIMARY_INBOX'
  | 'STARRED';

export const TEXT_VIEW_MODEL_TYPE =
  `${VOYAGER_COMMON_PREFIX}.TextViewModel` as const;

export interface TextViewModel {
  $type: typeof TEXT_VIEW_MODEL_TYPE;
  text: string;
  textDirection?: string;
  accessibilityText?: string;
  attributes: TextAttributes[];
}

type TextAttributes =
  | HyperlinkAttribute
  | MiniCompanyAttribute
  | ProfileFullnameAttribute
  | BoldAttribute;

export const TEXT_ATTRIBUTE_TYPE =
  `${VOYAGER_COMMON_PREFIX}.TextAttribute` as const;

interface TextAttribute<T extends string> {
  $type: typeof TEXT_ATTRIBUTE_TYPE;
  type: T;
}

export interface HyperlinkAttribute extends TextAttribute<'HYPERLINK'> {
  start: number;
  length: number;
  link: string;
}

export interface MiniCompanyAttribute extends TextAttribute<'MINI_COMPANY'> {
  '*miniCompany': MiniCompanyUrn;
  'miniCompany'?: LinkedInMiniCompany;
  'start': number;
  'length': number;
}

export interface ProfileFullnameAttribute
  extends TextAttribute<'PROFILE_FULLNAME'> {
  'start': number;
  'length': number;
  '*miniProfile': MiniProfileUrn;
  'miniProfile'?: LinkedInMiniProfile;
}

export interface BoldAttribute extends TextAttribute<'BOLD'> {
  start: number;
  length: number;
}

export const IMAGE_VIEW_MODEL_TYPE =
  `${VOYAGER_COMMON_PREFIX}.ImageViewModel` as const;

export interface ImageViewModel {
  $type: typeof IMAGE_VIEW_MODEL_TYPE;
  attributes: ImageAttributes[];
  actionTarget: string;
}

export const IMAGE_ATTRIBUTE_TYPE =
  `${VOYAGER_COMMON_PREFIX}.ImageAttribute` as const;

export type ImageAttributes =
  | ProfilePictureAttribute
  | CompanyLogoAttribute
  | ProfessionalEventLogoAttribute;

interface ImageAttribute<T extends string> {
  $type: typeof IMAGE_ATTRIBUTE_TYPE;
  sourceType: T;
}

export interface ProfilePictureAttribute
  extends ImageAttribute<'PROFILE_PICTURE'> {
  '*miniProfile': MiniProfileUrn;
  'miniProfile'?: LinkedInMiniProfile;
}

export type ProfessionalEventLogoAttribute =
  ImageAttribute<'PROFESSIONAL_EVENT_LOGO'>;

export interface CompanyLogoAttribute extends ImageAttribute<'COMPANY_LOGO'> {
  '*miniCompany': MiniCompanyUrn;
  'miniCompany'?: LinkedInMiniCompany;
}

export const VECTOR_IMAGE_TYPE = `${COMMON_PREFIX}.VectorImage` as const;
export interface LinkedInVectorImage {
  $type: typeof VECTOR_IMAGE_TYPE;
  artifacts: LinkedInVectorArtifact[];
  rootUrl: string;
}

export const VECTOR_ARTIFACT_TYPE = `${COMMON_PREFIX}.VectorArtifact` as const;
export interface LinkedInVectorArtifact {
  $type: typeof VECTOR_ARTIFACT_TYPE;
  expiresAt: number;
  fileIdentifyingUrlPathSegment: string;
  height: number;
  with: number;
}

export const AUDIO_METADATA_TYPE =
  `${VOYAGER_COMMON_PREFIX}.AudioMetadata` as const;

export interface LinkedInAudioMetadata {
  $type: typeof AUDIO_METADATA_TYPE;
  mediaType: 'AUDIO';
  audioMetadata: {
    duration: number;
    url: string;
    urn: DigitalMediaAssetUrn;
  } | null;
}

export const FILE_METADATA_TYPE = `${VOYAGER_COMMON_PREFIX}.File` as const;

// there are more types here, e.g. for documents but I don't have the time to get them all
export type LinkedInMediaType = `image/${LinkedInImageType}` | string;

// potentially more types here
export type LinkedInImageType = 'webp' | 'png' | 'jpg';

export interface LinkedInFileMetadata {
  $type: typeof FILE_METADATA_TYPE;
  byteSize: number;
  id: DigitalMediaAssetUrn;
  mediaType: LinkedInMediaType;
  name: string;
  /**
   * This is a URL to the file
   */
  reference: string;
}

export const LinkedInMemberDistanceType = `${VOYAGER_COMMON_PREFIX}.MemberDistance`;

export interface LinkedInMemberDistance {
  $type: typeof LinkedInMemberDistanceType;
  value: string;
}

export const LOCALE_TYPE = `${COMMON_PREFIX}.Locale` as const;

export interface LinkedInLocale {
  $type: typeof LOCALE_TYPE;
  $anti_abuse_annotations: {
    attributeId: number;
    entityId: number;
  }[];
  country: Country;
  language: string;
}

export const TIME_RANGE_TYPE = `${VOYAGER_COMMON_PREFIX}.TimeRange` as const;

export interface LinkedInTimeRange {
  $type: typeof TIME_RANGE_TYPE;
  end: number;
}

export const DASH_INDUSTRY_TYPE = `${DASH_COMMON_PREFIX}.Industry` as const;

export interface LinkedInIndustry {
  $type: typeof DASH_INDUSTRY_TYPE;
  entityUrn: DashIndustryUrn;
  name: string;
}

export const DASH_REGION_TYPE = `${DASH_COMMON_PREFIX}.Region` as const;

export interface LinkedInRegion {
  $type: typeof DASH_REGION_TYPE;
  entityUrn: DashRegionUrn;
}

export type MultiLocaleString = Record<string, string>;

export type MultiLocaleAudio = Record<string, LinkedInAudioMetadata>;

export const DATE_RANGE_TYPE = `${VOYAGER_COMMON_PREFIX}.DateRange` as const;

export interface LinkedInDateRange {
  $type: typeof DATE_RANGE_TYPE;
  start: LinkedInDate;
  end?: LinkedInDate;
}

export const DATE_TYPE = `${VOYAGER_COMMON_PREFIX}.Date` as const;

export interface LinkedInDate {
  $type: typeof DATE_TYPE;
  year?: number;
  month?: number;
  day?: number;
}

export const INTEGER_RANGE_TYPE =
  `${VOYAGER_COMMON_PREFIX}.IntegerRange` as const;

export interface LinkedInIntegerRange {
  $type: typeof INTEGER_RANGE_TYPE;
  start: number;
  end: number;
}

export const GEO_TYPE = `${DASH_COMMON_PREFIX}.Geo` as const;

export interface LinkedInGeo {
  '$type': typeof GEO_TYPE;
  'entityUrn': DashGeoUrn;
  'countryUrn'?: DashGeoUrn;
  'defaultLocalizedNameWithoutCountryName'?: string;
  '*country': DashGeoUrn;
  'defaultLocalizedName'?: string;
}

export const RING_STATUS_TYPE = `${VOYAGER_COMMON_PREFIX}.RingStatus` as const;

export interface LinkedInRingStatus {
  $type: typeof RING_STATUS_TYPE;
  dashEntityUrn: DashRingStatusUrn;
  entityUrn: RingStatusUrn;
  emphasized: boolean;
  ringContentType: 'PROFILE_VIDEO';
  actionTarget: string;
}

export const FOLLOWING_INFO_TYPE =
  `${VOYAGER_COMMON_PREFIX}.FollowingInfo` as const;

export interface LinkedInFollowingInfo {
  $type: typeof FOLLOWING_INFO_TYPE;
  entityUrn: FollowingInfoUrn;
  dashFollowingStateUrn: DashFollowingStateUrn;
  following: boolean;
  trackingUrn: ContentSeriesUrn;
  followingType: 'DEFAULT';
  followerCount: number;
  followingCount: number | null;
}

export const RING_STATUS_TYPE_PREFIX =
  `${VOYAGER_COMMON_PREFIX}.RingStatus` as const;

export interface LinkedInRingStatus {
  $type: typeof RING_STATUS_TYPE_PREFIX;
  dashEntityUrn: DashRingStatusUrn;
  entityUrn: RingStatusUrn;
  emphasized: boolean;
  ringContentType: 'PROFILE_VIDEO';
  actionTarget: string;
}
