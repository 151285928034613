import { Close, Download } from '@mui/icons-material';
import { Backdrop, Box, IconButton } from '@mui/material';

export interface FullscreenImageProps {
  open: boolean;
  src: string;
  onClose?: () => void;
}

export default function FullscreenImage(props: FullscreenImageProps) {
  return (
    <Backdrop
      open={props.open}
      sx={{
        zIndex: 1000,
        p: 2,
        cursor: props.onClose ? 'pointer' : undefined
      }}
      onClick={() => props.onClose?.()}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          display: 'flex',
          justifyContent: 'flex-end',
          background: (theme) => theme.palette.background.default,
          borderBottomLeftRadius: (theme) => theme.shape.borderRadius,
          cursor: 'default'
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}>
        <IconButton
          onClick={() => {
            const a = document.createElement('a');
            a.href = props.src;
            a.download = 'image.jpeg';

            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }}>
          <Download />
        </IconButton>
        {props.onClose && (
          <IconButton onClick={() => props.onClose?.()}>
            <Close />
          </IconButton>
        )}
      </Box>
      <Box
        component="img"
        src={props.src}
        style={{
          height: '100%',
          width: '100%',
          maxWidth: '100%',
          objectFit: 'contain',
          objectPosition: 'center center'
        }}
      />
    </Backdrop>
  );
}
