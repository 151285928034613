import { Box, BoxProps } from '@mui/material';

export interface HUDSidePanelProps extends BoxProps {}

export default function HUDSidePanel(props: HUDSidePanelProps) {
  return (
    <Box
      {...props}
      sx={{
        position: 'fixed',
        zIndex: 10,
        right: (theme) => theme.spacing(3),
        top: 0,
        width: (theme) => theme.spacing(34),
        height: '100%',
        boxSizing: 'border-box',
        px: 1,
        pt: 13,
        pb: 6,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        overflow: 'auto',
        ...props.sx
      }}
    />
  );
}
