import React from 'react';
import { Box } from '@mui/material';

export interface HUDBottomProps {
  bottomContent?: React.ReactNode;
}

export default function HUDBottom(props: HUDBottomProps) {
  const { bottomContent } = props;
  const mainHeight = 40;
  const mainSideWidth = 60;

  return (
    <Box
      sx={{
        zIndex: 11,
        position: 'fixed',
        bottom: 0,
        width: '100%',
        height: mainHeight + 'px',
        background: 'transparent'
      }}>
      <Box
        sx={{
          'position': 'relative',
          'width': `calc(90% - ${mainSideWidth * 2}px)`,
          'height': '100%',
          'margin': 'auto',
          'background': (theme) => theme.palette.hud.main,
          'borderTopLeftRadius': (theme) => theme.spacing(8),
          'borderTopRightRadius': (theme) => theme.spacing(8),
          '&:after': {
            display: 'block',
            content: '" "',
            width: `${mainSideWidth * 2}px`,
            height: 0,
            background: (theme) => theme.palette.hud.main,
            borderBottomColor: (theme) => theme.palette.hud.main,
            borderRightColor: (theme) => theme.palette.hud.main,
            borderLeftColor: 'transparent',
            borderTopColor: 'transparent',
            borderStyle: 'solid',
            borderBottomWidth: mainSideWidth + 'px',
            borderRightWidth: 0 + 'px',
            borderLeftWidth: mainSideWidth * 0.6 + 'px',
            borderTopWidth: mainSideWidth * 1.3 + 'px',
            borderTopRightRadius: '143px',
            position: 'absolute',
            transform: `rotate(${7}deg)`,
            right: '-113px',
            top: `${9}px`
          },
          '&:before': {
            display: 'block',
            content: '" "',
            width: `${mainSideWidth * 2}px`,
            height: 0,
            background: (theme) => theme.palette.hud.main,
            borderLeftColor: (theme) => theme.palette.hud.main,
            borderTopColor: (theme) => theme.palette.hud.main,
            borderBottomColor: 'transparent',
            borderRightColor: 'transparent',
            borderStyle: 'solid',
            borderBottomWidth: mainSideWidth + 'px',
            borderRightWidth: mainSideWidth * 0.6 + 'px',
            borderLeftWidth: 0 + 'px',
            borderTopWidth: mainSideWidth * 1.3 + 'px',
            borderTopRightRadius: '143px',
            position: 'absolute',
            transform: `rotate(${270}deg)`,
            left: '-113px',
            top: `${9}px`
          }
        }}>
        {bottomContent}
      </Box>
    </Box>
  );
}
