/**
 * A class that implements the AsyncIterator interface and iterates over all conversations in a LinkedIn account
 * filtering them by a given date before which they were created
 */
export abstract class CreatedBeforeIterator<T extends CreatedBeforeResponse>
  implements AsyncIterator<Iterable<T>, Iterable<T>>
{
  /**
   * The date before which the conversations were created
   * @protected the date before which the conversations were created
   */
  protected createdBefore: Date;

  /**
   * The date before which the conversations were created in the previous page
   * @private the date before which the conversations were created in the previous page
   */
  private prevCreatedBefore: Date;

  /**
   * The indices of the pages
   * @private the indices of the pages
   */
  private pageIndices: Date[] = [];

  /**
   * Function that fetches the next Iterable of Items
   * @protected the function that fetches the next Iterable of Items
   */
  protected abstract fetch(): Promise<Iterable<T>>;

  /**
   * Creates a new CreatedBeforeIterator
   * @param createdBefore the date before which the conversations were created
   * @protected creates a new CreatedBeforeIterator
   */
  protected constructor({
    createdBefore = new Date()
  }: {
    createdBefore?: Date;
  }) {
    this.createdBefore = createdBefore;
    this.prevCreatedBefore = new Date(createdBefore.valueOf() + 1000);
  }

  /**
   * Fetches the next page of conversations and advances the iterator
   * @returns the next page of conversations
   */
  async next(): Promise<IteratorResult<T[], T[]>> {
    const res = [...(await this.fetch().catch(() => []))];

    this.pageIndices.push(this.prevCreatedBefore);

    if (res.length === 0) {
      return { done: true, value: [] };
    }
    this.prevCreatedBefore = this.createdBefore;
    this.createdBefore = new Date(res[res.length - 1].createdBefore);

    return { done: false, value: res };
  }

  /**
   * Fetches the previous page of conversations and rewinds the iterator
   * @returns the previous page of conversations
   */
  async prev(): Promise<IteratorResult<T[], T[]>> {
    if (this.pageIndices.length === 0) {
      return { done: true, value: [] };
    }

    this.createdBefore = this.pageIndices.pop() ?? new Date(Infinity);

    const res = [...(await this.fetch().catch(() => []))];
    return { done: res.length === 0, value: res };
  }

  /**
   * Restarts the iterator
   */
  restart(): void {
    this.createdBefore = new Date();
    this.prevCreatedBefore = new Date(this.createdBefore.valueOf() + 1000);
    this.pageIndices = [];
  }
}

export interface CreatedBeforeResponse {
  createdBefore: number;
}
