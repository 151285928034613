import { MessageTemplate } from '@common/types/ApiTypes';
import { Box, Button } from '@mui/material';

export interface ChatTemplatesProps {
  templates?: MessageTemplate[];
  selectedTemplate: MessageTemplate['variantID'] | null;
  onSelectTemplate: (template: MessageTemplate) => void;
}

export default function ChatTemplates(props: ChatTemplatesProps) {
  if (!props.templates || props.templates.length === 0) return null;
  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      overflow="auto"
      flexWrap="wrap"
      py={1}
      px={2}
      gap={1}
      boxSizing="border-box"
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`
      }}>
      {props.templates.map((template) => (
        <Button
          key={template.variantID}
          variant={
            props.selectedTemplate === template.variantID
              ? 'contained'
              : 'outlined'
          }
          color="primary"
          onClick={() => {
            props.onSelectTemplate(template);
          }}>
          {template.name}
        </Button>
      ))}
    </Box>
  );
}
