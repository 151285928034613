import React, { RefObject } from 'react';

function useEventListener<K extends keyof HTMLElementEventMap>(
  event: K,
  handler: (this: HTMLElement, ev: HTMLElementEventMap[K]) => unknown,
  ref?: RefObject<HTMLElement>
) {
  React.useEffect(() => {
    const element = ref?.current;
    if (!element) return;

    element.addEventListener(event, handler);

    return () => {
      element.removeEventListener(event, handler);
    };
  }, [event, handler, ref]);
}

export default useEventListener;
