import React from 'react';
import { Box } from '@mui/material';
import AvaModal, { AvaModalProps } from './AvaModal';
import { AvaContextProvider } from './AvaContext';
import { AvaIcon } from 'ui-utils';

export interface AvaProps
  extends Omit<
    AvaModalProps,
    'open' | 'onClose' | 'avaHeight' | 'trackHeight'
  > {
  avaRef?: React.RefObject<HTMLElement | undefined>;
  hide?: boolean;
}

export default function Ava(props: AvaProps) {
  const { ...avaModalProps } = props;

  const [open, setOpen] = React.useState(false);
  const [modalHeight, setModalHeight] = React.useState(0);

  const visible = React.useMemo(() => {
    return React.Children.count(props.children) > 0 && !props.hide;
  }, [props.children, props.hide]);

  return (
    <>
      <Box
        ref={props.avaRef}
        onClick={() => setOpen(!open)}
        sx={{
          display: visible ? 'flex' : 'none',
          width: 'auto',
          height: 196,
          position: 'fixed',
          bottom: 0,
          left: 0,
          alignItems: 'flex-end',
          zIndex: open ? 1500 : 20,
          cursor: 'pointer'
        }}>
        <AvaIcon width="auto" />
      </Box>
      <AvaContextProvider
        modalHeight={modalHeight}
        setModalHeight={setModalHeight}>
        <AvaModal
          avaHeight={196}
          open={open}
          onClose={() => setOpen(false)}
          trackHeight={setModalHeight}
          {...avaModalProps}
        />
      </AvaContextProvider>
    </>
  );
}
