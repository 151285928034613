import React from 'react';
import { useSetup } from './SetupContext';
import { Box } from '@mui/material';
import Ava from '@/components/Ava/Ava';
import { Loading } from '@/components/Loading/LoadingTypes';
import HUDTitle from '@/components/HUD/HUDTitle';
import HUD from '@/components/HUD/HUD';
import { SetupStepHandler } from './setupSteps';
import { language } from '@/index';
import { QuoteLoading, RandomAnimatedLoading } from 'ui-utils';
import { useFeatureFlags } from '@/globals/featureFlags';

export interface SetupFrameProps {
  children: React.ReactNode;
}

export default function SetupFrame(props: SetupFrameProps) {
  const { currentStep, loading } = useSetup();
  const { show_ava } = useFeatureFlags();

  const avaContent = React.useMemo(() => {
    if (!currentStep) return undefined;
    switch (currentStep.handler) {
      case 'term':
        return language.text.ava_hint_setup_term;
      case 'url':
        return language.text.ava_hint_setup_survey;
      default:
        return undefined;
    }
  }, [currentStep]);

  const fullSize = React.useMemo(() => {
    if (!currentStep) return false;
    return (['ava-tutorial', 'term'] as SetupStepHandler[]).includes(
      currentStep.handler
    );
  }, [currentStep]);

  const hideHUD = React.useMemo(() => {
    if (loading === Loading.LONG) return true;
    if (!currentStep) return false;
    return currentStep.handler === 'loading';
  }, [currentStep, loading]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
      {show_ava && <Ava>{avaContent}</Ava>}
      <RandomAnimatedLoading show={loading === Loading.SHORT} />
      <QuoteLoading show={loading === Loading.LONG} />
      <HUD
        hideBottom={hideHUD}
        hideTop={hideHUD}
        topContent={
          currentStep?.header ? (
            <HUDTitle
              title={currentStep.header}
              subtitle={currentStep.subtitle}
            />
          ) : undefined
        }
      />
      <Box
        sx={{
          pt: 12,
          pb: fullSize ? 0 : 5,
          mx: 'auto',
          overflow: 'overlay',
          flexGrow: 1,
          width: fullSize ? '100%' : 'calc(100% - 180px)'
        }}>
        {props.children}
      </Box>
    </Box>
  );
}
