import { Box, BoxProps, Typography } from '@mui/material';
import { PlayCircleOutline } from '@mui/icons-material';
import { isYouTubeVideo } from './VideoHelper';

export interface GenericVideoThumbProps extends BoxProps {
  videoID: string;
}

export default function GenericVideoThumb(props: GenericVideoThumbProps) {
  const { videoID, ...rest } = props;

  return (
    <Box
      {...rest}
      sx={{
        'cursor': 'pointer',
        'borderRadius': 1,
        'transition': (theme) => theme.transitions.create(['box-shadow']),
        '&:hover': {
          boxShadow: (theme) => theme.shadows[3]
        },
        ...rest.sx
      }}>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: 'auto'
        }}>
        <Box
          component="img"
          sx={{
            width: '100%',
            height: 'auto',
            borderRadius: 1,
            filter: 'brightness(0.5)'
          }}
          src={
            isYouTubeVideo(videoID)
              ? `http://i3.ytimg.com/vi/${videoID}/hqdefault.jpg`
              : `https://vz-4a35cc50-984.b-cdn.net/${videoID}/thumbnail.jpg`
          }
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            borderRadius: 1
          }}>
          <PlayCircleOutline
            sx={{
              color: '#fff',
              width: (theme) => theme.spacing(8),
              height: (theme) => theme.spacing(8)
            }}
          />
        </Box>
      </Box>
      <Typography
        sx={{
          my: 1,
          textAlign: 'center',
          fontWeight: 'bold'
        }}>
        {props.title}
      </Typography>
    </Box>
  );
}
