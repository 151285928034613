import { Client } from '../client/Client';
import {
  ConversationIterator,
  ConversationV2Iterator
} from '../iterator/ConversationIterator';
import { ConversationRequest } from '../requests/ConversationRequest';
import {
  CONVERSATION_TYPE,
  ConversationId,
  ConversationUrn,
  DashProfileUrn,
  EVENT_TYPE,
  EventUrn,
  GetCategoryConversationResponse,
  GetConversationResponse,
  GetConversationsResponse,
  GetKeywordConversationsResponse,
  LinkedInConversation,
  LinkedInConversationCategory,
  LinkedInEvent,
  LinkedInMessage,
  LinkedInMessagingMember,
  LinkedInMessagingParticipant,
  LinkedInMessengerConversation,
  LinkedInMiniProfile,
  MessageConversationUrn,
  MessageMessageUrn,
  MessageMessagingParticipantUrn,
  MESSAGING_MEMBER_TYPE,
  MessagingMemberUrn,
  MESSENGER_CONVERSATION_TYPE,
  MESSENGER_MESSAGE_TYPE,
  MESSENGER_MESSAGING_PARTICIPANT_TYPE,
  MINI_PROFILE_TYPE,
  MiniProfileUrn
} from 'linkedin-domain-types';
import { Options, trace } from '../client/Utils';

@trace()
export class ConversationService {
  private client: Client;
  private conversationRequests: ConversationRequest;
  constructor(client: Client) {
    this.client = client;
    this.conversationRequests = new ConversationRequest({
      requestService: client.requestService
    });
  }

  /**
   * Creates an iterator that iterates over all conversations in a LinkedIn account returning them as single entities
   * @param recipients the recipients to filter by (does currently not work)
   * @param createdBefore the date to filter by
   * @param options the options to use for the request
   */
  getConversations({
    recipients,
    createdBefore,
    options
  }: {
    recipients?: string | string[];
    createdBefore?: Date;
    options: Options;
  }): ConversationIterator {
    return new ConversationIterator({
      fetchConversations: this.fetchConversations.bind(this),
      recipients,
      createdBefore,
      options
    });
  }

  /**
   * Returns conversations as paginated data
   * @param recipients the recipients to filter by (does currently not work)
   * @param createdBefore the date to filter by
   * @param options the options to use for the request
   */
  getConversationPage({
    recipients,
    createdBefore,
    options
  }: {
    recipients?: string | string[];
    createdBefore?: Date;
    options: Options;
  }): Promise<Iterable<LinkedInConversation>> {
    return this.fetchConversations({
      recipients,
      createdBefore,
      options
    });
  }

  /**
   * Searches for conversations with a given keyword
   * @param keyword the keyword to search for
   * @param categories the categories to search in
   * @param mailboxUrn the mailbox to search in
   * @param nextCursor the cursor to continue from
   * @param options the options to use for the request
   */
  getConversationsWithKeyword({
    keyword,
    categories,
    mailboxUrn,
    nextCursor,
    options
  }: {
    mailboxUrn: DashProfileUrn;
    keyword: string;
    categories?: LinkedInConversationCategory[];
    nextCursor?: string;
    options: Options;
  }): ConversationV2Iterator {
    return new ConversationV2Iterator({
      fetchConversations: this.fetchConversationsWithKeyword.bind(this),
      mailboxUrn,
      keyword,
      categories,
      nextCursor,
      options
    });
  }

  /**
   * Searches for conversations with a given keyword
   * @param keyword the keyword to search for
   * @param categories the categories to search in
   * @param mailboxUrn the mailbox to search in
   * @param nextCursor the cursor to continue from
   * @param options the options to use for the request
   */
  getConversationPageWithKeyword({
    keyword,
    categories,
    mailboxUrn,
    nextCursor,
    options
  }: {
    mailboxUrn: DashProfileUrn;
    keyword: string;
    categories?: LinkedInConversationCategory[];
    nextCursor?: string;
    options: Options;
  }) {
    return this.fetchConversationsWithKeyword({
      mailboxUrn,
      keyword,
      categories,
      nextCursor,
      options
    });
  }

  /**
   * Gets all conversations in a given category from a given mailbox
   * @param mailboxUrn the mailbox to search in
   * @param category the category to search in
   * @param nextCursor the cursor to continue from
   * @param count the number of conversations to fetch
   * @param options the options to use for the request
   */
  getConversationsInCategory({
    mailboxUrn,
    category,
    nextCursor,
    count,
    options
  }: {
    mailboxUrn: DashProfileUrn;
    category: LinkedInConversationCategory;
    nextCursor?: string;
    count?: number;
    options: Options;
  }): ConversationV2Iterator {
    return new ConversationV2Iterator({
      fetchConversations: this.fetchConversationsInCategory.bind(this),
      keyword: '',
      mailboxUrn,
      categories: [category],
      nextCursor,
      count,
      options
    });
  }

  /**
   * Gets all conversations in a given category from a given mailbox
   * @param mailboxUrn the mailbox to search in
   * @param category the category to search in
   * @param nextCursor the cursor to continue from
   * @param count the number of conversations to fetch
   * @param options the options to use for the request
   */
  getConversationPageInCategory({
    mailboxUrn,
    category,
    nextCursor,
    count,
    options
  }: {
    mailboxUrn: DashProfileUrn;
    category: LinkedInConversationCategory;
    nextCursor?: string;
    count?: number;
    options: Options;
  }) {
    return this.fetchConversationsInCategory({
      mailboxUrn,
      categories: [category],
      nextCursor,
      options
    });
  }

  /**
   * Fetches conversations from LinkedIn API and returns the first conversation including the given participant in either:
   *   - the publicIdentifier,
   *   - the entityUrn,
   *   - the miniProfile.entityUrn,
   *   - the alternateName,
   *   - or the full name
   * @param participant the participant to search for
   * @param searchFirst the number of conversations to search through (default: 100)
   * @param options the options to use for the request
   */
  async getConversationByParticipant(
    participant: string,
    searchFirst = 100,
    options: Options
  ): Promise<LinkedInConversation> {
    return this.findConversationBy(
      (c) => {
        return (
          c.participants.find((p) => {
            if (!p.miniProfile) return false;
            if (p.miniProfile.publicIdentifier.includes(participant))
              return true;
            if (p.miniProfile.entityUrn.includes(participant)) return true;
            if (p.entityUrn.includes(participant)) return true;
            if (p.alternateName?.includes(participant)) return true;
            if (
              `${p.miniProfile.firstName} ${p.miniProfile.lastName}`.includes(
                participant
              )
            )
              return true;
            return false;
          }) !== undefined
        );
      },
      searchFirst,
      options
    );
  }

  /**
   * Fetches conversations from LinkedIn API and returns the first conversation matching the given predicate
   * @param predicate the predicate to match
   * @param searchFirst the number of conversations to search through (default: 100)
   * @param options the options to use for the request
   */
  async findConversationBy(
    predicate: (c: LinkedInConversation) => boolean,
    searchFirst = 100,
    options: Options
  ): Promise<LinkedInConversation> {
    const res = this.getConversations({ options });
    for (let i = 0; i < searchFirst; i++) {
      const conversation = await res.next();
      if (conversation.done) break;
      const c = conversation.value;
      if (predicate(c)) {
        return c;
      }
    }
    return Promise.reject();
  }

  /**
   * Fetches a conversation from LinkedIn API and returns it
   * @param conversationId the conversation id of the conversation to fetch
   * @param options the options to use for the request
   */
  async getConversation({
    conversationId,
    options
  }: {
    conversationId: ConversationId;
    options: Options;
  }): Promise<LinkedInConversation | null> {
    const res = await this.conversationRequests.getConversation(
      conversationId,
      options
    );
    if (!res) return null;
    return ConversationService.parseGetConversationResponse(res);
  }

  /**
   * Sets the conversation to read or unread
   * @param conversationId the conversation id of the conversation to mark as read
   * @param state the read state to set the conversation to (default: true)
   * @param options the options to use for the request
   */
  async markConversationAsRead({
    conversationId,
    state = true,
    options
  }: {
    conversationId: ConversationId;
    state?: boolean;
    options: Options;
  }): Promise<void> {
    await this.conversationRequests.markConversationAsRead(
      conversationId,
      state,
      options
    );
  }

  /**
   * Sets or removes a category from conversations
   *
   * @param conversationIds the conversation ids of the conversations to set the category for
   * @param category the category to set
   * @param state whether to set or remove the category
   * @param options the options to use for the request
   * @returns `true` if the request was successful, `false` otherwise
   */
  async setConversationCategory({
    conversationUrns,
    category,
    state,
    options
  }: {
    conversationUrns: MessageConversationUrn[];
    category: LinkedInConversationCategory;
    state: 'addCategory' | 'removeCategory';
    options: Options;
  }): Promise<boolean> {
    return this.conversationRequests.setCategoryForConversations(
      conversationUrns,
      category,
      state,
      options
    );
  }

  /**
   * Fetches conversations from LinkedIn API and parses them into an Iterable of LinkedInConversation
   * @param recipients the recipients to filter by (does currently not work)
   * @param createdBefore the date to filter by
   * @param options the options to use for the request
   * @private
   */
  private async fetchConversations({
    recipients,
    createdBefore = new Date(),
    options
  }: {
    recipients?: string | string[];
    createdBefore?: Date;
    options: Options;
  }): Promise<Iterable<LinkedInConversation>> {
    const res = await this.conversationRequests.getConversations({
      recipients,
      createdBefore,
      options
    });
    if (!res) return [];
    return ConversationService.parseGetConversationsResponse(res).values();
  }

  private async fetchConversationsWithKeyword({
    mailboxUrn,
    keyword,
    categories,
    nextCursor,
    options
  }: {
    mailboxUrn: DashProfileUrn;
    keyword: string;
    categories?: LinkedInConversationCategory[];
    nextCursor?: string;
    options: Options;
  }): Promise<{
    nextCursor: string;
    conversations: IterableIterator<LinkedInMessengerConversation>;
  }> {
    const res = await this.conversationRequests.getConversationsWithKeyword({
      mailboxUrn,
      keyword,
      categories,
      nextCursor,
      options
    });
    if (!res) return { conversations: [].values(), nextCursor: '' };
    return {
      conversations:
        ConversationService.parseGetConversationsWithKeywordResponse(
          res
        ).values(),
      nextCursor:
        res.data.data.messengerConversationsBySearchCriteria.metadata.nextCursor
    };
  }

  private async fetchConversationsInCategory({
    mailboxUrn,
    categories,
    nextCursor,
    options
  }: {
    mailboxUrn: DashProfileUrn;
    categories?: LinkedInConversationCategory[];
    nextCursor?: string;
    options: Options;
  }): Promise<{
    nextCursor: string;
    conversations: IterableIterator<LinkedInMessengerConversation>;
  }> {
    if (!categories || categories.length === 0) {
      return { conversations: [].values(), nextCursor: '' };
    }
    const res = await this.conversationRequests.getConversationsWithCategory({
      mailboxUrn,
      category: categories[0],
      nextCursor,
      options
    });
    if (!res) return { conversations: [].values(), nextCursor: '' };
    return {
      conversations:
        ConversationService.parseGetConversationsWithKeywordResponse(
          res
        ).values(),
      nextCursor:
        res.data.data.messengerConversationsByCategory.metadata.nextCursor
    };
  }

  /**
   * Parses the response from the LinkedIn API into a LinkedInConversation array
   * @param res the response from the LinkedIn API
   * @private this method is only used internally
   */
  public static parseGetConversationsResponse(
    res: GetConversationsResponse
  ): LinkedInConversation[] {
    const mapping: {
      CONVERSATION_TYPE: Map<ConversationUrn, LinkedInConversation>;
      MINI_PROFILE_TYPE: Map<MiniProfileUrn, LinkedInMiniProfile>;
      EVENT_TYPE: Map<EventUrn, LinkedInEvent>;
      MESSAGING_MEMBER_TYPE: Map<MessagingMemberUrn, LinkedInMessagingMember>;
    } = {
      CONVERSATION_TYPE: new Map(),
      MINI_PROFILE_TYPE: new Map(),
      EVENT_TYPE: new Map(),
      MESSAGING_MEMBER_TYPE: new Map()
    };

    res.included.forEach((e) => {
      // just for typeScript...
      if (e.$type === CONVERSATION_TYPE) {
        mapping.CONVERSATION_TYPE.set(e.entityUrn, e);
      } else if (e.$type === MINI_PROFILE_TYPE) {
        mapping.MINI_PROFILE_TYPE.set(e.entityUrn, e);
      } else if (e.$type === EVENT_TYPE) {
        mapping.EVENT_TYPE.set(e.entityUrn, e);
      } else if (e.$type === MESSAGING_MEMBER_TYPE) {
        mapping.MESSAGING_MEMBER_TYPE.set(e.entityUrn, e);
      }
    });

    mapping.MESSAGING_MEMBER_TYPE.forEach((member) => {
      const miniProfile = mapping.MINI_PROFILE_TYPE.get(member['*miniProfile']);
      if (miniProfile) member.miniProfile = miniProfile;
    });

    mapping.EVENT_TYPE.forEach((event) => {
      const from = mapping.MESSAGING_MEMBER_TYPE.get(event['*from']);
      if (from) event.from = from;
      event.eventId = event.entityUrn.split(':').pop() ?? '';
    });

    mapping.CONVERSATION_TYPE.forEach((conversation) => {
      conversation.events = conversation['*events']
        .map((e) => {
          return mapping.EVENT_TYPE.get(e);
        })
        .filter((e) => e)
        .map((e) => e as LinkedInEvent)
        .sort((a, b) => {
          return b.createdAt - a.createdAt;
        })
        .values();

      conversation.participants = conversation['*participants']
        .map((p) => mapping.MESSAGING_MEMBER_TYPE.get(p))
        .filter((p) => p !== undefined) as LinkedInMessagingMember[];

      conversation.conversationId =
        conversation.entityUrn.split(':').pop() ?? '';

      conversation.createdBefore = conversation.lastActivityAt;
    });
    return [...mapping.CONVERSATION_TYPE.values()].sort((a, b) => {
      return b.lastActivityAt - a.lastActivityAt;
    });
  }

  /**
   * Parses the response from the LinkedIn API into a LinkedInConversation
   *
   * @param response the response from the LinkedIn API
   */
  public static parseGetConversationResponse(
    response: GetConversationResponse
  ): LinkedInConversation {
    const mapping: {
      MINI_PROFILE_TYPE: Map<MiniProfileUrn, LinkedInMiniProfile>;
      MESSAGING_MEMBER_TYPE: Map<MessagingMemberUrn, LinkedInMessagingMember>;
    } = {
      MINI_PROFILE_TYPE: new Map(),
      MESSAGING_MEMBER_TYPE: new Map()
    };

    response.included.forEach((e) => {
      if (e.$type === MINI_PROFILE_TYPE) {
        mapping.MINI_PROFILE_TYPE.set(e.entityUrn, e);
      } else if (e.$type === MESSAGING_MEMBER_TYPE) {
        mapping.MESSAGING_MEMBER_TYPE.set(e.entityUrn, e);
      }
    });

    mapping.MESSAGING_MEMBER_TYPE.forEach((member) => {
      const miniProfile = mapping.MINI_PROFILE_TYPE.get(member['*miniProfile']);
      if (miniProfile) member.miniProfile = miniProfile;
    });

    response.data.participants = response.data['*participants']
      .map((p) => mapping.MESSAGING_MEMBER_TYPE.get(p))
      .filter((p) => p !== undefined) as LinkedInMessagingMember[];

    response.data.events = [].values();
    response.data.createdBefore = response.data.lastActivityAt;
    response.data.conversationId =
      response.data.entityUrn.split(':').pop() ?? '';

    return response.data;
  }

  public static parseGetConversationsWithKeywordResponse(
    response: GetKeywordConversationsResponse | GetCategoryConversationResponse
  ): LinkedInMessengerConversation[] {
    const mapping: {
      MESSENGER_CONVERSATION_TYPE: Map<
        MessageConversationUrn,
        LinkedInMessengerConversation
      >;
      MESSENGER_MESSAGE_TYPE: Map<MessageMessageUrn, LinkedInMessage>;
      MESSENGER_MESSAGING_PARTICIPANT_TYPE: Map<
        MessageMessagingParticipantUrn,
        LinkedInMessagingParticipant
      >;
    } = {
      MESSENGER_CONVERSATION_TYPE: new Map(),
      MESSENGER_MESSAGE_TYPE: new Map(),
      MESSENGER_MESSAGING_PARTICIPANT_TYPE: new Map()
    };

    response.included.forEach((e) => {
      if (e.$type === MESSENGER_CONVERSATION_TYPE) {
        mapping.MESSENGER_CONVERSATION_TYPE.set(e.entityUrn, e);
      } else if (e.$type === MESSENGER_MESSAGE_TYPE) {
        mapping.MESSENGER_MESSAGE_TYPE.set(e.entityUrn, e);
      } else if (e.$type === MESSENGER_MESSAGING_PARTICIPANT_TYPE) {
        mapping.MESSENGER_MESSAGING_PARTICIPANT_TYPE.set(e.entityUrn, e);
      }
    });

    mapping.MESSENGER_MESSAGE_TYPE.forEach((message) => {
      const sender = mapping.MESSENGER_MESSAGING_PARTICIPANT_TYPE.get(
        message['*sender']
      );
      if (sender) message.sender = sender;
      const conversation = mapping.MESSENGER_CONVERSATION_TYPE.get(
        message['*conversation']
      );
      if (conversation) message.conversation = conversation;
    });

    mapping.MESSENGER_CONVERSATION_TYPE.forEach((conversation) => {
      conversation.conversationParticipants = conversation[
        '*conversationParticipants'
      ]
        .map((p) => mapping.MESSENGER_MESSAGING_PARTICIPANT_TYPE.get(p))
        .filter((p) => p) as LinkedInMessagingParticipant[];
      if ('*elements' in conversation.messages) {
        conversation.lastMessages = conversation.messages['*elements']
          .map((e) => mapping.MESSENGER_MESSAGE_TYPE.get(e))
          .filter((e) => e)
          .map((e) => e as LinkedInMessage)
          .sort((a, b) => b.deliveredAt - a.deliveredAt);
      }
      const creator = mapping.MESSENGER_MESSAGING_PARTICIPANT_TYPE.get(
        conversation['*creator']
      );
      if (creator) conversation.creator = creator;
    });

    return [...mapping.MESSENGER_CONVERSATION_TYPE.values()].sort((a, b) => {
      return b.lastActivityAt - a.lastActivityAt;
    });
  }
}
