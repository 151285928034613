import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';
import { language } from '@/index';
import React from 'react';
import posthog from 'posthog-js';
import { NumberInput, useTimer } from 'ui-utils';

export default function ZoomSettings() {
  const [zoom, setZoom] = React.useState(
    Number(localStorage.getItem('zoom')) || 1
  );
  const lastZoom = React.useRef(zoom);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { remaining, restart, pause } = useTimer(10, {
    callback: () => {
      setDialogOpen(false);
      resetZoom();
    }
  });
  const confirmButtonRef = React.useRef<HTMLButtonElement>(null);

  function handleZoomConfirm() {
    localStorage.setItem('zoom', String(zoom));
    posthog.capture('$set', {
      $set: { zoom }
    });
    lastZoom.current = zoom;
    pause();
  }

  function resetZoom() {
    window.electron.setWindowZoom(lastZoom.current);
    setZoom(lastZoom.current);
  }

  //eslint-disable-next-line -- check if preload script is up to date and the function is available
  if (!window.electron.setWindowZoom) return null;

  return (
    <Box width="100%">
      <Typography variant="h4">{language.text.zoom}</Typography>
      <Box display="flex" gap={1}>
        <NumberInput
          variant="standard"
          value={zoom * 100}
          onChange={(num) => setZoom(num / 100)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              confirmButtonRef.current?.click();
            }
          }}
        />

        <Button
          ref={confirmButtonRef}
          onClick={() => {
            window.electron.setWindowZoom(zoom);
            restart();
            setDialogOpen(true);
          }}
          variant="outlined">
          {language.text.apply}
        </Button>
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          resetZoom();
          setDialogOpen(false);
        }}>
        <DialogTitle>{language.text.apply_zoom_settings}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {language.text.confirm_zoom_settings}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              resetZoom();
              setDialogOpen(false);
            }}
            color="primary"
            variant="outlined">
            {language.text.cancel} ({remaining})
          </Button>
          <Button
            onClick={() => {
              handleZoomConfirm();
              setDialogOpen(false);
            }}
            color="primary"
            variant="contained">
            {language.text.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
