import { Button, ButtonProps } from '@mui/material';
import React from 'react';
import PulsatingButton, { PulsatingButtonProps } from './PulsatingButton';

export type PulsatingRingButtonProps<
  Ele extends React.FunctionComponent<
    Pick<ButtonProps, 'sx'> & {
      onClick?: (...args: any[]) => any;
    }
  > = typeof Button
> = PulsatingButtonProps<Ele>;

const PulsatingRingButton = React.forwardRef(
  <
    Ele extends React.FunctionComponent<
      Pick<ButtonProps, 'sx'> & {
        onClick?: (...args: any[]) => any;
      }
    > = typeof Button
  >(
    props: PulsatingButtonProps<Ele>,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    return (
      <PulsatingButton
        ref={ref}
        {...{
          ...props,
          sx: {
            zIndex: 1,
            ...props.sx,
            ...((!('disabled' in props) ||
              !(props as { disabled: any }).disabled) && {
              '&:before': {
                'content': '""',
                'position': 'absolute',
                'top': '50%',
                'left': '50%',
                'height': 0,
                'width': 0,
                'borderRadius': '50%',
                'border': (theme) =>
                  `${theme.spacing(0.5)} solid ${theme.palette.primary.main}`,
                'transform': 'translate(-50%, -50%)',
                'animation': 'idle 1.5s infinite',
                'zIndex': -1,
                '@keyframes idle': {
                  '0%': {
                    width: 0,
                    height: 0,
                    opacity: 1
                  },
                  '100%': {
                    width: (theme) => theme.spacing(8),
                    height: (theme) => theme.spacing(8),
                    opacity: 0
                  }
                }
              }
            })
          }
        }}
      />
    );
  }
);

export default PulsatingRingButton;
