import React from 'react';
import { Box, Button, Popover, Typography } from '@mui/material';
import EstablishRelationTask from './EstablishRelationTask';
import ContactView from '../_general/ContactView/ContactView';
import { Mood, Person } from '@mui/icons-material';
import ContactChat from '../_general/ContactView/ContactChat';
import CategorySelector from '../_general/ContactView/CategorySelector';
import RelationshipMenu from '../_general/ContactView/RelationshipMenu';
import HUDSidePanel from '@/components/HUD/HUDSidePanel';
import HUDFocusBar from '@/components/HUD/HUDFocusBar';
import ContactDisplay from '../_general/ContactView/ContactDisplay';
import LinkedInWebview from '@common/Webview.renderer/Base/LinkedInWebview';
import { language } from '@/index';
import { Contact } from '@/data/Classes/Contact';
import { ContactType } from '@common/types/enums';
import { FancyButton, useEventEmitter } from 'ui-utils';
import { useScheduler } from 'focus-mode-scheduler';
import SkipUntilDialog from '../_general/SkipUntilDialog/SkipUntilDialog';

export interface EstablishRelationTaskUIProps {
  task: EstablishRelationTask;
}

export default function EstablishRelationTaskUI(
  props: EstablishRelationTaskUIProps
) {
  const { task } = props;
  const { complete, skip } = useScheduler();

  const [showDatePicker, setShowDatePicker] = React.useState(false);
  useEventEmitter(task, {
    'get-skip-until-date': () => {
      setShowDatePicker(true);
    }
  });

  const [page, setPage] = React.useState<'chat' | 'profile'>('chat');

  const [sideView, setSideView] = React.useState('pipeline_steps');

  const messageSend = React.useRef(false);

  const establishedRef = React.useRef<HTMLButtonElement>(null);
  const [showPopover, setShowPopover] = React.useState(false);

  return (
    <>
      <ContactView
        contact={task.contact}
        page={page}
        pages={[
          {
            id: 'chat',
            component: (
              <ContactChat
                key={'chat'}
                fillTemplateFromChat
                chat={task.chat}
                onSend={(message) => {
                  messageSend.current = true;
                  task.setCurrentMessage(message);
                  return complete({ itemsDone: 1 });
                }}
                hide={page !== 'chat'}
                templates={task.templates}
              />
            ),
            keepMounted: true
          },
          {
            keepMounted: true,
            id: 'profile',
            component: (
              <LinkedInWebview
                startURL={task.contact.profileURL}
                features={['focus-mode-style', 'hide-connect-button']}
              />
            )
          }
        ]}
        sx={{
          boxSizing: 'border-box',
          /* add spacing add bottom to make space between bottom bar buttons and textarea */
          pb: 2,
          // extra spacing due to sidebar
          mr: 18,
          width: 'unset'
        }}
      />
      <HUDSidePanel>
        <Box flex={1} />
        <ContactDisplay
          contact={task.chat.contact}
          onClick={() =>
            setPage((page) => (page === 'profile' ? 'chat' : 'profile'))
          }
        />
        {sideView === 'categorize' && (
          <CategorySelector
            defaultValue={ContactType.POTENTIAL_CUSTOMER}
            onFinished={async (result) => {
              if (result === ContactType.POTENTIAL_CUSTOMER)
                setSideView('pipeline_steps');
              else {
                await Contact.categorize(task.contact, result);
                task.omitSkipUntil();
                await skip('instance');
              }
            }}
          />
        )}
        {sideView === 'pipeline_steps' && (
          <RelationshipMenu
            goBackToCategorySelector={() => setSideView('categorize')}
            steps={task.steps}
            currentStep={task.activeStep}
            completeText={language.text.recategorize}
            onComplete={async (pipeline_step) => {
              await Contact.categorize(
                task.contact,
                ContactType.POTENTIAL_CUSTOMER,
                pipeline_step
              );
              task.omitSkipUntil();
              await skip('instance');
            }}
          />
        )}
        <Box flex={4} />
      </HUDSidePanel>
      <HUDFocusBar>
        <FancyButton
          key="profile"
          size="large"
          variant="contained"
          color="secondary"
          onClick={() =>
            setPage((page) => (page === 'profile' ? 'chat' : 'profile'))
          }
          sx={{
            minWidth: 250
          }}
          startIcon={<Person />}>
          {page === 'profile'
            ? language.text.close_profile
            : language.text.open_profile}
        </FancyButton>
        <Popover
          anchorEl={establishedRef.current}
          open={showPopover}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}>
          <Box p={1}>
            <Typography sx={{ maxWidth: 400, mb: 2 }}>
              {language.text.confirm_relation_established(
                task.contact.firstname
              )}
            </Typography>
            <Box display="flex" gap={2} justifyContent="flex-end">
              <Button
                color="primary"
                onClick={setShowPopover.bind(null, false)}>
                {language.text.cancel}
              </Button>
              <FancyButton
                variant="contained"
                color="primary"
                fType={{ promise: true }}
                onClick={() => {
                  task.setRelationEstablished(true);
                  return complete({ itemsDone: 1 });
                }}>
                {language.text.relation_established}
              </FancyButton>
            </Box>
          </Box>
        </Popover>
        <Button
          ref={establishedRef}
          key="skip"
          size="large"
          variant="contained"
          color="primary"
          sx={{
            minWidth: 250
          }}
          onClick={setShowPopover.bind(null, true)}
          startIcon={<Mood />}>
          {language.text.relation_established}
        </Button>
      </HUDFocusBar>

      <SkipUntilDialog
        onFinish={(date) =>
          task.emit('skip-until-date', { date, type: 'complete' })
        }
        contact={task.contact}
        open={showDatePicker}
        onClose={() => {
          setShowDatePicker(false);
          task.emit('skip-until-date', {
            type: 'close'
          });
        }}
        onSkip={task.emit.bind(task, 'skip-until-date', { type: 'skip' })}
      />
    </>
  );
}
