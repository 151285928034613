import React from 'react';
import { Box } from '@mui/material';
import { language } from '@/index';
import { ContactType } from '@common/types/enums';
import { DelayedSkeleton, FancyButton } from 'ui-utils';

export interface ContactCategorySelectorProps {
  mode?: 'vertical' | 'horizontal';
  onChange: (type: ContactType) => Promise<void>;
  type?: ContactType;
}

export default function ContactCategorySelector(
  props: ContactCategorySelectorProps
) {
  const CATEGORIES: {
    id: ContactType;
    label: string;
  }[] = [
    {
      id: ContactType.PERSONAL,
      label: language.text.personal_contact
    },
    {
      id: ContactType.AUDIENCE_HOLDER,
      label: language.text.audience_holder
    },
    {
      id: ContactType.POTENTIAL_CUSTOMER,
      label: language.text.potential_customer
    },
    {
      id: ContactType.CUSTOMER,
      label: language.text.customer
    },
    {
      id: ContactType.NO_MATCH,
      label: language.text.no_match
    }
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: props.mode == 'vertical' ? 'column' : 'row',
        alignItems: props.mode == 'vertical' ? 'strech' : 'center',
        justifyContent: 'flex-start',
        gap: '0.5em'
      }}>
      {props.type ? (
        CATEGORIES.map((category) => (
          <FancyButton
            key={category.id}
            fType={{
              promise: true
            }}
            variant="contained"
            sx={{
              width: props.mode == 'vertical' ? '100%' : 'auto'
            }}
            color={props.type === category.id ? 'primary' : 'neutral'}
            onClick={() => {
              if (props.type === category.id) return Promise.resolve();
              return props.onChange(category.id);
            }}>
            {category.label}
          </FancyButton>
        ))
      ) : (
        <DelayedSkeleton
          quantity={CATEGORIES.length}
          variant="rounded"
          width="100%"
          height={48}
        />
      )}
    </Box>
  );
}
