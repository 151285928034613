import { Task, TaskBuilder, TaskOptionsConfig } from 'focus-mode-scheduler';
import CommentTask from './CommentTask';
import { NextTask } from 'focus-mode-scheduler/src/Task/TaskBuilder';
import { Contact } from '@/data/Classes/Contact';
import { ContactType } from '@common/types/enums';
import ContactActions from '@/data/DataServer/Contact';
import { BindProps } from 'ui-utils';
import CommentTaskUI from './CommentTaskUI';
import { language } from '@/index';

export default class CommentTaskBuilder extends TaskBuilder {
  protected task = CommentTask;

  private audienceHolders: Contact[] = [];
  protected async loadAudienceHolders(): Promise<void> {
    if (this.audienceHolders.length) return;
    this.audienceHolders = await ContactActions.listAllContacts({
      values: {
        type: ContactType.AUDIENCE_HOLDER
      }
    });
  }

  async getTask(): Promise<NextTask<Task>> {
    await this.loadAudienceHolders();

    const task = new CommentTask(this.audienceHolders);
    return {
      task,
      UI: BindProps(CommentTaskUI, { task })
    };
  }

  public async getOptionsConfig(): Promise<TaskOptionsConfig | null> {
    return {
      items: {
        min: 1,
        max: 60,
        defaultValue: 5,
        scale: 'linear',
        step: 1
      },
      time: {
        min: 1,
        max: 60,
        defaultValue: 15,
        scale: 'linear',
        step: 1
      },
      defaultValue: {
        type: 'items',
        items: 5
      },
      texts: { taskName: language.text.write_comments }
    };
  }
}
