import HUD from '@/components/HUD/HUD';
import { language } from '@/index';
import { Box, Typography } from '@mui/material';
import StaticLoginLinks from './StaticLoginLinks';
import Auth from '@common/AuthManager/Auth.renderer';
import LoginDayCounter from './LoginDayCounter';
import { useFeatureFlags } from '@/globals/featureFlags';

export interface SSCLoginHUDProps {
  children: React.ReactNode;
  loggedIn: boolean;
}

export default function SSCLoginHUD(props: SSCLoginHUDProps) {
  const { ssc_login_10_days_challenge } = useFeatureFlags();
  return (
    <Box
      sx={{
        position: 'relative',
        height: '100vh',
        width: '100vw',
        zIndex: 1,
        overflowY: 'auto',
        overflowX: 'hidden',

        pt: 16,
        pb: 6,
        boxSizing: 'border-box',

        display: 'grid',
        gridTemplateRows: '1fr auto'
      }}>
      <HUD
        topContent={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              position: 'relative'
            }}>
            <Typography
              variant="h4"
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                color: (theme) => theme.palette.secondary.main,
                mb: 2
              }}>
              {language.text.start_now_on_linkedin}
            </Typography>
            {ssc_login_10_days_challenge && (
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: '100%',
                  transform: 'translateY(-50%)',
                  width: '100%',
                  px: 4,
                  zIndex: 1,
                  boxSizing: 'border-box'
                }}>
                <LoginDayCounter days={Auth.getSessionInfo().usageDays ?? 0} />
              </Box>
            )}
          </Box>
        }
      />
      {props.children}
      <StaticLoginLinks
        continueFct={() => {
          const token = Auth.getRefreshToken();
          if (token) window.api.send('ssc:logged-in', token);
        }}
        loggedIn={props.loggedIn}
      />
    </Box>
  );
}
