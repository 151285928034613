import MainConfig from '@common/config/MainConfig';
import { Objects } from '@idot-digital/generic-helpers';
import { OnboardingSteps } from '@common/types/Setup';
import { createQueryHook } from './QueryHelper';
import queryClient from '@/other/QueryClient';
import Tutorials, { ChatTutorial } from '@common/config/Tutorials.renderer';
import { SetupStepID } from '@/Routes/Setup/setupSteps';
import PrintableError from '@common/PrintableError/PrintableError';
import Auth from '@common/AuthManager/Auth.renderer';
import { setup } from '@digital-sun-solutions/cloud-functions';
import MessageBus from '@common/MessageBus/MessageBus.renderer';
import posthog from 'posthog-js';

const ONBOARDING_PREFIX = 'onboarding_';

async function setSetupStep(step: SetupStepID, done = true) {
  const res = await Auth.execRoute((token) =>
    setup[done ? 'completeStep' : 'undoStep'](
      {
        step
      },
      {
        token
      }
    )
  );
  if (res.code !== 200)
    throw new PrintableError(
      'Could not get setup steps due to internal server error'
    );
  invalidateQueries();
}

async function getSetupSteps() {
  const res = await Auth.execRoute((token) =>
    setup.getCompletedSteps({}, { token })
  );
  if (res.code !== 200)
    throw new PrintableError(
      'Could not get setup steps due to internal server error'
    );
  return res.data;
}
getSetupSteps.getQueryKey = () => ['setup'];

const useSetupSteps = createQueryHook(getSetupSteps);

/**
 * Checks if a setup step has been done yet. \
 * If done, return `false` \
 * If not done, return the video to show \
 * If not done, but no video defined, return `null`
 */
async function checkOnboardingStep(
  step: OnboardingSteps
): Promise<Video | null | false> {
  const setupSteps = await getSetupSteps();
  if (setupSteps.includes(`${ONBOARDING_PREFIX}${step}`)) return false;
  return MainConfig.videos.onboarding[step] ?? null;
}
checkOnboardingStep.getQueryKey = (step?: OnboardingSteps | null) => [
  'setup',
  'onboarding',
  ...(step ? [step] : [])
];

async function getRemainingChatTutorials(): Promise<ChatTutorial[]> {
  const chats = Tutorials.chats;
  const setupSteps = await getSetupSteps();
  return chats.filter(
    (chat) =>
      !setupSteps.includes(`${ONBOARDING_PREFIX}focus:chat-tutorial-${chat.id}`)
  );
}
getRemainingChatTutorials.getQueryKey = () => [
  'setup',
  'onboarding',
  'focus:chat-tutorial'
];
async function completeChatTutorial(
  chatTutorial: Pick<ChatTutorial, 'id'>
): Promise<void> {
  await setSetupStep(
    `${ONBOARDING_PREFIX}focus:chat-tutorial-${chatTutorial.id}`,
    true
  );
  invalidateQueries();
}

async function completeOnboardingStep(
  step: OnboardingSteps
): Promise<Video | null> {
  posthog.capture(`onboarding-${step}-completed`);
  await setSetupStep(`${ONBOARDING_PREFIX}${step}`, true);
  invalidateQueries();
  return null;
}

async function resetOnboardingSteps() {
  const onboardingSteps = Objects.keys(MainConfig.videos.onboarding);
  await Promise.all(
    onboardingSteps.map((step) =>
      setSetupStep(`${ONBOARDING_PREFIX}${step}`, false)
    )
  );
  invalidateQueries();
}

async function completeAllOnboardingSteps() {
  const onboardingSteps = Objects.keys(MainConfig.videos.onboarding);
  await Promise.all(
    onboardingSteps.map((step) =>
      setSetupStep(`${ONBOARDING_PREFIX}${step}`, true)
    )
  );
  invalidateQueries();
}

async function invalidateQueries() {
  await queryClient.invalidateQueries(checkOnboardingStep.getQueryKey());
  await queryClient.invalidateQueries(getSetupSteps.getQueryKey());
  await queryClient.invalidateQueries(getRemainingChatTutorials.getQueryKey());
}

const useOnboardingVideo = createQueryHook(checkOnboardingStep, 'video');

const SetupActions = {
  setSetupStep,
  getSetupSteps,
  useSetupSteps,

  checkOnboardingStep,
  completeOnboardingStep,
  resetOnboardingSteps,
  useOnboardingVideo,
  getRemainingChatTutorials,
  completeChatTutorial,
  completeAllOnboardingSteps
};

export default SetupActions;
