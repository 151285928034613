import { Navigate, Route, Routes } from 'react-router-dom';
import PostLoaderStart from './PostLoaderStart';
import { POST_LOADER_PATHS } from '@/globals/Paths';
import PostLoaderRun from './PostLoaderRun';

export default function PostLoaderRouter() {
  return (
    <Routes>
      <Route index element={<Navigate to={POST_LOADER_PATHS.Start} />} />
      <Route path={POST_LOADER_PATHS.Start} element={<PostLoaderStart />} />
      <Route path={POST_LOADER_PATHS.Run} element={<PostLoaderRun />} />
    </Routes>
  );
}
