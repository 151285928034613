import React, { useState, ChangeEvent } from 'react';

import './style.css';
interface AutocompleteInputProps {
  options: string[];
  onChange: (value: string) => void;
  value: string;
  style?: React.CSSProperties;
  placeholder?: string;
}

const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  options,
  onChange,
  value,
  style,
  placeholder
}) => {
  const [inputValue, setInputValue] = useState<string>(value);
  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const setInput = (value: string) => {
    setInputValue(value);
    const filtered = options.filter((option) =>
      option.toLowerCase().startsWith(value.toLowerCase())
    );
    setFilteredOptions(filtered);
    setShowDropdown(true);
    onChange(value);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleOptionClick = (option: string) => {
    setShowDropdown(false);
    setInput(option);
  };

  return (
    <div className="autocomplete">
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => setShowDropdown(true)}
        onBlur={() => setShowDropdown(false)}
        className="autocomplete-input"
        placeholder={placeholder}
        style={style}
      />
      {showDropdown && (
        <ul className="autocomplete-dropdown">
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              onMouseDown={() => handleOptionClick(option)}
              className="autocomplete-option">
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AutocompleteInput;
