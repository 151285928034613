export const CONTENT_SCRIPT_CSS = `.ssc-back-button {
  padding: var(--spacing-half-x);
  border-radius: 50%;
  margin-right: var(--spacing-half-x);
}

.ssc-back-button:hover {
  background-color: var(
    --color-background-none-tint-hover,
    rgba(0, 0, 0, 0.08)
  );
}

.ssc-back-button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

:root {
  /* main colors */
  --ssc-main: #0062ff;
  --ssc-main-light: #0050ff;
  --ssc-main-lighter: #499eeb;
  --ssc-main-dark: #1952ba;
  --ssc-main-darker: #2735ef;

  --ssc-second-main: #fada6d;
  --ssc-second-main-text: #44444f;

  --ssc-tertiary-main: #9933cc;
  --ssc-tertiary-main-text: #fff;

  --ssc-success-main: #78dc74;
  --ssc-success-main-text: #fff;

  /* main colors with alpha */
  --ssc-main-a20: #0062ff33;
  --ssc-main-a50: #0062ff7f;
  --ssc-main-light-a20: #0050ff33;
  --ssc-main-light-a50: #0050ff7f;
  --ssc-main-lighter-a20: #499eeb33;
  --ssc-main-lighter-a50: #499eeb7f;
  --ssc-main-dark-a20: #1952ba33;
  --ssc-main-dark-a50: #1952ba7f;
  --ssc-main-darker-a20: #0e306d33;
  --ssc-main-darker-a50: #0e306d7f;

  /* contrast color for main */
  --ssc-contrast-main: #fff;

  /* attention color for e.g. notifictaions */
  --ssc-attention: #fc5a5a;

  /* colors for widgets */
  --ssc-good: #008a65;
  --ssc-neutral: #ffc353;
  --ssc-bad: #be4b00;

  /* text */
  --ssc-text: #171725;
  --ssc-text-low-emphasis: #707070;
  --ssc-action-low-emphasis: #92929d;
  /* --ssc-action-low-emphasis: #e0dfdc; */

  /* shadow */
  --ssc-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.39);

  --color-text: var(--ssc-text) !important;

  /* overwrite linkedin default colors */
  --color-brand: var(--ssc-main) !important;
  --color-brand-active: var(--ssc-main-dark) !important;

  --color-action: var(--ssc-main) !important;
  --color-action-active: var(--ssc-main-dark) !important;
  --color-action-on-dark: var(--ssc-main-light) !important;
  --color-action-on-dark-active: var(--ssc-lighter) !important;

  --color-background-new: var(--ssc-main-a20) !important;
  --color-background-new-active: var(--ssc-main-a50) !important;
  --color-background-new-on-dark: var(--ssc-main-light-a20) !important;
  --color-background-new-on-dark-active: var(--ssc-main-light-a50) !important;
  --color-background-new-hover: var(--ssc-main-a20) !important;
  --color-background-new-on-dark-hover: var(--ssc-main-light-a50) !important;

  --color-border-focus: var(--ssc-main-dark) !important;
  --color-background-focus: var(--ssc-main-a50) !important;
  --color-border-focus-on-dark: var(--ssc-main-light) !important;
  --color-background-focus-on-dark: var(--ssc-main-light-a50) !important;

  --voyager-color-action: var(--ssc-main) !important;
  --voyager-color-action-hover: var(--ssc-main-dark) !important;
  --voyager-color-action-active: var(--ssc-main-darker) !important;
  /* default white */
  /* --voyager-color-action-on-dark: white !important; */
  /* --voyager-color-action-on-dark-hover: white !important; */
  /* --voyager-color-action-on-dark-active: rgba(255, 255, 255, 0.7) !important; */
  --voyager-color-action-stick: var(--ssc-main) !important;
  --voyager-color-action-stick-hover: var(--ssc-main-dark) !important;
  --voyager-color-action-stick-active: var(--ssc-main-darker) !important;

  --reactions-color-text-like: var(--ssc-main) !important;
  --reactions-color-text-like-on-dark: var(--ssc-main-light) !important;

  --reactions-color-text-entertainment: var(--ssc-main-dark) !important;
  --reactions-color-text-entertainment-on-dark: var(
    --ssc-main-lighter
  ) !important;

  --reactions-color-content-like: var(--ssc-main) !important;
  --reactions-color-content-like-on-dark: var(--ssc-main-light) !important;

  --post-color-icon-promoted-ct-1: var(--ssc-main) !important;
  --post-color-text-promoted-ct-1: var(--ssc-main) !important;
  --post-color-background-container-icon-promoted-ct-1: var(
    --ssc-main
  ) !important;

  --voyager-color-background-badge-new: var(--ssc-attention) !important;
  /* notifcation badges should be in main color and not red */
  --voyager-color-background-badge-notification: var(
    --ssc-attention
  ) !important;

  --data-viz-color-accent-1: var(--ssc-main) !important;
  --data-viz-color-accent-2: var(--ssc-main-dark) !important;
  --data-viz-color-accent-3: var(--ssc-main-light) !important;

  --corner-radius-small: 0.75rem !important;
  --corner-radius-medium: 1rem !important;
  --corner-radius-large: 1.5rem !important;

  /* disable light grey outline around cards */
  --elevation-lined: none !important;

  /* make linkedin wider */
  --scaffold-layout-md-max-width: calc(100% - 2rem) !important;
  --scaffold-layout-lg-max-width: calc(100% - 2rem) !important;
  --scaffold-layout-xl-max-width: max(
    80%,
    min(calc(1200px - 2rem), calc(85% - 52px))
  ) !important;
}

#artdeco-global-alert-container:has([type='COOKIE_CONSENT']) {
  top: unset !important;
  bottom: 0 !important;
}

#artdeco-global-alerts-cls-offset {
  height: 0 !important;
}

.global-nav__secondary-items li:last-child {
  display: none;
}

/* disabled unwanted nav items */
body.ssc-focus .global-nav__nav .global-nav__primary-item:nth-child(3),
body.ssc-focus .global-nav__nav .global-nav__primary-item:nth-child(5) {
  display: none;
}
/* disable notification badges in header */
body.ssc-focus .global-nav__nav .notification-badge {
  display: none !important;
}

/* remove button list with "folgen" and "nachricht" button on profile page */
body.ssc-focus div:has(> .entry-point) > *:not(.ssc-button-wrapper) {
  display: none;
}
/* also hide in sticky header bar that comes down when scrolling down on profile */
body.ssc-focus .pvs-sticky-header-profile-actions {
  display: none !important;
}

/* hide sidebar on profile page */
body.ssc-focus .scaffold-layout .scaffold-layout__content--main-aside {
  grid-template-areas: 'main';
  grid-template-columns: 100%;
}
body.ssc-focus .scaffold-layout aside {
  display: none;
}

/* hide "People you may know" sidebar */
body.ssc-focus aside:not(#msg-overlay) > *:not(footer) {
  display: none;
}

/* center content to prevent overlaying Ava over content in bottom right corner */
@media screen and (min-width: 1200px) {
  body.ssc-focus .scaffold-layout-container.scaffold-layout-container--reflow {
    --scaffold-layout-xl-max-width: 80% !important;
    margin-left: auto !important;
  }
}

/* hide header in profile focus mode */
body.ssc-profile-focus #global-nav {
  display: none;
}
/* move sticky header up since header is now missing */
body.ssc-profile-focus .scaffold-layout-toolbar {
  top: 0;
}

/* prevent posts to wide */
body.ssc-search-page .scaffold-layout__inner {
  max-width: min(720px, 80vh);
}

.ssc-ah-post {
  outline: 3px solid var(--ssc-second-main);
}

/* hide message button in right sidebar */
.ssc-mark-profiles
  #recent-activity-top-card
  .pv-recent-activity-top-card__profile-actions {
  display: none;
}

/* hide all top right nav items */
.ssc-mark-profiles .global-nav__primary-item {
  display: none;
}

/* change cursor to text on disabled links */
.ssc-mark-profiles .profile-creator-shared-feed-update__container a {
  cursor: text;
}

/* ---------------------- Hide elements ---------------------- */
/* disable ad container in bottom left */
.ad-banner-container {
  display: none;
}

/* disable linkedin news */
/* .artdeco-card.ember-view:has(#feed-news-module) {
  display: none;
} */

/* disable community tab */
/* .community-panel {
  display: none;
} */

/* disable linkedin learning in profile page */
aside > div:has(> .pv-course-recommendations) {
  display: none;
}

/* ---------------------- Feed Layout ---------------------- */
/* change width scaling since we add a sidebar on the left */
@media screen and (min-width: 1200px) {
  body:not(.ssc-focus)
    .scaffold-layout-container.scaffold-layout-container--reflow
    .scaffold-layout__content--main-aside {
    grid-template-columns: minmax(min(600px, 80vw), 1fr);
  }
}
/* put profile and site info on right side */
@media screen and (min-width: 768px) {
  body .scaffold-layout__content--sidebar-main-aside {
    grid-template-areas:
      'aside main'
      '. sidebar' !important;
    /* column widths don't need to be adjusted since only main and one side are still displayed */
    /* grid-template-columns: minmax(0, 17fr) minmax(0, 7fr) !important; */
  }
}
@media screen and (min-width: 992px) {
  body .scaffold-layout__content--sidebar-main-aside {
    grid-template-areas: 'aside main sidebar' !important;
    /* reverse order of default grid column templates */
    grid-template-columns: minmax(0, 5fr) minmax(0, 12fr) minmax(0, 5fr) !important;
  }
}

/* ---------------------- Posts ---------------------- */
/* hide reactions text with names to show raw number hidden underneath */
.social-details-social-counts__social-proof-text {
  display: none;
}

/* ---------------------- Recolor LinkedIn Logo ---------------------- */
/* some linkedin logos have inline fills */
.global-nav__branding-logo svg path {
  fill: var(--ssc-main) !important;
}
/* loading logo when opening the site */
.loader__linkedin-logo {
  color: var(--ssc-main) !important;
}

/* disable chat popup in bottom right */
body:not(.ssc-show-chat) #msg-overlay {
  opacity: 0 !important;
  pointer-events: none !important;
}

/* ---------------------- Restyle Navbar ---------------------- */
/* disabled unwanted nav items */
.global-nav__nav .global-nav__primary-item:nth-child(4),
.global-nav__nav .global-nav__primary-item:nth-child(7),
.global-nav__nav .global-nav__primary-item:nth-child(8) {
  display: none;
}
/* disable text in navbar */
/* do not disable text for profile drop down since we want to use the icon in the span */
.global-nav__primary-item:not(:nth-child(6)) .global-nav__primary-link-text {
  display: none !important;
}
/* put dropdown indicator left of profile pic instead of below */
.global-nav__primary-item:nth-child(6) button {
  flex-direction: row;
  color: var(--ssc-action-low-emphasis) !important;
}
/* hide "Sie" text under profile dropdown in main navbar */
.global-nav__primary-item:nth-child(6) .global-nav__primary-link-text {
  font-size: 0;
  margin-left: 0.5rem;
}

/* disable lower two options in profile sidebar in feed (test premium, "Ihre Elemente") */
.feed-identity-module > a {
  display: none;
}

/* ---------------------- Search Results Page ---------------------- */
/* improve / fix style for search results */
/* ssc-search-page class gets added by scripts when on search page (addCssClasses.ts) */
/* check for .scaffold-layout__content--has-sidebar to not mess up style when no sidebar is present */
.ssc-search-page .scaffold-layout__content--has-sidebar .search-marvel-srp,
.scaffold-layout__content--has-sidebar .search-results-container {
  position: relative;
  /* increase default width to make space for bigger buttons */
  max-width: 720px;
  right: 0;
  float: right;
}
.ssc-search-page
  .scaffold-layout__row.scaffold-layout__content.scaffold-layout__content--main-aside.scaffold-layout__content--has-aside.scaffold-layout__content--has-sidebar {
  float: right;
}
/* with custom style this clips into main search field */
.ssc-search-page .scaffold-layout__aside {
  display: none;
}

/* ---------------------- Disabled Links ---------------------- */
/* we remove href from some links and don't want to underline those link on hover */
a:not([href]),
a:not([href]) .hoverable-link-text,
/* "do you know" profile links in sidebar of profile page */
.pv-pymk-section__member-detail--has-hover .name:hover {
  text-decoration: none !important;
  color: initial !important;
  cursor: pointer;
}

/* ----------------------- Scroll Bar ------------------------- */
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-thumb {
  background-color: #5e6c84;
  border-radius: 4px;
}

.ssc-button-wrapper {
  cursor: pointer;
}

.ssc-post-button {
  min-width: fit-content !important;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.update-components-actor--with-control-menu-and-hide-post .ssc-post-button,
.update-components-actor--with-control-menu .ssc-post-button {
  align-self: flex-start;
  margin-top: -0.75rem;
}

.ssc-save-profile-button {
  min-height: 0 !important;
  position: relative;
  margin-right: 0.5rem;
}
.ssc-save-profile-button svg,
.ssc-save-profile-button li-icon,
.ssc-save-profile-dropdown-item svg,
.ssc-save-profile-dropdown-item li-icon {
  color: inherit !important;
}
.ssc-save-profile-button.artdeco-button--muted svg,
.ssc-save-profile-dropdown-item.artdeco-button--muted svg {
  margin: 0;
}
.ssc-save-profile-button.artdeco-button--muted svg,
.ssc-save-profile-dropdown-item.artdeco-button--muted svg {
  height: 2.4rem;
  width: 2.4rem;
}

.ssc-save-profile-button.ssc-save-profile-button--no-text,
.ssc-save-profile-dropdown-item.ssc-save-profile-button--no-text {
  aspect-ratio: 1 / 1;
  border-radius: 50% !important;
}

.ssc-save-profile-button > *:not(.ssc-loading-icon),
.ssc-save-profile-dropdown-item > *:not(.ssc-loading-icon) {
  z-index: 0;
  transition: opacity 0.3s ease-in-out;
}
.ssc-save-profile-button.loading,
.ssc-save-profile-dropdown-item.loading {
  pointer-events: none;
}
.ssc-save-profile-button.loading > *:not(.ssc-loading-icon),
.ssc-save-profile-dropdown-item.loading > *:not(.ssc-loading-icon) {
  opacity: 0;
}
.ssc-save-profile-button:not(.loading) > .ssc-loading-icon,
.ssc-save-profile-dropdown-item:not(.loading) > .ssc-loading-icon {
  display: none;
}
.ssc-loading-icon {
  position: absolute;
  inset: 0;
  padding: 0.25em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  width: 100%;
}

.ssc-loading__circle {
  stroke: currentColor;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0;
  animation: loading 1.4s ease-in-out infinite;
}
@keyframes loading {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}

/* prevent misaligned icon for icon button */
.ssc-save-profile-button--no-text li-icon {
  margin: 0 !important;
}

/* set background of successful button to light blue (only when text button -> .pvs-profile-actions__action) */
.ssc-save-profile-button.success.pvs-profile-actions__action,
.ssc-save-profile-dropdown-item.success.pvs-profile-actions__action {
  background: var(--ssc-main-darker);
}
.ssc-save-profile-button.success .ssc-add-icon,
.ssc-save-profile-dropdown-item.success .ssc-add-icon {
  display: none;
}
.ssc-save-profile-button:not(.success) .ssc-success-icon,
.ssc-save-profile-dropdown-item:not(.success) .ssc-success-icon {
  display: none;
}

/* ---- Profile sidebar ---- */
/* change styling so added button fits */
.pv-browsemap-section__member-container > div,
.pv-pymk-section__member-cta {
  margin-left: 0 !important;
  padding-left: 0 !important;
}
.pv-browsemap-section__member-container > div > div,
.pv-pymk-section__member-cta {
  display: flex;
}
.pv-browsemap-section__member-container > div > div > button,
.pv-browsemap-section__member-container
  > div
  > div
  > .ssc-pipeline-button-wrapper,
.pv-pymk-section__member-cta > button,
.pv-pymk-section__member-cta > .ssc-pipeline-button-wrapper {
  width: calc(50% - 0.5em);
}
.pv-browsemap-section__member-container
  > div
  > div
  > .ssc-pipeline-button-wrapper
  button,
.pv-pymk-section__member-cta > .ssc-pipeline-button-wrapper button {
  width: 100%;
}
.pv-browsemap-section__member-container
  > div
  > div
  > .ssc-pipeline-button-wrapper,
.pv-pymk-section__member-cta > .ssc-pipeline-button-wrapper {
  margin-left: 1em;
}

/* ---- Post comment ---- */
.ssc-comment-save-profile-button {
  margin-left: auto;
  display: flex;
}
.ssc-comment-save-profile-button > button {
  padding: 0 0.5em !important;
  min-height: 0 !important;
  font-size: 1.25rem !important;
  border-radius: var(--corner-radius-small);
}
/* style for success comment buttons */
.ssc-comment-save-profile-button > button:not(.success) {
  /* can accidentally overlay name / badges */
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white 10%);
  color: var(--ssc-main);
}
.ssc-comment-save-profile-button > button.success {
  padding: 0.75em !important;
}
.ssc-comment-save-profile-button > button.artdeco-button--disabled {
  color: var(--color-text-disabled) !important;
}

/* make more space so text (profile name and description) do not clip into our button */
.comments-post-meta__profile-info-wrapper {
  padding-right: 25rem !important;
}

/* popover gets cut off on profile card */
#recent-activity-top-card {
  overflow: visible !important;
}

/* in mini profile on activity page of a profile the wrapper is not the full with since the parent is display: flex */
.pv-recent-activity-top-card__profile-actions .ssc-pipeline-button-wrapper {
  width: 100%;
}

/* ------ reactions ------ */
/* profile in reactions are not full width */
.social-details-reactors-tab-body-list-item a {
  width: 100%;
}
.social-details-reactors-tab-body-list-item .artdeco-entity-lockup {
  width: 100%;
}
.social-details-reactors-tab-body-list-item .artdeco-entity-lockup__content {
  flex-grow: 1;
}
/* add styles to button */
.ssc-reaction-save-profile-button {
  display: inline-flex;
  float: right;
}

/* ---- search ---- */
/* hide message and follow button -> we insert custom buttons */
.ssc-search-page
  .entity-result__actions
  div
  button:not(.ssc-save-profile-button) {
  display: none;
}

/* ---- dropdown ---- */
.ssc-dropdown-wrapper {
  position: relative;
  padding: 0.5rem;
  align-self: start;
  margin: 0;
  cursor: pointer;
}
/* post that do not have menu action items in the top right corner */
.update-components-actor:not(
    .update-components-actor--with-control-menu-and-hide-post
  )
  .ssc-dropdown-wrapper {
  align-self: center;
}
.ssc-dropdown-content-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10000;

  cursor: pointer;
}
.ssc-dropdown-content-wrapper:not(.ssc-show) {
  pointer-events: none;
  opacity: 0;
}

body.ssc-modal-open:not(.artdeco-modal-is-open) {
  overflow: hidden;
  margin-right: 8px;
}

.ssc-dropdown-content {
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0.25rem 1rem;
  background: white;
  border-radius: var(--corner-radius-small);
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  z-index: 100;
}

.ssc-save-profile-dropdown-item:not(:last-child) {
  border-bottom: 1px solid var(--color-text-disabled);
}

.ssc-save-profile-dropdown-item {
  position: relative;
  font-size: 1.6rem;
  min-height: 3.2rem;
  padding: 0.6rem 1.6rem 0.6rem 3.8rem;
  line-height: 2rem;
  background: transparent;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 167ms;
  align-items: center;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  font-family: inherit;
  font-weight: 600;
  display: inline-flex;
  justify-content: flex-start;
  min-width: 6.4rem;
  max-width: 480px;
  overflow: hidden;
  text-align: center;
  transition-property: background, box-shadow, color;
  vertical-align: middle;
  color: var(--color-text);
}
.ssc-save-profile-dropdown-item:hover {
  filter: brightness(0.95);
}
.ssc-save-profile-dropdown-item__icon {
  height: 100%;
  aspect-ratio: 1 / 1;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.7rem;
  background: var(--background, --ssc-main);
  color: var(--color, white);
}
.ssc-save-profile-dropdown-item__icon svg {
  height: 1.8rem !important;
  width: 1.8rem !important;
}
.ssc-save-profile-dropdown-item[data-type='active'] {
  background: var(--background, --ssc-main);
  color: var(--color, rgb(207, 192, 192));
}

/* .artdeco-button in selector is only to have a highter priority */
.ssc-save-profile-dropdown-item.artdeco-button {
  border-radius: 0 !important;
  max-width: 600px;
  width: 100%;
  justify-content: flex-start;
}
.ssc-save-profile-dropdown-item span.artdeco-button__text {
  width: max-content;
}
.ssc-save-profile-dropdown-item:hover {
  filter: brightness(0.95);
}

/* ---- Profile button ---- */
.ssc-profile-button {
  padding: 0 !important;
}
`;