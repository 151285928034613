import { Conversation } from 'linkedin-domain-types';
import { SSCChat } from '../Classes/Chat/SSCChat';
import { getSelf } from '../LinkedIn/Account';
import { Arrays } from '@idot-digital/generic-helpers';
import queryClient from '@/other/QueryClient';
import ContactActions from './Contact';
import {
  PartialParameters,
  RemoveFirst,
  createInfiniteQueryHook,
  createQueryHook
} from './QueryHelper';
import { Message } from '@common/types/ApiTypes';
import PrintableError from '@common/PrintableError/PrintableError';
import Auth from '@common/AuthManager/Auth.renderer';
import { chats } from '@digital-sun-solutions/cloud-functions';
import log from 'electron-log';
import LinkedInChatActions from './LinkedInChats';
import { AbstractChat } from '../Classes/Chat/AbstractChat';
import { LinkedInChat } from '../Classes/Chat/LinkedInChat';
import TemplateActions from './Templates';
import { ContactType } from '@common/types/enums';

export type ChatFilter =
  | Omit<Parameters<(typeof chats)['list']>[0], 'cursor'>
  | ChatSeachFilter;

export type ChatSeachFilter = Omit<
  Parameters<(typeof chats)['search']>[0],
  'cursor'
>;

async function listChats(
  lastChat: SSCChat | null | undefined,
  filter: ChatFilter
): Promise<SSCChat[]> {
  const ownProfile = await getSelf(true, true);

  // legacy cloud does not support search -> will be implemented in new cloud rewrite
  if ('searchQuery' in filter && !filter.searchQuery) {
    //@ts-expect-error -- ts has an issue with this...
    delete filter.searchQuery;
  }

  const rawChatsRes = await Auth.execRoute((token) =>
    chats['searchQuery' in filter && filter.searchQuery ? 'search' : 'list'](
      {
        ...(filter as ChatSeachFilter),
        ...(lastChat?.lastActivityAt && {
          cursor: lastChat.lastActivityAt
        })
      },
      { token }
    )
  );

  if (rawChatsRes.code !== 200) {
    log.error(`Failed to load chats: ${rawChatsRes.code}`, rawChatsRes.data, {
      cursor: lastChat?.lastActivityAt ?? undefined
    });
    throw new PrintableError(`Failed to list chats from SSC server`);
  }

  const rawChats = rawChatsRes.data;

  const contacts = await ContactActions.getContacts(
    rawChats.map((chat) => chat.participant.profileID)
  );

  const pipelineSteps = new Set(
    Arrays.filterNull(contacts.map((c) => c.lastPipelineEvent?.currentStep))
  );
  const templates = new Map(
    await Promise.all(
      [...pipelineSteps.values()].map(async (step) => {
        const templates =
          await TemplateActions.getTemplatesOfPipelineStep(step);
        return [step, templates?.[0]?.templates[0] ?? undefined] as const;
      })
    )
  );

  const uncategorizedTemplate = (
    await TemplateActions.getUncategorizedTemplate()
  )?.[0];

  const parsedChats = Arrays.filterNull(
    rawChats.map((chat) => {
      const contact = contacts.find(
        (contact) => contact.profileID === chat.participant.profileID
      );
      if (!contact) return null;

      const template =
        contact.type === ContactType.UNCATEGORIZED
          ? uncategorizedTemplate
          : templates.get(contact.lastPipelineEvent?.currentStep ?? '');

      return new SSCChat({
        ownPublicIdentifier: ownProfile.publicIdentifier,
        conversationID: contact.conversationID,
        contact,
        chatDetails: chat,
        template
      });
    })
  );

  return parsedChats;
}
listChats.getQueryKey = (
  ...args: Partial<RemoveFirst<Parameters<typeof listChats>>>
): string[] => {
  return [
    'conversation',
    'ssc',
    'list',
    ...(args[0] ? [JSON.stringify(args[0])] : [])
  ];
};

async function getChat(
  conversationID: string,
  conversationData?: Conversation
): Promise<SSCChat> {
  const ownProfile = await getSelf(true, true);
  const chat = new SSCChat({
    conversationID,
    ownPublicIdentifier: ownProfile.publicIdentifier,
    conversationData
  });
  if (!conversationData) await chat.getConversationData();
  return chat;
}
getChat.getQueryKey = (...args: PartialParameters<typeof getChat>) => [
  'conversation',
  'ssc',
  'get',
  ...(args[0] ? [args[0]] : [])
];

async function invalidateChatsCache(
  conversationID?: string,
  invalidateLinkedIn = true
) {
  await queryClient.invalidateQueries(listChats.getQueryKey());
  await queryClient.invalidateQueries(getChat.getQueryKey());
  await queryClient.invalidateQueries(
    SSCChat.getListMessagesQueryKey(conversationID)
  );
  if (invalidateLinkedIn)
    LinkedInChatActions.invalidateChatsCache(conversationID, false);
}

const useChat = createQueryHook(getChat, 'chat');

const useChats = createInfiniteQueryHook(listChats, 'chats', {
  refetchOnMount: false,
  refetchOnWindowFocus: false
});

const useMessages = createInfiniteQueryHook(
  (lastMessage: Message | undefined, chat?: AbstractChat | undefined | null) =>
    chat?.listMessages(lastMessage) ?? [],
  'messages',
  {
    staticQueryKey: (chat?: AbstractChat | null) =>
      (chat instanceof LinkedInChat
        ? LinkedInChat
        : SSCChat
      ).getListMessagesQueryKey(chat)
  }
);

const ChatActions = {
  listChats,
  useChats,

  getChat,
  useChat,

  useMessages,

  invalidateChatsCache
};

export default ChatActions;
