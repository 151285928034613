export default function MapProps<GivenProps, Props>(
  Component: React.FC<Props>,
  map: (props: GivenProps) => Props & JSX.IntrinsicAttributes
): React.FC<GivenProps> {
  return function (props: GivenProps) {
    return (
      <Component
        {...(props ? map(props) : ({} as Props & JSX.IntrinsicAttributes))}
      />
    );
  };
}
