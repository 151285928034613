import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Popover,
  SxProps,
  Theme,
  useTheme
} from '@mui/material';
import { EmojiEmotionsOutlined } from '@mui/icons-material';
import { Slice, Fragment, Node, ResolvedPos } from 'prosemirror-model';
import { Editor, EditorContent, useEditor } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import EmojiList from '../EmojiList/EmojiList';
import FileInput from '../FileInput/FileInput';
import VoiceMessage from '../VoiceMessage/VoiceMessage';
import SendIcon from '../../icons/SendIcon';
import History from '@tiptap/extension-history';
import FancyButton from '../FancyButtons/FancyButton';
import { VariableBlock } from './CustomBlocks/Variable/VariableBlock';
import { PlaceholderBlock } from './CustomBlocks/Placeholder/PlaceholderBlocks';
import { language } from '../../Config';
import TextEditorHelper from './TextEditorHelper';

export interface TextEditorProps {
  value?: TextEditorValue | string;
  onChange?: (value: TextEditorValue) => void;
  /*
   * if returned true, the text editor will be cleared
   */
  onSubmit?: (value: TextEditorValue) => Promise<boolean>;
  showFileInput?: boolean;
  showVoiceInput?: boolean;
  showEmojiInput?: boolean;
  showSendButton?: boolean;
  getEditor?: (editor: Editor) => void;
  disabledBorder?: boolean;
  margin?: 'none' | 'noTop' | 'noBottom' | 'all';
  sendIcon?: React.ReactNode;
  disableSend?: boolean | ((value: TextEditorValue) => boolean);
  sendButton?: (props: {
    disabled: boolean;
    sending: boolean;
    onClick?: () => Promise<void>;
    icon: React.ReactNode;
  }) => React.ReactNode;
  disabled?: boolean;
  sendOnEnter?: 'ctrl' | true | false;
  sx?: SxProps<Theme>;
  sxEditor?: SxProps<Theme>;
  children?: React.ReactNode | React.ReactNode[];
  autofocus?: boolean;
  maxHeight?: string;
  emojiInputPosition?: 'top' | 'bottom' | 'left' | 'right';
  placeholderEditable?: boolean;
}

export interface TextEditorValue {
  html: string;
  text: string;
}

// fixes multiple line pasting
// https://stackoverflow.com/a/71063311
export function clipboardTextParser(text: string, context: ResolvedPos) {
  //@ts-ignore
  const blocks = text.replace().split(/(?:\r\n?|\n)/);
  const nodes: Node[] = [];

  blocks.forEach((line) => {
    const nodeJson = {
      type: 'paragraph',
      content: [] as { type: 'text'; text: string }[]
    };
    if (line.length > 0) {
      nodeJson.content = [{ type: 'text', text: line }];
    }
    const node = Node.fromJSON(context.doc.type.schema, nodeJson);
    nodes.push(node);
  });

  const fragment = Fragment.fromArray(nodes);
  return Slice.maxOpen(fragment);
}

const TextEditor = React.forwardRef<HTMLDivElement, TextEditorProps>(
  (props, ref) => {
    const theme = useTheme();
    const [value, setValue] = React.useState<string>('');

    const [sending, setSending] = React.useState<boolean>(false);

    const getTextValue = (value: string) => {
      const startTrimmed = value.startsWith('<p>') ? value.slice(3) : value;
      const endTrimmed = startTrimmed.endsWith('</p>')
        ? startTrimmed.slice(0, -4)
        : startTrimmed;
      const lines = endTrimmed.split('</p><p>');

      return TextEditorHelper.replaceSpecialChars(lines.join('\n'));
    };

    const editor = useEditor({
      extensions: [
        Document,
        Paragraph,
        Text,
        History.configure({
          depth: 100
        }),
        VariableBlock.configure({}),
        PlaceholderBlock.configure({
          editable: props.placeholderEditable ?? false
        })
      ],
      content: props.value,
      onUpdate: ({ editor }) => {
        const value = editor.getHTML();
        setValue(value);

        if (props.onChange) {
          props.onChange({
            html: value,
            text: getTextValue(value)
          });
        }
      },
      editorProps: {
        clipboardTextParser
      },
      autofocus: props.autofocus,
      editable: !sending && !props.disabled
    });

    // autofocus (autofocus in useEditor doesn't always work)
    const lastAutofocus = React.useRef<boolean>(false);
    React.useEffect(() => {
      if (props.autofocus && props.autofocus !== lastAutofocus.current)
        editor?.commands.focus();
      lastAutofocus.current = !!props.autofocus;
    }, [editor, props.autofocus]);

    // update editor content when prop value changes
    React.useEffect(() => {
      // setTimeout prevent error: https://github.com/ueberdosis/tiptap/issues/3764
      setTimeout(() => {
        if (editor) {
          if (typeof props.value === 'string') {
            // TODO: content can have html tags -> potential security issue
            let content = props.value.replace(/\n/g, '</p><p>');
            content = `<p>${content}</p>`;
            setValue(content);
            editor.commands.setContent(content);
          } else {
            setValue(props.value?.html ?? '');
            editor.commands.setContent(props.value?.html ?? '');
          }
        }
      });
    }, [editor, props.value]);

    // expose editor to change content/focus from outside
    React.useEffect(() => {
      if (props.getEditor && editor) props.getEditor(editor);
    }, [editor, props.getEditor]);

    const emojiAnchor = React.useRef<HTMLElement | null>(null);
    const [emojisOpen, setEmojisOpen] = React.useState<boolean>(false);

    const send = props.onSubmit
      ? async () => {
          if (!props.onSubmit) return;
          try {
            setSending(true);
            const clear = await props.onSubmit({
              text: getTextValue(value),
              html: value
            });
            if (clear) {
              editor?.commands.setContent('');
              editor?.commands.focus();
              setValue('<p></p>');
            }
          } finally {
            setSending(false);
          }
        }
      : undefined;

    const sendDisabled = React.useMemo(() => {
      if (sending) return true;
      if (props.disableSend)
        if (typeof props.disableSend === 'function')
          return props.disableSend({
            text: getTextValue(value),
            html: value
          });
        else return props.disableSend;
      else return getTextValue(value).length === 0;
    }, [sending, props.disableSend, value]);

    return (
      <Box
        sx={{
          ...(!props.disabledBorder && {
            border: (theme) => `1px solid ${theme.palette.divider}`,
            boxShadow: '0px 2px 4px #0000000A'
          }),
          background: (theme) => theme.palette.background.default,
          m: (theme) =>
            props.margin === 'noTop'
              ? theme.spacing(0, 2, 2, 2)
              : props.margin === 'noBottom'
                ? theme.spacing(2, 2, 0, 2)
                : props.margin === 'none'
                  ? 0
                  : theme.spacing(2),
          width: (theme) => `calc(100% - ${theme.spacing(4)})`,
          boxSizing: 'border-box',
          borderRadius: 2,
          // prevent editor from being a little smaller while loading
          minHeight:
            (props.showEmojiInput ??
            props.showFileInput ??
            props.showSendButton ??
            props.showVoiceInput)
              ? 88
              : 44,
          display: 'flex',
          flexDirection: 'column',
          ...props.sx
        }}
        ref={(ele: HTMLDivElement) => {
          if (typeof ref === 'function') ref(ele);
          else if (ref !== null) ref.current = ele;
          emojiAnchor.current = ele;
        }}>
        <Box
          sx={{
            flex: '1 0 auto',
            minHeight: (theme) => theme.spacing(3),
            maxHeight: props.maxHeight ?? '30vh',
            overflow: 'auto',
            marginBottom: 'auto',
            // without this, min-height: 100% of child does not work
            display: 'flex',
            px: 1,
            ...props.sxEditor
          }}>
          <Box
            sx={{
              '*[contenteditable=true]': {
                overflow: 'auto',
                outline: 'none',
                height: '100%',
                boxSizing: 'border-box',
                py: 1
              },
              '*>p:not(:last-child)': {
                margin: (theme) => theme.spacing(0, 0, 1, 0)
              },
              '*>p:last-child': {
                margin: 0
              },
              'px': 0.5,
              'width': '100%',
              'boxSizing': 'border-box',
              // must be min-height, not height, otherwise it will not grow
              'minHeight': '100%'
            }}>
            <EditorContent
              spellCheck={true}
              lang="de"
              srcLang="en"
              editor={editor}
              style={{
                fontFamily: theme.typography.fontFamily,
                wordBreak: 'break-word',
                height: '100%'
              }}
              placeholder={language.text.type_a_message}
              onKeyDown={(e) => {
                if (e.key !== 'Enter') return;
                if (!props.sendOnEnter) return;
                if (props.sendOnEnter === 'ctrl' && !e.ctrlKey) return;
                send?.();
                e.preventDefault();
                editor?.commands.blur();
              }}
            />
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            px: 1
          }}>
          <Box display="flex" alignItems="center" sx={{ flexWrap: 'wrap' }}>
            {props.showVoiceInput && (
              <VoiceMessage
                disabled={props.disabled}
                onConfirm={(voiceMessage) => console.log(voiceMessage)}
              />
            )}
            {props.showFileInput && (
              <FileInput
                disabled={props.disabled}
                sx={{
                  padding: (theme) => theme.spacing(1, 0.5),
                  borderRadius: '50%',
                  minWidth: 0
                }}
                onUploaded={(files) => console.log(files)}
              />
            )}
            {props.showEmojiInput && (
              <Button
                disabled={props.disabled}
                sx={{
                  p: 0.5,
                  borderRadius: '50%',
                  minWidth: 0
                }}
                onClick={() => setEmojisOpen(true)}>
                <EmojiEmotionsOutlined color="action" />
              </Button>
            )}
            {props.children}
          </Box>
          <Box
            sx={{
              cursor: 'text',
              flexGrow: 1,
              alignSelf: 'stretch'
            }}
            onClick={() => editor?.commands.focus()}
          />
          {props.showSendButton &&
            (props.sendButton ? (
              props.sendButton({
                disabled: props.disabled ?? sendDisabled,
                onClick: send,
                sending,
                icon: sending ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  (props.sendIcon ?? <SendIcon />)
                )
              })
            ) : (
              <FancyButton
                fType={{
                  promise: true
                }}
                variant="text"
                sx={{
                  padding: (theme) => theme.spacing(1),
                  borderRadius: '50%',
                  minWidth: 0
                }}
                disabled={sendDisabled || props.disabled}
                onClick={send}>
                {sending ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  (props.sendIcon ?? <SendIcon />)
                )}
              </FancyButton>
            ))}
          {props.showEmojiInput && (
            <Popover
              anchorEl={emojiAnchor.current}
              open={emojisOpen}
              anchorOrigin={{
                vertical:
                  props.emojiInputPosition === 'top'
                    ? 'top'
                    : props.emojiInputPosition === 'bottom'
                      ? 'bottom'
                      : props.emojiInputPosition
                        ? 'bottom'
                        : 'top',
                horizontal:
                  props.emojiInputPosition === 'left'
                    ? 'left'
                    : props.emojiInputPosition === 'right'
                      ? 'right'
                      : props.emojiInputPosition
                        ? 'center'
                        : 'left'
              }}
              transformOrigin={{
                vertical:
                  props.emojiInputPosition === 'bottom' ? 'top' : 'bottom',
                horizontal:
                  props.emojiInputPosition === 'right'
                    ? 'left'
                    : props.emojiInputPosition === 'left'
                      ? 'right'
                      : props.emojiInputPosition
                        ? 'center'
                        : 'left'
              }}
              onClose={() => {
                setEmojisOpen(false);
                editor?.commands.focus();
              }}>
              <EmojiList
                onEmojiClick={(emoji) => {
                  editor?.commands.insertContent(emoji);
                }}
              />
            </Popover>
          )}
        </Box>
      </Box>
    );
  }
);

export default TextEditor;
