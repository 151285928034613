import React from 'react';
import { Box, IconButton, Popover } from '@mui/material';
import {
  CirclePlusIcon,
  EmojiList,
  OptionalTooltip,
  SmileIcon
} from 'ui-utils';
import LinkedIn from 'linkedin-api';
import { language } from '@/index';

const MAIN_REACTIONS = ['👍', '🔥', '😂', '😍', '👏', '🙏'];

export interface ChatMessageReactEmojiProps {
  emojiClick?: (emoji: string) => void;
  triggerOpen?: boolean;
  setTriggerOpen?: (triggerOpen: boolean) => void;
  alignment?: 'left' | 'right';
  currentEmojis?: string[];
}

export default function ChatMessageReactEmoji(
  props: ChatMessageReactEmojiProps
) {
  const { emojiClick, triggerOpen, setTriggerOpen, alignment, currentEmojis } =
    props;
  const anchorRef = React.useRef<HTMLElement | null>(null);

  const [open, setOpen] = React.useState(false);
  const [full, setFull] = React.useState(false);

  const handleEmojiClick = (emoji: string) => {
    setOpen(false);
    setTriggerOpen && setTriggerOpen(false);
    emojiClick && emojiClick(emoji);
  };

  const handleClick = () => {
    if (maxReached) return;
    setOpen(true);
    setFull(false);
    setTriggerOpen && setTriggerOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setFull(false);
    setTriggerOpen && setTriggerOpen(false);
  };

  const showFullEmojiPicker = () => {
    setFull(true);
  };

  const maxReached =
    (props.currentEmojis?.length ?? 0) >= LinkedIn.MAX_REACTIONS_PER_MESSAGE;

  return (
    <Box
      sx={{
        position: 'absolute',
        left: alignment === 'right' ? '100%' : undefined,
        right: alignment === 'left' ? '100%' : undefined,
        top: 0,
        mt: 1
      }}>
      <OptionalTooltip
        title={maxReached && language.text.max_reactions_for_message}
        placement={alignment}>
        <Box
          component="span"
          ref={(ref: HTMLElement | null) => (anchorRef.current = ref)}>
          <IconButton disabled={maxReached} onClick={handleClick}>
            <SmileIcon />
          </IconButton>
        </Box>
      </OptionalTooltip>
      <Popover
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- we want logical OR here
        open={triggerOpen || open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        sx={{
          marginTop: (theme) => theme.spacing(-2)
        }}
        anchorOrigin={{
          vertical: full ? 'bottom' : 'top',
          horizontal: full ? (alignment == 'left' ? 'left' : 'right') : 'center'
        }}
        transformOrigin={{
          vertical: full ? 'top' : 'bottom',
          horizontal: full ? (alignment == 'left' ? 'right' : 'left') : 'center'
        }}>
        {full ? (
          <EmojiList onEmojiClick={(emoji) => handleEmojiClick(emoji)} />
        ) : (
          <Box
            sx={{
              display: 'inline',
              p: 1
            }}>
            {MAIN_REACTIONS.map((emoji, i) => (
              <IconButton
                key={i}
                sx={{
                  backgroundColor: currentEmojis?.includes(emoji)
                    ? (theme) => theme.palette.background.paper
                    : undefined,
                  // make sure no color with opacity is used
                  color: (theme) => theme.palette.text.primary
                }}
                onClick={() => handleEmojiClick(emoji)}>
                {emoji}
              </IconButton>
            ))}
            <IconButton onClick={() => showFullEmojiPicker()}>
              <CirclePlusIcon color="primary" />
            </IconButton>
          </Box>
        )}
      </Popover>
    </Box>
  );
}
