export const LINKEDIN_URN_PREFIX = 'urn:li';
export const LINKEDIN_URN_REALTIME_PREFIX = 'urn:li-realtime';

export abstract class Urn extends String {
  protected abstract IDENTIFIER: string;
  protected content: string;
  constructor(urn: string) {
    super(urn);
    this.content = this.stripPrefixes(urn);
  }

  getContent(): string {
    return this.content;
  }

  stripPrefixes(urn = this.getFullUrn()): string {
    return urn.replace(`${LINKEDIN_URN_PREFIX}:${this.IDENTIFIER}:`, '');
  }

  getIdentifier(): string {
    return this.IDENTIFIER;
  }

  getFullUrn(): string {
    return `${LINKEDIN_URN_PREFIX}:${this.IDENTIFIER}:${this.content}`;
  }

  static getIdentifier(): string {
    return this.prototype.IDENTIFIER;
  }
}

export abstract class RealtimeUrn extends Urn {
  protected abstract IDENTIFIER: string;
  protected content: string;
  protected constructor(urn: string) {
    super(urn);
    this.content = this.stripPrefixes(urn);
  }

  stripPrefixes(urn: string): string {
    return urn.replace(
      `${LINKEDIN_URN_REALTIME_PREFIX}:${this.IDENTIFIER}:`,
      ''
    );
  }

  getFullUrn(): string {
    return `${LINKEDIN_URN_REALTIME_PREFIX}:${this.IDENTIFIER}:${this.content}`;
  }
}

export class DashConversationUrn extends Urn {
  protected IDENTIFIER = 'fsd_conversation';
}

export class ConversationUrn extends Urn {
  protected IDENTIFIER = 'fs_conversation';
}

export class MessageConversationUrn extends Urn {
  protected IDENTIFIER = 'msg_conversation';
}

export class MessageMessageUrn extends Urn {
  protected IDENTIFIER = 'msg_message';
}

export class MessageMessagingParticipantUrn extends Urn {
  protected IDENTIFIER = 'msg_messagingParticipant';
}

export class MessageExternalMediaUrn extends Urn {
  protected IDENTIFIER = 'msg_externalMedia';
}

export class DashDigitalMediaConferenceUrn extends Urn {
  protected IDENTIFIER = 'fsd_digitalMediaConference';
}

export class DashProfileUrn extends Urn {
  protected IDENTIFIER = 'fsd_profile';
}

export class MiniProfileUrn extends Urn {
  protected IDENTIFIER = 'fs_miniProfile';
}

export class MemberUrn extends Urn {
  protected IDENTIFIER = 'member';
}

export class MessagingMemberUrn extends Urn {
  protected IDENTIFIER = 'fs_messagingMember';
}

export class EventUrn extends Urn {
  protected IDENTIFIER = 'fs_event';
}

export class DashMessageUrn extends Urn {
  protected IDENTIFIER = 'fsd_message';
}

export class MessagingThreadUrn extends Urn {
  protected IDENTIFIER = 'messagingThread';
}

export class MessagingMessageUrn extends Urn {
  protected IDENTIFIER = 'messagingMessage';
}

export class RelSentInvitationViewUrn extends Urn {
  protected IDENTIFIER = 'fs_relSentInvitationView';
}

export class RelInvitationViewUrn extends Urn {
  protected IDENTIFIER = 'fs_relInvitationView';
}

export class InvitationUrn extends Urn {
  protected IDENTIFIER = 'invitation';
}

export class RelInvitationUrn extends Urn {
  protected IDENTIFIER = 'fs_relInvitation';
}

export class CollectionResponseUrn extends Urn {
  protected IDENTIFIER = 'collectionResponse';
}

export class DigitalMediaAssetUrn extends Urn {
  protected IDENTIFIER = 'digitalmediaAsset';
}

export class UpdateV2Urn extends Urn {
  protected IDENTIFIER = 'fs_updateV2';
}

export class MemberBadgesUrn extends Urn {
  protected IDENTIFIER = 'fs_memberBadges';
}

export class JobUrn extends Urn {
  protected IDENTIFIER = 'job';
}

export class MiniJobUrn extends Urn {
  protected IDENTIFIER = 'fs_miniJob';
}

export class JobPostingUrn extends Urn {
  protected IDENTIFIER = 'jobPosting';
}

export class CompanyUrn extends Urn {
  protected IDENTIFIER = 'company';
}

export class MiniCompanyUrn extends Urn {
  protected IDENTIFIER = 'fs_miniCompany';
}

export class DashCompanyUrn extends Urn {
  protected IDENTIFIER = 'fsd_company';
}

export class ActivityUrn extends Urn {
  protected IDENTIFIER = 'activity';
}

export class SocialActivityCountsUrn extends Urn {
  protected IDENTIFIER = 'fs_socialActivityCounts';
}

export class DashSocailActivityCountsUrn extends Urn {
  protected IDENTIFIER = 'fsd_socialActivityCounts';
}

export class UpdateSummaryUrn extends Urn {
  protected IDENTIFIER = 'fs_updateSummary';
}
export class SocialDetailUrn extends Urn {
  protected IDENTIFIER = 'fs_socialDetail';
}

export class WvmpCardUrn extends Urn {
  protected IDENTIFIER = 'fs_wvmpCard';
}

export class ProfileViewerUrn extends Urn {
  protected IDENTIFIER = 'profileViewer';
}

export class CardUrn extends Urn {
  protected IDENTIFIER = 'fs_card';
}

export class ConnectionsInCommonUrn extends Urn {
  protected IDENTIFIER = 'connectionsInCommon';
}

export class WvmpUrn extends Urn {
  protected IDENTIFIER = 'wvmp';
}

export class DashIndustryUrn extends Urn {
  protected IDENTIFIER = 'fsd_industry';
}

export class DashRegionUrn extends Urn {
  protected IDENTIFIER = 'fsd_region';
}

export class DashProfileCertificationUrn extends Urn {
  protected IDENTIFIER = 'fsd_profileCertification';
}

export class DashDegreeUrn extends Urn {
  protected IDENTIFIER = 'fsd_degree';
}

export class DashSchoolUrn extends Urn {
  protected IDENTIFIER = 'fsd_school';
}

export class DashProfileEducationUrn extends Urn {
  protected IDENTIFIER = 'fsd_profileEducation';
}

export class DashEmploymentTypeUrn extends Urn {
  protected IDENTIFIER = 'fsd_employmentType';
}

export class DashGeoUrn extends Urn {
  protected IDENTIFIER = 'fsd_geo';
}

export class DashProfilePositionUrn extends Urn {
  protected IDENTIFIER = 'fsd_profilePosition';
}

export class DashProfilePositionGroupUrn extends Urn {
  protected IDENTIFIER = 'fsd_profilePositionGroup';
}

export class DashProfileTreasuryMediaUrn extends Urn {
  protected IDENTIFIER = 'fsd_profileTreasuryMedia';
}

export class DashConnectionUrn extends Urn {
  protected IDENTIFIER = 'fsd_connection';
}

export class DashInvitationUrn extends Urn {
  protected IDENTIFIER = 'fsd_invitation';
}

export class DashMemberRelationshipUrn extends Urn {
  protected IDENTIFIER = 'fsd_memberRelationship';
}

export class DigitalmediaAssetUpdatesTopicRealtimeUrn extends RealtimeUrn {
  protected IDENTIFIER = 'digitalmediaAssetUpdatesTopic';
}

export class DigitalmediaMediaArtifactUrn extends Urn {
  protected IDENTIFIER = 'digitalmediaMediaArtifact';
}

export class QuickReplyUrn extends Urn {
  protected IDENTIFIER = 'quickReply';
}

export class DashUpdateActionsUrn extends Urn {
  protected IDENTIFIER = 'fsd_updateActions';
}

export class UpdateV2ActionsUrn extends Urn {
  protected IDENTIFIER = 'fs_updateV2Actions';
}

export class UgcPostUrn extends Urn {
  protected IDENTIFIER = 'ugcPost';
}

export class SaveActionUrn extends Urn {
  protected IDENTIFIER = 'fs_saveAction';
}

export class FollowingInfoUrn extends Urn {
  protected IDENTIFIER = 'fs_followingInfo';
}

export class DashSubscribeActionUrn extends Urn {
  protected IDENTIFIER = 'fsd_subscribeAction';
}

export class SubscribeActionUrn extends Urn {
  protected IDENTIFIER = 'fs_subscribeAction';
}

export class DashSaveStateUrn extends Urn {
  protected IDENTIFIER = 'fsd_saveState';
}

export class DashHidePostActionUrn extends Urn {
  protected IDENTIFIER = 'fsd_hidePostAction';
}

export class HidePostActionUrn extends Urn {
  protected IDENTIFIER = 'fs_hidePostAction';
}

export class ShareUrn extends Urn {
  protected IDENTIFIER = 'share';
}

export class DashRingStatusUrn extends Urn {
  protected IDENTIFIER = 'fsd_ringStatus';
}

export class RingStatusUrn extends Urn {
  protected IDENTIFIER = 'fs_ringStatus';
}

export class ContentSeriesUrn extends Urn {
  protected IDENTIFIER = 'contentSeries';
}

export class DashFollowingStateUrn extends Urn {
  protected IDENTIFIER = 'fsd_followingState';
}

export class DashUpdateUrn extends Urn {
  protected IDENTIFIER = 'fsd_update';
}

export class LinkedInArticleUrn extends Urn {
  protected IDENTIFIER = 'linkedInArticle';
}

export class SponsoredCreativeUrn extends Urn {
  protected IDENTIFIER = 'sponsoredCreative';
}

export class DashAdServingUrn extends Urn {
  protected IDENTIFIER = 'fsd_adServing';
}

export class SponsoredCampaignUrn extends Urn {
  protected IDENTIFIER = 'sponsoredCampaign';
}

export class SponsoredAccountUrn extends Urn {
  protected IDENTIFIER = 'sponsoredAccount';
}

export class SocialPermissionsUrn extends Urn {
  protected IDENTIFIER = 'fs_socialPermissions';
}

export class DashSocialPermissionsUrn extends Urn {
  protected IDENTIFIER = 'fsd_socialPermissions';
}

export class DashProfessionalEventUrn extends Urn {
  protected IDENTIFIER = 'fsd_professionalEvent';
}

export class DashCommentUrn extends Urn {
  protected IDENTIFIER = 'fsd_comment';
}

export class DashTranslationUrn extends Urn {
  protected IDENTIFIER = 'fsd_translation';
}

export class ObjectCommentUrn extends Urn {
  protected IDENTIFIER = 'fs_objectComment';
}

export class CommentUrn extends Urn {
  protected IDENTIFIER = 'comment';
}

export class TranslationUrn extends Urn {
  protected IDENTIFIER = 'fs_translation';
}

export class DashSocialDetailUrn extends Urn {
  protected IDENTIFIER = 'fsd_socialDetail';
}

export class ReactionUrn extends Urn {
  protected IDENTIFIER = 'fs_reaction';
}

export class DashReactionUrn extends Urn {
  protected IDENTIFIER = 'fsd_reaction';
}

export class HideCommentActionUrn extends Urn {
  protected IDENTIFIER = 'fs_hideCommentAction';
}

export class DashHideCommentActionUrn extends Urn {
  protected IDENTIFIER = 'fsd_hideCommentAction';
}
