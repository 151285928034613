import React from 'react';
import { Box, BoxProps, SxProps, Theme } from '@mui/material';
import { Styles } from '../../types';

export interface FlameProps extends BoxProps {
  disable?: boolean;
  speed?: number;
  animationDelay?: number;
}

const yellow = '#FFDC01';
const orange = '#FDAC01';
const red = '#F73B01';
const animationtime = 1.5;

const styles = {
  fire: {
    height: (theme) => theme.spacing(3),
    width: (theme) => theme.spacing(3),
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  flames: {
    position: 'absolute',
    bottom: '0%',
    left: '50%',
    width: '60%',
    height: '60%',
    transform: 'translateX(-50%) rotate(45deg)'
  },
  flame: {
    'position': 'absolute',
    'right': '0',
    'bottom': '0',
    'width': '0',
    'height': '0',
    'backgroundColor': yellow,
    'borderRadius': 1,
    '@keyframes flameodd': {
      '0%, 100%': {
        width: '0%',
        height: '0%'
      },
      '25%': {
        width: '100%',
        height: '100%',
        right: '1%',
        bottom: '2%'
      },
      '0%': {
        backgroundColor: yellow,
        zIndex: 1000000,
        right: '0%',
        bottom: '0%'
      },
      '40%': {
        backgroundColor: orange,
        zIndex: 1000000
      },
      '100%': {
        backgroundColor: red,
        zIndex: -10,

        right: '150%',
        bottom: '170%'
      }
    },

    '@keyframes flameeven': {
      '0%, 100%': {
        width: '0%',
        height: '0%'
      },
      '25%': {
        width: '100%',
        height: '100%',
        right: '2%',
        bottom: '1%'
      },
      '0%': {
        backgroundColor: yellow,
        zIndex: 1000000,
        right: '0%',
        bottom: '0%'
      },
      '40%': {
        backgroundColor: orange,
        zIndex: 1000000
      },
      '100%': {
        backgroundColor: red,
        zIndex: -10,
        right: '170%',
        bottom: '150%'
      }
    }
  }
} satisfies Styles;

export default function Flame(props: FlameProps) {
  const { disable, animationDelay, ...rest } = props;

  const time = React.useMemo(() => {
    if (props.speed) return animationtime / props.speed;
    return animationtime;
  }, [props.speed]);

  return (
    <Box
      component="span"
      {...rest}
      sx={{ ...styles.fire, ...rest.sx } as SxProps<Theme>}>
      <Box sx={styles.flames}>
        <Box
          sx={{
            ...styles.flame,
            animation: `flameodd ${time}s ease-in infinite`,
            animationDelay: `${animationDelay ?? 0}s`,
            animationPlayState: disable ? 'paused' : null
          }}
        />
        <Box
          sx={{
            ...styles.flame,
            animation: `flameeven ${time}s ease-in infinite`,
            animationDelay: `${time / 4 + (animationDelay ?? 0)}s`,
            animationPlayState: disable ? 'paused' : null
          }}
        />
        <Box
          sx={{
            ...styles.flame,
            animation: `flameodd ${time}s ease-in infinite`,
            animationDelay: `${(time / 4) * 2 + (animationDelay ?? 0)}s`,
            animationPlayState: disable ? 'paused' : null
          }}
        />
        <Box
          sx={{
            ...styles.flame,
            animation: `flameeven ${time}s ease-in infinite`,
            animationDelay: `${(time / 4) * 3 + (animationDelay ?? 0)}s`,
            animationPlayState: disable ? 'paused' : null
          }}
        />
      </Box>
    </Box>
  );
}
