import {
  CompanyName,
  CompanyUniversalName,
  LinkedInVectorImage,
  TrackingId
} from './Common';
import {
  CompanyUrn,
  DashCompanyUrn,
  JobUrn,
  MiniCompanyUrn,
  MiniJobUrn
} from './Urns';

const ENTITIES_TYPE_PREFIX = 'com.linkedin.voyager.entities.shared';

export const MINI_COMPANY_TYPE = `${ENTITIES_TYPE_PREFIX}.MiniCompany` as const;

export interface LinkedInMiniCompany {
  $type: typeof MINI_COMPANY_TYPE;
  objectUrn: CompanyUrn;
  entityUrn: MiniCompanyUrn;
  name: CompanyName;
  showcase: boolean;
  active: boolean;
  logo: LinkedInVectorImage;
  universalName: CompanyUniversalName;
  dashCompanyUrn: DashCompanyUrn;
  trackingId: TrackingId;
}

export const MINI_JOB_TYPE = `${ENTITIES_TYPE_PREFIX}.MiniJob` as const;

export interface LinkedInMiniJob {
  $type: typeof MINI_JOB_TYPE;
  listDate: number;
  dashEntityUrn: unknown;
  objectUrn: JobUrn;
  entityUrn: MiniJobUrn;
  logo: LinkedInVectorImage;
  location: string;
  title: string;
  listedAt: number;
  trackingId: TrackingId;
}
