import React from 'react';
import { Box, useTheme } from '@mui/material';
import YouTube from 'react-youtube';

export interface YouTubePlayerProps {
  videoID: string;
  onEnd?: () => void;
  onStart?: () => void;
  disableAutoPlay?: boolean;
}

export type YouTubePlayerEle = HTMLVideoElement & {
  pauseVideo: () => void;
  playVideo: () => void;
  getCurrentTime: () => number;
  getPlayerState: () => YouTubePlayerState;
  videoTitle: string;
};

export enum YouTubePlayerState {
  ENDED = 0,
  PLAYING = 1,
  PAUSED = 2
}

const YouTubePlayer = React.forwardRef<YouTubePlayerEle, YouTubePlayerProps>(
  function YouTubePlayer(props, ref) {
    const theme = useTheme();

    const [started, setStarted] = React.useReducer(
      () => true,
      !props.disableAutoPlay
    );
    React.useEffect(() => {
      if (started) {
        props.onStart?.();
      }
    }, [started]);

    return (
      <Box
        sx={{
          position: 'relative',
          maxWidth: '100%',
          height: '100%',
          margin: 'auto',
          aspectRatio: '16 / 9'
        }}>
        <YouTube
          videoId={props.videoID}
          onReady={(e: { target: YouTubePlayerEle }) => {
            if (!ref) return;
            const element = e.target;
            const playVideo = element.playVideo.bind(element);
            element.playVideo = () => {
              try {
                playVideo();
                return true;
              } catch (e) {
                console.error('Could not play video', e);
                return false;
              }
            };
            if (typeof ref === 'function') ref(element);
            else ref.current = element;
          }}
          onPlay={setStarted}
          onEnd={props.onEnd}
          style={{
            width: '100%',
            position: 'absolute',
            aspectRatio: '16 / 9',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            maxHeight: '100%'
          }}
          opts={{
            height: '100%',
            width: '100%',
            host: 'https://www.youtube.com',
            // https://developers.google.com/youtube/player_parameters
            playerVars: {
              autoplay: props.disableAutoPlay ? 0 : 1,
              origin: window.location.origin,
              frameborder: 0,
              rel: 0,
              showinfo: 0,
              modestbranding: 1,
              hl: 'de',
              controls: 2,
              iv_load_policy: 3,
              color: theme.palette.primary.main
            }
          }}
        />
      </Box>
    );
  }
);

export default YouTubePlayer;
