import { language } from '@/index';
import { Task, TaskConfig } from 'focus-mode-scheduler';
import { TaskIncomingEventMap } from 'focus-mode-scheduler/src/Task/Task';

export default class SummaryTask extends Task {
  public static readonly SETUP_STEP = 'skip_summary';
  public static get config(): TaskConfig {
    return {
      id: 'summary',
      info: {
        title: language.text.summary,
        description: language.text.summary_of_focus_mode,
        hideNoMoreInstancesInfo: true,
        hideNoInstancesInfo: true
      },
      hide: true,
      constraintBreachedDialogContent:
        Task.DEFAULT_CONTRAINT_BREACHED_DIALOG_TEXTS
    };
  }
  protected _header = null;

  protected handleEvent<Type extends keyof TaskIncomingEventMap>(
    type: Type
  ): void {
    switch (type) {
      case 'skip':
      case 'complete':
        this.internalEmit('finished', undefined);
        break;
    }
  }
}
