import { SvgIcon, SvgIconProps } from '@mui/material';
import LogoBlueTextSVG from './logo/logo_blue_with_text.svg?react';
import LogoWhiteTextSVG from './logo/logo_white_with_text.svg?react';
import LogoBlueSVG from './logo/logo_blue.svg?react';

export default function Logo(
  props: SvgIconProps & { variant?: 'blue_text' | 'white_text' | 'blue' }
) {
  const svg =
    props.variant === 'blue_text'
      ? LogoBlueTextSVG
      : props.variant === 'white_text'
        ? LogoWhiteTextSVG
        : LogoBlueSVG;
  const viewbox =
    props.variant === 'blue_text'
      ? '95 20 410 130'
      : props.variant === 'white_text'
        ? '65 115 595 194'
        : '0 0 250 250';

  const height =
    props.height ??
    (props.variant === 'blue_text'
      ? 130
      : props.variant === 'white_text'
        ? 194
        : 250);

  const width =
    props.width ??
    (props.variant === 'blue_text'
      ? 410
      : props.variant === 'white_text'
        ? 595
        : 250);

  return (
    <SvgIcon
      component={svg}
      viewBox={viewbox}
      {...props}
      height={height}
      width={width}
    />
  );
}
