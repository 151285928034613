import { TaskBuilder, TaskOptionsConfig } from 'focus-mode-scheduler';
import { NextTask } from 'focus-mode-scheduler/src/Task/TaskBuilder';
import WritePostTask from './WritePostTask';
import WritePostTaskUI from './WritePostTaskUI';
import { language } from '@/index';

export default class WritePostTaskBuilder extends TaskBuilder<WritePostTask> {
  protected task = WritePostTask;

  protected hasBeenDone = false;
  async getTask(): Promise<NextTask<WritePostTask>> {
    if (this.hasBeenDone) return null;
    this.hasBeenDone = true;

    return {
      task: new WritePostTask(),
      UI: WritePostTaskUI
    };
  }

  public async getOptionsConfig(): Promise<TaskOptionsConfig | null> {
    return {
      time: {
        defaultValue: 15,
        min: 1,
        max: 60,
        scale: 'linear',
        step: 1
      },
      defaultValue: {
        type: 'time',
        time: 15
      },
      items: null,
      texts: {
        taskName: language.text.write_post,
        explanation: language.text.write_post_time_explanation
      }
    };
  }
}
