export const APP_PATHS = {
  Setup: 'setup',
  Focus: 'focus',
  Manual: 'manual',
  Login: 'login',
  Error: 'error',
  Maintenance: 'maintenance',
  Updater: 'updater',
  Debugger: 'debugger',
  Loader: 'loader'
} as const;

export const MANUAL_MODE_PATHS = {
  LinkedIn: 'linkedin',
  Dashboard: 'dashboard',
  Templates: 'templates',
  Chat: 'chat',
  Settings: 'settings',
  Community: 'community',
  Training: 'training',
  Stats: 'stats'
} as const;

export const SETTINGS_PATHS = {
  // About: 'about',
  Progression: 'progression',
  Tags: 'tags',
  General: 'general',
  ThirdPartyLicenses: 'third-party-licenses',
  Goals: 'goals'
} as const;

export const FOCUS_MODE_PATHS = {
  Start: 'start',
  LevelUp: 'level-up',
  Chat: 'chat',
  ExpandNetwork: 'expand-network',
  ExpandNetworkOld: 'expand-network-old',
  Categorize: 'categorize',
  Summary: 'summary',
  Question: 'question',
  ConnectProfile: 'connect-profile',
  EstablishRelation: 'establish-relation',
  Comment: 'comment',
  OnboardingVideo: 'onboarding-video',
  AudienceHolder: 'audience-holder',
  ChatTurial: 'chat-tutorial'
} as const;

export const SETUP_PATHS = {
  AvaTutorial: 'ava-tutorial',
  Terms: 'term',
  Video: 'video',
  Website: 'url',
  Confirmations: 'confirmations',
  Loading: 'loading',
  LinkedInLoading: 'linkedin-loading',
  Success: 'success',
  Usage: 'usage'
} as const;

export const TRAINING_PATHS = {
  Onboarding: 'onboarding',
  SSCTraining: 'ssc-training',
  ExplanationVideos: 'explanation-videos',
  Program: 'program'
};

export const LOGIN_PATHS = {
  SSC: 'ssc',
  LinkedIn: 'linkedin',
  LinkedInLogout: 'linkedin-logout'
} as const;

export const SSC_LOGIN_PATHS = {
  Login: 'login',
  Start: 'start',
  Error: 'error',
  Warning: 'warning'
};

export const UPDATER_PATHS = {
  Updater: 'updater',
  Required: 'required'
};

export const LOADER_PATHS = {
  Post: 'post',
  Network: 'network'
};

export const POST_LOADER_PATHS = {
  Start: 'start',
  Run: 'run'
};

export const NETWORK_LOADER_PATHS = {
  Start: 'start',
  Run: 'run'
};

export const STATS_PATHS = {
  FocusMode: 'focusmode',
  Today: 'today',
  Historic: 'historic'
};
