import React from 'react';
import { Box, Typography } from '@mui/material';

export interface HUDTitleIconProps {
  title?: string;
  icon?: React.ReactNode;
}

export default function HUDTitleIcon(props: HUDTitleIconProps) {
  const { title, icon } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      {icon}
      <Typography
        variant="h4"
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
          ml: (theme) => (icon ? theme.spacing(2) : 0),
          color: (theme) => theme.palette.secondary.main
        }}>
        {title}
      </Typography>
    </Box>
  );
}
