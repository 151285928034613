import { language } from '@/index';
import {
  APP_PATHS,
  LOADER_PATHS,
  MANUAL_MODE_PATHS,
  NETWORK_LOADER_PATHS,
  SETTINGS_PATHS
} from '@/globals/Paths';
import { useNavigate } from 'react-router-dom';
import LoaderStart from '../_generic/LoaderStart';

export default function NetworkLoaderStart() {
  const navigate = useNavigate();

  return (
    <LoaderStart
      title={language.text.load_entire_network}
      description={language.text.load_entire_network_description}
      continue={language.text.load_network}
      onContinue={() =>
        navigate(
          `/${APP_PATHS.Loader}/${LOADER_PATHS.Network}/${NETWORK_LOADER_PATHS.Run}`
        )
      }
      onCancel={() =>
        navigate(
          `/${APP_PATHS.Manual}/${MANUAL_MODE_PATHS.Settings}/${SETTINGS_PATHS.General}`
        )
      }
    />
  );
}
