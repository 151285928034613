import { LanguageObject } from '@common/LanguageManager/LanguageTypes';

export default class PrintableError extends Error {
  public constructor(
    public override readonly message: string,
    public readonly options: {
      internalMessage?: string;
      langKey?: keyof LanguageObject;
      shouldRetry?: boolean;
    } = {}
  ) {
    super(message);
  }
}
