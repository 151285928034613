import { TextField, TextFieldProps } from '@mui/material';

export interface NumberInputProps
  extends Omit<TextFieldProps, 'onChange' | 'value'> {
  onChange: (number: number) => void;
  value: number;
}

export default function NumberInput(props: NumberInputProps) {
  const { value, onChange, ...rest } = props;
  return (
    <TextField
      placeholder="0"
      value={isNaN(value) ? '' : Math.round(value).toString()}
      onChange={(e) =>
        onChange(Math.round(Number(e.target.value.replace(/[^0-9]/g, ''))))
      }
      {...rest}
    />
  );
}
