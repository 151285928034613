import {
  UseInfiniteQueryResult,
  UseQueryResult,
  useInfiniteQuery,
  useQuery
} from 'react-query';
import {
  InfiniteQueryOptionsFromFct,
  QueryOptionsFromFct
} from '@/other/UtilTypes';
import PrintableError from '@common/PrintableError/PrintableError';
import { language } from '@/index';
import log from 'electron-log';
import { useLoadMore } from 'ui-utils';

/**
 * **!!!! IMPORTANT !!!!** \
 * If you use this function your `fct` argument must not have spread arguments or pre-defined arguments \
 * e.g. `function test(a, ...args) {}` or `function test(a, b = "test") {}` are both not allowed
 */
export const createQueryHook: CreateQueryFunction = (fct, name, fctOptions) => {
  const { disableWhenUndefined, staticQueryKey, map, ...defaultOptions } =
    fctOptions ?? {};
  return (...args) => {
    const hasOptions = args.length > fct.length;
    const options = {
      retry(failureCount: number, error: unknown) {
        if (error instanceof PrintableError && !error.options.shouldRetry)
          return false;
        return failureCount < 3;
      },
      ...defaultOptions,
      ...(hasOptions ? (args.at(-1) as QueryOptionsFromFct<typeof fct>) : [])
    };
    const fctArgs = (hasOptions ? args.slice(0, -1) : args) as Parameters<
      typeof fct
    >;
    const queryKey =
      staticQueryKey?.(...fctArgs) ??
      fct.getQueryKey?.(...fctArgs) ??
      (() => {
        log.error('No query key found for function', fct.toString(), fctArgs);
        return ['error'];
      })();
    const { data, ...rest } = useQuery(
      queryKey,
      () => fct(...fctArgs),
      disableWhenUndefined
        ? {
            ...options,
            enabled: !!fctArgs[0] && options?.enabled !== false
          }
        : options
    );

    return {
      ...rest,
      errorString: rest.error
        ? rest.error instanceof PrintableError
          ? rest.error.options.langKey
            ? language.text[rest.error.options.langKey]
            : rest.error.message
          : undefined
        : undefined,
      [name ?? 'data']: map && data ? map(data, ...fctArgs) : data
    } as any;
  };
};

type PartialArray<Arr extends any[]> = Array<Arr[number]> & {
  [key in keyof Arr]?: Arr[key] | null;
};

type CreateQueryFunction = <
  F extends ((...args: any[]) => any) &
    Partial<{
      getQueryKey: GetQueryKeysFct<F>;
    }>,
  Name extends string = 'data',
  Res extends Awaited<ReturnType<F>> = Awaited<ReturnType<F>>,
  MapFct extends (item: Res, ...args: Parameters<F>) => any = (
    item: Res,
    ...args: Parameters<F>
  ) => Res
>(
  fct: F,
  name?: Name,
  fctOptions?: {
    disableWhenUndefined?: boolean;
    staticQueryKey?: (...args: PartialParameters<F>) => string[];
    map?: MapFct;
  } & Partial<QueryOptionsFromFct<F>>
) => (
  ...args: PartialArray<[...Parameters<F>, QueryOptionsFromFct<F>]>
) => Omit<UseQueryResult<Awaited<ReturnType<F>>>, 'data'> & {
  [key in Name]: ReturnType<MapFct>;
} & { errorString: string | undefined };

export type GetQueryKeysFct<F extends (...args: any[]) => any> = (
  ...args: PartialParameters<F>
) => string[];

export type PartialParameters<F extends (...args: any[]) => any> = PartialArray<
  Parameters<F>
>;

/**
 * **!!!! IMPORTANT !!!!** \
 * If you use this function your `fct` argument must not have spread arguments or pre-defined arguments \
 * e.g. `function test(a, ...args) {}` or `function test(a, b = "test") {}` are both not allowed \
 * \
 * The function should also have a cursor as first argument and return an array of items
 */
export const createInfiniteQueryHook: CreateInfiniteQueryFunction = (
  fct,
  name,
  fctOptions
) => {
  const { staticQueryKey, ...defaultOptions } = fctOptions ?? {};
  return (...args) => {
    const hasOptions = args.length > fct.length - 1;
    const { loadMore, ...queryOptions } = (
      hasOptions ? args.at(-1) : {}
    ) as InfiniteQueryOptionsFromFct<typeof fct> & { loadMore?: boolean };
    const options = {
      retry(failureCount: number, error: unknown) {
        if (error instanceof PrintableError && !error.options.shouldRetry)
          return false;
        return failureCount < 3;
      },
      ...defaultOptions,
      ...queryOptions
    };
    const fctArgs = (hasOptions ? args.slice(0, -1) : args) as RemoveFirst<
      Parameters<typeof fct>
    >;
    const { data, ...rest } = useInfiniteQuery(
      staticQueryKey?.(...fctArgs) ??
        fct.getQueryKey?.(...fctArgs) ??
        (() => {
          log.error('No infinite query key found for function', fct, fctArgs);
          return ['error'];
        })(),
      ({ pageParam }) =>
        fct(pageParam, ...fctArgs) as Promise<Awaited<ReturnType<typeof fct>>>,
      {
        ...options,
        getNextPageParam: (lastPage?: Awaited<ReturnType<typeof fct>>) =>
          lastPage?.at(-1) ?? undefined
      } as any
    );

    useLoadMore(
      rest.fetchNextPage,
      loadMore,
      loadMore === undefined || !rest.hasNextPage
    );

    return {
      ...rest,
      errorString: rest.error
        ? rest.error instanceof PrintableError
          ? rest.error.options.langKey
            ? language.text[rest.error.options.langKey]
            : rest.error.message
          : undefined
        : undefined,
      [name ?? 'data']: data?.pages.flat() ?? []
    } as any;
  };
};

type CreateInfiniteQueryFunction = <
  F extends ((
    cursor: Item | undefined,
    ...args: any[]
  ) => any[] | Promise<any[]>) &
    Partial<{
      getQueryKey: (...args: RemoveFirst<Parameters<F>>) => string[];
    }>,
  Item extends Awaited<ReturnType<F>>[number],
  Name extends string = 'data'
>(
  fct: F,
  name?: Name,
  fctOptions?: {
    staticQueryKey?: (...args: RemoveFirst<Parameters<F>>) => string[];
  } & InfiniteQueryOptionsFromFct<F>
) => (
  ...args: PartialArray<
    [
      ...RemoveFirst<Parameters<F>>,
      InfiniteQueryOptionsFromFct<F> & { loadMore?: boolean }
    ]
  >
) => Omit<UseInfiniteQueryResult<Awaited<ReturnType<F>>>, 'data'> & {
  [key in Name]: Awaited<ReturnType<F>>;
} & { errorString: string | undefined };

export type RemoveFirst<T extends [any, ...any[]]> = T extends [any, ...infer U]
  ? U
  : [];
