import AccountActions from '@/data/DataServer/Account';
import { language } from '@/index';
import { Box, Typography } from '@mui/material';
import { DelayedSkeleton } from 'ui-utils';

export default function AccountInformation() {
  const { user } = AccountActions.useUserData();
  return (
    <Box>
      <Typography variant="h4">{language.text.account}</Typography>
      {user ? (
        <>
          <Typography>
            {language.text.name}: {user.firstname} {user.lastname}
          </Typography>
          <Typography>
            {language.text.email}: {user.email}
          </Typography>
          <Typography>
            {language.text.userid}: {user.id}
          </Typography>
        </>
      ) : (
        <DelayedSkeleton
          variant="rounded"
          width={200}
          height={20}
          sx={{ my: '3px' }}
          quantity={3}
        />
      )}
    </Box>
  );
}
