type PartialKeys<T, K extends Partial<T>> = Omit<T, keyof K> & Partial<T>;

export default function BindProps<
  FC extends React.FC<any>,
  Props extends React.ComponentProps<FC>,
  GivenProps extends Partial<Props>
>(
  Component: FC,
  defaultProps: GivenProps
): React.FC<PartialKeys<Props, GivenProps>> {
  return function (props: PartialKeys<Props, GivenProps>) {
    return <Component {...({ ...defaultProps, ...props } as Props)} />;
  };
}
