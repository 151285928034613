import React from 'react';
import {
  Box,
  Button,
  Divider,
  Modal,
  Slide,
  TextField,
  Typography
} from '@mui/material';
import { CheckIcon, FancyButton, MaximizeIcon, MinimizeIcon } from 'ui-utils';
import Auth from '@common/AuthManager/Auth.renderer';
import { language } from '@/index';
import { createSupportTicket } from '@digital-sun-solutions/cloud-functions';

export interface AvaModalProps {
  title?: string;
  hideTitle?: boolean;
  hideTicket?: boolean;
  avaHeight?: number;
  children?: React.ReactNode;
  open: boolean;
  onClose: () => void;
  isExpanded?: boolean;
  canExpand?: boolean;
  expandChanged?: (value: boolean) => void;
  trackHeight?: (value: number) => void;
  onboardingVideo?: Video | null;
}

export default function AvaModal(props: AvaModalProps) {
  const {
    onClose,
    open,
    avaHeight = 196,
    hideTitle = false,
    hideTicket = false,
    title,
    isExpanded,
    canExpand = true,
    expandChanged,
    trackHeight
  } = props;

  const modal = React.useRef<HTMLElement>();

  const [expand, setExpand] = React.useState(isExpanded);
  const [page, setPage] = React.useState<'hint' | 'ticket' | 'ticket-complete'>(
    'hint'
  );
  const [ticketSubmitting, setTicketSubmitting] = React.useState(false);

  const [ticketTitle, setTicketTitle] = React.useState('');
  const [ticketContent, setTicketContent] = React.useState('');

  const handleClose = () => {
    setPage('hint');
    onClose();
  };

  const handleWrapperClose = (e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget != e.target) return;
    onClose();
  };

  const setExpandChanged = (value: boolean) => {
    setExpand(value);
    expandChanged && expandChanged(value);
    if (value) {
      setTimeout(() => {
        trackHeight && trackHeight(modal.current?.clientHeight || 0);
      }, 600);
    } else {
      trackHeight && trackHeight(0);
    }
  };

  const closeTicketView = () => {
    setPage('hint');
    setTicketTitle('');
    setTicketContent('');
  };

  const createTicket = async () => {
    setTicketSubmitting(true);
    try {
      await Auth.execRoute((token) =>
        createSupportTicket(
          {
            subject: ticketTitle,
            text: ticketContent
          },
          {
            token
          }
        )
      );
      setPage('ticket-complete');
      setTicketTitle('');
      setTicketContent('');
    } finally {
      setTicketSubmitting(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-ava-title"
      aria-describedby="modal-ava-description">
      <Box
        onClick={handleWrapperClose}
        sx={{
          'position': 'absolute',
          'display': 'flex',
          'justifyContent': 'center',
          'alignItems': 'flex-end',
          'bottom': avaHeight + 20,
          'left': expand ? '0' : '60px',
          'width': expand ? '100vw' : 'auto',
          'height': expand ? '100vh' : 'auto',
          'zIndex': 100000,
          'maxWidth': expand
            ? '100vw'
            : {
                md: 400,
                lg: 450
              },
          'maxHeight': expand
            ? 'calc(100vh - ' + (avaHeight + 20) + 'px)'
            : {
                md: 400,
                lg: 450
              },
          'transition':
            'max-width 0.6s cubic-bezier(0, 0, 0.2, 1) 0ms, max-height 0.6s cubic-bezier(0, 0, 0.2, 1) 0ms',
          '&:focus-visible': {
            outline: 'none'
          }
        }}>
        <Slide in={open} timeout={300} direction={'up'}>
          <Box
            sx={{
              'width': expand
                ? '80%'
                : {
                    md: 300,
                    lg: 355
                  },
              'height': expand
                ? '95%'
                : {
                    md: 400,
                    lg: 450
                  },
              // 'position': 'absolute',
              'display': 'flex',
              'flexDirection': 'column',
              'borderRadius': 1,
              '&:focus-visible': {
                outline: 'none'
              },
              'border': 0,
              'backgroundColor': (theme) => theme.palette.background.default
            }}>
            {!hideTitle && (
              <Box
                sx={{
                  position: 'relative',
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: (theme) => theme.palette.primary.contrastText,
                  textAlign: 'center',
                  padding: (theme) => theme.spacing(2, 2, 3, 2)
                }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 'bold'
                  }}>
                  {page === 'ticket-complete'
                    ? language.text.thanks_for_your_support
                    : page === 'ticket'
                      ? language.text.create_ticket
                      : title || language.text.avas_tip}
                </Typography>
                {canExpand &&
                  (() => {
                    const Icon = expand ? MinimizeIcon : MaximizeIcon;
                    return (
                      <Icon
                        onClick={() => setExpandChanged(!expand)}
                        sx={{
                          width: '32px',
                          height: '32px',
                          position: 'absolute',
                          right: '16px',
                          top: '16px',
                          cursor: 'pointer'
                        }}
                      />
                    );
                  })()}
              </Box>
            )}

            {page === 'ticket' || page === 'ticket-complete' ? (
              <Box
                sx={{
                  position: 'relative',
                  zIndex: 10,
                  height: '100%',
                  overflowY: 'auto',
                  backgroundColor: (theme) => theme.palette.background.default,
                  mt: -1,
                  p: 2,
                  borderRadius: 1
                }}>
                {page === 'ticket-complete' ? (
                  <Box
                    sx={{
                      maxWidth: {
                        md: '500px',
                        lg: '600px',
                        xl: '700px'
                      },
                      mx: 'auto'
                    }}>
                    <CheckIcon
                      sx={{
                        borderRadius: '100%',
                        border: '6px solid',
                        borderColor: (theme) => theme.palette.success.main,
                        color: (theme) => theme.palette.success.main,
                        mt: (theme) => theme.spacing(2),
                        mb: (theme) => theme.spacing(2),
                        display: 'block',
                        width: '80px',
                        mx: 'auto',
                        height: 'auto'
                      }}
                    />
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        mb: (theme) => theme.spacing(2)
                      }}>
                      {language.text.ticket_submitted}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: 'center',
                        m: (theme) => theme.spacing(1, 3, 2)
                      }}>
                      {language.text.ticket_we_will_solve_the_ticket_soon}
                    </Typography>

                    <FancyButton
                      variant="contained"
                      sx={{
                        display: 'block',
                        mx: 'auto',
                        width: expand ? 'auto' : '100%'
                      }}
                      onClick={() => closeTicketView()}>
                      {language.text.ticket_back_to_ssc}
                    </FancyButton>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      maxWidth: {
                        md: '500px',
                        lg: '500px',
                        xl: '500px'
                      },
                      mx: 'auto',
                      my: 1,
                      display: 'flex',
                      flexDirection: 'column'
                    }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        mb: 2
                      }}>
                      {language.text.is_there_a_problem}
                    </Typography>
                    <TextField
                      disabled={ticketSubmitting}
                      autoFocus
                      placeholder={language.text.ticket_title_name}
                      variant="outlined"
                      value={ticketTitle}
                      onChange={(e) => setTicketTitle(e.target.value)}
                      fullWidth
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.background.paper,
                        mb: (theme) => theme.spacing(1)
                      }}
                    />
                    <TextField
                      disabled={ticketSubmitting}
                      multiline
                      rows={6}
                      placeholder={language.text.ticket_description_placeholder}
                      variant="outlined"
                      value={ticketContent}
                      onChange={(e) => setTicketContent(e.target.value)}
                      fullWidth
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.background.paper,
                        minHeight: 125
                      }}
                    />
                    <Box
                      sx={{
                        mt: (theme) => theme.spacing(2),
                        display: 'flex',
                        justifyContent: 'center'
                      }}>
                      <Button
                        disabled={ticketSubmitting}
                        variant="outlined"
                        color="inherit"
                        sx={{
                          mr: (theme) => theme.spacing(0.5),
                          width: '100%'
                        }}
                        onClick={() => setPage('hint')}>
                        {language.text.cancel}
                      </Button>
                      <FancyButton
                        disabled={!ticketTitle || !ticketContent}
                        variant="contained"
                        fType={{
                          promise: true
                        }}
                        sx={{
                          ml: (theme) => theme.spacing(0.5),
                          width: '100%'
                        }}
                        onClick={() => createTicket()}>
                        {language.text.ticket_submit}
                      </FancyButton>
                    </Box>
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                ref={(ref: HTMLElement) => (modal.current = ref)}
                sx={{
                  position: 'relative',
                  zIndex: 10,
                  height: '100%',
                  overflowY: 'auto',
                  backgroundColor: (theme) => theme.palette.background.default,
                  mt: -1,
                  p: 2,
                  borderRadius: 1
                }}>
                <Box
                  sx={{
                    'width': {
                      md: expand ? '100%' : '100%',
                      lg: expand ? '80%' : '100%',
                      xl: expand ? '60%' : '100%'
                    },
                    'margin': 'auto',
                    'p, li': {
                      fontSize: {
                        md: expand ? '1.2rem' : '1rem',
                        lg: expand ? '1.5rem' : '1rem',
                        xl: expand ? '1.7rem' : '1rem'
                      },
                      mt: expand
                        ? {
                            md: 'inherit',
                            lg: 2
                          }
                        : undefined
                    }
                  }}>
                  <Typography>{props.children}</Typography>
                </Box>
              </Box>
            )}

            {!hideTicket && page === 'hint' && (
              <>
                <Divider orientation="horizontal" />
                <Box
                  sx={{
                    position: 'relative',
                    px: 2,
                    py: 1,
                    textAlign: 'center'
                  }}>
                  <Button
                    variant="outlined"
                    sx={{
                      px: (theme) => theme.spacing(expand ? 4 : 1),
                      width: expand ? 'auto' : '100%'
                    }}
                    onClick={() => setPage('ticket')}>
                    {language.text.report_problem}
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Slide>
      </Box>
    </Modal>
  );
}
