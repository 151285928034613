import React from 'react';
import PulsatingPromiseButton from '../PulsatingButton/PulsatingPromiseButton';
import DisabledTooltip, { DisabledTooltipProps } from './DisabledTooltip';

export type DisabledTooltipPulsatingPromiseButtonProps = DisabledTooltipProps<
  typeof PulsatingPromiseButton
>;

const DisabledTooltipPulsatingPromiseButton = React.forwardRef(
  (
    props: DisabledTooltipPulsatingPromiseButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    return (
      <DisabledTooltip
        component={PulsatingPromiseButton}
        {...props}
        ref={ref}
      />
    );
  }
);

export default DisabledTooltipPulsatingPromiseButton;
