import React from 'react';
import { Box, Button, SxProps, Typography } from '@mui/material';

export interface SettingsGenericInfoProps {
  header: string;
  children: React.ReactNode | React.ReactNode[];
  icon?: React.FC<{ sx: SxProps }>;
  button?: {
    label: string;
    onClick: () => void;
  };
}

export default function SettingsGenericInfo(props: SettingsGenericInfoProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        p: 2,
        height: '100%',
        boxSizing: 'border-box'
      }}>
      {props.icon && (
        <props.icon sx={{ fontSize: '4rem', color: 'primary.main' }} />
      )}
      <Typography variant="h6" textAlign="center">
        {props.header}
      </Typography>
      <Typography textAlign="center">{props.children}</Typography>
      {props.button && (
        <Button
          sx={
            props.icon
              ? {
                  marginBottom: 10
                }
              : {}
          }
          variant="contained"
          color="primary"
          onClick={() => props.button?.onClick()}>
          {props.button?.label}
        </Button>
      )}
    </Box>
  );
}
