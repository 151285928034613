export abstract class CursorIterator<T> implements AsyncIterator<T[], T[]> {
  protected cursor: string;

  protected done = false;

  protected abstract fetch(): Promise<Iterable<T>>;

  protected constructor({ cursor = '' }: { cursor?: string }) {
    this.cursor = cursor;
  }

  async next(): Promise<IteratorResult<T[], T[]>> {
    if (this.done) {
      return Promise.resolve({ done: true, value: [] });
    }
    const res = [...(await this.fetch().catch(() => []))];
    if (res.length === 0) {
      return Promise.resolve({ done: true, value: [] });
    }
    return Promise.resolve({ done: false, value: res });
  }
}
