import React from 'react';
import { Box, Chip, Typography } from '@mui/material';
import PeopleGrid from '@/components/TaskHeader/PeopleGrid';
import LinkedInWebview from '@common/Webview.renderer/Base/LinkedInWebview';
import { LinkedInWebviewElement } from '@common/Webview.renderer/Base/ContentScriptTypes';
import { useSnackbar } from 'notistack';
import TaskHeaderDrawer from '@/components/TaskHeader/TaskHeaderDrawer';
import ExpandNetworkTask from './ExpandNetworkTask';
import { Objects } from '@idot-digital/generic-helpers';
import { language } from '@/index';
import { CSEventType } from 'webview-preload';
import { useScheduler } from 'focus-mode-scheduler';
import BrokenAudienceHolders from '../_general/BorkenAudienceHolders/BrokenAudienceHolders';
import ContactActions from '@/data/DataServer/Contact';
import MessageBus from '@common/MessageBus/MessageBus.renderer';
import OnboardingVideo from '../_general/OnboardingVideo/OnboardingVideo';
import tracking from 'tracking';
import posthog from 'posthog-js';

export interface ExpandNetworkTaskUIProps {
  task: ExpandNetworkTask;
}

export default function ExpandNetworkTaskUI(props: ExpandNetworkTaskUIProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { task } = props;
  const { constraints } = useScheduler();

  const { profiles } = ContactActions.useMarkedProfiles();
  const initialProfileCount = React.useRef(task.existingContacts.length);

  React.useEffect(() => {
    const newProfilesCount = Math.max(
      0,
      (profiles?.length ?? 0) - initialProfileCount.current
    );
    constraints.setItemsDone(newProfilesCount);
  }, [profiles]);

  const [audienceHoldersBroken, setAudienceHoldersBroken] =
    React.useState(false);
  const [linkedInKey, forceReloadLinkedIn] = React.useReducer<
    (x: number) => number
  >((x) => x + 1, 0);

  const messageHandler = React.useCallback(
    async <Event extends CSEventType>(channel: Event) => {
      switch (channel) {
        case 'linkedin:invalid-search':
          setAudienceHoldersBroken(true);
          break;
      }
    },
    [task]
  );

  const webviewRef = React.useRef<LinkedInWebviewElement | null>(null);

  const headerTextColor = React.useMemo(() => {
    if (!profiles) return undefined;
    const peopleLength = profiles.length;
    const keys = Objects.keys(ExpandNetworkTask.PHASES).map((k) => Number(k));
    const phase = keys.reduce((prev, curr) => {
      if (peopleLength >= curr && curr > prev) return curr;
      return prev;
    }, keys[0]);
    return ExpandNetworkTask.PHASES[phase];
  }, [profiles]);

  const constraint = constraints.useCurrentConstraint();
  const headerText = React.useMemo(() => {
    if (!profiles) return '';
    if (constraint?.type !== 'items') return profiles.length;
    // filter out deleted profiles
    const existing = task.existingContacts.filter((c) =>
      profiles.some((p) => p.profileID === c.profileID)
    );
    return `${profiles.length} / ${constraint.items + existing.length}`;
  }, [constraint, profiles]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%'
      }}>
      <OnboardingVideo
        onboardingStep="focus:expandNetwork"
        offset={{ y: 60 }}
        completeOn="finish"
      />
      <TaskHeaderDrawer
        text={
          <>
            <Chip
              label={headerText}
              sx={{
                'height': (theme) => theme.spacing(3.25),
                'color': headerTextColor,
                'pointerEvents': 'none',
                'fontSize': {
                  lg: '1em',
                  xl: '1.2em'
                },
                '& span': {
                  px: (theme) => theme.spacing(1)
                }
              }}
            />
            <Typography
              sx={{
                ml: 1,
                pointerEvents: 'none'
              }}>
              {language.text.x_marked_profiles}
            </Typography>
          </>
        }
        defaultOpenMode="full">
        <PeopleGrid
          people={
            profiles?.map((p) => ({
              firstName: p.firstname,
              lastName: p.lastname,
              profileID: p.profileID,
              profilePictureUrl: p.pictures,
              profilePicture: p.pictures['800'],
              priority: p.priority
            })) ?? []
          }
          maxPerLine={7}
          onPersonRemove={(person) =>
            ContactActions.removeMarkedProfile(person.profileID)
          }
        />
      </TaskHeaderDrawer>
      <LinkedInWebview
        key={linkedInKey}
        ref={webviewRef}
        messageHandler={messageHandler}
        features={[
          'disable-connecting',
          'focus-mode-style',
          'highlight-audience-holders-posts',
          'mark-profiles'
        ]}
        onProfileTypeChanged={({ newType }) => {
          if (newType === 'marked') {
            tracking.capture('ProfilesMarked');
            posthog.capture('profile-marked');
          }
        }}
        onOpenChat={() => {
          enqueueSnackbar(
            language.text.find_potential_customers_before_chatting,
            { variant: 'info' }
          );
          return false;
        }}
        startURL={task.startLink ?? undefined}
      />
      <BrokenAudienceHolders
        audienceHoldersBroken={audienceHoldersBroken}
        audienceHolders={task.audienceHolders}
        onResolved={(audienceHolders) => {
          task.setAudienceHolders(audienceHolders);
          setAudienceHoldersBroken(false);
          forceReloadLinkedIn();
        }}
      />
    </Box>
  );
}
