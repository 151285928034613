import SetupActions from '@/data/DataServer/Setup';
import { OnboardingSteps } from '@common/types/Setup';
import ManagedOnboardingVideo, {
  ManagedOnboardingVideoProps
} from './ManagedOnboardingVideo';
import React from 'react';

export interface OnboardingVideoProps
  extends Omit<ManagedOnboardingVideoProps, 'video'> {
  onboardingStep: OnboardingSteps;
  completeOn?: 'mount' | 'unmount' | 'finish';
  disableCompleteOnClosed?: boolean;
}

export default function OnboardingVideo(props: OnboardingVideoProps) {
  const { onboardingStep, completeOn, disableCompleteOnClosed, ...rest } =
    props;
  const { video, isSuccess } = SetupActions.useOnboardingVideo(onboardingStep);

  const [cachedVideo, setCachedVideo] = React.useState(video);
  React.useEffect(() => {
    if (video) setCachedVideo(video);
  }, [video]);

  React.useEffect(() => {
    // wait until steps are fetched to update, to not complete video before it is shown
    if (!isSuccess) return;
    if (!video) return;
    if (completeOn === 'mount')
      SetupActions.completeOnboardingStep(onboardingStep);
    if (completeOn === 'unmount')
      return () => {
        SetupActions.completeOnboardingStep(onboardingStep);
      };
    return;
  }, [isSuccess]);

  return (
    <ManagedOnboardingVideo
      {...rest}
      video={cachedVideo ? cachedVideo : null}
      onClosed={() => {
        if (!disableCompleteOnClosed)
          SetupActions.completeOnboardingStep(onboardingStep);
      }}
      onEnd={
        completeOn === 'finish'
          ? () => {
              SetupActions.completeOnboardingStep(onboardingStep);
            }
          : undefined
      }
    />
  );
}
