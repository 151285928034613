import { ButtonProps, Button, Box } from '@mui/material';
import PromiseButton, {
  PromiseButtonProps
} from '../FancyButtons/PromiseButton/PromiseButton';
import { language } from '../../Config';
import React from 'react';
import {
  ConfirmingButtonGroupContextType,
  useConfirmingButtonGoupContext
} from './ConfirmingButtonContext';

export interface ConfirmingButtonProps extends Omit<ButtonProps, 'onClick'> {
  onClick?: PromiseButtonProps['onClick'];
  confirmationButtonProps?: Omit<PromiseButtonProps, 'onClick'>;
  confirmationText?: string;
  showConfirmation?: boolean;
  width?: string | number;
  onOpenStateChange?: (open: boolean) => void;
}

const ConfirmingButton = React.forwardRef<
  HTMLButtonElement,
  ConfirmingButtonProps
>(function ConfirmingButton(props, ref) {
  const context = useConfirmingButtonGoupContext() as
    | ConfirmingButtonGroupContextType
    | undefined;
  const id = React.useRef(context?.getIndex() ?? -1);

  const {
    onClick,
    confirmationText,
    confirmationButtonProps,
    showConfirmation,
    onOpenStateChange,
    width,
    sx,
    ...rest
  } = props;

  const [isActive, setShowConfirmation] = React.useState(
    showConfirmation ?? false
  );

  const active =
    showConfirmation ??
    (context ? context.activeButtonId === id.current : isActive);

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        position: 'relative',
        width: width ?? '100%',
        overflow: 'hidden'
      }}>
      {/* Primary Button */}
      <Button
        variant="contained"
        color="primary"
        {...rest}
        onClick={() => {
          setShowConfirmation(!active);
          onOpenStateChange?.(!active);
          context?.setActiveButtonId(!active ? id.current : null);
        }}
        sx={{
          width: active ? 'calc(50% - 1px)' : '100%',
          transition: (theme) =>
            theme.transitions.create('width', {
              duration: theme.transitions.duration.short,
              easing: theme.transitions.easing.easeOut
            }),
          flexShrink: 0,
          ...sx
        }}
      />

      {/* Confirmation Button */}
      <PromiseButton
        variant="outlined"
        {...confirmationButtonProps}
        onClick={onClick}
        sx={{
          position: 'relative',
          width: 'calc(50% - 1px)',
          flexShrink: 0,
          fontWeight: 'bold',
          ml: '2px',
          ...confirmationButtonProps?.sx
        }}>
        {confirmationText ?? language.text.confirm}
      </PromiseButton>
    </Box>
  );
});

export default ConfirmingButton;
