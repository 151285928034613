import StatsComparison from '@/components/Stats/StatsComparison';
import StatsActions from '@/data/DataServer/Stats';
import { language } from '@/index';
import { PriorityHigh } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { Loading } from 'ui-utils';

export interface RealtimeStatsProps {
  type: 'today' | 'focusmode';
}

export default function RealtimeStats(props: RealtimeStatsProps) {
  const { today, status: todayStatus } = StatsActions.useToday({
    enabled: props.type === 'focusmode'
  });
  const { focusModeStats, status: focusModeStatus } =
    StatsActions.useLastFocusModeRun({ enabled: props.type === 'focusmode' });
  const status = props.type === 'focusmode' ? focusModeStatus : todayStatus;
  const stats = props.type === 'focusmode' ? focusModeStats : today;

  if (status !== 'success') {
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center">
      {status === 'loading' && <Loading />}
      {status === 'error' && (
        <>
          <PriorityHigh sx={{ fontSize: '5rem' }} />
          <Typography variant="h4">{language.text.server_error}</Typography>
        </>
      )}
    </Box>;
  }

  return (
    <StatsComparison stats={stats} header={language.text.your_achivements} />
  );
}
