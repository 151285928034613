import { LINKEDIN_REALTIME_URL, REALTIME_HEADER } from '../client/Config';
import { Logger, trace } from '../client/Utils';
import { Client } from '../client/Client';
import { Event, RealtimeEvent } from 'linkedin-domain-types';
import { RealtimeEventParser } from '../parser/RealtimeEventParser';
// polyfill for EventSource - we can't use the built-in one because it doesn't support headers
import 'eventsource/example/eventsource-polyfill.js';
import EventSource from 'eventsource';
declare global {
  interface Window {
    EventSourcePolyfill: typeof EventSource;
  }
}

@trace()
export class RealtimeService {
  /**
   * EventSource for realtime events
   */
  private realtimeSource?: EventSource;
  private ready = false;
  private listening = false;

  /**
   * Creates a new instance for interactions with the realtime api
   * @param client The client to use for initializing the realtime api
   */
  constructor(readonly client: Client) {
    new RealtimeEventParser(client.eventService);
    Logger.debug('Adding realtime listener for login event');
    client.eventService.addListener(Event.LOGIN, () => {
      Logger.debug('Login event received, opening realtime connection');
      this.ready = true;
    });
    client.eventService.addListener(Event.LOGOUT, () => {
      Logger.debug('Logout event received, closing realtime connection');
      this.ready = false;
      this.close();
    });
    client.eventService.addListener(Event.CLOSE, () => {
      Logger.debug('Close event received, closing realtime connection');
      this.close();
    });
  }

  /**
   * Initializes the realtime api
   */
  public open() {
    if (!this.ready) {
      Logger.debug('Not logged in, waiting for login event');
      this.listening = true;
      this.client.eventService.addListener(Event.LOGIN, () => this._open());
    } else if (!this.listening) {
      Logger.debug('Already logged in, opening realtime connection');
      this.listening = true;
      this._open();
    }
  }
  private _open() {
    if (this.realtimeSource) {
      Logger.debug('Realtime connection already open');
      return;
    }

    Logger.debug('Detected browser: Using native EventSource');
    this.realtimeSource = new window.EventSourcePolyfill(
      LINKEDIN_REALTIME_URL,
      {
        withCredentials: true,
        headers: REALTIME_HEADER
      }
    );
    this.realtimeSource.onerror = (err: globalThis.Event | MessageEvent) =>
      Logger.warn(`Realtime error ${JSON.stringify(err)}`);
    this.realtimeSource.onmessage = (event: MessageEvent<string>) => {
      let data = JSON.parse(event.data) as object;
      if (typeof data !== 'object') data = { data };
      this.client.eventService.emit({
        type: Event.REALTIME,
        data
      } as RealtimeEvent);
    };
    this.realtimeSource.onopen = () =>
      Logger.debug('Successfully opened realtime connection');
  }

  /**
   * Closes the realtime api
   */
  public close() {
    if (this.realtimeSource) {
      this.realtimeSource.close();
      this.realtimeSource = undefined;
    }
  }
}
