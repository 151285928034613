import React from 'react';
import LinkedInWebview from '@common/Webview.renderer/Base/LinkedInWebview';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';
import ContactsWrapper from './Contacts/ContactsWrapper';
import TemplatesWrapper from './Templates/TemplatesWrapper';
import Chat from './Chat/Chat';
import Settings from './Settings/Settings';
import Training from './Training/Training';
import { useManualMode } from './ManualModeContext';
import { useSnackbar } from 'notistack';
import { IconProps, Typography } from '@mui/material';
import { OnboardingSteps } from '@common/types/Setup';
import SetupActions from '@/data/DataServer/Setup';
import { language } from '@/index';
import { MANUAL_MODE_PATHS } from '@/globals/Paths';
import {
  ChatIcon,
  DashboardIcon,
  GenericVideoModal,
  LinkedInIcon,
  PilotIcon,
  PipelineIcon,
  RandomAnimatedLoading,
  SettingIcon
} from 'ui-utils';
import { posthog } from 'posthog-js';
import Stats from './Stats/Stats';
import { QueryStats } from '@mui/icons-material';
import { FeatureFlags, useFeatureFlags } from '@/globals/featureFlags';

export const getManualModeRoutes = (featureFlags: FeatureFlags) =>
  [
    {
      name: language.text.linkedin,
      icon: (props: React.ComponentProps<typeof LinkedInIcon>) => (
        <LinkedInIcon
          {...props}
          sx={{ fontSize: '2rem', mb: '10.2px', ...props.sx }}
        />
      ),
      path: MANUAL_MODE_PATHS.LinkedIn,
      component: () => <></>
    },
    {
      name: language.text.dashboard,
      icon: DashboardIcon,
      path: MANUAL_MODE_PATHS.Dashboard,
      component: ContactsWrapper,
      onboardingStep: 'manual:dashboard'
    },
    {
      name: language.text.chat,
      icon: ChatIcon,
      path: MANUAL_MODE_PATHS.Chat,
      removeRightPadding: true,
      component: Chat,
      onboardingStep: 'manual:chatCockpit'
    },
    {
      name: language.text.pilot_training,
      icon: (props: React.ComponentProps<typeof PilotIcon>) => (
        <PilotIcon {...props} sx={{ fontSize: '3rem', ...props.sx }} />
      ),
      path: MANUAL_MODE_PATHS.Training,
      component: Training,
      onboardingStep: 'manual:training'
    },
    {
      name: language.text.templates,
      icon: PipelineIcon,
      path: MANUAL_MODE_PATHS.Templates,
      component: TemplatesWrapper,
      onboardingStep: 'manual:templates'
    },
    {
      name: language.text.stats,
      icon: QueryStats,
      path: MANUAL_MODE_PATHS.Stats,
      component: Stats,
      hidden: !featureFlags.manualMode_stats_page
    },
    {
      name: language.text.settings,
      icon: SettingIcon,
      path: MANUAL_MODE_PATHS.Settings,
      component: Settings
    }
  ] as {
    name: string;
    icon: React.FC<Pick<IconProps, 'sx'>>;
    path: string;
    removeRightPadding?: boolean;
    component: React.FC;
    onboardingStep?: OnboardingSteps;
    hidden?: boolean;
  }[];

type ManualModePaths =
  (typeof MANUAL_MODE_PATHS)[keyof typeof MANUAL_MODE_PATHS];

export const LOCAL_STORAGE_START_PATH = 'manual-mode-start-path';

export type ManualRoutes =
  (typeof MANUAL_MODE_PATHS)[keyof typeof MANUAL_MODE_PATHS];

export default function ManualModeRouter(props: { linkedinActive: boolean }) {
  const { openChat } = useManualMode();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const featureFlags = useFeatureFlags();
  const ROUTES = React.useMemo(
    () => getManualModeRoutes(featureFlags),
    [featureFlags]
  );

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const startPath = localStorage.getItem(LOCAL_STORAGE_START_PATH);
    if (!startPath) return;
    localStorage.removeItem(LOCAL_STORAGE_START_PATH);
    if (
      !Object.values(MANUAL_MODE_PATHS).some((path) =>
        startPath.startsWith(path)
      )
    )
      return;
    requestAnimationFrame(() => {
      navigate(startPath);
    });
  }, []);

  const { pathname } = useLocation();
  const [video, setVideo] = React.useState<Video | null>(null);
  const [videoOpen, setVideoOpen] = React.useState(false);
  const onboardingStep = React.useRef<OnboardingSteps | null>(null);
  // check if current location is first time opening -> display video
  React.useEffect(() => {
    const currentPath = pathname.split('/')[2] as ManualModePaths;
    const currentOnboardingStep = ROUTES.find(
      (route) => route.path === currentPath
    )?.onboardingStep;
    if (!currentOnboardingStep) return;
    let mounted = true;
    (async () => {
      const video = await SetupActions.checkOnboardingStep(
        currentOnboardingStep
      );
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- can be null
      if (!mounted) return;
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- logical OR is intended behavior
      setVideo(video || null);
      if (video) {
        setVideoOpen(true);
        await SetupActions.completeOnboardingStep(currentOnboardingStep);
        onboardingStep.current = currentOnboardingStep;
      }
    })();
    return () => {
      mounted = false;
    };
  }, [pathname]);

  return (
    <>
      {video && (
        <GenericVideoModal
          title={video.title}
          videoID={video.videoID}
          open={videoOpen}
          onStart={() => {
            if (onboardingStep.current) {
              posthog.capture(`onboarding-${onboardingStep.current}-started`);
            }
          }}
          onClose={(ended) => {
            setVideoOpen(false);

            if (onboardingStep.current) {
              posthog.capture(
                `onboarding-${onboardingStep.current}-${ended ? 'completed' : 'cancel'}`
              );
            }
          }}
        />
      )}
      <RandomAnimatedLoading show={loading} />
      <LinkedInWebview
        visible={props.linkedinActive}
        onOpenChat={(contact) => {
          if (!contact.conversationID) {
            enqueueSnackbar(language.text.inmail_not_supported, {
              variant: 'error'
            });
          } else
            openChat({
              profileID: contact.profile.profileID,
              conversationID: contact.conversationID
            });
          setLoading(false);
          return false;
        }}
        onOpenChatInitial={() => setLoading(true)}
        onOpenChatError={() => {
          setLoading(false);
          enqueueSnackbar(language.text.error_while_loading_chat, {
            variant: 'error'
          });
        }}
        features={['highlight-audience-holders-posts', 'save-profiles']}
      />
      <Routes>
        <Route index element={<Navigate to={MANUAL_MODE_PATHS.LinkedIn} />} />
        {ROUTES.map((route) => (
          <Route
            element={<route.component />}
            key={route.path}
            path={`${route.path}/*`}
          />
        ))}
        <Route
          path="*"
          element={<Typography p={2}>{language.text.generic_error}</Typography>}
        />
      </Routes>
    </>
  );
}
