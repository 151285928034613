import React from 'react';
import { Avatar, Box, BoxProps } from '@mui/material';

export interface AvatarGridProps extends BoxProps {
  children: React.ReactNode | React.ReactNode[];
  maxSize?: number;
  baseSize?: number;
}

const AvatarGrid = React.forwardRef<HTMLDivElement, AvatarGridProps>(
  (props, ref) => {
    const { children, maxSize = 2, baseSize = 5.5, ...boxProps } = props;

    const childrenArray = React.Children.toArray(children);
    const squareSize = Math.min(
      Math.ceil(Math.sqrt(childrenArray.length)),
      maxSize
    );
    const rowsNeeded = Math.min(
      Math.ceil(childrenArray.length / squareSize),
      maxSize
    );
    const spots = squareSize * rowsNeeded;

    const slicedChildren = childrenArray.slice(0, spots - 1);

    const remaining = childrenArray.length - spots + 1;

    return (
      <Box
        {...boxProps}
        ref={ref}
        sx={{
          'display': 'grid',
          'gridTemplateColumns': `repeat(${squareSize}, 1fr)`,
          'gridTemplateRows': `repeat(${rowsNeeded}, 1fr)`,
          'justifyContent': 'center',
          'alignItems': 'center',
          'gap': 0.25,
          'height': (theme) => theme.spacing(baseSize),
          'width': (theme) => theme.spacing(baseSize),
          '.MuiAvatar-root': {
            height: (theme) => theme.spacing(baseSize / squareSize - 0.5),
            width: (theme) => theme.spacing(baseSize / squareSize - 0.5),
            fontSize:
              ((1 / squareSize) * 1.25 * (baseSize / 5.5)).toString() + 'rem'
          },

          ...boxProps.sx
        }}>
        {slicedChildren}
        {remaining === 1 ? (
          childrenArray.at(-1)
        ) : (
          <Avatar alt={`+${remaining}`}>+{remaining}</Avatar>
        )}
      </Box>
    );
  }
);

export default AvatarGrid;
