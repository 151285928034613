import React from 'react';
import {
  Box,
  Chip,
  Fab,
  ListItem,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import LinkedInWebview from '@common/Webview.renderer/Base/LinkedInWebview';
import { LinkedInWebviewElement } from '@common/Webview.renderer/Base/ContentScriptTypes';
import { useSnackbar } from 'notistack';
import CommentTask from './CommentTask';
import HUDFocusBar from '@/components/HUD/HUDFocusBar';
import { language } from '@/index';
import { CSComment, CSEventData, CSEventType } from 'webview-preload';
import ContactAvatar from '@/components/Avatar/ContactAvatar';
import { useScheduler } from 'focus-mode-scheduler';
import { SpeakerIcon } from 'ui-utils';
import OnboardingVideo from '../_general/OnboardingVideo/OnboardingVideo';
import SetupActions from '@/data/DataServer/Setup';

export interface CommentTaskUIProps {
  task: CommentTask;
}

export default function CommentTaskUI(props: CommentTaskUIProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { task } = props;
  const { constraints } = useScheduler();

  const [comments, setComments] = React.useState<CSComment[]>([]);

  const messageHandler = React.useCallback(
    <Event extends CSEventType>(channel: Event, data: CSEventData<Event>) => {
      if (task.id !== 'comment') return;
      switch (channel) {
        case 'linkedin:save-comment':
          const comment = data as CSComment;
          setComments((prev) => {
            constraints.setItemsDone(prev.length + 1);
            return [...prev, comment];
          });
          task.commentPosted(comment);
          break;
      }
    },
    [task]
  );

  const webviewRef = React.useRef<LinkedInWebviewElement | null>(null);

  const fabRef = React.useRef<HTMLButtonElement | null>(null);
  const [fabOpen, setFabOpen] = React.useState(false);

  // store public identifiers to not load twice
  const gotoAudienceHolder = (holder: CommentTask['audienceHolders'][0]) => {
    setFabOpen(false);
    webviewRef.current?.loadURL(holder.activityURL);
  };

  const headerTextColor = React.useMemo(() => {
    if (!(task instanceof CommentTask)) return undefined;
    const peopleLength = comments.length;
    const keys = Object.keys(CommentTask.PHASES).map((k) => Number(k));
    const phase = keys.reduce((prev, curr) => {
      if (peopleLength >= curr && curr > prev) return curr;
      return prev;
    }, keys[0]);
    return CommentTask.PHASES[phase];
  }, [comments]);

  const constraint = constraints.useCurrentConstraint();
  const constraintText = React.useMemo(() => {
    if (constraint?.type !== 'items') return comments.length;
    return `${constraint.itemsDone} / ${constraint.items}`;
  }, [comments, constraint]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%'
      }}>
      <OnboardingVideo
        onboardingStep="focus:comments"
        offset={{ y: 60 }}
        completeOn="finish"
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: (theme) => theme.palette.secondary.main,
          p: 1,
          borderBottomLeftRadius: (theme) => theme.shape.borderRadius,
          borderBottomRightRadius: (theme) => theme.shape.borderRadius
        }}>
        <Chip
          label={constraintText}
          sx={{
            color: headerTextColor,
            pointerEvents: 'none',
            fontSize: '1.2em'
          }}
        />
        <Typography
          sx={{
            ml: 1,
            pointerEvents: 'none'
          }}>
          {language.text.comments_written}
        </Typography>
      </Box>
      <LinkedInWebview
        ref={webviewRef}
        messageHandler={messageHandler}
        features={[
          'disable-connecting',
          'focus-mode-style',
          'highlight-audience-holders-posts'
        ]}
        onOpenChat={() => {
          enqueueSnackbar(language.text.no_chatting_comments_task, {
            variant: 'info'
          });
          return false;
        }}
        startURL={task.startLink}
      />
      <HUDFocusBar>
        {task.audienceHolders.length > 0 && (
          <>
            <Fab
              variant="extended"
              ref={fabRef}
              onClick={() => setFabOpen((prev) => !prev)}
              color="primary"
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                // be above linkedin but below task header people drawer
                zIndex: 100
              }}>
              <SpeakerIcon sx={{ mr: 1 }} />
              {language.text.change_audience_holder}
            </Fab>
            <Menu
              open={fabOpen}
              anchorEl={fabRef.current}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              onClose={() => setFabOpen(false)}>
              <ListItem divider dense>
                <Typography textAlign="center" fontSize="1.2em">
                  {language.text.audience_holder}
                </Typography>
              </ListItem>
              {task.audienceHolders.map((holder) => (
                <MenuItem
                  onClick={() => gotoAudienceHolder(holder)}
                  key={holder.profileID}>
                  <ContactAvatar
                    contact={holder}
                    sx={{
                      mr: 1,
                      height: (theme) => theme.spacing(4),
                      width: (theme) => theme.spacing(4)
                    }}
                  />
                  <Typography>{holder.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </HUDFocusBar>
    </Box>
  );
}
