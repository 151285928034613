import { LangaugeKeys } from '@common/LanguageManager/LanguageTypes';
import MessageBus from '@common/MessageBus/MessageBus.renderer';
import {
  ContentScriptEvent,
  LinkedInWebviewElement
} from '@common/Webview.renderer/Base/ContentScriptTypes';
import { wait } from '@idot-digital/generic-helpers';
import {
  CSAction,
  CSActionOptions,
  CSActionReturn,
  CSEventData,
  CSEventType
} from 'webview-preload';
import * as Sentry from '@sentry/electron/renderer';

async function executeAction<Action extends CSAction>(
  webview: LinkedInWebviewElement,
  action: CSActionOptions<Action>['action'],
  data: CSActionOptions<Action>['data'],
  onHeartbeat?: (info: string | undefined) => void
): Promise<CSActionReturn<Action>> {
  const messageBus = MessageBus.getInstance();
  let resolve: (data: CSActionReturn<Action>) => void = () => undefined;
  let reject: (error: unknown) => void = () => undefined;
  let finished = false;

  function listener<Event extends CSEventType>(e: ContentScriptEvent<Event>) {
    const channel = e.channel;
    if (channel === 'linkedin:action-done') {
      const data = e.args[0] as CSEventData<'linkedin:action-done'>;
      if (data.action !== action) return;
      webview.removeEventListener('ipc-message', listener);
      if (data.error) {
        reject(new Error(data.error));
        Sentry.captureException(data.error, {
          extra: {
            data: data.data,
            action: action
          }
        });
        console.error(`[LinkedInActions:executeAction:${action}]`, data.error);
      } else {
        resolve(data.data as CSActionReturn<Action>);
      }
      finished = true;
    } else if (channel === 'linkedin:action-heartbeat') {
      const info = e.args[0] as CSEventData<'linkedin:action-heartbeat'>;
      resetTimeout(info?.timeout);

      const key = info?.info as LangaugeKeys | undefined;
      if (key)
        messageBus.emit('load:info', {
          status: key,
          data: info?.infoData
        });

      onHeartbeat?.(info?.info);
      Sentry.addBreadcrumb({
        message: `[LinkedInActions:executeAction:${action}] Heartbeat: ${info?.info ?? '[no info]'}`,
        category: 'started',
        type: 'debug'
      });
    }

    // open profile reloads page -> wait for ssc:ping -> next page is loaded
    if (
      action === 'open-profile' &&
      channel === 'ssc:ping' &&
      webview.getURL().includes('/in/')
    ) {
      webview.removeEventListener('ipc-message', listener);
      resolve(undefined as CSActionReturn<Action>);
      finished = true;
    }
  }
  //@ts-expect-error -- removeEventListener for "ipc-message" is not correctly typed for webview
  webview.addEventListener('ipc-message', listener);

  const promise = new Promise<CSActionReturn<Action>>((res, rej) => {
    resolve = res;
    reject = rej;
  });

  webview.send('linkedin:action', { action, data });

  let timeout: NodeJS.Timeout | null = null;
  /**
   * Restart action if no response is received after 60 seconds
   */
  let timeoutCount = 0;
  let MAX_TIMEOUTS = 3;
  function resetTimeout(length = 60_000) {
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => {
      if (finished) return;
      timeoutCount++;
      if (timeoutCount >= MAX_TIMEOUTS) {
        webview.removeEventListener('ipc-message', listener);
        const errorString = `Timeout waiting for linkedin:action-done for action ${action}`;
        reject(new Error(errorString));
        console.error(errorString);
      } else {
        console.warn(
          `[LinkedInActions:executeAction] Timeout ${timeoutCount}, retrying action`
        );
        Sentry.addBreadcrumb({
          message: `[LinkedInActions:executeAction:${action}] Timeout ${timeoutCount}, retrying action`,
          category: 'started',
          type: 'error'
        });
        webview.send('linkedin:action', { action, data });
        resetTimeout();
      }
    }, length);
  }
  resetTimeout();

  try {
    return await promise;
  } catch (err) {
    Sentry.captureException(err, {
      attachments: [
        {
          filename: 'webview.html',
          data: await webview.executeJavaScript<string>(
            'document.documentElement.outerHTML'
          )
        }
      ],
      data: {
        action
      }
    });
    throw new Error(
      `Error while executing linkedin action '${action}': ${typeof err === 'string' ? err : err instanceof Error ? err.message : JSON.stringify(err)}`
    );
  }
}

interface OvertakeOptions {
  lockWebview?: boolean;
  forceBackground?: boolean;
}

async function execute(
  actions: (CSAction | CSActionOptions)[],
  options?: OvertakeOptions & { webview?: LinkedInWebviewElement }
) {
  let webview: LinkedInWebviewElement;
  let onRelease: () => void = () => undefined;
  if (options?.webview) webview = options.webview;
  else {
    const newWebview = await overtakeWebview(options);
    webview = newWebview.webview;
    onRelease = newWebview.onRelease;
  }
  const response: CSActionReturn<CSAction>[] = [];
  try {
    for (const item of actions) {
      const action =
        typeof item === 'string' ? { action: item, data: undefined } : item;
      const data = await executeAction(webview, action.action, action.data);
      response.push(data);
      await wait(500);
    }
    return response;
  } finally {
    onRelease();
  }
}

interface LatestWebview {
  webview: LinkedInWebviewElement;
  onOvertake: () => void;
  onRelease: (link: string) => void;
}

let latestWebview: LatestWebview | null = null;

let getBackgroundWebview: (() => Promise<LatestWebview>) | null = null;

function registerMountWebview(
  mountWebview: () => Promise<LinkedInWebviewElement>,
  unmountWebview: () => void
) {
  getBackgroundWebview = async () => {
    const webview = await mountWebview();
    return {
      webview,
      onOvertake: () => undefined,
      onRelease: () => {
        unmountWebview();
      }
    };
  };
}

function registerLatestWebview(webview: LatestWebview) {
  latestWebview = webview;
}

function unregisterLatestWebview(webview: LinkedInWebviewElement | null) {
  if (latestWebview?.webview === webview) latestWebview = null;
}

async function overtakeWebview(options?: OvertakeOptions) {
  const parsedOptions = {
    lockWebview: true,
    forceBackground: false,
    ...options
  };
  const forceBackground = parsedOptions.forceBackground;
  let lockWebview = parsedOptions.lockWebview;
  let isBackgroundWebview = false;

  // don't use normal webview if we force background
  let webview = forceBackground ? null : latestWebview;
  // if no webview is available, try to get one
  if (!webview) {
    isBackgroundWebview = true;
    webview = (await getBackgroundWebview?.()) ?? null;
    // no need to overtake if we use the webview created just for this action
    lockWebview = false;
  }
  // if still no webview is available, throw an error
  if (!webview) throw new Error('No webview available');

  // save current link to return to after overtake
  const currentLink = webview.webview.getURL();
  if (lockWebview) webview.onOvertake();

  return {
    webview: webview.webview,
    onRelease:
      lockWebview || isBackgroundWebview
        ? webview.onRelease.bind(null, currentLink)
        : () => undefined
  };
}

async function getWebview({
  forceBackground = false
}: {
  forceBackground?: boolean;
} = {}) {
  if (latestWebview && !forceBackground) return latestWebview;
  return await getBackgroundWebview?.();
}

const LinkedInActions = {
  executeAction,
  execute,
  overtakeWebview,
  getWebview,
  internal: {
    registerMountWebview,
    registerLatestWebview,
    unregisterLatestWebview
  }
};

export default LinkedInActions;

window.LinkedInActions = LinkedInActions;
