import MainConfig from '../../Config';
import { generateUUID } from './Utils';

/**
 * The LinkedIn API URL
 */
export const LINKEDIN_API_URL = 'https://www.linkedin.com/voyager/api/';

/**
 * The LinkedIn API URL for authentication
 */
export const LINKEDIN_AUTH_URL = 'https://www.linkedin.com/uas/authenticate';

/**
 * The LinkedIn API URL for login
 */
export const LINKEDIN_LOGIN_URL =
  'https://www.linkedin.com/checkpoint/lg/login-submit';

export const LINKEDIN_META_URL =
  'https://www.linkedin.com/litms/api/metadata/user';

/**
 * The LinkedIn API URL for logout
 */
export const LINKEDIN_LOGOUT_URL = 'https://www.linkedin.com/uas/logout';

/**
 * The LinkedIn API URL for realtime events
 */
export const LINKEDIN_REALTIME_URL =
  'https://www.linkedin.com/realtime/connect';

/**
 * Timeout for requests
 */
export const REQUEST_TIMEOUT = MainConfig.linkedInTimout;

/**
 * The timeout for conversation requests
 */
export const CONVERSATION_TIMEOUT = 1000;

/**
 * The timeout for getting individual profiles requests
 */
export const GET_PROFILE_TIMEOUT = 1000;

/**
 * Max number of retries for requests
 */
export const MAX_RETRIES = 5;

/**
 * Headers for authentication requests
 */
export const AUTH_HEADER = {
  // 'user-agent':
  //   'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.5359.62 Safari/537.36',
  'accept-encoding': 'gzip, deflate',
  'Accept': '*/*',
  'Connection': 'keep-alive',
  'x-li-lang': 'en_US',
  'Accept-Language': 'en-us'
  // 'Referer': 'https://www.linkedin.com/login'
};

/**
 * Headers for requests
 */
export const REQUEST_HEADER = {
  'authority': 'www.linkedin.com',
  'x-restli-protocol-version': '2.0.0',
  'x-li-lang': 'en_US',
  // 'user-agent':
  //   'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.5359.62 Safari/537.36',
  'x-li-page-instance': 'urn:li:page:d_flagship3_feed;N2rwHUFIQrykkt4exT64CQ==',
  'Accept': 'application/vnd.linkedin.normalized+json+2.1',
  'x-li-track':
    '{"clientVersion":"1.13.9378","mpVersion":"1.13.9378","osName":"web","timezoneOffset":1,"timezone":"Europe/Berlin","deviceFormFactor":"DESKTOP","mpName":"voyager-web","displayDensity":1,"displayWidth":1920,"displayHeight":1080}',
  // 'sec-fetch-site': 'same-origin',
  // 'sec-fetch-mode': 'cors',
  // 'referer':
  //   'https://www.linkedin.com/feed/?trk=guest_homepage-basic_nav-header-signin',
  // 'accept-encoding': 'gzip, deflate, br',
  'accept-language': 'en-US,en;q=0.9'
};

/**
 * Headers for realtime requests
 */
export const REALTIME_HEADER = {
  // 'User-Agent':
  //   'Mozilla/5.0 (Windows NT 10.0; rv:109.0) Gecko/20100101 Firefox/116.0',
  'Accept': 'text/event-stream',
  'Accept-Language': 'en-US,en;q=0.5',
  'Accept-Encoding': 'gzip, deflate, br',
  // 'Referer': 'https://www.linkedin.com/feed/',
  'x-RestLi-Protocol-Version': '2.0.0',
  'x-li-query-accept': 'application/graphql',
  'x-li-accept': 'application/vnd.linkedin.normalized+json+2.1',
  'x-li-track':
    '{"clientVersion":"1.13.9378","mpVersion":"1.13.9378","osName":"web","timezoneOffset":1,"timezone":"Europe/Berlin","deviceFormFactor":"DESKTOP","mpName":"voyager-web","displayDensity":1,"displayWidth":1920,"displayHeight":1080}',
  'x-li-realtime-session': generateUUID(),
  'Sec-Fetch-Dest': 'empty',
  'Sec-Fetch-Mode': 'cors',
  'Sec-Fetch-Site': 'same-origin',
  'Sec-GPC': '1',
  'Pragma': 'no-cache',
  'Cache-Control': 'no-cache',
  'TE': 'trailers'
};

export const LINKEDIN_FILE_HEADER = {
  'Accept': 'image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8',
  'Accept-Encoding': 'gzip, deflate, br',
  'Accept-Language': 'en-DE,en;q=0.8',
  'Dnt': '1',
  'Pragma': 'no-cache',
  'Referer':
    'https://www.linkedin.com/feed/?trk=guest_homepage-basic_nav-header-signin',
  'Sec-Ch-Ua': '"Not_A Brand";v="8", "Chromium";v="120", "Brave";v="120"',
  'Sec-Ch-Ua-Mobile': '?0',
  'Sec-Ch-Ua-Platform': '"Windows"',
  'Sec-Fetch-Dest': 'image',
  'Sec-Fetch-Mode': 'no-cors',
  'Sec-Fetch-Site': 'same-origin',
  'Sec-Gpc': '1',
  'User-Agent':
    'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36'
};

/**
 * Used for debugging parameters and return values in trace logs.
 */
export const VERBOSE_LOGS = false;
