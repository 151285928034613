import { FullEventData } from '@common/MessageBus/MessageBusUtilTypes';
import { useState } from 'react';
import { JsonView } from 'react-json-view-lite';
import MessagesList, { findStringinObject } from './MessagesList';
import MessageBus from '@common/MessageBus/MessageBus.renderer';

export default function Debugger() {
  const [selectedMessage, setselectedMessage] = useState<
    (FullEventData & { date: Date }) | null
  >(null);

  const [searchQuery, setSearchQuery] = useState('');

  return (
    <div
      style={{
        backgroundColor: '#eee',
        padding: '8px',
        minWidth: '100%',
        minHeight: '100%',
        boxSizing: 'border-box',
        paddingRight: '16px',
        fontFamily: 'monospace'
      }}>
      <h1
        style={{ marginTop: 0, textAlign: 'center', fontFamily: 'monospace' }}>
        Message Bus Debugger
      </h1>

      <div
        style={{
          display: 'grid',
          gridTemplateAreas: '"list button" "list json"',
          gridTemplateColumns: '50% 50%',
          gridTemplateRows: 'auto 1fr',
          gap: '8px',
          boxSizing: 'border-box'
        }}>
        <div
          style={{
            gridArea: 'list'
          }}>
          <MessagesList
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            messageClicked={setselectedMessage}
          />
        </div>
        {selectedMessage !== null ? (
          <>
            <button
              style={{
                gridArea: 'button',
                fontFamily: 'monospace'
              }}
              onClick={() => {
                MessageBus.getInstance().emit(
                  selectedMessage.eventName,
                  selectedMessage.payload as any
                );
              }}>
              re-emit
            </button>
            <div style={{ gridArea: 'json' }}>
              <JsonView
                data={selectedMessage?.payload ?? {}}
                shouldExpandNode={(level, value) => {
                  if (
                    searchQuery.length > 0 &&
                    findStringinObject(value, searchQuery)
                  )
                    return true;
                  if (level > 1) return false;
                  return true;
                }}
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
