import React from 'react';
import { Box, Typography } from '@mui/material';
import { Styles } from '@/other/UtilTypes';
import { useManualMode } from '../ManualModeContext';
import { useLinkedInAccount } from '@/data/LinkedIn/Account';
import AccountAvatar from '@/components/Avatar/AccountAvatar';
import { DelayedSkeleton } from 'ui-utils';
import { useAutoAnimate } from '@formkit/auto-animate/react';

export interface AccountBadgeProps {
  onlyAvatar?: boolean;
}

const styles = {
  wrapper: {
    border: (theme) => `1px solid ${theme.palette.divider}`,
    margin: (theme) => theme.spacing(1),
    borderRadius: 2,
    background: (theme) => theme.palette.background.paper,
    padding: (theme) => theme.spacing(1),
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  textWrapper: {
    marginX: (theme) => theme.spacing(1),
    flexGrow: 1,
    maxWidth: 300
  },
  handle: {
    color: (theme) => theme.palette.action.disabled
  },
  text: {
    lineHeight: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
} satisfies Styles;

export default function AccountBadge(props: AccountBadgeProps) {
  const [animationRef] = useAutoAnimate();
  const [animationRefName] = useAutoAnimate();
  const { openLinkedIn } = useManualMode();
  const { linkedInAccount } = useLinkedInAccount();

  const name = React.useMemo(() => {
    if (linkedInAccount)
      return linkedInAccount.firstName + ' ' + linkedInAccount.lastName;
    return '';
  }, [linkedInAccount]);

  const handle = React.useMemo(() => {
    if (linkedInAccount) return '@' + linkedInAccount.publicIdentifier;
    return '';
  }, [linkedInAccount]);

  return (
    <Box
      sx={{
        ...styles.wrapper,
        cursor: linkedInAccount ? 'pointer' : undefined
      }}
      ref={animationRef}
      onClick={() => {
        if (linkedInAccount) openLinkedIn(linkedInAccount);
      }}>
      <AccountAvatar />
      {!props.onlyAvatar && (
        <Box sx={styles.textWrapper} ref={animationRefName}>
          {name ? (
            <Typography variant="body1" fontWeight="bold" sx={styles.text}>
              {name}
            </Typography>
          ) : (
            <DelayedSkeleton width="100%" height={24} />
          )}
          {handle ? (
            <Typography
              variant="caption"
              sx={{ ...styles.handle, ...styles.text }}>
              {handle}
            </Typography>
          ) : (
            <DelayedSkeleton width="100%" height={17} sx={{ mt: '2px' }} />
          )}
        </Box>
      )}
    </Box>
  );
}
