import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import AffiliateActions from '@/data/DataServer/Affiliate';
import { Info } from '@mui/icons-material';
import { language } from '@/index';
import { DelayedSkeleton, MoneyCircle } from 'ui-utils';

export default function AffiliateStats() {
  const { earnings, status } = AffiliateActions.useTotalEarnings();
  const { freeToUseCoupons } = AffiliateActions.useFreeToUseCoupons();
  const { discountCoupons } = AffiliateActions.useDiscountCodes();

  const totalReferred = React.useMemo(() => {
    if (freeToUseCoupons && discountCoupons)
      return [...freeToUseCoupons, ...discountCoupons].reduce(
        (acc, code) => acc + code.number_of_uses,
        0
      );
    return null;
  }, [freeToUseCoupons, discountCoupons]);

  if (status === 'loading' || totalReferred === null)
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <DelayedSkeleton variant="circular" height={200} width={200} />
      </Box>
    );
  if (status === 'error')
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <Typography color="error" variant="h6">
          {language.text.error_loading_balance}
        </Typography>
      </Box>
    );

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, minmax(5vw, 300px))',
        gap: 4,
        justifyContent: 'center',
        width: '100%',
        p: 2,
        boxSizing: 'border-box'
      }}>
      {[
        {
          title: language.text.customers,
          value: totalReferred,
          percentage: 1,
          format: (value: number) => value.toString()
        },
        {
          title: (
            <>
              {language.text.balance}
              <Tooltip
                placement="bottom"
                title={
                  <Typography>{language.text.balance_explanation}</Typography>
                }>
                <Info
                  color="disabled"
                  fontSize="small"
                  sx={{ ml: 0.5, verticalAlign: 'middle' }}
                />
              </Tooltip>
            </>
          ),
          value: earnings,
          percentage: earnings > 0 ? 1 : 0
        }
      ].map((item, index) => (
        <MoneyCircle
          key={index}
          value={item.value}
          filled={item.percentage}
          title={item.title}
          format={item.format}
        />
      ))}
    </Box>
  );
}
