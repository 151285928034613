import { EventHandlerAndEmitter } from '@idot-digital/generic-helpers';
import { DialogContent } from '../components/ConstraintDialogs';
import { language } from '../Config';

export interface TaskHeader {
  title: string;
  subtitle?: string;
  skip?: Partial<{
    category: {
      disable?: boolean;
      text?: string;
      omitConfirmationDialog?: boolean;
    };
    instance: {
      enable: boolean;
      text?: string;
      omitConfirmationDialog?: boolean;
    };
  }>;
}

/**
 * Options for the task
 */
export interface TaskOptionsValue {
  iteration: number | null;
  time: number | null;
}

export enum TaskLoadingType {
  SHORT = 'loading',
  LONG = 'loading-long',
  NONE = 'none'
}

export interface TaskInfo {
  title: string;
  description: string;
  avaContent?: React.ReactNode;
  hideNoMoreInstancesInfo?: boolean;
  hideNoInstancesInfo?: boolean;
}

export interface TaskIncomingEventMap {
  complete: undefined;
  skip: undefined;
}

export interface TaskOutgoingEventMap {
  'error': {
    error: Error;
    title?: string;
    code?: string;
  };
  'finished': undefined;
  'finish-aborted': undefined;
}

export interface TaskDialogContent {
  time: DialogContent;
  items: DialogContent;
}

export interface TaskConfig {
  /**
   * Unique identifier of the task. Needs to be unique across all tasks
   */
  id: string;
  /**
   * Title and description of the task (displayed in the UI)
   */
  info: TaskInfo;
  /**
   * If the task should be hidden in the UI
   */
  hide: boolean;
  /**
   * Texts of dialogs that open when a constraint is breached
   */
  constraintBreachedDialogContent: TaskDialogContent;
}

/**
 * Base class for all tasks \
 * Implement `handleEvent` to save data on `complete` or `skip` event \
 */
export default abstract class Task<
  ExternalIncomingEventMap extends Record<string, any> = {},
  ExternalOutgoingEventMap extends Record<string, any> = {}
> extends EventHandlerAndEmitter<
  TaskIncomingEventMap & ExternalIncomingEventMap,
  TaskOutgoingEventMap & ExternalOutgoingEventMap
> {
  /**
   * Get the id of the task
   */
  public get id() {
    return (this.constructor as typeof Task).config.id;
  }

  /**
   * All the information about the task \
   * Should be defined as getter, when using language, because it is not initialized when script is loaded
   */
  public static config: TaskConfig;

  /**
   * Header to display in the UI (null to hide header)
   */
  protected abstract _header: TaskHeader | null;
  /**
   * Get the header of the task
   */
  public get header(): TaskHeader | null {
    return this._header;
  }

  public static get DEFAULT_CONTRAINT_BREACHED_DIALOG_TEXTS(): TaskDialogContent {
    return {
      items: {
        header: language.text.max_reached,
        body: language.text.goal_reached,
        buttons: {
          oneMore: {
            text: language.text.one_more,
            amount: 1
          },
          next: {
            text: language.text.next
          }
        }
      },
      time: {
        header: language.text.max_reached,
        body: language.text.you_reached_max_time,
        buttons: {
          oneMore: {
            text: language.text.one_more_minute,
            amount: 2
          },
          next: {
            text: language.text.next
          }
        }
      }
    };
  }
}
