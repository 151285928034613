import { useEffect } from 'react';
import UsedLicenses from './UsedLicenses';
import posthog from 'posthog-js';
import { Box } from '@mui/material';

export default function ThirdPartyLicenses() {
  useEffect(() => {
    posthog.capture('viewed_third_party_licenses');
  }, []);

  return (
    <Box sx={{ userSelect: 'none' }}>
      <p>
        Inspirational quotes provided by{' '}
        <a href="https://zenquotes.io/" target="_blank">
          ZenQuotes API
        </a>
      </p>
      <UsedLicenses />
    </Box>
  );
}
