import { FullProfile, MessageTemplate } from '@common/types/ApiTypes';
import { SSCChat } from './Chat/SSCChat';
import { Contact } from './Contact';
import { TutorialChat } from './TutorialChat';
import { PipelineStep } from '@common/PipelineManager/PipelineTypes';
import { ContactType } from '@common/types/enums';

export class TutorialContact extends Contact {
  constructor(
    profileData: Pick<
      FullProfile['profile'],
      'firstname' | 'lastname' | 'headline' | 'pictures'
    >,
    _step?: PipelineStep
  ) {
    super(
      {
        id: -1,
        profile: {
          ...profileData,
          profileID: 'UNKNOWN',
          publicIdentifier: 'UNKNOWN'
        },
        info: {
          notes: '',
          priority: 100
        },
        tags: []
      },
      'self'
    );
  }

  public get activityURL(): string {
    return `${this.profileURL}recent-activity/all/`;
  }

  public get hasChat(): boolean {
    return true;
  }

  private _notes = '';
  public get notes() {
    return this._notes;
  }

  public async setNotes(text: string): Promise<void> {
    this._notes = text;
  }

  public static getNotesQueryKey(): string[] {
    return ['contact', 'notes', 'tutorial'];
  }

  public async getChat(
    template?: MessageTemplate | null
  ): Promise<TutorialChat> {
    return new TutorialChat({
      contact: this,
      template: template ?? undefined
    });
  }

  /**
   *
   * @param chat optional param to use chat cache instead of fetching messages again
   */
  public async setPipelineStep(
    _step: PipelineStep,
    _chat?: SSCChat
  ): Promise<void> {}

  public async completePipeline(_result: boolean): Promise<void> {}

  public async setContactType(
    _specialType: ContactType,
    _closeOpenPipelines = true
  ): Promise<void> {}

  public async categorize(
    _category: ContactType,
    _stepID?: PipelineStep['id']
  ): Promise<void> {}
}
