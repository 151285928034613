import React from 'react';
import { Box, BoxProps, IconButton, Typography } from '@mui/material';
import { Contact } from '@/data/Classes/Contact';
import TagChip from './TagChip';
import { language } from '@/index';
import TagMenu from './TagMenu';
import { Add } from '@mui/icons-material';

export interface ContactTagsProps extends BoxProps {
  contact: Pick<Contact, 'tags' | 'removeTag' | 'addTag'> | undefined | null;
  tagSize?: 'small' | 'medium';
  editable?: boolean;
}

export default function ContactTags(props: ContactTagsProps) {
  const { contact, tagSize, editable, ...boxProps } = props;
  const [tagMenuOpen, setTagMenuOpen] = React.useState(false);
  const tagMenuAnchor = React.useRef<HTMLButtonElement>(null);

  // in some cases there is no update through cache invalidation
  // in those cases we need to force update to rerender the changed tags
  const forceUpdate = React.useReducer((x) => x + 1, 0)[1];

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      gap={1}
      alignItems="center"
      ref={tagMenuAnchor}
      {...boxProps}>
      {props.contact?.tags.map((tag) => (
        <TagChip
          key={tag.name}
          tag={tag}
          size={tagSize ?? 'small'}
          onDelete={
            editable
              ? async () => {
                  await contact?.removeTag(tag.name);
                  forceUpdate();
                }
              : undefined
          }
        />
      ))}
      {contact?.tags.length === 0 && (
        <Typography variant="caption" textAlign="center" fontStyle="italic">
          {language.text.no_tags}
        </Typography>
      )}
      {editable && (
        <>
          <IconButton
            onClick={() => setTagMenuOpen(true)}
            sx={{
              height: 32,
              width: 32
            }}>
            <Add height={24} width={24} />
          </IconButton>
          <TagMenu
            contact={props.contact}
            anchorEl={tagMenuAnchor.current}
            open={tagMenuOpen}
            onClose={() => setTagMenuOpen(false)}
            onChange={() => forceUpdate()}
          />
        </>
      )}
    </Box>
  );
}
