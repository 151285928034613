import Auth from '@common/AuthManager/Auth.renderer';
import { Box } from '@mui/material';
import LoginVideo from './LoginVideo';
import StartNow from './StartNow';
import { useFeatureFlags } from '@/globals/featureFlags';

export interface StartSSCProps {
  next: () => void;
}

export default function StartSSC(props: StartSSCProps) {
  const { ssc_login_10_days_challenge } = useFeatureFlags();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        px: 8,
        boxSizing: 'border-box'
      }}>
      <Box
        sx={{
          width: '50%',
          maxWidth: 'max(80vmin, 500px)',
          margin: {
            sm: 'auto',
            lg: '0'
          },
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          zIndex: 10,
          boxSizing: 'border-box'
        }}>
        <StartNow next={props.next} />
      </Box>
      {ssc_login_10_days_challenge && (
        <Box overflow="hidden" flexGrow={1} mr={4} maxWidth="90vh">
          <LoginVideo days={Auth.getSessionInfo().usageDays ?? 0} />
        </Box>
      )}
    </Box>
  );
}
