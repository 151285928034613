import { Button } from '@mui/material';
import { Download } from '@mui/icons-material';
import { Message } from '@common/types/ApiTypes';
import { language } from '@/index';
import { useLinkedInData } from '@/data/DataServer/LinkedInFetch';
import React from 'react';
import FullscreenImage from './FullscreenImage';

export interface ChatAttachmentProps {
  attachment: Message['attachments'][0];
  onLoad?: (height: number) => void;
}

function getFileName(type: Message['attachments'][0]['type']) {
  switch (type) {
    case 'audio':
      return 'audio.mp3';
    case 'file':
      return 'file';
    case 'image':
      return 'image.jpeg';
  }
}
function getFileType(type: Message['attachments'][0]['type']) {
  switch (type) {
    case 'audio':
      return 'audio/mp3';
    case 'file':
      return '';
    case 'image':
      return 'image/jpeg';
  }
}

export default function ChatAttachment(props: ChatAttachmentProps) {
  const { blob } = useLinkedInData(props.attachment.url, {
    enabled: props.attachment.type !== 'file'
  });
  const file = React.useMemo(
    () =>
      blob
        ? new File([blob], getFileName(props.attachment.type), {
            type: getFileType(props.attachment.type)
          })
        : undefined,
    [blob]
  );
  const url = React.useMemo(
    () => (file ? URL.createObjectURL(file) : undefined),
    [file]
  );

  const [fullscreenOpen, setFullscreenOpen] = React.useState(false);

  if (props.attachment.type === 'image')
    return (
      <>
        <img
          src={url}
          style={{
            cursor: 'pointer',
            height: '500px',
            maxWidth: '100%',
            objectFit: 'contain',
            objectPosition: 'center'
          }}
          onClick={() => setFullscreenOpen(true)}
          onLoad={(e) => {
            props.onLoad?.((e.target as HTMLElement).clientHeight);
          }}
        />
        <FullscreenImage
          open={fullscreenOpen}
          src={url ?? ''}
          onClose={() => setFullscreenOpen(false)}
        />
      </>
    );

  if (props.attachment.type === 'audio')
    return (
      <audio
        id="audio"
        controls
        src={url}
        style={{ width: '100%', minWidth: 150 }}
      />
    );

  if (props.attachment.type === 'file')
    return (
      <Button
        onClick={() => window.api.send('url:open_ext', props.attachment.url)}
        variant="contained"
        color="inherit"
        startIcon={<Download />}>
        {language.text.download_file}
      </Button>
    );

  return null;
}
