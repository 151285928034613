import { useNavigate } from 'react-router-dom';
import { SetupProvider } from './SetupContext';
import SetupRouter from './SetupRouter';
import SetupFrame from './SetupFrame';

export interface SetupProps {
  basePath: string;
  redirectAfterFinish: string;
}

export default function Setup(props: SetupProps) {
  const navigate = useNavigate();
  return (
    <SetupProvider
      basePath={props.basePath}
      onFinished={() => {
        // navigate should not be called on first render of component
        requestAnimationFrame(() => {
          navigate(props.redirectAfterFinish, {
            relative: 'path'
          });
        });
      }}>
      <SetupFrame>
        <SetupRouter />
      </SetupFrame>
    </SetupProvider>
  );
}
