import React from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Fab,
  TextField
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, Tooltip, Typography } from '@mui/material';
import { LinkedIn } from '@mui/icons-material';
import { useManualMode } from '../ManualModeContext';
import { Contact } from '@/data/Classes/Contact';
import { CommentAltIcon, FancyButton, SaveIcon, WritingIcon } from 'ui-utils';
import ContactCategorySelector from './ContactCateogrySelector';
import { wait } from '@idot-digital/generic-helpers';
import { language } from '@/index';
import { Pipelines } from '@common/PipelineManager/Pipelines';
import { ContactType } from '@common/types/enums';
import ContactActions from '@/data/DataServer/Contact';
import ContactTags from '@/components/Contact/ContactTags';
import ContactAvatar from '@/components/Avatar/ContactAvatar';

export interface ContactDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  contact: Contact | null;
  onRecategorize?: (category: ContactType) => void;
}

export default function ContactDetailsDialog(props: ContactDetailsDialogProps) {
  const { openLinkedIn, openChat } = useManualMode();

  const [notesOpen, setNotesOpen] = React.useState(false);
  const [notes, setNotes] = React.useState<string | null>(null);
  const [notesSaving, setNotesSaving] = React.useState(false);
  React.useEffect(() => {
    setNotes((notes) => notes ?? props.contact?.notes ?? null);
  }, [props.contact]);
  React.useEffect(() => {
    setNotesOpen(false);
  }, [props.contact]);

  const { history } = ContactActions.usePipelineHistory(
    props.contact?.profileID
  );
  const step = React.useMemo(() => {
    if (!props.contact?.lastPipelineEvent) return null;
    return Pipelines.getStepToDisplay(
      props.contact.lastPipelineEvent.currentStep,
      history?.map((h) => h.currentStep) ?? []
    );
  }, [props.contact?.lastPipelineEvent]);

  return (
    <>
      <Dialog
        onClose={props.onClose}
        fullWidth={true}
        maxWidth="lg"
        open={props.open}>
        <HighlightOffIcon
          sx={{
            position: 'absolute',
            top: (theme) => theme.spacing(1),
            right: (theme) => theme.spacing(1),
            cursor: 'pointer'
          }}
          onClick={props.onClose}
        />
        <DialogContent>
          {props.contact && (
            <Box
              sx={{
                display: 'flex',
                paddingBottom: (theme) => theme.spacing(2)
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: (theme) => theme.spacing(1),
                  flex: '1',
                  boxSizing: 'border-box'
                }}>
                <ContactAvatar
                  contact={props.contact}
                  sx={{
                    width: (theme) => theme.spacing(20),
                    height: (theme) => theme.spacing(20),
                    cursor: 'pointer'
                  }}
                  rootSx={{
                    mt: (theme) => theme.spacing(-15),
                    position: 'fixed'
                  }}
                  borderSizeModifier={2}
                  onClick={() => {
                    if (props.contact) openLinkedIn(props.contact);
                  }}
                />
                <Box
                  sx={{
                    backgroundColor: (theme) => theme.palette.background.paper,
                    borderRadius: 1.5,
                    padding: (theme) => theme.spacing(5, 1, 1, 1),
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: (theme) => theme.spacing(21),
                    width: '100%',
                    marginTop: (theme) => theme.spacing(1),
                    boxSizing: 'border-box'
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%'
                    }}>
                    <Typography
                      sx={{
                        fontSize: '1.5rem',
                        fontWeight: 700,
                        textAlign: 'center',
                        cursor: 'pointer'
                      }}>
                      {props.contact.name}
                    </Typography>

                    {step && (
                      <Typography
                        sx={{
                          textAlign: 'center',
                          fontSize: '1.2rem'
                        }}>
                        {step?.name || '-'}
                      </Typography>
                    )}

                    <ContactTags editable contact={props.contact} />

                    {props.contact.infoText && (
                      <Typography
                        variant="caption"
                        sx={{
                          textAlign: 'center',
                          mt: 2
                        }}>
                        {props.contact.infoText}
                      </Typography>
                    )}
                  </Box>

                  <Box
                    display="flex"
                    gap={1}
                    alignItems="center"
                    justifyContent="center">
                    <Button
                      variant="text"
                      size="small"
                      color="primary"
                      sx={{
                        padding: (theme) => theme.spacing(0.5),
                        minWidth: 0
                      }}
                      onClick={() => setNotesOpen((open) => !open)}>
                      <WritingIcon
                        sx={{
                          fontSize: '2.2em',
                          boxSizing: 'border-box'
                        }}
                      />
                    </Button>
                    <Tooltip
                      title={
                        props.contact.hasChat
                          ? language.text.open_chat_with.replace(
                              '{name}',
                              props.contact.name
                            )
                          : language.text.no_chat_with.replace(
                              '{name}',
                              props.contact.name
                            )
                      }>
                      <Box component="span">
                        <Button
                          disabled={!props.contact.hasChat}
                          variant="text"
                          size="small"
                          color="primary"
                          sx={{
                            padding: (theme) => theme.spacing(0.5),
                            minWidth: 0
                          }}
                          onClick={() => {
                            if (props.contact) openChat(props.contact);
                          }}>
                          <CommentAltIcon
                            sx={{
                              fontSize: '2.2em',
                              boxSizing: 'border-box'
                            }}
                          />
                        </Button>
                      </Box>
                    </Tooltip>
                    <Tooltip title={language.text.open_profile_in_browser}>
                      <FancyButton
                        fType={{
                          promise: true
                        }}
                        variant="text"
                        size="small"
                        color="primary"
                        sx={{
                          padding: (theme) => theme.spacing(0.5),
                          minWidth: 0
                        }}
                        onClick={async () => {
                          if (props.contact)
                            await window.api.send(
                              'url:open_ext',
                              props.contact.profileURL
                            );
                        }}>
                        <LinkedIn
                          sx={{
                            fontSize: '3em'
                          }}
                        />
                      </FancyButton>
                    </Tooltip>
                  </Box>
                </Box>

                {notesOpen && (
                  <Box width="100%" display="flex" justifyContent="center">
                    <Box
                      sx={{
                        position: 'fixed',
                        width: 'min(20vw, 17.5rem)'
                      }}>
                      <TextField
                        multiline
                        rows={6}
                        placeholder={language.text.make_notes_about_person}
                        variant="outlined"
                        value={notes ?? ''}
                        onChange={(e) => setNotes(e.target.value)}
                        fullWidth
                        sx={{
                          '.MuiInputBase-root': {
                            backgroundColor: (theme) =>
                              `${theme.palette.background.paper} !important`,
                            boxShadow: (theme) => theme.shadows[1]
                          }
                        }}
                      />
                      <Fab
                        variant="circular"
                        color="primary"
                        size="small"
                        disabled={notesSaving || notes === props.contact.notes}
                        onClick={async () => {
                          if (notes === null) return;
                          try {
                            const timeout = wait(300);
                            setNotesSaving(true);
                            // add some delay to make sure the user sees the loading state
                            await props.contact?.setNotes(notes);
                            await timeout;
                          } finally {
                            setNotesSaving(false);
                          }
                        }}
                        sx={{
                          position: 'absolute',
                          bottom: (theme) => theme.spacing(1),
                          right: (theme) => theme.spacing(1),
                          boxShadow: 'none'
                        }}>
                        {notesSaving ? (
                          <CircularProgress size="1em" />
                        ) : (
                          <SaveIcon />
                        )}
                      </Fab>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  padding: (theme) => theme.spacing(1),
                  flex: '1',
                  borderLeft: '1px solid #707070',
                  marginLeft: (theme) => theme.spacing(1),
                  paddingLeft: (theme) => theme.spacing(1.5)
                }}>
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontSize: '1.1rem',
                    fontWeight: 700,
                    marginTop: (theme) => theme.spacing(1),
                    marginBottom: (theme) => theme.spacing(1),
                    padding: (theme) => theme.spacing(1)
                  }}>
                  {language.text.categorization}
                </Typography>
                {
                  <ContactCategorySelector
                    type={props.contact.type}
                    mode="vertical"
                    onChange={async (type) => {
                      if (props.contact) {
                        await Contact.categorize(props.contact, type);
                        props.onRecategorize?.(type);
                      }
                    }}
                  />
                }
              </Box>
              {props.contact &&
                !props.contact.pipelineCompleted &&
                props.contact.type === ContactType.POTENTIAL_CUSTOMER &&
                step && (
                  <Box
                    sx={{
                      padding: (theme) => theme.spacing(1),
                      flex: '2',
                      borderLeft: '1px solid #707070',
                      marginLeft: (theme) => theme.spacing(1),
                      paddingLeft: (theme) => theme.spacing(1.5)
                    }}>
                    <Typography
                      sx={{
                        textAlign: 'center',
                        fontSize: '1.1rem',
                        fontWeight: 700,
                        marginTop: (theme) => theme.spacing(1),
                        marginBottom: (theme) => theme.spacing(1),
                        padding: (theme) => theme.spacing(1)
                      }}>
                      {language.text.select_relation_step}
                    </Typography>
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gap: 1
                      }}>
                      {Pipelines.getCurrentStepsToDisplay(step.id).map(
                        (current) => (
                          <FancyButton
                            fType={{
                              promise: true
                            }}
                            key={current.id}
                            color={
                              current.id === step.id ? 'primary' : 'neutral'
                            }
                            variant="contained"
                            onClick={async () => {
                              if (!props.contact || step.id === current.id)
                                return;
                              await props.contact.setPipelineStep(current);
                            }}>
                            {current.name}
                          </FancyButton>
                        )
                      )}
                    </Box>
                  </Box>
                )}
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
