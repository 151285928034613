import { forwardRef } from 'react';
import { Box, BoxProps } from '@mui/material';
import { ConfirmingButtonGroupContextProvider } from './ConfirmingButtonContext';

export interface ConfirmingButtonGroupProps extends BoxProps {
  children: React.ReactNode | React.ReactNode[];
}

const ConfirmingButtonGroup = forwardRef<
  HTMLDivElement,
  ConfirmingButtonGroupProps
>(({ children, ...boxProps }, ref) => {
  return (
    <Box
      {...boxProps}
      sx={{ display: 'flex', flexDirection: 'column', gap: 1, ...boxProps.sx }}
      ref={ref}>
      <ConfirmingButtonGroupContextProvider children={children} />
    </Box>
  );
});

export default ConfirmingButtonGroup;
