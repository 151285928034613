import React from 'react';
import { Box } from '@mui/material';
import TaskHeaderDrawer from '@/components/TaskHeader/TaskHeaderDrawer';
import LinkedInWebview from '@common/Webview.renderer/Base/LinkedInWebview';
import { useSnackbar } from 'notistack';
import PeopleGrid from '@/components/TaskHeader/PeopleGrid';
import AudienceHolderTask from './AudienceHolderTask';
import HUDFocusBar from '@/components/HUD/HUDFocusBar';
import { language } from '@/index';
import { useScheduler } from 'focus-mode-scheduler';
import ContactActions from '@/data/DataServer/Contact';
import { ContactType } from '@common/types/enums';
import OnboardingVideo from '../_general/OnboardingVideo/OnboardingVideo';

export interface AudienceHolderTaskUIProps {
  task: AudienceHolderTask;
  defaultMax: number;
}

export default function AudienceHolderTaskUI(props: AudienceHolderTaskUIProps) {
  const { constraints, complete: complete } = useScheduler();
  const { enqueueSnackbar } = useSnackbar();

  const { contacts: existingAudienceHolders } = ContactActions.useContacts({
    values: { type: ContactType.AUDIENCE_HOLDER }
  });

  React.useEffect(() => {
    constraints.setItemsDone(existingAudienceHolders.length);
  }, [existingAudienceHolders]);

  const MAX = (() => {
    const constraint = constraints.getConstraint();
    if (constraint?.type === 'items') return constraint.items;
    return props.defaultMax;
  })();

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%'
      }}>
      <OnboardingVideo
        onboardingStep="focus:comments"
        offset={{ y: 60 }}
        completeOn="finish"
      />
      <TaskHeaderDrawer text={`${existingAudienceHolders.length} / ${MAX}`}>
        <PeopleGrid
          people={existingAudienceHolders.map((c) => ({
            firstName: c.firstname,
            lastName: c.lastname,
            profileID: c.profileID,
            profilePicture: c.pictures?.['800'],
            profilePictureUrl: c.pictures,
            priority: c.priority
          }))}
          maxPerLine={5}
          onPersonRemove={async (person) => {
            const existing = existingAudienceHolders.find(
              (p) => p.profileID === person.profileID
            );
            if (existing) {
              if (existing.connected) {
                await existing.setContactType(ContactType.UNCATEGORIZED);
              } else {
                await existing.delete();
              }
            }
          }}
        />
      </TaskHeaderDrawer>
      <LinkedInWebview
        features={[
          'audience-holders',
          'disable-connecting',
          'focus-mode-style'
        ]}
        onOpenChat={() => {
          enqueueSnackbar(
            language.text.do_not_chat_while_searching_audience_holders,
            { variant: 'info' }
          );
          return false;
        }}
      />
      <HUDFocusBar
        complete={{
          disabled: existingAudienceHolders.length === 0,
          fct: complete.bind(null, { itemsDone: 0 }),
          label: language.text.next
        }}
      />
    </Box>
  );
}
