import React from 'react';
import { Box, Typography, Paper, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { language } from '@/index';
import Auth from '@common/AuthManager/Auth.renderer';
import { createSupportTicket } from '@digital-sun-solutions/cloud-functions';
import log from 'electron-log';
import { AvaIcon, Background, FancyButton } from 'ui-utils';

export interface ErrorPageProps {
  error?: string;
}

export default function ErrorPage(props: ErrorPageProps) {
  const { error } = useParams<{ error?: string }>();
  const [errorTitle, setErrorTitle] = React.useState('');
  const [errorDescription, setErrorDescription] = React.useState('');

  const [completed, setCompleted] = React.useState(false);

  return (
    <Background
      variant="frame"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 9999,
        height: '100vh',
        display: 'flex',
        width: '100vw'
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          margin: 'auto',
          textAlign: 'center'
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            padding: 0,
            boxSizing: 'border-box'
          }}>
          <AvaIcon width={150} />
        </Box>
        <Paper
          sx={{
            p: 2,
            mb: 15
          }}>
          {completed ? (
            <>
              <Typography
                sx={{
                  fontSize: '42px',
                  fontWeight: 'bold',
                  color: (theme) => theme.palette.error.main,
                  marginTop: (theme) => theme.spacing(3),
                  marginBottom: (theme) => theme.spacing(1.5),
                  textAlign: 'center'
                }}>
                {language.text.thanks_for_your_support}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: '20px',
                  maxWidth: '500px',
                  margin: 'auto',
                  textAlign: 'center'
                }}>
                {language.text.we_will_solve_the_ticket_soon}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: '20px',
                  maxWidth: '500px',
                  margin: 'auto',
                  textAlign: 'center'
                }}>
                {language.text.restart_due_to_error}
              </Typography>
              <FancyButton
                variant="contained"
                color="primary"
                fType={{ promise: true }}
                onClick={() => window.api.send('app:quit')}>
                {language.text.quit_ssc}
              </FancyButton>
            </>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: '42px',
                  fontWeight: 'bold',
                  color: (theme) => theme.palette.error.main,
                  marginTop: (theme) => theme.spacing(3),
                  marginBottom: (theme) => theme.spacing(1.5),
                  textAlign: 'center'
                }}>
                {language.text.something_went_wrong}
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  color: (theme) => theme.palette.error.main,
                  textAlign: 'center'
                }}>
                {props.error ?? error ?? language.text.unknown_error}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: '20px',
                  maxWidth: '500px',
                  margin: 'auto',
                  textAlign: 'center'
                }}>
                {language.text.we_will_be_up_and_running_soon}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: '20px',
                  maxWidth: '500px',
                  margin: 'auto',
                  textAlign: 'center'
                }}>
                {language.text.please_explain_the_error}
              </Typography>
              <TextField
                placeholder={language.text.error_title}
                value={errorTitle}
                onChange={(e) => setErrorTitle(e.target.value)}
                fullWidth
                sx={{ mt: 2 }}
              />
              <TextField
                rows={6}
                placeholder={language.text.error_description}
                value={errorDescription}
                onChange={(e) => setErrorDescription(e.target.value)}
                multiline
                fullWidth
                sx={{ mt: 2 }}
              />
              <FancyButton
                fType={{ promise: true }}
                variant="contained"
                sx={{ mt: 2 }}
                disabled={!errorDescription}
                onClick={async () => {
                  log.error('Critical error:', {
                    error: props.error ?? error,
                    errorTitle,
                    errorDescription
                  });
                  const res = await Auth.execRoute((token) =>
                    createSupportTicket(
                      {
                        subject: `[${error ?? 'Unknown Error'}]: ${errorTitle}`,
                        text: errorDescription
                      },
                      { token }
                    )
                  );
                  if (res.code === 200) setCompleted(true);
                  else {
                    log.error('Error while sending support ticket', res, {
                      error: props.error ?? error,
                      errorTitle,
                      errorDescription
                    });
                    throw new Error('Error while sending support ticket');
                  }
                }}>
                {language.text.send_error_report}
              </FancyButton>
            </>
          )}
        </Paper>
      </Box>
    </Background>
  );
}
