import { MarkedProfile } from '@/data/Classes/Contact';
import { language } from '@/index';
import { ContactType } from '@common/types/enums';
import { Task, TaskConfig, TaskHeader } from 'focus-mode-scheduler';
import { TaskIncomingEventMap } from 'focus-mode-scheduler/src/Task/Task';
import MessageBus from '@common/MessageBus/MessageBus.renderer';
import { mergeObjects } from '@common/Functions';
import PrintableError from '@common/PrintableError/PrintableError';
import tracking from 'tracking';
import posthog from 'posthog-js';

export enum ConnectionType {
  POTENTIAL_CUSTOMER = 'potential_customer',
  AUDIENCE_HOLDER = 'audienceHolder',
  NO_MATCH = 'no_match',
  LATER = 'later'
}

export default class ConnectProfileTask extends Task {
  public static get config(): TaskConfig {
    return {
      id: 'connect-profile',
      info: {
        title: language.text.connect,
        description: language.text.connect_task_description,
        avaContent: language.text.ava_hint_focus_connect_profile
      },
      hide: false,
      constraintBreachedDialogContent: mergeObjects(
        Task.DEFAULT_CONTRAINT_BREACHED_DIALOG_TEXTS,
        {
          items: {
            buttons: {
              oneMore: undefined
            }
          }
        }
      )
    };
  }

  protected _header: TaskHeader = {
    title: language.text.send_connection_request
  };

  constructor(
    public readonly contact: MarkedProfile,
    private onConnect: () => void
  ) {
    super();
    this._header.subtitle = language.text.check_if_fitting_to_connect.replace(
      '{name}',
      this.contact.name
    );
  }

  protected async handleEvent<Type extends keyof TaskIncomingEventMap>(
    type: Type
  ) {
    try {
      switch (type) {
        case 'skip':
          // TODO: save notes of marked contacts to db (currently only in memory, so lost if profile is skipped)
          this.internalEmit('finished', undefined);
          break;
        case 'complete':
          await this.complete();
          this.internalEmit('finished', undefined);
          break;
      }
    } catch (e) {
      this.internalEmit('error', {
        error: e as Error,
        code: `task-${ConnectProfileTask.config.id}-event-${type}-error`
      });
    }
  }

  private notes = '';
  public setNotes(notes: string): void {
    this.notes = notes;
  }
  private connectionType: ConnectionType | null = null;
  public setConnectionType(type: ConnectionType): void {
    this.connectionType = type;
  }
  private async complete(): Promise<void> {
    if (!this.connectionType) return;
    switch (this.connectionType) {
      case ConnectionType.POTENTIAL_CUSTOMER:
        await (async () => {
          // send connection request
          const contact = await this.contact.connect();
          if (!contact) {
            throw new PrintableError('Issue converting profile to contact');
          }
          await contact.setContactType(ContactType.CONNECTION_REQUEST_SENT);
          this.onConnect();
        })();
        break;
      case ConnectionType.AUDIENCE_HOLDER:
        await (async () => {
          // send connection request
          const contact = await this.contact.connect();
          if (!contact) {
            throw new PrintableError('Issue converting profile to contact');
          }
          await contact.setContactType(ContactType.AUDIENCE_HOLDER);
          this.onConnect();
        })();
        break;
      case ConnectionType.NO_MATCH:
        await this.contact.remove();
        break;
      case ConnectionType.LATER:
        await this.contact.skipUntil(undefined, this.notes);
        break;
    }

    tracking.capture('ConnectionRequestSend');
    posthog.capture('connection-request-send');
  }
}
