import { CSSendEventData, CSSendEventType } from 'webview-preload';
import { LinkedInWebviewElement } from './ContentScriptTypes';
import log from 'electron-log';
import MessageBus from '@common/MessageBus/MessageBus.renderer';
import { wait } from '@idot-digital/generic-helpers';
import DevConfig from '@common/config/DevConfig';

let ID = 0;

/**
 * Global cache of stored webviews that are not mounted in the DOM -> to be reused
 * This is used to prevent creating too many webviews (expensive)
 */
const webviews: LinkedInWebviewElement[] = [];
/**
 * Get webview from stack or a newly created one
 */
function getWebview({
  link,
  preload,
  disabledWebAuth
}: {
  link: string;
  preload: string;
  disabledWebAuth?: boolean;
}) {
  // select webview with matching disabledblinkfeatures
  const existing = webviews.find((w) => {
    const disabledBlinkFeatures = w.getAttribute('disableblinkfeatures');
    if (disabledWebAuth) return disabledBlinkFeatures?.includes('WebAuth');
    return disabledBlinkFeatures?.includes('WebAuth');
  });

  // remove the webview from the stack
  if (existing) {
    const index = webviews.indexOf(existing);
    webviews.splice(index, 1);
  }

  const isNew = !existing;

  const webview: LinkedInWebviewElement =
    // select webview with matching disabledblinkfeatures
    existing ?? document.createElement('webview');

  if (!isNew) {
    log.debug(`Reusing webview for link "${link}"`);
  }

  const attributes = {
    'src': link,
    'data-src': link,
    'preload': `file://${preload}`,
    // disabled webcam access
    'disableblinkfeatures': disabledWebAuth
      ? 'MediaCaptureCameraControls,WebAuth'
      : 'MediaCaptureCameraControls',
    'partition': 'persist:linkedin',
    'className': 'linkedin-webview'
  };

  Object.entries(attributes).forEach(([key, value]) => {
    // check first if the attribute is already set to the value
    if (webview.getAttribute(key) !== value) webview.setAttribute(key, value);
  });

  webview.style.width = '100%';
  webview.style.flexGrow = '1';
  webview.id = `webview-${ID++}`;

  // webview gets mounted again, which means it's not loaded and it emits dom-ready again
  webview.domReady = false;
  console.debug(`[WebviewStorage][${webview.id}] Reset domReady`);

  if (isNew) {
    const send = webview.send;
    // eslint-disable-next-line @typescript-eslint/no-misused-promises -- this is just a function replacement
    webview.send = async <Event extends CSSendEventType>(
      channel: Event,
      data: CSSendEventData<Event>
    ): Promise<void> => {
      while (!webview.domReady) {
        await wait(200);
      }

      if (DevConfig.emitWebviewIPCtoMessageBus) {
        MessageBus.getInstance().emit(
          `webview:to:${channel}`,
          // @ts-expect-error -- ts does not vibe with template strings
          { channel, data, webview: webview.id }
        );
      }

      send.call(webview, channel, data);
    };
  }

  return { webview, isNew };
}

/**
 * Check if webview is mounted in the DOM and save it to the stack if not
 */
function saveWebview(webview: LinkedInWebviewElement | null) {
  if (!webview) return;
  if (isMounted(webview)) return;

  // save at most 2 webviews
  if (webviews.length > 2) return;
  webviews.push(webview);
}

/**
 * Check if an element is mounted in the DOM
 */
function isMounted(ele: HTMLElement) {
  while (ele.parentElement) {
    if (ele.parentElement === document.documentElement) return true;
    ele = ele.parentElement;
  }
  return false;
}

const WebviewStorage = {
  get: getWebview,
  save: saveWebview
};

export default WebviewStorage;
