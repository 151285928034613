import AccountActions from '@/data/DataServer/Account';
import { language } from '@/index';
import { Box, Typography } from '@mui/material';
import { FancyButton } from 'ui-utils';

export interface StartNowProps {
  next?: () => void;
}

export default function StartNow(props: StartNowProps) {
  const { user } = AccountActions.useUserData();
  const name = user ? `${user.firstname} ${user.lastname}` : undefined;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'center',
        p: 4
      }}>
      <Typography variant={'h4'} textAlign="center">
        {name
          ? language.text.greeting_with_name(name)
          : language.text.general_greeting}
      </Typography>
      <Typography variant={'h6'} textAlign="center">
        {language.text.start_now_on_linkedin}
      </Typography>

      <FancyButton
        fType={{ promise: true }}
        fullWidth
        size="large"
        variant="contained"
        color="primary"
        onClick={() => props.next?.()}>
        {language.text.lift_off}
      </FancyButton>
      <FancyButton
        fType={{ promise: true }}
        fullWidth
        size="large"
        variant="outlined"
        color="primary"
        onClick={async () => {
          await window.api.send('ssc:logout');
        }}>
        {language.text.logout}
      </FancyButton>
    </Box>
  );
}
