import AppActions from '@/data/DataServer/App';
import { APP_PATHS } from '@/globals/Paths';
import { language } from '@/index';
import { Dates } from '@/other/Dates';
import MainConfig from '@common/config/MainConfig';
import { LinkedInWebviewElement } from '@common/Webview.renderer/Base/ContentScriptTypes';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation } from 'react-router-dom';
import { FancyButton, useRefreshed } from 'ui-utils';

export default function AskToRestart() {
  const { pathname } = useLocation();

  const shouldAskOnPath = !(
    [
      APP_PATHS.Login,
      APP_PATHS.Debugger,
      APP_PATHS.Error,
      APP_PATHS.Maintenance,
      APP_PATHS.Setup,
      APP_PATHS.Updater
    ] as const
  ).some((path) => pathname.startsWith(`/${path}`));

  const [shouldAskToRestart, setShouldAskToRestart] = React.useState(false);
  const restartReason = React.useRef<'24h' | 'idle'>('24h');

  useIdleTimer({
    timeout: MainConfig.realtimeConnectionClosingTime,
    onIdle: () => {
      // small prevention of asking user directly after opening, when useEffects may misfire
      if (startTime.getTime() + 2_000 > Date.now()) return;

      // get all webview instead of only closing realtime for background webview
      document
        .querySelectorAll<LinkedInWebviewElement>('webview')
        .forEach((w) => w.send('linkedin:api', ['closeRealtimeConnection']));

      restartReason.current = 'idle';
      setShouldAskToRestart(true);
    }
  });

  const startTime = useRefreshed(
    () => AppActions.getStartTime(),
    2 * 60 * 1000
  );

  React.useEffect(() => {
    const openTooLong = !Dates.onSameDay(startTime);
    if (!openTooLong) return;
    restartReason.current = '24h';
    setShouldAskToRestart(true);
  }, [startTime]);

  return (
    <Dialog open={shouldAskToRestart && shouldAskOnPath}>
      {restartReason.current === '24h' ? (
        <>
          <DialogTitle>{language.text.restart_ssc}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {language.text.periodic_restart_ssc_explanation}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShouldAskToRestart(false)}
              color="primary"
              variant="outlined">
              {language.text.remind_in_2_min}
            </Button>
            <FancyButton
              variant="contained"
              color="primary"
              onClick={() => window.api.send('app:restart')}>
              {language.text.restart}
            </FancyButton>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>{language.text.restart_ssc}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {language.text.restart_due_to_inactivity}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <FancyButton
              variant="contained"
              color="primary"
              onClick={() => window.api.send('app:restart')}>
              {language.text.restart}
            </FancyButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
