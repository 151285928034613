import { Box, Typography } from '@mui/material';
import { language } from '@/index';

export default function TrainingUpcoming() {
  return (
    <Box>
      <Typography
        variant="h5"
        sx={{
          textAlign: 'center',
          fontWeight: 'bolder'
        }}>
        {language.text.pilot_training}
      </Typography>
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          justifyContent: 'center'
        }}>
        <Typography
          variant="body1"
          sx={{
            textAlign: 'center'
          }}>
          {language.text.this_section_is_under_construction}
        </Typography>
      </Box>
    </Box>
  );
}
