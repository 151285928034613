import { Node as TipTapNode } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import PlaceholderBlockView from './PlaceholderBlockView';
import { language } from '../../../../Config';
import { Node } from 'prosemirror-model';

export interface PlaceholderBlockOptions {
  editable?: boolean;
}
export type PlaceholderBlockStorage = { [key: string]: never };

export interface PlaceholderBlockAttributes {
  text?: string;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    placeholder: {
      /**
       * Insert a variable block
       */
      setPlaceholder: (options: PlaceholderBlockAttributes) => ReturnType;
    };
  }
}

export const PlaceholderBlock = TipTapNode.create<
  PlaceholderBlockOptions,
  PlaceholderBlockStorage
>({
  name: 'ssc-placeholder',
  atom: true,
  selectable: true,

  inline() {
    return true;
  },

  group() {
    return 'inline';
  },

  draggable: true,

  addOptions() {
    return {
      editable: true
    };
  },

  addAttributes() {
    return {
      text: {
        default: language.text.placeholder
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'ssc-placeholder',
        getAttrs: (dom: HTMLElement | string) => ({
          text: typeof dom === 'string' ? dom : dom.textContent
        })
      }
    ];
  },

  addCommands() {
    return {
      setPlaceholder:
        (options: PlaceholderBlockAttributes) =>
        ({ commands }) => {
          options.text = options.text ?? language.text.placeholder;

          return commands.insertContent({
            type: this.name,
            attrs: options
          });
        }
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(PlaceholderBlockView);
  },

  renderHTML({ node }: { node: Node & { attrs: PlaceholderBlockAttributes } }) {
    return ['ssc-placeholder', {}, node.attrs.text ?? ''];
  }
});
