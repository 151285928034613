import React from 'react';
import HUDTop from './HUDTop';
import HUDSide from './HUDSide';
import HUDBottom from './HUDBottom';

export interface HUDProps {
  topContent?: React.ReactNode;
  bottomContent?: React.ReactNode;
  hideBottom?: boolean;
  hideTop?: boolean;
}

export default function HUD(props: HUDProps) {
  const { hideBottom, hideTop, topContent, bottomContent } = props;

  return (
    <>
      {!hideTop && <HUDTop topContent={topContent} />}
      <HUDSide position="left" />
      <HUDSide position="right" />
      {!hideBottom && <HUDBottom bottomContent={bottomContent} />}
    </>
  );
}
