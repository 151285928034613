import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

export interface DoubleCalendarProps extends BoxProps {
  date?: Date | undefined | null;
  onDateChange?: (date: Date) => void;
}

export default function DoubleCalendar(props: DoubleCalendarProps) {
  const { date, onDateChange, ...rest } = props;
  const [value, setValue] = React.useState<Dayjs | null>(dayjs(date));

  const [nextMonthDateValue, setNextMonthDateValue] =
    React.useState<Dayjs | null>(null);

  const [currentViewMonth, setCurrentViewMonth] = React.useState<Dayjs | null>(
    dayjs(date)
  );

  const setDateValue = (date: Dayjs | null) => {
    if (date) {
      setValue(date);
      onDateChange && onDateChange(date.toDate());
    }
  };

  const moveCalendarMonth = (month: Dayjs) => {
    setCurrentViewMonth(month);
    setNextMonthDateValue(month.clone().add(1, 'month'));
    setTimeout(() => {
      setNextMonthDateValue(null);
    }, 100);
  };

  return (
    <Box {...rest}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
        <StaticDatePicker
          sx={{
            'backgroundColor': (theme) => theme.palette.background.default,
            '.MuiPickersCalendarHeader-labelContainer': {
              width: '100%'
            },
            '.MuiPickersCalendarHeader-labelContainer::after': {
              display: 'block',
              content: '" "',
              height: '1px',
              backgroundColor: '#ccc',
              marginRight: '18px',
              flex: '1'
            },
            '.MuiPickersCalendarHeader-label ': {
              fontWeight: '600'
            },
            'MuiPickersArrowSwitcher-spacer': {
              width: '20px'
            },
            '.MuiPickersArrowSwitcher-button': {
              'marginLeft': '-12px',
              'transform': 'rotate(90deg)',
              'border': '0.1px solid #ccc',
              'borderRadius': '4px',
              'width': '25px',
              'height': '25px',
              'fontSize': '18px',
              ':first-of-type': {
                marginRight: '0px'
              }
            },
            '.MuiDayCalendar-weekContainer, .MuiDayCalendar-header': {
              justifyContent: 'space-evenly'
            },
            '.MuiDateCalendar-root': {
              width: '100%'
            },
            '.MuiPickersDay-root': {
              width: '30px !important',
              borderRadius: '4px !important'
            },
            '.Mui-selected.MuiPickersDay-dayWithMargin': {
              backgroundColor: (theme) => theme.palette.primary.main
            }
          }}
          // dayOfWeekFormatter={((day) => day.toUpperCase())}
          onMonthChange={(date) => moveCalendarMonth(date)}
          displayStaticWrapperAs="desktop"
          views={['day']}
          openTo="day"
          value={value}
          onChange={(date) => setDateValue(date)}
        />
        <StaticDatePicker
          sx={{
            'backgroundColor': (theme) => theme.palette.background.default,
            '.MuiPickersCalendarHeader-labelContainer': {
              width: '100%'
            },
            '.MuiPickersCalendarHeader-labelContainer::after': {
              display: 'block',
              content: '" "',
              height: '1px',
              backgroundColor: '#ccc',
              marginRight: '0px',
              flex: '1'
            },
            '.MuiPickersCalendarHeader-label ': {
              fontWeight: '600'
            },
            'MuiPickersArrowSwitcher-spacer': {
              width: '20px'
            },
            '.MuiPickersArrowSwitcher-root': {
              display: 'none'
            },
            '.MuiDayCalendar-weekContainer, .MuiDayCalendar-header': {
              justifyContent: 'space-evenly'
            },
            '.MuiDateCalendar-root': {
              width: '100%'
            },
            '.MuiPickersDay-root': {
              width: '30px !important',
              borderRadius: '4px !important'
            },
            '.Mui-selected.MuiPickersDay-dayWithMargin': {
              backgroundColor: (theme) => theme.palette.primary.main
            }
          }}
          // dayOfWeekFormatter={((day: string) => day.toUpperCase()) as any}
          displayStaticWrapperAs="desktop"
          views={['day']}
          openTo="day"
          referenceDate={currentViewMonth?.clone().add(1, 'month')}
          value={nextMonthDateValue}
          onChange={(date) => setDateValue(date)}
        />
      </LocalizationProvider>
    </Box>
  );
}
