import { language } from '@/index';
import { Box, Divider, Typography } from '@mui/material';
import StatsHelper from '../../Stats/StatsHelper';
import StatsActions from '@/data/DataServer/Stats';
import { DelayedSkeleton } from 'ui-utils';
import { PriorityHigh } from '@mui/icons-material';
import SetGoal from './SetGoal';
import { useSnackbar } from 'notistack';
import * as Sentry from '@sentry/electron/renderer';
import React from 'react';

export default function Goals() {
  const { enqueueSnackbar } = useSnackbar();
  const { today, status } = StatsActions.useToday();

  const categories = React.useMemo(
    StatsHelper.getStatCategories.bind(StatsHelper),
    []
  );

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="h4">{language.text.goals}</Typography>
      {status === 'loading' && (
        <Box display="flex" flexWrap="wrap" gap={2}>
          <DelayedSkeleton quantity={16} width="20%" height="150px" />
        </Box>
      )}
      {status === 'error' && (
        <>
          <PriorityHigh color="error" sx={{ fontSize: '5rem' }} />
          <Typography>{language.text.server_error}</Typography>
        </>
      )}
      {status === 'success' && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
            gap: 2,
            pb: 4
          }}>
          {categories.map((category, i) => (
            <React.Fragment key={category.title}>
              {i !== 0 && <Divider sx={{ gridColumn: '1 / -1' }} />}
              <Typography variant="h5" sx={{ gridColumn: '1 / -1' }}>
                {category.title}
              </Typography>
              {category.stats.map((type) => {
                const goal = today[type]?.goal ?? null;
                if (goal === null) return null;
                return (
                  <SetGoal
                    key={type}
                    goal={type}
                    defaultValue={goal}
                    onFinish={(goal) => {
                      StatsActions.setGoal(type, goal).catch(
                        (error: unknown) => {
                          enqueueSnackbar(language.text.server_error, {
                            variant: 'error'
                          });
                          Sentry.captureException(error);
                        }
                      );
                    }}
                  />
                );
              })}
            </React.Fragment>
          ))}
        </Box>
      )}
    </Box>
  );
}
