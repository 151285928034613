import React from 'react';
import { Box, Typography } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  Assistant,
  MultipleStop,
  Person,
  PlayCircleOutline,
  Recommend
} from '@mui/icons-material';
import ProgressionHeader from './ProgressionHeader';
import { ProgressionNewUser } from './ProgressionNewUser';
import { ProgressionBasicUser } from './ProgressionBasicUser';
import ProgressionReferrer from './ProgressionReferrer';
import ProgressionAmbassador from './ProgressionAmbassador';
import ProgressionAffiliate from './ProgressionAffiliate';
import { language } from '@/index';
import AffiliateActions, { UserProgression } from '@/data/DataServer/Affiliate';
import { DelayedSkeleton } from 'ui-utils';

export const PROGRESSION_ROUTES = [
  {
    element: ProgressionNewUser,
    name: () => language.text.new,
    path: `new`,
    icon: PlayCircleOutline,
    status: UserProgression.New
  },
  {
    element: ProgressionBasicUser,
    name: () => language.text.basic,
    path: `basic`,
    icon: Person,
    status: UserProgression.User,
    disabledTooltip: () => language.text.use_ssc_to_become_basic
  },
  {
    element: ProgressionReferrer,
    name: () => language.text.referrer,
    path: `referrer`,
    icon: Recommend,
    status: UserProgression.Referrer,
    disabledTooltip: () => language.text.finish_training_to_become_adavanced
  },
  {
    element: ProgressionAmbassador,
    name: () => language.text.ambassador,
    path: `ambassador`,
    icon: MultipleStop,
    status: UserProgression.Ambassador,
    disabledTooltip: () => language.text.invite_users_to_become_ambassador
  },
  {
    element: ProgressionAffiliate,
    name: () => language.text.affiliate,
    path: `affiliate`,
    icon: Assistant,
    status: UserProgression.Affiliate,
    disabledTooltip: () => language.text.apply_to_become_affiliate
  }
] satisfies {
  path: string;
  name: () => string;
  icon?: React.FC;
  element: React.FC;
  status: UserProgression;
  disabledTooltip?: () => string;
}[];

export default function Progression() {
  const { userProgression, status } = AffiliateActions.useUserProgression();

  if (status === 'loading')
    return <DelayedSkeleton variant="rounded" width="100%" height={200} />;
  if (status === 'error')
    return (
      <Box>
        <Typography variant="h5">{language.text.error}</Typography>
        <Typography>{language.text.generic_error}</Typography>
      </Box>
    );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%'
      }}>
      <ProgressionHeader />
      <Box sx={{ flexGrow: 1, pt: 2 }}>
        <Routes>
          <Route
            index
            element={
              <Navigate
                to={
                  [...PROGRESSION_ROUTES]
                    .reverse()
                    .find((route) =>
                      AffiliateActions.isUnlocked(userProgression, route.status)
                    )?.path ?? PROGRESSION_ROUTES[0].path
                }
              />
            }
          />
          {PROGRESSION_ROUTES.map((item) => (
            <Route
              key={item.path}
              path={`${item.path}/*`}
              element={<item.element />}
            />
          ))}
        </Routes>
      </Box>
    </Box>
  );
}
