import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Add, Check, Error, Info, Remove } from '@mui/icons-material';
import { language } from '@/index';
import AffiliateActions from '@/data/DataServer/Affiliate';
import { FancyButton, useDebounced } from 'ui-utils';
import { Money } from '@idot-digital/generic-helpers';

const MAX_NAME_LENGTH = 30;
const MAX_CODE_LENGTH = 30;

const MAX_FREE_MONTHS = 255;

export interface AffiliateCreateFreeToUseCodesProps {
  open: boolean;
  onClose: () => void;
}

export default function AffiliateCreateFreeToUseCodes(
  props: AffiliateCreateFreeToUseCodesProps
) {
  const { appPrice } = AffiliateActions.useAppPrice();

  const [name, setName] = React.useState('');
  const [code, setCode] = React.useState('');
  const [numberOfFreeMonths, setNumberOfFreeMonths] = React.useState(1);

  const { value: debouncedCode } = useDebounced(code, 500);
  const [isCodeAvailable, setIsCodeAvailable] = React.useState<{
    available: boolean;
    allowed: boolean;
  }>({
    available: true,
    allowed: true
  });
  const [codeAvailableLoading, setCodeAvailableLoading] = React.useState(false);

  React.useEffect(() => {
    let mounted = true;

    (async () => {
      if (debouncedCode === '')
        return setIsCodeAvailable({ available: true, allowed: true });
      try {
        setCodeAvailableLoading(true);
        const isAvailable =
          await AffiliateActions.isCodeAvailable(debouncedCode);
        if (mounted) setIsCodeAvailable(isAvailable);
      } finally {
        if (mounted) setCodeAvailableLoading(false);
      }
    })();

    return () => {
      mounted = false;
    };
  }, [debouncedCode]);

  const changed = React.useMemo(() => {
    if (name !== '') return true;
    if (code !== '') return true;
    if (numberOfFreeMonths !== 1) return true;
    return false;
  }, [name, code, numberOfFreeMonths]);

  const canSave = (() => {
    if (name === '') return false;
    if (code === '') return false;
    if (code !== debouncedCode) return false;
    if (codeAvailableLoading) return false;
    if (!isCodeAvailable.available) return false;
    if (!isCodeAvailable.allowed) return false;
    if (numberOfFreeMonths < 1) return false;
    return true;
  })();

  const [confirmQuit, setConfirmQuit] = React.useState(false);
  const onClose = () => {
    if (!changed) props.onClose();
    else setConfirmQuit(true);
  };

  const [confirmCreate, setConfirmCreate] = React.useState(false);

  React.useEffect(() => {
    if (props.open) return;
    setName('');
    setCode('');
    setNumberOfFreeMonths(1);
  }, [props.open]);

  return (
    <>
      {/* main dialog */}
      <Dialog open={props.open} onClose={onClose}>
        <DialogTitle>{language.text.create_f2u_coupon}</DialogTitle>
        <Box
          sx={{
            my: 2,
            mx: 4,
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            minWidth: 'min(80vw, 500px)'
          }}>
          <TextField
            label={language.text.coupon_name}
            value={name}
            variant="standard"
            onChange={(event) => {
              setName(event.target.value.slice(0, MAX_NAME_LENGTH));
            }}
            InputProps={{
              endAdornment: (
                <>
                  <Typography variant="caption">
                    {`${name.length}/${MAX_NAME_LENGTH}`}
                  </Typography>
                  <Tooltip
                    enterDelay={500}
                    placement="right"
                    title={
                      <Typography>
                        {language.text.f2u_coupon_name_explanation}
                      </Typography>
                    }>
                    <Info
                      color="disabled"
                      fontSize="small"
                      sx={{ cursor: 'pointer', ml: 1 }}
                    />
                  </Tooltip>
                </>
              )
            }}
          />
          <TextField
            label={language.text.code}
            value={code}
            variant="standard"
            onChange={(event) => {
              setCode(
                event.target.value
                  .toLocaleUpperCase()
                  .replace(/[^A-Z0-9\-]/g, '')
                  .slice(0, MAX_CODE_LENGTH)
              );
            }}
            error={!isCodeAvailable.available || !isCodeAvailable.allowed}
            InputProps={{
              endAdornment: (
                <>
                  {code === '' ? undefined : codeAvailableLoading ||
                    code !== debouncedCode ? (
                    <CircularProgress size={20} />
                  ) : !isCodeAvailable.available || !isCodeAvailable.allowed ? (
                    <Tooltip
                      placement="right"
                      title={
                        <Typography>
                          {!isCodeAvailable.available
                            ? language.text.code_already_in_use
                            : language.text.code_not_allowed}
                        </Typography>
                      }>
                      <Error
                        color="error"
                        fontSize="small"
                        sx={{ cursor: 'pointer' }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      placement="right"
                      title={
                        <Typography>{language.text.code_available}</Typography>
                      }>
                      <Check
                        color="success"
                        fontSize="small"
                        sx={{ cursor: 'pointer' }}
                      />
                    </Tooltip>
                  )}
                  <Typography variant="caption" sx={{ width: 40, ml: 1 }}>
                    {`${code.length}/${MAX_CODE_LENGTH}`}
                  </Typography>
                  <Tooltip
                    enterDelay={500}
                    placement="right"
                    title={
                      <Typography>{language.text.code_explanation}</Typography>
                    }>
                    <Info
                      color="disabled"
                      fontSize="small"
                      sx={{ cursor: 'pointer', ml: 1 }}
                    />
                  </Tooltip>
                </>
              )
            }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography flex="1 0 0">{language.text.free_months}:</Typography>
            <IconButton
              onClick={() => setNumberOfFreeMonths(numberOfFreeMonths - 1)}
              disabled={numberOfFreeMonths <= 1}
              color="default"
              sx={{
                color:
                  numberOfFreeMonths <= 1
                    ? (theme) => `${theme.palette.text.disabled} !important`
                    : undefined
              }}>
              <Remove />
            </IconButton>
            <Typography
              sx={{ width: 24, textAlign: 'center', userSelect: 'none' }}>
              {numberOfFreeMonths}
            </Typography>
            <IconButton
              onClick={() => setNumberOfFreeMonths(numberOfFreeMonths + 1)}
              disabled={numberOfFreeMonths >= MAX_FREE_MONTHS}
              color="default"
              sx={{
                color:
                  numberOfFreeMonths >= MAX_FREE_MONTHS
                    ? (theme) => `${theme.palette.text.disabled} !important`
                    : undefined
              }}>
              <Add />
            </IconButton>
            <Box
              flex="1 0 0"
              display="flex"
              justifyContent="flex-end"
              gap={2}
              alignItems="center">
              <Typography>
                {Money.stringifyCents(
                  appPrice ? numberOfFreeMonths * appPrice.amount * 0.5 : 0,
                  appPrice?.currency
                )}
              </Typography>
              <Tooltip
                title={
                  <Typography>
                    {language.text.f2u_free_months_explanation({
                      price: Money.stringifyCents(
                        appPrice ? appPrice.amount * 0.5 : 0,
                        appPrice?.currency
                      )
                    })}
                  </Typography>
                }
                placement="right">
                <Info fontSize="small" color="disabled" />
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <DialogActions>
          <Button variant="contained" color="neutral" onClick={onClose}>
            {language.text.cancel}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!canSave}
            onClick={() => setConfirmCreate(true)}>
            {language.text.save}
          </Button>
        </DialogActions>
      </Dialog>
      {/* confirm leave with changes */}
      <Dialog open={confirmQuit} onClose={() => setConfirmQuit(false)}>
        <DialogTitle>{language.text.unsaved_changes}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {language.text.you_have_unsaved_changes}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="neutral"
            onClick={() => setConfirmQuit(false)}>
            {language.text.back}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setConfirmQuit(false);
              props.onClose();
            }}>
            {language.text.dont_save}
          </Button>
        </DialogActions>
      </Dialog>
      {/* confirm create */}
      <Dialog open={confirmCreate} onClose={() => setConfirmCreate(false)}>
        <DialogTitle>{language.text.create_coupon}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {language.text.create_f2u_payment_warning({
              price: Money.stringifyCents(
                appPrice ? appPrice.amount * 0.5 * numberOfFreeMonths : 0,
                appPrice?.currency
              )
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="neutral"
            onClick={() => setConfirmCreate(false)}>
            {language.text.cancel}
          </Button>

          <FancyButton
            fType={{ promise: true }}
            variant="contained"
            color="primary"
            disabled={!canSave}
            onClick={async () => {
              await AffiliateActions.createFreeToUseCoupon({
                code,
                name,
                number_of_free_months: numberOfFreeMonths
              });
              props.onClose();
              setConfirmCreate(false);
            }}>
            {language.text.save}
          </FancyButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
