import React from 'react';
import { Box } from '@mui/material';
import { CircularProgress, List, ListItem, ListItemText } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import ChatListItem from './ChatListItem';
import { language } from '@/index';
import LinkedInChatActions from '@/data/DataServer/LinkedInChats';
import { AbstractChat } from '@/data/Classes/Chat/AbstractChat';

export interface LinkedInChatListProps {
  openChat: AbstractChat | null;
  onSelect: (
    conversation: AbstractChat,
    initial: boolean
  ) => void | Promise<void>;
  headerContent?: React.ReactNode | React.ReactNode[];
  category: 'normal' | 'archived';
  filter?: { searchQuery?: string };
}

export default function LinkedInChatList(props: LinkedInChatListProps) {
  const { ref, inView } = useInView();

  const { chats, status, error, hasNextPage } = LinkedInChatActions.useChats(
    props.filter?.searchQuery ? 'search' : props.category,
    props.filter?.searchQuery,
    {
      loadMore: inView
    }
  );

  // when data is loaded, reselect the open chat to prevent out of sync chat classes in states
  React.useEffect(() => {
    if (!props.openChat || status !== 'success') return;
    const currentOpen = chats.find(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked above
      (chat) => chat.conversationID === props.openChat!.conversationID
    );
    if (!currentOpen) {
      props.onSelect(chats[0], true);
    }
  }, [chats]);

  // select first conversation when data is loaded
  React.useEffect(() => {
    if (status !== 'success') return;
    if (!chats.length) return;
    if (
      // none is open
      !props.openChat ||
      // or open is not in data (e.g. different search query or category)
      !chats.some((c) => c.conversationID === props.openChat?.conversationID)
    )
      props.onSelect(chats[0], true);
  }, [chats]);

  return (
    <List disablePadding>
      {status === 'success' &&
        chats.map((chat) => (
          <ChatListItem
            key={chat.conversationID}
            chat={chat}
            onSelect={() => props.onSelect(chat, false)}
            selected={props.openChat?.conversationID === chat.conversationID}
            type={chat.contact?.type}
          />
        ))}
      {status === 'error' && (
        <ListItem key="error">
          <ListItemText primary={language.text.error_loading_contacts} />
        </ListItem>
      )}
      <ListItem ref={ref} key={'end'}>
        {(error || !hasNextPage) && status !== 'loading' ? (
          <ListItemText
            primary={
              error
                ? language.text.error_while_loading
                : hasNextPage
                  ? language.text.load_more_entries
                  : language.text.no_more_entries
            }
          />
        ) : (
          <Box display="flex" justifyContent="center" width="100%" padding={2}>
            <CircularProgress />
          </Box>
        )}
      </ListItem>
    </List>
  );
}
