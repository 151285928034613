import { Box, BoxProps, Typography } from '@mui/material';

import { Meeting } from '../DualMonthDayPicker';
import { language } from '../../../Config';
import { CalendarIcon, SquareCheckIcon } from '../../../icons';

export interface MeetingDayProps extends BoxProps {
  date?: string | undefined;
  meetings: Meeting[];
}

export default function MeetingDay(props: MeetingDayProps) {
  const { date, meetings, ...rest } = props;

  return (
    <Box
      {...rest}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        borderBottom: '1px solid #E2E2EA',
        padding: (theme) => theme.spacing(2, 0, 1.5)
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          minWidth: '130px'
        }}>
        <CalendarIcon
          sx={{
            color: '#92929D',
            mr: (theme) => theme.spacing(0.8)
          }}
        />
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '0.95rem'
          }}>
          {date}
        </Typography>
      </Box>
      <Box
        sx={{
          flex: '1'
        }}>
        {meetings.map((meeting, i) => (
          <Box
            key={i}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              mb: (theme) => theme.spacing(0.5)
            }}>
            <SquareCheckIcon
              sx={{
                fontSize: '1.3rem',
                color: '#3DD598',
                mr: (theme) => theme.spacing(0.8)
              }}
            />
            <Typography sx={{ fontWeight: '500', fontSize: '0.95rem' }}>
              {language.text.meeting_with.replace('{name}', meeting.fullname)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
