import { Client } from '../client/Client';
import { InviteRequest } from '../requests/InviteRequest';
import { InvitationIterator } from '../iterator/InvitationIterator';
import {
  DashProfileUrn,
  GENERIC_INVITATION_VIEW_TYPE,
  GetReceivedInvitationsResponse,
  GetSentInvitationsResponse,
  GraphQLCollection,
  GraphQLResponse,
  INVITATION_TYPE,
  INVITATION_VIEW_TYPE,
  LinkedInDashInvitation,
  LinkedInDashSentInvitationView,
  LinkedInGenericInvitationView,
  LinkedInInvitationView,
  LinkedInMiniProfile,
  LinkedInPhotoFilterPicture,
  LinkedInReceivedInvitation,
  LinkedInSentInvitation,
  LinkedInSentInvitationViewV2,
  MINI_PROFILE_TYPE,
  MiniProfileUrn,
  PROFILE_TYPE,
  RelInvitationUrn,
  RelInvitationViewUrn,
  RelSentInvitationViewUrn,
  SENT_INVITATION_VIEW_TYPE
} from 'linkedin-domain-types';
import { Options, parseCollectionResponse, trace } from '../client/Utils';
import { GraphQLRequest } from '../requests/GraphQLRequest';

/**
 * The invite service class that handles all invite related requests
 * @class InviteService - The invite service class that handles all invite related requests
 */
@trace()
export class InviteService {
  /**
   * The client that instantiated this service
   * @private the client that instantiated this service
   */
  private client: Client;

  /**
   * The invite request service
   * @private the invite request service
   */
  inviteRequests: InviteRequest;

  /**
   * The graphQl request service
   */
  private graphQlRequests: GraphQLRequest;

  /**
   * Creates a new InviteService
   * @param client the client that instantiated this service
   */
  constructor(client: Client) {
    this.client = client;
    this.inviteRequests = new InviteRequest({
      requestService: client.requestService
    });
    this.graphQlRequests = new GraphQLRequest(client.requestService);
  }

  /**
   * Gets all sent invitations from the user
   * @param start the number of invitations to skip
   * @param count the number of invitations to fetch
   * @param options the options to use for the request
   */
  getSentInvitations({
    start = 0,
    count = 10,
    options
  }: {
    start?: number;
    count?: number;
    options: Options;
  }): InvitationIterator<LinkedInSentInvitation> {
    return new InvitationIterator({
      start,
      count,
      fetchInvitations: this.fetchSentInvites.bind(this),
      options
    });
  }

  /**
   * Gets a page of event invitations from the user
   *
   * @param start the number of invitations to skip
   * @param count the number of invitations to fetch
   * @param options the options to use for the request
   */
  async getSentInvitationsPage({
    start = 0,
    count = 10,
    options
  }: {
    start?: number;
    count?: number;
    options: Options;
  }) {
    const res = await this.graphQlRequests.graphQLRequest<
      GraphQLResponse<
        {
          relationshipsDashSentInvitationViewsByInvitationType: GraphQLCollection<LinkedInDashSentInvitationView>;
        },
        | (LinkedInDashInvitation & { entityUrn: string })
        | {
            profilePicture?: LinkedInPhotoFilterPicture;
            $type: typeof PROFILE_TYPE;
            entityUrn: string;
          }
      >
    >({
      variables: {
        start,
        count,
        invitationType: 'EVENT'
      },
      queryId:
        'voyagerRelationshipsDashSentInvitationViews.ae305855593fe45f647a54949e3b3c96',
      options
    });

    if (!res || res.errors) return null;

    return parseCollectionResponse(
      res.data.relationshipsDashSentInvitationViewsByInvitationType.elements,
      []
    );
  }

  /**
   * Gets all received invitations from the user
   * @param start the number of invitations to skip
   * @param count the number of invitations to fetch
   * @param options the options to use for the request
   */
  getReceivedInvitations({
    start = 0,
    count = 10,
    options
  }: {
    start?: number;
    count?: number;
    options: Options;
  }): InvitationIterator<LinkedInReceivedInvitation> {
    return new InvitationIterator({
      start,
      count,
      fetchInvitations: this.fetchReceivedInvites.bind(this),
      options
    });
  }

  /**
   * Gets a page of invitations from the user
   * @param start the number of invitations to skip
   * @param count the number of invitations to fetch
   * @param options the options to use for the request
   */
  getReceivedInvitationsPage({
    start = 0,
    count = 10,
    options
  }: {
    start?: number;
    count?: number;
    options: Options;
  }) {
    return this.fetchReceivedInvites({
      start,
      count,
      options
    });
  }

  /**
   * Sends an invitation to a profile given the profile urn
   * @param inviteeProfileUrn the profile to send the invitation to
   * @param customMessage the custom message to send with the invitation
   * @param options the options to use for the request
   */
  async sendInvitation(
    inviteeProfileUrn: DashProfileUrn,
    customMessage: string,
    options: Options
  ): Promise<LinkedInSentInvitation> {
    await this.inviteRequests.sendInvitation({
      inviteeProfileUrn,
      customMessage,
      options
    });

    return [
      ...(await this.fetchSentInvites({ start: 0, count: 1, options }))
    ][0];
  }

  /**
   * Fetches sent invitations
   * @param start the number of invitations to skip
   * @param count the number of invitations to fetch
   * @param options the options to use for the request
   * @private fetches sent invitations
   */
  private async fetchSentInvites({
    start = 0,
    count = 10,
    options
  }: {
    start?: number;
    count?: number;
    options: Options;
  }): Promise<Iterable<LinkedInSentInvitation>> {
    const res = await this.inviteRequests.getSentInvitations({
      start,
      count,
      options
    });
    if (!res) return [];
    return InviteService.parseSent(res).values();
  }

  /**
   * Fetches received invitations
   * @param start the number of invitations to skip
   * @param count the number of invitations to fetch
   * @param options the options to use for the request
   * @private fetches received invitations
   */
  private async fetchReceivedInvites({
    start = 0,
    count = 10,
    options
  }: {
    start?: number;
    count?: number;
    options: Options;
  }): Promise<Iterable<LinkedInReceivedInvitation>> {
    const res = await this.inviteRequests.getReceivedInvitations({
      start,
      count,
      options
    });
    if (!res) return [];
    return InviteService.parseReceived(res).values();
  }

  /**
   * Parses Sent invitations
   * @param res the response from the request
   * @private parses received invitations
   */
  public static parseSent(
    res: GetSentInvitationsResponse
  ): LinkedInSentInvitation[] {
    const mapping: {
      MINI_PROFILE_TYPE: Map<MiniProfileUrn, LinkedInMiniProfile>;
      INVITATION_TYPE: Map<RelInvitationUrn, LinkedInSentInvitation>;
      SENT_INVITATION_VIEW_TYPE: Map<
        RelSentInvitationViewUrn,
        LinkedInSentInvitationViewV2
      >;
    } = {
      MINI_PROFILE_TYPE: new Map(),
      INVITATION_TYPE: new Map(),
      SENT_INVITATION_VIEW_TYPE: new Map()
    };

    res.included.forEach((i) => {
      if (i.$type === MINI_PROFILE_TYPE) {
        mapping.MINI_PROFILE_TYPE.set(i.entityUrn, i);
      } else if (i.$type === INVITATION_TYPE) {
        mapping.INVITATION_TYPE.set(i.entityUrn, i);
      } else if (i.$type === SENT_INVITATION_VIEW_TYPE) {
        mapping.SENT_INVITATION_VIEW_TYPE.set(i.entityUrn, i);
      }
    });

    mapping.INVITATION_TYPE.forEach((i) => {
      const fromMember = mapping.MINI_PROFILE_TYPE.get(i['*fromMember']);
      if (fromMember) i.fromMember = fromMember;

      const toMember = mapping.MINI_PROFILE_TYPE.get(i['*toMember']);
      if (toMember) i.toMember = toMember;

      const miniProfile = mapping.MINI_PROFILE_TYPE.get(
        i.invitee['*miniProfile']
      );
      if (miniProfile) i.invitee.miniProfile = miniProfile;
    });

    mapping.SENT_INVITATION_VIEW_TYPE.forEach((i) => {
      const invitation = mapping.INVITATION_TYPE.get(i['*invitation']);
      if (invitation) i.invitation = invitation;
    });

    return [...mapping.INVITATION_TYPE.values()].sort((a, b) => {
      return b.sentTime - a.sentTime;
    });
  }

  /**
   * Parses received invitations and returns an array of invitations
   * @param res the response from the request
   * @private parses received invitations
   */
  public static parseReceived(
    res: GetReceivedInvitationsResponse
  ): (LinkedInReceivedInvitation & LinkedInGenericInvitationView)[] {
    const mapping: {
      MINI_PROFILE_TYPE: Map<MiniProfileUrn, LinkedInMiniProfile>;
      INVITATION_TYPE: Map<RelInvitationUrn, LinkedInReceivedInvitation>;
      INVITATION_VIEW_TYPE: Map<RelInvitationViewUrn, LinkedInInvitationView>;
      GENERIC_INVITATION_VIEW_TYPE: Map<
        RelInvitationViewUrn,
        LinkedInGenericInvitationView
      >;
    } = {
      MINI_PROFILE_TYPE: new Map(),
      INVITATION_TYPE: new Map(),
      INVITATION_VIEW_TYPE: new Map(),
      GENERIC_INVITATION_VIEW_TYPE: new Map()
    };

    res.included.forEach((i) => {
      if (i.$type === MINI_PROFILE_TYPE) {
        mapping.MINI_PROFILE_TYPE.set(i.entityUrn, i);
      } else if (i.$type === INVITATION_TYPE) {
        mapping.INVITATION_TYPE.set(i.entityUrn, i);
      } else if (i.$type === INVITATION_VIEW_TYPE) {
        mapping.INVITATION_VIEW_TYPE.set(i.entityUrn, i);
      } else if (i.$type === GENERIC_INVITATION_VIEW_TYPE) {
        mapping.GENERIC_INVITATION_VIEW_TYPE.set(i.entityUrn, i);
      }
    });

    mapping.GENERIC_INVITATION_VIEW_TYPE.forEach((i) => {
      i.primaryImage.attributes.forEach((a) => {
        if (a.sourceType === 'PROFILE_PICTURE') {
          const b = a;
          const miniProfile = mapping.MINI_PROFILE_TYPE.get(b['*miniProfile']);
          if (miniProfile) b.miniProfile = miniProfile;
        }
      });
    });

    mapping.INVITATION_VIEW_TYPE.forEach((i) => {
      const invitation = mapping.INVITATION_TYPE.get(i['*invitation']);
      if (invitation) i.invitation = invitation;
      const genericInvitationView = mapping.GENERIC_INVITATION_VIEW_TYPE.get(
        i['*genericInvitationView']
      );
      if (genericInvitationView)
        i.genericInvitationView = genericInvitationView;

      i.invitation.invitationType ??= i.genericInvitationView.invitationType;
      // TODO: Change sentTime to number and parse it
      i.invitation.sentTime ??= i.genericInvitationView.sentTime;
      i.invitation.unseen ??= i.genericInvitationView.unseen;
    });

    const results: (LinkedInReceivedInvitation &
      LinkedInGenericInvitationView)[] = [];
    mapping.INVITATION_VIEW_TYPE.forEach((i) => {
      const stripped = {
        ...i.genericInvitationView,
        ...i.invitation
      } as LinkedInReceivedInvitation & LinkedInGenericInvitationView;
      results.push(stripped);
    });
    return results;
  }
}
