import { Client } from '../client/Client';
import { SearchRequest } from '../requests/SearchRequest';
import {
  BlendedSearchResponse,
  LinkedInMemberBadges,
  LinkedInMiniCompany,
  LinkedInMiniJob,
  LinkedInMiniProfile,
  LinkedInSocialActivityCounts,
  LinkedInUpdateSummary,
  MEMBER_BADGES_TYPE,
  MemberBadgesUrn,
  MINI_COMPANY_TYPE,
  MINI_JOB_TYPE,
  MINI_PROFILE_TYPE,
  MiniCompanyUrn,
  MiniJobUrn,
  MiniProfileUrn,
  SOCIAL_ACTIVITY_COUNTS_TYPE,
  SocialActivityCountsUrn,
  UPDATE_SUMMARY_TYPE,
  UpdateSummaryUrn,
  UserSearchFilters,
  UserSearchHit
} from 'linkedin-domain-types';
import { UserIterator } from '../iterator/UserIterator';
import { Options, trace } from '../client/Utils';

@trace()
export class SearchService {
  /**
   * The client that instantiated this service
   * @private the client that instantiated this service
   */
  private client: Client;

  /**
   * The search requests
   * @private the search requests
   */
  private searchRequests: SearchRequest;

  /**
   * Creates a new SearchService
   * @param client the client that instantiated this service
   */
  constructor(client: Client) {
    this.client = client;
    this.searchRequests = new SearchRequest(client.requestService);
  }

  /**
   * Searches for users
   */
  searchUsers({
    keywords,
    start = 0,
    count = 10,
    filters = {},
    options
  }: {
    keywords?: string;
    start?: number;
    count?: number;
    filters?: UserSearchFilters;
    options: Options;
  }) {
    return new UserIterator({
      keywords,
      start,
      count,
      filters,
      fetchUsers: this.fetchUsers.bind(this),
      options
    });
  }

  /**
   * Fetches users
   */
  async fetchUsers({
    keywords,
    start,
    count,
    filters = {},
    options
  }: {
    keywords?: string;
    start?: number;
    count?: number;
    filters?: UserSearchFilters;
    options: Options;
  }): Promise<Iterable<UserSearchHit>> {
    const res = await this.searchRequests.blendedSearch({
      keywords,
      start,
      count,
      filters,
      options
    });
    if (!res) return [];
    return SearchService.parseUsers(res);
  }

  public static parseUsers(
    res: BlendedSearchResponse
  ): Iterable<UserSearchHit> {
    const mapping: {
      MINI_PROFILE_TYPE: Map<MiniProfileUrn, LinkedInMiniProfile>;
      MEMBER_BADGES_TYPE: Map<MemberBadgesUrn, LinkedInMemberBadges>;
      SOCIAL_ACTIVITY_COUNTS_TYPE: Map<
        SocialActivityCountsUrn,
        LinkedInSocialActivityCounts
      >;
      UPDATE_SUMMARY_TYPE: Map<UpdateSummaryUrn, LinkedInUpdateSummary>;
      MINI_JOB_TYPE: Map<MiniJobUrn, LinkedInMiniJob>;
      MINI_COMPANY_TYPE: Map<MiniCompanyUrn, LinkedInMiniCompany>;
    } = {
      MINI_PROFILE_TYPE: new Map(),
      MEMBER_BADGES_TYPE: new Map(),
      SOCIAL_ACTIVITY_COUNTS_TYPE: new Map(),
      UPDATE_SUMMARY_TYPE: new Map(),
      MINI_JOB_TYPE: new Map(),
      MINI_COMPANY_TYPE: new Map()
    };

    res.included.forEach((item) => {
      if (item.$type === MINI_PROFILE_TYPE) {
        mapping.MINI_PROFILE_TYPE.set(item.entityUrn, item);
      } else if (item.$type === MEMBER_BADGES_TYPE) {
        mapping.MEMBER_BADGES_TYPE.set(item.entityUrn, item);
      } else if (item.$type === SOCIAL_ACTIVITY_COUNTS_TYPE) {
        mapping.SOCIAL_ACTIVITY_COUNTS_TYPE.set(item.entityUrn, item);
      } else if (item.$type === UPDATE_SUMMARY_TYPE) {
        mapping.UPDATE_SUMMARY_TYPE.set(item.entityUrn, item);
      } else if (item.$type === MINI_JOB_TYPE) {
        mapping.MINI_JOB_TYPE.set(item.entityUrn, item);
      } else if (item.$type === MINI_COMPANY_TYPE) {
        mapping.MINI_COMPANY_TYPE.set(item.entityUrn, item);
      }
    });

    mapping.UPDATE_SUMMARY_TYPE.forEach((item) => {
      item.socialCounts = mapping.SOCIAL_ACTIVITY_COUNTS_TYPE.get(
        item['*socialCounts']
      );

      item.actorName.attributes.forEach((attr) => {
        if (attr.type === 'PROFILE_FULLNAME') {
          attr.miniProfile = mapping.MINI_PROFILE_TYPE.get(
            attr['*miniProfile']
          );
        } else if (attr.type === 'MINI_COMPANY') {
          attr.miniCompany = mapping.MINI_COMPANY_TYPE.get(
            attr['*miniCompany']
          );
        }
      });
    });

    const hits = res.data.elements
      .flatMap((element) => element.elements)
      .filter((element) => element.type === 'PROFILE');
    hits.forEach((element) => {
      element.badges = mapping.MEMBER_BADGES_TYPE.get(element['*badges']);

      element.image?.attributes.forEach((attr) => {
        if (attr.sourceType === 'PROFILE_PICTURE') {
          attr.miniProfile = mapping.MINI_PROFILE_TYPE.get(
            attr['*miniProfile']
          );
        } else if (attr.sourceType === 'COMPANY_LOGO') {
          attr.miniCompany = mapping.MINI_COMPANY_TYPE.get(
            attr['*miniCompany']
          );
        }
      });

      element.socialProofImagePile?.forEach((image) => {
        image.attributes.forEach((attr) => {
          if (attr.sourceType === 'PROFILE_PICTURE') {
            attr.miniProfile = mapping.MINI_PROFILE_TYPE.get(
              attr['*miniProfile']
            );
          } else if (attr.sourceType === 'COMPANY_LOGO') {
            attr.miniCompany = mapping.MINI_COMPANY_TYPE.get(
              attr['*miniCompany']
            );
          }
        });
      });
    });

    return hits
      .map((hit) => {
        return {
          ...hit,
          profile: mapping.MINI_PROFILE_TYPE.get(hit.targetUrn)
        } as UserSearchHit;
      })
      .filter((hit) => hit.profile);
  }
}
