import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography
} from '@mui/material';
import { language } from '@/index';
import { Background, ConteaIcon, FancyButton } from 'ui-utils';
import { useScheduler } from 'focus-mode-scheduler';
import MainConfig from '@common/config/MainConfig';
import { Done, LinkedIn } from '@mui/icons-material';
import React from 'react';
import posthog from 'posthog-js';

export default function WritePostTaskUI() {
  const { complete } = useScheduler();

  const [buttonEnabled, setButtonEnabled] = React.useState(false);

  return (
    <Background
      variant="frame"
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        height: '100vh',
        width: '100%',
        overflow: 'overlay',
        boxSizing: 'border-box',
        p: 4
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          gap: 4,
          maxWidth: 800,
          mx: 'auto',
          height: '100%'
        }}>
        <Typography variant="h4">{language.text.write_post}</Typography>
        <Typography variant="body1" textAlign="center">
          {language.text.write_post_description}
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            mt: 4,
            gap: 4
          }}>
          <Card>
            <CardActionArea
              sx={{ height: '100%' }}
              onClick={() => {
                posthog.capture('writing-post:linkedin');
                window.api.send('url:open_ext', MainConfig.linkedInUrl);
                setButtonEnabled(true);
              }}>
              <CardContent>
                <Typography variant="h6" gutterBottom component="div">
                  <LinkedIn
                    color="primary"
                    fontSize="large"
                    sx={{ verticalAlign: 'middle', mr: 1 }}
                  />
                  {language.text.open_linkedin}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card
            sx={{
              borderWidth: 2,
              borderColor: '#FF008D',
              borderStyle: 'solid'
            }}>
            <CardActionArea
              onClick={() => {
                posthog.capture('writing-post:contea');
                window.api.send('url:open_ext', MainConfig.conteaLink);
                setButtonEnabled(true);
              }}>
              <CardContent>
                <ConteaIcon sx={{ width: '100%', height: '2rem', mb: 1 }} />
                <Typography variant="body2" color="text.secondary">
                  {language.text.contea_description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
        <FancyButton
          size="large"
          color="primary"
          variant="contained"
          fType={{ promise: true, pulsating: 'default' }}
          animations={{ disableElevation: true }}
          onClick={complete.bind(null, { itemsDone: 1 })}
          disabled={!buttonEnabled}
          startIcon={<Done />}>
          {language.text.next}
        </FancyButton>
      </Box>
    </Background>
  );
}
