import React from 'react';
import {
  Navigate,
  NavigateFunction,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';
import Redirect from '../components/Redirect/Redirect';
import FocusMode from './Focus/FocusMode';
import Setup from './Setup/Setup';
import ManualMode from './ManualMode/ManualMode';
import Login from './Login/LoginRouter';
import ErrorPage from './Error/ErrorPage';
import Maintenance from './Maintenance/Maintenance';
import { ReactQueryDevtools } from 'react-query/devtools';
import DevConfig from '@common/config/DevConfig';
import { APP_PATHS, UPDATER_PATHS } from '../globals/Paths';
import MountWebview from '@common/Webview.renderer/MountWebview';
import log from 'electron-log';
import Debugger from './Debugger/Debugger';
import UpdateRouter from './Update/UpdateRouter';
import Versions from '../other/Versions';
import LoaderRouter from './Loader/LoaderRouter';
import WebViewLinkedIn from '@common/Webview.renderer/WebviewLinkedIn';
import CelebrateUpdate from '../components/CelebrateUpdate/CelebrateUpdate';
import AppActions from '../data/DataServer/App';
import LoadingManager from '@common/LoadingManager/LoadingManager.renderer';
import useTrackUserActivity from '@/hooks/UserActivity';
import UsageWarningDialog from '@/components/UsageWarningDialog/UsageWarningDialog';
import AskToRestart from '@/components/AskToRestart/AskToRestart';

export let navigate: NavigateFunction = (path) => {
  log.error('navigate function used before initialized: ', path);
};

function MainRouter() {
  const { pathname } = useLocation();
  const _navigate = useNavigate();
  navigate = _navigate;

  useTrackUserActivity();

  React.useEffect(() => {
    if (
      pathname.startsWith(`/${APP_PATHS.Login}/`) ||
      pathname.startsWith(`/${APP_PATHS.Debugger}`)
    )
      return;
    LoadingManager.init();
  }, [pathname]);

  React.useEffect(() => {
    if (
      !pathname.startsWith(`/${APP_PATHS.Login}/`) &&
      !pathname.startsWith(`/${APP_PATHS.Debugger}`)
    )
      // mostly only needed for dev if we reload on a subpage that has no active webview
      WebViewLinkedIn.loggedIn = true;
  }, [pathname]);

  // check if main thread is outdated -> force user to upgrade
  React.useEffect(() => {
    if (Versions.isMainThreadOutDated())
      navigate(`/${APP_PATHS.Updater}/${UPDATER_PATHS.Required}`);
  }, []);

  const [showQueryDevTools, setShowQueryDevTools] = React.useState(
    DevConfig.showQueryDevTools
  );

  const [showActionWebview, setShowActionWebview] = React.useState(false);

  // set react_path as window attribute for debug purposes
  window.react_path = pathname;
  window.navigate = navigate;
  window.toggleQueryDevTools = () => setShowQueryDevTools((show) => !show);
  window.toggleActionWebviewVisibility = (newShow?: boolean) =>
    setShowActionWebview((show) => newShow ?? !show);

  const shouldForceUpdateDownload = AppActions.useShouldForceUpdateDownload();

  if (shouldForceUpdateDownload) return <CelebrateUpdate />;

  return (
    <>
      {/* this components mounts a webview in the background if needed to perform actions */}
      <MountWebview show={showActionWebview} />
      <UsageWarningDialog />
      <AskToRestart />
      <Routes>
        <Route index element={<Redirect to={`/${APP_PATHS.Setup}`} />} />
        <Route
          path={`${APP_PATHS.Setup}/*`}
          element={
            <Setup
              basePath={APP_PATHS.Setup}
              redirectAfterFinish={`/${APP_PATHS.Manual}`}
            />
          }
        />
        <Route path={`${APP_PATHS.Focus}/*`} element={<FocusMode />} />
        <Route path={`${APP_PATHS.Manual}/*`} element={<ManualMode />} />
        <Route path={`${APP_PATHS.Login}/*`} element={<Login />} />
        <Route path={`${APP_PATHS.Error}/:error`} element={<ErrorPage />} />
        <Route path={`${APP_PATHS.Error}/*`} element={<ErrorPage />} />
        <Route path={`${APP_PATHS.Maintenance}/*`} element={<Maintenance />} />
        <Route path={`${APP_PATHS.Updater}/*`} element={<UpdateRouter />} />
        <Route path={`${APP_PATHS.Debugger}/*`} element={<Debugger />} />
        <Route path={`${APP_PATHS.Loader}/*`} element={<LoaderRouter />} />
        <Route path="*" element={<Navigate to={`/${APP_PATHS.Error}/404`} />} />
      </Routes>
      {showQueryDevTools && <ReactQueryDevtools initialIsOpen />}
    </>
  );
}

export default MainRouter;
