import { Box } from '@mui/material';
import DayImg1 from '@/assets/img/login/day-medals/day-01.png';
import DayImg2 from '@/assets/img/login/day-medals/day-02.png';
import DayImg3 from '@/assets/img/login/day-medals/day-03.png';
import DayImg4 from '@/assets/img/login/day-medals/day-04.png';
import DayImg5 from '@/assets/img/login/day-medals/day-05.png';
import DayImg6 from '@/assets/img/login/day-medals/day-06.png';
import DayImg7 from '@/assets/img/login/day-medals/day-07.png';
import DayImg8 from '@/assets/img/login/day-medals/day-08.png';
import DayImg9 from '@/assets/img/login/day-medals/day-09.png';
import DayImg10 from '@/assets/img/login/day-medals/day-10.png';

const DAY_IMGs = [
  DayImg1,
  DayImg2,
  DayImg3,
  DayImg4,
  DayImg5,
  DayImg6,
  DayImg7,
  DayImg8,
  DayImg9,
  DayImg10
];

export interface LoginDayCounterProps {
  days?: number;
}

const TRANSITION_TIME = 400;

export default function LoginDayCounter(props: LoginDayCounterProps) {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        gap: 2
      }}>
      {DAY_IMGs.map((img, i) => (
        <Box
          key={i}
          sx={{
            aspectRatio: '87/64',
            maxHeight: (theme) => `min(${theme.spacing(8)}, 5vw)`
          }}>
          <Box
            component={'img'}
            src={img}
            sx={{
              height: '100%',
              objectFit: 'contain',
              filter: i + 1 > (props.days ?? 0) ? 'grayscale(100%)' : undefined,
              transition: (theme) =>
                theme.transitions.create('filter', {
                  duration: TRANSITION_TIME
                }),
              transitionDelay: `${TRANSITION_TIME * i}ms`
            }}
          />
        </Box>
      ))}
    </Box>
  );
}
