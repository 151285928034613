import lang_de from '@common/config/Language/de';

export type Languages = 'en' | 'de';

export type LanguageObject = typeof lang_de;

export type LangaugeKeys = keyof LanguageObject;

export const CS_LANGUAGE_KEYS = [
  'hiding_already_known_profiles',
  'reposts',
  'audience_holder',
  'add_audience_holder',
  'remove_audience_holder',
  'mark_profile',
  'unmark_profile',
  'save_profile',
  'save',
  'marked',
  'delete',
  'customer',
  'no_match',
  'potential_customer',
  'personal_contact'
] satisfies (keyof LanguageObject)[];
