import { StartCountIterator } from './StartCountIterator';
import { UserSearchFilters, UserSearchHit } from 'linkedin-domain-types';
import { Options, trace } from '../client/Utils';

type FetchUsers = ({
  keywords,
  start,
  count,
  filters,
  options
}: {
  keywords?: string;
  start?: number;
  count?: number;
  filters?: UserSearchFilters;
  options: Options;
}) => Promise<Iterable<UserSearchHit>>;

/**
 * A wrapper around the UserPageIterator that iterates over single users instead of pages and fetches the next page when needed.
 */
@trace()
export class UserIterator implements AsyncIterator<UserSearchHit> {
  /**
   * The page iterator
   */
  private readonly pageIterator: UserPageIterator;

  /**
   * The current page
   */
  private entries: UserSearchHit[] = [];

  /**
   * The current index in the current page
   */
  private index = 0;

  /**
   * Creates a new user iterator
   */
  constructor(params: {
    keywords?: string;
    start?: number;
    count?: number;
    filters: UserSearchFilters;
    fetchUsers: FetchUsers;
    options: Options;
  }) {
    this.pageIterator = new UserPageIterator(params);
  }

  /**
   * Fetches the next user and advances the iterator.
   * @returns the next user
   *
   * @see AsyncIterator.next
   */
  async next(): Promise<IteratorResult<UserSearchHit, UserSearchHit>> {
    if (this.index >= this.entries.length) {
      const { done, value } = await this.pageIterator.next();
      if (done) {
        return { done: true, value: {} as UserSearchHit };
      }
      this.entries.push(...value);
    }
    return { done: false, value: this.entries[this.index++] };
  }

  /**
   * Returns the previous user and rewinds the iterator.
   */
  async prev(): Promise<IteratorResult<UserSearchHit, UserSearchHit>> {
    if (this.index <= 0) {
      const { done, value } = await this.pageIterator.prev();
      if (done) {
        return { done: true, value: {} as UserSearchHit };
      }
      this.entries.unshift(...value);
      this.index = this.entries.length;
    }
    return { done: false, value: this.entries[--this.index] };
  }

  /**
   * Resets the iterator and clears the already fetched users.
   */
  restart(): void {
    this.pageIterator.restart();
    this.entries = [];
    this.index = 0;
  }
}

/**
 * A user page iterator that supports skip and limit.
 * This iterator is used to fetch user pages from the search.
 *
 * @extends StartCountIterator<UserSearchHit>
 */
@trace()
class UserPageIterator extends StartCountIterator<UserSearchHit> {
  /**
   * The keywords used in the search
   */
  private readonly keywords?: string;

  /**
   * The filters used in the search
   */
  private readonly filters: UserSearchFilters;

  /**
   * The options to use for requests
   */
  private readonly options: Options;

  /**
   * The function used to fetch users
   */
  private readonly FetchUsers: FetchUsers;

  /**
   * Creates a new user iterator
   * @param params.keywords the keywords used in the search
   * @param params.start the start index
   * @param params.count the number of users to fetch
   * @param params.filters the filters used in the search
   * @param params.fetchUsers the function used to fetch users
   *
   * @returns a new user iterator
   */
  constructor(params: {
    keywords?: string;
    start?: number;
    count?: number;
    filters: UserSearchFilters;
    fetchUsers: FetchUsers;
    options: Options;
  }) {
    super({
      start: params.start,
      count: params.count
    });
    this.keywords = params.keywords;
    this.filters = params.filters;
    this.FetchUsers = params.fetchUsers;
    this.options = params.options;
  }
  async fetch(): Promise<Iterable<UserSearchHit>> {
    return this.FetchUsers({
      keywords: this.keywords,
      start: this.start,
      count: this.count,
      filters: this.filters,
      options: this.options
    });
  }
}
