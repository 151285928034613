import {
  Attachment,
  CategoryConversation,
  ConversationContact,
  LinkedInAccount,
  LinkedInContact,
  Message
} from './domain/PublicTypes';
import {
  FILE_ATTACHMENT_TYPE,
  LinkedInFileAttachment,
  LinkedInMessage,
  LinkedInMessagingParticipant,
  LinkedInMessengerAudioMetadata,
  LinkedInMessengerConversation,
  LinkedInRenderContent,
  MESSENGER_AUDIO_METADATA_TYPE
} from './linkedin/Messenger';
import { LinkedInVectorImage, VECTOR_IMAGE_TYPE } from './linkedin/Common';
import { EventCreateResponse } from './linkedin/responses/EventCreateResponse';
import { Arrays } from '@idot-digital/generic-helpers';
import { LinkedInProfile } from './linkedin/Profile';
import { LinkedInMiniProfile } from './linkedin/Identity';

/**
 * Parses a LinkedInMessengerConversation received from the LinkedIn API to a Conversation object
 * @param conversation LinkedInMessengerConversation to parse
 */
export function parseLinkedInMessengerConversation(
  conversation: LinkedInMessengerConversation
): CategoryConversation {
  return {
    conversationID: conversation.backendUrn.split(':').pop() ?? '',
    participants: Arrays.filterNull(
      conversation.conversationParticipants.map((participant) =>
        parseParticipant(participant)
      )
    ),
    archived: conversation.categories.includes('ARCHIVE'),
    read: conversation.read,
    unreadCount: conversation.unreadCount,
    lastActivityAt: new Date(conversation.lastActivityAt),
    lastMessages: (
      conversation.lastMessages ??
      conversation.messages?.elements ??
      []
    ).map((msg) => parseLinkedInMessage(msg))
  };
}

/**
 * Parses a LinkedInMessage received from the LinkedIn API to a Message object
 * @param message LinkedInMessage to parse
 */
export function parseLinkedInMessage(message: LinkedInMessage): Message {
  return {
    messageID: message.backendUrn.split(':').pop() ?? '',
    sentFrom: message.sender.hostIdentityUrn.split(':').pop() ?? '',
    reactions: message.reactionSummaries.map((reaction) => ({
      emoji: reaction.emoji,
      count: reaction.count,
      viewerReacted: reaction.viewerReacted
    })),
    text: message.body.text,
    deleted: false,
    createdAt: new Date(message.deliveredAt),
    attachments: parseAttachments(message.renderContent)
  };
}

/**
 * Parses a participant from a LinkedInMessagingParticipant to a ConversationContact
 * @param participant LinkedInMessagingParticipant to parse
 * @returns ConversationContact parsed from the participant (or null if self)
 */
export function parseParticipant(
  participant: LinkedInMessagingParticipant
): Omit<ConversationContact, 'publicIdentifier'> | null {
  let ret: Omit<ConversationContact, 'publicIdentifier'> | null;
  if (participant.participantType.member) {
    if (participant.participantType.member.distance === 'SELF') ret = null;
    else {
      ret = {
        profileID: participant.hostIdentityUrn.split(':').pop() ?? '',
        firstName: participant.participantType.member.firstName?.text ?? '',
        lastName: participant.participantType.member.lastName?.text ?? '',
        profilePictureUrl: resolveProfilePictureUrls(
          participant.participantType.member.profilePicture
        ),
        infoText: participant.participantType.member.headline.text ?? undefined,
        distance: (() => {
          switch (participant.participantType.member?.distance) {
            case 'DISTANCE_1':
              return 1;
            case 'DISTANCE_2':
              return 2;
            case 'DISTANCE_3':
              return 3;
          }
        })()
      };
    }
  } else if (participant.participantType.organization) {
    ret = {
      profileID: participant.hostIdentityUrn.split(':').pop() ?? '',
      firstName: participant.participantType.organization.name.text,
      lastName: '',
      profilePictureUrl: resolveProfilePictureUrls(
        participant.participantType.organization.logo
      ),
      infoText:
        participant.participantType.organization.tagline.text ?? undefined
    };
  } else {
    ret = null;
  }

  return ret;
}

/**
 * Parses a CreateEvent received from the LinkedIn API to a Message object
 *
 * @param event LinkedInCreateEvent to parse
 * @param account LinkedInAccount to parse the event for
 *
 * @returns Message object
 */
export function parseLinkedInCreateEvent(
  event: EventCreateResponse & { text: string },
  account: LinkedInAccount
): Message {
  return {
    messageID:
      event.eventUrn.split(':').pop()?.split(',').pop()?.slice(0, -1) ?? '',
    deleted: false,
    text: event.text,
    sentFrom: account.profileID,
    createdAt: new Date(event.createdAt),
    attachments: [],
    reactions: []
  };
}

export function parseLinkedInProfile(
  profile: LinkedInProfile
): LinkedInContact {
  const month = profile.birthDateOn?.month;
  const day = profile.birthDateOn?.day;
  const connection =
    profile.memberRelationship?.memberRelationshipUnion ??
    profile.memberRelationship?.memberRelationship;
  return {
    profileID: profile.entityUrn.split(':').pop() ?? '',
    firstName: profile.firstName,
    lastName: profile.lastName,
    infoText: profile.headline,
    profilePictureUrl: resolveProfilePictureUrls(
      profile.profilePicture?.displayImageReference?.vectorImage
    ),
    publicIdentifier: profile.publicIdentifier,
    birthday: month && day ? { month, day } : undefined,
    isConnection: connection
      ? Object.keys(connection).includes('connection')
      : undefined
  };
}

export function parseLinkedInMiniProfile(
  profile: LinkedInMiniProfile
): LinkedInAccount {
  return {
    profileID: profile.entityUrn.split(':').pop() ?? '',
    firstName: profile.firstName,
    lastName: profile.lastName,
    profilePictureUrl: resolveProfilePictureUrls(profile.picture),
    publicIdentifier: profile.publicIdentifier
  };
}

export function parseAttachments(
  attachments: LinkedInRenderContent[]
): Attachment[] {
  return attachments
    .map((attachment) => {
      const value = Object.values(attachment).find(
        (value) => value !== null
      ) as {
        $type: string;
      };
      if (value.$type === FILE_ATTACHMENT_TYPE) {
        return {
          url: (value as LinkedInFileAttachment).url,
          type: 'file'
        };
      } else if (value.$type === VECTOR_IMAGE_TYPE) {
        return {
          url: (value as LinkedInVectorImage).rootUrl,
          type: 'image'
        };
      } else if (value.$type === MESSENGER_AUDIO_METADATA_TYPE) {
        return {
          url: (value as LinkedInMessengerAudioMetadata).url,
          type: 'audio'
        };
      }
      return undefined;
    })
    .filter((a) => a !== undefined) as Attachment[];
}

function resolveProfilePictureUrls(
  vectorImage: LinkedInVectorImage | undefined
): Record<number, string> {
  if (!vectorImage) return {};
  return Object.fromEntries(
    vectorImage.artifacts.map((element) => [
      element.height,
      vectorImage.rootUrl + element.fileIdentifyingUrlPathSegment
    ])
  );
}
