import React from 'react';
import FilterIcon, { FilterIconProps } from './FilterIcon';
import { MenuItem } from '@mui/material';
import { language } from '@/index';

export interface FilterMenuIconProps<T>
  extends Omit<FilterIconProps, 'children' | 'active'> {
  current: T | null;
  options: {
    value: T;
    label: string;
    icon?: React.ReactNode;
  }[];
  onChange: (value: T | null) => void;
  allLabel?: string;
  hideAll?: boolean;
}

export default function FilterMenuIcon<T>(props: FilterMenuIconProps<T>) {
  const { current, options, onChange, allLabel, ...rest } = props;
  return (
    <FilterIcon {...rest} active={current !== null}>
      {!props.hideAll && (
        <MenuItem onClick={() => onChange(null)} selected={current === null}>
          {allLabel ?? language.text.all}
        </MenuItem>
      )}
      {options.map((option, i) => (
        <MenuItem
          key={option.label + i.toString()}
          onClick={() =>
            onChange(current === option.value ? null : option.value)
          }
          selected={current === option.value}>
          {option.icon && (
            <>
              {option.icon}
              &nbsp;
            </>
          )}
          {option.label}
        </MenuItem>
      ))}
    </FilterIcon>
  );
}
