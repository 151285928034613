import { Contact } from './Contact';
import {
  Message as CloudMessage,
  Message,
  MessageTemplate
} from '@common/types/ApiTypes';
import {
  CategoryConversation,
  Conversation,
  ConversationContact
} from 'linkedin-domain-types';
import { SSCChat } from './Chat/SSCChat';
import { TutorialContact } from './TutorialContact';
import { language } from '@/index';
import { VariableTypes } from 'ui-utils/src/components/TextEditor/CustomBlocks/Variable/VariableBlock';

const VARIABLE_WRAPPER = (id: VariableTypes) =>
  `<ssc-variable>${id}</ssc-variable>`;

const TEMPLATE_VARIABLES = () =>
  ({
    [VARIABLE_WRAPPER('firstname')]: (c) => c.firstname,
    [VARIABLE_WRAPPER('lastname')]: (c) => c.lastname,
    [VARIABLE_WRAPPER('fullname')]: (c) => c.name
  }) satisfies {
    [key: string]: (contact: Contact) => string;
  };

export class TutorialChat extends SSCChat {
  constructor(options: {
    contact: TutorialContact;
    template?: MessageTemplate;
  }) {
    super({
      ownPublicIdentifier: 'self',
      contact: options.contact,
      template: options.template
    });
  }
  /**
   * Currently DB is only used for 1:1 conversations
   */
  protected async canHandleOnDB(): Promise<boolean> {
    return false;
  }
  /**
   * Fetch the conversation data from the API
   */
  public async getConversationData(): Promise<
    Conversation | CategoryConversation | null
  > {
    return this.conversationData;
  }
  /**
   * Some message are chat events (like a person joining a group chat)
   * This function checks if a message is an actual message and should be displayed
   */
  protected shouldMessageBeDisplayed(message: CloudMessage): boolean {
    return Boolean(message.text) || message.attachments.length > 0;
  }
  public async listMessages(
    _lastMessage?: Pick<CloudMessage, 'createdAt' | 'messageID'>
  ): Promise<CloudMessage[]> {
    return [];
  }
  public static getListMessagesQueryKey(
    _chat?: SSCChat | string | null
  ): string[] {
    return ['conversation', 'messages', 'tutorial'];
  }
  public async sendMessage(text: string): Promise<CloudMessage> {
    return {
      reactions: [],
      attachments: [],
      createdAt: new Date(),
      deleted: false,
      messageID: 'messageID',
      sendByYou: true,
      text
    };
  }

  public async resendSendMessageEvent() {
    throw new Error('Not available for tutorial chats');
  }

  public async sendMessageReaction(
    _message: CloudMessage,
    _emoji: string,
    _status: boolean
  ): Promise<void> {
    throw new Error('Not available for tutorial chats');
  }

  public async markAsRead(read: boolean): Promise<void> {
    if (this.conversationData) this.conversationData.read = read;
    if (this.chatDetails) this.chatDetails.unreadCount = read ? 0 : 1;
  }

  public async setArchived(_archived: boolean): Promise<void> {
    throw new Error('Not available for tutorial chats');
  }

  public setTemplate(template: MessageTemplate | null): void {
    this._template = template;
  }

  /**
   * Get the message template for the current step
   */
  public getFilledTemplate(template?: string | null): string | null {
    let text = template ?? this._template?.text;
    if (!text) return null;
    if (!this.contact) return text;

    for (const [code, resolve] of Object.entries(TEMPLATE_VARIABLES())) {
      const value = resolve(this.contact);
      if (value) {
        text = text.replace(new RegExp(code, 'ig'), value);
      }
    }

    return text;
  }

  public get participants():
    | ConversationContact[]
    | CategoryConversation['participants'] {
    return this.conversationData?.participants ?? [];
  }
  public get unreadCount(): number {
    return this.conversationData?.unreadCount ?? 0;
  }
  public get read(): boolean {
    return this.conversationData?.read ?? !this.chatDetails?.unreadCount;
  }
  public get unread(): boolean {
    return !this.read;
  }
  public get archived(): boolean {
    return this.conversationData?.archived ?? false;
  }
  public get conversationID(): string | null {
    return (
      this._conversationID ?? this.conversationData?.conversationID ?? null
    );
  }
  public get lastActivityAt(): Date | null {
    const date =
      this.conversationData?.lastActivityAt ??
      this.chatDetails?.lastActivityAt ??
      null;
    // copy to prevent mutation
    if (date) return new Date(date);
    return null;
  }
  public get lastMessage(): string | null {
    const lastMessage = this.conversationData?.lastMessages?.[0] ?? null;
    if (!lastMessage) return null;
    if (lastMessage.text) return lastMessage.text;
    if (lastMessage.attachments?.length) {
      switch (lastMessage.attachments[0].type) {
        case 'image':
          return language.text.image;
        case 'audio':
          return language.text.voice_recording;
        case 'file':
          return language.text.file;
      }
    }
    return null;
  }
  public get cursor(): string | undefined {
    return this._linkedin_cursor;
  }

  public get profileID(): string {
    return this.contact?.profileID ?? this.participants[0].profileID;
  }

  public registerMessage(
    _message: Omit<Message, 'id' | 'userid'>,
    _unread = false
  ) {
    throw new Error('Not available for tutorial chats');
  }
}
