import { Box, BoxProps, SxProps, Theme, Typography } from '@mui/material';
import { Styles } from '../../types';

export interface LoadingFloatingProps extends BoxProps {
  text?: string;
}

const styles: Styles<'letter' | 'wrapper' | 'text'> = {
  letter: {
    'position': 'absolute',
    'opacity': 0,
    'padding': (theme) => theme.spacing(1),
    'width': (theme) => theme.spacing(3),
    'textAlign': 'center',
    'animation': 'move 2s linear infinite',
    '@keyframes move': {
      '0%': {
        left: 0,
        opacity: 0
      },
      '35%': {
        left: '41%',
        transform: 'rotate(0deg)',
        opacity: 1
      },
      '65%': {
        left: '59%',
        transform: 'rotate(0deg)',
        opacity: 1
      },
      '100%': {
        left: '100%',
        transform: 'rotate(-180deg)',
        opacity: 0
      }
    }
  },
  wrapper: {
    position: 'relative',
    userSelect: 'none',
    cursor: 'default'
  },
  text: {
    visibility: 'hidden',
    letterSpacing: '3rem'
  }
};

export default function LoadingFloating(props: LoadingFloatingProps) {
  const { text = 'Loading', ...rest } = props;
  return (
    <Box
      {...rest}
      sx={
        {
          ...styles.wrapper,
          // width: (theme) => theme.spacing(3 * text.length),
          ...rest.sx
        } as SxProps<Theme>
      }>
      {text
        .split('')
        .reverse()
        .map((letter, index) => (
          <Typography
            variant="h4"
            sx={{
              ...styles.letter,
              animationDelay: `${index * 0.2}s`,
              animationDuration: `${text.length * 0.2 + 0.5}s`
            }}
            key={`${text}_${index}`}>
            {letter}
          </Typography>
        ))}
      <Typography variant="h4" sx={styles.text}>
        {text}
      </Typography>
    </Box>
  );
}
