import React from 'react';
import { Box, Typography } from '@mui/material';
import QuestionDate from './QuestionInputs/QuestionDate';
import QuestionChoice from './QuestionInputs/QuestionChoice';
import { ArrowRightIcon, FancyButton } from 'ui-utils';
import { SkipNext } from '@mui/icons-material';
import { language } from '@/index';
import {
  OptionQuestionOption,
  Question
} from '@common/PipelineManager/PipelineTypes';

export interface QuestionFormProps {
  title: string;
  type: Question['type'];
  onFinished: (choice: OptionQuestionOption | Date) => void | Promise<void>;
  onSkip?: () => void | Promise<void>;
  options?: OptionQuestionOption[];
}

export default function QuestionForm(props: QuestionFormProps) {
  const { title, type, onFinished, options } = props;

  const [currentChoice, setCurrentChoice] = React.useState<
    OptionQuestionOption | Date | null
  >(null);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: (theme) => theme.spacing(0, 5),
        boxSizing: 'border-box',
        width: '100%'
      }}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          py: 4,
          alignItems: 'center'
        }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 'bold'
          }}>
          {title}
        </Typography>
        <Box
          sx={{
            // maxWidth: '560px',
            height: '100%',
            marginTop: (theme) => theme.spacing(2),
            width: type === 'question-options' ? '100%' : undefined,
            maxWidth: '600px'
          }}>
          {type === 'question-options' && (
            <QuestionChoice
              current={currentChoice as OptionQuestionOption | null}
              choices={options!}
              onChoiceChange={setCurrentChoice}
            />
          )}
          {type === 'question-date' && (
            <QuestionDate
              date={currentChoice as Date | null}
              onDateChange={(date) => setCurrentChoice(new Date(date))}
            />
          )}
        </Box>
        <Box
          sx={{
            textAlign: 'right',
            alignSelf: 'flex-end',
            gap: 2
          }}>
          {props.onSkip && (
            <FancyButton
              fType={{
                promise: true
              }}
              variant="contained"
              color="inherit"
              onClick={() => onFinished?.(currentChoice!)}
              sx={{
                minWidth: '100px',
                mb: 0,
                ml: 2,
                fontSize: '16px'
              }}
              endIcon={<SkipNext />}>
              {language.text.skip}
            </FancyButton>
          )}
          <FancyButton
            fType={{
              promise: true
            }}
            disabled={type === 'question-options' && currentChoice === null}
            variant="contained"
            onClick={() => onFinished?.(currentChoice!)}
            sx={{
              minWidth: '100px',
              mb: 0,
              ml: 2,
              fontSize: '16px'
            }}
            endIcon={<ArrowRightIcon />}>
            {language.text.continue}
          </FancyButton>
        </Box>
      </Box>
    </Box>
  );
}
