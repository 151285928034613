import LanguageManager from '@common/LanguageManager/LanguageManager';
import { PipelineDefinition, PipelineStep } from './PipelineTypes';

export const getKennelernenPipeline = () => {
  const language = LanguageManager.getInstance();
  return {
    id: 1,
    name: language.text.pipe_meet_name,
    description: language.text.pipe_meet_description,
    results: {
      positive: language.text.customer,
      negative: language.text.no_match
    },
    main_flow: [
      {
        id: 'beziehungsaufbau',
        name: language.text.welcome_and_establish_relation,
        description: language.text.welcome_and_establish_relation_description,
        type: 'buildRelationship',
        welcomeMessage: 'begrueßungsnachricht-kennenlernen',
        relationshipMessage: 'beziehungsnachricht-kennenlernen'
      },
      {
        id: 'meeting-pitch',
        name: language.text.meeting_pitch,
        description: language.text.meeting_pitch_description,
        type: 'message',
        templateID: 'meeting-pitch-kennenlernen'
      },
      {
        id: 'meeting-pitch-antwort',
        name: language.text.waiting_for_pitch_meeting,
        description: language.text.waiting_for_pitch_meeting_description,
        type: 'waitForMessageReceived'
      },
      {
        id: 'calendar-link',
        name: language.text.calendar_link,
        description: language.text.calendar_link_description,
        type: 'message',
        templateID: 'calendar-link-kennenlernen'
      },
      {
        id: 'meeting-accepted',
        name: language.text.meeting_accepted,
        description: language.text.meeting_accepted_description,
        type: 'question-date',
        question: language.text.when_is_the_meeting
      },
      {
        id: 'waiting-for-meeting',
        name: language.text.waiting_for_meeting,
        description: language.text.waiting_for_meeting_description,
        type: 'waitForDay'
      },
      {
        id: 'meeting-done',
        name: language.text.meeting_done,
        description: language.text.meeting_done_description,
        type: 'question-options',
        question: language.text.how_was_meeting_result,
        options: [
          {
            id: 1,
            text: language.text.strategy_call_scheduled,
            action: null,
            description: language.text.you_scheduled_a_strategy_call
          },
          {
            id: 2,
            text: language.text.no_match,
            action: 'result:negative',
            description: language.text.no_match_explanation
          },
          {
            id: 3,
            text: language.text.wrong_time,
            action: 'flow:wrong_time',
            description: language.text.wrong_time_explanation
          },
          {
            id: 4,
            text: `${language.text.canceled}/${language.text.did_not_show_up}`,
            action: 'flow:no_show',
            description: language.text.did_not_show_up_explanation
          }
        ]
      },
      {
        id: 'homework',
        name: language.text.thanks_for_the_meeting_and_homework,
        description:
          language.text.thanks_for_the_meeting_and_homework_description,
        type: 'message',
        templateID: 'homework-kennenlernen'
      },
      {
        id: 'strategy-scheduled',
        name: language.text.strategy_call_scheduled,
        description: language.text.prepare_for_strategy_meeting,
        type: 'question-date',
        question: language.text.when_is_the_strategy_meeting
      },
      {
        id: 'wait-for-strategy-meeting',
        name: language.text.waiting_for_strategy_meeting,
        description: language.text.waiting_for_strategy_meeting_description,
        type: 'waitForDay'
      },
      {
        id: 'strategy-done',
        name: language.text.strategy_meeting_done,
        description: language.text.strategy_meeting_done_description,
        type: 'question-options',
        question: language.text.how_was_meeting_result,
        options: [
          {
            id: 1,
            text: language.text.customer,
            action: 'result:positive',
            description: language.text.you_got_a_customer
          },
          {
            id: 2,
            text: language.text.no_match,
            action: 'result:negative',
            description: language.text.no_match_explanation
          },
          {
            id: 3,
            text: language.text.wrong_time,
            action: 'flow:wrong_time',
            description: language.text.wrong_time_explanation
          },
          {
            id: 4,
            text: `${language.text.canceled}/${language.text.did_not_show_up}`,
            action: 'flow:no_show',
            description: language.text.did_not_show_up_explanation
          }
        ]
      }
    ],
    secondary_flows: {
      no_show: [
        {
          id: 'no-show-message',
          name: language.text.messages_after_no_show,
          description: language.text.messages_after_no_show_description,
          type: 'message',
          templateID: 'no-show-kennenlernen'
        },
        {
          id: 'no-show-message-antwort',
          name: language.text.waiting_for_response,
          description:
            language.text.waiting_for_response_to_no_show_message_description,
          type: 'waitForMessageReceived'
        },
        {
          id: 'no-show-resolve-question',
          name: language.text.where_do_we_go_from_here,
          description: language.text.you_got_no_answer_what_now,
          type: 'question-options',
          question: language.text.where_do_we_go_from_here,
          options: [
            {
              id: 1,
              text: language.text.new_appointment,
              action: null,
              description: language.text.new_appointment_explanation
            },
            {
              id: 2,
              text: language.text.no_match,
              action: 'result:negative',
              description: language.text.no_match_explanation
            },
            {
              id: 3,
              text: language.text.wrong_time,
              action: 'flow:wrong_time',
              description: language.text.wrong_time_explanation
            }
          ]
        },
        {
          id: 'no-show-new-date',
          name: language.text.make_new_appointment,
          description: language.text.make_new_appointment_description,
          type: 'message',
          templateID: 'no-show-calendar-link-kennenlernen'
        },
        {
          id: 'meeting-accepted-no-show',
          name: language.text.meeting_accepted,
          description: language.text.meeting_accepted_description,
          type: 'question-date',
          question: language.text.when_is_the_meeting
        },
        {
          id: 'waiting-for-meeting-no-show',
          name: language.text.waiting_for_meeting,
          description: language.text.waiting_for_meeting_description,
          type: 'waitForDay'
        },
        {
          id: 'meeting-done-no-show',
          name: language.text.meeting_done,
          description: language.text.meeting_done_description,
          type: 'question-options',
          question: language.text.how_was_meeting_result,
          options: [
            {
              id: 1,
              text: language.text.strategy_call_scheduled,
              action: null,
              description: language.text.you_scheduled_a_strategy_call
            },
            {
              id: 2,
              text: language.text.no_match,
              action: 'result:negative',
              description: language.text.no_match_explanation
            },
            {
              id: 3,
              text: language.text.wrong_time,
              action: 'flow:wrong_time',
              description: language.text.wrong_time_explanation
            },
            {
              id: 4,
              text: `${language.text.canceled}/${language.text.did_not_show_up}`,
              action: 'flow:no_show',
              description: language.text.did_not_show_up_explanation
            }
          ]
        }
      ],
      wrong_time: [
        {
          id: 'wrong-time-message',
          name: language.text.message_after_wrong_time,
          description: language.text.message_after_wrong_time_description,
          type: 'message',
          templateID: 'wrong-time-kennenlernen'
        },
        {
          id: 'timeout-wrong-time1',
          name: language.text.time_to_retry,
          description: language.text.this_is_the_wrong_time,
          type: 'question-date',
          question: language.text.when_do_you_want_to_message_again
        },
        {
          id: 'wait-for-1-followup',
          name: language.text.waiting_for_first_followup,
          description: language.text.we_wait_for_first_followup,
          type: 'waitForDay'
        },
        {
          id: '1-followup',
          name: language.text.first_followup,
          description: language.text.first_followup_description,
          type: 'message',
          templateID: 'follow-up-kennenlernen'
        },
        {
          id: 'wait-for-1-followup-response',
          name: language.text.waiting_for_response,
          description: language.text.first_followup_sent_waiting_for_response,
          type: 'waitForMessageReceived'
        },
        {
          id: '1-followup-response',
          name: language.text.response_to_first_followup,
          description: language.text.we_got_a_response_to_first_followup,
          type: 'question-options',
          question: language.text.where_do_we_go_from_here,
          options: [
            {
              id: 1,
              text: language.text.right_time,
              action: 'exit-flow',
              description: language.text.the_time_is_right_we_continue
            },
            {
              id: 2,
              text: language.text.wrong_time,
              action: null,
              description: language.text.wrong_time_explanation
            },
            {
              id: 3,
              text: language.text.no_match,
              action: 'result:negative',
              description: language.text.no_match_explanation
            }
          ]
        },
        {
          id: 'timeout-wrong-time2',
          name: language.text.time_to_retry,
          description: language.text.this_is_the_wrong_time,
          type: 'question-date',
          question: language.text.when_do_you_want_to_message_again
        },
        {
          id: 'wait-for-2-followup',
          name: language.text.waiting_for_second_followup,
          description: language.text.we_wait_for_second_followup,
          type: 'waitForDay'
        },
        {
          id: '2-followup',
          name: language.text.second_follow_up,
          description: language.text.we_reached_second_follow_up,
          type: 'message',
          templateID: 'follow-up-kennenlernen'
        },
        {
          id: 'wait-for-2-followup-response',
          name: language.text.waiting_for_response,
          description: language.text.we_send_second_followup,
          type: 'waitForMessageReceived'
        },
        {
          id: '2-followup-response',
          name: language.text.response_to_second_followup,
          description: language.text.we_got_a_response_to_second_followup,
          type: 'question-options',
          question: language.text.where_do_we_go_from_here,
          options: [
            {
              id: 1,
              text: language.text.right_time,
              action: 'exit-flow',
              description: language.text.the_time_is_right_we_continue
            },
            {
              id: 2,
              text: language.text.wrong_time,
              action: null,
              description: language.text.wrong_time_explanation
            },
            {
              id: 3,
              text: language.text.no_match,
              action: 'result:negative',
              description: language.text.no_match_explanation
            }
          ]
        },
        {
          id: 'timeout-wrong-time3',
          name: language.text.time_to_retry,
          description: language.text.this_is_the_wrong_time,
          type: 'question-date',
          question: language.text.when_do_you_want_to_message_again
        },
        {
          id: 'wait-for-3-followup',
          name: language.text.waiting_for_third_followup,
          description: language.text.we_wait_for_third_followup,
          type: 'waitForDay'
        },
        {
          id: '3-followup',
          name: language.text.third_followup,
          description: language.text.we_reached_third_followup,
          type: 'message',
          templateID: 'follow-up-kennenlernen'
        },
        {
          id: 'wait-for-3-followup-response',
          name: language.text.waiting_for_response,
          description: language.text.waiting_for_third_followup_response,
          type: 'waitForMessageReceived'
        },
        {
          id: '3-followup-response',
          name: language.text.response_to_third_followup,
          description: language.text.we_tried_three_times_did_it_work,
          type: 'question-options',
          question: language.text.where_do_we_go_from_here,
          options: [
            {
              id: 1,
              text: language.text.right_time,
              action: null,
              description: language.text.the_time_is_right_we_continue
            },
            {
              id: 2,
              text: language.text.wrong_time,
              action: 'result:negative',
              description: language.text.wrong_time_explanation
            }
          ]
        }
      ]
    }
  } as PipelineDefinition<string[]>;
};

export function translateSteps(steps: PipelineStep[]): PipelineStep[] {
  const pipeline = getKennelernenPipeline();
  const langSteps = [
    ...pipeline.main_flow,
    ...Object.values(pipeline.secondary_flows)
  ] as PipelineStep[];

  return steps.map((step) => {
    const langStep = langSteps.find((s) => s.id === step.id);
    return {
      ...step,
      name: langStep ? langStep.name : step.name,
      description: langStep ? langStep.description : step.description
    };
  });
}
