import React from 'react';
import {
  Box,
  IconButton,
  InputBase,
  InputBaseProps,
  SxProps,
  Theme
} from '@mui/material';
import { Search } from '@mui/icons-material';

export type CollapsingInputProps = Omit<InputBaseProps, 'ref'> & {
  icon?: React.ReactNode;
  styles?: {
    open?: SxProps<Theme>;
    closed?: SxProps<Theme>;
  };
};

const CollapsingInput = React.forwardRef<
  HTMLInputElement,
  CollapsingInputProps
>(function CollapsingInput(props, ref) {
  const { icon, styles, ...rest } = props;
  const [focused, setFocused] = React.useState(false);
  const [value, setValue] = React.useState(props.value);

  React.useEffect(() => {
    if (props.value === value) return;
    setValue(props.value);
  }, [props.value]);

  const open = React.useMemo(() => {
    return focused || !!value;
  }, [focused, value]);

  const insideRef = React.useRef<HTMLInputElement | null>(null);

  return (
    <InputBase
      {...rest}
      startAdornment={
        <IconButton disabled={props.disabled}>{icon ?? <Search />}</IconButton>
      }
      ref={(ele: HTMLInputElement | null) => {
        if (!ele) insideRef.current = null;
        else if (ele.nodeName === 'INPUT') insideRef.current = ele;
        else insideRef.current = ele.querySelector('input');

        if (ref) {
          if (typeof ref === 'function') ref(ele);
          else ref.current = ele;
        }
      }}
      endAdornment={
        props.endAdornment && (
          <>
            <Box flexGrow={1} />
            <Box
              sx={{
                transition: (theme) => theme.transitions.create('transform'),
                ...(open
                  ? { width: 'auto', transform: 'translateX(0)' }
                  : { width: 0, transform: 'translateX(-100%)' })
              }}>
              {props.endAdornment}
            </Box>
          </>
        )
      }
      onFocus={(e) => {
        if (props.disabled) return;
        props.onFocus?.(e);
        setFocused(true);
      }}
      onBlur={(e) => {
        props.onBlur?.(e);
        setFocused(false);
      }}
      onChange={(e) => {
        props.onChange?.(e);
        setValue(e.target.value);
      }}
      onClick={(e) => {
        if (props.disabled) return;
        props.onClick?.(e);
        setFocused(true);
        insideRef.current?.focus();
      }}
      sx={
        {
          'background': (theme) => theme.palette.background.paper,
          'borderRadius': (theme) => theme.shape.borderRadius,
          'overflow': 'hidden',
          '.MuiInputBase-input': {
            transition: (theme) => theme.transitions.create('width'),
            width: open ? '13rem' : '0px'
          },
          ...props.sx,
          ...(styles?.[open ? 'open' : 'closed'] as SxProps<Theme>)
        } as SxProps<Theme>
      }
    />
  );
});

export default CollapsingInput;
