import React from 'react';
import { SnackbarContent, CustomContentProps } from 'notistack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { Contact } from '@/data/Classes/Contact';
import { language } from '@/index';
import { CSProfileData } from 'webview-preload';
import ContactAvatar from '../Avatar/ContactAvatar';

export type AudienceHolderSnackBarProps = {
  audienceHolder?:
    | (Pick<Contact, 'firstname' | 'lastname' | 'name'> &
        Partial<Pick<Contact, 'getProfilePictureURL' | 'pictures'>>)
    | CSProfileData;
  success: boolean;
  type: 'deleted' | 'added';
};

const AudienceHolderSnackBar = React.forwardRef<
  HTMLDivElement,
  AudienceHolderSnackBarProps & CustomContentProps
>(({ id, audienceHolder, success, type, ...props }, ref) => {
  const profile = React.useMemo(() => {
    if (!audienceHolder)
      return {
        name: ''
      };
    if ('firstName' in audienceHolder) {
      return {
        name:
          (audienceHolder.firstName ?? '') +
          ' ' +
          (audienceHolder.lastName ?? ''),
        pictures: { 800: audienceHolder.profilePicture }
      };
    } else {
      return audienceHolder;
    }
  }, [audienceHolder]);

  return (
    <SnackbarContent ref={ref} style={props.style} className={props.className}>
      <Card
        sx={{
          width: '100%',
          background: (theme) =>
            success ? theme.palette.secondary.main : theme.palette.error.main,
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          p: 1
        }}>
        {('getProfilePictureURL' in profile || 'pictures' in profile) && (
          <ContactAvatar contact={profile as Contact} />
        )}
        <Typography
          variant="body1"
          color={
            success ? undefined : (theme) => theme.palette.error.contrastText
          }>
          {props.message ??
            (success
              ? type === 'added'
                ? language.text.audience_holder_saved(profile.name)
                : language.text.audience_holder_removed(profile.name)
              : language.text.audience_holder_not_saved)}
        </Typography>
      </Card>
    </SnackbarContent>
  );
});

AudienceHolderSnackBar.displayName = 'AudienceHolder';

export default AudienceHolderSnackBar;
