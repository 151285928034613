import SetupActions from '@/data/DataServer/Setup';
import { handleRequestInterceptorData } from '../LinkedInEventHandlers/handleRequestInterceptorData.renderer';
import MessageBus from '@common/MessageBus/MessageBus.renderer';
import { ApiEvent, Event } from 'linkedin-domain-types';
import { handleRealtimeEvents } from '../LinkedInEventHandlers/handleRealtimeEvents.renderer';
import { contacts, loading } from '@digital-sun-solutions/cloud-functions';
import Auth from '@common/AuthManager/Auth.renderer';
import apiRetry from '@common/FetchRetry/FetchRetry.renderer';
import { navigate } from '@/Routes/MainRouter';
import { APP_PATHS } from '@/globals/Paths';
import log from 'electron-log';
import AbstractLinkedInLoadingEngine from './LinkedInLoadingEngines.renderer/AbstractLinkedInLoadingEngine';
import WebviewLoadingEngine from './LinkedInLoadingEngines.renderer/WebviewLoadingEngine';
import Logger from 'electron-log';
import * as Sentry from '@sentry/electron/renderer';

export type WebviewLoadFailedResponse = 'retry';
export type LoaderType =
  | 'webview'
  /**
   * @deprecated
   * Use `webview` instead
   */
  | 'api';

export class LinkedInLoadingManagerClass {
  private SETUP_KEY = 'linkedin-loading-manager-setup' as const;
  private LOAD_TYPE_LOCAL_STORAGE_KEY =
    'linkedin-loading-manager-load-type' as const;
  constructor() {
    handleRequestInterceptorData();

    MessageBus.getInstance().on('profile-categorized', (payload) => {
      this.interestingProfiles.push({
        profileID: payload.profileID,
        conversationID: payload.conversationID
      });
    });

    MessageBus.getInstance().on('conversation-created', (payload) => {
      const existing = this.interestingProfiles.find(
        (p) => p.profileID === payload.profileID
      );
      if (existing) existing.conversationID = payload.conversationID;
      else
        this.interestingProfiles.push({
          profileID: payload.profileID,
          conversationID: payload.conversationID
        });
    });
  }

  private initializing = false;
  private _resolveInit: () => void = () => undefined;
  private _waitForInit = new Promise<void>((res) => {
    this._resolveInit = res;
  });
  public async waitForInit() {
    return this._waitForInit;
  }
  private interestingProfiles: {
    profileID: string;
    publicIdentifier?: string;
    conversationID?: string;
  }[] = [];
  public async init() {
    if (this.initializing) return this._waitForInit;
    this.initializing = true;
    await this._init();
    this._resolveInit();
  }
  private async _init() {
    try {
      const ids = await apiRetry(() =>
        Auth.execRoute((token) => contacts.listIDs({}, { token }))
      );
      this.interestingProfiles = ids;
    } catch (e) {
      console.error('Could not load interesting profiles', e);
      Sentry.captureException(e);
      navigate(
        `${APP_PATHS.Error}/error-loading-interesting-profiles-from-server`
      );
    }
  }

  public setLoadingType(type: LoaderType) {
    localStorage.setItem(this.LOAD_TYPE_LOCAL_STORAGE_KEY, type);
  }
  public getLoadingType(): LoaderType {
    // Currently we only have one engine that we want to use
    return 'webview';
  }

  private loadFinished = false;
  private _loading = false;
  private _loadingPromise: Promise<void> | null = null;
  public async load() {
    // when ids are not loaded until we start, we potentially load the entire network instead of just the interesting profiles
    await this.waitForInit();
    if (this._loading) return this._loadingPromise;
    let resolve: () => void = () => undefined;
    this._loadingPromise = new Promise<void>((res) => (resolve = res));
    try {
      this._loading = true;
      const isFirst = await this.isFirstLoad();
      await this.executeLoad(isFirst ? 'first' : 'startup');
    } catch (e) {
      log.error('LinkedInLoadingManager: Could not load', e);
      navigate(`${APP_PATHS.Error}/error-linkedin-loading`);
      throw e;
    } finally {
      this.loadFinished = true;
      this._loadingPromise = null;
      this._loading = false;
      resolve();
    }
  }
  public isLoading() {
    return this._loading;
  }
  public async waitForLoad() {
    if (!this.loadFinished) return await this.load();
    if (!this._loadingPromise) return Promise.resolve();
    await this._loadingPromise;
  }

  private onWebviewLoadFailed: () => Promise<WebviewLoadFailedResponse> = () =>
    Promise.resolve('retry');
  public setWebviewLoadFailedHandler(
    handler: () => Promise<WebviewLoadFailedResponse>
  ) {
    this.onWebviewLoadFailed = handler;
  }

  private async isFirstLoad() {
    const setupSteps = await SetupActions.getSetupSteps();
    return !setupSteps.includes(this.SETUP_KEY);
  }

  private getLoader(
    type = this.getLoadingType()
  ): AbstractLinkedInLoadingEngine {
    if (type === 'webview') return new WebviewLoadingEngine(this);
    throw new Error(`Unknown loader type: ${type}`);
  }

  /**
   * This function executes the first load and the startup load
   * - The first load should only be done ONCE (on very first login of a user)
   * - The startup load should be done every time the user opens the app. But NOT on very first login of a user
   */
  private async executeLoad(
    type: 'first' | 'startup',
    loader: AbstractLinkedInLoadingEngine = this.getLoader()
  ) {
    MessageBus.getInstance().emit('load:type', { type });
    try {
      if (type === 'first') {
        await loader.firstLoad();
        await SetupActions.setSetupStep(this.SETUP_KEY, true);
      } else {
        await loader.startupLoad();
      }
    } catch (e) {
      Logger.error('LinkedInLoadingManager: Could not load', e);
      if (loader instanceof WebviewLoadingEngine) {
        const response = await this.onWebviewLoadFailed();
        await this.executeLoad(type);
      } else {
        throw e;
      }
    }
  }

  private csvDelimiter = ';';
  public async shouldSaveDataForProfileIDs(ids: string[]) {
    if (ids.length === 0) return [];
    const res = await Auth.execRoute((token) =>
      loading.shouldSaveDataForProfileIDs(
        {
          csv: ids.join(this.csvDelimiter)
        },
        { token }
      )
    );
    if (res.code !== 200)
      throw new Error(
        `Could not check if profile IDs are known: ${res.code} | ${res.data}`
      );
    return res.data;
  }

  public async shouldSaveDataForConversationIds(conversationIDs: string[]) {
    if (conversationIDs.length === 0) return [];
    const res = await Auth.execRoute((token) =>
      loading.shouldSaveDataForConversationIds(
        {
          csv: conversationIDs.join(this.csvDelimiter)
        },
        { token }
      )
    );
    if (res.code !== 200)
      throw new Error(
        `Could not check if should save data for conversation IDs: ${res.code} | ${res.data}`
      );
    return res.data;
  }

  public async shouldSaveMessagesForProfileIDs(profileIDs: string[]) {
    if (profileIDs.length === 0) return {};
    const res = await Auth.execRoute((token) =>
      loading.shouldSaveMessagesForProfileIDs(
        {
          csv: profileIDs.join(this.csvDelimiter)
        },
        { token }
      )
    );
    if (res.code !== 200)
      throw new Error(
        `Could not check if should save data for profile IDs: ${res.code} | ${res.data}`
      );
    return res.data;
  }

  public async areNamesKnown(names: string[]) {
    if (names.length === 0) return [];
    const res = await Auth.execRoute((token) =>
      loading.isNameKnown(
        {
          csv: names.join(this.csvDelimiter)
        },
        { token }
      )
    );
    if (res.code !== 200)
      throw new Error(
        `Could not check if names are known: ${res.code} | ${res.data}`
      );
    return res.data;
  }

  public async getConversationIDsFromNames(
    names: string[]
  ): Promise<{ [name: string]: string[] | undefined }> {
    if (names.length === 0) return {};
    const res = await Auth.execRoute((token) =>
      loading.getConversationIDsFromNames(
        {
          csv: names.join(this.csvDelimiter)
        },
        { token }
      )
    );
    if (res.code !== 200)
      throw new Error(
        `Could not check conversation IDs: ${res.code} | ${res.data}`
      );
    return res.data;
  }

  public handleRealtimeEvent(event: ApiEvent<Event>) {
    handleRealtimeEvents(event);
  }
}

const LinkedInLoadingManager = new LinkedInLoadingManagerClass();
export default LinkedInLoadingManager;
