import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { DelayedSkeleton, OptionalTooltip } from 'ui-utils';

export interface SettingsSubheaderProps {
  loading?: boolean;
  routes: {
    name: string | (() => string);
    path: string;
    icon?: React.FC;
    disabledTooltip?: string | (() => string);
    disabled?: boolean;
  }[];
}

export default function SettingsSubheader(props: SettingsSubheaderProps) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
      {props.loading
        ? (props.routes.length ? props.routes : Array.from({ length: 4 })).map(
            (_, i) => (
              <Box key={i}>
                <DelayedSkeleton variant="circular" />
                <DelayedSkeleton variant="text" />
              </Box>
            )
          )
        : props.routes.map((route) => {
            const active = pathname.includes(route.path);
            return (
              <OptionalTooltip
                title={
                  route.disabled && route.disabledTooltip ? (
                    <Typography>
                      {typeof route.disabledTooltip === 'string'
                        ? route.disabledTooltip
                        : route.disabledTooltip()}
                    </Typography>
                  ) : undefined
                }
                key={route.path}>
                <Box flex="1 1 0">
                  <Button
                    fullWidth
                    onClick={() => navigate(route.path)}
                    variant={active ? 'contained' : 'outlined'}
                    color="primary"
                    disabled={route.disabled}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      transition: (theme) =>
                        theme.transitions.create(['transform', 'box-shadow']),
                      ...(active
                        ? {
                            transform: (theme) =>
                              `translateY(-${theme.spacing(2)})`,
                            boxShadow: (theme) => theme.shadows[4]
                          }
                        : !route.disabled && {
                            '&:hover': {
                              transform: (theme) =>
                                `translateY(-${theme.spacing(1)})`,
                              boxShadow: (theme) => theme.shadows[1]
                            }
                          })
                    }}>
                    <Box>{route.icon && <route.icon />}</Box>
                    <Typography>
                      {typeof route.name === 'string'
                        ? route.name
                        : route.name()}
                    </Typography>
                  </Button>
                </Box>
              </OptionalTooltip>
            );
          })}
    </Box>
  );
}
