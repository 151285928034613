import Auth from '@common/AuthManager/Auth.renderer';
import { statistics } from '@digital-sun-solutions/cloud-functions';
import { createQueryHook } from './QueryHelper';
import { StatTypes } from '@common/types/ApiTypes';
import queryClient from '@/other/QueryClient';
import MainConfig from '@common/config/MainConfig';
import { Objects } from '@idot-digital/generic-helpers';

async function getToday() {
  const res = await Auth.execRoute((token) => statistics.today({}, { token }));
  if (res.code !== 200) {
    throw new Error('Failed to get today stats');
  }

  for (const key of Objects.keys(res.data)) {
    if (!res.data[key]) continue;
    res.data[key].goal =
      res.data[key].goal ?? MainConfig.defaultStatGoals[key] ?? 0;
  }

  return res.data;
}
getToday.getQueryKey = () => ['stats', 'today'];

const useToday = createQueryHook(getToday, 'today', { refetchOnMount: true });

async function getLastFocusModeRun() {
  const res = await Auth.execRoute((token) =>
    statistics.focusModeRun({}, { token })
  );
  if (res.code !== 200) {
    throw new Error('Failed to get last focus mode stats');
  }

  for (const key of Objects.keys(res.data)) {
    if (!res.data[key]) continue;
    res.data[key].goal =
      res.data[key].goal ?? MainConfig.defaultStatGoals[key] ?? 0;
  }

  return res.data;
}
getLastFocusModeRun.getQueryKey = () => ['stats', 'focus'];

const useLastFocusModeRun = createQueryHook(
  getLastFocusModeRun,
  'focusModeStats',
  { refetchOnMount: true }
);

export type HistoricStatsFilter = Parameters<typeof statistics.historic>[0];

async function getHistoric(filter: HistoricStatsFilter) {
  const res = await Auth.execRoute((token) =>
    statistics.historic(filter, { token })
  );
  if (res.code !== 200) {
    throw new Error('Failed to get historic stats');
  }
  return res.data.map((d) => ({ ...d, timestamp: new Date(d.timestamp) }));
}
getHistoric.getQueryKey = (filter?: HistoricStatsFilter | null) => [
  'stats',
  'historic',
  ...(filter ? [JSON.stringify(filter)] : [])
];

const useHistoric = createQueryHook(getHistoric, 'historic', {
  refetchOnMount: true
});

async function setGoal(name: StatTypes, value: number) {
  // optimistic update
  queryClient.setQueryData(
    getToday.getQueryKey(),
    (old: Awaited<ReturnType<typeof getToday>> | null | undefined) => {
      if (!old) return null;
      return {
        ...old,
        [name]: {
          ...old[name],
          goal: value
        }
      };
    }
  );
  const res = await Auth.execRoute((token) =>
    statistics.setGoal({ name, value }, { token })
  );
  if (res.code !== 200) {
    throw new Error('Failed to set goal');
  }
  await queryClient.invalidateQueries(getToday.getQueryKey());
}

const StatsActions = {
  getToday,
  useToday,
  getLastFocusModeRun,
  useLastFocusModeRun,

  getHistoric,
  useHistoric,

  setGoal
};

export default StatsActions;
