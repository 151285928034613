import { Box, Button, Typography } from '@mui/material';
import { Background, Logo } from 'ui-utils';
import { language } from '@/index';
import MainConfig from '@common/config/MainConfig';

export default function UpdateRequired() {
  return (
    <Background
      variant="frame"
      sx={{
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        background: (theme) => theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Box p={2} width="min(25rem, 80vw)">
        <Logo
          variant="blue_text"
          sx={{
            height: '100%',
            width: '100%'
          }}
        />
      </Box>
      <Box p={2} maxWidth={800}>
        <Typography variant="h4" textAlign="center">
          {language.text.new_version_available}
        </Typography>
        <Typography textAlign="center">
          {language.text.update_required_explanation}
        </Typography>
        <Box pt={2} display="flex" gap={2} width="100%" justifyContent="center">
          <Button
            variant="contained"
            size="large"
            onClick={() => window.api.send('app:quit')}>
            {language.text.quit_ssc}
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() =>
              window.api.send('url:open_ext', MainConfig.sscDownloadLink)
            }>
            {language.text.download_new_version}
          </Button>
        </Box>
      </Box>
    </Background>
  );
}
