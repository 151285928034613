import { SSCChat } from '@/data/Classes/Chat/SSCChat';
import { Contact } from '@/data/Classes/Contact';
import { language } from '@/index';
import {
  OptionQuestionOption,
  PipelineStep,
  Question
} from '@common/PipelineManager/PipelineTypes';
import { PipelineEvent } from '@common/types/ApiTypes';
import PrintableError from '@common/PrintableError/PrintableError';
import { Pipelines } from '@common/PipelineManager/Pipelines';
import { Task, TaskConfig, TaskHeader } from 'focus-mode-scheduler';
import { TaskIncomingEventMap } from 'focus-mode-scheduler/src/Task/Task';

export interface QuestionTaskIncomingEventMap {
  'skip-until-date':
    | { date: Date; type: 'complete' }
    | { type: 'close' }
    | { type: 'skip' };
}
export interface QuestionTaskOutgoingEventMap {
  'get-skip-until-date': undefined;
}

export default class QuestionTask extends Task<
  QuestionTaskIncomingEventMap,
  QuestionTaskOutgoingEventMap
> {
  public static get config(): TaskConfig {
    return {
      id: 'question',
      info: {
        title: language.text.relationship_questions,
        description: language.text.relationship_questions_description,
        avaContent: language.text.ava_hint_focus_question,
        hideNoMoreInstancesInfo: true,
        hideNoInstancesInfo: true
      },
      hide: false,
      constraintBreachedDialogContent:
        Task.DEFAULT_CONTRAINT_BREACHED_DIALOG_TEXTS
    };
  }

  protected _header: TaskHeader | null = null;

  constructor(
    public readonly contact: Contact,
    public readonly chat: SSCChat,
    public readonly question: Question,
    public readonly pipelineHistory: PipelineEvent[],
    public readonly pipelineSteps: PipelineStep[],
    public readonly activeStep: PipelineStep | null
  ) {
    super();

    this._header = {
      title: question.question,
      skip: {
        instance: {
          enable: true,
          text: language.text.skip_question,
          // when skipping via header we automatically open the skip until so we don't need another confirmation
          omitConfirmationDialog: true
        },
        category: {
          // when skipping via header we automatically open the skip until so we don't need another confirmation
          omitConfirmationDialog: true
        }
      }
    };
  }

  protected answer: OptionQuestionOption<string[]> | Date | null = null;
  public setAnswer(answer: OptionQuestionOption<string[]> | Date) {
    this.answer = answer;
  }

  protected async commitAnswer() {
    if (!this.answer) return;

    const historyLevels = Pipelines.filterLevelsFromHistory(
      this.pipelineHistory.map((e) => e.currentStep)
    );

    if (
      this.question.type === 'question-options' &&
      !(this.answer instanceof Date)
    ) {
      if (this.answer.action) {
        const result = Pipelines.executeAction(
          historyLevels,
          this.answer.action
        );
        if (result) {
          if (result.done) await this.contact.completePipeline(result.result);
          else {
            await this.contact.setPipelineStep(result.step);
            result.current_steps;
          }
        }
      } else {
        await this.contact.gotoNextPipelineStep(historyLevels);
      }
    } else if (
      this.answer instanceof Date &&
      this.question.type === 'question-date'
    ) {
      this.answer.setHours(23, 59, 59);
      await this.contact.skipUntil(this.answer);
      await this.contact.gotoNextPipelineStep(historyLevels);
    }
  }

  private shouldSkipSkipUntil = false;
  public omitSkipUntil() {
    this.shouldSkipSkipUntil = true;
  }

  protected async finishSkipUntil(date: Date) {
    await this.contact.skipUntil(date);
    this.internalEmit('finished', undefined);
  }

  protected async handleEvent<
    Type extends keyof (TaskIncomingEventMap & QuestionTaskIncomingEventMap)
  >(
    type: Type,
    data: (TaskIncomingEventMap & QuestionTaskIncomingEventMap)[Type]
  ) {
    switch (type) {
      case 'skip-until-date':
        const date = data as QuestionTaskIncomingEventMap['skip-until-date'];
        if (date.type === 'complete') await this.finishSkipUntil(date.date);
        else if (date.type === 'close')
          this.internalEmit('finish-aborted', undefined);
        else if (date.type === 'skip') this.internalEmit('finished', undefined);
        break;
      case 'skip':
        if (this.shouldSkipSkipUntil) this.internalEmit('finished', undefined);
        else this.internalEmit('get-skip-until-date', undefined);
        break;
      case 'complete':
        await this.commitAnswer();
        this.internalEmit('finished', undefined);
        break;
    }
  }
}
