import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem
} from '@mui/material';
import React from 'react';
import { language } from '@/index';
import { ArrowDropDown, PersonOff, SkipNext } from '@mui/icons-material';
import { FancyButton, FocusIcon } from 'ui-utils';
import { useScheduler } from 'focus-mode-scheduler';

export interface FocusModeConstraintDisplayProps {
  onLeave: () => void;
}

const TIME_COLORING_ZONES = [
  {
    color: 'error',
    start: 0.9
  },
  {
    color: 'warning',
    start: 0.75
  },
  {
    color: 'neutral',
    start: 0
  }
] as const;

interface DialogContent {
  header: string;
  body: React.ReactNode;
  cancelText?: string;
  confirmText?: string;
}

export default function FocusModeConstraintDisplay(
  props: FocusModeConstraintDisplayProps
) {
  const { constraints, header, skip } = useScheduler();
  const constraintDropdownRef = React.useRef<HTMLButtonElement>(null);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const CONFIRM_SKIP_CATEGORY: DialogContent = {
    header: language.text.skip_step,
    body: language.text.confirm_skip_step,
    cancelText: language.text.cancel,
    confirmText: language.text.skip_step
  };
  const CONFIRM_SKIP: DialogContent = {
    header: language.text.skip_task,
    body: language.text.confirm_skip_task,
    cancelText: language.text.cancel,
    confirmText: language.text.skip_task
  };

  const constraint = constraints.useCurrentConstraint();

  const [showDialog, setShowDialog] = React.useState(false);
  // stores current dialog content to be displayed
  // no state needed since opening and closing of dialog (showDialog) rerenders anyways
  const dialogRef = React.useRef(CONFIRM_SKIP_CATEGORY);

  const confirmFct = React.useRef<() => void | Promise<void>>(async () => {});

  const [timeKey, resetTimeKey] = React.useReducer(() => ({}), {});

  React.useEffect(() => {
    if (constraint?.type !== 'time') return;
    const interval = setInterval(resetTimeKey, 1000);
    return () => clearInterval(interval);
  }, [constraint]);

  const constraintString = React.useMemo(() => {
    if (!constraint) return '';
    switch (constraint.type) {
      case 'items':
        return `${constraint.itemsDone}/${constraint.items}`;
      case 'time':
        const remaining =
          constraint.time * 60 * 1000 -
          (Date.now() - constraint.start.getTime());
        let minutes = Math.floor(Math.abs(remaining / 1000 / 60));
        const seconds = Math.abs(Math.floor(remaining / 1000) % 60);
        if (remaining < 0) return '0:00';
        return `${remaining < 0 ? '-' : ''}${minutes}:${seconds.toString().padStart(2, '0')}`;
      default:
        return '';
    }
  }, [constraint, timeKey]);

  const buttonColor = React.useMemo(() => {
    if (constraint?.type !== 'time') return 'neutral';
    const totalTime = constraint.time * 60 * 1000;
    const passedTime = Date.now() - constraint.start.getTime();
    const passed = passedTime / totalTime;
    return (
      TIME_COLORING_ZONES.find((zone) => zone.start <= passed)?.color ??
      'neutral'
    );
  }, [constraint, timeKey]);

  return (
    <>
      <Button
        variant="contained"
        color={buttonColor}
        ref={constraintDropdownRef}
        onClick={() => setMenuOpen(true)}
        startIcon={<ArrowDropDown sx={{ visibility: 'hidden' }} />}
        endIcon={<ArrowDropDown />}>
        <span style={{ minWidth: '4rem' }}>
          {constraint ? constraintString : language.text.skip}
        </span>
      </Button>
      <Menu
        open={menuOpen}
        onClose={setMenuOpen.bind(null, false)}
        anchorEl={constraintDropdownRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        {/* skip person */}
        {header?.skip?.instance?.enable && (
          <MenuItem
            onClick={() => {
              if (header.skip?.instance?.omitConfirmationDialog) {
                skip('instance');
              } else {
                dialogRef.current = CONFIRM_SKIP;
                confirmFct.current = skip.bind(null, 'instance');
                setShowDialog(true);
              }
              setMenuOpen(false);
            }}>
            <ListItemIcon>
              <PersonOff fontSize="small" />
            </ListItemIcon>
            {header.skip?.instance.text ?? language.text.skip_person}
          </MenuItem>
        )}
        {/* skip category */}
        {!header?.skip?.category?.disable && (
          <MenuItem
            onClick={() => {
              if (header?.skip?.category?.omitConfirmationDialog) {
                skip('category');
              } else {
                dialogRef.current = CONFIRM_SKIP_CATEGORY;
                confirmFct.current = skip.bind(null, 'category');
                setShowDialog(true);
              }
              setMenuOpen(false);
            }}>
            <ListItemIcon>
              <SkipNext fontSize="small" />
            </ListItemIcon>
            {header?.skip?.category?.text ?? language.text.skip_task}
          </MenuItem>
        )}
        <Divider />
        {/* back to manual mode */}
        <MenuItem onClick={props.onLeave}>
          <ListItemIcon>
            <FocusIcon fontSize="small" />
          </ListItemIcon>
          {language.text.leave_focus_mode}
        </MenuItem>
      </Menu>

      <Dialog open={showDialog}>
        <DialogTitle>{dialogRef.current.header}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogRef.current.body}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setShowDialog(false)}>
            {dialogRef.current.cancelText ?? language.text.cancel}
          </Button>
          <FancyButton
            fType={{ promise: true }}
            variant="contained"
            onClick={async () => {
              await confirmFct.current();
              setShowDialog(false);
            }}>
            {dialogRef.current.confirmText ?? language.text.skip_step}
          </FancyButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
