import React from 'react';
import ChatTask from './ChatTask';
import ContactChat from '../_general/ContactView/ContactChat';
import ContactView from '../_general/ContactView/ContactView';
import { Box } from '@mui/material';
import CategorySelector from '../_general/ContactView/CategorySelector';
import RelationshipMenu from '../_general/ContactView/RelationshipMenu';
import HUDSidePanel from '@/components/HUD/HUDSidePanel';
import ContactDisplay from '../_general/ContactView/ContactDisplay';
import LinkedInWebview from '@common/Webview.renderer/Base/LinkedInWebview';
import { language } from '@/index';
import { ContactType } from '@common/types/enums';
import { Contact } from '@/data/Classes/Contact';
import { useEventEmitter } from 'ui-utils';
import { useScheduler } from 'focus-mode-scheduler';
import SkipUntilDialog from '../_general/SkipUntilDialog/SkipUntilDialog';

export interface ChatTaskUIProps {
  task: ChatTask;
}

export default function ChatTaskUI(props: ChatTaskUIProps) {
  const { task } = props;
  const { skip, complete } = useScheduler();

  /*----------- Current open UI -------------*/
  // use pseudo routes instead of actual routing to keep chat loaded to not load again and not loose state of textfield and notes
  const [page, setPage] = React.useState<'chat' | 'profile'>('chat');
  const [sideView, setSideView] = React.useState<
    'categorize' | 'pipeline_steps'
  >('pipeline_steps');

  const [showDatePicker, setShowDatePicker] = React.useState(false);
  useEventEmitter(task, {
    'get-skip-until-date': () => setShowDatePicker(true)
  });

  return (
    <>
      <ContactView
        contact={task.contact}
        page={page}
        pages={[
          {
            id: 'chat',
            component: (
              <ContactChat
                fillTemplateFromChat
                chat={task.chat}
                onSend={(message) => {
                  task.setCurrentMessage(message);
                  return complete({ itemsDone: 1 });
                }}
                currentStep={task.activeStep}
                hide={page !== 'chat'}
                templates={task.templates}
              />
            ),
            keepMounted: true
          },
          {
            keepMounted: true,
            id: 'profile',
            component: (
              <LinkedInWebview
                startURL={task.contact.profileURL}
                features={['focus-mode-style', 'hide-connect-button']}
              />
            )
          }
        ]}
        sx={{
          boxSizing: 'border-box',
          /* add spacing add bottom to make space between bottom bar buttons and textarea */
          pb: 2,
          // extra spacing due to sidebar
          mr: 18,
          width: 'unset'
        }}
      />

      <HUDSidePanel>
        <Box flex={1} />
        <ContactDisplay
          contact={task.chat.contact}
          onClick={() =>
            setPage((page) => (page === 'profile' ? 'chat' : 'profile'))
          }
        />
        {sideView === 'categorize' && (
          <CategorySelector
            defaultValue={ContactType.POTENTIAL_CUSTOMER}
            onFinished={async (result) => {
              if (result === ContactType.POTENTIAL_CUSTOMER)
                setSideView('pipeline_steps');
              else {
                await Contact.categorize(task.contact, result);
                task.omitSkipUntil();
                await skip('instance');
              }
            }}
          />
        )}
        {sideView === 'pipeline_steps' && (
          <RelationshipMenu
            goBackToCategorySelector={() => setSideView('categorize')}
            steps={task.steps}
            currentStep={task.activeStep}
            completeText={language.text.recategorize}
            onComplete={async (pipeline_step) => {
              await Contact.categorize(
                task.contact,
                ContactType.POTENTIAL_CUSTOMER,
                pipeline_step
              );
              task.omitSkipUntil();
              return skip('instance');
              // TODO: directly execute new message step
            }}
          />
        )}
        <Box flex={4} />
      </HUDSidePanel>

      <SkipUntilDialog
        onFinish={(date) =>
          task.emit('skip-until-date', { date, type: 'complete' })
        }
        contact={task.contact}
        open={showDatePicker}
        onClose={() => {
          setShowDatePicker(false);
          task.emit('skip-until-date', {
            type: 'close'
          });
        }}
        onSkip={task.emit.bind(task, 'skip-until-date', { type: 'skip' })}
      />
    </>
  );
}
