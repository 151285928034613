import { language } from '@/index';
import LinkedInLoad from '@/components/LinkedInLoad/LinkedInLoad';

export default function NetworkLoaderRun() {
  return (
    <LinkedInLoad
      hideProgress
      actions={[
        {
          action: 'open-network'
        },
        {
          action: 'scroll-entire-network',
          actionData: {
            scrollAll: true
          }
        }
      ]}
      texts={{
        title: language.text.load_entire_network,
        confirm_leave: language.text.confirm_leave_load_entire_network,
        finished: language.text.load_network_finished,
        error_title: language.text.error_while_loading_network,
        error_description: language.text.error_while_loading_network_description
      }}
    />
  );
}
