import type LinkedIn from 'linkedin-api';

export const LINKEDIN_FUNCTION_KEYS = [
  'checkLogin',
  'addListener',
  'closeRealtimeConnection',
  'getArchivedConversations',
  'getConversation',
  'getFullProfile',
  'getOwnProfile',
  'getProfile',
  'isConnection',
  'listArchivedConversations',
  'listConversations',
  'listInvites',
  'listMessages',
  'listNetworkParticipants',
  'listProfileVisitors',
  'listSearchConversations',
  'logout',
  'markAsRead',
  'openRealtimeConnection',
  'sendConnectionRequest',
  'sendDirectMessage',
  'sendMessage',
  'setArchived',
  'setReaction'
] satisfies (keyof LinkedIn)[];

export type LinkedInFunctions = (typeof LINKEDIN_FUNCTION_KEYS)[number];
