import {
  LinkedInMemberDistance,
  LinkedInTimeRange,
  TrackingId
} from './Common';
import {
  CardUrn,
  ConnectionsInCommonUrn,
  MiniCompanyUrn,
  MiniProfileUrn,
  ProfileViewerUrn,
  WvmpCardUrn,
  WvmpUrn
} from './Urns';
import { LinkedInMiniProfile } from './Identity';
import { LinkedInMiniCompany } from './Entities';
import { LinkedInAttributedText } from './LinkedInAttributedText';

export const IDENTITY_ME_PREFIX = 'com.linkedin.voyager.identity.me';

export const CARD_TYPE = `${IDENTITY_ME_PREFIX}.Card` as const;

export interface LinkedInCard {
  $type: typeof CARD_TYPE;
  objectUrn: ProfileViewerUrn;
  entityUrn: CardUrn;
  value: LinkedInWvmpProfileViewCard;
  trackingId: TrackingId;
  setting: unknown;
}

export const WVMP_PROFILE_VIEW_CARD_TYPE =
  `${IDENTITY_ME_PREFIX}.WvmpProfileViewCard` as const;

export interface LinkedInWvmpProfileViewCard {
  $type: typeof WVMP_PROFILE_VIEW_CARD_TYPE;
  viewer: LinkedInFullProfileViewer;
  insight: LinkedInInsight;
  pendingInvitee: boolean;
  viewedAt: number;
}

export const FULL_PROFILE_VIEWER_TYPE =
  `${IDENTITY_ME_PREFIX}.FullProfileViewer` as const;

export interface LinkedInFullProfileViewer {
  $type: typeof FULL_PROFILE_VIEWER_TYPE;
  profile: ActorMiniProfile;
}

export const ACTOR_MINI_PROFILE_TYPE =
  `${IDENTITY_ME_PREFIX}.ActorMiniProfile` as const;

export interface ActorMiniProfile {
  '$type': typeof ACTOR_MINI_PROFILE_TYPE;
  'distance': LinkedInMemberDistance;
  '*miniProfile': MiniProfileUrn;
  'miniProfile'?: LinkedInMiniProfile;
}

export const INSIGHT_TYPE = `${IDENTITY_ME_PREFIX}.Insight` as const;

export interface LinkedInInsight {
  $type: typeof INSIGHT_TYPE;
  objectUrn: ConnectionsInCommonUrn;
  value: LinkedInConnectionsInCommonInsight;
  trackingId: TrackingId;
}

export const CONNECTIONS_IN_COMMON_INSIGHT_TYPE =
  `${IDENTITY_ME_PREFIX}.ConnectionsInCommonInsight` as const;

export interface LinkedInConnectionsInCommonInsight {
  '$type': typeof CONNECTIONS_IN_COMMON_INSIGHT_TYPE;
  '*connectionsInCommon': MiniProfileUrn[];
  'connectionsInCommon'?: LinkedInMiniProfile[];
  'numConnectionsInCommon': number;
  '*miniProfile': MiniProfileUrn;
  'miniProfile'?: LinkedInMiniProfile;
}

export const WVMP_CARD_TYPE =
  `${IDENTITY_ME_PREFIX}.wvmpOverview.WvmpCard` as const;

export interface LinkedInWvmpCard {
  $type: typeof WVMP_CARD_TYPE;
  value: LinkedInWvmpViewersCard;
  entityUrn: WvmpCardUrn;
  trackingId: TrackingId;
}

export const WVMP_VIEWERS_CARD_TYPE =
  `${IDENTITY_ME_PREFIX}.wvmpOverview.WvmpViewersCard` as const;

export interface LinkedInWvmpViewersCard {
  $type: typeof WVMP_VIEWERS_CARD_TYPE;
  insightsCards: LinkedInWvmpInsightsCard[];
}

export const WVMP_INSIGHTS_CARD_TYPE =
  `${IDENTITY_ME_PREFIX}.wvmpOverview.WvmpInsightsCard` as const;

export interface LinkedInWvmpInsightsCard {
  $type: typeof WVMP_INSIGHTS_CARD_TYPE;
  trackingId: TrackingId;
  objectUrn: WvmpUrn;
  value: LinkedInWvmpInsightCards;
}

export type LinkedInWvmpInsightCards =
  | LinkedInWvmpSummaryInsightCard
  | LinkedInWvmpNotableViewersCard
  | LinkedInWvmpCompanyInsightCard
  | LinkedInWvmpJobTitleInsightCard
  | LinkedInWvmpSourceInsightCard;

interface LinkedInWvmpInsightCard {
  'wvmpMetadata': LinkedInWvmpMetadata;
  '*cards': CardUrn[];
  'cards'?: LinkedInCard[];
  'numViews': number;
}

export const WVMP_METADATA_TYPE = `${IDENTITY_ME_PREFIX}.WvmpMetadata` as const;

export interface LinkedInWvmpMetadata {
  $type: typeof WVMP_METADATA_TYPE;
  timeRange: LinkedInTimeRange;
}

export const WVMP_SUMMARY_INSIGHT_CARD_TYPE =
  `${IDENTITY_ME_PREFIX}.wvmpOverview.WvmpSummaryInsightCard` as const;

export interface LinkedInWvmpSummaryInsightCard
  extends LinkedInWvmpInsightCard {
  $type: typeof WVMP_SUMMARY_INSIGHT_CARD_TYPE;
  numViewsChangeInPercentage: number;
  timeFrame: string;
}

export const WVMP_NOTABLE_VIEWERS_CARD_TYPE =
  `${IDENTITY_ME_PREFIX}.wvmpOverview.WvmpNotableViewersCard` as const;

export interface LinkedInWvmpNotableViewersCard
  extends LinkedInWvmpInsightCard {
  $type: typeof WVMP_NOTABLE_VIEWERS_CARD_TYPE;
  notableViewerType: string; // TODO: add stricter types
}

export const WVMP_COMAPNY_INSIGHT_CARD_TYPE =
  `${IDENTITY_ME_PREFIX}.wvmpOverview.WvmpCompanyInsightCard` as const;

export interface LinkedInWvmpCompanyInsightCard
  extends LinkedInWvmpInsightCard {
  '$type': typeof WVMP_COMAPNY_INSIGHT_CARD_TYPE;
  '*miniCompany': MiniCompanyUrn;
  'miniCompany'?: LinkedInMiniCompany;
}

export const WVMP_JOB_TITLE_INSIGHT_CARD_TYPE =
  `${IDENTITY_ME_PREFIX}.wvmpOverview.WvmpJobTitleInsightCard` as const;

export interface LinkedInWvmpJobTitleInsightCard
  extends LinkedInWvmpInsightCard {
  $type: typeof WVMP_JOB_TITLE_INSIGHT_CARD_TYPE;
  viewerTitle: string;
}

export const WVMP_SOURCE_INSIGHT_CARD_TYPE =
  `${IDENTITY_ME_PREFIX}.wvmpOverview.WvmpSourceInsightCard` as const;

export interface LinkedInWvmpSourceInsightCard extends LinkedInWvmpInsightCard {
  $type: typeof WVMP_SOURCE_INSIGHT_CARD_TYPE;
  referrer: LinkedInAttributedText;
}
