import { Skeleton, SkeletonProps } from '@mui/material';
import React from 'react';

const DelayedSkeleton = React.forwardRef<
  HTMLElement,
  SkeletonProps & { delay?: number; quantity?: number }
>((props, ref) => {
  const { delay, quantity, ...rest } = props;
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => setShow(true), props.delay ?? 200);
    return () => clearTimeout(timeout);
  }, []);

  return show ? (
    <>
      {Array.from({ length: props.quantity ?? 1 }, (_, i) => (
        <Skeleton {...rest} ref={i === 0 ? ref : undefined} key={i} />
      ))}
    </>
  ) : null;
});

export default DelayedSkeleton;
