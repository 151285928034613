import React from 'react';
import { Box } from '@mui/material';
import { Slide, SlideProps, Typography } from '@mui/material';
import { parseText } from './Chat.functions';
import { ConversationContact } from 'linkedin-domain-types';
import ChatAttachment from './ChatAttachment';
import { useManualMode } from '@/Routes/ManualMode/ManualModeContext';
import { useLinkedInAccount } from '@/data/LinkedIn/Account';
import { Contact } from '@/data/Classes/Contact';
import ChatMessageMenu from './ChatMessageMenu';
import ChatMessageReactEmoji from './ChatMessageReactEmoji';
import { Message } from '@common/types/ApiTypes';
import { language } from '@/index';
import { Colors } from '@idot-digital/generic-helpers';
import { useFeatureFlags } from '@/globals/featureFlags';
import ContactAvatar from '../Avatar/ContactAvatar';

export interface Reaction {
  count: number;
  emoji: string;
  viewerReacted: boolean;
}

export interface ChatMessageProps {
  message: Message & { new?: boolean };
  contact: Pick<Contact, 'name' | 'pictures'> | null;
  participants: (Pick<
    ConversationContact,
    'firstName' | 'lastName' | 'profileID'
  > &
    Partial<Pick<ConversationContact, 'publicIdentifier'>>)[];
  onLoad?: (height: number) => void;
  setReaction?: (message: Message, emoji: string, status: boolean) => void;
  disabled?: boolean;
}

export default function ChatMessage(props: ChatMessageProps) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- this component could be used outside of the manual mode context
  const { openLinkedIn } = useManualMode() ?? {};
  const { linkedInAccount } = useLinkedInAccount();

  const { chat_reactions } = useFeatureFlags();

  const [triggerOpenEmojiReaction, setTriggerOpenEmojiReaction] =
    React.useState(false);

  const [reactions, setReactions] = React.useState<Reaction[]>(
    props.message.reactions
  );

  // update reactions when message is updated
  React.useEffect(() => {
    setReactions(props.message.reactions);
  }, [props.message.reactions]);

  const toggleReaction = (emoji: string) => {
    const reaction = reactions.find((r) => r.emoji === emoji);

    props.setReaction?.(
      props.message as Message,
      emoji,
      !reaction?.viewerReacted
    );
  };

  const parsedMessage = React.useMemo(() => {
    return parseText(props.message.text ?? '');
  }, [props.message]);

  const sentByMe = React.useMemo(() => {
    return props.message.sendByYou;
  }, [props.message]);

  const Wrapper = props.message.new ? Slide : React.Fragment;
  const wrapperProps = props.message.new
    ? ({
        direction: sentByMe ? 'left' : 'right',
        in: true
      } as SlideProps)
    : {};

  return (
    <Box
      width="100%"
      boxSizing="border-box"
      px={2}
      display="flex"
      flexDirection="column"
      alignItems={sentByMe ? 'flex-end' : 'flex-start'}
      gap={1}
      sx={{
        '&:hover': {
          '.chat-show-on-hover': {
            opacity: 1
          }
        }
      }}>
      <Wrapper {...wrapperProps}>
        <Box
          sx={{
            position: 'relative',
            background: (theme) =>
              sentByMe
                ? theme.palette.background.default
                : theme.palette.action.selected,
            color: (theme) => theme.palette.text.secondary,
            borderRadius: 2,
            [sentByMe ? 'borderBottomRightRadius' : 'borderBottomLeftRadius']:
              0,
            maxWidth: '66%',
            border: (theme) => `1px solid ${theme.palette.divider}`,
            padding: (theme) =>
              props.contact ? theme.spacing(0.5, 1.5, 1.5) : theme.spacing(1.5),
            boxShadow: '0px 2px 4px #0000000A',
            minWidth: props.message.attachments.some(
              (att) => att.type === 'audio'
            )
              ? '50%'
              : undefined
          }}>
          {chat_reactions && !props.disabled && (
            <Box
              sx={{
                opacity: 0,
                transition: (theme) =>
                  theme.transitions.create('opacity', {
                    easing: theme.transitions.easing.sharp
                  })
              }}
              className="chat-show-on-hover">
              <ChatMessageMenu
                showReact={() => {
                  setTriggerOpenEmojiReaction(true);
                }}
              />
              <ChatMessageReactEmoji
                alignment={sentByMe ? 'left' : 'right'}
                triggerOpen={triggerOpenEmojiReaction}
                setTriggerOpen={() => setTriggerOpenEmojiReaction(false)}
                emojiClick={toggleReaction}
                currentEmojis={reactions.map((r) => r.emoji)}
              />
            </Box>
          )}

          {props.contact && (
            <Typography
              sx={{
                color: Colors.fromString(props.contact.name),
                fontSize: '0.8em'
              }}>
              <ContactAvatar
                component="span"
                sx={{
                  height: (theme) => theme.spacing(2),
                  width: (theme) => theme.spacing(2),
                  display: 'inline-block',
                  verticalAlign: 'sub',
                  fontSize: '.6rem',
                  textAlign: 'center',
                  lineHeight: (theme) => theme.spacing(2),
                  mr: 0.5
                }}
                contact={props.contact}
              />
              {props.contact.name}
            </Typography>
          )}
          {props.message.attachments.map((att) => (
            <ChatAttachment
              attachment={att}
              key={att.url}
              onLoad={(height) => props.onLoad?.(height)}
            />
          ))}
          <Typography
            sx={{
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              fontSize: (theme) => theme.typography.body2.fontSize
            }}>
            {props.message.deleted && <em>{language.text.message_deleted}</em>}
            {parsedMessage.map(({ type, value }, index) => {
              switch (type) {
                case 'link':
                  return (
                    <Typography
                      component="a"
                      href={value}
                      target="_blank"
                      sx={{
                        'textDecoration': 'none',
                        'color': (theme) => theme.palette.primary.main,
                        'fontSize': '1em',
                        '&:hover': {
                          textDecoration: 'underline'
                        }
                      }}
                      key={index}>
                      {value}
                    </Typography>
                  );
                case 'linebreak':
                  return <br key={index} />;
                case 'mention':
                  return (() => {
                    const name = value.slice(1);
                    const participant =
                      props.participants.find(
                        (p) => p.firstName + ' ' + p.lastName === name
                      ) ??
                      (linkedInAccount &&
                      linkedInAccount.firstName +
                        ' ' +
                        linkedInAccount.lastName ===
                        name
                        ? linkedInAccount
                        : null);
                    if (participant) {
                      return (
                        <Typography
                          component="span"
                          sx={{
                            color: 'primary.main',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            fontSize: '1em'
                          }}
                          onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- could be undefined if outside of manual mode context
                            openLinkedIn?.(participant);
                          }}
                          key={index}>
                          {value}
                        </Typography>
                      );
                    } else {
                      return (
                        <Typography
                          component="span"
                          key={index}
                          sx={{ fontSize: '1em' }}>
                          {value}
                        </Typography>
                      );
                    }
                  })();
                case 'text':
                default:
                  return (
                    <Typography
                      component="span"
                      key={index}
                      sx={{ fontSize: '1em' }}>
                      {value}
                    </Typography>
                  );
              }
            })}
          </Typography>
        </Box>
      </Wrapper>
      {chat_reactions && reactions.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            justifyContent: sentByMe ? 'flex-end' : 'flex-start',
            flexWrap: 'wrap',
            maxWidth: '100%'
          }}>
          {reactions.map((reaction) => (
            <Box
              key={reaction.emoji}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '0.8rem',
                minWidth: '1.5rem',
                borderRadius: 2,
                border: '0.5px solid',
                borderColor: reaction.viewerReacted
                  ? (theme) => theme.palette.primary.main
                  : (theme) => theme.palette.text.secondary,
                boxSizing: 'border-box',
                textAlign: 'center',
                cursor: 'pointer',
                px: 0.5
              }}
              onClick={() => toggleReaction(reaction.emoji)}>
              <Typography>{reaction.emoji}</Typography>
              {reaction.count > 1 && (
                <Typography variant="caption" ml={0.5}>
                  {reaction.count}
                </Typography>
              )}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
