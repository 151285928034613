import { Button, ButtonProps } from '@mui/material';
import React from 'react';
import DisabledTooltip, {
  DisabledTooltipProps
} from './DisabledTooltip/DisabledTooltip';
import DisabledTooltipPulsatingPromiseButton, {
  DisabledTooltipPulsatingPromiseButtonProps
} from './DisabledTooltip/DisabledTooltipPulsatingPromiseButton';
import ProgressButton, {
  ProgressButtonProps
} from './ProgressButton/ProgressButton';
import ProgressPromiseButton, {
  ProgressPromiseButtonProps
} from './ProgressButton/ProgressPromiseButton';
import PulsatingProgressButton, {
  PulsatingProgressButtonProps
} from './ProgressButton/PulsatingProgressButton';
import PulsatingProgressPromiseButton, {
  PulsatingProgressPromiseButtonProps
} from './ProgressButton/PulsatingProgressPromiseButton';
import PromiseButton, {
  PromiseButtonProps
} from './PromiseButton/PromiseButton';
import PulsatingButton, {
  PulsatingButtonProps
} from './PulsatingButton/PulsatingButton';
import PulsatingPromiseButton from './PulsatingButton/PulsatingPromiseButton';
import PulsatingRingButton, {
  PulsatingRingButtonProps
} from './PulsatingButton/PulsatingRingButton';
import PulsatingRingPromiseButton, {
  PulsatingRingPromiseButtonProps
} from './PulsatingButton/PulsatingRingPromiseButton';

export type FancyButtonProps<
  PromiseB extends boolean = false,
  PulsatingB extends 'ring' | 'default' | 'none' = 'none',
  TooltipB extends boolean = false,
  ProgressB extends boolean = false
> = {
  fType?: {
    promise?: PromiseB;
    pulsating?: PulsatingB;
    hasTooltip?: TooltipB;
    progress?: ProgressB;
  };
} & (PromiseB extends true
  ? PulsatingB extends 'default'
    ? TooltipB extends true
      ? ProgressB extends true
        ? // promise, pulsating, tooltip, progress
          {}
        : // promise, pulsating, tooltip
          DisabledTooltipPulsatingPromiseButtonProps
      : ProgressB extends true
        ? // promise, pulsating, progress
          PulsatingProgressPromiseButtonProps
        : // promise, pulsating
          ProgressPromiseButtonProps
    : PulsatingB extends 'ring'
      ? TooltipB extends true
        ? ProgressB extends true
          ? // promise, pulsating ring, tooltip, progress
            {}
          : // promise, pulsating ring, tooltip
            {}
        : ProgressB extends true
          ? // promise, pulsating ring, progress
            {}
          : // promise, pulsating ring
            PulsatingRingPromiseButtonProps
      : TooltipB extends true
        ? ProgressB extends true
          ? // promise, tooltip, progress
            {}
          : // promise, tooltip
            {}
        : ProgressB extends true
          ? // promise, progress
            ProgressPromiseButtonProps
          : // promise
            PromiseButtonProps
  : PulsatingB extends 'default'
    ? TooltipB extends true
      ? ProgressB extends true
        ? // pulsating, tooltip, progress
          {}
        : // pulsating, tooltip
          {}
      : ProgressB extends true
        ? // pulsating, progress
          PulsatingProgressButtonProps
        : // pulsating
          PulsatingButtonProps
    : PulsatingB extends 'ring'
      ? TooltipB extends true
        ? ProgressB extends true
          ? // pulsating ring, tooltip, progress
            {}
          : // pulsating ring, tooltip
            {}
        : ProgressB extends true
          ? // pulsating ring, progress
            {}
          : // pulsating ring
            PulsatingRingButtonProps
      : TooltipB extends true
        ? ProgressB extends true
          ? // tooltip, progress
            {}
          : // tooltip
            DisabledTooltipProps
        : ProgressB extends true
          ? // progress
            ProgressButtonProps
          : // normal button
            ButtonProps);

const FancyButton = React.forwardRef(
  <
    PromiseB extends boolean = false,
    PulsatingB extends 'ring' | 'default' | 'none' = 'none',
    TooltipB extends boolean = false,
    ProgressB extends boolean = false
  >(
    props: FancyButtonProps<PromiseB, PulsatingB, TooltipB, ProgressB>,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const { fType = {}, ...buttonProps } = props;
    const {
      promise = false,
      pulsating = 'none',
      hasTooltip = false,
      progress = false
    } = fType;

    if (promise) {
      if (pulsating === 'default') {
        if (hasTooltip) {
          if (progress) {
            // promise, pulsating, tooltip, progress
          } else {
            // promise, pulsating, tooltip
            return (
              <DisabledTooltipPulsatingPromiseButton
                {...buttonProps}
                ref={ref}
              />
            );
          }
        } else {
          if (progress) {
            // promise, pulsating, progress
            return (
              <PulsatingProgressPromiseButton {...buttonProps} ref={ref} />
            );
          } else {
            // promise, pulsating
            return <PulsatingPromiseButton {...buttonProps} ref={ref} />;
          }
        }
      } else if (pulsating === 'ring') {
        if (hasTooltip) {
          if (progress) {
            // promise, pulsating ring, tooltip, progress
          } else {
            // promise, pulsating ring, tooltip
          }
        } else {
          if (progress) {
            // promise, pulsating ring, progress
          } else {
            // promise, pulsating ring
            return <PulsatingRingPromiseButton {...buttonProps} ref={ref} />;
          }
        }
      } else {
        if (hasTooltip) {
          if (progress) {
            // promise, tooltip, progress
          } else {
            // promise, tooltip
          }
        } else {
          if (progress) {
            // promise, progress
            return <ProgressPromiseButton {...buttonProps} ref={ref} />;
          } else {
            // promise
            return <PromiseButton {...buttonProps} ref={ref} />;
          }
        }
      }
    } else {
      if (pulsating === 'default') {
        if (hasTooltip) {
          if (progress) {
            // pulsating, tooltip, progress
          } else {
            // pulsating, tooltip
          }
        } else {
          if (progress) {
            // pulsating, progress
            return <PulsatingProgressButton {...buttonProps} ref={ref} />;
          } else {
            // pulsating
            return <PulsatingButton {...buttonProps} ref={ref} />;
          }
        }
      } else if (pulsating === 'ring') {
        if (hasTooltip) {
          if (progress) {
            // pulsating ring, tooltip, progress
          } else {
            // pulsating ring, tooltip
          }
        } else {
          if (progress) {
            // pulsating ring, progress
          } else {
            // pulsating ring
            return <PulsatingRingButton {...buttonProps} ref={ref} />;
          }
        }
      } else {
        if (hasTooltip) {
          if (progress) {
            // tooltip, progress
          } else {
            // tooltip
            return <DisabledTooltip {...buttonProps} ref={ref} />;
          }
        } else {
          if (progress) {
            // progress
            return <ProgressButton {...buttonProps} ref={ref} />;
          } else {
            // normal button
            return <Button {...buttonProps} ref={ref} />;
          }
        }
      }
    }

    return <>Not implemented</>;
  }
);

export default FancyButton;
