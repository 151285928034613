import { Contact } from '@/data/Classes/Contact';
import { Theme } from '@mui/material';
import { MarkedProfile } from '@common/types/ApiTypes';
import { language } from '@/index';
import { Task, TaskConfig, TaskHeader } from 'focus-mode-scheduler';
import { TaskIncomingEventMap } from 'focus-mode-scheduler/src/Task/Task';
import LinkedInLinks from '@common/Webview.common/LinkedInLinks';

export default class ExpandNetworkTask extends Task {
  public static get config(): TaskConfig {
    return {
      id: 'expand-network',
      info: {
        title: language.text.expand_network,
        description: language.text.expand_network_description,
        avaContent: language.text.ava_hint_focus_expand_network
      },
      hide: false,
      constraintBreachedDialogContent: {
        time: {
          header: language.text.time_is_up,
          body: language.text.you_reached_max_time_for_marking_contacts,
          buttons: {
            next: { text: language.text.next_step },
            oneMore: {
              text: language.text.one_more_contact,
              amount: 5
            }
          }
        },
        items: {
          header: language.text.maximum_reached,
          body: language.text.maximum_marked_contacts_reached,
          buttons: {
            next: { text: language.text.next_step },
            oneMore: {
              text: language.text.one_more_contact,
              amount: 2
            }
          }
        }
      }
    };
  }
  /**
   * Colors for different completion phases of the task
   */
  public static PHASES: {
    [key: number]: (theme: Theme) => string | undefined;
  } = {
    // 0: (theme) => theme.palette.error.light,
    0: (theme) => theme.palette.warning.main,
    0.5: (theme) => theme.palette.success.main
  };

  protected _header: TaskHeader = {
    title: language.text.expand_network,
    subtitle: language.text.expand_network_description
  };
  /**
   * Link where to start looking for new contacts (e.g. activity of audience holder)
   */
  private _startLink: string = '';
  public get startLink(): string {
    return this._startLink!;
  }

  constructor(
    public audienceHolders: Contact[],
    public readonly existingContacts: MarkedProfile[]
  ) {
    super();

    this.calculateStartLink();
  }

  protected calculateStartLink(): string {
    this._startLink = LinkedInLinks.getLinkedInSearchLink(this.audienceHolders);
    return this._startLink;
  }

  protected async handleEvent<Type extends keyof TaskIncomingEventMap>(
    type: Type
  ) {
    switch (type) {
      case 'skip':
      case 'complete':
        this.internalEmit('finished', undefined);
        break;
    }
  }

  public setAudienceHolders(audienceHolders: Contact[]) {
    this.audienceHolders = audienceHolders;
    this.calculateStartLink();
  }
}
