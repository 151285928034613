import React from 'react';
import Loading from '../Loading/Loading';
import log from 'electron-log';
import * as Sentry from '@sentry/electron/renderer';

export interface WaitForProps {
  promise?: Promise<any>;
  component?: React.ComponentType<any>;
  errorComponent: React.FC<{ error: string }>;
  errorMessage?: string;
}

/**
 * Small component to only mount a component when a promise is resolved.
 * If the promise is rejected, an error page is shown.
 * Loading is shown while the promise is pending.
 */
export default function WaitFor(props: WaitForProps) {
  const [state, setState] = React.useState({
    loading: true,
    error: false
  });

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (!props.promise) return;
    (async () => {
      try {
        await props.promise!;
        setState({
          loading: false,
          error: false
        });
      } catch (error: any) {
        log.error('Error while loading data: ' + error.message, error.stack);
        Sentry.captureException(error, { data: { errorInfo: error.message } });
        setState({
          loading: false,
          error: true
        });
      }
    })();
  }, [props.promise]);

  if (state.loading) return <Loading />;

  const ErrorPage = props.errorComponent;
  if (state.error)
    return (
      <ErrorPage
        error={props.errorMessage ?? 'Error while loading data in WaitFor'}
      />
    );

  if (props.component) return React.createElement(props.component);
  return null;
}
