import React from 'react';
import PromiseButton from '../PromiseButton/PromiseButton';
import PulsatingButton, { PulsatingButtonProps } from './PulsatingButton';

const PulsatingPromiseButton = React.forwardRef(
  (
    props: PulsatingButtonProps<typeof PromiseButton>,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    return <PulsatingButton component={PromiseButton} {...props} ref={ref} />;
  }
);

export default PulsatingPromiseButton;
