import { LinkedInRequestService } from '../services/LinkedInRequestService';
import {
  AUTH_HEADER,
  LINKEDIN_AUTH_URL,
  LINKEDIN_LOGIN_URL,
  LINKEDIN_LOGOUT_URL
} from '../client/Config';
import { AxiosResponse } from 'axios';
import { Options, trace } from '../client/Utils';

@trace()
export class AuthRequest {
  private requestService: LinkedInRequestService;

  constructor(requestService: LinkedInRequestService) {
    this.requestService = requestService;
  }

  getAnonAuth(options: Options): Promise<AxiosResponse | null> {
    return this.requestService.get(
      LINKEDIN_AUTH_URL,
      options,
      {
        fullResponse: true
      },
      undefined,
      'anonAuth'
    );
  }

  logout(options: Options): Promise<unknown> {
    const JSESSIONID = this.requestService.updateCSRFToken();
    return this.requestService.get<unknown>(
      `${LINKEDIN_LOGOUT_URL}?csrfToken=${JSESSIONID}`,
      options,
      undefined,
      undefined,
      'logout'
    );
  }

  authUser(
    username: string,
    password: string,
    sessionId: string,
    options: Options
  ): Promise<AxiosResponse | null> {
    const payload = new URLSearchParams({
      session_key: username,
      session_password: password,
      csrfToken: sessionId
    });

    return this.requestService.post(
      LINKEDIN_LOGIN_URL,
      payload.toString(),
      options,
      {
        headers: AUTH_HEADER,
        fullResponse: true
      },
      undefined,
      'authUser'
    );
  }
}
