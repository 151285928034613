import { Box, BoxProps, SxProps, Theme } from '@mui/material';
import FrameImg from './fullscreen_frame.png';
import CockpitImg from './cockpit.png';
import DoorImg from './bottom_door.png';

export type BackgroundVariant = 'frame' | 'door' | 'cockpit';

const IMAGES = {
  cockpit: {
    img: CockpitImg,
    styles: {
      backgroundPosition: 'center bottom'
    }
  },
  door: {
    img: DoorImg,
    styles: {
      backgroundPosition: 'center bottom'
    }
  },
  frame: {
    img: FrameImg
  }
} as {
  [key in BackgroundVariant]: {
    img: string;
    styles?: SxProps<Theme>;
  };
};

export interface BackgroundProps extends BoxProps {
  variant?: BackgroundVariant;
}

export default function Background(props: BackgroundProps) {
  const { variant = 'frame', ...boxProps } = props;
  const img = IMAGES[variant];
  return (
    <Box
      {...boxProps}
      sx={{
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        ...(img.styles as any),
        ...boxProps.sx,
        backgroundImage: `url(${img.img})`
      }}
    />
  );
}
