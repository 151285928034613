import StatsActions, {
  HistoricStatsFilter as HistoricStatsFilterType
} from '@/data/DataServer/Stats';
import { Box, Typography } from '@mui/material';
import React from 'react';
import HistoricStatsFilter from './HistoricStatsFilter';
import { Center, DelayedSkeleton } from 'ui-utils';
import { PriorityHigh } from '@mui/icons-material';
import { language } from '@/index';
import { Dates } from '@/other/Dates';
import ApexChart from 'react-apexcharts';

export default function HistoricStats() {
  const [filter, setFilter] = React.useState<HistoricStatsFilterType>({
    endDate: Dates.add(new Date(), -1, 'day'),
    startDate: Dates.add(new Date(), -1, 'week'),
    interval: 'days',
    intervalFactor: 1,
    stats: ['MessageSend']
  });
  const { historic, status } = StatsActions.useHistoric(filter);

  const xAxisTicks = React.useMemo(
    () =>
      Dates.getDatesBetween(filter.startDate, filter.endDate, {
        interval: filter.interval.slice(0, -1) as
          | 'day'
          | 'hour'
          | 'week'
          | 'month',
        intervalFactor: filter.intervalFactor
      }),
    [filter.startDate, filter.endDate]
  );

  const data = React.useMemo(() => {
    if (!historic) return [];
    return [
      {
        name: filter.stats[0],
        data: xAxisTicks.map(
          (date) =>
            historic.find((d) =>
              Dates.onSame(
                filter.interval.slice(0, -1) as
                  | 'hour'
                  | 'day'
                  | 'week'
                  | 'month',
                d.timestamp,
                date
              )
            )?.stats[filter.stats[0]] ?? 0
        )
      }
    ];
  }, [filter.stats, historic]);

  const barWrapperRef = React.useRef<HTMLDivElement>();
  const height = 350;

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr"
      gridTemplateRows="auto 1fr"
      flex={1}>
      <HistoricStatsFilter filter={filter} onChange={setFilter} />
      {status === 'loading' && (
        <DelayedSkeleton height={300} width={500} sx={{ mx: 'auto' }} />
      )}
      {status === 'error' && (
        <Center fullHeight fullWidth>
          <PriorityHigh color="error" sx={{ fontSize: '5rem' }} />
          <Typography variant="h4">{language.text.server_error}</Typography>
        </Center>
      )}
      {status === 'success' && (
        <Box height="90%" ref={barWrapperRef}>
          <ApexChart
            options={{
              chart: {
                type: 'bar',
                height,
                toolbar: {
                  show: false
                }
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: '55%',
                  dataLabels: {
                    position: 'center'
                  },
                  borderRadius: 5
                }
              },
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: '12px'
                }
              },
              stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
              },
              xaxis: {
                categories: xAxisTicks.map((d) => {
                  switch (filter.interval) {
                    case 'hours':
                      return d.toLocaleDateString(language.getLanguage(), {
                        hour: 'numeric'
                      });
                    case 'days':
                      return d.toLocaleDateString(language.getLanguage(), {
                        day: 'numeric',
                        month: 'numeric'
                      });
                    case 'weeks':
                      return `${d.toLocaleDateString(language.getLanguage(), {
                        day: 'numeric',
                        month: 'numeric'
                      })} - ${Dates.add(
                        new Date(d),
                        6,
                        'day'
                      ).toLocaleDateString(language.getLanguage(), {
                        day: 'numeric',
                        month: 'numeric'
                      })}`;
                    case 'months':
                      return d.toLocaleDateString(language.getLanguage(), {
                        month: 'numeric',
                        year: '2-digit'
                      });
                  }
                }),
                axisTicks: {
                  show: false
                },
                crosshairs: {
                  fill: {
                    type: 'gradient',
                    gradient: {
                      colorFrom: '#D8E3F0',
                      colorTo: '#BED1E6',
                      stops: [0, 100],
                      opacityFrom: 0.4,
                      opacityTo: 0.5
                    }
                  }
                }
              },
              yaxis: {
                forceNiceScale: true
              },
              fill: {
                opacity: 1
              },
              tooltip: {
                enabled: false
              }
            }}
            series={data}
            type="bar"
            height={height}
          />
          {/* <LineChart
            xAxis={[
              {
                dataKey: 'date',
                valueFormatter: (value: Date) =>
                  new Date(value).toLocaleDateString('de-DE', {
                    day: 'numeric',
                    month: 'numeric'
                  }),
                label: 'Date',
                min: filter.startDate,
                max: filter.endDate,
                tickInterval: xAxisTicks,
                tickPlacement: 'middle',
                hideTooltip: true
              }
            ]}
            yAxis={filter.stats.map((stat) => ({
              dataKey: stat,
              label: StatsHelper.getStatsAsset(stat)?.title ?? '[UNKNOWN]',
              min: 0,
              hideTooltip: true
            }))}
            dataset={mappedData}
            series={filter.stats.map((stat) => ({
              dataKey: stat,
              label: stat,
              connectNulls: true
            }))}
            slotProps={{
              legend: {
                hidden: true
              }
            }}
          /> */}
        </Box>
      )}
    </Box>
  );
}
