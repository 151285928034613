import { AxiosInstance } from 'axios';
import LinkedIn from './LinkedIn';
import {
  Conversation,
  ConversationId,
  LinkedInContact,
  Message,
  ProfileId
} from 'linkedin-domain-types';
import { Options } from './raw/client/Utils';
import { Iterators } from '@idot-digital/generic-helpers';

export default class MonitoredLinkedIn extends LinkedIn {
  private monitoringListener: MonitoringListener;
  private sessionIdentifier: string;
  constructor(
    monitoringListener: MonitoringListener,
    requestInstance?: AxiosInstance
  ) {
    super(requestInstance);
    this.monitoringListener = monitoringListener;
    this.sessionIdentifier = Math.random().toString(36).slice(2);
  }

  public override getConversation(
    conversationID: Conversation['conversationID'],
    options?: Options
  ) {
    this.monitoringListener({
      type: 'GET_CONVERSATION',
      conversationID,
      category: 'normal',
      sessionIdentifier: this.sessionIdentifier
    });
    return super.getConversation(conversationID, options);
  }

  public override listConversations(
    lastActivityAt?: Conversation['lastActivityAt'],
    options?: Options
  ) {
    this.monitoringListener({
      type: 'GET_CONVERSATION_PAGE',
      category: 'normal',
      sessionIdentifier: this.sessionIdentifier
    });
    return super.listConversations(lastActivityAt, options);
  }

  public override listSearchConversations(
    keyword: string,
    cursor?: string,
    options?: Options
  ) {
    this.monitoringListener({
      type: 'GET_CONVERSATION_PAGE_WITH_KEYWORD',
      category: 'normal',
      keyword,
      sessionIdentifier: this.sessionIdentifier
    });
    return super.listSearchConversations(keyword, cursor, options);
  }

  public override getArchivedConversations(cursor?: string, options?: Options) {
    return Iterators.map(
      super.getArchivedConversations(cursor, options),
      (conversation) => {
        this.monitoringListener({
          type: 'GET_CONVERSATION_ITERATOR_ENTRY',
          category: 'normal',
          sessionIdentifier: this.sessionIdentifier
        });
        return conversation;
      }
    );
  }

  public override listArchivedConversations(
    cursor?: string,
    options?: Options
  ) {
    this.monitoringListener({
      type: 'GET_CONVERSATION_PAGE',
      category: 'archived',
      sessionIdentifier: this.sessionIdentifier
    });
    return super.listArchivedConversations(cursor, options);
  }

  public override listMessages(
    conversationId: string,
    lastMessage?: Pick<Message, 'createdAt'>,
    options?: Options
  ) {
    this.monitoringListener({
      type: 'GET_MESSAGES_PAGE',
      conversationID: conversationId,
      sessionIdentifier: this.sessionIdentifier
    });
    return super.listMessages(conversationId, lastMessage, options);
  }

  public override getProfile(profileID: string, options?: Options) {
    this.monitoringListener({
      type: 'GET_PROFILE',
      profileID,
      sessionIdentifier: this.sessionIdentifier
    });
    return super.getProfile(profileID, options);
  }

  public override getFullProfile(profileID: string, options?: Options) {
    this.monitoringListener({
      type: 'GET_FULL_PROFILE',
      profileID,
      sessionIdentifier: this.sessionIdentifier
    });
    return super.getFullProfile(profileID, options);
  }

  public override listInvites(startIndex?: number, options?: Options) {
    this.monitoringListener({
      type: 'GET_RECEIVED_INVITES_PAGE',
      sessionIdentifier: this.sessionIdentifier
    });
    return super.listInvites(startIndex, options);
  }

  public override listNetworkParticipants(
    startIndex?: number,
    sortType?: 'RECENTLY_ADDED' | 'FIRSTNAME_LASTNAME' | 'LASTNAME_FIRSTNAME',
    options?: Options
  ): Promise<(LinkedInContact & { index: number })[]> {
    this.monitoringListener({
      type: 'GET_OWN_NETWORK_PAGE',
      sortType,
      sessionIdentifier: this.sessionIdentifier
    });
    return super.listNetworkParticipants(startIndex, sortType, options);
  }

  public override listProfileVisitors(options?: Options) {
    this.monitoringListener({
      type: 'GET_PROFILE_VISITORS_PAGE',
      sessionIdentifier: this.sessionIdentifier
    });
    return super.listProfileVisitors(options);
  }

  public override sendMessage(
    conversationId: string,
    text: string,
    options?: Options
  ) {
    this.monitoringListener({
      type: 'SEND_MESSAGE',
      conversationId,
      text,
      sessionIdentifier: this.sessionIdentifier
    });
    return super.sendMessage(conversationId, text, options);
  }

  public override checkLogin(options?: Options) {
    this.monitoringListener({
      type: 'CHECK_LOGIN',
      sessionIdentifier: this.sessionIdentifier
    });
    return super.checkLogin(options);
  }

  public override sendConnectionRequest(
    profileID: string,
    message: string,
    options?: Options
  ) {
    this.monitoringListener({
      type: 'SEND_CONNECTION_REQUEST',
      profileID,
      sessionIdentifier: this.sessionIdentifier
    });
    return super.sendConnectionRequest(profileID, message, options);
  }

  public override setArchived(
    conversation_ids: ConversationId[],
    archived?: boolean,
    options?: Options
  ) {
    this.monitoringListener({
      type: 'SET_ARCHIVED',
      conversationIds: conversation_ids,
      sessionIdentifier: this.sessionIdentifier
    });
    return super.setArchived(conversation_ids, archived, options);
  }

  public override getOwnProfile(options?: Options) {
    this.monitoringListener({
      type: 'GET_OWN_PROFILE',
      sessionIdentifier: this.sessionIdentifier
    });
    return super.getOwnProfile(options);
  }

  public override isConnection(
    profile:
      | string
      | Pick<LinkedInContact, 'firstName' | 'lastName' | 'profileID'>,
    options?: Options
  ): Promise<boolean> {
    this.monitoringListener({
      type: 'IS_CONNECTED',
      publicIdentifier: profile,
      sessionIdentifier: this.sessionIdentifier
    });
    return super.isConnection(profile, options);
  }

  public override markAsRead(
    conversationId: Conversation['conversationID'],
    read?: boolean,
    options?: Options
  ) {
    this.monitoringListener({
      type: 'MARK_AS_READ',
      conversationId,
      sessionIdentifier: this.sessionIdentifier
    });
    return super.markAsRead(conversationId, read, options);
  }

  public override setReaction(
    message: Message,
    emoji: string,
    status?: boolean,
    options?: Options
  ) {
    this.monitoringListener({
      type: 'SET_EMOJI_REACTION',
      messageID: message.messageID,
      sessionIdentifier: this.sessionIdentifier
    });
    return super.setReaction(message, emoji, status, options);
  }

  public override logout(options?: Options) {
    this.monitoringListener({
      type: 'LOGOUT',
      sessionIdentifier: this.sessionIdentifier
    });
    return super.logout(options);
  }

  public override sendDirectMessage(
    profileID: ProfileId,
    message: string,
    options?: Options
  ) {
    this.monitoringListener({
      type: 'SEND_DIRECT_MESSAGE',
      profileID,
      text: message,
      sessionIdentifier: this.sessionIdentifier
    });
    return super.sendDirectMessage(profileID, message, options);
  }

  public override openRealtimeConnection() {
    this.monitoringListener({
      type: 'OPEN_REALTIME_CONNECTION',
      sessionIdentifier: this.sessionIdentifier
    });
    super.openRealtimeConnection();
  }

  public override closeRealtimeConnection() {
    this.monitoringListener({
      type: 'CLOSE_REALTIME_CONNECTION',
      sessionIdentifier: this.sessionIdentifier
    });
    super.closeRealtimeConnection();
  }
}

export type MonitoringListener = (event: API_EVENT) => void;

export type API_EVENT = RawApiEvt & {
  sessionIdentifier: string;
};

type RawApiEvt =
  | {
      type: 'GET_OWN_PROFILE';
    }
  | {
      type: 'GET_PROFILE';
      profileID: string;
    }
  | {
      type: 'GET_FULL_PROFILE';
      profileID: string;
    }
  | {
      type: 'IS_CONNECTED';
      publicIdentifier:
        | Pick<LinkedInContact, 'firstName' | 'lastName' | 'profileID'>
        | string;
    }
  | {
      type: 'GET_MESSAGES_PAGE';
      conversationID: string;
    }
  | {
      type: 'GET_RECEIVED_INVITES_PAGE';
    }
  | {
      type: 'GET_OWN_NETWORK_PAGE';
      sortType?: 'RECENTLY_ADDED' | 'FIRSTNAME_LASTNAME' | 'LASTNAME_FIRSTNAME';
    }
  | {
      type: 'GET_PROFILE_VISITORS_PAGE';
    }
  | {
      type: 'GET_CONVERSATION';
      conversationID: string;
      category: 'normal' | 'archived';
    }
  | {
      type: 'GET_CONVERSATION_ITERATOR_ENTRY';
      conversationID?: string;
      category: 'normal' | 'archived';
    }
  | {
      type: 'GET_CONVERSATION_PAGE';
      category: 'normal' | 'archived';
    }
  | {
      type: 'GET_CONVERSATION_PAGE_WITH_KEYWORD';
      keyword: string;
      category: 'normal' | 'archived';
    }
  | {
      type: 'SET_ARCHIVED';
      conversationIds: string[];
    }
  | {
      type: 'SET_EMOJI_REACTION';
      messageID: string;
    }
  | {
      type: 'MARK_AS_READ';
      conversationId: string;
    }
  | {
      type: 'SEND_MESSAGE';
      conversationId: string;
      text: string;
    }
  | {
      type: 'SEND_DIRECT_MESSAGE';
      profileID: string;
      text: string;
    }
  | {
      type: 'SEND_CONNECTION_REQUEST';
      profileID: string;
    }
  | {
      type: 'CHECK_LOGIN';
    }
  | {
      type: 'LOGOUT';
    }
  | {
      type: 'OPEN_REALTIME_CONNECTION';
    }
  | {
      type: 'CLOSE_REALTIME_CONNECTION';
    };
