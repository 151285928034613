import { APP_PATHS, LOADER_PATHS } from '@/globals/Paths';
import { language } from '@/index';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function LoadEntireNetwork() {
  const navigate = useNavigate();

  return (
    <Box>
      <Typography variant="h4">{language.text.load_entire_network}</Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          navigate(`/${APP_PATHS.Loader}/${LOADER_PATHS.Network}`)
        }>
        {language.text.load_network}
      </Button>
    </Box>
  );
}
