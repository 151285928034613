import {
  FullProfile,
  MarkedProfile,
  PipelineEvent,
  Tag
} from '@common/types/ApiTypes';
import { Contact } from '../Classes/Contact';
import { Iterators, Objects } from '@idot-digital/generic-helpers';
import { getSelf } from '../LinkedIn/Account';
import queryClient from '@/other/QueryClient';
import {
  PartialParameters,
  RemoveFirst,
  createInfiniteQueryHook,
  createQueryHook
} from './QueryHelper';
import PrintableError from '@common/PrintableError/PrintableError';
import Auth from '@common/AuthManager/Auth.renderer';
import { chats, contacts } from '@digital-sun-solutions/cloud-functions';
import WebviewLinkedIn from '@common/Webview.renderer/WebviewLinkedIn';
import { LinkedInContact, Message } from 'linkedin-domain-types';
import apiRetry from '@common/FetchRetry/FetchRetry.renderer';
import ProfileCache from '@common/LinkedInEventHandlers/ProfileCache.renderer';
import { CSProfileType } from 'webview-preload';
import * as Sentry from '@sentry/electron/renderer';
import Logger from 'electron-log';
import { ContactType } from '@common/types/enums';
import { Arrays } from '@/other/Arrays';

/**
 * Get all missing data from LinkedIn API to create a full contact in DB
 * @param linkedInIdentifier identifier of the linkedin profile (`profileID` or `publicIdentifier`, `profileID` is best)
 * @param profile existing data of the profile
 * @param messages existing messages of the profile
 * @returns
 */
async function createContactFromLinkedInAPI(
  linkedInIdentifier: string,
  data: Partial<{
    profile: (LinkedInContact & { conversationID?: string }) | null;
    messages: Message[];
    noMoreMessages?: boolean;
  }> = {}
) {
  const cachedProfile =
    ProfileCache.getInstance().getProfile(linkedInIdentifier);
  const profileID = cachedProfile?.profileID ?? linkedInIdentifier;

  let profile = data.profile;

  if (profileID.length !== 39) {
    // needs to resolve profileID -> load entire profile for later
    profile = await WebviewLinkedIn.getFullProfile(profileID);
    if (!profile) return null;
  }

  try {
    const contact = await getContact(profileID);
    if (
      contact &&
      contact.type !== ContactType.MARKED &&
      contact.type !== ContactType.UNCATEGORIZED
    )
      return contact;
  } catch (_) {
    // if there is no contact, create one
  }

  // profile could already be loaded from profileID resolve step
  if (!profile?.conversationID)
    profile = await WebviewLinkedIn.getFullProfile(profileID);

  if (!profile) return null;

  // cleanup uncatagorized profile, create contact, create chat, insert messages
  const promises: Promise<unknown>[] = [];

  promises.push(
    apiRetry(() =>
      Auth.execRoute((token) =>
        contacts.uncategorized.add(
          [
            {
              firstname: profile.firstName,
              lastname: profile.lastName,
              profileID: profile.profileID,
              publicIdentifier: profile.publicIdentifier,
              pictures: profile.profilePictureUrl
            }
          ],
          { token }
        )
      )
    )
  );

  promises.push(
    apiRetry(() =>
      Auth.execRoute((token) =>
        contacts.setProfileData(
          {
            profiles: [
              {
                firstname: profile.firstName,
                lastname: profile.lastName,
                headline: profile.infoText,
                profileID: profile.profileID,
                publicIdentifier: profile.publicIdentifier,
                pictures: profile.profilePictureUrl
              }
            ]
          },
          { token }
        )
      )
    )
  );

  // add messages and get contact
  if (profile.conversationID) {
    promises.push(
      loadChatFromLinkedIn(profile.conversationID, {
        ...data,
        profile
      })
    );
  }

  await Promise.all(promises);

  invalidateCache();

  const contact = await getContact(profile.profileID);

  return contact;
}

async function loadChatFromLinkedIn(
  conversationID: string,
  data: {
    profile: Pick<LinkedInContact, 'profileID' | 'publicIdentifier'>;
    messages?: Message[];
    noMoreMessages?: boolean;
  }
) {
  const messages = data.messages ?? [];
  // Sort by newest first
  messages.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
  if (conversationID && !data.noMoreMessages) {
    messages.push(
      ...(await Iterators.toArray(
        Iterators.fromApiV2<Message>((cursor) =>
          WebviewLinkedIn.listMessages(
            conversationID,
            cursor ?? messages.at(-1)
          )
        )
      ))
    );
  }

  if (conversationID && !data.noMoreMessages) {
    messages.push(
      ...(await Iterators.toArray(
        Iterators.fromApiV2<Message>((cursor) =>
          WebviewLinkedIn.listMessages(
            conversationID,
            cursor ?? messages.at(-1)
          )
        )
      ))
    );
  }

  // create chat, insert messages
  const promises: Promise<unknown>[] = [];

  promises.push(
    apiRetry(
      () =>
        Auth.execRoute((token) =>
          chats.create(
            {
              archived: false,
              profileID: data.profile.profileID,
              conversationID: conversationID,
              unreadCount: 0
            },
            { token }
          )
        ),
      { acceptCodes: [409] }
    )
  );
  Arrays.split(messages, 50).map((messages) =>
    apiRetry(() =>
      Auth.execRoute((token) =>
        chats.addMessages(
          {
            conversationID,
            messages: messages.map((m) => ({
              attachments: m.attachments,
              createdAt: m.createdAt,
              deleted: m.deleted,
              messageID:
                m.messageID.split(',').at(-1)?.slice(0, -1) ?? 'MALFORMED',
              reactions: m.reactions,
              text: m.text,
              sendByYou: m.sentFrom !== data.profile.publicIdentifier
            }))
          },
          { token }
        )
      )
    )
  );

  await Promise.all(promises);
}

/**
 * Get a profile from cloud or linkedin
 * When getting a profile that is not yet saved in the cloud, it will be saved
 * Automatically updates profile if it is not a full contact
 * @param id profileID
 * @param canIDbePublicIdentifier if the id could also be a publicIdentifier instead of a profileID
 * @returns
 */
async function getFullProfile(
  id: string,
  canIDbePublicIdentifier = false
): Promise<FullProfile | null> {
  if (id === 'UNKNOWN') return null;
  // load data from cloud
  const rawContactDataRes = await Auth.execRoute((token) =>
    contacts.get(
      canIDbePublicIdentifier
        ? { profileIdOrPublicIdentifier: id }
        : {
            profileID: id
          },
      { token }
    )
  );
  if (rawContactDataRes.code === 500)
    throw new PrintableError(`Could not get profile of ${id}`);

  if (rawContactDataRes.code === 404) return null;

  if (typeof rawContactDataRes.data.profile.pictures === 'string')
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- catch parsing error of cloud functions -> not in types
    rawContactDataRes.data.profile.pictures = JSON.parse(
      rawContactDataRes.data.profile.pictures
    );
  return rawContactDataRes.data;
}

async function getFullProfiles(ids: string[]): Promise<FullProfile[]> {
  if (ids.length === 0) return [];

  const res = await Promise.allSettled(ids.map((id) => getFullProfile(id)));
  return Arrays.filterNull(
    res.map((r) => (r.status === 'fulfilled' ? r.value : null))
  );
}

async function getContact(
  id: string | undefined,
  canIDbePublicIdentifier?: boolean
): Promise<Contact | null> {
  if (id === undefined) return null;
  const linkedInAccount = await getSelf(true);
  if (!linkedInAccount) return null;

  // get full contact from cloud (or linkedin if not full)
  const fullContact = await getFullProfile(id, canIDbePublicIdentifier);
  if (!fullContact) return null;
  if (fullContact.profile.profileID === linkedInAccount.profileID) return null;
  // create contact class
  const contact = new Contact(fullContact, linkedInAccount.publicIdentifier);
  return contact;
}
getContact.getQueryKey = (...args: PartialParameters<typeof getContact>) => [
  'contacts',
  'get',
  args[0]?.toString() ?? '-1'
];

async function getContacts(ids: string[]): Promise<Contact[]> {
  try {
    // get full contact from cloud (or linkedin if not full)
    const fullContacts = await getFullProfiles(ids);

    return createContacts(fullContacts);
  } catch (e) {
    Logger.error('Failed to get contacts:', e);
    Sentry.captureException(e);
    return [];
  }
}

async function createContacts(
  profiles: (FullProfile & { cursor?: number })[]
): Promise<Contact[]> {
  const linkedInAccount = await getSelf(true);
  if (!linkedInAccount) return [];
  // create contact class
  return Arrays.filterNull(
    profiles.map(
      (profile) => new Contact(profile, linkedInAccount.publicIdentifier)
    )
  );
}

export type ContactFilter =
  | Omit<Parameters<(typeof contacts)['list']>[0], 'cursor'>
  | ContactSearchFilter;

export type ContactSearchFilter = Omit<
  Parameters<(typeof contacts)['search']>[0],
  'cursor'
>;

async function listContacts(
  cursor: Pick<Contact, 'cursor'> | null | undefined,
  filter?: ContactFilter
): Promise<Contact[]> {
  // remove empty filter values
  if (filter) {
    for (const [key, value] of Objects.entries(filter)) {
      if (value === undefined || Object.keys(value).length === 0)
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- but i want to
        delete filter[key];
    }
  }
  const res = await Auth.execRoute((token) =>
    contacts[
      filter && 'searchQuery' in filter && filter.searchQuery
        ? 'search'
        : 'list'
    ](
      {
        ...(filter as ContactSearchFilter),
        cursor: cursor?.cursor ?? undefined
      },
      { token }
    )
  );
  if (res.code !== 200)
    throw new PrintableError(
      `Could not load contacts ${res.code}: ${res.data}`
    );

  return await createContacts(
    res.data.contacts.map((c: FullProfile & { cursor?: number }) => {
      if (typeof c.profile.pictures === 'string')
        c.profile.pictures = JSON.parse(c.profile.pictures) as {
          [resolution: string]: string;
        };
      c.cursor = res.data.cursor;
      return c;
    })
  );
}
listContacts.getQueryKey = (
  ...args: Partial<RemoveFirst<Parameters<typeof listContacts>>>
) =>
  [
    'contacts',
    'list',
    ...(args[0] ? [JSON.stringify(args[0])] : [])
  ] as string[];

async function listAllContacts(filter?: ContactFilter | null) {
  const result: Contact[] = [];
  let cursor: Contact | null = null;
  while (true) {
    const res: Contact[] = await listContacts(cursor, filter ?? undefined);
    if (!res.length) break;
    result.push(...res);
    cursor = res[res.length - 1] ?? null;
  }
  return result;
}
listAllContacts.getQueryKey = (
  ...args: Partial<Parameters<typeof listAllContacts>>
) =>
  [
    'contacts',
    'listAll',
    ...(args[0] ? [JSON.stringify(args[0])] : [])
  ] as string[];

async function getPipelineHistory(
  profileID: string | undefined | null
): Promise<PipelineEvent[]> {
  if (profileID === undefined || profileID === null) return [];
  const res = await Auth.execRoute((token) =>
    contacts.getPipelineEvents(
      {
        profileID
      },
      { token }
    )
  );
  if (res.code !== 200)
    throw new PrintableError(
      `Could not get pipeline history of contact ${profileID}: ${res.data}`
    );
  return res.data;
}
getPipelineHistory.getQueryKey = (
  ...args: PartialParameters<typeof getPipelineHistory>
) => ['contacts', 'pipelineHistory', args[0]?.toString() ?? '-1'];

async function invalidateCache() {
  await queryClient.invalidateQueries('contacts');
  // await queryClient.invalidateQueries(getContact.getQueryKey());
  // await queryClient.invalidateQueries(listContacts.getQueryKey());
  // await queryClient.invalidateQueries(listAllContacts.getQueryKey());
}

async function getAllTags() {
  const res = await Auth.execRoute((token) => contacts.tags.get({}, { token }));
  if (res.code !== 200)
    throw new PrintableError(`Could not get all tags: ${res.data}`);
  return res.data.map((tag) => ({
    name: tag.tag_name,
    color: `#${tag.color}`
  }));
}
getAllTags.getQueryKey = () => ['contacts', 'tags', 'all'];

async function createTag(tag: Tag): Promise<void> {
  if (tag.color.startsWith('#')) tag.color = tag.color.slice(1);
  const res = await Auth.execRoute((token) =>
    contacts.tags.add(
      {
        tagName: tag.name,
        color: tag.color
      },
      { token }
    )
  );

  if (res.code !== 200)
    throw new PrintableError(`Could not create tag: ${res.code} ${res.data}`);

  await queryClient.invalidateQueries(getAllTags.getQueryKey());
}

async function deleteTag(name: Tag['name']) {
  const res = await Auth.execRoute((token) =>
    contacts.tags.remove(
      {
        tagName: name
      },
      { token }
    )
  );

  if (res.code !== 200)
    throw new PrintableError(`Could not delete tag: ${res.code} ${res.data}`);

  await queryClient.invalidateQueries(getAllTags.getQueryKey());
}

async function getMarkedProfiles() {
  const res = await Auth.execRoute((token) =>
    contacts.marked.get({}, { token })
  );
  if (res.code !== 200)
    throw new PrintableError(`Could not get marked contacts: ${res.code}`);
  return res.data;
}
getMarkedProfiles.getQueryKey = () => ['contacts', 'marked'];

async function addMarkedProfile(profile: MarkedProfile) {
  const res = await Auth.execRoute((token) =>
    contacts.marked.add(
      {
        profiles: [profile]
      },
      { token }
    )
  );

  if (res.code !== 200)
    throw new PrintableError(
      `Could not add marked contact: ${res.code} ${res.data}`
    );

  await queryClient.invalidateQueries(getMarkedProfiles.getQueryKey(), {
    exact: false
  });
}

async function existsMarkedProfile(profileIdOrPublicIdentifier: string) {
  const marked = await Auth.execRoute((token) =>
    contacts.marked.exist(
      {
        profileIdOrPublicIdentifier
      },
      { token }
    )
  );
  return marked.code === 200 && marked.data;
}
existsMarkedProfile.getQueryKey = (profileID: string) => [
  'contacts',
  'marked',
  'exists',
  profileID
];

async function removeMarkedProfile(profileIdOrPublicIdentifier: string) {
  const res = await Auth.execRoute((token) =>
    contacts.marked.remove(
      {
        profileID: profileIdOrPublicIdentifier
      },
      { token }
    )
  );

  if (res.code !== 200)
    throw new PrintableError(
      `Could not remove marked contact: ${res.code} ${res.data}`
    );

  await queryClient.invalidateQueries(getMarkedProfiles.getQueryKey(), {
    exact: false
  });
}

async function getProfileType(identifier: string): Promise<CSProfileType> {
  try {
    const profile = await getFullProfile(identifier, true);
    if (!profile) {
      if (await existsMarkedProfile(identifier)) return 'marked';
      return 'unknown';
    }
    switch (profile.info.type) {
      case 'PERSONAL':
        return 'personal';
      case 'AUDIENCE_HOLDER':
        return 'audienceHolder';
      case 'CUSTOMER':
        return 'customer';
      case 'NO_FIT':
        return 'no_match';
      case 'POTENTIAL_CUSTOMER':
        return 'potential_customer';
      case 'MARKED':
        return 'marked';
      case 'UNCATEGORIZED':
        return 'unknown';
      default:
        return 'unknown';
    }
  } catch (_) {
    return 'unknown';
  }
}

/*------------ React Query hooks --------------*/
const useContact = createQueryHook(
  (id?: string | null) => (id ? getContact(id) : null),
  'contact',
  {
    disableWhenUndefined: true,
    staticQueryKey: (...args) => getContact.getQueryKey(args[0] ?? undefined)
  }
);

const useContacts = createInfiniteQueryHook(listContacts, 'contacts', {
  refetchOnWindowFocus: false
});

const useAllContacts = createQueryHook(listAllContacts, 'contacts', {
  refetchOnWindowFocus: false
});

const usePipelineHistory = createQueryHook(getPipelineHistory, 'history', {
  refetchOnWindowFocus: false
});

const useTags = createQueryHook(getAllTags, 'tags', {
  refetchOnWindowFocus: false
});

const useMarkedProfiles = createQueryHook(getMarkedProfiles, 'profiles', {
  refetchOnWindowFocus: false
});

const ContactActions = {
  getFullProfile,
  getFullProfiles,
  getProfileType,
  getContact,
  getContacts,
  listContacts,
  listAllContacts,

  useContact,
  useContacts,
  useAllContacts,

  getPipelineHistory,
  usePipelineHistory,

  createTag,
  deleteTag,
  getAllTags,
  useTags,

  getMarkedProfiles,
  useMarkedProfiles,
  addMarkedProfile,
  existsMarkedProfile,
  removeMarkedProfile,

  createContactFromLinkedInAPI,
  loadChatFromLinkedIn,

  invalidateCache
};

export default ContactActions;
