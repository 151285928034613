import { TaskBuilder, TaskOptionsConfig } from 'focus-mode-scheduler';
import QuestionTask from './QuestionTask';
import { language } from '@/index';
import { NextTask } from 'focus-mode-scheduler/src/Task/TaskBuilder';
import { Contact } from '@/data/Classes/Contact';
import { SSCChat } from '@/data/Classes/Chat/SSCChat';
import {
  PipelineStep,
  Question,
  ServerPipelineStepType
} from '@common/PipelineManager/PipelineTypes';
import { PipelineEvent } from '@common/types/ApiTypes';
import { ContactType } from '@common/types/enums';
import ContactActions from '@/data/DataServer/Contact';
import { Pipelines } from '@common/PipelineManager/Pipelines';
import { BindProps, MapProps } from 'ui-utils';
import QuestionTaskUI from './QuestionTaskUI';
import OnboardingVideo from '../_general/OnboardingVideo/OnboardingVideo';
import { Iterators } from '@/other/Iterators';
import * as Sentry from '@sentry/electron/renderer';

interface InstanceData {
  contact: Contact;
  chat: SSCChat;
  question: Question;
  pipelineHistory: PipelineEvent[];
  pipelineSteps: PipelineStep[];
  activeStep: PipelineStep | null;
}

export default class QuestionTaskBuilder extends TaskBuilder<QuestionTask> {
  protected task = QuestionTask;

  protected iterator: AsyncGenerator<InstanceData> | null = null;
  private getIterator(): AsyncGenerator<InstanceData> {
    if (this.iterator) return this.iterator;
    this.iterator = Iterators.filterNull(
      Iterators.map(
        Iterators.merge(
          'priority',
          ...(
            [
              ServerPipelineStepType.QuestionDate,
              ServerPipelineStepType.QuestionOptions
            ] as const
          ).map((type) =>
            Iterators.fromApiV2<Contact>(async (cursor) =>
              ContactActions.listContacts(cursor, {
                values: {
                  stepType: type,
                  skipped: false,
                  type: ContactType.POTENTIAL_CUSTOMER
                }
              })
            )
          )
        ),
        async (contact) => {
          if (!contact.lastPipelineEvent) return null;
          const step = Pipelines.findStep(
            contact.lastPipelineEvent.currentStep
          );
          if (
            !step ||
            (step.type !== 'question-date' && step.type !== 'question-options')
          )
            return null;
          const chat = await contact.getChat().catch(() => {
            Sentry.captureException(
              new Error('[QuestionTaskBuilder] Chat not found'),
              {
                tags: {
                  contactId: contact.profileID,
                  connected: contact.connected,
                  lastStep: contact.lastPipelineEvent?.currentStep
                }
              }
            );
            return null;
          });
          if (!chat) return null;

          const pipelineHistory = await contact.getPipelineHistory();
          const pipelineSteps = Pipelines.getCurrentStepsToDisplay(
            contact.lastPipelineEvent?.currentStep
          );
          const activeStep = contact.lastPipelineEvent?.currentStep
            ? (Pipelines.getStepToDisplay(
                contact.lastPipelineEvent.currentStep,
                pipelineHistory.map((h) => h.currentStep)
              ) ?? null)
            : null;

          return {
            chat,
            contact,
            question: step,
            pipelineHistory,
            pipelineSteps,
            activeStep
          };
        }
      )
    );

    return this.iterator;
  }

  async getTask(): Promise<NextTask<QuestionTask>> {
    const { value, done } = await this.getIterator().next();
    if (done) return null;

    const task = new QuestionTask(
      value.contact,
      value.chat,
      value.question,
      value.pipelineHistory,
      value.pipelineSteps,
      value.activeStep
    );
    return {
      task,
      UI: BindProps(QuestionTaskUI, { task })
    };
  }

  public getStaticUI(): React.FC<{ loading?: boolean }> | null {
    return MapProps(
      BindProps(OnboardingVideo, {
        onboardingStep: 'focus:question',
        completeOn: 'finish'
      }),
      ({ loading }: { loading?: boolean }) => ({ pause: loading })
    );
  }

  public async getOptionsConfig(): Promise<TaskOptionsConfig | null> {
    return {
      items: {
        defaultValue: 10,
        min: 1,
        max: 10,
        scale: 'logarithmic',
        step: 1
      },
      time: {
        defaultValue: 5,
        max: 30,
        min: 2,
        scale: 'logarithmic',
        step: 1
      },
      defaultValue: {
        type: 'items',
        items: 10
      },
      texts: { taskName: language.text.question }
    };
  }
}
