import React from 'react';
import PromiseButton from '../PromiseButton/PromiseButton';
import PulsatingRingButton, {
  PulsatingRingButtonProps
} from './PulsatingRingButton';

export type PulsatingRingPromiseButtonProps = PulsatingRingButtonProps<
  typeof PromiseButton
>;

const PulsatingRingPromiseButton = React.forwardRef(
  (
    props: PulsatingRingPromiseButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    return (
      <PulsatingRingButton component={PromiseButton} {...props} ref={ref} />
    );
  }
);

export default PulsatingRingPromiseButton;
