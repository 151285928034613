import { Client } from '../client/Client';
import { MessageIterator } from '../iterator/MessageIterator';
import { MessageRequest } from '../requests/MessageRequest';
import {
  ConversationId,
  DashProfileUrn,
  EVENT_TYPE,
  EventCreateResponse,
  EventUrn,
  GetMessagesResponse,
  LinkedInEvent,
  LinkedInMessagingMember,
  LinkedInMiniProfile,
  MessageMessageUrn,
  MESSAGING_MEMBER_TYPE,
  MessagingMemberUrn,
  MINI_PROFILE_TYPE,
  MiniProfileUrn
} from 'linkedin-domain-types';
import { Options, trace } from '../client/Utils';

/**
 * Service for interacting with messages
 * @class MessageService the service for interacting with messages
 */
@trace()
export class MessageService {
  /**
   * The client that instantiated this service
   * @private the client that instantiated this service
   */
  private client: Client;

  /**
   * The message requests
   * @private the message requests
   */
  private messageRequests: MessageRequest;

  /**
   * Creates a new MessageService
   * @param client the client that instantiated this service
   */
  constructor(client: Client) {
    this.client = client;
    this.messageRequests = new MessageRequest({
      requestService: client.requestService
    });
  }

  /**
   * Returns an iterator that iterates over all messages in a conversation
   * @param conversationId the id of the conversation
   * @param createdBefore the date to filter by
   * @param options the options to use for the request
   */
  getMessages({
    conversationId,
    createdBefore,
    options
  }: {
    conversationId: ConversationId;
    createdBefore?: Date;
    options: Options;
  }): MessageIterator {
    return new MessageIterator({
      conversationId,
      createdBefore,
      fetchMessages: this.fetchMessages.bind(this),
      options
    });
  }

  /**
   * Returns a page of all messages in a conversation
   * @param conversationId the id of the conversation
   * @param createdBefore the date to filter by
   * @param options the options to use for the request
   */
  getMessagePage({
    conversationId,
    createdBefore,
    options
  }: {
    conversationId: ConversationId;
    createdBefore?: Date;
    options: Options;
  }) {
    return this.fetchMessages({
      conversationId,
      createdBefore,
      options
    });
  }

  /**
   * Sets the emoji reaction on a message
   * @param messageUrn the urn of the message
   * @param emoji the emoji to react with
   * @param status whether to add or remove the reaction
   * @param options the options to use for the request
   */
  setEmojiReaction({
    messageUrn,
    emoji,
    status,
    options
  }: {
    messageUrn: MessageMessageUrn;
    emoji: string;
    status: boolean;
    options: Options;
  }): Promise<boolean> {
    return this.messageRequests.setMessageReaction({
      messageUrn,
      emoji,
      status,
      options
    });
  }

  /**
   * Sends a direct message to a profile
   * @param profileId the id of the profile
   * @param text the text of the message
   * @param options the options to use for the request
   */
  async sendDirectMessage(
    profileId: DashProfileUrn,
    text: string,
    options: Options
  ) {
    const res = await this.messageRequests.sendDirectMessage({
      profileId,
      text,
      options
    });
    if (!res) return null;
    return { ...res.data.value, text };
  }

  /**
   * Sends a message to a conversation
   * @param conversationId the id of the conversation
   * @param text the text of the message
   * @param options the options to use for the request
   */
  async sendMessage(
    conversationId: ConversationId,
    text: string,
    options: Options
  ): Promise<(EventCreateResponse & { text: string }) | null> {
    const res = await this.messageRequests.sendMessage({
      conversationId,
      text,
      options
    });
    if (!res) return null;
    return { ...res.data.value, text };
  }

  /**
   * Fetches messages from a conversation
   * @param conversationId the id of the conversation
   * @param createdBefore the date to filter by
   * @param options the options to use for the request
   * @private fetches messages from a conversation
   */
  private async fetchMessages({
    conversationId,
    createdBefore = new Date(Date.now()),
    options
  }: {
    conversationId: ConversationId;
    createdBefore?: Date;
    options: Options;
  }): Promise<Iterable<LinkedInEvent>> {
    const res = await this.messageRequests.getMessages({
      conversationId,
      createdBefore,
      options
    });
    if (!res) return [];
    return MessageService.parseMessages(res).values();
  }

  /**
   * Parses messages from a response into an array of LinkedInEvents
   * @param res the response
   * @private parses messages from a response into an array of LinkedInEvents
   */
  public static parseMessages(res: GetMessagesResponse): LinkedInEvent[] {
    const mapping: {
      EVENT_TYPE: Map<EventUrn, LinkedInEvent>;
      MINI_PROFILE_TYPE: Map<MiniProfileUrn, LinkedInMiniProfile>;
      MESSAGING_MEMBER_TYPE: Map<MessagingMemberUrn, LinkedInMessagingMember>;
    } = {
      EVENT_TYPE: new Map(),
      MINI_PROFILE_TYPE: new Map(),
      MESSAGING_MEMBER_TYPE: new Map()
    };

    res.included.forEach((i) => {
      // TypeScript ¯\_(ツ)_/¯
      if (i.$type === EVENT_TYPE) {
        mapping.EVENT_TYPE.set(i.entityUrn, i);
      } else if (i.$type === MINI_PROFILE_TYPE) {
        mapping.MINI_PROFILE_TYPE.set(i.entityUrn, i);
      } else if (i.$type === MESSAGING_MEMBER_TYPE) {
        mapping.MESSAGING_MEMBER_TYPE.set(i.entityUrn, i);
      }
    });

    mapping.MESSAGING_MEMBER_TYPE.forEach((v) => {
      const miniProfile = mapping.MINI_PROFILE_TYPE.get(v['*miniProfile']);
      if (miniProfile) v.miniProfile = miniProfile;
    });

    mapping.EVENT_TYPE.forEach((v) => {
      const from = mapping.MESSAGING_MEMBER_TYPE.get(v['*from']);
      if (from) v.from = from;
      v.eventId = v.entityUrn.split(':').pop() ?? '';
      v.createdBefore = v.createdAt;
    });

    return [...mapping.EVENT_TYPE.values()].sort((a, b) => {
      return b.createdAt - a.createdAt;
    });
  }
}
