import { Box, Tooltip, Typography } from '@mui/material';
import { language } from '@/index';
import { useFeatureFlags } from '@/globals/featureFlags';

export default function BetaFlag() {
  const { full_version_released } = useFeatureFlags();

  if (full_version_released && RELEASE_CHANNEL === 'PROD') return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: (theme) => theme.spacing(-5),
        left: (theme) => theme.spacing(-5),
        width: (theme) => theme.spacing(10),
        height: (theme) => theme.spacing(10),
        rotate: '-45deg',
        zIndex: 10000,
        background: (theme) => theme.palette.background.paper,
        boxShadow: (theme) => theme.shadows[3]
      }}>
      <Tooltip
        title={
          <Typography>
            {RELEASE_CHANNEL === 'PROD'
              ? `Dies ist aktuell noch eine BETA Version, es kann also noch zu Fehler
            oder unerwartetem Verhalten kommen. Falls irgendetwas nicht so
            funktioniert, wie du es dir vorstellst, kannst du einfach auf Ava
            klicken und uns eine Nachricht schreiben.`
              : language.text.build_flag_text.replace(
                  '{RELEASE_CHANNEL}',
                  RELEASE_CHANNEL
                )}
          </Typography>
        }>
        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="center"
          height="100%">
          <Typography color="primary">
            {RELEASE_CHANNEL === 'PROD' ? 'BETA' : RELEASE_CHANNEL}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
}
