import { Box, Typography } from '@mui/material';
import { Contact } from '@/data/Classes/Contact';
import { language } from '@/index';

export interface ChatPipelineResultDisplayProps {
  result: boolean | null;
  contact: Contact | null;
}

export default function ChatPipelineResultDisplay(
  props: ChatPipelineResultDisplayProps
) {
  if (!props.contact) return null;

  return (
    <Box p={2} width="100%" boxSizing="border-box">
      {props.result ? (
        <Typography textAlign="center">
          {language.text.contact_is_alredy_customer.replace(
            '{name}',
            props.contact.name
          )}
        </Typography>
      ) : (
        <Typography textAlign="center">
          {language.text.contact_is_no_match.replace(
            '{name}',
            props.contact.name
          )}
        </Typography>
      )}
    </Box>
  );
}
