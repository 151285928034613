import { Objects } from '@idot-digital/generic-helpers';

const DevConfig = {
  linkedInDevTools: false,
  skipLoadNewData: false,
  redoInitialLoad: false,
  showQueryDevTools: false,
  disabledChatCockpitPreloading: false,
  emitWebviewIPCtoMessageBus: false,
  useCloudDevServer: false
} as {
  linkedInDevTools: boolean;
  skipLoadNewData: boolean;
  redoInitialLoad: boolean;
  showQueryDevTools: boolean;
  disabledChatCockpitPreloading: boolean;
  emitWebviewIPCtoMessageBus: boolean;
  useCloudDevServer?: boolean;
};

// prevent accidental use of dev tools in production
// this is a workaround since the RELEASE_CHANNEL variable is set asynchronously in renderer
const reset = () => {
  if (!('RELEASE_CHANNEL' in globalThis)) return;
  if (RELEASE_CHANNEL !== 'DEV') {
    Objects.keys(DevConfig).forEach((key) => {
      DevConfig[key] = false;
    });
  }
};
if ('RELEASE_CHANNEL' in globalThis) {
  reset();
} else {
  const interval = setInterval(() => {
    if (!('RELEASE_CHANNEL' in globalThis)) return;
    clearInterval(interval);
    reset();
  }, 100);
}

export default DevConfig;
