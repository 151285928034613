import { language } from '@/index';
import { AuthWarning } from '@common/AuthManager/AuthErrors';
import MainConfig from '@common/config/MainConfig';
import { PriorityHigh } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { FancyButton } from 'ui-utils';

export interface SSCLoginWarningProps {
  warning: AuthWarning;
  next: () => Promise<void>;
}

export default function SSCLoginWarning(props: SSCLoginWarningProps) {
  const { title, description } = stringifyWarning(props.warning);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        p: 6,
        borderBox: 'box-sizing',
        maxWidth: 900,
        mx: 'auto'
      }}>
      <PriorityHigh color="warning" sx={{ fontSize: '5rem' }} />
      <Typography variant="h6" textAlign="center">
        {title}
      </Typography>
      <Typography variant="body1" textAlign="center">
        {description}
      </Typography>
      <Box display="flex" gap={2}>
        <FancyButton
          fType={{ promise: true }}
          variant="outlined"
          color="primary"
          onClick={() =>
            window.api.send(
              'url:open_ext',
              MainConfig.subscriptionManagementLink
            )
          }>
          {language.text.edit_subscription}
        </FancyButton>
        <FancyButton
          fType={{ promise: true }}
          variant="contained"
          color="primary"
          onClick={() => props.next()}>
          {language.text.lift_off}
        </FancyButton>
      </Box>
    </Box>
  );
}

function stringifyWarning(warning: AuthWarning): {
  title: string;
  description: string;
} {
  switch (warning) {
    case AuthWarning.PAYMENT_FAILED:
      return {
        title: language.text.payment_failed,
        description: language.text.payment_warning_description
      };
    case AuthWarning.SUBSCRIPTION_CANCELED:
      return {
        title: language.text.subscription_cancelled,
        description: language.text.subscription_cancelled_description
      };
    default:
      return {
        title: language.text.unknown_error,
        description: language.text.unknown_error_description
      };
  }
}
