export interface PipelineInfo {
  id: string;
  name: string;
  description: string;
}

export interface PipelineDefinition<T extends string[]> {
  id: number;
  name: string;
  description: string;
  main_flow: PipelineStep<T>[];
  secondary_flows: Record<T[number], PipelineStep<T>[]>;
  results: {
    positive: string;
    negative: string;
  };
}

export type PipelineStepTypes = PipelineStep<string[]>['type'];

export type DisplayPipelineStep<T extends string[]> = Exclude<
  PipelineStep<T>,
  WaitForDayStep | WaitForMessageReceivedStep
>;

export type PipelineStep<T extends string[] = string[]> =
  | MessageStep
  | OptionQuestion<T>
  | DateQuestion
  | WaitForDayStep
  | WaitForMessageReceivedStep
  | BuildRelationship;

export interface AbstractStep {
  id: string;
  name: string;
  description: string;
}

export interface BuildRelationship extends AbstractStep {
  type: 'buildRelationship';
  welcomeMessage: string;
  relationshipMessage: string;
}

export interface MessageStep extends AbstractStep {
  type: 'message';
  templateID: string;
}

export interface WaitForDayStep extends AbstractStep {
  type: 'waitForDay';
}

export interface WaitForMessageReceivedStep extends AbstractStep {
  type: 'waitForMessageReceived';
}

export type Question = DateQuestion | OptionQuestion<string[]>;

export interface DateQuestion extends AbstractStep {
  type: 'question-date';
  question: string;
}

export interface OptionQuestion<T extends string[]> extends AbstractStep {
  type: 'question-options';
  question: string;
  options: OptionQuestionOption<T>[];
}

export interface OptionQuestionOption<T extends string[] = string[]> {
  id: number;
  text: string;
  description: string;
  action: Actions<T> | null;
}

export type Actions<T extends string[]> =
  | 'result:positive'
  | 'result:negative'
  | 'exit-flow'
  | `flow:${T[number]}`;

export enum ServerPipelineStepType {
  RESULT_NEGATIVE = -3,
  RESULT_POSITIVE = -2,
  NoStep = -1,
  BuildRelationship = 1,
  Message = 2,
  WaitForDay = 3,
  WaitForMessageReceived = 4,
  QuestionDate = 5,
  QuestionOptions = 6
}
