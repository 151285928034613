import pino, { LogFn } from 'pino';
import { VERBOSE_LOGS } from './Config';
import { Urn } from 'linkedin-domain-types';
import LinkedIn from '../../lib';

/**
 * Provides a logger instance
 */
const internalLogger: pino.Logger = pino({
  hooks: {
    /**
     * Hook that is called when a log method is called.
     * This hook is used to add the caller to the log message.
     * Detailed caller information is only added in Loglevel *trace*.
     *
     * @param inputArgs the input arguments
     * @param method the method that is called
     * @param level the level of the log method
     */
    logMethod(inputArgs, method, level) {
      const caller =
        Error().stack?.replace(
          /^(?:.*\n)*?(?:.*node_modules[\\/]pino.*\n)+\s*at\s?(\S*)\s.*[\\/](.*)(\.\w+:.*):(?:\n|.)*$/m,
          `${internalLogger.levels.labels[level] === 'trace' ? '$1@$2$3' : '$2'}`
        ) ?? '';
      const newArgs: any[] = [];
      const arg0 = inputArgs.shift() as string | object;
      if (typeof arg0 === 'object') {
        newArgs.push({ caller, ...arg0 });
      } else {
        newArgs.push({ caller });
        newArgs.push(arg0);
      }

      let logType: 'debug' | 'warn' | 'error' | 'info' = internalLogger.levels
        .labels[level] as any;
      if (!['debug', 'warn', 'error', 'info'].includes(logType)) {
        logType = 'warn';
      }
      LinkedIn.logger[logType]?.(...newArgs, ...inputArgs);

      return method.apply(this, newArgs.concat(inputArgs) as Parameters<LogFn>);
    }
  }
});

const keys = ['trace', 'debug', 'info', 'warn', 'error'] as const;
export const Logger = Object.fromEntries(
  keys.map((key) => [
    key,
    (obj: any, msg: string, ...args: any[]) => {
      if (key !== 'trace') {
        LinkedIn.logger[key]?.(obj, msg, ...args);
      }

      internalLogger[key](obj, msg, ...args);
    }
  ])
) as Pick<pino.Logger<never>, 'warn' | 'info' | 'error' | 'debug' | 'trace'>;

/**
 * The options used for methods
 */
export type Options = {
  /**
   * Prioritize the request
   */
  prioritize?: boolean;
};

/**
 * Get cookie value by name
 * @param cookieName name of the cookie
 * @param cookies array of cookies
 */
export function getCookie(cookieName: string, cookies: string[]): string {
  for (const cookie of cookies) {
    const n = cookie.indexOf('=');
    const name = cookie.substring(0, n);
    if (name === cookieName) {
      Logger.trace(`Requested cookie ${cookieName}`);
      return cookie.replace(/^.*?=(.*?);.*/, '$1');
    }
  }
  Logger.trace(`Cookie ${cookieName} not found`);
  return '';
}

/**
 * Generates a random UUID
 *
 * @returns {string} the UUID
 */
export function generateUUID(): string {
  const result = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }
  );
  Logger.trace(`Generated UUID ${result}`);
  return result;
}

/**
 * Generates a random tracking id for LinkedIn
 *
 * @returns {string} a tracking id
 */
export function generateTrackingId(): string {
  const result = Array.from({ length: 16 }, () =>
    String.fromCharCode(Math.floor(Math.random() * 256))
  ).join('');
  Logger.trace(`Generated tracking id ${result}`);
  return result;
}

/**
 * Parses a given collection of elements with given included elements.
 * Basically, this function links the included elements to the elements.
 *
 * @param elements the elements to parse
 * @param included the included elements
 */
export function parseCollectionResponse<
  E,
  I extends { entityUrn: string | Urn }
>(elements: E[] | string[], included: I[]): E[] {
  if (elements.length === 0) {
    return [];
  }
  const _included = (included ?? []).filter((e) => e);
  const mapping = new Map<string, I>(
    _included.map((element: I) => [element.entityUrn as string, element])
  );
  const workingElements =
    typeof elements[0] === 'string'
      ? elements.map((element) => mapping.get(element as string) as E)
      : (elements as E[]);

  for (const includedElement of _included) {
    fixRecursive(includedElement, mapping);
  }

  for (const element of workingElements) {
    fixRecursive(element, mapping);
  }

  return workingElements;
}

function fixRecursive<E>(
  element: E,
  mapping: Map<string, unknown>,
  seen: Set<E> = new Set<E>()
): E {
  if (typeof element !== 'object' || element === null || seen.has(element)) {
    return element;
  }
  seen.add(element);

  if (
    !Object.keys(element).includes('$type') &&
    Object.keys(element).includes('_type')
  ) {
    Object.defineProperty(element, '$type', {
      value: (element as Record<string, unknown>)._type
    });
  }

  for (const [key, value] of Object.entries(element)) {
    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        // @ts-expect-error this is fine because we know that element is an array
        element[key] = value.map((v: E) => fixRecursive(v, mapping, seen));
      } else {
        // @ts-expect-error this is fine because we know that element is an object
        element[key] = fixRecursive<E>(value as E, mapping, seen);
      }
    } else if (key.startsWith('*')) {
      const ref = mapping.get(value as string);
      if (ref) {
        // @ts-expect-error this is fine because we know that element is an object
        element[key.replace('*', '')] = ref;
      }
    }
  }
  return element;
}

/**
 * Finds a property with given name in a nested object structure.
 * Can also handle reference loops.
 *
 * @param obj the object to search in
 * @param name the name of the property to find
 * @param visited optional set of visited objects to prevent infinite loops
 * @returns the property or undefined if not found
 */
export function findProperty<E>(
  obj: Record<string, unknown>,
  name: string,
  visited = new Set<Record<string, unknown>>()
): E | undefined {
  if (visited.has(obj) || typeof obj !== 'object') {
    return undefined;
  }
  visited.add(obj);
  for (const [key, value] of Object.entries(obj)) {
    if (key === name) {
      return value as E;
    }
    if (typeof value === 'object' && value !== null) {
      const result = findProperty<E>(value as Record<string, unknown>, name);
      if (result !== undefined) {
        return result;
      }
    }
  }
  return undefined;
}

/**
 * Task type for the task queue class
 *
 * @property {() => Promise<unknown>} task - The task to execute
 * @property {number} [overrideTimeout] - The timeout to override the default timeout
 */
export interface Task {
  readonly identifier: string;
  overrideTimeout?: number;
  task: (this: Task) => Promise<unknown>;
}

/**
 * A task queue class that executes tasks in a queue with a timeout between each task
 * @class TaskQueue
 * @property {number} execTimeout - The timeout between each task
 */
@trace()
export class TaskQueue {
  constructor(
    private execTimeout: number,
    readonly identifier: string = 'task'
  ) {
    Logger.debug(`Creating ${identifier} queue with timeout ${execTimeout}ms`);
    this.queue = [];
  }

  /**
   * The queue of tasks
   */
  private queue: Task[];

  /**
   * Whether the task queue is currently running
   * @private
   */
  private running = false;

  /**
   * Adds a task to the queue and returns a promise that resolves when the task is done
   * @param identifier - The identifier of the task
   * @param task - The task to add
   * @param addFront - If true, the task will be added to the front of the queue instead of the back (default: false)
   * @param overrideTimeout - The timeout to override the default timeout
   */
  public async add<T>(
    identifier: string,
    task: (this: Task) => Promise<T>,
    {
      overrideTimeout,
      addFront = false
    }: {
      overrideTimeout?: number;
      addFront?: boolean;
    }
  ): Promise<T> {
    if (identifier.length > 50) {
      identifier = `${identifier.slice(0, 24)}..${identifier.slice(-24)}`;
    }
    const res = await new Promise((res, rej) => {
      const _task = {
        identifier,
        async task() {
          Logger.debug(`Running task ${identifier}`);
          try {
            const result = await task.call(this);
            res(result);
          } catch (e) {
            rej(e);
          }
        },
        overrideTimeout
      };

      Logger.debug(
        `Adding task ${identifier} to ${this.identifier} queue to the ${
          addFront ? 'front' : 'back'
        } with timeout ${
          overrideTimeout !== undefined
            ? overrideTimeout
            : `default of ${this.execTimeout}`
        }ms`
      );

      if (addFront) {
        this.queue.unshift(_task);
      } else {
        this.queue.push(_task);
      }

      void this.run();
    });
    return res as T;
  }

  /**
   * Runs the task queue and executes all tasks in the queue
   * @private
   */
  private async run() {
    if (this.running) {
      Logger.debug(
        `${this.identifier} queue is already running, skipping trigger`
      );
      return;
    }
    this.running = true;
    Logger.debug(
      `Starting ${this.identifier} queue with ${this.queue.length} tasks`
    );
    while (this.queue.length > 0) {
      const task = this.queue.shift();
      if (!task) {
        continue;
      }
      await task.task();
      Logger.debug(
        `${this.identifier} queue is now waiting for ${
          task.overrideTimeout ?? this.execTimeout
        }ms`
      );

      await new Promise((res) => {
        setTimeout(res, task.overrideTimeout ?? this.execTimeout);
      });
      Logger.debug(
        `Task ${task.identifier} finished, ${this.queue.length} left`
      );
    }
    Logger.debug(`${this.identifier} queue finished`);
    this.running = false;
  }
}

/**
 * Provides a trace logging decorator that decorates all function properties of a class with a trace log
 */
export function trace(): (
  // eslint-disable-next-line @typescript-eslint/ban-types
  target: Function,
  _context?: ClassDecoratorContext
) => void {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return (target: Function, _context?: ClassDecoratorContext) => {
    Logger.trace(`Decorating ${_context?.name}`);
    for (const key of Object.getOwnPropertyNames(target.prototype)) {
      const descriptor: PropertyDescriptor | undefined =
        Object.getOwnPropertyDescriptor(target.prototype, key);
      if (descriptor === undefined) {
        continue;
      }

      if (typeof descriptor.value === 'function') {
        Logger.trace(`Decorating ${_context?.name}.${key}`);

        const originalMethod = descriptor.value as (
          ...args: unknown[]
        ) => unknown;

        descriptor.value = function (...args: unknown[]) {
          Logger.trace(
            { ...(VERBOSE_LOGS ? args : {}) },
            `-> ${target.name}.${key}()`
          );

          const result = originalMethod.apply(this, args);
          const exitLog = function () {
            Logger.trace(
              {
                ...(VERBOSE_LOGS ? { result } : [])
              },
              `<- ${target.name}.${key}`
            );
          };

          // workaround for async functions
          if (result instanceof Promise) {
            const promise = result.then(exitLog);

            if (typeof promise.catch === 'function') {
              promise.catch((e: unknown) => e);
            }
          } else {
            exitLog();
          }
          return result;
        };
        Object.defineProperty(target.prototype, key, descriptor);
      }
    }
  };
}
