import { Box } from '@mui/material';

export interface MountDomProps {
  element: HTMLElement | null | undefined;
}

export default function MountDOM(props: MountDomProps) {
  return (
    <Box
      key="static"
      display="contents"
      ref={(ref: HTMLDivElement | null) => {
        if (ref) {
          [...ref.children].forEach((c) => {
            // remove all children except the one we want to mount
            if (c !== props.element) c.remove();
          });
          // only mount if not already mounted
          if (props.element && ref.children.length === 0)
            ref.appendChild(props.element);
        }
      }}
    />
  );
}
