import { LinkedInRequestService } from '../services/LinkedInRequestService';
import {
  BlendedSearchFilters,
  BlendedSearchResponse,
  JobSearchFilters,
  SearchType
} from 'linkedin-domain-types';
import { Options, trace } from '../client/Utils';

@trace()
export class SearchRequest {
  /**
   * The request service
   * @private the request service
   */
  private requestService: LinkedInRequestService;

  /**
   * Creates a new SearchRequest
   * @param requestService the request service
   */
  constructor(requestService: LinkedInRequestService) {
    this.requestService = requestService;
  }

  async blendedSearch({
    keywords,
    filters = {},
    start = 0,
    count = 10,
    options
  }: {
    keywords?: string;
    filters?: BlendedSearchFilters;
    start?: number;
    count?: number;
    options: Options;
  }): Promise<BlendedSearchResponse | null> {
    const payload = new URLSearchParams(keywords);

    return this.requestService.get(
      'search/blended',
      options,
      {
        params: {
          start,
          count,
          filters,
          ...(keywords ? { keywords: payload.toString() } : {}),
          origin: 'TYPEAHEAD_ESCAPE_HATCH',
          q: 'all',
          queryContext: {
            spellCorrectionEnabled: true,
            relatedSearchesEnabled: true
          }
        }
      },
      undefined,
      `search${keywords ? keywords.slice(0, 8) : ''}`
    );
  }

  async jobSearch({
    keywords,
    filters = {},
    start = 0,
    count = 10,
    options
  }: {
    keywords?: string;
    filters?: JobSearchFilters;
    start?: number;
    count?: number;
    options: Options;
  }) {
    const payload = new URLSearchParams(keywords);

    return this.requestService.get(
      'search/hits',
      options,
      {
        params: {
          start,
          count,
          filters,
          ...(keywords ? { keywords: payload.toString() } : {}),
          origin: 'JOB_SEARCH_RESULTS_PAGE',
          decorationId:
            'com.linkedin.voyager.deco.jserp.WebJobSearchHitLite-14',
          q: 'jserpFilters',
          queryContext: {
            spellCorrectionEnabled: true,
            primaryHitType: SearchType.JOBS
          }
        }
      },
      undefined,
      `jobSearch${keywords ? keywords.slice(0, 8) : ''}`
    );
  }
}
