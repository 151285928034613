import React from 'react';
import { Box, BoxProps } from '@mui/material';

export interface CenterProps extends BoxProps {
  fullWidth?: boolean;
  fullHeight?: boolean;
}

const Center = React.forwardRef((props: CenterProps, ref) => {
  const { fullWidth, fullHeight, ...rest } = props;
  return (
    <Box
      {...rest}
      ref={ref}
      sx={{
        ...(fullHeight && { height: '100%' }),
        ...(fullWidth && { width: '100%' }),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        ...props.sx
      }}
    />
  );
});

export default Center;
