import React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  Typography
} from '@mui/material';
import { AttachFile, Close, Upload } from '@mui/icons-material';
import { language } from '../../Config';

export interface FileInputProps extends ButtonProps {
  onUploadStart?: () => void;
  onUploaded?: (files: File[]) => void;
}

export default function FileInput(props: FileInputProps) {
  const { onUploadStart, onUploaded, ...buttonProps } = props;

  const [isDraggingFiles, setIsDraggingFiles] = React.useState(false);

  React.useEffect(() => {
    const dragEnter = (event: DragEvent) => {
      setIsDraggingFiles(event.dataTransfer?.types[0] === 'Files');
    };
    const dragLeave = (e: DragEvent) => {
      // check if mouse is outside of window
      if (e.pageX === 0 && e.pageY === 0) setIsDraggingFiles(false);
    };

    document.addEventListener('dragenter', dragEnter, { passive: true });
    document.addEventListener('dragleave', dragLeave, { passive: true });

    return () => {
      document.removeEventListener('dragenter', dragEnter);
      document.removeEventListener('dragleave', dragLeave);
    };
  });

  return (
    <>
      <Button
        {...buttonProps}
        sx={{
          ...buttonProps.sx,
          'position': 'relative',
          'cursor': 'pointer',
          'label, input': {
            ...{
              position: 'absolute',
              inset: 0,
              opacity: 0,
              cursor: 'pointer !important'
            },
            ...(isDraggingFiles && {
              position: 'fixed',
              inset: (theme) => theme.spacing(4),
              zIndex: 101
            })
          },
          'input': {
            ...(!isDraggingFiles && {
              display: 'none'
            })
          }
        }}>
        <AttachFile
          color="action"
          sx={{
            pointerEvents: 'none'
          }}
        />
        <label htmlFor="file-upload">
          <input
            id="file-upload"
            type="file"
            multiple
            onInput={(e) => {
              setIsDraggingFiles(false);
              console.log(e);
            }}
          />
        </label>
      </Button>
      {/* overlay */}
      {isDraggingFiles && (
        <Box
          sx={{
            position: 'fixed',
            inset: (theme) => theme.spacing(4),
            backgroundColor: (theme) => theme.palette.primary.light,
            borderRadius: 2,
            boxShadow: (theme) => theme.shadows[1],
            color: (theme) => theme.palette.primary.contrastText,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 100,
            opacity: 0.9
          }}>
          <Upload
            sx={{
              fontSize: '20vh'
            }}
          />
          <Typography variant="h2" textAlign="center">
            {language.text.upload_files_here}
          </Typography>
          {/* fallback for user to close overlay if not closed automatically */}
          <IconButton
            sx={{
              position: 'absolute',
              top: (theme) => theme.spacing(2),
              right: (theme) => theme.spacing(2)
            }}
            onClick={() => setIsDraggingFiles(false)}>
            <Close />
          </IconButton>
        </Box>
      )}
    </>
  );
}
