import { Box, BoxProps } from '@mui/material';
import { CircularProgress, CircularProgressProps } from '@mui/material';
import RainbowLoadingAnimation, {
  RainbowLoadingAnimationProps
} from './RainbowLoadingAnimation';
import LoadingFloating, { LoadingFloatingProps } from './LoadingFloating';

export type LoadingType = 'circular' | 'rainbow' | 'floating';

export type LoadingProps<Type extends LoadingType = 'circular'> = {
  type?: Type;
  component?: BoxProps['component'];
} & (Type extends 'circular'
  ? CircularProgressProps
  : Type extends 'rainbow'
    ? RainbowLoadingAnimationProps
    : LoadingFloatingProps);

export default function Loading<Type extends LoadingType = 'circular'>(
  props: LoadingProps<Type>
) {
  const { type = 'circular', ...rest } = props;
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      component={props.component}>
      {type === 'circular' ? (
        <CircularProgress {...(rest as CircularProgressProps)} />
      ) : type === 'rainbow' ? (
        <RainbowLoadingAnimation {...(rest as RainbowLoadingAnimationProps)} />
      ) : (
        <LoadingFloating {...(rest as LoadingFloatingProps)} />
      )}
    </Box>
  );
}
