import React from 'react';
import { Contact } from '@/data/Classes/Contact';
import { language } from '@/index';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { FancyButton } from 'ui-utils';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

export interface SkipUntilDialogProps {
  contact: Pick<Contact, 'name' | 'firstname'>;
  onFinish: (date: Date) => void | Promise<void>;
  open: boolean;
  onClose: () => void;
  onSkip?: () => Promise<void> | void;
}

export default function SkipUntilDialog(props: SkipUntilDialogProps) {
  const [skipDate, setSkipDate] = React.useState<Dayjs | null>(
    // now in one week
    dayjs(new Date()).add(1, 'week')
  );

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{language.text.skip_name(props.contact.name)}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {language.text.skip_name_until(props.contact.firstname)}
        </DialogContentText>
        <Box display="flex" justifyContent="center" my={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
              format="DD.MM.YYYY"
              value={skipDate}
              onChange={(newValue) => setSkipDate(newValue)}
            />
          </LocalizationProvider>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>{language.text.cancel}</Button>
        {props.onSkip && (
          <>
            <Box flexGrow={1} />
            <FancyButton
              variant="contained"
              color="primary"
              fType={{
                promise: true
              }}
              onClick={async () => {
                await props.onSkip?.();
              }}>
              {language.text.skip_once}
            </FancyButton>
          </>
        )}
        <FancyButton
          variant="contained"
          color="primary"
          fType={{
            promise: true
          }}
          disabled={!skipDate}
          onClick={async () => {
            if (!skipDate) return;
            await props.onFinish(skipDate.toDate());
          }}>
          {language.text.skip}
        </FancyButton>
      </DialogActions>
    </Dialog>
  );
}
