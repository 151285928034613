import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { DualMonthDayPicker } from 'ui-utils';

export interface QuestionDateProps extends BoxProps {
  date: Date | undefined | null;
  onDateChange: (date: Date) => void;
}

export default function QuestionDate(props: QuestionDateProps) {
  const { date, onDateChange } = props;
  const [value, setValue] = React.useState<Date | null>(
    date ? new Date(date) : new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
  );

  const setDateValue = (date: Date | null) => {
    if (date) {
      setValue(date);
      onDateChange(date);
    }
  };

  return (
    <Box
      sx={{
        '.MuiTypography-root': {
          display: 'none'
        },
        '.MuiPickersDay-today': {
          '&:not(.Mui-selected)': {
            background: (theme) => theme.palette.text.disabled
          }
        },
        '.MuiPickerStaticWrapper-content': {
          background: (theme) => theme.palette.background.default,
          borderRadius: 2
        },
        'mt': 2
      }}>
      <DualMonthDayPicker
        sx={{
          borderRadius: 2
        }}
        date={value}
        onDateChange={setDateValue}
        meetings={[]}
      />
    </Box>
  );
}
