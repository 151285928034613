import React from 'react';
import Auth from '@common/AuthManager/Auth.renderer';
import { AuthError, AuthWarning } from '@common/AuthManager/AuthErrors';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { SSC_LOGIN_PATHS } from '@/globals/Paths';
import SSCCodeLogin from './Login/SSCCodeLogin';
import SSCLoginLoading from './Loading/SSCLoginLoading';
import Logger from 'electron-log';
import SSCLoginHUD from './HUD/SSCLoginHUD';
import SSCLoginError from './Error/SSCLoginError';
import SSCLoginWarning from './Warning/SSCLoginWarning';
import StartSSC from './StartNow/StartSSC';
import AppActions from '@/data/DataServer/App';

export default function SSCLogin() {
  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = React.useState(Auth.sscLoggedIn);

  const [error, _setError] = React.useState<AuthError | null>(null);
  const setError = (error: AuthError) => {
    _setError(error);
    navigate(SSC_LOGIN_PATHS.Error);
  };
  const [warning, setWarning] = React.useState<AuthWarning | null>(null);

  // initially check if user is logged in
  React.useEffect(() => {
    (async () => {
      try {
        const { error, warning, loggedIn } = await Auth.checkAccountValidity();
        if (!mounted.current) return;

        if (error) {
          setError(error);
          return;
        }

        // save warning to display later
        if (warning) {
          setWarning(warning);
        }

        setLoggedIn(loggedIn);
        if (loggedIn) {
          navigate(SSC_LOGIN_PATHS.Start);
        } else {
          navigate(SSC_LOGIN_PATHS.Login);
        }
      } catch (e) {
        Logger.error(
          '[SSCLogin:initialLoginCheck] Error while checking account validity',
          e
        );
        setError(AuthError.UNKNOWN_ERROR);
      }
    })();
  }, []);

  const onFinishedLoginProcess = async () => {
    try {
      const token = Auth.getRefreshToken();
      if (!token) {
        setError(AuthError.INVALID_REFRESH_TOKEN);
        return;
      }

      // save time the user started the app to remind them to restart after 24h
      AppActions.saveStartTime();

      // with this call the main thread closes this window and starts the main app
      await window.api.send('ssc:logged-in', token);
    } catch (e) {
      Logger.error('[SSCLogin:finishedLoginProcess] Error while logging in', e);
      setError(AuthError.UNKNOWN_ERROR);
    }
  };

  return (
    <SSCLoginHUD loggedIn={loggedIn}>
      <Routes>
        <Route index element={<SSCLoginLoading />} />
        <Route
          path={SSC_LOGIN_PATHS.Login}
          element={
            <SSCCodeLogin
              onError={setError}
              onWarning={setWarning}
              next={() => {
                setLoggedIn(true);
                navigate(SSC_LOGIN_PATHS.Start);
              }}
            />
          }
        />
        <Route
          path={SSC_LOGIN_PATHS.Start}
          element={
            <StartSSC
              next={() => {
                if (warning) {
                  navigate(SSC_LOGIN_PATHS.Warning);
                } else {
                  onFinishedLoginProcess();
                }
              }}
            />
          }
        />
        <Route
          path={SSC_LOGIN_PATHS.Error}
          element={
            <SSCLoginError
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we only route there if error is set
              error={error!}
              backToLogin={() => navigate(SSC_LOGIN_PATHS.Login)}
              onError={setError}
            />
          }
        />
        <Route
          path={SSC_LOGIN_PATHS.Warning}
          element={
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we only route there if warning is set
            <SSCLoginWarning warning={warning!} next={onFinishedLoginProcess} />
          }
        />
      </Routes>
    </SSCLoginHUD>
  );
}
