import { Box, BoxProps, Theme, useTheme } from '@mui/material';
import React from 'react';

export interface CircularProgressIconProps
  extends Omit<BoxProps<'svg'>, 'component' | 'color'> {
  /**
   * The percentage of the circle that is filled, between 0 and 1.
   */
  percentage: number;
  color?: string | ((theme: Theme) => string);
}

const CircularProgressIcon = React.forwardRef<
  SVGSVGElement,
  CircularProgressIconProps
>((props, ref) => {
  const { percentage, color, ...boxProps } = props;
  const theme = useTheme();
  return (
    <Box
      {...boxProps}
      component="svg"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}>
      <circle
        cx="50"
        cy="50"
        r="38"
        strokeLinecap="round"
        stroke={theme.palette.background.paper}
        strokeWidth={16}
        fill="none"
        pathLength={10}
      />
      <circle
        cx="50"
        cy="50"
        r="38"
        strokeLinecap="round"
        stroke={
          color
            ? typeof color === 'function'
              ? color(theme)
              : color
            : theme.palette.primary.main
        }
        strokeWidth={16}
        fill="none"
        pathLength={10}
        strokeDasharray={`${Math.max(percentage - 0.05, 0) * 10}, ${10 - Math.max(percentage - 0.05, 0) * 10}`}
        strokeDashoffset={2.5}
      />
    </Box>
  );
});

export default CircularProgressIcon;
