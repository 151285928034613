import { LanguageTexts } from './LanguageTexts';

export interface GenericLanguageManager {
  text: LanguageTexts;
  getLanguage(): string;
}

let language: GenericLanguageManager = {
  text: {} as any,
  getLanguage: () => 'en'
};
let returnPath = '/';

const FocusModeSchedulerConfig = {
  setLanguageManager(manager: GenericLanguageManager) {
    language = manager;
  },
  setReturnPath(path: string) {
    returnPath = path;
  }
};

export default FocusModeSchedulerConfig;
export { language, returnPath };
