import { Box, BoxProps } from '@mui/material';
import React from 'react';
import ManagedRelationshipMenu from './ManagedRelationshipMenu';
import { Contact } from '@/data/Classes/Contact';
import { ReplayOutlined, FastForward } from '@mui/icons-material';
import { language } from '@/index';
import { PipelineStep } from '@common/PipelineManager/PipelineTypes';
import { Pipelines } from '@common/PipelineManager/Pipelines';
import { FancyButton } from 'ui-utils';

export interface RelationshipMenuProps extends Omit<BoxProps, 'onSelect'> {
  steps?: PipelineStep[] | null;
  currentStep?: PipelineStep | null;
  contact?: Contact;
  goBackToCategorySelector?: () => void;
  onComplete?: (step: PipelineStep) => Promise<void> | void;
  onSelect?: (step: PipelineStep) => void | Promise<void>;
  completeText?: string;
  hideError?: () => void;
  hasError?: boolean;
  title?: string;
  tooltipPrefix?: string;
  readonly?: boolean;
  disabled?: boolean;
}

export default function RelationshipMenu(props: RelationshipMenuProps) {
  const {
    steps: propsSteps,
    currentStep,
    contact,
    goBackToCategorySelector,
    onComplete,
    onSelect,
    completeText,
    hideError,
    hasError,
    title,
    tooltipPrefix,
    readonly,
    disabled,
    ...boxProps
  } = props;
  const [selectedStep, setSelectedStep] = React.useState<PipelineStep | null>(
    currentStep ?? null
  );
  React.useEffect(() => {
    setSelectedStep(currentStep ?? null);
  }, [currentStep]);

  const steps = React.useMemo(() => {
    return Pipelines.getCurrentStepsToDisplay(currentStep?.id).map((s) => ({
      ...s,
      description: tooltipPrefix
        ? `${tooltipPrefix} ${s.description}`
        : s.description
    }));
  }, [currentStep]);

  return (
    <ManagedRelationshipMenu
      {...boxProps}
      title={title}
      goBackToCategorySelector={goBackToCategorySelector}
      active={selectedStep ?? currentStep}
      listItem={steps}
      setActive={(item) => {
        setSelectedStep(item);
        if (item) return onSelect?.(item);
      }}
      readonly={readonly}>
      {onComplete && (
        <Box display="flex" justifyContent="flex-end" sx={{ width: '100%' }}>
          {hasError ? (
            <FancyButton
              size="large"
              variant="contained"
              fullWidth
              color="error"
              endIcon={<ReplayOutlined />}
              onClick={() => hideError && hideError()}>
              {language.text.generic_error_try_again}
            </FancyButton>
          ) : (
            <FancyButton
              size="large"
              fType={{ promise: true, pulsating: 'default' }}
              animations={{
                disableElevation: true
              }}
              fullWidth
              variant="contained"
              disabled={!selectedStep || disabled}
              startIcon={<FastForward />}
              onClick={() => {
                if (!selectedStep) return;
                return onComplete?.(selectedStep);
              }}>
              {completeText || language.text.continue}
            </FancyButton>
          )}
        </Box>
      )}
    </ManagedRelationshipMenu>
  );
}
