import React from 'react';
import { Box, Typography } from '@mui/material';
import MainConfig from '@common/config/MainConfig';
import { language } from '@/index';
import { GenericVideoPlayer } from 'ui-utils';
import posthog from 'posthog-js';

export interface LoginVideoProps {
  days: number | null;
}

export default function LoginVideo(props: LoginVideoProps) {
  const currentVideo = React.useMemo(() => {
    if (props.days === null) return null;
    return MainConfig.videos.login[props.days];
  }, [props.days]);

  React.useEffect(() => {
    if (!currentVideo) return;
    posthog.capture(`login-video-started-${currentVideo}`);
  }, [currentVideo]);

  if (currentVideo)
    return (
      <Box display="flex" alignItems="center" height="100%">
        <GenericVideoPlayer
          disableAutoPlay={true}
          videoID={currentVideo}
          onEnd={() => {
            posthog.capture(`login-video-watched-${currentVideo}`);
          }}
        />
      </Box>
    );

  return (
    <Box display="flex" alignItems="center" height="100%">
      <Box
        sx={{
          maxWidth: '100%',
          margin: 'auto',
          aspectRatio: '16 / 9',
          border: (theme) => `1px solid ${theme.palette.primary.main}`,
          boxShadow: (theme) => theme.shadows[3],
          background: (theme) => theme.palette.background.paper,
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 2,
          p: 2
        }}>
        <Typography variant="h6" textAlign="center">
          {language.text.today_no_video}
        </Typography>
        <Typography variant="body1" textAlign="center">
          {language.text.come_back_tomorrow}
        </Typography>
      </Box>
    </Box>
  );
}
