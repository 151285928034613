import { Box, BoxProps } from '@mui/material';
import './Fireworks.css';

export interface FireworksProps extends BoxProps {}

const AMOUNT = 10;
const ANIMATION_DURATION = 4000;

export default function Fireworks(props: FireworksProps) {
  const { ...rest } = props;
  return (
    <Box position="relative" {...rest}>
      {Array.from({ length: AMOUNT }, (_, i) => (
        <Box
          className="firework"
          key={i}
          sx={{
            '--x': Math.round(Math.random() * 60 - 25).toString() + 'vmin',
            '--y': '-' + Math.round(Math.random() * 50).toString() + 'vmin',
            '--finalSize':
              Math.round(Math.random() * 50 + 20).toString() + 'vmin',
            'left': Math.round(Math.random() * 80 + 10).toString() + '%',
            'top': Math.round(Math.random() * 50 + 25).toString() + '%',
            'animationDelay':
              Math.round(
                i * 20 * (ANIMATION_DURATION / (20 * AMOUNT)) - 1000
              ).toString() + 'ms'
          }}
        />
      ))}
    </Box>
  );
}
