import { APP_PATHS, FOCUS_MODE_PATHS } from '@/globals/Paths';
import { language } from '@/index';
import UsageTrendsMonitor, {
  UsageTrendsMonitorEvents
} from '@common/UsageTrendsMonitor/UsageTrendsMonitor.renderer';
import { Warning } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import FocusModeScheduler from 'focus-mode-scheduler/src/Scheduler';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { FancyButton, useEventEmitter } from 'ui-utils';

export default function UsageWarningDialog() {
  const { pathname } = useLocation();
  const inFocusMode = React.useMemo(
    () =>
      pathname.startsWith(`/${APP_PATHS.Focus}`) &&
      !pathname.startsWith(`/${APP_PATHS.Focus}/${FOCUS_MODE_PATHS.Start}`),
    [pathname]
  );

  const [open, setOpen] = React.useState(false);
  const [warningData, setWarningData] = React.useState<
    UsageTrendsMonitorEvents['usageWarning'] | null
  >(null);

  useEventEmitter(UsageTrendsMonitor, {
    usageWarning(data: UsageTrendsMonitorEvents['usageWarning']) {
      if (!data.firstWarning) return;
      setWarningData(data);
      setOpen(true);
    }
  });

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Warning
        color="error"
        sx={{ fontSize: '4rem', mx: 'auto', pt: 2, mb: -2 }}
      />
      <DialogTitle textAlign="center">
        {language.text.usage_warning}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {warningData
            ? language.text[`usage_warning_${warningData.category}` as const](
                warningData.count
              )
            : ''}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {inFocusMode ? (
          <>
            <Button
              onClick={() => setOpen(false)}
              color="warning"
              variant="contained">
              {language.text.continue_anyways}
            </Button>
            <FancyButton
              fType={{ promise: true }}
              onClick={async () => {
                const scheduler = FocusModeScheduler.getCurrentInstance();
                if (!scheduler) return;
                await scheduler.skipCategory();
                setOpen(false);
              }}
              color="primary"
              variant="contained">
              {language.text.skip_step}
            </FancyButton>
          </>
        ) : (
          <Button
            onClick={() => setOpen(false)}
            color="primary"
            variant="contained">
            {language.text.ok}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
