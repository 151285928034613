import { language } from '@/index';
import LinkedInLoadingManager, {
  LoaderType
} from '@common/LoadingManager/LinkedInLoadingManager.renderer';
import { Info } from '@mui/icons-material';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from '@mui/material';
import React from 'react';

export default function LinkedInLoadingSettings() {
  const [loadingType, setLoadingType] = React.useState<LoaderType>(
    LinkedInLoadingManager.getLoadingType()
  );
  return (
    <Box>
      <Typography variant="h4">{language.text.loading_settings}</Typography>
      <RadioGroup
        value={loadingType}
        onChange={(e) => {
          const type = e.target.value as LoaderType;
          setLoadingType(type);
          LinkedInLoadingManager.setLoadingType(type);
        }}>
        <FormControlLabel
          value="webview"
          control={<Radio />}
          label={
            <>
              {language.text.safe_loading}
              <Tooltip
                title={language.text.safe_loading_description}
                placement="right">
                <Info
                  fontSize="small"
                  color="disabled"
                  sx={{ verticalAlign: 'sub', ml: 1 }}
                />
              </Tooltip>
            </>
          }
        />
        <FormControlLabel
          value="api"
          control={<Radio />}
          label={
            <>
              {language.text.api_loading}
              <Tooltip
                title={language.text.api_loading_description}
                placement="right">
                <Info
                  fontSize="small"
                  color="disabled"
                  sx={{ verticalAlign: 'sub', ml: 1 }}
                />
              </Tooltip>
            </>
          }
        />
      </RadioGroup>
    </Box>
  );
}
