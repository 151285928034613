import React from 'react';
import {
  Backdrop,
  Box,
  BoxProps,
  Typography,
  useMediaQuery,
  Theme,
  Divider
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

export interface TaskHeaderDrawerProps {
  text: React.ReactNode;
  defaultOpenMode?: 'auto' | 'full';
  onOpen?: (openMode: 'auto' | 'full' | 'none') => void;
  children?: React.ReactNode;
  hideOpenIcon?: boolean;
  boxProps?: BoxProps;
  disableFullBarClickable?: boolean;
  openMode?: 'auto' | 'full' | 'none';
  /**
   * If custom elements are injected the height can change and needs to be adjusted (-> spacer)
   */
  height?: string;
}

export default function TaskHeaderDrawer(props: TaskHeaderDrawerProps) {
  const {
    text,
    defaultOpenMode = 'auto',
    onOpen,
    children,
    hideOpenIcon,
    openMode,
    height
  } = props;
  const [open, setOpen] = React.useState<'none' | 'auto' | 'full'>('none');
  const small = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const openTab = () => {
    if (open === 'none') {
      setOpen(defaultOpenMode);
      onOpen?.(defaultOpenMode);
    } else {
      setOpen('none');
      onOpen?.('none');
    }
  };

  React.useEffect(() => {
    if (openMode) setOpen(openMode);
  }, [openMode]);

  return (
    <>
      <Box
        {...props.boxProps}
        sx={{
          position: 'absolute',
          width: (theme) => `calc(100% - ${theme.spacing(30)})`,
          left: (theme) => theme.spacing(10),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height:
            open === 'full'
              ? (theme) => `calc(100vh - 150px - ${theme.spacing(2)})`
              : open === 'auto'
                ? 'auto'
                : (height ?? (small ? '28px' : '38px')),
          backgroundColor: 'secondary.main',
          px: 5,
          // boxSizing: 'border-box',
          borderBottomLeftRadius: (theme) => theme.spacing(4),
          borderBottomRightRadius: (theme) => theme.spacing(4),
          zIndex: 63,
          maxHeight: 'calc(100vh - 120px)',
          ...props.boxProps?.sx
        }}>
        <Box
          sx={{
            display: open === 'full' || open === 'auto' ? 'flex' : 'none',
            flexWrap: 'wrap',
            height: '100%',
            width: '100%',
            overflowY: 'auto',
            pb: 2
          }}>
          {children}
        </Box>
        <Divider sx={{ width: '100%' }} orientation="horizontal" />
        <Box
          onClick={!props.disableFullBarClickable ? openTab : undefined}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            cursor: !props.disableFullBarClickable ? 'pointer' : undefined,
            alignItems: 'center'
          }}>
          {open === 'none' &&
            (typeof text === 'string' ? (
              <Typography
                variant="body1"
                sx={{
                  fontSize: {
                    lg: '18px',
                    md: '14px'
                  },
                  fontWeight: 'bold',
                  color: '#44444F',
                  mr: 1
                }}>
                {text}
              </Typography>
            ) : (
              text
            ))}
          {!hideOpenIcon && (
            <ExpandMore
              sx={{
                // transition: (theme) => theme.transitions.create('transform'),
                fontSize: '2em',
                ...(open !== 'none' && { transform: 'rotate(180deg)' })
              }}
            />
          )}
        </Box>
      </Box>
      <Box
        height={(theme) =>
          `calc(${(height ?? small) ? '30px' : '38px'} + ${theme.spacing(2)})`
        }
      />
      {open !== 'none' && (
        <Backdrop
          open
          sx={{ position: 'fixed', zIndex: 62 }}
          onClick={() => setOpen('none')}
        />
      )}
    </>
  );
}
