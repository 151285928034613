import { Box, BoxProps } from '@mui/material';

export interface HUDSideProps extends Pick<BoxProps, 'sx'> {
  position?: 'left' | 'right';
}

export default function HUDSide(props: HUDSideProps) {
  const { position } = props;

  return (
    <Box
      sx={{
        'zIndex': 0,
        'position': 'fixed',
        'bottom': 0,
        'top': '140px',
        'left': position === 'left' ? -100 : 'unset',
        'right': position === 'right' ? -100 : 'unset',
        'width': '140px',
        'height': '60%',
        'borderRadius': '25px',
        'opacity': 0.8,
        'transform': `rotate(${position == 'left' ? -8 : 8}deg)`,
        'background': (theme) => theme.palette.hud.light,
        '&:after': {
          display: 'block',
          content: '" "',
          width: 0,
          height: 0,
          borderBottomColor: (theme) => theme.palette.hud.light,
          borderRightColor: (theme) =>
            position === 'left' ? theme.palette.hud.light : 'transparent',
          borderLeftColor: (theme) =>
            position === 'right' ? theme.palette.hud.light : 'transparent',
          borderTopColor: 'transparent',
          borderStyle: 'solid',
          borderBottomWidth: '25px',
          borderRightWidth: `${position === 'right' ? 30 : 60}px`,
          borderLeftWidth: `${position === 'right' ? 60 : 30}px`,
          borderTopWidth: '50px',
          position: 'absolute',
          transform: 'rotate(180deg)',
          right: `${position === 'right' ? 37 : 15}px`,
          bottom: `${position === 'right' ? -72.5 : -74.5}px`,
          opacity: 0.8
        },
        ...props.sx
      }}></Box>
  );
}
