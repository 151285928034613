import { Theme } from '@emotion/react';
import { Box, SxProps, Typography } from '@mui/material';
import React from 'react';

export interface CircularSelectProps {
  value?: number | null;
  onChange?: (value: number) => void;
  onSelect?: (value: number) => void;
  label?: (value: number) => React.ReactNode;
  sx?: SxProps<Theme>;
  step?: number;
  scale?: 'linear' | 'logarithmic';
  min?: number;
  max?: number;
  lang?: string;
  readonly?: boolean;
}

export default function CircularSelect(props: CircularSelectProps) {
  const {
    min = 0,
    max = 1,
    scale = 'linear',
    step = 0.01,
    lang = 'de'
  } = props;

  const convertPercentage = React.useCallback(
    (percentage: number) => {
      if (scale === 'logarithmic') percentage **= 2;
      const value = min + percentage * (max - min);
      return Math.round(value / step) * step;
    },
    [min, max, scale]
  );
  const toPercentage = React.useCallback(
    (value: number) => {
      let percentage = (value - min) / (max - min);
      if (scale === 'logarithmic') percentage = Math.sqrt(percentage);
      return percentage;
    },
    [min, max, scale]
  );

  const [percentage, setPercentage] = React.useState(props.value ?? 0);
  // ref to access the current value in the useEffect
  const percentageRef = React.useRef(percentage);
  // send onChange event when value changes
  React.useEffect(() => {
    percentageRef.current = percentage;

    if (props.onChange) props.onChange(convertPercentage(percentage));
  }, [percentage, convertPercentage]);
  // keep value in sync with props
  React.useEffect(() => {
    if (props.value !== undefined && props.value !== null)
      setPercentage(toPercentage(props.value));
  }, [props.value]);

  const ref = React.useRef<HTMLDivElement>(null);
  const mouseDown = React.useRef(false);

  const onMouseUp = React.useCallback(() => {
    if (mouseDown.current)
      props.onSelect?.(convertPercentage(percentageRef.current));
    mouseDown.current = false;
  }, [props.onSelect, convertPercentage]);
  const selectFromPosition = React.useCallback(
    (e: Pick<MouseEvent, 'clientX' | 'clientY'>, sendOnSelect = false) => {
      if (!ref.current) return;
      const elementPos = ref.current.getBoundingClientRect();
      const middle = {
        x: elementPos.left + elementPos.width / 2,
        y: elementPos.top + elementPos.height / 2
      };
      const angle = Math.atan2(e.clientY - middle.y, e.clientX - middle.x);
      const percentage = (angle / Math.PI / 2 + 1.25) % 1;

      setPercentage(percentage);
      if (sendOnSelect) props.onSelect?.(convertPercentage(percentage));
    },
    [props.onSelect, convertPercentage]
  );
  const onMouseMove = React.useCallback(
    (e: MouseEvent) => {
      if (!mouseDown.current) return;
      selectFromPosition(e);
    },
    [selectFromPosition]
  );

  // mount global listeners
  React.useEffect(() => {
    if (props.readonly) return;
    document.addEventListener('mousemove', onMouseMove, {
      capture: false,
      passive: true
    });
    document.addEventListener('mouseup', onMouseUp, {
      capture: false,
      passive: true
    });

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [onMouseMove, onMouseUp, props.readonly]);

  return (
    <Box
      sx={{
        ...props.sx,
        aspectRatio: 1,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        m: 1,
        borderRadius: '50%',
        boxShadow: 1,
        background: (theme) => theme.palette.background.paper
      }}>
      {/* label + spacing */}
      <Box
        sx={{
          m: 2,
          p: 1.5,
          boxSizing: 'border-box',
          borderRadius: '50%',
          aspectRatio: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          zIndex: 1,
          minWidth: (theme) => theme.spacing(12),
          minHeight: (theme) => theme.spacing(12)
        }}>
        {props.label ? (
          (() => {
            const label = props.label(convertPercentage(percentage));
            if (typeof label === 'string' || typeof label === 'number')
              return <Typography textAlign="center">{label}</Typography>;
            return label;
          })()
        ) : (
          <Typography textAlign="center">
            {(
              Math.round(convertPercentage(percentage) * 100) / 100
            ).toLocaleString(lang)}
          </Typography>
        )}
      </Box>
      {/* selector */}
      <Box
        ref={ref}
        onMouseDown={
          props.readonly
            ? undefined
            : (e) => {
                mouseDown.current = true;
                e.preventDefault();
              }
        }
        onClick={
          props.readonly ? undefined : (e) => selectFromPosition(e, true)
        }
        sx={{
          position: 'absolute',
          inset: 0,
          borderRadius: '50%',
          color: (theme) => theme.palette.primary.main,
          cursor: props.readonly ? undefined : 'pointer',
          zIndex: 0,
          overflow: 'hidden'
        }}>
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle
            cx="50"
            cy="50"
            r="45"
            strokeLinecap="round"
            stroke="currentColor"
            strokeWidth="10"
            fill="none"
            pathLength={10}
            strokeDasharray={`${percentage * 10}, ${10 - percentage * 10}`}
            strokeDashoffset={2.5}
          />
          <circle
            cx="50"
            cy="50"
            r="45"
            opacity={0.2}
            strokeLinecap="round"
            stroke="currentColor"
            strokeWidth="10"
            fill="none"
            pathLength={10}
          />
        </svg>
      </Box>
    </Box>
  );
}
