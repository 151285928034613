import { LinkedInRequestService } from '../services/LinkedInRequestService';
import { Options } from '../client/Utils';
import { PaginatedFeedResponse } from 'linkedin-domain-types';

export class FeedRequest {
  constructor(readonly requestService: LinkedInRequestService) {}

  public async getPaginatedFeed(
    start: number,
    count: number,
    options: Options
  ) {
    return this.requestService.get<PaginatedFeedResponse>(
      'feed/updatesV2',
      options,
      {
        params: {
          count,
          start,
          q: 'chronFeed'
        }
      },
      undefined,
      'feedFetchV2'
    );
  }
}
