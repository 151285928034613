import { LinkedInEvent, ReactionSummary } from './Messaging';
import { RealtimeConversation, TabBadge } from './RealtimeTypes';
import { ConversationUrn, EventUrn, MemberUrn, MiniProfileUrn } from './Urns';
import { Attachment } from '../domain/PublicTypes';

export enum Event {
  ALL = 'all',
  MESSAGE = 'message',
  READ = 'read',
  PING = 'heartbeat',
  LOGIN = 'login',
  REALTIME = 'realtime',
  CONNECTION = 'connection',
  TAB_BADGE_UPDATE = 'tabBadgeUpdate',
  TYPING = 'typing',
  CONVERSATION = 'conversation',
  QUICK_REPLY = 'quickReply',
  REACTION_SUMMARY = 'reactionSummary',
  FAILED_LOGIN = 'failedLogin',
  LOGOUT = 'logout',
  CLOSE = 'close'
}

export interface ApiEvent<T extends Event> {
  type: T;
}

export interface PingEvent extends ApiEvent<Event.PING> {
  type: Event.PING;
}

export interface LoginEvent extends ApiEvent<Event.LOGIN> {
  type: Event.LOGIN;
}

export interface FailedLoginEvent extends ApiEvent<Event.FAILED_LOGIN> {
  type: Event.FAILED_LOGIN;
}

export interface MessageEvent extends ApiEvent<Event.MESSAGE> {
  type: Event.MESSAGE;
  unreadConversationsCount: number;
  conversationUnreadCount: number;
  conversationLastActivityAt: number;
  previousEventInConversationUrn: EventUrn;
  event: LinkedInEvent;
  attachments: Attachment[];
}

export interface RealtimeEvent extends ApiEvent<Event.REALTIME> {
  type: Event.REALTIME;
  data: object;
}

export interface ConnectionEvent extends ApiEvent<Event.CONNECTION> {
  type: Event.CONNECTION;
  topics: string[];
}

export interface TabBadgeUpdateEvent extends ApiEvent<Event.TAB_BADGE_UPDATE> {
  type: Event.TAB_BADGE_UPDATE;
  badges: TabBadge[];
}

export interface ReadEvent extends ApiEvent<Event.READ> {
  type: Event.READ;
  fromEntity: MiniProfileUrn;
  fromParticipant: MemberUrn;
  seenAt: number;
  message: EventUrn;
}

export interface TypingEvent extends ApiEvent<Event.TYPING> {
  type: Event.TYPING;
  from: MiniProfileUrn;
  conversation: ConversationUrn;
}

export interface ConversationEvent extends ApiEvent<Event.CONVERSATION> {
  type: Event.CONVERSATION;
  starred: boolean;
  entityUrn: ConversationUrn;
  unreadConversationsCount: number;
  conversation: RealtimeConversation;
}

export interface QuickReplyEvent extends ApiEvent<Event.QUICK_REPLY> {
  type: Event.QUICK_REPLY;
  message: EventUrn;
  quickReplies: string[];
}

export interface ReactionSummaryEvent extends ApiEvent<Event.REACTION_SUMMARY> {
  type: Event.REACTION_SUMMARY;
  message: EventUrn;
  actor: MiniProfileUrn;
  reactionAdded: boolean;
  reactionSummary: ReactionSummary;
}
