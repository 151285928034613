// Instantly redirects to given path
import { Box } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { Navigate } from 'react-router-dom';

export interface RedirectProps {
  to: string;
}

export default function Redirect(props: RedirectProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%'
      }}>
      <Navigate to={props.to} relative="path" />
      <CircularProgress />
    </Box>
  );
}
