import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Clear, Delete, Info } from '@mui/icons-material';
import AffiliateActions from '@/data/DataServer/Affiliate';
import { language } from '@/index';
import { DiscountCoupon } from '@common/types/ApiTypes';
import { CopyTextButton, DelayedSkeleton, FancyButton } from 'ui-utils';
import { Money } from '@idot-digital/generic-helpers';

export default function AffiliateDiscountCodes() {
  const { discountCoupons, status: discountCodeStatus } =
    AffiliateActions.useDiscountCodes();
  const { appPrice, status: appPriceStatus } = AffiliateActions.useAppPrice();

  const [search, setSearch] = React.useState('');

  const filiteredDiscountCodes = React.useMemo(() => {
    if (!discountCoupons) return [];
    return discountCoupons
      .filter(
        (code) =>
          code.code.toLowerCase().includes(search.toLowerCase()) ||
          code.name.toLowerCase().includes(search.toLowerCase())
      )
      .map((code) => ({
        ...code,
        provision: appPrice
          ? (0.5 - code.percentage_off / 100) * appPrice.amount
          : 0
      }))
      .sort((a, b) => {
        const nameCompare = a.name.localeCompare(b.name);
        if (a.active) {
          if (b.active) return nameCompare;
          else return -1;
        } else {
          if (b.active) return 1;
          else return nameCompare;
        }
      });
  }, [discountCoupons, search]);

  const loading =
    discountCodeStatus === 'loading' || appPriceStatus === 'loading';

  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [codeToDelete, setCodeToDelete] = React.useState<DiscountCoupon | null>(
    null
  );

  return (
    <Box
      sx={{
        width: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Box>
        <TextField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          variant="standard"
          label={language.text.search}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => setSearch('')}
                sx={{ visibility: Boolean(search) ? undefined : 'hidden' }}>
                <Clear />
              </IconButton>
            )
          }}
        />
      </Box>
      <TableContainer sx={{ width: '100%' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{language.text.active}</TableCell>
              <TableCell>{language.text.name}</TableCell>
              <TableCell>{language.text.code}</TableCell>
              <TableCell>{language.text.discount}</TableCell>
              <TableCell>{language.text.provision}</TableCell>
              <TableCell>
                {language.text.number_of_active_users}
                <Tooltip
                  placement="bottom"
                  title={
                    <Typography>
                      {language.text.number_of_active_users_explanation}
                    </Typography>
                  }>
                  <Info
                    color="disabled"
                    fontSize="small"
                    sx={{ ml: 0.5, verticalAlign: 'middle' }}
                  />
                </Tooltip>
              </TableCell>
              <TableCell>
                {language.text.number_of_uses}
                <Tooltip
                  placement="bottom"
                  title={
                    <Typography>
                      {language.text.number_of_uses_explanation}
                    </Typography>
                  }>
                  <Info
                    color="disabled"
                    fontSize="small"
                    sx={{ ml: 0.5, verticalAlign: 'middle' }}
                  />
                </Tooltip>
              </TableCell>
              <TableCell>
                {language.text.number_of_not_confirmed_users}
                <Tooltip
                  placement="bottom"
                  title={
                    <Typography>
                      {language.text.number_of_not_confirmed_users_explanation}
                    </Typography>
                  }>
                  <Info
                    color="disabled"
                    fontSize="small"
                    sx={{ ml: 0.5, verticalAlign: 'middle' }}
                  />
                </Tooltip>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              Array.from({ length: 6 }).map((_, i) => (
                <TableRow key={i}>
                  {Array.from({ length: 7 }).map((_, i) => (
                    <TableCell key={i}>
                      <DelayedSkeleton
                        width="100%"
                        height={40}
                        variant="rounded"
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : filiteredDiscountCodes.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7}>
                  <Typography
                    variant="caption"
                    textAlign="center"
                    fontStyle="italic">
                    {language.text.no_discount_coupons}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              filiteredDiscountCodes.map((code) => (
                <TableRow key={code.id}>
                  <TableCell>
                    <Switch
                      checked={code.active}
                      onChange={(_, checked) =>
                        AffiliateActions.changeCodeActiviation(
                          code.code,
                          checked
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>{code.name}</TableCell>
                  <TableCell>
                    <CopyTextButton
                      variant="text"
                      disableLeftSpacing
                      popupText={language.text.code_copied}>
                      {code.code}
                    </CopyTextButton>
                  </TableCell>
                  <TableCell>{code.percentage_off + '%'}</TableCell>
                  <TableCell>
                    {Money.stringifyCents(code.provision, appPrice?.currency)}
                  </TableCell>
                  <TableCell>{code.number_of_active_users} </TableCell>
                  <TableCell>{code.number_of_uses} </TableCell>
                  <TableCell>{code.number_of_not_confirmed_users} </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setCodeToDelete(code);
                        setDeleteOpen(true);
                      }}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DialogTitle>{language.text.confirm_deletion}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {language.text.confirm_delete_coupon({ code: codeToDelete?.code })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="neutral"
            onClick={() => setDeleteOpen(false)}>
            {language.text.cancel}
          </Button>
          <FancyButton
            fType={{ promise: true }}
            variant="contained"
            color="error"
            onClick={async () => {
              if (!codeToDelete) return;
              try {
                await AffiliateActions.deleteCode(codeToDelete?.code);
              } finally {
                setDeleteOpen(false);
              }
            }}>
            {language.text.delete}
          </FancyButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
