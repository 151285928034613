import React from 'react';
import { useChatContext } from '@/Routes/ManualMode/Chat/ChatContext';
import { Contact } from '@/data/Classes/Contact';
import useError from '@/hooks/ErrorHook';
import ChatPipelineResultDisplay from './ChatPipelineResultDisplay';
import RelationshipMenu from '@/Routes/Focus/_general/ContactView/RelationshipMenu';
import { ContactType } from '@common/types/enums';
import { Pipelines } from '@common/PipelineManager/Pipelines';
import ContactActions from '@/data/DataServer/Contact';
import { PipelineStep } from '@common/PipelineManager/PipelineTypes';

export interface ChatPipelineDisplayProps {
  contact: Contact | null;
  loading: boolean;
  openRecategorize?: () => void;
  title?: string;
  onChangeStep?: (step: PipelineStep) => void;
}

export default function ChatPipelineDisplay(props: ChatPipelineDisplayProps) {
  const { history } = ContactActions.usePipelineHistory(
    props.contact?.profileID
  );
  const currentStep = React.useMemo(() => {
    if (props.contact?.pipelineCompleted) return null;
    const stepID = props.contact?.lastPipelineEvent?.currentStep;
    if (!stepID) return null;
    return Pipelines.getStepToDisplay(
      stepID,
      history?.map((h) => h.currentStep) ?? []
    );
  }, [props.contact, history]);

  const steps = React.useMemo(() => {
    return Pipelines.getCurrentStepsToDisplay(currentStep?.id);
  }, [currentStep]);

  const { displayChatMessageBoxInfo } = useChatContext();

  const { handleError } = useError({
    onError: (_, errorInfo) => {
      displayChatMessageBoxInfo(errorInfo, 'error');
    }
  });

  if (props.contact?.type !== ContactType.POTENTIAL_CUSTOMER) return null;

  if (props.contact.pipelineCompleted)
    return (
      <ChatPipelineResultDisplay
        result={props.contact.pipelineResult}
        contact={props.contact}
      />
    );

  return (
    <RelationshipMenu
      title={props.title}
      goBackToCategorySelector={props.openRecategorize}
      steps={steps}
      currentStep={currentStep}
      onSelect={handleError(async (step) => {
        if (props.contact) {
          await props.contact.setPipelineStep(step);
          props.onChangeStep?.(step);
        }
      })}
    />
  );
}
