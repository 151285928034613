import { statistics } from '@digital-sun-solutions/cloud-functions';
import { EventEmitter } from '@idot-digital/generic-helpers';
if (typeof window === 'undefined') {
    throw new Error('Tracking can only be used in renderer process');
}
if (typeof window === 'undefined') {
    process.exit(1);
}
const SAVE_INTERVAL = 60_000;
class Tracking extends EventEmitter {
    token;
    eventsMap;
    userState = 'idle';
    constructor() {
        super();
        this.eventsMap = new Map();
        setInterval(() => {
            if (this.userState === 'active') {
                this.capture('UserActive');
            }
            this.saveEventsToCloud();
        }, SAVE_INTERVAL);
    }
    init(token) {
        this.token = token;
    }
    /**
     * Captures a tracking event. Events will be cached locally and send in batches to the cloud.
     * @param event Event to capture
     * @param count Number of times the event was captured (default: 1)
     */
    capture(event, count = 1) {
        if (this.token === undefined) {
            console.warn('Tracking event received before initialization. The event will be stored locally and sent to the cloud when initialization is complete.');
        }
        const currentEventCount = this.eventsMap.get(event) ?? 0;
        this.eventsMap.set(event, currentEventCount + count);
        this.internalEmit('eventCaptured', { event, count });
        if (event === 'FocusModeStart' || event === 'FocusModeFinished') {
            this.saveEventsToCloud().then((success) => {
                if (!success)
                    throw new Error('Tracking not initialized but focus mode event was sent. ');
            });
        }
    }
    /**
     * Handles sending periodic user-active events while user is active. Call this when the user activity state changes.
     * @param state current state of the user
     */
    captureUserStateChange(state) {
        this.userState = state;
    }
    /**
     * Saves the events to the cloud.
     * @returns true if successful or no events to save, false if failed
     */
    async saveEventsToCloud() {
        if (this.token === undefined) {
            return false;
        }
        const events = this.getEventsToSave();
        if (events.length === 0)
            return true;
        await statistics.track({
            events
        }, {
            token: this.token,
            FetchOptions: {
                keepalive: true
            }
        });
        this.eventsMap.clear();
        return true;
    }
    /**
     * Returns an array of events to save to the cloud.
     */
    getEventsToSave() {
        const eventsToSave = [];
        for (const [eventName, count] of this.eventsMap) {
            if (count > 0) {
                eventsToSave.push({
                    eventType: eventName,
                    count
                });
            }
        }
        return eventsToSave;
    }
}
const tracking = new Tracking();
export default tracking;
