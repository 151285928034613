import { Box, Typography } from '@mui/material';
import { useSetup } from '../../SetupContext';
import { language } from '@/index';
import { Background, FancyButton, Logo } from 'ui-utils';

export default function SetupEnd() {
  const { endSuccessScreen } = useSetup();

  return (
    <Background
      variant="cockpit"
      sx={{
        height: '100%',
        display: 'flex',
        width: '100%'
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '100%',
          maxWidth: '640px',
          margin: 'auto',
          textAlign: 'center'
        }}>
        <Logo
          variant="blue_text"
          sx={{
            width: {
              md: '200px',
              lg: '280px',
              xl: '380px'
            },
            height: 'auto',
            mb: (theme) => ({
              md: theme.spacing(5),
              lg: theme.spacing(7.5),
              xl: theme.spacing(10)
            }),
            mt: (theme) => ({
              md: theme.spacing(10),
              lg: theme.spacing(15),
              xl: theme.spacing(17)
            })
          }}
        />
        <Typography
          variant="body1"
          sx={{
            fontSize: {
              md: '20px',
              lg: '24px',
              xl: '28px'
            },
            marginBottom: (theme) => theme.spacing(1),
            textAlign: 'center'
          }}>
          {language.text.ssc_now_setup}
        </Typography>
        <Typography
          sx={{
            fontSize: {
              md: '45px',
              lg: '50px',
              xl: '65px'
            },
            fontWeight: 'bold',
            marginBottom: (theme) => theme.spacing(2),
            textAlign: 'center'
          }}>
          {language.text.setup_finished}
        </Typography>
        <FancyButton
          fType={{
            promise: true
          }}
          variant="contained"
          onClick={() => {
            endSuccessScreen();
            return new Promise(() => {});
          }}
          sx={{
            width: {
              md: '290px',
              lg: '320px',
              xl: '400px'
            },
            height: '48px',
            marginTop: (theme) => theme.spacing(5),
            borderRadius: 1,
            fontSize: '18px',
            backgroundColor: (theme) => theme.palette.primary.main,
            textTransform: 'capitalize'
          }}>
          {language.text.lets_go}
        </FancyButton>
      </Box>
    </Background>
  );
}
