import { TaskBuilder, TaskOptionsConfig } from 'focus-mode-scheduler';
import CategorizeTask from './CategorizeTask';
import { UncategorizedProfile } from '@common/types/ApiTypes';
import { getSelf } from '@/data/LinkedIn/Account';
import { Iterators } from '@idot-digital/generic-helpers';
import Auth from '@common/AuthManager/Auth.renderer';
import { contacts } from '@digital-sun-solutions/cloud-functions';
import PrintableError from '@common/PrintableError/PrintableError';
import { BindProps, MapProps } from 'ui-utils';
import CategorizeTaskUI from './CategorizeTaskUI';
import { language } from '@/index';
import OnboardingVideo from '../_general/OnboardingVideo/OnboardingVideo';
import WebViewLinkedIn from '@common/Webview.renderer/WebviewLinkedIn';

export default class CategorizeTaskBuilder extends TaskBuilder {
  protected task = CategorizeTask;

  protected async getIterator(): Promise<AsyncGenerator<UncategorizedProfile>> {
    if (this.iterator) return this.iterator;
    const linkedInAccount = await getSelf(true);
    if (!linkedInAccount) return Iterators.fromArray([]);

    this.iterator = Iterators.filter(
      Iterators.fromApiV2<UncategorizedProfile & { cursor: number }>(
        async (cursor) => {
          const res = await Auth.execRoute((token) =>
            contacts.uncategorized.list({ cursor: cursor?.cursor }, { token })
          );
          if (res.code !== 200)
            throw new PrintableError(
              `Could not get uncategorized contacts: ${res.data}`
            );
          return res.data.contacts.map((contact) => ({
            ...contact,
            cursor: res.data.cursor
          }));
        }
      ),
      async (contact) => {
        if (contact.profileID === linkedInAccount.profileID) return false;
        const connected = await WebViewLinkedIn.isConnection(contact.profileID);
        if (connected) return true;
        await Auth.execRoute((token) =>
          contacts.uncategorized.remove(
            { profileID: contact.profileID },
            { token }
          )
        );
        return false;
      }
    );

    return this.iterator;
  }
  protected iterator: AsyncGenerator<
    UncategorizedProfile,
    any,
    UncategorizedProfile
  > | null = null;

  async getTask() {
    const iterator = await this.getIterator();
    const { value, done } = await iterator.next();
    if (done) return null;

    const task = new CategorizeTask(value as UncategorizedProfile);
    const UI = BindProps(CategorizeTaskUI, {
      task
    });

    return {
      task,
      UI
    };
  }

  public getStaticUI() {
    return MapProps(
      BindProps(OnboardingVideo, { onboardingStep: 'focus:categorization' }),
      ({ loading }: { loading?: boolean }) => ({ pause: loading })
    );
  }

  public async getOptionsConfig(): Promise<TaskOptionsConfig> {
    return {
      items: {
        defaultValue: 20,
        min: 1,
        max: 100,
        scale: 'logarithmic',
        step: 1
      },
      time: {
        defaultValue: 10,
        max: 60,
        min: 2,
        scale: 'logarithmic',
        step: 1
      },
      defaultValue: {
        type: 'items',
        items: 20
      },
      texts: {
        taskName: language.text.categorize
      }
    };
  }
}
