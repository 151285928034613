import { useEffect } from 'react';
import AffiliateOverview from './Affiliate/AffiliateOverview';
import posthog from 'posthog-js';

export default function ProgressionAffiliate() {
  useEffect(() => {
    posthog.capture('opened-affiiliate-dashboard');
  }, []);
  return <AffiliateOverview />;
}
