import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import { wait } from '@idot-digital/generic-helpers';
import { language } from '@/index';
import { Background, FancyButton, Loading, Logo } from 'ui-utils';
import { APP_PATHS, MANUAL_MODE_PATHS, STATS_PATHS } from '@/globals/Paths';
import { useScheduler } from 'focus-mode-scheduler';
import React from 'react';
import FocusModeActions from '../Modes';
import posthog from 'posthog-js';
import SetupActions from '@/data/DataServer/Setup';
import SummaryTask from './SummaryTask';
import { useFeatureFlags } from '@/globals/featureFlags';
import MessageBus from '@common/MessageBus/MessageBus.renderer';
import tracking from 'tracking';

export default function SummaryTaskUI() {
  const navigate = useNavigate();
  const { constraints, customMode } = useScheduler();
  const { data: setupSteps, isSuccess: setupStepsSuccess } =
    SetupActions.useSetupSteps();
  const successPageDone = setupStepsSuccess
    ? setupSteps.includes(SummaryTask.SETUP_STEP)
    : false;
  const { focusMode_summary_with_stats } = useFeatureFlags();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [name, setName] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');

  async function finish() {
    const timeout = wait(500);
    await SetupActions.setSetupStep(SummaryTask.SETUP_STEP);
    await window.api.send('app:check-for-update');
    await timeout;
    posthog.capture('focus-mode-finished');
    tracking.capture('FocusModeFinished');
    if (focusMode_summary_with_stats) {
      navigate(
        `/${APP_PATHS.Manual}/${MANUAL_MODE_PATHS.Stats}/${STATS_PATHS.FocusMode}`
      );
    } else {
      navigate(`/${APP_PATHS.Manual}`);
    }
  }

  if (focusMode_summary_with_stats) {
    if (successPageDone && customMode)
      return (
        <Navigate
          to={`/${APP_PATHS.Manual}/${MANUAL_MODE_PATHS.Stats}/${STATS_PATHS.FocusMode}`}
        />
      );
    if (!setupStepsSuccess || customMode) return <Loading />;
  }

  return (
    <Background
      variant="cockpit"
      sx={{
        height: '100vh',
        display: 'flex',
        width: '100vw',
        overflow: 'overlay',
        boxSizing: 'border-box',
        p: 2
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          margin: 'auto',
          textAlign: 'center'
        }}>
        <Logo
          variant="blue_text"
          sx={{
            width: {
              md: '200px',
              lg: '280px',
              xl: '380px'
            },
            height: 'auto',
            mb: (theme) => ({
              md: theme.spacing(5),
              lg: theme.spacing(7.5),
              xl: theme.spacing(10)
            }),
            mt: (theme) => ({
              md: theme.spacing(10),
              lg: theme.spacing(15),
              xl: theme.spacing(17)
            })
          }}
        />
        {setupStepsSuccess ? (
          <>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 'bold',
                fontSize: {
                  md: '30px',
                  lg: '40px',
                  xl: '45px'
                },
                marginBottom: (theme) => theme.spacing(1),
                textAlign: 'center'
              }}>
              {language.text.done_for_today}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: (theme) => theme.spacing(6),
                gap: 2
              }}>
              {!customMode &&
                Array.from(constraints.constraintsMap.values()).filter(Boolean)
                  .length > 0 && (
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    sx={{
                      height: '48px',
                      borderRadius: 1,
                      fontSize: '18px'
                    }}
                    onClick={setDialogOpen.bind(null, true)}>
                    {language.text.save_as_preset}
                  </Button>
                )}
              <FancyButton
                fType={{
                  promise: true
                }}
                variant="contained"
                color="primary"
                onClick={finish}
                sx={{
                  height: '48px',
                  borderRadius: 1,
                  fontSize: '18px'
                }}>
                {focusMode_summary_with_stats
                  ? language.text.see_my_stats
                  : language.text.continue_in_manual_mode}
              </FancyButton>
            </Box>
          </>
        ) : (
          <CircularProgress />
        )}
      </Box>
      <Dialog open={dialogOpen} onClose={setDialogOpen.bind(null, false)}>
        <DialogTitle>{language.text.save_as_preset}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {language.text.save_as_preset_description}
          </DialogContentText>
          <TextField
            label={language.text.name}
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            variant="standard"
            sx={{ my: 2 }}
          />
          <TextField
            label={language.text.description}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            variant="filled"
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={setDialogOpen.bind(null, false)}
            variant="contained"
            color="neutral">
            {language.text.cancel}
          </Button>
          <FancyButton
            fType={{ promise: true }}
            disabled={!name}
            onClick={async () => {
              await FocusModeActions.addFocusMode(constraints.constraintsMap, {
                title: name,
                description
              });
              await finish();
              setDialogOpen(false);
            }}
            color="primary"
            variant="contained">
            {language.text.save}
          </FancyButton>
        </DialogActions>
      </Dialog>
    </Background>
  );
}
