import { language } from '@/index';
import React from 'react';
import PostLoaderHelper from './PostLoaderHelper';
import LinkedInLoad from '@/components/LinkedInLoad/LinkedInLoad';

const TOTAL_POSTS = 8;

const TOTAL_PROFILE_VISITS_PERCENTAGE = 0.2;
const EXPECTED_PROFILE_VISIT_PAGES = 6;

export default function PostLoaderRun() {
  const remainingProgress = React.useRef(1);
  return (
    <LinkedInLoad
      actions={[
        {
          action: 'scroll-profile-visits',
          infoHandler(current, info) {
            if (info !== 'scrolling_profile_visits') return current;
            const progress =
              TOTAL_PROFILE_VISITS_PERCENTAGE / EXPECTED_PROFILE_VISIT_PAGES;
            return Math.min(
              current + progress,
              TOTAL_PROFILE_VISITS_PERCENTAGE
            );
          },
          onCompleted(progress) {
            remainingProgress.current = 1 - progress;
          },
          errorBehavior: 'continue'
        },
        {
          action: 'scroll-recent-posts',
          infoHandler(current, info) {
            switch (info) {
              // 0.1 for opening post page
              case 'opening_own_profile':
              case 'opening_recent_posts':
                return current + 0.05 * remainingProgress.current;
              case 'opening_reactions':
                return (
                  current +
                  (0.9 / TOTAL_POSTS / 3) * 1.5 * remainingProgress.current
                );
              case 'opening_reposts':
                return (
                  current +
                  (0.9 / TOTAL_POSTS / 3) * 0.5 * remainingProgress.current
                );
              case 'opening_comments':
                return (
                  current + (0.9 / TOTAL_POSTS / 3) * remainingProgress.current
                );
              default:
                return current;
            }
          },
          progressAfterFinished: 1
        }
      ]}
      onCompleted={() => PostLoaderHelper.setLastTimeCompleted()}
      texts={{
        title: language.text.loading_posts,
        confirm_leave: language.text.confirm_leave_load_posts,
        finished: language.text.post_loading_finished,
        error_title: language.text.error_while_loading_posts,
        error_description: language.text.error_while_loading_posts_description
      }}
    />
  );
}
