import { Client } from '../client/Client';
import {
  CARD_TYPE,
  CardUrn,
  CONNECTION_TYPE,
  DashConnectionUrn,
  DashProfileUrn,
  GetConnectionsResponse,
  GetMeResponse,
  GetProfileResponse,
  GetWvmpCardResponse,
  LinkedInCard,
  LinkedInConnection,
  LinkedInContact,
  LinkedInConversationID,
  LinkedInMiniCompany,
  LinkedInMiniProfile,
  LinkedInProfile,
  LinkedInWvmpCard,
  MINI_COMPANY_TYPE,
  MINI_PROFILE_TYPE,
  MiniCompanyUrn,
  MiniProfileUrn,
  PROFILE_CONVERSATION_ID,
  PROFILE_TYPE,
  ProfileId,
  WVMP_CARD_TYPE,
  WVMP_COMAPNY_INSIGHT_CARD_TYPE,
  WvmpCardUrn
} from 'linkedin-domain-types';
import { ProfileRequest } from '../requests/ProfileRequest';
import { ConnectionIterator } from '../iterator/ConnectionIterator';
import { Iterators } from '@idot-digital/generic-helpers';
import { Options, trace } from '../client/Utils';

/**
 * The profile service class that handles all profile related requests
 * @class ProfileService - The profile service class that handles all profile related requests
 */
@trace()
export class ProfileService {
  /**
   * The client that instantiated this service
   * @private the client that instantiated this service
   */
  private client: Client;

  /**
   * The profile requests
   * @private the profile requests
   */
  private profileRequests: ProfileRequest;

  /**
   * Creates a new ProfileService
   * @param client the client that instantiated this service
   */
  constructor(client: Client) {
    this.client = client;
    this.profileRequests = new ProfileRequest({
      requestService: client.requestService
    });
  }

  /**
   * Gets a profile by its public identifier
   * @param publicIdentifier the public identifier of the profile
   * @param options the options to use for the request
   * @todo Add parsing of profile
   */
  async getProfile(
    publicIdentifier: ProfileId,
    options: Options
  ): Promise<LinkedInProfile | null> {
    if (publicIdentifier === 'UNKNOWN') return null;
    const res = await this.profileRequests.getProfile(
      publicIdentifier,
      options
    );
    if (!res) return null;
    return this.parseGetProfileResponse(res);
  }
  /**
   * Gets a profile by its public identifier
   * @param publicIdentifier the public identifier of the profile
   * @param options the options to use for the request
   * @todo Add parsing of profile
   */

  async getFullProfile(
    publicIdentifier: ProfileId,
    options: Options
  ): Promise<LinkedInProfile | null> {
    if (publicIdentifier === 'UNKNOWN') return null;
    const res = await this.profileRequests.getFullProfile(
      publicIdentifier,
      options
    );
    if (!res) return null;
    const profile = this.parseGetProfileResponse(res);
    const baseProfile = await this.getProfile(publicIdentifier, options);
    if (!profile || !baseProfile) return null;
    return {
      ...baseProfile,
      ...profile
    };
  }

  /**
   * Gets the profile of the logged-in user
   * @returns the profile of the logged-in user
   *
   * @todo Add parsing of profile
   */
  async getMe(options: Options): Promise<LinkedInMiniProfile | null> {
    const res = await this.profileRequests.getMe(options);
    if (!res) return null;
    const mini = this.parseGetMeResponse(res);
    return mini;
  }

  /**
   * Gets the wvmp card of the logged-in user
   * @param options the options to use for the request
   * @returns the wvmp card of the logged-in user
   */
  async getWvmpCard(options: Options): Promise<LinkedInWvmpCard[]> {
    const res = await this.profileRequests.getWvmpCard(options);
    if (!res) return [];
    return this.parseWvmpCard(res);
  }

  /**
   * Gets the users of the own network
   * @param start the start index
   * @param sortType the sort type of the network
   * @param options the options to use for the request
   * @returns the users of the own network as an iterator
   */
  getOwnNetwork(
    start = 0,
    sortType: 'RECENTLY_ADDED' | 'FIRSTNAME_LASTNAME' | 'LASTNAME_FIRSTNAME',
    options: Options
  ): ConnectionIterator {
    return new ConnectionIterator(
      start,
      40,
      this.fetchOwnNetwork.bind(this),
      sortType,
      options,
      undefined
    );
  }

  /**
   * Gets the users of the own network
   * @param start the start index
   * @param sortType the sort type of the network
   * @param options the options to use for the request
   * @returns the users of the own network as an iterator
   */
  getOwnNetworkPage(
    start = 0,
    sortType: 'RECENTLY_ADDED' | 'FIRSTNAME_LASTNAME' | 'LASTNAME_FIRSTNAME',
    options: Options
  ) {
    return this.fetchOwnNetwork(start, 40, sortType, options, undefined);
  }

  /**
   * Gets some of the users that visited the profile
   * @param options the options to use for the request
   */
  async *getProfileVisitors(
    options: Options
  ): AsyncGenerator<LinkedInMiniProfile, null> {
    const res = await this.profileRequests.getWvmpCard(options);
    if (!res) return null;
    const ret = res.included
      .filter((r) => r.$type === MINI_PROFILE_TYPE)
      // TypeScript ¯\_(ツ)_/¯
      .map((r) => r as LinkedInMiniProfile);
    for (const r of ret) {
      yield r;
    }
    return null;
  }

  /**
   * Gets some of the users that visited the profile
   * @param options the options to use for the request
   */
  async getProfileVisitorsPage(
    options: Options
  ): Promise<LinkedInMiniProfile[]> {
    return Iterators.toArray(this.getProfileVisitors(options));
  }

  /**
   * Checks whether the logged-in user is connected to the given profile
   * @param publicIdentifier the public identifier of the profile or the profile itself
   * @param options the options to use for the request
   */
  async isConnected(
    publicIdentifier:
      | ProfileId
      | Pick<LinkedInContact, 'firstName' | 'lastName' | 'profileID'>,
    options: Options
  ): Promise<boolean> {
    const profile =
      typeof publicIdentifier === 'string'
        ? await this.getProfile(publicIdentifier, options)
        : publicIdentifier;
    if (!profile) return false;
    const searchResults = await this.fetchConnections(
      0,
      100,
      options,
      `${profile.firstName ?? ''} ${profile.lastName ?? ''}`.trim()
    );
    const urn =
      'entityUrn' in profile
        ? profile.entityUrn
        : new DashProfileUrn(profile.profileID).getFullUrn();
    for (const result of searchResults) {
      if (result.connectedMemberResolutionResult?.entityUrn === urn)
        return true;
    }
    return false;
  }

  private async fetchOwnNetwork(
    start: number,
    count: number,
    sortType: 'RECENTLY_ADDED' | 'FIRSTNAME_LASTNAME' | 'LASTNAME_FIRSTNAME',
    options: Options,
    _keyword?: string
  ): Promise<Iterable<LinkedInConnection>> {
    const res = await this.profileRequests.getConnectionsWithProfile({
      start,
      count,
      options,
      sortType
    });
    if (!res) return [];
    return ProfileService.parseConnectionsResponse(res);
  }

  private async fetchConnections(
    start: number,
    count: number,
    options: Options,
    keyword?: string
  ): Promise<Iterable<LinkedInConnection>> {
    const res = await this.profileRequests.getConnectionList({
      start,
      count,
      keyword,
      options
    });
    if (!res) return [];
    return ProfileService.parseConnectionsResponse(res);
  }

  /**
   * Parses the get me response
   * @param res the response
   * @returns the parsed profile or null if not found
   */
  public parseGetMeResponse(res: GetMeResponse): LinkedInMiniProfile | null {
    return res.included.find((r) => r.$type === MINI_PROFILE_TYPE) ?? null;
  }

  /**
   * Parses the get profile response
   * @param res the response
   * @returns the parsed profile
   */
  public parseGetProfileResponse(
    res: GetProfileResponse
  ): LinkedInProfile | null {
    const profile = res.included.find(
      (r) =>
        r.$type === PROFILE_TYPE && res.data['*elements'][0] === r.entityUrn
    ) as LinkedInProfile | undefined;
    if (!profile) return null;
    return {
      ...profile,
      conversationID: (
        res.included.find((i) => i.$type === PROFILE_CONVERSATION_ID) as
          | LinkedInConversationID
          | undefined
      )?.entityUrn
    };
  }

  /**
   * Parses the wvmp card response
   */
  public parseWvmpCard(res: GetWvmpCardResponse): LinkedInWvmpCard[] {
    const mapping: {
      MINI_COMPANY_TYPE: Map<MiniCompanyUrn, LinkedInMiniCompany>;
      CARD_TYPE: Map<CardUrn, LinkedInCard>;
      WVMP_CARD_TYPE: Map<WvmpCardUrn, LinkedInWvmpCard>;
      MINI_PROFILE_TYPE: Map<MiniProfileUrn, LinkedInMiniProfile>;
    } = {
      MINI_COMPANY_TYPE: new Map(),
      CARD_TYPE: new Map(),
      WVMP_CARD_TYPE: new Map(),
      MINI_PROFILE_TYPE: new Map()
    };

    res.included.forEach((r) => {
      if (r.$type === MINI_COMPANY_TYPE) {
        mapping.MINI_COMPANY_TYPE.set(r.entityUrn, r);
      } else if (r.$type === CARD_TYPE) {
        mapping.CARD_TYPE.set(r.entityUrn, r);
      } else if (r.$type === WVMP_CARD_TYPE) {
        mapping.WVMP_CARD_TYPE.set(r.entityUrn, r);
      } else if (r.$type === MINI_PROFILE_TYPE) {
        mapping.MINI_PROFILE_TYPE.set(r.entityUrn, r);
      }
    });

    mapping.CARD_TYPE.forEach((card) => {
      card.value.viewer.profile.miniProfile = mapping.MINI_PROFILE_TYPE.get(
        card.value.viewer.profile['*miniProfile']
      );

      card.value.insight.value.connectionsInCommon = card.value.insight.value[
        '*connectionsInCommon'
      ]
        .map((c) => mapping.MINI_PROFILE_TYPE.get(c))
        .filter((c) => c) as LinkedInMiniProfile[];

      card.value.insight.value.miniProfile = mapping.MINI_PROFILE_TYPE.get(
        card.value.insight.value['*miniProfile']
      );
    });

    mapping.WVMP_CARD_TYPE.forEach((card) => {
      card.value.insightsCards.forEach((insightCard) => {
        if (insightCard.value.$type === WVMP_COMAPNY_INSIGHT_CARD_TYPE) {
          insightCard.value.miniCompany = mapping.MINI_COMPANY_TYPE.get(
            insightCard.value['*miniCompany']
          );
        }

        insightCard.value.cards = insightCard.value['*cards']
          .map((c) => mapping.CARD_TYPE.get(c))
          .filter((c) => c) as LinkedInCard[];
      });
    });

    return res.data.elements
      .map((e) => mapping.WVMP_CARD_TYPE.get(e))
      .filter((c) => c) as LinkedInWvmpCard[];
  }

  public static parseConnectionsResponse(
    res: GetConnectionsResponse
  ): Iterable<LinkedInConnection> {
    const mapping: {
      PROFILE_TYPE: Map<DashProfileUrn, LinkedInProfile>;
      CONNECTION_TYPE: Map<DashConnectionUrn, LinkedInConnection>;
    } = {
      PROFILE_TYPE: new Map(),
      CONNECTION_TYPE: new Map()
    };

    res.included.forEach((r) => {
      if (r.$type === PROFILE_TYPE) {
        mapping.PROFILE_TYPE.set(r.entityUrn, r);
      } else if (r.$type === CONNECTION_TYPE) {
        mapping.CONNECTION_TYPE.set(r.entityUrn, r);
      }
    });

    mapping.CONNECTION_TYPE.forEach((connection) => {
      if (!connection['*connectedMemberResolutionResult']) return;
      connection.connectedMemberResolutionResult = mapping.PROFILE_TYPE.get(
        connection['*connectedMemberResolutionResult']
      );
    });

    return [...mapping.CONNECTION_TYPE.values()].sort(
      (a, b) => b.createdAt - a.createdAt
    );
  }
}
