import { createQueryHook } from './QueryHelper';
import PrintableError from '@common/PrintableError/PrintableError';
import Auth from '@common/AuthManager/Auth.renderer';
import { subscription } from '@digital-sun-solutions/cloud-functions';

async function getBillingLink() {
  const res = await Auth.execRoute((token) =>
    subscription.getBillingLink({}, { token })
  );
  if (res.code === 500)
    throw new PrintableError(
      'Could not get billing link due to internal server error'
    );
  if (res.code === 404) return null;
  return res.data;
}
getBillingLink.getQueryKey = () => ['subscription', 'billing_link'];

const useBillingLink = createQueryHook(getBillingLink, 'billingLink');

const SubscriptionActions = {
  getBillingLink,
  useBillingLink
};

export default SubscriptionActions;
