import React from 'react';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { language } from '@/index';
import MainConfig from '@common/config/MainConfig';
import { FancyButton, Logo, useTimer } from 'ui-utils';

const EMAIL_TIMEOUT = 30;
const TOO_MANY_REQUESTS_TIMEOUT = 60;
const INFO_TIMOUT = 10;

export interface CodeLoginProps {
  /**
   * return true if login was successful, false or error string otherwise
   */
  onLogin: (email: string, code: string) => Promise<boolean | string>;

  /**
   * return true if request was successful, false or error string otherwise
   */
  requestCode: (email: string) => Promise<boolean | string>;
  onError?: () => void;
  onSuccess?: () => void;
  logo?: React.ReactNode;
  title?: string;
  localStorageKey?: string | null;
  disableRegister?: boolean;
  onRegister?: () => void;
}

export default function CodeLogin(props: CodeLoginProps) {
  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const requestCodeTimer = useTimer(EMAIL_TIMEOUT, { startOnMount: false });
  const submitCodeTimer = useTimer(TOO_MANY_REQUESTS_TIMEOUT, {
    startOnMount: false
  });

  const [email, setEmail] = React.useState(
    localStorage.getItem(props.localStorageKey ?? 'login-email') ?? ''
  );
  const [code, setCode] = React.useState('');
  const [info, setInfo] = React.useState<{
    type: 'info' | 'error' | 'success';
    text: string;
  } | null>(null);

  // hide info after 10 seconds
  React.useEffect(() => {
    if (!info) return;
    const timeout = setTimeout(() => {
      if (mounted.current) {
        setInfo(null);
      }
    }, INFO_TIMOUT * 1000);
    return () => clearTimeout(timeout);
  }, [info]);

  const [codeRequested, setCodeRequested] = React.useState(false);

  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  const buttonDisabled = React.useMemo(() => {
    if (!email) return true;
    if (codeRequested && !code) return true;
    // no code requested and cant request new code
    if (!codeRequested && !requestCodeTimer.completed) return true;
    // too many tries to enter code
    if (codeRequested && !submitCodeTimer.completed) return true;
    return false;
  }, [email, code, codeRequested]);

  const message = info ?? {
    text: ' ',
    type: 'info'
  };

  const requestCode = async () => {
    try {
      const success = await props.requestCode(email.trim());
      if (success === true) {
        requestCodeTimer.restart();
        setInfo({
          text: language.text.code_sent,
          type: 'success'
        });
        setCodeRequested(true);
      } else {
        setInfo({
          text:
            typeof success === 'string'
              ? success
              : language.text.wrong_email_or_code,
          type: 'error'
        });
        props.onError?.();
      }
    } catch (e) {
      console.error('Error requesting code', e);
      setInfo({
        text: language.text.error_requesting_code,
        type: 'error'
      });
    }
  };

  const login = async () => {
    try {
      const success = await props.onLogin(email.trim(), code.trim());
      if (success === true) {
        if (props.localStorageKey !== null)
          localStorage.setItem(
            props.localStorageKey ?? 'login-email',
            email.trim()
          );
        setInfo(null);
        props.onSuccess?.();
      } else {
        setInfo({
          text:
            typeof success === 'string'
              ? success
              : language.text.wrong_email_or_code,
          type: 'error'
        });
        props.onError?.();
      }
    } catch (e) {
      console.error('Error logging in', e);
      if (e instanceof Error) {
        if (e.message === 'code-error') {
          setInfo({
            text: language.text.wrong_email_or_code,
            type: 'error'
          });
        } else if (e && e.message === 'too-many-requests') {
          submitCodeTimer.restart();
        } else {
          setInfo({
            text: language.text.generic_error_try_again,
            type: 'error'
          });
        }
        props.onError?.();
      }
    }
  };

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          md: '70%',
          lg: '70%'
        },
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: 1,
        padding: {
          xs: 1,
          md: 4,
          lg: 4
        }
      }}>
      <Box
        sx={{
          width: 'auto',
          height: '12vh',
          maxHeight: '8vmax',
          display: 'block',
          marginBottom: 2
        }}>
        {props.logo ?? (
          <Logo
            variant="blue_text"
            sx={{
              height: '100%',
              width: 'auto'
            }}
          />
        )}
      </Box>
      <Typography
        variant="h1"
        textAlign="left"
        fontWeight="bold"
        sx={{
          fontSize: {
            xl: '2.4rem',
            lg: '2.2rem',
            md: '2rem'
          }
        }}
        my={3}>
        {props.title ?? language.text.login_to_your_account}
      </Typography>
      <Box
        component="form"
        onSubmit={(e: any) => e.preventDefault()}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%'
        }}>
        <TextField
          label={language.text.email}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="standard"
          fullWidth
          spellCheck={false}
          margin="normal"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              buttonRef.current?.click();
            }
          }}
          disabled={codeRequested || !requestCodeTimer.completed}
          InputProps={{
            endAdornment: codeRequested ? (
              <IconButton
                size="small"
                onClick={() => {
                  setCodeRequested(false);
                  setCode('');
                }}>
                <Clear />
              </IconButton>
            ) : null
          }}
        />
        <TextField
          label={language.text.login_code}
          value={code}
          // remove all whitespaces + uppercase everything
          onChange={(e) =>
            setCode(e.target.value.replace(/\s/g, '').toLocaleUpperCase())
          }
          type="text"
          spellCheck={false}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              buttonRef.current?.click();
            }
          }}
          variant="standard"
          fullWidth
          margin="normal"
          sx={{
            visibility: codeRequested ? 'visible' : 'hidden',
            pointerEvents: codeRequested ? 'all' : 'none'
          }}
          tabIndex={codeRequested ? undefined : -1}
          disabled={codeRequested && !submitCodeTimer.completed}
        />
        <FancyButton
          fType={{
            pulsating: 'default',
            promise: true
          }}
          animations={{
            disableElevation: true
          }}
          ref={(ref) => (buttonRef.current = ref)}
          variant="contained"
          type="submit"
          size="large"
          disabled={buttonDisabled}
          sx={{
            padding: (theme) => theme.spacing(3)
          }}
          fullWidth
          onClick={async () => {
            if (codeRequested) {
              await login();
            } else await requestCode();
          }}>
          {codeRequested ? language.text.login : language.text.request_code}
        </FancyButton>
      </Box>
      {(codeRequested ||
        !requestCodeTimer.completed ||
        !submitCodeTimer.completed) && (
        <Typography
          color={'text.secondary'}
          minHeight="1.5rem"
          lineHeight="1.5rem"
          textAlign="center"
          mx="auto"
          mt={1}
          fontSize="0.9rem">
          {submitCodeTimer.completed && requestCodeTimer.completed ? (
            <>
              {language.text.no_email_delivered}
              <FancyButton
                fType={{
                  promise: true
                }}
                variant="text"
                sx={{
                  padding: 0,
                  fontSize: '0.9rem',
                  mb: (theme) => theme.spacing(0.25),
                  ml: (theme) => theme.spacing(0.25)
                }}
                onClick={requestCode}>
                {language.text.request_new_code}
              </FancyButton>
            </>
          ) : (
            language.text.please_wait_seconds_before_requesting_code.replace(
              '{seconds}',
              (
                submitCodeTimer.remaining || requestCodeTimer.remaining
              ).toString() ?? '0'
            )
          )}
        </Typography>
      )}
      <Typography
        color={message.type === 'error' ? 'error.main' : 'text.secondary'}
        minHeight="1.5rem"
        lineHeight="1.5rem"
        textAlign="center"
        mx="auto"
        mt={1}>
        {message.text}
      </Typography>
      {!props.disableRegister && (
        <Typography variant="body2" sx={{ marginTop: 1 }}>
          {language.text.no_account_yet}
          <Button
            variant="text"
            sx={{ padding: 0, ml: 0.5 }}
            onClick={() =>
              props.onRegister
                ? props.onRegister()
                : window.open(MainConfig.registerURL)
            }>
            {language.text.register_now}
          </Button>
        </Typography>
      )}
    </Box>
  );
}
