import LanguageManager from './LanguageManager';
import log from 'electron-log';

async function initLanguageManager() {
  const languageManager = LanguageManager.getInstance();

  try {
    // get language from main thread
    const language = await window.api.send('language:get');
    if (!language) throw new Error('no language received from main thread');

    languageManager.setLanguage(language);
  } catch (err) {
    log.error('error while getting language from main thread', err);
    languageManager.setLanguage('en');
  }
}

export default initLanguageManager;
