import { inspirationalQuotes } from '@digital-sun-solutions/cloud-functions';
import Logger from 'electron-log';
import { useQuery } from 'react-query';

export interface Quote {
  quote: string;
  author: string;
}

interface RawQuote {
  q: string;
  a: string;
  h: string;
}
async function getQuote(): Promise<Quote | null> {
  try {
    const result = await inspirationalQuotes.get({}, {});
    if (result.code !== 200) {
      Logger.error('Error getting quote: ', result.data);
      return null;
    }
    return result.data;
  } catch (e) {
    Logger.error('Error getting quote: ', e);
    return null;
  }
}
getQuote.getQueryKey = () => ['misc', 'quote', 'get'];

const useQuote = () => {
  const { data: quote, ...rest } = useQuery(getQuote.getQueryKey(), getQuote, {
    cacheTime: 1000
  });
  return {
    quote,
    ...rest
  };
};

export default useQuote;
