import { language } from '@/index';
import { StatTypes } from '@common/types/ApiTypes';
import {
  AccessTime,
  AddReactionOutlined,
  AlarmOn,
  Bookmark,
  CalendarMonth,
  Campaign,
  Category,
  Chat,
  Close,
  Link,
  Person
} from '@mui/icons-material';
import { IconProps } from '@mui/material';
import { CommentIcon, MoneyIcon, SpeakerIcon, TargetHitIcon } from 'ui-utils';
import { InternalEvents } from '@digital-sun-solutions/cloud-functions/src/functions/statistics/types';
import { TAssert } from '@idot-digital/generic-helpers';

export interface StatsAsset {
  title: string;
  description?: string;
  icon: React.FC<Pick<IconProps, 'sx'>>;
}

export interface Stat {
  type: StatTypes;
  current: number;
  avg: number;
  change: number;
}

export default class StatsHelper {
  static getStatsAsset(type: StatTypes): StatsAsset | null {
    switch (type) {
      case 'CommentPosted':
        return {
          title: language.text.comments_posted,
          icon: CommentIcon
        };
      case 'ConnectionRequestSend':
        return {
          title: language.text.connection_requests_sent,
          icon: Link
        };
      case 'MessageSend':
        return {
          title: language.text.messages_sent,
          icon: Chat
        };
      case 'ProfileCategorized':
        return {
          title: language.text.profiles_categorized,
          icon: Category
        };
      case 'ProfilesMarked':
        return {
          title: language.text.profiles_marked,
          icon: Bookmark
        };
      case 'UserActive':
        return {
          title: language.text.usage_time,
          icon: AccessTime
        };
      case 'CustomerCount':
        return {
          title: language.text.customer_count,
          icon: MoneyIcon
        };
      case 'PersonalContactCount':
        return {
          title: language.text.personal_contact_count,
          icon: Person
        };
      case 'NoFitCount':
        return {
          title: language.text.no_match_count,
          icon: Close
        };
      case 'AudienceHolderCount':
        return {
          title: language.text.audience_holder_count,
          icon: SpeakerIcon
        };
      case 'MarkedCount':
        return {
          title: language.text.marked_count,
          icon: Bookmark
        };
      case 'PotentialCustomerCount':
        return {
          title: language.text.potential_customer_count,
          icon: TargetHitIcon
        };
      case 'BeziehungsaufbauCount':
        return {
          title: language.text.build_relationship_count,
          icon: AddReactionOutlined
        };
      case 'MeetingPitchCount':
        return {
          title: language.text.meeting_pitch_count,
          icon: Campaign
        };
      case 'CalendarLinkCount':
        return {
          title: language.text.calendar_link_count,
          icon: CalendarMonth
        };
      case 'MeetingBookedCount':
        return {
          title: language.text.meeting_booked_count,
          icon: AlarmOn
        };
      default:
        TAssert(type, InternalEvents);
        return null;
    }
  }

  static getChange(value: number, previousValue?: number): number {
    if (previousValue === 0) return value;
    if (value === 0) return -1;

    const absoluteChange = value - (previousValue ?? 0);
    const relativeChange = absoluteChange / (previousValue ?? 1);

    return Math.round(relativeChange * 100) / 100;
  }

  static getStatTypes(): StatTypes[] {
    return [
      'CommentPosted',
      'ConnectionRequestSend',
      'MessageSend',
      'ProfileCategorized',
      'ProfilesMarked',
      'UserActive',
      'CustomerCount',
      'PersonalContactCount',
      'NoFitCount',
      'AudienceHolderCount',
      'MarkedCount',
      'PotentialCustomerCount',
      'BeziehungsaufbauCount',
      'MeetingPitchCount',
      'CalendarLinkCount',
      'MeetingBookedCount'
    ];
  }

  static getStatCategories(): {
    stats: StatTypes[];
    title: string;
  }[] {
    return [
      {
        stats: [
          'CommentPosted',
          'ConnectionRequestSend',
          'MessageSend',
          'ProfileCategorized',
          'ProfilesMarked',
          'UserActive'
        ],
        title: language.text.activities
      },
      {
        stats: [
          'MarkedCount',
          'PotentialCustomerCount',
          'CustomerCount',
          'NoFitCount',
          'PersonalContactCount',
          'AudienceHolderCount'
        ],
        title: language.text.categories
      },
      {
        stats: [
          'BeziehungsaufbauCount',
          'MeetingPitchCount',
          'CalendarLinkCount',
          'MeetingBookedCount'
        ],
        title: language.text.pipelines
      }
    ];
  }
}
