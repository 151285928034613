import React from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import { language } from '@/index';
import { ArrowDownIcon, FancyButton, OptionalTooltip } from 'ui-utils';

interface Item {
  name: string;
  id: string;
  description?: string;
}
export interface ManagedRelationshipMenuProps<T extends Item>
  extends Omit<BoxProps, 'children'> {
  listItem: T[];
  active: T | null | undefined;
  setActive: (item: T | null) => void | Promise<void>;
  goBackToCategorySelector?: () => void;
  children?: React.ReactNode;
  title?: string;
  readonly?: boolean;
}

export default function ManagedRelationshipMenu<T extends Item>(
  props: ManagedRelationshipMenuProps<T>
) {
  const {
    listItem,
    active,
    setActive,
    goBackToCategorySelector,
    title,
    readonly,
    children,
    ...boxProps
  } = props;

  const [tooltipAnchorRefs, setTooltipAnchorRefs] = React.useState<
    HTMLElement[]
  >([]);

  return (
    <Box
      {...boxProps}
      sx={{
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        ...boxProps.sx
      }}>
      <Box
        sx={{
          width: '100%',
          boxSizing: 'border-box',
          px: 2,
          py: 1,
          mb: 2,
          borderRadius: 1,
          backgroundColor: 'secondary.main',
          color: 'secondary.contrastText',
          cursor: goBackToCategorySelector ? 'pointer' : undefined
        }}
        onClick={goBackToCategorySelector}>
        <Typography variant="h6" textAlign="center">
          {language.text.categorization}
        </Typography>
        <Typography variant="body2" textAlign="center">
          {language.text.potential_customer}
        </Typography>
      </Box>
      <Typography
        variant="body1"
        textAlign="center"
        sx={{
          color: 'text.secondary',
          mb: 2
        }}>
        {title ?? language.text.select_relation_step}
      </Typography>
      <Box sx={{ position: 'relative', zIndex: 0 }}>
        {listItem.map((item, i) => (
          <OptionalTooltip
            key={item.id}
            title={<Typography>{item.description}</Typography>}
            placement="left"
            // place tooltip left of button text
            PopperProps={{
              anchorEl: tooltipAnchorRefs[i] ?? null
            }}>
            <FancyButton
              fType={{ promise: true }}
              disableElevation={true}
              variant="contained"
              color={active?.id === item.id ? 'primary' : 'neutral'}
              onClick={() => !readonly && setActive(item)}
              fullWidth
              sx={{
                height: '42px',
                mb: 1
              }}>
              <span
                ref={(ref) => {
                  // check if ref already set otherwise we get a infinite rerender loop
                  if (ref && !tooltipAnchorRefs[i])
                    setTooltipAnchorRefs((old) => {
                      const newRefs = [...old];
                      newRefs[i] = ref;
                      return newRefs;
                    });
                }}>
                {item.name}
              </span>
            </FancyButton>
          </OptionalTooltip>
        ))}
        <Box
          sx={{
            position: 'absolute',
            zIndex: -1,
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            mt: 6
          }}>
          <ArrowDownIcon
            color="disabled"
            sx={{
              height: 'calc(100% - 28px)',
              width: '100%',
              opacity: 0.5,
              mt: -6
            }}
          />
        </Box>
      </Box>
      {children}
    </Box>
  );
}
