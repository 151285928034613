import { language } from '@/index';
import { APP_PATHS } from '@/globals/Paths';
import { Update } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Background } from 'ui-utils';

export interface LoaderStartProps {
  icon?: React.ReactNode;
  title: string;
  description: string;
  continue: string;
  onCancel?: () => void;
  onContinue: () => void;
}

export default function LoaderStart(props: LoaderStartProps) {
  const navigate = useNavigate();
  return (
    <Background
      variant="frame"
      height="100vh"
      width="100%"
      overflow="auto"
      p={4}
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={2}>
      <Typography maxWidth="1000px" variant="h1">
        {props.icon ?? (
          <Update
            sx={{
              verticalAlign: 'middle',
              fontSize: '3rem',
              mr: 2
            }}
          />
        )}
        {props.title}
      </Typography>
      <Typography maxWidth="800px">{props.description}</Typography>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
        <Button
          variant="contained"
          color="neutral"
          size="large"
          sx={{
            minWidth: '200px'
          }}
          onClick={() => {
            if (props.onCancel) props.onCancel();
            else navigate(`/${APP_PATHS.Manual}`);
          }}>
          {language.text.back}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{
            minWidth: '200px'
          }}
          onClick={() => props.onContinue()}>
          {props.continue}
        </Button>
      </Box>
    </Background>
  );
}
