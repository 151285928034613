import React from 'react';
import BunnyPlayer from './BunnyPlayer/BunnyPlayer';
import YouTubePlayer, { YouTubePlayerEle } from './YouTubePlayer/YouTubePlayer';
import { isYouTubeVideo } from './VideoHelper';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { Typography } from '@mui/material';
import { language } from '../../Config';
import { ErrorOutline } from '@mui/icons-material';
import Center from '../Center/Center';

export interface GenericVideoPlayerProps {
  videoID: string;
  disableAutoPlay?: boolean;
  onEnd?: () => void;
  onStart?: () => void;
}

const GenericVideoPlayer = React.forwardRef<
  YouTubePlayerEle | HTMLIFrameElement,
  GenericVideoPlayerProps
>(function GenericVideoPlayer(props, ref) {
  React.useEffect(() => {
    if (!isYouTubeVideo(props.videoID)) props.onEnd?.();
  }, []);

  // bunny cdn has uuid and yt short ids
  if (isYouTubeVideo(props.videoID))
    return (
      // YouTubePlayer can throw an error when unmounted -> prevent app from crashing
      <ErrorBoundary
        fallback={
          <Center fullHeight>
            <ErrorOutline color="error" sx={{ fontSize: '5rem' }} />
            <Typography variant="h6">
              {language.text.error_loading_video}
            </Typography>
          </Center>
        }>
        <YouTubePlayer
          ref={ref as React.ForwardedRef<YouTubePlayerEle>}
          videoID={props.videoID}
          disableAutoPlay={props.disableAutoPlay}
          onEnd={props.onEnd}
          onStart={props.onStart}
        />
      </ErrorBoundary>
    );
  return (
    <BunnyPlayer
      ref={ref as React.ForwardedRef<HTMLIFrameElement>}
      videoID={props.videoID}
      autoplay={!props.disableAutoPlay}
      onStart={props.onStart}
    />
  );
});

export default GenericVideoPlayer;
