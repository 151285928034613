import React from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import DoubleCalendar from './DualMonthDayPicker/DoubleCalendar';
import MeetingDay from './DualMonthDayPicker/MeetingDay';

export interface Meeting {
  id: string;
  fullname: string;
  date: Date;
}

export interface DualMonthDayPickerProps extends BoxProps {
  date?: Date | undefined | null;
  onDateChange?: (date: Date) => void;
  meetings: Meeting[];
  lang?: string;
}

export default function DualMonthDayPicker(props: DualMonthDayPickerProps) {
  const { date, onDateChange, meetings, ...rest } = props;

  const groupMeetingsByDate = (meetings: Meeting[]) => {
    const groupedMeetings: { [key: string]: Meeting[] } = {};
    meetings.forEach((meeting) => {
      const date = meeting.date.toLocaleDateString(props.lang ?? 'de');
      if (groupedMeetings[date]) {
        groupedMeetings[date].push(meeting);
      } else {
        groupedMeetings[date] = [meeting];
      }
    });

    return groupedMeetings;
  };

  const groupedMeetings = React.useMemo(
    () => groupMeetingsByDate(meetings),
    [meetings]
  );

  return (
    <Box
      {...rest}
      sx={{
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        backgroundColor: (theme) => theme.palette.background.default,
        ...rest.sx
      }}>
      <Box
        sx={{
          width: '55%',
          height: '100%',
          display: 'flex',
          padding: (theme) => theme.spacing(1.5),
          flexDirection: 'column'
        }}>
        <DoubleCalendar
          sx={{
            display: 'flex',
            gap: 2
          }}
          date={date}
          onDateChange={onDateChange}
        />
      </Box>
      {groupedMeetings.length && (
        <Box
          sx={{
            width: '45%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            borderLeft: '1px solid #ccc',
            padding: (theme) => theme.spacing(1.5, 2)
          }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: '600', textAlign: 'center' }}>
            Meeting Reminder
          </Typography>
          <Box
            sx={{
              flex: '1',
              maxHeight: '100%',
              overflowY: 'auto'
            }}>
            <Box>
              {Object.keys(groupedMeetings).map((date, i) => (
                <MeetingDay
                  key={i}
                  date={date}
                  meetings={groupedMeetings[date]}
                />
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
