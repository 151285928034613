import React from 'react';
import { Box } from '@mui/material';

export interface BunnyPlayerProps {
  videoID: string;
  autoplay?: boolean;
  onStart?: () => void;
}

const BunnyPlayer = React.forwardRef<HTMLIFrameElement, BunnyPlayerProps>(
  function BunnyPlayer(props, ref) {
    const innerRef = React.useRef<HTMLIFrameElement | null>(null);
    const started = React.useRef(false);

    React.useEffect(() => {
      if (props.autoplay && !started.current) {
        started.current = true;
        props.onStart?.();
      }
    }, [props.autoplay]);

    React.useEffect(() => {
      if (started.current) return;

      function listener() {
        if (document.activeElement === innerRef.current) {
          if (started.current) {
            props.onStart?.();
          }
        }
      }

      document.addEventListener('blur', listener);
      return () => {
        document.removeEventListener('blur', listener);
      };
    }, []);

    return (
      <Box sx={{ position: 'relative', pt: '56.25%', width: '100%' }}>
        <iframe
          ref={(orgRef) => {
            innerRef.current = orgRef;
            if (typeof ref === 'function') {
              ref(orgRef);
            } else {
              if (ref) {
                ref.current = orgRef;
              }
            }
          }}
          src={`https://iframe.mediadelivery.net/embed/162015/${
            props.videoID
          }?autoplay=${(
            props.autoplay ?? false
          ).toString()}&loop=false&muted=false&preload=true`}
          loading="lazy"
          style={{
            border: 0,
            position: 'absolute',
            top: 0,
            height: '100%',
            width: '100%'
          }}
          allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
          allowFullScreen
        />
      </Box>
    );
  }
);

export default BunnyPlayer;
