import React from 'react';
import { Box } from '@mui/material';
import ChatPipelineDisplay from './ChatPipelineDisplay';
import ChatRecategorize from './ChatRecategorize';
import ChatContactRecategorizeButton from './ChatContactRecategorizeButton';
import { ContactType } from '@common/types/enums';
import { PipelineStep } from '@common/PipelineManager/PipelineTypes';
import { Contact } from '@/data/Classes/Contact';

export interface ChatSidebarProps {
  contact: Contact | null;
  children?: React.ReactNode;
  onChangeStep?: (step: PipelineStep) => void;
  onRecategorize?: (category: ContactType) => void;
}

export default function ChatSidebar(props: ChatSidebarProps) {
  const [recategorizeOpen, setRecategorizeOpen] = React.useState(false);

  if (!props.contact) return null;

  return (
    <Box
      sx={{
        overflow: 'overlay',
        pt: 1,
        pl: 1
      }}>
      <ChatRecategorize
        contact={props.contact}
        open={recategorizeOpen}
        onClose={() => setRecategorizeOpen(false)}
        onRecategorize={props.onRecategorize}
      />
      {props.contact.type === ContactType.POTENTIAL_CUSTOMER ? (
        <ChatPipelineDisplay
          title=""
          contact={props.contact}
          loading={false}
          openRecategorize={() => setRecategorizeOpen(true)}
          onChangeStep={props.onChangeStep}
        />
      ) : (
        <ChatContactRecategorizeButton
          type={props.contact.type}
          onClick={() => setRecategorizeOpen(true)}
        />
      )}
      {props.children}
    </Box>
  );
}
