import {
  DashInvitationUrn,
  DashProfessionalEventUrn,
  EventUrn,
  InvitationUrn,
  MiniProfileUrn,
  RelInvitationUrn,
  RelInvitationViewUrn,
  RelSentInvitationViewUrn
} from './Urns';
import { ImageViewModel, TextViewModel } from './Common';
import { LinkedInMiniProfile } from './Identity';

export const INVITATION_TYPE_PREFIX =
  'com.linkedin.voyager.relationships.invitation';

export const INVITATION_TYPE_DASH_PREFIX =
  'com.linkedin.voyager.dash.relationships.invitation';

export enum InvitationType {
  PENDING = 'PENDING',
  SENT = 'SENT',
  EVENT = 'EVENT',
  CONNECTION = 'CONNECTION'
}

const PROFILE_INVITEE_TYPE =
  `${INVITATION_TYPE_PREFIX}.ProfileInvitee` as const;

interface LinkedInProfileInvitee {
  '$type': typeof PROFILE_INVITEE_TYPE;
  '*miniProfile': MiniProfileUrn;
  'miniProfile': LinkedInMiniProfile;
}

export const INVITATION_TYPE = `${INVITATION_TYPE_PREFIX}.Invitation` as const;

export interface LinkedInSentInvitation {
  '$type': typeof INVITATION_TYPE;
  '*fromMember': MiniProfileUrn;
  'fromMember': LinkedInMiniProfile;
  'fromMemberId': string;
  '*toMember': MiniProfileUrn;
  'toMember': LinkedInMiniProfile;
  'toMemberId': string;
  'mailboxItemId': InvitationUrn;
  'message'?: string;
  'invitee': LinkedInProfileInvitee;
  'fromEvent'?: unknown;
  'invitationType': InvitationType;
  'entityUrn': RelInvitationUrn;
  'inviterActors'?: unknown;
  'sentTime': number;
  'customMessage': boolean;
  'sharedSecret': string;
  'unseen'?: boolean;
}

export interface LinkedInReceivedInvitation {
  $type: typeof INVITATION_TYPE;
  fromMember?: LinkedInMiniProfile;
  fromMemberId?: string;
  toMember: LinkedInMiniProfile;
  toMemberId: string;
  mailboxItemId: InvitationUrn;
  message?: string;
  invitee?: LinkedInProfileInvitee;
  fromEvent?: unknown;
  invitationType?: InvitationType;
  entityUrn: RelInvitationUrn;
  inviterActors?: unknown;
  sentTime?: string;
  customMessage: boolean;
  sharedSecret: string;
  unseen?: boolean;
}

export const SENT_INVITATION_VIEW_TYPE =
  `${INVITATION_TYPE_PREFIX}.SentInvitationView` as const;

export interface LinkedInSentInvitationViewV2 {
  '$type': typeof SENT_INVITATION_VIEW_TYPE;
  '*invitation': RelInvitationUrn;
  'invitation': LinkedInSentInvitation;
  'entityUrn': RelSentInvitationViewUrn;
  'genericSentInvitationView'?: unknown;
}

export const INVITATION_VIEW_TYPE =
  `${INVITATION_TYPE_PREFIX}.InvitationView` as const;

export interface LinkedInInvitationView {
  '$type': typeof INVITATION_VIEW_TYPE;
  'showProfileInfo'?: unknown;
  'inviterFollowingInvitee'?: unknown;
  'entityUrn': RelInvitationViewUrn;
  'insights'?: unknown;
  '*invitation': RelInvitationUrn;
  'invitation': LinkedInReceivedInvitation;
  '*genericInvitationView': RelInvitationViewUrn;
  'genericInvitationView': LinkedInGenericInvitationView;
  'connectionDistance'?: unknown;
  'mutualCurrentCompany'?: unknown;
}

export const GENERIC_INVITATION_VIEW_TYPE =
  'com.linkedin.voyager.relationships.genericinvitations.GenericInvitationView';

export interface LinkedInGenericInvitationView {
  $type: typeof GENERIC_INVITATION_VIEW_TYPE;
  primaryImage: ImageViewModel;
  usePreAcceptExtension: boolean;
  title: TextViewModel;
  inviterName?: string;
  communicationHeadline?: string;
  preAcceptExtensionUseCase?: unknown;
  invitationType: InvitationType;
  entityUrn: RelInvitationViewUrn;
  communicationActions?: unknown;
  invitationTargetUrn: EventUrn;
  subtitle: TextViewModel;
  cardAction?: unknown;
  insightImage?: unknown;
  sentTime: string;
  inviterInformation?: unknown;
  typeLabel: string;
  sharedSecret: string;
  insightText?: unknown;
  unseen: boolean;
}

export const CREATED_INVITATION_TYPE =
  `${INVITATION_TYPE_DASH_PREFIX}.Invitation` as const;

export interface LinkedInCreatedInvitation {
  $type: typeof CREATED_INVITATION_TYPE;
  entityUrn: DashInvitationUrn;
}

export const DASH_SENT_INVITATION_VIEW_TYPE =
  `${INVITATION_TYPE_DASH_PREFIX}.SentInvitationView` as const;

export interface LinkedInDashSentInvitationView {
  '$type': typeof DASH_SENT_INVITATION_VIEW_TYPE;
  '$recipeTypes': string[];
  'cardActionTarget': string;
  'primaryImage': ImageViewModel;
  'sentTimeLabel': string;
  'subtitle': TextViewModel;
  '*invitation': DashInvitationUrn;
  'invitation': LinkedInDashInvitation;
  'title': TextViewModel;
}

enum InvitationState {
  PENDING
}

export const DASH_INVITATION_TYPE =
  `${INVITATION_TYPE_DASH_PREFIX}.Invitation` as const;

export interface LinkedInDashInvitation {
  $type: typeof DASH_INVITATION_TYPE;
  $recipeTypes: string[];
  inviteeMemberResolutionResult: unknown;
  inviterFollowingInvitee: boolean;
  genericInvitationType: InvitationType;
  invitationState: InvitationState;
  invitationId: number;
  message: string | null;
  genericInviter: {
    'memberProfileUrn': null;
    'contentSeries': null;
    'marketplaceProviderUrn': null;
    '*eventUrn': DashProfessionalEventUrn;
    'organizationUrn': null;
    'group': null;
  };
  preAcceptExtensionUseCase: unknown;
  invitationType: InvitationType;
  entityUrn: DashInvitationUrn;
  sharedSecret: string;
  unseen: null;
  trackingId: string | null;
}
