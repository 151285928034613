import React from 'react';

export interface ConfirmingButtonGroupContextType {
  activeButtonId: number | null;
  setActiveButtonId: (id: number | null) => void;
  getIndex: () => number;
}

export const ConfirmingButtonGroupContext =
  React.createContext<ConfirmingButtonGroupContextType>(undefined!);

export function useConfirmingButtonGoupContext() {
  return React.useContext(ConfirmingButtonGroupContext);
}

export function ConfirmingButtonGroupContextProvider(props: {
  children: React.ReactNode | React.ReactNode[];
}) {
  const { children, ...rest } = props;
  const [activeButtonId, setActiveButtonId] = React.useState<number | null>(
    null
  );
  const lastIndex = React.useRef(0);

  return (
    <ConfirmingButtonGroupContext.Provider
      value={{
        activeButtonId,
        setActiveButtonId,
        getIndex() {
          return lastIndex.current++;
        }
      }}>
      {children}
    </ConfirmingButtonGroupContext.Provider>
  );
}
