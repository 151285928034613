import ChatStorage from '@/data/Classes/Chat/ChatStorage';
import ChatActions from '@/data/DataServer/Chat';
import { getSelf } from '@/data/LinkedIn/Account';
import Auth from '@common/AuthManager/Auth.renderer';
import MessageBus from '@common/MessageBus/MessageBus.renderer';
import { Message } from '@common/types/ApiTypes';
import { chats } from '@digital-sun-solutions/cloud-functions';
import {
  ApiEvent,
  Event,
  MessageEvent,
  ReactionSummary,
  ReactionSummaryEvent
} from 'linkedin-domain-types';

export async function handleRealtimeEvents(event: ApiEvent<Event>) {
  const mb = MessageBus.getInstance();
  switch (event.type) {
    case Event.MESSAGE:
      mb.emit('RealtimeEvent:message', event);
      await handleMessageEvent(event as MessageEvent);
      break;
    case Event.REACTION_SUMMARY:
      mb.emit('RealtimeEvent:reaction', event);
      handleReactionEvent(event as ReactionSummaryEvent);
      break;
    case Event.REALTIME:
      // filter out heartbeat events
      if (
        'data' in event &&
        typeof event.data === 'object' &&
        event.data &&
        'com.linkedin.realtimefrontend.Heartbeat' in event.data
      )
        break;

      mb.emit('RealtimeEvent:unparsed', event);
      break;
    default:
      break;
  }
}

const seenEvents = new Map<string, number>();
setInterval(() => {
  seenEvents.forEach((time, messageID) => {
    if (Date.now() - time > 1000 * 60 * 5) {
      seenEvents.delete(messageID);
    }
  });
}, 60_000);

async function handleMessageEvent(event: MessageEvent) {
  // prevent parsing same event twice (can happen if multiple realtime instances are running)
  if (seenEvents.has(event.event.entityUrn.toString())) return;
  seenEvents.set(event.event.entityUrn.toString(), Date.now());
  const self = await getSelf(true);
  const fullMessageID = event.event.entityUrn.split(':').pop() ?? '';
  const messageID = fullMessageID.split(',').pop()?.slice(0, -1);
  const conversationID = fullMessageID.split(',').shift()?.slice(1);
  const senderID = event.event.from?.miniProfile?.entityUrn.split(':').pop();
  const senderPublicIdentifier =
    event.event.from?.miniProfile?.publicIdentifier;
  if (!conversationID || !senderID || !messageID) return;

  const message: Message & { sentFrom?: string } = {
    text: event.event.eventContent.body,
    attachments: (event.event.eventContent.attachments ?? []).map((a) => ({
      type: a.mediaType.startsWith('image/') ? 'image' : 'file',
      url: a.reference
    })),
    reactions:
      (event.event.reactionSummaries as ReactionSummary[] | undefined) ?? [],
    createdAt: new Date(event.event.createdAt),
    messageID,
    deleted: false,
    sendByYou: senderID === self?.profileID,
    sentFrom: senderPublicIdentifier
  };

  console.debug(
    `[Realtime Event] received message event for chat ${conversationID}`,
    event
  );

  await Auth.execRoute((token) =>
    chats.addMessages(
      {
        conversationID,
        messages: [message]
      },
      { token }
    )
  );

  ChatStorage.registerMessage(conversationID, message, true);
  ChatActions.invalidateChatsCache(conversationID, false);
}

async function handleReactionEvent(event: ReactionSummaryEvent) {
  // event might come twice, but there is with the current way of handling no way to prevent that
  // still this does not cause any issues since the reaction is set and not added

  const fullMessageID = event.message.split(':').pop() ?? '';
  const messageID = fullMessageID.split(',').pop()?.slice(0, -1);
  const conversationID = fullMessageID.split(',').shift()?.slice(1);

  if (!conversationID || !messageID) return;

  console.debug(
    `[Realtime Event] received reaction event for chat ${conversationID}`,
    event
  );

  await Auth.execRoute((token) =>
    chats.addReactions(
      {
        messageID,
        reactions: [
          {
            count: event.reactionSummary.count,
            emoji: event.reactionSummary.emoji,
            viewerReacted: event.reactionSummary.viewerReacted
          }
        ]
      },
      { token }
    )
  );

  ChatStorage.getChats(conversationID).forEach((chat) =>
    chat.registerReaction(messageID, event.reactionSummary)
  );
}
