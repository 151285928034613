import React from 'react';
import { Button, ButtonProps, Checkbox } from '@mui/material';

export interface CheckBoxButtonProps
  extends Omit<ButtonProps, 'value' | 'onChange' | 'onClick' | 'startIcon'> {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const CheckBoxButton = React.forwardRef<HTMLButtonElement, CheckBoxButtonProps>(
  (props, ref) => {
    const { checked, onChange, ...rest } = props;
    return (
      <Button
        {...rest}
        startIcon={
          <Checkbox
            sx={{ pointerEvents: 'none', color: 'inherit !important', p: 0 }}
            checked={checked}
          />
        }
        ref={ref}
        onClick={() => {
          onChange(!checked);
        }}
      />
    );
  }
);

export default CheckBoxButton;
