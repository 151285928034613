import React from 'react';
import { Box, Typography } from '@mui/material';
import TrainingRouter, { TRAINING_SECTIONS } from './TrainingRouter';
import { useLocation, useNavigate } from 'react-router-dom';
import { wait } from '@idot-digital/generic-helpers';
import { language } from '@/index';
import { APP_PATHS, MANUAL_MODE_PATHS } from '@/globals/Paths';
import { OptionalTooltip } from 'ui-utils';

export const TRAINING_CERTIFICATE_KEY = 'training-certificate-done';

export default function Training() {
  const navigate = useNavigate();
  const ROOT_ROUTE = `${APP_PATHS.Manual}/${MANUAL_MODE_PATHS.Training}`;
  const { pathname: path } = useLocation();

  const [sectionStates, setSectionStates] = React.useState<
    { loading: boolean; disabled: boolean }[]
  >(
    TRAINING_SECTIONS.map((section) =>
      section.disabled && typeof section.disabled === 'function'
        ? { loading: true, disabled: false }
        : { loading: false, disabled: section.disabled ?? false }
    )
  );
  React.useEffect(() => {
    let mounted = true;

    (async () => {
      const newSectionsStates = await Promise.all(
        sectionStates.map(async (sectionState, index) => {
          const disabledFct = TRAINING_SECTIONS[index].disabled;
          if (typeof disabledFct !== 'function') return sectionState;
          await wait(1000);
          const disabled = await disabledFct();
          return { loading: false, disabled };
        })
      );

      if (!mounted) return;
      setSectionStates(newSectionsStates);
    })();

    return () => {
      mounted = false;
    };
  }, []);

  const activePath = React.useMemo(() => {
    const pathParts = path.split('/');
    return pathParts[3] ?? '';
  }, [path]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        p: 2,
        boxSizing: 'border-box',
        backgroundColor: (theme) => theme.palette.background.default
      }}>
      <Box
        sx={{
          width: '100%',
          mb: 2,
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
          gap: 2
        }}>
        {TRAINING_SECTIONS.map((section, i) => (
          //TODO: make this a component
          <OptionalTooltip
            key={section.path}
            title={
              sectionStates[i].disabled
                ? section.disabledTooltip?.()
                : undefined
            }>
            <Box
              onClick={
                !sectionStates[i].disabled && !sectionStates[i].loading
                  ? () => navigate(`/${ROOT_ROUTE}/${section.path}`)
                  : undefined
              }
              sx={{
                'maxWidth': '30rem',
                'cursor': sectionStates[i].disabled
                  ? 'not-allowed'
                  : sectionStates[i].loading
                    ? 'progress'
                    : 'pointer',
                'p': 1,
                'backgroundColor': (theme) =>
                  activePath == section.path
                    ? theme.palette.background.paper
                    : 'transparent',
                'transition': (theme) =>
                  theme.transitions.create(['background-color']),
                'borderRadius': 1,
                'borderWidth': '1px',
                'borderStyle': 'solid',
                'borderColor': (theme) =>
                  activePath == section.path
                    ? theme.palette.action.disabled
                    : 'transparent',
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.action.hover
                }
              }}>
              <img
                style={{
                  display: 'block',
                  margin: 'auto',
                  width: 'auto',
                  height: '20vh',
                  maxHeight: '200px',
                  opacity: activePath == section.path ? 1 : 0.5
                }}
                src={section.imgSrc}
              />
              <Typography
                variant="body1"
                sx={{
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}>
                {section.label()}
              </Typography>
              {sectionStates[i].loading && (
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: 'center'
                  }}>
                  {language.text.loading}...
                </Typography>
              )}
              {sectionStates[i].disabled && (
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: 'center'
                  }}>
                  {language.text.coming_soon}
                </Typography>
              )}
            </Box>
          </OptionalTooltip>
        ))}
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          boxSizing: 'border-box',
          p: 2,
          border: (theme) => `1px solid ${theme.palette.action.disabled}`,
          borderRadius: 1,
          backgroundColor: (theme) => theme.palette.background.paper,
          gridArea: 'route',
          overflow: 'auto',
          position: 'relative'
        }}>
        <TrainingRouter />
      </Box>
    </Box>
  );
}
