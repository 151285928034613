import { Box, Typography } from '@mui/material';
import ProgressionReferrerStepper from './ProgressionReferrerStepper';
import { language } from '@/index';
import AffiliateActions from '@/data/DataServer/Affiliate';
import { CopyTextButton, DelayedSkeleton, MoneyCircle } from 'ui-utils';

export default function ProgressionReferrer() {
  const { referralCode, status } = AffiliateActions.useReferralCode();

  if (status === 'loading')
    return (
      <DelayedSkeleton
        width="80%"
        height={200}
        sx={{ m: 'auto' }}
        quantity={3}
      />
    );
  if (status === 'error')
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Typography variant="h5">{language.text.error}</Typography>
        <Typography>{language.text.generic_error}</Typography>
      </Box>
    );

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, minmax(5vw, 300px))',
          gap: 4,
          justifyContent: 'center'
        }}>
        {[
          {
            name: language.text.default_price,
            value: referralCode.price.original,
            percentage: 1
          },
          {
            name: language.text.price,
            value: referralCode.price.remaining,
            percentage:
              referralCode.price.remaining / referralCode.price.original
          },
          {
            name: language.text.money_saved,
            value: referralCode.price.original - referralCode.price.remaining,
            percentage:
              (referralCode.price.original - referralCode.price.remaining) /
              referralCode.price.original
          }
        ].map((item) => (
          <MoneyCircle
            key={item.name}
            value={item.value}
            filled={item.percentage}
            title={item.name}
          />
        ))}
      </Box>
      <Typography mt={4} variant="h4" textAlign="center">
        {language.text.your_referral_code}:
      </Typography>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        my={2}>
        <CopyTextButton
          popupText={language.text.code_copied}
          size="large"
          sx={{
            width: 'fit-content',
            fontSize: '1.5rem',
            borderRadius: 2
          }}>
          {referralCode.code}
        </CopyTextButton>
        {!referralCode.active && (
          <Typography variant="caption">
            {language.text.referral_code_inactive}
          </Typography>
        )}
      </Box>
      <Typography variant="h4" textAlign="center" mt={4}>
        {language.text.referrer_status}
      </Typography>
      <ProgressionReferrerStepper
        current={referralCode.count.current}
        max={referralCode.count.max}
      />
      <Typography variant="caption" textAlign="center" component="p" mt={1}>
        {language.text.you_referred_x_users({
          count: referralCode.count.current,
          max: referralCode.count.max
        })}
      </Typography>
    </>
  );
}
