import React from 'react';
import {
  FormControl,
  FormControlProps,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectProps
} from '@mui/material';

export type SelectValue = string | number | readonly string[] | undefined;
type MenuValue<Value extends SelectValue> = Value extends (infer T)[]
  ? T
  : Value;

export interface SelectWrapperProps<Value extends SelectValue> {
  label?: string;
  variant?: FormControlProps['variant'];
  value: Value;
  onChange?: (value: Value) => void;
  options?: (
    | {
        value: MenuValue<Value>;
        label: React.ReactNode | React.ReactNode[];
        divider?: boolean;
      }
    | { divider: React.ReactNode }
  )[];
  children?: React.ReactNode[] | React.ReactNode;
  sx?: SelectProps['sx'];
  rootSx?: FormControlProps['sx'];
  disabled?: boolean;
  hideUnderline?: boolean;
  fullWidth?: boolean;
  renderValue?: (value: Value) => React.ReactNode;
}

let currentID = 0;

export default function SelectWrapper<Value extends SelectValue>(
  props: SelectWrapperProps<Value>
) {
  const id = React.useRef(currentID++).current;

  const select = (
    <Select
      renderValue={props.renderValue}
      multiple={Array.isArray(props.value)}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
      labelId={props.label ? `select-wrapper-${id}` : undefined}
      value={props.value}
      label={props.label}
      sx={{
        ...props.sx,
        ...(props.hideUnderline && {
          '::before, ::after': {
            borderBottom: 'none !important'
          }
        })
      }}
      onChange={(e) => {
        props.onChange?.(e.target.value as Value);
      }}>
      {props.options?.map((option, index) => {
        if ('value' in option)
          return (
            <MenuItem value={option.value} key={index} divider={option.divider}>
              {option.label}
            </MenuItem>
          );
        return <ListSubheader key={index}>{option.divider}</ListSubheader>;
      })}
      {props.children}
    </Select>
  );

  if (props.label)
    return (
      <FormControl variant={props.variant ?? 'filled'} sx={props.rootSx}>
        <InputLabel id={`select-wrapper-${id}`}>{props.label}</InputLabel>
        {select}
      </FormControl>
    );

  return select;
}
