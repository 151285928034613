import { StartCountIterator } from './StartCountIterator';
import { Options, trace } from '../client/Utils';

type FetchInvitations<T> = ({
  start,
  count,
  options
}: {
  start?: number;
  count?: number;
  options: Options;
}) => Promise<Iterable<T>>;

@trace()
export class InvitationIterator<T> implements AsyncIterator<T> {
  /**
   * The paging iterator
   */
  private readonly iterator: InvitationPageIterator<T>;

  /**
   * The current invitation page
   */
  private current: T[] = [];

  /**
   * The current index
   */
  private index = 0;

  /**
   * Creates a new InvitationIterator
   */
  constructor(params: {
    fetchInvitations: FetchInvitations<T>;
    start?: number;
    count?: number;
    options: Options;
  }) {
    this.iterator = new InvitationPageIterator({
      start: 0,
      count: 10,
      ...params
    });
  }

  /**
   * Returns the next invitation
   */
  async next(): Promise<IteratorResult<T, T>> {
    if (this.index >= this.current.length) {
      const { value, done } = await this.iterator.next();
      if (done) {
        return { value: {} as T, done: true };
      }
      this.current = value;
      this.index = 0;
    }
    return { value: this.current[this.index++], done: false };
  }

  /**
   * Returns the previous invitation
   */
  async prev(): Promise<IteratorResult<T, T>> {
    if (this.index <= 0) {
      const { value, done } = await this.iterator.prev();
      if (done) {
        return { value: {} as T, done: true };
      }
      this.current = value;
      this.index = this.current.length - 1;
    }
    return { value: this.current[this.index--], done: false };
  }

  /**
   * Restarts the iterator from the beginning
   */
  restart() {
    this.iterator.restart();
    this.current = [];
    this.index = 0;
  }
}

/**
 * Iterates over all invitations in a LinkedIn account returning them as single entities
 */
@trace()
class InvitationPageIterator<T> extends StartCountIterator<T> {
  /**
   * The function that fetches invitations
   * @private the function that fetches invitations
   */
  private readonly fetchInvitations: FetchInvitations<T>;

  /**
   * Options of the requests
   */
  private readonly options: Options;

  /**
   * Creates a new InvitationIterator
   * @param fetchInvitations the function that fetches invitations
   * @param start the number of invitations to skip (default: 0)
   * @param count the number of invitations to fetch (default: 10)
   * @param options the options to use for the request
   */
  constructor({
    fetchInvitations,
    start = 0,
    count = 10,
    options
  }: {
    fetchInvitations: FetchInvitations<T>;
    start: number;
    count: number;
    options: Options;
  }) {
    super({ start, count });
    this.options = options;
    this.fetchInvitations = fetchInvitations;
  }

  /**
   * Fetches the next page of invitations and advances the iterator
   * @protected fetches the next page of invitations
   */
  protected async fetch(): Promise<Iterable<T>> {
    return this.fetchInvitations({
      start: this.start,
      count: this.count,
      options: this.options
    });
  }
}
