import React from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography
} from '@mui/material';
import StartWrapper from './StartWrapper';
import { ChevronRight } from '@mui/icons-material';
import { FancyButton, Loading } from 'ui-utils';
import { FocusMode } from 'focus-mode-scheduler';
import { language } from '@/index';
import FocusModeActions from '../Modes';
import { Navigate } from 'react-router-dom';
import { APP_PATHS } from '@/globals/Paths';
import posthog from 'posthog-js';
import tracking from 'tracking';

export interface StartProps {
  onComplete: (mode: FocusMode & { custom?: boolean }) => void;
}

export default function Start(props: StartProps) {
  const { modes, status } = FocusModeActions.useFocusModes();
  const [currentMode, setCurrentMode] = React.useState<FocusMode | null>(null);

  if (status === 'loading') return <Loading />;
  if (status === 'error')
    return (
      <Navigate
        to={`/${APP_PATHS.Error}/${encodeURIComponent('Failed to load focus modes')}`}
      />
    );

  return (
    <StartWrapper onBack={setCurrentMode.bind(null, null)} mode={currentMode}>
      {currentMode ? (
        // mode details
        <>
          <FancyButton
            variant="contained"
            fullWidth
            size="large"
            onClick={() => {
              if (!currentMode) return;

              posthog.capture('focus-mode-start');
              tracking.capture('FocusModeStart');
              props.onComplete(currentMode);
            }}>
            {language.text.lets_go}
          </FancyButton>
          <FancyButton
            color="inherit"
            variant="contained"
            fullWidth
            size="large"
            onClick={setCurrentMode.bind(null, null)}>
            {language.text.back}
          </FancyButton>
          {currentMode &&
            currentMode.tasks.filter(
              (task) => task.info.title && task.info.description && !task.hide
            ).length > 0 && (
              <Box
                sx={{
                  width: '100%',
                  maxHeight: '100%',
                  overflowY: 'auto',
                  textAlign: 'left'
                }}>
                <Typography variant="h6">{language.text.tasks}</Typography>
                <Box mt={1}>
                  {currentMode.tasks
                    .filter(
                      (task) =>
                        task.info.title && task.info.description && !task.hide
                    )
                    .map((task) => (
                      <Box key={task.id}>
                        <Typography
                          variant="body2"
                          sx={{
                            display: 'inline-block',
                            fontWeight: 'bold',
                            mr: 0.5,
                            mb: 0.5
                          }}>
                          - {task.info.title}:
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            display: 'inline-block'
                          }}>
                          {task.info.description}
                        </Typography>
                      </Box>
                    ))}
                </Box>
              </Box>
            )}
        </>
      ) : (
        // mode selection
        <>
          <Box
            sx={{
              width: '100%'
            }}>
            <Typography variant="h4" fontWeight="bold" textAlign="center">
              {language.text.welcome_to_focus_mode}
            </Typography>
            <Typography variant="body2" textAlign="center">
              {language.text.focus_mode_description}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              maxHeight: '100%',
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              px: 2,
              gap: 2
            }}>
            {(modes ?? []).map((mode) => (
              <Card
                variant="outlined"
                key={mode.id.toString()}
                onClick={setCurrentMode.bind(null, mode)}>
                <CardActionArea>
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      textAlign: 'left'
                    }}>
                    <Box>
                      <Typography variant="body1" fontWeight="bold" mb={1}>
                        {mode.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          display: '-webkit-box',
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden'
                        }}>
                        {mode.excerpt || mode.description}
                      </Typography>
                    </Box>
                    <ChevronRight />
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
          </Box>
        </>
      )}
    </StartWrapper>
  );
}
