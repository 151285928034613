import { Box, Typography } from '@mui/material';
import { Contact } from '@/data/Classes/Contact';
import ContactTags from '@/components/Contact/ContactTags';
import ContactAvatar from '@/components/Avatar/ContactAvatar';

export interface ContactDisplayProps {
  contact?: Pick<Contact, 'name'> &
    Partial<Pick<Contact, 'pictures' | 'firstname' | 'lastname' | 'priority'>> &
    Partial<Pick<Contact, 'tags' | 'addTag' | 'removeTag'>>;
  onClick?: () => void;
}

export default function ContactDisplay(props: ContactDisplayProps) {
  return (
    <Box mb={2}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          ...(props.onClick && {
            ':hover': {
              cursor: 'pointer',
              h6: {
                textDecoration: 'underline'
              }
            }
          })
        }}
        onClick={() => props.onClick?.()}>
        <ContactAvatar
          contact={{
            firstname: props.contact?.firstname,
            lastname: props.contact?.lastname,
            name: props.contact?.name ?? '',
            pictures: props.contact?.pictures ?? {},
            priority: props.contact?.priority ?? undefined
          }}
          sx={{
            height: (theme) => theme.spacing(8),
            width: (theme) => theme.spacing(8)
          }}
          rootSx={{ m: 'unset' }}
        />
        <Typography
          variant="h6"
          sx={{
            color: (theme) => theme.palette.text.primary
          }}>
          {props.contact?.name}
        </Typography>
      </Box>
      {props.contact?.tags !== undefined && (
        <ContactTags contact={props.contact as Contact} editable mt={1} />
      )}
    </Box>
  );
}
