import { AxiosInstance, AxiosProxyConfig } from 'axios';

import { LoginService } from '../services/LoginService';
import { MessageService } from '../services/MessageService';
import { ConversationService } from '../services/ConversationService';
import { LinkedInRequestService } from '../services/LinkedInRequestService';
import { ProfileService } from '../services/ProfileService';
import { InviteService } from '../services/InviteService';
import { SearchService } from '../services/SearchService';
import { Logger, trace } from './Utils';
import { RealtimeService } from '../services/RealtimeService';
import { EventService } from '../services/EventService';
import { FeedService } from '../services/FeedService';

interface ClientOpts {
  proxy?: AxiosProxyConfig;
}

/**
 * The main client class
 * @class Client - The main client class
 *
 * @property {LinkedInRequestService} requestService - The request service
 * @property {ConversationService} conversationService - The conversation service
 * @property {MessageService} messageService - The message service
 * @property {LoginService} loginService - The login service
 * @property {ProfileService} profileService - The profile service
 * @property {InviteService} inviteService - The invite service
 *
 * @example
 * const client = new Client();
 * await client.loginService.cookieLogin({ JSESSIONID, li_at });
 * const profile = await client.profileService.getMe();
 * console.log(profile);
 *
 */
@trace()
export class Client {
  constructor({ proxy }: ClientOpts = {}, requestInstance?: AxiosInstance) {
    Logger.debug('Creating new client');
    this.requestService = new LinkedInRequestService(
      { proxy },
      requestInstance
    );
    this.eventService = new EventService();
    this.conversationService = new ConversationService(this);
    this.messageService = new MessageService(this);
    this.loginService = new LoginService(this);
    this.profileService = new ProfileService(this);
    this.inviteService = new InviteService(this);
    this.searchService = new SearchService(this);
    this.realtimeService = new RealtimeService(this);
    this.feedService = new FeedService(this);
    Logger.debug('Created new client');
  }
  requestService: LinkedInRequestService;
  profileService: ProfileService;
  loginService: LoginService;
  conversationService: ConversationService;
  messageService: MessageService;
  inviteService: InviteService;
  searchService: SearchService;
  realtimeService: RealtimeService;
  eventService: EventService;
  feedService: FeedService;
}
