import React from 'react';
import {
  EventHandler,
  EventName,
  EventPayload,
  MessagePersistence
} from './MessageBusUtilTypes';
import AbstractMessageBus from './AbstractMessageBus';
import { SendData } from '@common/types/ipc';

export default class MessageBus extends AbstractMessageBus {
  private static instance?: MessageBus;

  constructor() {
    super();
    if (!window.location.hash.startsWith('#debugger')) {
      console.debug('MessageBus: Trying to connect to ws');
    } else {
      console.log('MessageBus: Debug mode');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.on('auth-renderer:logged-in', ({ sessionToken }) => {
      console.debug('MessageBus: Renderer is ready');
    });
    // if on renderer thread, listen to ipc messages from main and push them to local listeners
    window.api.handle('messagebus:emit', (_, event) => {
      let id = event.id;
      if (!id) id = this.generateMessageID();
      this.pushEventToListeners({
        eventName: event.name,
        payload: event.payload as unknown as EventHandler<EventName>,
        id,
        persistence: event.persistence
      });
    });
  }

  public static getInstance(): MessageBus {
    if (!MessageBus.instance) MessageBus.instance = new MessageBus();
    return MessageBus.instance;
  }

  protected relayMessages<Name extends EventName>(
    eventName: Name,
    payload: EventPayload<Name>,
    persistence: MessagePersistence,
    id: string
  ): void {
    // if on renderer thread, send message over ipc to main
    window.api.send('messagebus:emit', {
      name: eventName,
      payload,
      id,
      persistence
    } as SendData<'messagebus:emit'>[0]);
  }

  public static use<Name extends EventName>(
    channel: Name,
    handler: EventHandler<Name>
  ) {
    React.useEffect(() => {
      const messageBus = MessageBus.getInstance();
      messageBus.on(channel, handler);
      return () => {
        messageBus.removeHandler(channel, handler);
      };
    }, []);
  }
  public use<Name extends EventName>(
    channel: Name,
    handler: EventHandler<Name>
  ) {
    MessageBus.use(channel, handler);
  }
}
