import {
  ActivityUrn,
  CommentUrn,
  DashAdServingUrn,
  DashCommentUrn,
  DashSocailActivityCountsUrn,
  DashSocialDetailUrn,
  DashTranslationUrn,
  DashUpdateUrn,
  FollowingInfoUrn,
  MemberUrn,
  MiniJobUrn,
  MiniProfileUrn,
  ObjectCommentUrn,
  ShareUrn,
  SocialActivityCountsUrn,
  SocialDetailUrn,
  SocialPermissionsUrn,
  SponsoredAccountUrn,
  SponsoredCampaignUrn,
  SponsoredCreativeUrn,
  TranslationUrn,
  UpdateV2Urn,
  Urn
} from './Urns';
import {
  CompanyName,
  LinkedInFollowingInfo,
  LinkedInMemberDistance,
  TextViewModel
} from './Common';
import { LinkedInMiniJob } from './Entities';
import {
  LinkedInCommenter,
  LinkedInSocialPermissions,
  ReactionTypeCount
} from './FeedSocial';
import { CollectionMetadata } from './RestliCommon';
import { ProfileId } from './Profile';
import { LinkedInMiniProfile } from './Identity';
import { LinkedInSocialContent } from './Social';
import {
  LinkedInCommentaryComponent,
  LinkedInSocialActorComponent
} from './FeedComponent';
import { LinkedInSocialUpdateMetadata } from './FeedMetadata';

const FEED_TYPE_PREFIX = 'com.linkedin.voyager.feed';
const DASH_FEED_PREFIX = 'com.linkedin.voyager.dash.feed';

const FEED_TYPE_SHARED_PREFIX = 'com.linkedin.voyager.feed.shared';

export const FEED_EXTERNAL_COMPANY_TYPE =
  `${FEED_TYPE_SHARED_PREFIX}.ExternalCompany` as const;

export interface LinkedInExternalCompany {
  $type: typeof FEED_EXTERNAL_COMPANY_TYPE;
  companyName: CompanyName;
}

export const JYMBII_UPDATE_TYPE =
  `${FEED_TYPE_SHARED_PREFIX}.JymbiiUpdate` as const;

export interface LinkedInJymbiiUpdate {
  '$type': typeof JYMBII_UPDATE_TYPE;
  'urn': MiniJobUrn;
  '*miniJob': MiniJobUrn;
  'miniJob'?: LinkedInMiniJob;
  'company': LinkedInExternalCompany;
}

export const SOCIAL_ACTIVITY_COUNTS_TYPE =
  `${FEED_TYPE_SHARED_PREFIX}.SocialActivityCounts` as const;

export interface LinkedInSocialActivityCounts {
  $type: typeof SOCIAL_ACTIVITY_COUNTS_TYPE;
  socialDetailEntityUrn: SocialDetailUrn;
  reactionText?: unknown;
  dashEntityUrn: DashSocailActivityCountsUrn;
  reactionByOrganizationActor?: unknown;
  numLikes: number;
  liked: boolean;
  numViews?: number;
  urn: ActivityUrn;
  numComments: number;
  reactionTypeCounts: ReactionTypeCount[];
  likedByOrganizationActor?: unknown;
  entityUrn: SocialActivityCountsUrn;
  numShares: number;
  numImpressions?: number;
  reacted?: boolean;
}

export const SOCIAL_DETAIL_TYPE = `${FEED_TYPE_PREFIX}.SocialDetail` as const;

export interface LinkedInSocialDetail {
  '$type': typeof SOCIAL_DETAIL_TYPE;
  'hideSocialCountsIfAllowed': unknown;
  'dashEntityUrn': DashSocialDetailUrn;
  'quickComments': unknown;
  'socialPermissionsPersonalTopicUrn': unknown;
  'liked': boolean;
  '*socialPermissions': SocialPermissionsUrn;
  'socialPermissions': LinkedInSocialPermissions;
  'threadId': string;
  'pillText': unknown;
  'feedDetailSocialActionPromptType': unknown;
  'entityUrn': SocialDetailUrn;
  'socialUpdateType': unknown;
  'reactionsTopicUrn': unknown;
  'showReplyPrivatelyButton': unknown;
  'updateSaveAction': unknown;
  'reactionElements': unknown;
  'reactionSummariesTopicUrn': null;
  'detailPageUpdateUrn': null;
  '*totalSocialActivityCounts': SocialActivityCountsUrn;
  'totalSocialActivityCounts': LinkedInSocialActivityCounts;
  'showPremiumAnalytics': boolean;
  'hideFirstPrompt': boolean;
  'showShareButton': boolean;
  'totalShares': number;
  'urn': Urn;
  'allowedCommentersScope': 'ALL' | 'CONNECTIONS';
  'reactionsOnCommentsTopicUrn': null;
  'commentingDisabled': boolean;
  'reactionsSummariesTopicUrn': null;
  'companyActor': null;
  'commentsTopicUrn': null;
  'reshareUpdateUrn': ShareUrn | null;
  'socialDetailsTopicUrn': null;
  'commentSocialPermissionsTopicUrn': null;
  'pillNavigationContext': null;
  'detailPageNavigationContext': null;
}

export interface ActivitySocialDetail extends LinkedInSocialDetail {
  urn: ActivityUrn;
  likes?: LinkedInLikes;
  comments?: LinkedInComments;
}

export const LIKES_TYPE = `${FEED_TYPE_PREFIX}.Likes` as const;

export interface LinkedInLikes {
  $type: typeof LIKES_TYPE;
  paging: CollectionMetadata;
  elements: unknown[];
}

export const COMMENTS_TYPE = `${FEED_TYPE_PREFIX}.Comments` as const;

export interface LinkedInComments {
  $type: typeof COMMENTS_TYPE;
  paging: CollectionMetadata;
  elements: unknown[];
}

export const TRACKING_DATA_TYPE =
  `${FEED_TYPE_SHARED_PREFIX}.TrackingData` as const;

export interface LinkedInTrackingData {
  $type: typeof TRACKING_DATA_TYPE;
  sponsoredTracking?: SponsoredMetadata;
  trackingId: string;
  requestId: string;
}

export const SPONSORED_METADATA_TYPE =
  `${FEED_TYPE_SHARED_PREFIX}.SponsoredMetadata` as const;
export interface SponsoredMetadata {
  $type: typeof SPONSORED_METADATA_TYPE;
  adRequestId: string;
  displayFormat: string;
  adUrn: SponsoredCreativeUrn;
  videoBehavior: string;
  version: string;
  adServingUrn: DashAdServingUrn;
  tscpUrl: string;
  sponsoredCampaignUrn: SponsoredCampaignUrn;
  adTrackingCode: string;
  advertiserUrn: SponsoredAccountUrn;
  activityType: 'SPONSORED';
  internalExperimentAssignmentKeyValuePairs: string | Record<string, string>;
}

export const COMMENT_TYPE = `${FEED_TYPE_PREFIX}.Comment` as const;

export interface LinkedInComment {
  '$type': typeof COMMENT_TYPE;
  'dashEntityUrn': DashCommentUrn;
  'pinned': boolean;
  'dashTranslationUrn': DashTranslationUrn;
  'commenterForDashConversation': LinkedInCommenter;
  'annotationActionType': unknown;
  'commentPrompt': unknown;
  'content': unknown;
  'threadId': string;
  'dashParentCommentUrn': unknown;
  'translatedText': unknown;
  'entityUrn': ObjectCommentUrn;
  'parendCommentUrn': unknown;
  'commentV2': TextViewModel;
  'createdTime': number;
  'canDelete': boolean;
  'headline': unknown;
  'contributed': boolean;
  'trackingId'?: string;
  'annotation': unknown;
  'commenterProfileId': ProfileId;
  'edited': boolean;
  'threadUrn': unknown;
  'index': number;
  'timeOffset': number;
  'translationUrn': TranslationUrn;
  'originalLanguage': string;
  'parentCommentBackendUrn': unknown;
  'createdDuringLiveEvent': unknown;
  'rootSocialPermissions': unknown;
  'commenter': LinkedInMemberActor;
  'urn': CommentUrn;
  'displayReason': string;
  'insight': unknown;
  '*socialDetail': SocialDetailUrn;
  'socialDetail': LinkedInSocialDetail;
  'comment': LinkedInAnnotatedText;
  'permalink': string;
  'groupMembership': unknown;
  'actions': string[];
}

export const MEMBER_ACTOR_TYPE = `${FEED_TYPE_PREFIX}.MemberActor` as const;

export interface LinkedInMemberActor {
  '$type': typeof MEMBER_ACTOR_TYPE;
  'urn': MemberUrn;
  'distance': LinkedInMemberDistance;
  '*miniProfile': MiniProfileUrn;
  'miniProfile': LinkedInMiniProfile;
  '*followingInfo': FollowingInfoUrn;
  'followingInfo': LinkedInFollowingInfo;
  'showFollowAction': boolean;
  'skillNames': unknown[];
}

export const ANNOTATED_TEXT_TYPE =
  `${FEED_TYPE_SHARED_PREFIX}.AnnotatedText` as const;

export interface LinkedInAnnotatedText {
  $type: typeof ANNOTATED_TEXT_TYPE;
  values: LinkedInAnnotatedString[];
}

export const ANNOTATED_STRING_TYPE =
  `${FEED_TYPE_SHARED_PREFIX}.AnnotatedString` as const;

export interface LinkedInAnnotatedString {
  $type: typeof ANNOTATED_STRING_TYPE;
  value: string;
}

export const UPDATE_TYPE = `${FEED_TYPE_PREFIX}.Update` as const;

export interface LinkedInUpdate {
  '$type': typeof UPDATE_TYPE;
  'metadata': LinkedInSocialUpdateMetadata;
  'socialContent': LinkedInSocialContent;
  'showSocialDetail': boolean;
  'footer': unknown;
  'debugInfo': unknown;
  'carouselContent': unknown;
  'content': unknown;
  'attachment': unknown;
  'detailHeader': unknown;
  'entityUrn': DashUpdateUrn;
  'leadGenFormContent': unknown;
  'annotation': unknown;
  'contextualHeader': unknown;
  'resharedUpdate': unknown;
  'interstitial': unknown;
  'aggregatedContent': unknown;
  'contextualDescription': unknown;
  'actor': LinkedInSocialActorComponent;
  '*socialDetail': DashSocialDetailUrn;
  'socialDetail'?: LinkedInSocialDetail;
  'header': unknown; //LinkedInHeaderComponent
  'highlightedComments': unknown[];
  'preDashEntityUrn': UpdateV2Urn;
  'commentary': LinkedInCommentaryComponent;
  'additionalContents': [];
}
