import { DashProfileUrn, MemberUrn, MiniProfileUrn } from './Urns';
import { LinkedInVectorImage } from './Common';

const IDENTITY_TYPE_PREFIX = 'com.linkedin.voyager.identity.shared';
export const MINI_PROFILE_TYPE = `${IDENTITY_TYPE_PREFIX}.MiniProfile` as const;

export interface LinkedInMiniProfile {
  $type: typeof MINI_PROFILE_TYPE;
  customPronoun?: string;
  memorialized: boolean;
  lastName: string;
  dashEntityUrn: DashProfileUrn;
  standardizedPronoun?: string;
  occupation: string;
  objectUrn: MemberUrn;
  backgroundImage?: LinkedInVectorImage;
  firstName: string;
  entityUrn: MiniProfileUrn;
  picture?: LinkedInVectorImage;
  publicIdentifier: string;
  trackingId: string;
}
