function redefineFetch() {
  if (!('newFunctions' in window)) return;

  const ignoreURLS = ['https://static.licdn.com/', 'https://media.licdn.com/'];

  function ignoreRequest(url: string) {
    if (ignoreURLS.some((urlToIgnore) => url.includes(urlToIgnore)))
      return true;
    return false;
  }

  // @ts-ignore
  const logInterceptedRequest = window.newFunctions.logInterceptedRequest;

  const originalOpen = XMLHttpRequest.prototype.open;

  XMLHttpRequest.prototype.open = function () {
    this.addEventListener('load', function () {
      if (ignoreRequest(this.responseURL)) return;

      const url = this.responseURL;

      let dataToPost;

      switch (this.responseType) {
        case '':
        case 'text':
          dataToPost = this.responseText;
          break;

        case 'document':
          dataToPost = logInterceptedRequest({
            url,
            //@ts-ignore
            data: new XMLSerializer().serializeToString(this.responseXML)
          });
          break;

        case 'blob':
          const blobReader = new FileReader();
          blobReader.onloadend = function () {
            logInterceptedRequest({ url, data: blobReader.result });
          };
          blobReader.readAsText(this.response); // or `.readAsDataURL` based on data type
          return;

        case 'arraybuffer':
          const textDecoder = new TextDecoder('utf-8'); // Use the appropriate encoding here
          logInterceptedRequest({
            url,
            data: textDecoder.decode(this.response)
          });
          break;

        case 'json':
          logInterceptedRequest({ url, data: JSON.stringify(this.response) });
          break;
      }
      dataToPost;
    });
    //@ts-ignore
    originalOpen.apply(this, arguments);
  };

  // Hook into Fetch (optional but useful for modern websites)
  const originalFetch = window.fetch;
  window.fetch = async function (...args) {
    //@ts-ignore
    const response = await originalFetch.apply(this, args);
    const clonedResponse = response.clone();
    const data = await clonedResponse.text();

    if (ignoreURLS.some((url) => response.url.includes(url))) return response;

    if (!data) return response;

    logInterceptedRequest({ url: response.url, data });
    return response;
  };

  // delete window.newFunctions;

  console.log('redefined fetch');
}

export const WebviewInjectCode = redefineFetch.toString() + '; redefineFetch()';
