import { language } from '@/index';
import { Box, CircularProgress, Typography } from '@mui/material';

export default function SSCLoginLoading() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={2}>
      <CircularProgress size="10rem" />
      <Typography variant="h6">
        {language.text.checking_login_status}
      </Typography>
    </Box>
  );
}
