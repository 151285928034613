import { TaskBuilder, TaskOptionsConfig } from 'focus-mode-scheduler';
import SummaryTask from './SummaryTask';
import { NextTask } from 'focus-mode-scheduler/src/Task/TaskBuilder';
import SummaryTaskUI from './SummaryTaskUI';

export default class SummaryTaskBuilder extends TaskBuilder<SummaryTask> {
  protected task = SummaryTask;

  protected hasBeenDone = false;
  async getTask(): Promise<NextTask<SummaryTask>> {
    if (this.hasBeenDone) return Promise.resolve(null);
    this.hasBeenDone = true;

    return Promise.resolve({
      task: new SummaryTask(),
      UI: SummaryTaskUI
    });
  }

  public async getOptionsConfig(): Promise<TaskOptionsConfig | null> {
    return Promise.resolve(null);
  }
}
