/**
 * A base class for iterators that support skip and limit.
 */
export abstract class StartCountIterator<T> implements AsyncIterator<T[], T[]> {
  /**
   * The number of items to skip.
   */
  protected start: number;

  /**
   * The maximum number of items.
   */
  readonly count: number;

  /**
   * The current index.
   * @private the current index
   */
  private index = 0;

  /**
   * Whether the iterator is done.
   * @private whether the iterator is done
   */
  private done = false;

  /**
   * Abstract method that fetches the next items.
   * @protected abstract method that fetches the next items
   */
  protected abstract fetch(): Promise<Iterable<T>>;

  /**
   * Creates a new StartCountIterator.
   * @param start the number of items
   * @param count the count of items
   * @protected creates a new StartCountIterator
   */
  protected constructor({
    start = 0,
    count = 10
  }: {
    start?: number;
    count?: number;
  } = {}) {
    this.start = start;
    this.count = count;
  }

  /**
   * Fetches the next items and advances the iterator.
   * @returns the next items
   */
  async next(): Promise<IteratorResult<T[], T[]>> {
    if (this.done) {
      return { done: true, value: [] };
    }

    const res = [...(await this.fetch().catch(() => []))];

    if (res.length === 0) {
      return { done: true, value: [] };
    }

    this.start += this.count;
    this.index++;

    if (res.length < this.count) {
      this.done = true;
    }

    return { done: false, value: res };
  }

  /**
   * Fetches the previous items and rewinds the iterator.
   * @returns the previous items
   */
  async prev(): Promise<IteratorResult<T[], T[]>> {
    if (this.index === 0) {
      return { done: true, value: [] };
    }

    if (this.index === 1) {
      this.start = 0;
      this.index = 0;
      return { done: false, value: [] };
    }

    this.start = Math.max(0, this.start - this.count * 2);
    this.index--;

    if (this.start === 0) {
      this.index = 0;
    }

    const res = [...(await this.fetch().catch(() => []))];
    return { done: res.length === 0, value: res };
  }

  /**
   * Restarts the iterator.
   */
  restart(): void {
    this.start = 0;
    this.index = 0;
    this.done = false;
  }
}
