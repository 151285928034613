import React from 'react';
import { Button, ButtonProps } from '@mui/material';

export type ProgressButtonProps<
  Ele extends React.FunctionComponent<{
    disabled?: boolean;
    sx?: ButtonProps['sx'];
  }> = typeof Button
> = React.ComponentProps<Ele> & {
  component?: Ele;
  progress?: number;
  min?: number;
  max?: number;
};

const ProgressButton = React.forwardRef(
  <
    Ele extends React.FunctionComponent<{
      disabled?: boolean;
      sx?: ButtonProps['sx'];
    }> = typeof Button
  >(
    props: ProgressButtonProps<Ele>,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const { progress = 0, min = 0, max = 1, component, ...buttonProps } = props;
    const progressPercentage = React.useMemo(
      () => Math.min(Math.max((progress - min) / (max - min), 0), 1),
      [progress, min, max]
    );

    const Component = component ?? Button;

    return (
      <Component
        {...buttonProps}
        ref={ref}
        variant="contained"
        disabled={buttonProps.disabled ?? progressPercentage < 1}
        sx={{
          'zIndex': 0,
          'color': (theme) =>
            `${theme.palette.primary.contrastText} !important`,
          ...props.sx,
          '&:after': {
            content: '""',
            position: 'absolute',
            inset: 0,
            background: (theme) => theme.palette.primary.main,
            zIndex: -1,
            width: `${progressPercentage * 100}%`,
            borderRadius: 1,
            transition: (theme) => theme.transitions.create('width')
          }
        }}
      />
    );
  }
);

export default ProgressButton;
