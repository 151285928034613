import React from 'react';
import { ReceiveData, ReceivingEvent } from '@common/types/ipc';

const useIPC = <Event extends ReceivingEvent>(
  channel: Event,
  handler: (e: Electron.IpcRendererEvent, ...data: ReceiveData<Event>) => void
) => {
  React.useEffect(() => {
    window.api.handle(channel, handler);
    return () => {
      //@ts-ignore
      window.api.remove(channel, handler);
    };
  }, [channel, handler]);
};

export default useIPC;
