import { Client } from '../client/Client';
import { FeedRequest } from '../requests/FeedRequest';
import { Options, parseCollectionResponse } from '../client/Utils';

export class FeedService {
  private requestService: FeedRequest;

  constructor(readonly client: Client) {
    this.requestService = new FeedRequest(client.requestService);
  }

  public async getFeedUpdatesV2(
    start: number,
    count: number,
    options: Options
  ) {
    const result = await this.requestService.getPaginatedFeed(
      start,
      count,
      options
    );
    if (result === null) {
      return null;
    }

    return parseCollectionResponse(result.data['*elements'], result.included);
  }
}
