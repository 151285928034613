// enum must be in separate file to other types since
// enums are values and .d.ts files are removed during
// compile phase so to use enum in other files we need
// to declare it in an .ts file

export enum ContactType {
  AUDIENCE_HOLDER = 'AUDIENCE_HOLDER',
  PERSONAL = 'PERSONAL',
  NO_MATCH = 'NO_FIT',
  CUSTOMER = 'CUSTOMER',
  POTENTIAL_CUSTOMER = 'POTENTIAL_CUSTOMER',
  UNCATEGORIZED = 'UNCATEGORIZED',
  MARKED = 'MARKED',
  CONNECTION_REQUEST_SENT = 'CONNECTION_REQUEST_SENT'
}
