import {
  FullProfile,
  MessageTemplate,
  MarkedProfile as MarkedProfileData,
  PipelineEvent,
  Tag,
  ProfileInfo
} from '@common/types/ApiTypes';
import { SSCChat } from './Chat/SSCChat';
import { ContactType } from '@common/types/enums';
import dayjs from 'dayjs';
import ContactActions from '../DataServer/Contact';
import PrintableError from '@common/PrintableError/PrintableError';
import Auth from '@common/AuthManager/Auth.renderer';
import MessageBus from '@common/MessageBus/MessageBus.renderer';
import { contacts, util } from '@digital-sun-solutions/cloud-functions';
import {
  PipelineStep,
  ServerPipelineStepType
} from '@common/PipelineManager/PipelineTypes';
import { Pipelines } from '@common/PipelineManager/Pipelines';
import TemplateActions from '../DataServer/Templates';
import ChatActions from '../DataServer/Chat';
import WebviewLinkedIn from '@common/Webview.renderer/WebviewLinkedIn';
import log from 'electron-log';
import apiRetry from '@common/FetchRetry/FetchRetry.renderer';
import { QueryUpdates } from '@/other/QueryClient';
import LinkedInChatActions from '../DataServer/LinkedInChats';
import tracking from 'tracking';
import posthog from 'posthog-js';

export class Contact {
  constructor(
    protected profile: FullProfile & { cursor?: number },
    protected ownPublicIdentifier: string
  ) {}

  public get publicIdentifier(): string | undefined {
    return this.profile.profile.publicIdentifier ?? undefined;
  }

  public get profileID(): string {
    return this.profile.profile.profileID;
  }

  /**
   * @deprecated Use profileID instead
   */
  public get contactID(): number {
    return this.profile.id;
  }

  public get conversationID(): string | undefined {
    return this.profile.conversationID ?? undefined;
  }

  public get name(): string {
    return `${this.firstname} ${this.lastname}`;
  }

  public get firstname(): string {
    return this.profile.profile.firstname;
  }

  public get lastname(): string {
    return this.profile.profile.lastname;
  }

  public get infoText(): string {
    return this.profile.profile.headline ?? ''; //TODO: FIX FLORIN
  }

  public get connected() {
    return !this.profile.profile.connectedAt;
  }

  public get pictures() {
    return this.profile.profile.pictures as
      | { [resolution: string]: string }
      | undefined;
  }

  public get connectedAt() {
    return this.profile.profile.connectedAt;
  }

  public get priority(): number {
    return this.profile.info.priority;
  }

  public get cursor(): number | undefined {
    return this.profile.cursor;
  }

  public get profileInfo(): ProfileInfo {
    return this.profile.profile;
  }

  public getProfilePictureURL(height?: number): string {
    const pictures = this.pictures;
    if (!pictures) return '';
    const sizes = Object.keys(pictures)
      .map((key) => parseInt(key))
      // filter out invalid keys
      .filter((key) => !isNaN(key))
      // sort by largest first
      .sort((a, b) => b - a);

    // get first size larger than height
    const fittingSize = sizes.reduce<number | null>((prev, curr) => {
      if (prev === null) return curr;
      if (height === undefined) return Math.max(prev, curr);
      if (curr < height && curr < prev) return prev;
      if (curr > height && curr > prev) return prev;
      return curr;
    }, null);

    return pictures[fittingSize ?? sizes[0]];
  }

  public get profileURL(): string {
    return `https://www.linkedin.com/in/${encodeURIComponent(
      this.publicIdentifier ?? this.profile.profile.profileID
    )}/`;
  }

  public get activityURL(): string {
    return `${this.profileURL}recent-activity/all/`;
  }

  public get hasChat(): boolean {
    return Boolean(this.conversationID);
  }

  public get type(): ContactType {
    return this.profile.info.type as ContactType;
  }

  public get skippedUntil() {
    return this.profile.info.skippedUntil;
  }

  public get tags() {
    return this.profile.tags.map((tag) => ({
      name: tag.name,
      color: `#${tag.color}`
    }));
  }

  public get lastPipelineEvent() {
    return this.profile.latestPipelineEvent;
  }

  public get pipelineCompleted() {
    return (
      this.profile.latestPipelineEvent?.stepType ===
        ServerPipelineStepType.RESULT_POSITIVE ||
      this.profile.latestPipelineEvent?.stepType ===
        ServerPipelineStepType.RESULT_NEGATIVE
    );
  }

  public get pipelineResult() {
    if (
      this.profile.latestPipelineEvent?.stepType ===
      ServerPipelineStepType.RESULT_POSITIVE
    )
      return true;
    if (
      this.profile.latestPipelineEvent?.stepType ===
      ServerPipelineStepType.RESULT_NEGATIVE
    )
      return false;
    return null;
  }

  public getActiveStep() {
    if (!this.profile.latestPipelineEvent?.currentStep) return null;
    return Pipelines.findStep(this.profile.latestPipelineEvent.currentStep);
  }

  public get notes(): string {
    return this.profile.info.notes;
  }

  public async setNotes(text: string): Promise<void> {
    const result = await Auth.execRoute((token) =>
      contacts.update(
        {
          profileID: this.profileID,
          notes: text,
          type: this.profile.info.type ?? ContactType.UNCATEGORIZED
        },
        { token }
      )
    );
    switch (result.code) {
      case 200:
        this.profile.info.notes = text;
        return;
      case 500:
        throw new PrintableError('Failed to set notes due to server error');
    }
  }

  public async addTag(tag: Tag): Promise<void> {
    if (tag.color.startsWith('#')) tag.color = tag.color.slice(1);

    const result = await Auth.execRoute((token) =>
      contacts.appendTag(
        {
          tag_name: tag.name,
          profileID: this.profileID
        },
        { token }
      )
    );
    switch (result.code) {
      case 200:
        if (!this.profile.tags.some((t) => t.name === tag.name)) {
          this.profile.tags.push(tag);
        }
        break;
      case 500:
        throw new PrintableError('Failed to add tag due to server error');
    }

    ContactActions.invalidateCache();
  }

  public async removeTag(name: Tag['name']): Promise<void> {
    const result = await Auth.execRoute((token) =>
      contacts.removeTag(
        {
          tag_name: name,
          profileID: this.profileID
        },
        { token }
      )
    );
    switch (result.code) {
      case 200:
      case 201:
        this.profile.tags = this.profile.tags.filter(
          (tag) => tag.name !== name
        );
        break;
      case 500:
        throw new PrintableError('Failed to remove tag due to server error');
    }

    ContactActions.invalidateCache();
  }

  public async getChat(template?: MessageTemplate | null): Promise<SSCChat> {
    if (!template) {
      const templates = await TemplateActions.getTemplatesOfPipelineStep(
        this.lastPipelineEvent?.currentStep
      );
      template = templates?.[0]?.templates[0] ?? null;
    }
    return new SSCChat({
      contact: this,
      conversationID: this.conversationID,
      ownPublicIdentifier: this.ownPublicIdentifier,
      template: template ?? undefined
    });
  }

  public async setPipelineStep(step: PipelineStep): Promise<void> {
    const pipelineEvent: PipelineEvent = {
      currentStep: step.id,
      pipelineID: Pipelines.getPipelineDefinition().id,
      stepType: Pipelines.getServerStepType(step),
      createdAt: new Date()
    };
    const res = await Auth.execRoute((token) =>
      contacts.addPipelineEvent(
        {
          profileID: this.profileID,
          currentStep: pipelineEvent.currentStep,
          pipelineID: pipelineEvent.pipelineID,
          stepType: pipelineEvent.stepType
        },
        { token }
      )
    );

    ContactActions.invalidateCache();
    ChatActions.invalidateChatsCache();

    if (res.code === 500)
      throw new PrintableError(
        'Internal server error while setting pipeline step'
      );
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 400 malformatted request could also occur, or 502 service unavailable
    if (res.code !== 200)
      throw new PrintableError('Unknown error while setting pipeline step');

    this.profile.latestPipelineEvent = pipelineEvent;
  }

  public async completePipeline(result: boolean): Promise<void> {
    const res = await Auth.execRoute((token) =>
      contacts.addPipelineEvent(
        {
          profileID: this.profileID,
          currentStep: '',
          pipelineID: Pipelines.getPipelineDefinition().id,
          stepType: result
            ? ServerPipelineStepType.RESULT_POSITIVE
            : ServerPipelineStepType.RESULT_NEGATIVE
        },
        { token }
      )
    );

    await this.setContactType(
      result ? ContactType.CUSTOMER : ContactType.NO_MATCH
    );

    ContactActions.invalidateCache();

    if (res.code === 500)
      throw new PrintableError(
        'Internal server error while completing pipeline'
      );
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 400 malformatted request could also occur, or 502 service unavailable
    if (res.code !== 200)
      throw new PrintableError('Unknown error while completing pipeline');
  }

  public async gotoNextPipelineStep(historicSteps?: string[]): Promise<void> {
    const history = Pipelines.filterLevelsFromHistory(
      historicSteps ??
        (await this.getPipelineHistory()).map((e) => e.currentStep)
    );
    const nextStep = Pipelines.getNextStep(history);
    if (!nextStep) throw new PrintableError('No next step found');
    if (nextStep.done) await this.completePipeline(true);
    else await this.setPipelineStep(nextStep.step);
  }

  public async setContactType(specialType: ContactType): Promise<void> {
    const result = await Auth.execRoute((token) =>
      contacts.update(
        {
          profileID: this.profileID,
          type: specialType
        },
        { token }
      )
    );
    if (
      [
        ContactType.POTENTIAL_CUSTOMER,
        ContactType.CONNECTION_REQUEST_SENT
      ].includes(specialType) &&
      !this.lastPipelineEvent
    ) {
      await this.setPipelineStep(Pipelines.getCategorizationSteps()[0]);
    }
    ContactActions.invalidateCache();
    switch (result.code) {
      case 200:
        return;
      case 500:
        throw new PrintableError(
          'Failed to set contact as special contact due to server error'
        );
    }
  }

  public static async categorize(
    profile: (FullProfile['profile'] & { conversationID?: string }) | Contact,
    category: ContactType,
    step?: Pick<PipelineStep, 'id' | 'type'>
  ): Promise<void> {
    const isNew = !(profile instanceof Contact);
    const givenProfileID = isNew ? null : (profile as Contact).profileID;
    if (profile instanceof Contact) {
      profile = {
        firstname: profile.firstname,
        headline: profile.infoText,
        lastname: profile.lastname,
        profileID: profile.profileID,
        publicIdentifier: profile.publicIdentifier,
        pictures: profile.pictures,
        connectedAt: profile.connectedAt,
        conversationID: profile.conversationID ?? undefined,
        country: '',
        city: ''
      } as FullProfile['profile'];
    }

    await apiRetry(() =>
      Auth.execRoute((token) =>
        contacts.setProfileData(
          { profiles: [profile as FullProfile['profile']] },
          { token }
        )
      )
    );

    const profileID = await (async () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- always not null when isNew is false
      if (!isNew) return givenProfileID!;
      const createdProfile = await Auth.execRoute((token) =>
        contacts.get({ profileID: profile.profileID }, { token })
      );

      if (createdProfile.code !== 200)
        throw new PrintableError('Could not get created profile');
      return createdProfile.data.profile.profileID;
    })();

    if (isNew) {
      tracking.capture('ProfileCategorized');
      posthog.capture('profile-categorized');
      MessageBus.getInstance().emit('profile-categorized', {
        profileID: profile.profileID,
        conversationID: profile.conversationID
      });
    }

    const response = await Auth.execRoute((token) =>
      contacts.update(
        {
          profileID: profile.profileID,
          type: category
        },
        { token }
      )
    );

    if (category === ContactType.POTENTIAL_CUSTOMER) {
      if (!step?.type) step = Pipelines.getCategorizationSteps()[0];
      await Auth.execRoute((token) =>
        contacts.addPipelineEvent(
          {
            profileID,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- already checked outside callback fct
            currentStep: step!.id,
            pipelineID: Pipelines.getPipelineDefinition().id,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- already checked outside callback fct
            stepType: Pipelines.getServerStepType(step!)
          },
          { token }
        )
      );
    }

    tracking.capture('ProfileCategorized');
    posthog.capture('profile-categorized');
    MessageBus.getInstance().emit('profile-categorized', {
      profileID: profile.profileID,
      conversationID: profile.conversationID
    });

    const contact = await ContactActions.getContact(profileID);

    QueryUpdates.optimisticUpdateInfiniteData(ChatActions.listChats, (chat) => {
      if (chat.profileID !== profileID) return chat;
      chat.contact = contact ?? undefined;
      return chat;
    });
    QueryUpdates.optimisticUpdateInfiniteData(
      LinkedInChatActions.listChats,
      (chat) => {
        if (chat.profileID !== profileID) return chat;
        chat.contact = contact ?? undefined;
        return chat;
      }
    );

    ContactActions.invalidateCache();
    ChatActions.invalidateChatsCache(profile.conversationID, true);

    if (response.code !== 200) {
      if (response.code === 500)
        throw new PrintableError(
          'Internal server error while categorizing contact'
        );
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- other errors can occur
      if (response.code === 404)
        throw new PrintableError(
          `Could not find contact in database ${response.data}`
        );
      throw new PrintableError('Unknown error while categorizing contact');
    }
  }

  public async uncategorize(): Promise<void> {
    const res = await Auth.execRoute((token) =>
      contacts.uncategorized.add(
        [
          {
            firstname: this.firstname,
            lastname: this.lastname,
            profileID: this.profileID,
            publicIdentifier: this.publicIdentifier,
            pictures: this.pictures
          }
        ],
        { token }
      )
    );
    if (res.code !== 200)
      throw new PrintableError('Could not uncategorize contact');

    await this.delete();
  }

  /**
   * !IMPORTANT! Only call this when you know what you are doing! \
   * In most cases this should ONLY be used for not connected profiles!
   */
  public async delete() {
    const res = await Auth.execRoute((token) =>
      contacts.remove(
        {
          profileID: this.profileID
        },
        { token }
      )
    );
    if (res.code !== 200) throw new PrintableError('Could not remove contact');
  }

  public async getPipelineHistory(): Promise<PipelineEvent[]> {
    const res = await Auth.execRoute((token) =>
      contacts.getPipelineEvents(
        {
          profileID: this.profileID
        },
        { token }
      )
    );

    if (res.code !== 200)
      throw new PrintableError('Could not get pipeline history');
    return res.data;
  }

  public async skipUntil(date: Date) {
    const res = await Auth.execRoute((token) =>
      contacts.update(
        {
          profileID: this.profileID,
          skippedUntil: date,
          type: this.profile.info.type ?? ContactType.UNCATEGORIZED
        },
        { token }
      )
    );
    if (res.code !== 200) throw new PrintableError('Could not skip contact');
  }
}

export class MarkedProfile {
  constructor(protected profile: MarkedProfileData) {}

  public get profileID(): string {
    return this.profile.profileID;
  }

  public get name(): string {
    return this.profile.firstname + ' ' + this.profile.lastname;
  }

  public get firstName(): string {
    return this.profile.firstname;
  }

  public get lastName(): string {
    return this.profile.lastname;
  }

  public get pictures() {
    return this.profile.pictures as
      | { [resolution: string]: string }
      | undefined;
  }

  public get publicIdentifier() {
    return this.profile.publicIdentifier;
  }

  public getProfilePictureURL(height?: number): string {
    const pictures = this.pictures;
    if (!pictures) return '';
    const sizes = Object.keys(pictures)
      .map((key) => parseInt(key))
      // filter out invalid keys
      .filter((key) => !isNaN(key))
      // sort by largest first
      .sort((a, b) => b - a);

    // get first size larger than height
    const fittingSize = sizes.reduce<number | null>((prev, curr) => {
      if (prev === null) return curr;
      if (height === undefined) return Math.max(prev, curr);
      if (curr < height && curr < prev) return prev;
      if (curr > height && curr > prev) return prev;
      return curr;
    }, null);

    return pictures[fittingSize ?? sizes[0]];
  }

  public async convertToContact() {
    const profile = await WebviewLinkedIn.getProfile(this.profileID);
    if (!profile)
      throw new PrintableError(
        '[MarkedProfile.connect] Could not get profile data before connecting'
      );

    await this.remove();

    const res = await Auth.execRoute((token) =>
      contacts.setProfileData(
        {
          profiles: [
            {
              firstname: profile.firstName,
              lastname: profile.lastName,
              headline: profile.infoText,
              profileID: profile.profileID,
              publicIdentifier: profile.publicIdentifier,
              pictures: profile.profilePictureUrl
            }
          ]
        },
        { token }
      )
    );
    if (res.code !== 200) {
      throw new PrintableError(
        '[MarkedProfile.connect] Could not save profile data'
      );
    }
    tracking.capture('ProfileCategorized');
    posthog.capture('profile-categorized');
    MessageBus.getInstance().emit('profile-categorized', {
      profileID: profile.profileID
    });

    return ContactActions.getContact(profile.profileID);
  }

  /**
   * Connect to the profile
   */
  public async connect() {
    await WebviewLinkedIn.sendConnectionRequest(this.profileID, '', {
      prioritize: true
    });

    await Auth.execRoute((token) =>
      util.track_connection_request({}, { token })
    );

    return await this.convertToContact();
  }

  public async remove(): Promise<void> {
    const res = await Auth.execRoute((token) =>
      contacts.marked.remove(
        {
          profileID: this.profileID
        },
        { token }
      )
    );

    if (res.code === 404)
      throw new PrintableError('Could not find contact in database');
    if (res.code === 500)
      throw new PrintableError(
        'Internal server error while connecting to profile'
      );
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 400 malformatted request could also occur, or 502 service unavailable
    if (res.code !== 200)
      throw new PrintableError('Unknown error while connecting to profile');
  }

  public async skipUntil(
    until: Date | undefined,
    notes: string
  ): Promise<void> {
    const res = await Auth.execRoute((token) =>
      contacts.marked.skip(
        {
          profileID: this.profile.profileID,
          until: until ?? dayjs(new Date()).add(1, 'week').toDate(),
          notes
        },
        { token }
      )
    );
    if (res.code !== 200) {
      log.error(
        `Error while skipping marked contact (${this.profileID}) until next week`,
        res.code,
        res.data
      );
      throw new PrintableError('Could not skip marked contact');
    }
  }
}
