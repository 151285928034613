import React from 'react';
import { Box, BoxProps, Button, Menu, MenuItem } from '@mui/material';
import { ChevronDownIcon } from 'ui-utils';
import { language } from '@/index';

export interface ChatMessageMenuProps extends BoxProps {
  showReact: () => void;
}

export default function ChatMessageMenu(props: ChatMessageMenuProps) {
  const { showReact, ...boxProps } = props;
  const anchorRef = React.useRef<HTMLElement | null>(null);
  const [open, setOpen] = React.useState(false);

  const handleReactClick = () => {
    setOpen(false);
    showReact();
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const id = open ? 'simple-popover' : undefined;

  return (
    <Box
      {...boxProps}
      sx={{
        position: 'absolute',
        right: '0',
        top: 0,
        ...boxProps.sx
      }}>
      <Button
        sx={{
          minWidth: '30px'
        }}
        ref={(ref: HTMLButtonElement) => (anchorRef.current = ref)}
        aria-describedby={id}
        variant="text"
        onClick={handleClick}>
        <ChevronDownIcon
          sx={{
            fontSize: '1rem',
            color: '#707070'
          }}
        />
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem onClick={handleReactClick}>{language.text.react}</MenuItem>
        {/* <MenuItem onClick={handleClose}>Delete</MenuItem> */}
      </Menu>
    </Box>
  );
}
