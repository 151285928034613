import { StatTypes } from '@common/types/ApiTypes';
import { Box, Typography } from '@mui/material';
import StatsHelper from '../../Stats/StatsHelper';
import React from 'react';
import { NumberInput, useDebounced } from 'ui-utils';

export interface SetGoalProps {
  goal: StatTypes;
  defaultValue: number;
  onFinish: (goal: number) => void;
}

export default function SetGoal(props: SetGoalProps) {
  const [goal, setGoal] = React.useState(props.defaultValue);
  const goalRef = React.useRef(goal);

  useDebounced(goal, 1000, (goal) => {
    if (props.defaultValue === goal) return;
    props.onFinish(goal);
  });

  React.useEffect(() => {
    return () => {
      if (props.defaultValue === goalRef.current) return;
      props.onFinish(goalRef.current);
    };
  }, []);

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.background.paper,
        boxShadow: 1,
        p: 2,
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        boxSizing: 'border-box'
      }}>
      <Typography variant="h6" textAlign="center" mt={2}>
        {StatsHelper.getStatsAsset(props.goal)?.title}
      </Typography>
      <NumberInput
        value={goal}
        onChange={(goal) => {
          goal = Math.min(goal, 200);
          setGoal(goal);
          goalRef.current = goal;
        }}
        variant="standard"
        sx={{
          maxWidth: '50px',
          input: {
            textAlign: 'center'
          }
        }}
      />
    </Box>
  );
}
