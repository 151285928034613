import { LinkedInRequestService } from '../services/LinkedInRequestService';
import {
  GetConnectionsResponse,
  GetMeResponse,
  GetProfileResponse,
  GetWvmpCardResponse,
  ProfileId
} from 'linkedin-domain-types';
import { GET_PROFILE_TIMEOUT } from '../client/Config';
import { Options, trace } from '../client/Utils';

@trace()
export class ProfileRequest {
  private requestService: LinkedInRequestService;

  constructor({ requestService }: { requestService: LinkedInRequestService }) {
    this.requestService = requestService;
  }

  getMe(options: Options): Promise<GetMeResponse | null> {
    return this.requestService.get<GetMeResponse>(
      'me',
      options,
      undefined,
      undefined,
      'me'
    );
  }

  getConnectionsWithProfile({
    start = 0,
    count = 40,
    options,
    sortType = 'RECENTLY_ADDED'
  }: {
    start?: number;
    count?: number;
    options: Options;
    keyword?: string;
    sortType?: 'RECENTLY_ADDED' | 'LASTNAME_FIRSTNAME' | 'FIRSTNAME_LASTNAME';
  }): Promise<GetConnectionsResponse | null> {
    return this.requestService.get<GetConnectionsResponse>(
      'relationships/dash/connections',
      options,
      {
        params: {
          start,
          count,
          sortType,
          q: 'search',
          decorationId: `com.linkedin.voyager.dash.deco.web.mynetwork.ConnectionList${
            sortType === 'RECENTLY_ADDED' ? 'WithProfile' : ''
          }-16`
        }
      },
      undefined,
      `conn${count}@${start}`
    );
  }

  getConnectionList({
    start = 0,
    count = 100,
    keyword,
    options
  }: {
    start?: number;
    count?: number;
    keyword?: string;
    options: Options;
  }): Promise<GetConnectionsResponse | null> {
    return this.requestService.get<GetConnectionsResponse>(
      'relationships/dash/connections',
      options,
      {
        params: {
          start,
          count,
          q: 'search',
          decorationId:
            'com.linkedin.voyager.dash.deco.web.mynetwork.ConnectionList-16',
          ...(keyword && { keyword })
        }
      },
      undefined,
      `conn${count}@${start}`
    );
  }

  async getProfile(
    publicIdentifier: ProfileId,
    options: Options
  ): Promise<GetProfileResponse | null> {
    return this.requestService.get<GetProfileResponse>(
      'identity/dash/profiles',
      options,
      {
        params: {
          q: 'memberIdentity',
          memberIdentity: publicIdentifier,
          decorationId:
            'com.linkedin.voyager.dash.deco.identity.profile.WebTopCardCore-22'
        }
      },
      undefined,
      `prof${publicIdentifier.slice(0, 8)}`
    );
  }

  async getFullProfile(
    publicIdentifier: ProfileId,
    options: Options
  ): Promise<GetProfileResponse | null> {
    return this.requestService.get<GetProfileResponse>(
      'identity/dash/profiles',
      options,
      {
        params: {
          q: 'memberIdentity',
          memberIdentity: publicIdentifier,
          decorationId:
            'com.linkedin.voyager.dash.deco.identity.profile.TopCardSupplementary-129'
        }
      },
      GET_PROFILE_TIMEOUT,
      `prof${publicIdentifier.slice(0, 8)}`
    );
  }

  /**
   * Gets the wvmp card of a profile
   */
  async getWvmpCard(options: Options): Promise<GetWvmpCardResponse | null> {
    return this.requestService.get(
      'identity/wvmpCards',
      options,
      undefined,
      undefined,
      'wvmpCard'
    );
  }
}
