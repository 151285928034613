import React from 'react';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import ChatTextField from '@/components/Chat/ChatTextField';
import ChatHeader from './ChatHeader';
import ChatHistory from '@/components/Chat/ChatHistory';
import TemplateActions from '@/data/DataServer/Templates';
import { useChatContext } from '../ChatContext';
import { language } from '@/index';
import { SpeakerNotesOff } from '@mui/icons-material';
import { Loading } from 'ui-utils';
import { AbstractChat } from '@/data/Classes/Chat/AbstractChat';
import { ChatListTab } from '../ChatList';
import {
  PipelineStep,
  ServerPipelineStepType
} from '@common/PipelineManager/PipelineTypes';
import { ContactType } from '@common/types/enums';
import ChatTemplates from '@/components/Chat/ChatTemplates';
import { MessageTemplate } from '@common/types/ApiTypes';

export interface OpenChatProps {
  chat: AbstractChat | null;
  hideHeader?: boolean;
  chatBackgroundColor?: string;
  loading?: boolean;
  tab?: ChatListTab;
  activeStep?: PipelineStep | null;
}

export default function OpenChat(props: OpenChatProps) {
  const { setChatMessage, focusChatMessageBox } = useChatContext();

  const { templates: userTemplates } =
    TemplateActions.useTemplatesOfPipelineStep(
      props.activeStep?.type === 'message' ? props.activeStep.id : undefined,
      {
        enabled: props.activeStep?.type === 'message'
      }
    );
  const { templates: buildRelationTemplates } =
    TemplateActions.useBuildRelationTemplates({
      enabled: props.activeStep?.type === 'buildRelationship'
    });
  const { templates: uncategorizedTemplates } =
    TemplateActions.useUncagorizedTemplates({
      enabled: props.activeStep?.type === 'buildRelationship'
    });

  const templates = React.useMemo(() => {
    if (props.activeStep?.type === 'message')
      return userTemplates?.flatMap((t) => t.templates) ?? [];
    if (props.activeStep?.type === 'buildRelationship')
      return buildRelationTemplates;
    if (
      props.chat &&
      (!props.chat.contact ||
        props.chat.contact.type === ContactType.UNCATEGORIZED)
    )
      return uncategorizedTemplates ?? [];
    return [];
  }, [
    userTemplates,
    buildRelationTemplates,
    uncategorizedTemplates,
    props.chat?.contact,
    props.activeStep
  ]);

  React.useEffect(() => {
    if (templates.length === 0) return;
    // wait one frame to prevent errors in tiptap (ChatTextField)
    requestAnimationFrame(() => {
      props.chat?.setTemplateVariant(templates[0]?.variantID);
      setSelectedTemplate(templates[0]?.variantID ?? null);
      const text = props.chat?.getFilledTemplate(templates[0].text);
      setChatMessage(text ?? templates[0].text, { soft: true });
      focusChatMessageBox();
    });
  }, [templates]);

  const [selectedTemplate, setSelectedTemplate] = React.useState<number | null>(
    null
  );

  const handleSelectTemplate = React.useCallback(
    (template: MessageTemplate) => {
      const text = props.chat?.getFilledTemplate(template.text);
      if (!text) return;
      props.chat?.setTemplateVariant(template.variantID);
      setChatMessage(text);
      setSelectedTemplate(template.variantID);
    },
    [props.chat, setChatMessage, setSelectedTemplate]
  );

  if (props.loading) return <Loading />;

  if (!props.chat)
    return (
      <Box
        display="flex"
        height="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap={2}>
        <SpeakerNotesOff sx={{ fontSize: 100 }} color="primary" />
        <Typography textAlign="center" variant="h4">
          {language.text.no_chat_selected}
        </Typography>
        {props.tab === 'ssc:normal' && (
          <Typography textAlign="center">
            {language.text.categorize_before_chatting}
          </Typography>
        )}
      </Box>
    );

  if (!props.chat.conversationID)
    return (
      <Typography textAlign="center" marginTop={6}>
        {language.text.error_while_loading_chat}
        <br />
        <Typography component="span" fontSize="0.7em">
          (Error: Chat missing conversationID)
        </Typography>
      </Typography>
    );

  return (
    <Box width="100%" display="flex" flexDirection="column" height="100%">
      {!props.hideHeader && (
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            flex: '0 0 auto'
          }}>
          <ChatHeader chat={props.chat} />
        </Box>
      )}
      <Box
        sx={{
          flex: '1 0 0',
          width: '100%',
          position: 'relative',
          backgroundColor: props.chatBackgroundColor
        }}>
        <Box position="absolute" top="0" left="0" width="100%" height="100%">
          <ChatHistory chat={props.chat} key={props.chat.conversationID} />
        </Box>
      </Box>
      <Box
        flex="0 0 auto"
        width="100%"
        sx={{ backgroundColor: props.chatBackgroundColor }}>
        <ChatTemplates
          templates={templates}
          selectedTemplate={selectedTemplate}
          onSelectTemplate={handleSelectTemplate}
        />
        <ChatTextField
          chat={props.chat}
          defaultMessage={props.chat.getFilledTemplate() ?? undefined}
          onClear={() => {
            props.chat?.setTemplateVariant(undefined);
            setSelectedTemplate(null);
          }}
          afterSend={() => {
            if (
              props.chat?.contact?.lastPipelineEvent?.stepType ===
              ServerPipelineStepType.Message
            )
              props.chat.contact.gotoNextPipelineStep();
          }}
        />
      </Box>
    </Box>
  );
}
