import React from 'react';
import AffiliateDiscountCodes from './AffiliateDiscountCoupons';
import AffiliateStats from './AffiliateStats';
import AffiliateF2UCodes from './AffiliateF2UCoupons';
import AffiliateCreateDiscountCode from './AffiliateCreateDiscountCoupons';
import AffiliateCreateFreeToUseCodes from './AffiliateCreateFreeToUseCoupons';
import {
  Box,
  Divider,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Typography
} from '@mui/material';
import { CardGiftcard, Discount } from '@mui/icons-material';
import { language } from '@/index';
import AffiliateActions from '@/data/DataServer/Affiliate';

const MAX_DISCOUNT_COUPONS = 5;
const MAX_F2U_COUPONS = 5;

export default function AffiliateOverview() {
  const [createOpen, setCreateOpen] = React.useState<
    'closed' | 'discount' | 'f2u'
  >('closed');

  const { discountCoupons, isSuccess: discountCouponsSuccess } =
    AffiliateActions.useDiscountCodes();
  const canUserCreateDiscountCoupons =
    discountCouponsSuccess && discountCoupons.length < MAX_DISCOUNT_COUPONS;

  const { freeToUseCoupons, isSuccess: freeToUseSuccess } =
    AffiliateActions.useFreeToUseCoupons();
  const canUserCreateFreeToUseCoupons =
    freeToUseSuccess && freeToUseCoupons.length < MAX_F2U_COUPONS;

  return (
    <>
      <Box sx={{ height: '100%', position: 'relative', width: '100%' }}>
        <Box
          sx={{
            overflowY: 'auto',
            position: 'absolute',
            inset: 0,
            height: '100%',
            width: '100%'
          }}>
          <Divider sx={{ position: 'sticky', top: 0 }} />
          <AffiliateStats />
          <Typography variant="h6" textAlign="center" mt={4}>
            {language.text.discount_coupons}
          </Typography>
          <AffiliateDiscountCodes />
          <Typography variant="h6" textAlign="center" mt={4}>
            {language.text.f2u_coupons}
          </Typography>
          <AffiliateF2UCodes />
          {/* added space so that create button never overlays an item */}
          <Box mb={10} />
        </Box>
      </Box>
      <SpeedDial
        sx={{ position: 'absolute', bottom: 16, right: 32 }}
        ariaLabel={language.text.create_coupon}
        icon={<SpeedDialIcon />}>
        <SpeedDialAction
          icon={
            <Discount
              color={canUserCreateDiscountCoupons ? 'primary' : 'disabled'}
            />
          }
          tooltipTitle={
            <Typography whiteSpace="nowrap">
              {canUserCreateDiscountCoupons
                ? language.text.create_discount_coupon
                : language.text.max_discount_coupons_reached}
            </Typography>
          }
          onClick={() => {
            if (canUserCreateDiscountCoupons) setCreateOpen('discount');
          }}
          tooltipOpen
        />
        <SpeedDialAction
          icon={<CardGiftcard color="primary" />}
          tooltipTitle={
            <Typography whiteSpace="nowrap">
              {canUserCreateFreeToUseCoupons
                ? language.text.create_f2u_coupon
                : language.text.max_free_to_use_coupons_reached}
            </Typography>
          }
          onClick={() => {
            if (canUserCreateFreeToUseCoupons) setCreateOpen('f2u');
          }}
          tooltipOpen
        />
      </SpeedDial>
      <AffiliateCreateDiscountCode
        open={createOpen === 'discount'}
        onClose={() => setCreateOpen('closed')}
      />
      <AffiliateCreateFreeToUseCodes
        open={createOpen === 'f2u'}
        onClose={() => setCreateOpen('closed')}
      />
    </>
  );
}
