import { checkFeatureFlag, useFeatureFlags } from '@/globals/featureFlags';
import { createQueryHook } from './QueryHelper';

const LOCAL_STORAGE_KEY = 'app-start-time';

function saveStartTime() {
  localStorage.setItem(LOCAL_STORAGE_KEY, Date.now().toString());
}

function getStartTime() {
  const time = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!time) return new Date();
  return new Date(parseInt(time));
}

function shouldForceUpdateDownload() {
  return (
    checkFeatureFlag('full_version_released') &&
    parseInt(globalThis.APP_VERSION.split('.')[0]) < 1
  );
}

function useShouldForceUpdateDownload() {
  const { full_version_released } = useFeatureFlags();

  return (
    full_version_released && parseInt(globalThis.APP_VERSION.split('.')[0]) < 1
  );
}

function getVersion() {
  return globalThis.APP_VERSION;
}

async function getCurrentVersionHash(): Promise<string> {
  return globalThis.VERSION_HASH ?? 'dev';
}
getCurrentVersionHash.getQueryKey = () => ['version_hash', 'current'];

async function getLatestVersionHash(): Promise<string> {
  try {
    const versionHashResponse = await fetch('https://version-hash.local');
    const versionHash = await versionHashResponse.text();
    return versionHash;
  } catch (error) {
    return 'unknown';
  }
}
getLatestVersionHash.getQueryKey = () => ['version_hash', 'latest'];

const useCurrentVersionHash = createQueryHook(getCurrentVersionHash, 'hash');
const useLatestVersionHash = createQueryHook(getLatestVersionHash, 'hash');

const AppActions = {
  saveStartTime,
  getStartTime,

  shouldForceUpdateDownload,
  useShouldForceUpdateDownload,

  getCurrentVersionHash,
  useCurrentVersionHash,
  getLatestVersionHash,
  useLatestVersionHash,

  getVersion
};

export default AppActions;
