import axios, {
  AxiosError,
  AxiosInstance,
  AxiosProxyConfig,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults
} from 'axios';

import {
  LINKEDIN_API_URL,
  LINKEDIN_REALTIME_URL,
  MAX_RETRIES,
  REALTIME_HEADER,
  REQUEST_HEADER,
  REQUEST_TIMEOUT
} from '../client/Config';
import { Logger, Options, Task, TaskQueue, trace } from '../client/Utils';
import Config from '../../Config';

/**
 * Build a URL with the LinkedIn API URL as base
 * @param url the URL to build
 */
function buildUrl(url: string): string {
  if (url.startsWith('https://') || url.startsWith('http://')) return url;
  return LINKEDIN_API_URL + url;
}

type ConfigFullResponse = AxiosRequestConfig & { fullResponse?: true };
type ConfigNonFullResponse = AxiosRequestConfig & { fullResponse?: false };

interface RequestOpts {
  proxy?: AxiosProxyConfig;
}

const RETRY_CODES = [429, 500, 502, 503, 504];

/**
 * The request service class that handles all requests to LinkedIn
 * @class LinkedInRequestService - The request service class that handles all requests to LinkedIn
 */
@trace()
export class LinkedInRequestService {
  /**
   * The request instance that is used to make requests
   */
  requestInstance: AxiosInstance;

  /**
   * The dummy headers that are used when headers are disabled
   */
  private dummyHeaders: Record<string, string> = {};

  /**
   * The task queue that is used to make requests
   */
  private taskQueue: TaskQueue;

  /**
   * Creates a new LinkedInRequestService
   * @param proxy the proxy to use
   * @param instance the request instance to use
   */
  constructor({ proxy }: RequestOpts = {}, instance?: AxiosInstance) {
    Logger.debug('Creating new request service');
    this.taskQueue = new TaskQueue(REQUEST_TIMEOUT);
    this.requestInstance =
      instance ??
      axios.create({
        withCredentials: true,
        ...(proxy && { proxy })
      });

    Logger.debug(
      `Request service created: Valid Codes for retrying: ${JSON.stringify(
        RETRY_CODES
      )}, Proxy: ${proxy ? `${JSON.stringify(proxy)}` : 'Disabled'}`
    );

    this.addHeaders(REQUEST_HEADER);
  }

  /**
   * Adds headers to the request instance
   * @param headers the headers to add
   */
  addHeaders(headers: Record<string, string>) {
    Logger.debug('Adding new headers');
    for (const header in headers) {
      this.requestInstance.defaults.headers[header] = headers[header];
    }
  }

  /**
   * Sets the headers of the request instance
   * @param headers the headers to set
   */
  setHeaders(headers: HeadersDefaults & Record<string, string>): void {
    Logger.debug('Setting new headers');
    this.requestInstance.defaults.headers = headers;
  }

  /**
   * Gets current headers of the request instance
   *
   * @param url the url to get headers for (optional)
   *
   * @returns the headers of the request instance as a record of header name to header value
   */
  getHeaders(url?: string): Record<string, string> {
    if (url?.includes(LINKEDIN_REALTIME_URL)) {
      const headers = this.getHeaders();
      return {
        ...REALTIME_HEADER,
        'cookie': headers.cookie,
        'csrf-token': headers['csrf-token']
      };
    } else if (url?.includes('/graphql?')) {
      return {
        ...this.getHeaders(),
        accept: 'application/graphql'
      };
    }
    const ret: Record<string, string> = {};
    for (const header in this.requestInstance.defaults.headers) {
      ret[header] = this.requestInstance.defaults.headers[header] as string;
    }
    return ret;
  }

  public updateCSRFToken() {
    const REGEXP = /([\w\.]+)\s*=\s*(?:"((?:\\"|[^"])*)"|(.*?))\s*(?:[;,]|$)/g;
    const cookies: Record<string, string> = {};
    let match: RegExpExecArray | null = null;
    while ((match = REGEXP.exec(document.cookie)) !== null) {
      const value = match[2] || match[3];
      const key = match[1];
      cookies[key] = decodeURIComponent(value);
    }

    this.addHeaders({ 'csrf-token': cookies.JSESSIONID });
    return cookies.JSESSIONID;
  }

  /**
   * Makes a GET request
   * @param url the URL to make the request to
   * @param options the options to use for the request
   * @param reqConfig the request config
   * @param timeout the timeout for the request
   * @param identifier the identifier for the request
   */
  async get<T>(
    url: string,
    options: Options,
    reqConfig?: ConfigNonFullResponse,
    timeout?: number,
    identifier?: string
  ): Promise<T | null>;

  /**
   * Makes a GET request
   * @param url the URL to make the request to
   * @param options the options to use for the request
   * @param reqConfig the request config
   * @param timeout the timeout for the request
   * @param identifier the identifier for the request
   */
  async get<T>(
    url: string,
    options: Options,
    reqConfig?: ConfigFullResponse,
    timeout?: number,
    identifier?: string
  ): Promise<AxiosResponse<T> | null>;

  /**
   * Makes a GET request
   * @param url the URL to make the request to
   * @param options the options to use for the request
   * @param reqConfig the request config
   * @param timeout the timeout for the request
   * @param identifier the identifier for the request
   */
  async get<T>(
    url: string,
    options: Options,
    reqConfig?: ConfigFullResponse | ConfigNonFullResponse,
    timeout?: number,
    identifier?: string
  ): Promise<T | AxiosResponse<T> | null> {
    Logger.debug(
      `GET ${url.length > 50 ? `${url.slice(0, 24)}…${url.slice(-24)}` : url}`
    );
    this.updateCSRFToken();
    const { requestInstance } = this;
    const response = await this.exponentialRetry<AxiosResponse<T> | null>(
      identifier ?? url,
      async function () {
        return requestInstance.get<T>(buildUrl(url), reqConfig).catch((_e) => {
          if ((_e as AxiosError).isAxiosError) {
            const e = _e as AxiosError;
            Logger.error(
              `${e.response?.status ?? ''} ${e.code ?? ''} ${
                this.identifier
              }: ${e.config?.method?.toUpperCase() ?? ''} ${
                e.config?.url ?? ''
              }`,
              {
                response: e.response?.data
              }
            );
          }
          return null;
        });
      },
      options.prioritize,
      timeout
    );

    return this.checkResponse<T>(response, 'GET', reqConfig);
  }

  /**
   * Makes a POST request
   * @param url the URL to make the request to
   * @param data the data to send
   * @param options the options to use for the request
   * @param reqConfig the request config
   * @param timeout the timeout for the request
   * @param identifier the identifier for the request
   */
  async post<T>(
    url: string,
    data: string | Record<string, unknown>,
    options: Options,
    reqConfig?: ConfigNonFullResponse,
    timeout?: number,
    identifier?: string
  ): Promise<T | null>;

  /**
   * Makes a POST request
   * @param url the URL to make the request to
   * @param data the data to send
   * @param options the options to use for the request
   * @param reqConfig the request config
   * @param timeout the timeout for the request
   * @param identifier the identifier for the request
   */
  async post<T>(
    url: string,
    data: string | Record<string, unknown>,
    options: Options,
    reqConfig?: ConfigFullResponse,
    timeout?: number,
    identifier?: string
  ): Promise<AxiosResponse<T> | null>;

  /**
   * Makes a POST request
   * @param url the URL to make the request to
   * @param data the data to send
   * @param options the options to use for the request
   * @param reqConfig the request config
   * @param timeout the timeout for the request
   * @param identifier the identifier for the request
   */
  async post<T>(
    url: string,
    data: string | Record<string, unknown>,
    options: Options,
    reqConfig?: ConfigFullResponse | ConfigNonFullResponse,
    timeout?: number,
    identifier?: string
  ): Promise<T | AxiosResponse<T> | null> {
    Logger.debug(
      `POST ${url.length > 50 ? `${url.slice(0, 24)}…${url.slice(-24)}` : url}`
    );
    this.updateCSRFToken();
    const { requestInstance } = this;
    const response = await this.exponentialRetry<AxiosResponse<T> | null>(
      identifier ?? url,
      async () => {
        return requestInstance
          .post<T>(buildUrl(url), data, reqConfig)
          .catch((_e) => {
            if ((_e as AxiosError).isAxiosError) {
              const e = _e as AxiosError;
              Logger.error(
                `${e.response?.status ?? ''} ${e.code ?? ''}: ${
                  e.config?.method?.toUpperCase() ?? ''
                } ${e.config?.url ?? ''}`,
                { body: data, response: e.response?.data ?? '[no response]' }
              );
            }
            return null;
          });
      },
      options.prioritize,
      timeout
    );

    return this.checkResponse<T>(response, 'POST', reqConfig);
  }

  /**
   * Handles the response of a request with the given method and request config
   * @param response the response to handle
   * @param method the method of the request
   * @param reqConfig the request config
   */
  private checkResponse<T>(
    response: AxiosResponse<T> | null,
    method: string,
    reqConfig: ConfigFullResponse | ConfigNonFullResponse | undefined
  ): T | AxiosResponse<T> | null {
    const str = `${method} ${response?.config.url ?? ''} -> `;
    if (!response) {
      Logger.warn(`${str}(null)`);
      return null;
    } else if (reqConfig?.fullResponse) {
      Logger.debug(`${str}${response.status} ${response.statusText}`);
      return response;
    }

    if (!response.data) {
      Logger.debug(`${str}Data: (null)`);
      return null;
    }
    Logger.debug(`${str}${response.status} ${response.statusText}`);
    return response.data;
  }

  /**
   * Wrapper for the task queue that retries failed requests with exponential backoff.
   * Only retries requests that return a status code in RETRY_CODES are retried.
   *
   * @param identifier the identifier for the task
   * @param func the function to execute
   * @param addFront If true the task will be added to the front of the queue
   * @param timeout the timeout for the task
   */
  async exponentialRetry<T>(
    identifier: string,
    func: (this: Task) => Promise<T>,
    addFront?: boolean,
    timeout = REQUEST_TIMEOUT
  ): Promise<T | null> {
    let y: T | null = null;
    for (let i = 0; i < MAX_RETRIES; i++) {
      if (i > 0)
        Logger.info(`Retrying failed linkedin request, attempt ${i + 1}`);
      try {
        y = await this.taskQueue.add(`${identifier}_${i}`, func, {
          overrideTimeout: timeout * 2 ** i,
          addFront
        });
        break;
      } catch (error: unknown) {
        const err = error as AxiosError;
        if (!(err.response && RETRY_CODES.includes(err.response.status))) {
          return null;
        }
        Logger.warn(err, 'Error fetching linkedin data');
      }
    }
    return y;
  }
}
