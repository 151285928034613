import ContactActions from '@/data/DataServer/Contact';
import { Pipelines } from '@common/PipelineManager/Pipelines';
import { ServerPipelineStepType } from '@common/PipelineManager/PipelineTypes';
import PrintableError from '@common/PrintableError/PrintableError';
import Logger from 'electron-log';

class SSCLoadingManagerClass {
  private initializing = false;
  private _resolveInit: () => void = () => undefined;
  private _waitForInit = new Promise<void>((res) => {
    this._resolveInit = res;
  });
  public async waitForInit() {
    return this._waitForInit;
  }
  public async init() {
    if (this.initializing) return this._waitForInit;
    this.initializing = true;
    await this._init();
    this._resolveInit();
  }
  private async _init() {
    return Promise.resolve();
  }

  private loadFinished = false;
  private _loading = false;
  private _loadingPromise: Promise<void> | null = null;
  public async load() {
    await this.waitForInit();
    if (this._loading) return this._loadingPromise;
    let resolve: () => void = () => undefined;
    this._loadingPromise = new Promise<void>((res) => (resolve = res));
    try {
      this._loading = true;
      const waitingContacts = await ContactActions.listAllContacts({
        values: {
          stepType: ServerPipelineStepType.WaitForDay,
          skipped: false
        }
      });

      for (const contact of waitingContacts) {
        if (
          contact.lastPipelineEvent?.stepType !==
          ServerPipelineStepType.WaitForDay
        )
          continue;

        await contact.gotoNextPipelineStep();
        Logger.log(
          `[SSCLoadingManager] Contact ${contact.name} (${contact.profileID}) finished wait for date - moved to next step`
        );
      }
    } catch (e) {
      Logger.error('[LoadingManager]: Could not load', e);
      throw e;
    } finally {
      this.loadFinished = true;
      this._loadingPromise = null;
      this._loading = false;
      resolve();
    }
  }
  public isLoading() {
    return this._loading;
  }
  public async waitForLoad() {
    if (!this.loadFinished) {
      await this.load();
      return;
    }
    if (!this._loadingPromise) return Promise.resolve();
    await this._loadingPromise;
  }
}

const SSCLoadingManager = new SSCLoadingManagerClass();
export default SSCLoadingManager;
