import { TaskBuilder, TaskOptionsConfig } from 'focus-mode-scheduler';
import ChatTask from './ChatTask';
import { SSCChat } from '@/data/Classes/Chat/SSCChat';
import { Contact } from '@/data/Classes/Contact';
import { MessageTemplate, PipelineEvent } from '@common/types/ApiTypes';
import { ServerPipelineStepType } from '@common/PipelineManager/PipelineTypes';
import { ContactType } from '@common/types/enums';
import ContactActions from '@/data/DataServer/Contact';
import TemplateActions from '@/data/DataServer/Templates';
import { NextTask } from 'focus-mode-scheduler/src/Task/TaskBuilder';
import ChatTaskUI from './ChatTaskUI';
import { BindProps, MapProps } from 'ui-utils';
import { language } from '@/index';
import { Dates } from '@/other/Dates';
import OnboardingVideo from '../_general/OnboardingVideo/OnboardingVideo';
import { Iterators } from '@/other/Iterators';

interface InstanceData {
  chat: SSCChat;
  contact: Contact;
  templates: MessageTemplate[];
  pipelineHistory: PipelineEvent[];
  type: 'unanswered' | 'followup';
}

export default class ChatTaskBuilder extends TaskBuilder<ChatTask> {
  protected task = ChatTask;

  // first try to get unanswered chats, if there are none, get no answer chats
  private getIterator(): AsyncGenerator<InstanceData> {
    if (this.iterator) return this.iterator;
    this.iterator = Iterators.filterNull(
      Iterators.map(
        Iterators.merge<
          {
            contact: Contact;
            type: 'unanswered' | 'followup';
            priority: number;
          },
          'priority'
        >(
          'priority',
          // unanswered chats / where we want to send a message (e.g. after a meeting, we want to send a follow up message to the customer without waiting for an answer)
          Iterators.fromApiV2(async (cursor) => {
            const contacts = await ContactActions.listContacts(
              cursor?.contact,
              {
                values: {
                  stepType: ServerPipelineStepType.Message,
                  skipped: false,
                  type: ContactType.POTENTIAL_CUSTOMER
                }
              }
            );
            return contacts.map((contact) => ({
              contact,
              type: 'unanswered',
              priority: contact.priority
            }));
          }),
          // follow up chats where we haven't received an answer in the last 7 days
          Iterators.fromApiV2(async (cursor) => {
            const contacts = await ContactActions.listContacts(
              cursor?.contact,
              {
                values: {
                  stepType: ServerPipelineStepType.Message,
                  skipped: false,
                  type: ContactType.POTENTIAL_CUSTOMER
                },
                has: {
                  unansweredMessages: false
                },
                dates: {
                  lastMessage: {
                    before: Dates.add(new Date(), -7, 'day')
                  }
                }
              }
            );
            return contacts.map((contact) => ({
              contact,
              type: 'followup',
              priority: contact.priority
            }));
          })
        ),
        async ({ contact, type }) => {
          const templatesRaw =
            type === 'followup'
              ? await TemplateActions.getFollowUpTemplates()
              : await TemplateActions.getTemplatesOfPipelineStep(
                  contact.lastPipelineEvent?.currentStep
                );
          const templates = templatesRaw?.[0]?.templates ?? [];
          const chat = await contact.getChat(templates[0] ?? null);
          const pipelineHistory = await contact.getPipelineHistory();
          return {
            chat,
            contact,
            templates,
            pipelineHistory,
            type
          };
        }
      )
    );

    return this.iterator;
  }

  protected iterator: AsyncGenerator<InstanceData> | null = null;

  async getTask(): Promise<NextTask<ChatTask>> {
    const { value, done } = await this.getIterator().next();
    if (done) return null;

    const task = new ChatTask(
      value.chat,
      value.contact,
      value.templates,
      value.pipelineHistory,
      value.type
    );
    return {
      task,
      UI: BindProps(ChatTaskUI, { task })
    };
  }

  public getStaticUI(): React.FC<{ loading?: boolean }> | null {
    return MapProps(
      BindProps(OnboardingVideo, {
        onboardingStep: 'focus:chat',
        completeOn: 'finish'
      }),
      ({ loading }: { loading?: boolean }) => ({ pause: loading })
    );
  }

  public async getOptionsConfig(): Promise<TaskOptionsConfig | null> {
    return {
      items: {
        defaultValue: 10,
        min: 1,
        max: 60,
        scale: 'logarithmic',
        step: 1
      },
      time: {
        defaultValue: 15,
        max: 60,
        min: 2,
        scale: 'logarithmic',
        step: 1
      },
      defaultValue: {
        type: 'items',
        items: 10
      },
      texts: { taskName: language.text.chat }
    };
  }
}
