import React from 'react';
import { Box } from '@mui/material';
import { Styles } from '@/other/UtilTypes';
import Appbar from './Appbar/Appbar';
import HUD from '@/components/HUD/HUD';
import HUDTitleIcon from '@/components/HUD/HUDTitleIcon';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { APP_PATHS } from '@/globals/Paths';
import { language } from '@/index';
import { FancyButton, FocusIcon } from 'ui-utils';
import { getManualModeRoutes } from './ManualModeRouter';
import { useFeatureFlags } from '@/globals/featureFlags';

const styles: Styles<'root' | 'routeWrapper' | 'rightWrapper' | 'hudIcon'> = {
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridAutoRows: '100%',
    height: '100vh',
    width: '100vw',
    background: (theme) => theme.palette.background.default
  },
  rightWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  routeWrapper: {
    gridArea: 'route',
    overflow: 'auto',
    width: '100%',
    position: 'relative',
    paddingRight: (theme) => theme.spacing(9.625),
    paddingBottom: (theme) => theme.spacing(5),
    boxSizing: 'border-box'
  },
  hudIcon: {
    color: (theme) => theme.palette.secondary.main,
    fontSize: '2.1rem',
    marginBottom: '2.5px'
  }
};

export default function ManualModeFrame(props: {
  children: React.ReactNode;
  linkedinActive: boolean;
}) {
  const featureFlags = useFeatureFlags();
  const ROUTES = React.useMemo(
    () => getManualModeRoutes(featureFlags),
    [featureFlags]
  );

  const { pathname: path } = useLocation();
  const currentRoute = ROUTES.find((route) =>
    path.startsWith(`/${APP_PATHS.Manual}/${route.path}`)
  );
  const pulsateFct = React.useRef<() => void | Promise<void>>();
  const navigate = useNavigate();

  return (
    <Box
      className="manualModeFrame"
      sx={{
        ...styles.root,
        ...(props.linkedinActive
          ? {
              gridTemplateAreas: '"appbar route"'
            }
          : {
              gridTemplateAreas: '"appbar topbar" "appbar route"',
              gridTemplateRows: (theme) =>
                `minmax(auto, ${theme.spacing(7.5)}) 1fr`
            })
      }}>
      <HUD
        topContent={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '100%',
              px: (theme) => theme.spacing(3)
            }}>
            <Box
              sx={{
                minWidth: {
                  md: '130px',
                  lg: '200px'
                }
              }}></Box>
            <HUDTitleIcon
              title={currentRoute?.name}
              icon={currentRoute && <currentRoute.icon sx={styles.hudIcon} />}
            />
            <FancyButton
              fType={{
                promise: true,
                pulsating: 'default'
              }}
              animations={{
                disableElevation: true
                // color: (theme) =>
                //   next ? theme.palette.secondary.main : theme.palette.primary.dark
              }}
              pulsate={(pulsate) => (pulsateFct.current = pulsate)}
              variant="contained"
              color="primary"
              size="large"
              sx={{
                minWidth: {
                  md: '130px',
                  lg: '200px'
                },
                color: (theme) => theme.palette.primary.contrastText
              }}
              onClick={() => navigate('/focus')}
              startIcon={<FocusIcon sx={{ mr: 1 }} />}>
              {language.text.focus_mode}
            </FancyButton>
          </Box>
        }
        hideBottom={props.linkedinActive}
      />
      <Appbar gridArea="appbar" />
      {/* keep linkedin mounted to not reload site when switching tabs */}
      {/* <Box gridArea="topbar"> */}
      {/* <TopBar visible={!props.linkedinActive} /> */}
      {/* </Box> */}
      <Box
        sx={{
          ...styles.routeWrapper,
          pt: (theme) => theme.spacing(props.linkedinActive ? 7.5 : 0),
          pr: (theme) =>
            theme.spacing(
              props.linkedinActive
                ? 15.25
                : currentRoute?.removeRightPadding
                  ? 0
                  : 9.625
            ),
          pb: (theme) => theme.spacing(props.linkedinActive ? 0 : 5)
        }}>
        {props.children}
      </Box>
    </Box>
  );
}
