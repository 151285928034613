import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Ava from '@/components/Ava/Ava';
import { wait } from '@idot-digital/generic-helpers';

import HUD from '@/components/HUD/HUD';
import HUDTitle from '@/components/HUD/HUDTitle';
import { language } from '@/index';
import FocusModeConstraintDisplay from './FocusModeConstraintDisplay';
import { FancyButton, Logo } from 'ui-utils';
import { useScheduler } from 'focus-mode-scheduler';
import Sounds from '@/other/Sounds';
import posthog from 'posthog-js';
import { useFeatureFlags } from '@/globals/featureFlags';
import MessageBus from '@common/MessageBus/MessageBus.renderer';
import tracking from 'tracking';

export default function FocusModeFrame(props: { children: React.ReactNode }) {
  const { taskInfo: info, header, constraints } = useScheduler();
  const { show_ava } = useFeatureFlags();
  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const navigate = useNavigate();

  const [loadingOnClose, setLoadingOnClose] = React.useState(false);

  const [closeHintOpen, setCloseHintOpen] = React.useState(false);

  constraints.use('constraint:breach', () => {
    Sounds.play('level-up');
  });

  return (
    <>
      <Dialog open={closeHintOpen} onClose={() => setCloseHintOpen(false)}>
        <DialogTitle>{language.text.are_you_sure}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {language.text.leave_focus_mode_confirmation}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setCloseHintOpen(false)}
            disabled={loadingOnClose}>
            {language.text.cancel}
          </Button>
          <FancyButton
            fType={{ promise: true }}
            onClick={async () => {
              try {
                setLoadingOnClose(true);
                await window.api.send('app:check-for-update');
                if (!mounted.current) return;
                tracking.capture('FocusModeFinished');
                posthog.capture('focus-mode-finished');
                navigate('/manual');
                await wait(250);
              } finally {
                if (mounted.current) setLoadingOnClose(false);
              }
            }}>
            {language.text.leave_focus_mode}
          </FancyButton>
        </DialogActions>
      </Dialog>
      {show_ava && <Ava>{info.avaContent}</Ava>}

      {header && (
        <Logo
          variant={'blue'}
          sx={{
            position: 'absolute',
            height: (theme) => theme.spacing(8),
            width: (theme) => theme.spacing(8),
            margin: (theme) => theme.spacing(1, 1, 2, 0),
            cursor: 'pointer',
            zIndex: 12
          }}
          onClick={() => setCloseHintOpen(true)}
        />
      )}
      <Box
        sx={{
          height: '100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column'
        }}>
        {header && (
          <HUD
            topContent={
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: '100%',
                  px: (theme) => theme.spacing(3)
                }}>
                <Box
                  sx={{
                    minWidth: {
                      md: '130px',
                      lg: '200px'
                    }
                  }}
                />
                <HUDTitle
                  title={header.title}
                  subtitle={header.subtitle ?? ''}
                />
                <FocusModeConstraintDisplay
                  onLeave={() => setCloseHintOpen(true)}
                />
              </Box>
            }
          />
        )}
        <Box
          sx={{
            width: header ? 'calc(95% - 180px)' : '100%',
            height: '100%',
            maxHeight: '100vh',
            overflowY: 'auto',
            position: 'relative',
            boxSizing: 'border-box',
            margin: '0 auto',
            paddingTop: (theme) => (header ? theme.spacing(7.5) : 0),
            paddingBottom: (theme) => (header ? theme.spacing(5) : 0)
          }}>
          {props.children}
        </Box>
      </Box>
    </>
  );
}
