import { Logger, parseCollectionResponse, trace } from '../client/Utils';
import { EventService } from '../services/EventService';
import {
  ApiEvent,
  ClientConnectionData,
  ConnectionEvent,
  ConversationEvent,
  ConversationTopicData,
  DecoratedEventData,
  Event,
  EventTopic,
  LinkedInEvent,
  LinkedInMessagingMember,
  LinkedInMiniProfile,
  MessageEvent,
  MessageReactionSummariesTopicData,
  MessageSeenReceiptsTopicData,
  MessagesTopicData,
  PingEvent,
  QuickReplyEvent,
  ReactionSummaryEvent,
  ReadEvent,
  RealtimeConversation,
  RealtimeEvent,
  TabBadgeUpdateEvent,
  TabBadgeUpdateTopicData,
  TypingEvent,
  TypingIndicatorsTopicData
} from 'linkedin-domain-types';

enum RealtimeEventKind {
  NONE,
  CONNECTION,
  HEARTBEAT,
  DECORATED
}

const lexer = new Map<RegExp, RealtimeEventKind>([
  [
    /com\.linkedin\.realtimefrontend\.ClientConnection/,
    RealtimeEventKind.CONNECTION
  ],
  [/com\.linkedin\.realtimefrontend\.Heartbeat/, RealtimeEventKind.HEARTBEAT],
  [
    /com\.linkedin\.realtimefrontend\.DecoratedEvent/,
    RealtimeEventKind.DECORATED
  ]
]);

/**
 * Class for listening to realtime events, parsing them and emitting them as new events
 */
@trace()
export class RealtimeEventParser {
  constructor(readonly eventService: EventService) {
    eventService.addListener(Event.REALTIME, (event: RealtimeEvent) =>
      this.parse(event.data)
    );
  }

  /**
   * Parses a realtime event and emits it as a new event
   */
  private parse(event: object) {
    const [root, data] = Object.entries(event)[0] as [string, object];
    const [, kind] = [...lexer.entries()].find(([regex]) =>
      regex.test(root)
    ) ?? [undefined, RealtimeEventKind.NONE];
    if (kind === RealtimeEventKind.NONE) {
      Logger.error(`Unknown realtime event: ${root}`);
      Logger.warn(`Unknown realtime event: ${JSON.stringify(data, null, 2)}`);
    } else if (kind === RealtimeEventKind.CONNECTION) {
      this.eventService.emit({
        type: Event.CONNECTION,
        topics: (data as ClientConnectionData).personalTopics
      } as ConnectionEvent);
    } else if (kind === RealtimeEventKind.HEARTBEAT) {
      this.eventService.emit({
        type: Event.PING
      } as PingEvent);
    } else if (kind === RealtimeEventKind.DECORATED) {
      const event = this.processDecoratedEvent(data as DecoratedEventData<any>);
      if (event !== null) {
        this.eventService.emit(event);
      } else {
        Logger.warn(
          `Unprocessed decorated event: ${JSON.stringify(data, null, 2)}`
        );
      }
    } else {
      Logger.error(`Unprocessed realtime event kind: ${kind}`);
    }
  }

  /**
   * Processes a decorated event and returns the actual event
   */
  private processDecoratedEvent(
    event: DecoratedEventData<any>
  ): ApiEvent<Event> | null {
    const [, kind] = [...Object.entries(EventTopic)].find(([, value]) =>
      event.topic.includes(value)
    ) ?? [undefined, undefined];
    if (kind === undefined) {
      Logger.error(`Unknown decorated event: ${event.topic}`);
      Logger.warn(`Unknown decorated event: ${JSON.stringify(event, null, 2)}`);
    } else if (kind === EventTopic.tabBadgeUpdate) {
      return {
        type: Event.TAB_BADGE_UPDATE,
        badges: (event as TabBadgeUpdateTopicData).payload.data.value.tabBadges
      } as TabBadgeUpdateEvent;
    } else if (kind === EventTopic.messages) {
      const { payload } = event as MessagesTopicData;
      const data = parseCollectionResponse<
        LinkedInEvent,
        LinkedInEvent | LinkedInMessagingMember | LinkedInMiniProfile
      >([payload.data.value['*event'].toString()], payload.included)[0];
      data.eventContent.body =
        data.eventContent.attributedBody?.text ?? data.eventContent.body;
      return {
        type: Event.MESSAGE,
        unreadConversationsCount: payload.data.value.unreadConversationsCount,
        conversationUnreadCount: payload.data.value.conversationUnreadCount,
        conversationLastActivityAt:
          payload.data.value.conversationLastActivityAt,
        previousEventInConversationUrn:
          payload.data.value.previousEventInConversationUrn,
        event: data,
        attachments:
          data.eventContent.attachments?.map((attachment) => ({
            url: attachment.reference,
            type: 'file'
          })) ?? []
      } as MessageEvent;
    } else if (kind === EventTopic.messageSeenReceipts) {
      const { payload } = event as MessageSeenReceiptsTopicData;
      return {
        type: Event.READ,
        seenAt: payload.data.value.seenReceipt.seenAt,
        message: payload.data.value.seenReceipt.eventUrn,
        fromEntity: payload.data.value.fromEntity,
        fromParticipant: payload.data.value.fromParticipant
      } as ReadEvent;
    } else if (kind === EventTopic.typingIndicators) {
      const { payload } = event as TypingIndicatorsTopicData;
      return {
        type: Event.TYPING,
        from: payload.data.value.fromEntity,
        conversation: payload.data.value.conversation
      } as TypingEvent;
    } else if (kind === EventTopic.conversations) {
      const { payload } = event as ConversationTopicData;
      const data = parseCollectionResponse<
        RealtimeConversation,
        RealtimeConversation | LinkedInMessagingMember | LinkedInMiniProfile
      >([payload.data['*value'].toString()], payload.included)[0];
      return {
        type: Event.CONVERSATION,
        starred: data.starred,
        entityUrn: data.entityUrn,
        unreadConversationsCount: data.unreadConversationCount,
        conversation: data
      } as ConversationEvent;
    } else if (kind === EventTopic.replySuggestion) {
      const { payload } = event;
      return {} as QuickReplyEvent;
    } else if (kind === EventTopic.messageReactionSummaries) {
      const { payload } = event as MessageReactionSummariesTopicData;
      return {
        type: Event.REACTION_SUMMARY,
        message: payload.data.value.eventUrn,
        actor: payload.data.value.actorMiniProfileUrn,
        reactionAdded: payload.data.value.reactionAdded,
        reactionSummary: payload.data.value.reactionSummary
      } as ReactionSummaryEvent;
    }
    return null;
  }
}
