import UsageTypeSelector from '@/components/UsageTypeSelector/UsageTypeSelector';
import { language } from '@/index';
import { Box, Typography } from '@mui/material';

export default function UsageClassSettings() {
  return (
    <Box>
      <Typography variant="h4">{language.text.linkedin_usage}</Typography>
      <Typography variant="body1">
        {language.text.linkedin_usage_description}
      </Typography>
      <UsageTypeSelector />
    </Box>
  );
}
