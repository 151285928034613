import {
  DashProfileUrn,
  DigitalMediaAssetUrn,
  MemberUrn,
  MessageConversationUrn,
  MessageMessageUrn,
  MessageMessagingParticipantUrn,
  MessagingMessageUrn,
  MessagingThreadUrn
} from './Urns';
import { LinkedInConversationCategory, LinkedInVectorImage } from './Common';
import { LinkedInAttributedText } from './LinkedInAttributedText';
import { ReactionSummary } from './Messaging';

const MESSENGER_TYPE_PREFIX = `com.linkedin.messenger`;

export type LinkedInNotificationStatus = 'ACTIVE';

export type LinkedInMessageBodyRenderFormat = 'DEFAULT';

export type LinkedInDistance =
  | 'SELF'
  | 'DISTANCE_1'
  | 'DISTANCE_2'
  | 'DISTANCE_3';

export type LinkedInPageType = 'SCHOOL';

export const MESSENGER_CONVERSATION_TYPE =
  `${MESSENGER_TYPE_PREFIX}.Conversation` as const;

export interface LinkedInMessengerConversation {
  '$type': typeof MESSENGER_CONVERSATION_TYPE;
  '_recipeType': unknown;
  'backendUrn': MessagingThreadUrn;
  'categories': LinkedInConversationCategory[];
  'contentMetadata': unknown;
  '*conversationParticipants': MessageMessagingParticipantUrn[];
  'conversationParticipants': LinkedInMessagingParticipant[];
  'conversationTypeText': unknown;
  'conversationUrl': string;
  'createdAt': number;
  '*creator': MessageMessagingParticipantUrn;
  'creator': LinkedInMessagingParticipant;
  'disabledFeatures': LinkedInDisabledFeature[];
  'entityUrn': MessageConversationUrn;
  'groupChat': boolean;
  'headlineText': unknown;
  'hostConversationActions': unknown[];
  'incompleteRetriableData': boolean;
  'lastActivityAt': number;
  'lastReadAt': number | null;
  'messages': {
    '*elements': MessageMessageUrn[];
    'elements': LinkedInMessage[];
    '$type': 'com.linkedin.restli.common.CollectionResponse';
  };
  'lastMessages'?: LinkedInMessage[];
  'notificationStatus': LinkedInNotificationStatus;
  'read': boolean;
  'shortHeadlineText': unknown;
  'state': unknown;
  'title': unknown;
  'unreadCount': number;
}

export const MESSENGER_MESSAGE_TYPE =
  `${MESSENGER_TYPE_PREFIX}.Message` as const;

export interface LinkedInMessage {
  '$type': typeof MESSENGER_MESSAGE_TYPE;
  'backendConversataionUrn': MessagingThreadUrn;
  'backendUrn': MessagingMessageUrn;
  'body': LinkedInAttributedText;
  '*conversation': MessageConversationUrn;
  'conversation': LinkedInMessengerConversation;
  'deliveredAt': number;
  'entityUrn': MessageMessageUrn;
  'footer': unknown;
  'incompleteRetriableData': boolean;
  'inlineWarning': unknown;
  'messageBodyRenderFormat': LinkedInMessageBodyRenderFormat;
  'originToken': unknown;
  'reactionSummaries': ReactionSummary[];
  'renderContent': LinkedInRenderContent[];
  'renderContentFallbackText': unknown;
  '*sender': MessageMessagingParticipantUrn;
  'sender': LinkedInMessagingParticipant;
  'subject': unknown;
}

export interface LinkedInRenderContent {
  videoMeeting: unknown | null;
  conversationAdsMessageContent: unknown | null;
  repliedMessageContent: unknown | null;
  video: unknown | null;
  vectorImage: LinkedInVectorImage | null;
  awayMessage: unknown | null;
  file: LinkedInFileAttachment | null;
  externalMedia: unknown | null;
  messageAdRenderContent: unknown | null;
  audio: LinkedInMessengerAudioMetadata | null;
  forwardedMessageContent: unknown | null;
  hostUrnData: unknown | null;
  unavailableContent: unknown | null;
}

export const MESSENGER_AUDIO_METADATA_TYPE =
  `${MESSENGER_TYPE_PREFIX}.AudioMetadata` as const;

export interface LinkedInMessengerAudioMetadata {
  $type: typeof MESSENGER_AUDIO_METADATA_TYPE;
  duration: number;
  url: string;
}

export const FILE_ATTACHMENT_TYPE =
  `${MESSENGER_TYPE_PREFIX}.FileAttachment` as const;

export interface LinkedInFileAttachment {
  $type: typeof FILE_ATTACHMENT_TYPE;
  assetUrn: DigitalMediaAssetUrn;
  byteSize: number;
  name: string;
  mediaType: string;
  url: string;
}

export const MESSENGER_MESSAGING_PARTICIPANT_TYPE =
  `${MESSENGER_TYPE_PREFIX}.MessagingParticipant` as const;

export interface LinkedInMessagingParticipant {
  $type: typeof MESSENGER_MESSAGING_PARTICIPANT_TYPE;
  backendUrn: MemberUrn;
  entityUrn: MessageMessagingParticipantUrn;
  hostIdentityUrn: DashProfileUrn;
  participantType: {
    custom: unknown;
    member: LinkedInMemberParticipantInfo | null;
    organization: LinkedInOrganizationParticipantInfo | null;
  };
}

export const MESSENGER_ORGANIZATION_PARTICIPANT_INFO_TYPE =
  `${MESSENGER_TYPE_PREFIX}.OrganizationParticipantInfo` as const;

export interface LinkedInOrganizationParticipantInfo {
  $type: typeof MESSENGER_ORGANIZATION_PARTICIPANT_INFO_TYPE;
  following: boolean;
  industryName: unknown;
  logo: LinkedInVectorImage;
  name: LinkedInAttributedText;
  pageType: LinkedInPageType;
  pageUrl: string;
  tagline: LinkedInAttributedText;
}

export const MESSENGER_MEMBER_PARTICIPANT_INFO_TYPE =
  `${MESSENGER_TYPE_PREFIX}.MemberParticipantInfo` as const;

export interface LinkedInMemberParticipantInfo {
  $type: typeof MESSENGER_MEMBER_PARTICIPANT_INFO_TYPE;
  distance: LinkedInDistance;
  firstName?: LinkedInAttributedText;
  headline: LinkedInAttributedText;
  lastName?: LinkedInAttributedText;
  profilePicture: LinkedInVectorImage;
  profileUrl: string;
  pronoun: unknown;
}

export const MESSENGER_CONVERSATION_DISABLED_FEATURE_TYPE =
  `${MESSENGER_TYPE_PREFIX}.DisabledFeature` as const;

export interface LinkedInDisabledFeature {
  $type: typeof MESSENGER_CONVERSATION_DISABLED_FEATURE_TYPE;
  disabledFeature: string;
  reasonText: unknown;
}

export const MESSENGER_CONVERSATION_CURSOR_METADATA_TYPE =
  `${MESSENGER_TYPE_PREFIX}.CursorMetadata` as const;

export interface LinkedInConversationCursorMetadata {
  $type: typeof MESSENGER_CONVERSATION_CURSOR_METADATA_TYPE;
  nextCursor: string;
  rawSearchId: string;
  trackingIds: LinkedInSearchTrackingId[];
}

export const MESSENGER_SEARCH_TRACKING_ID_TYPE =
  `${MESSENGER_TYPE_PREFIX}.SearchTrackingId` as const;

export interface LinkedInSearchTrackingId {
  $type: typeof MESSENGER_SEARCH_TRACKING_ID_TYPE;
  hitEntityUrn: MessagingMessageUrn;
  trackingId: string;
}
