import { language } from '@/index';
import { APP_PATHS, LOADER_PATHS, POST_LOADER_PATHS } from '@/globals/Paths';
import { useNavigate } from 'react-router-dom';
import LoaderStart from '../_generic/LoaderStart';
import posthog from 'posthog-js';

export default function PostLoaderStart() {
  const navigate = useNavigate();

  return (
    <LoaderStart
      title={language.text.load_posts}
      description={language.text.load_posts_description}
      continue={language.text.load_posts}
      onContinue={() => {
        posthog.capture('advanced-loading');
        navigate(
          `/${APP_PATHS.Loader}/${LOADER_PATHS.Post}/${POST_LOADER_PATHS.Run}`
        );
      }}
    />
  );
}
