import {
  ConversationUrn,
  DashDigitalMediaConferenceUrn,
  DashMessageUrn,
  EventUrn,
  MemberUrn,
  MessagingMemberUrn,
  MessagingMessageUrn,
  MessagingThreadUrn,
  MiniProfileUrn,
  QuickReplyUrn,
  UpdateV2Urn
} from './Urns';
import {
  LinkedInAudioMetadata,
  LinkedInFileMetadata,
  LinkedInVectorImage
} from './Common';
import { LinkedInMiniProfile } from './Identity';
import { LinkedInAttributedText } from './LinkedInAttributedText';
import { CreatedBeforeResponse } from './responses/CreatedBeforeResponse';

const MESSAGING_TYPE_PREFIX = 'com.linkedin.voyager.messaging';
const DASH_MESSAGING_TYPE_PREFIX = 'com.linkedin.voyager.dash.messaging';
export type ConversationId = string;

export const MESSAGING_MEMBER_TYPE =
  `${MESSAGING_TYPE_PREFIX}.MessagingMember` as const;

export interface LinkedInMessagingMember {
  '$type': typeof MESSAGING_MEMBER_TYPE;
  'entityUrn': MessagingMemberUrn;
  '*miniProfile': MiniProfileUrn;
  'restrictedProfile'?: boolean;
  'miniProfile': LinkedInMiniProfile | null;
  'alternateName'?: string;
  'alternateImage'?: LinkedInVectorImage;
  'nameInitials'?: string;
}

export const MESSAGE_EVENT_TYPE =
  `${MESSAGING_TYPE_PREFIX}.event.MessageEvent` as const;

export interface LinkedInMessageEvent {
  '$type': typeof MESSAGE_EVENT_TYPE;
  'attributedBody'?: LinkedInAttributedText;
  'body': string;
  'messageBodyRenderFormat': string;
  'mediaAttachments'?: LinkedInAudioMetadata[];
  'attachments'?: LinkedInFileMetadata[];
  '*feedUpdate'?: UpdateV2Urn;
  'recalledAt'?: number;
  'digitalMediaConferenceUrn'?: DashDigitalMediaConferenceUrn;
}

export const EVENT_TYPE = `${MESSAGING_TYPE_PREFIX}.Event` as const;

export interface LinkedInEvent extends CreatedBeforeResponse {
  '$type': typeof EVENT_TYPE;
  '*from': MessagingMemberUrn;
  'from'?: LinkedInMessagingMember;
  'originToken': string;
  'reactionSummaries': ReactionSummary[];
  'dashEntityUrn': DashMessageUrn;
  'quickReplies'?: unknown[];
  'eventContent': LinkedInMessageEvent;
  'backendUrn': MessagingMessageUrn;
  'obfuscatedMessageWarning': unknown;
  'expiresAt'?: number;
  'createdAt': number;
  'quickReplyRecommendations': LinkedInQuickReply[];
  'inlineWarningDismissCTAText'?: string;
  'entityUrn': EventUrn;
  'subtype': string;
  'previousEventInConversation'?: EventUrn;
  'reportSpamCTAText'?: string;
  'viewObfuscatedMessageCTAText'?: string;
  'eventId': string;
}

export const REACTION_SUMMARY_TYPE =
  `${MESSAGING_TYPE_PREFIX}.ReactionSummary` as const;

export interface ReactionSummary {
  $type: typeof REACTION_SUMMARY_TYPE;
  count: number;
  emoji: string;
  firstReactedAt: number;
  viewerReacted: boolean;
}

export const QUICK_REPLY_RECOMMENDATION_TYPE =
  `${MESSAGING_TYPE_PREFIX}.QuickReply` as const;

export interface LinkedInQuickReply {
  $type: typeof QUICK_REPLY_RECOMMENDATION_TYPE;
  content: LinkedInAttributedText;
  objectUrn: QuickReplyUrn;
  replyType: string;
  trackingId: string;
}

export const CONVERSATION_TYPE =
  `${MESSAGING_TYPE_PREFIX}.Conversation` as const;

export interface LinkedInConversation extends CreatedBeforeResponse {
  'conversationId': ConversationId;
  'participants': LinkedInMessagingMember[];
  '$type': typeof CONVERSATION_TYPE;
  '*events': EventUrn[];
  'events': IterableIterator<LinkedInEvent>;
  '*participants': MessagingMemberUrn[];
  '*type': string[];
  'archived': boolean;
  'backendUrn': MessagingThreadUrn;
  'blocked': boolean;
  'entityUrn': ConversationUrn;
  'featureTypes': FeaturedType[];
  'firstMessageUrn': EventUrn;
  'lastActivityAt': number;
  'muted': boolean;
  'notificationStatus': string;
  'read': boolean;
  'receipts': LinkedInParticipantReceipts[];
  'totalEventCount': number;
  'unreadCount': number;
  'viewerCurrentParticipant': boolean;
  'withNonConnection': boolean;
}

export enum FeaturedType {
  REACTIONS,
  CREATE_NEW_GROUP_CHAT,
  CREATE_LINKEDIN_VIRTUAL_MEETING
}

export const SEEN_RECEIPT_TYPE =
  `${MESSAGING_TYPE_PREFIX}.SeenReceipt` as const;

interface LinkedInSeenReceipt {
  $type: typeof SEEN_RECEIPT_TYPE;
  eventUrn: EventUrn;
  seenAt: number;
}

export const PARTICIPANT_RECEIPTS_TYPE =
  `${MESSAGING_TYPE_PREFIX}.ParticipantReceipts` as const;

export interface LinkedInParticipantReceipts {
  $type: typeof PARTICIPANT_RECEIPTS_TYPE;
  fromEntity: MiniProfileUrn;
  fromParticipant: MemberUrn;
  seenReceipt: LinkedInSeenReceipt;
}

export const PRESENCE_STATUS_TYPE =
  `${DASH_MESSAGING_TYPE_PREFIX}.presence.PresenceStatus` as const;

export interface LinkedInPresenceStatus {
  $type: typeof PRESENCE_STATUS_TYPE;
  available: boolean;
  lastActiveAt: number;
  instantlyReachable: boolean;
}
