import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

const PulsatingBadge = styled(Badge)(({ theme, color }) => {
  const calculatedColor = color
    ? color === 'default'
      ? 'currentColor'
      : theme.palette[color].main
    : 'currentColor';
  return {
    '& .MuiBadge-badge': {
      'backgroundColor': calculatedColor,
      'color': calculatedColor,
      'boxShadow': `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""'
      }
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0
      }
    }
  };
});

export default PulsatingBadge;
