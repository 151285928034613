import React from 'react';
import { Box, Button, Divider } from '@mui/material';
import ChatTextField from '@/components/Chat/ChatTextField';
import { Editor } from '@tiptap/react';
import ChatHistory, { SendMessage } from '@/components/Chat/ChatHistory';
import useError from '@/hooks/ErrorHook';
import { SSCChat } from '@/data/Classes/Chat/SSCChat';
import { MessageTemplate } from '@common/types/ApiTypes';
import { wait } from '@idot-digital/generic-helpers';
import { language } from '@/index';
import { PipelineStep } from '@common/PipelineManager/PipelineTypes';
import ContactAvatar from '@/components/Avatar/ContactAvatar';
import { CheckIcon } from 'ui-utils';
import ChatTemplates from '@/components/Chat/ChatTemplates';

export interface ContactChatProps {
  chat: SSCChat;
  fillTemplateFromChat?: boolean;
  message?: string;
  onSend?: (text: string) => Promise<void>;
  getEditor?: (editor: Editor) => void;
  hide?: boolean;
  openRelation?: () => void;
  currentStep?: PipelineStep | null;
  disableChat?: boolean;
  templates?: MessageTemplate[];
  showHeader?: boolean;
  sendMessages?: SendMessage[] | null;
}

export default function ContactChat(props: ContactChatProps) {
  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const { errorInfo, handleError } = useError();

  const [editor, setEditor] = React.useState<Editor | null>(null);

  const [loading, setLoading] = React.useState(false);
  const [sendMessages, setSendMessages] = React.useState<SendMessage[]>([]);
  const allSendMessages = React.useMemo(
    () => [...sendMessages, ...(props.sendMessages ?? [])],
    [sendMessages, props.sendMessages]
  );

  const [selectedTemplate, setSelectedTemplate] = React.useState<number | null>(
    null
  );
  React.useEffect(() => {
    if (!props.templates) return;
    if (selectedTemplate) return;
    setSelectedTemplate(props.templates[0]?.variantID ?? null);
    props.chat.setTemplateVariant(props.templates[0]?.variantID);
  }, [props.templates]);

  const setEditorContent = (text: string) => {
    if (!editor) return;
    editor.commands.setContent(
      `<p>${text.replace(/\n/g, '</p><p>')}</p>`,
      true
    );
    editor.commands.setTextSelection({ from: 0, to: 0 });
    editor.commands.scrollIntoView();
  };

  // set default states from step data
  // fill out message template + notes + pipeline status
  React.useEffect(() => {
    if (!props.message || !editor) return;
    const message = props.message;
    // call as timeout since setEditorContent calls fullSync which can't be called inside of lifecycle methods (useEffect) so we delay it
    const timeout = setTimeout(() => {
      setEditorContent(message);
    });
    return () => {
      clearTimeout(timeout);
    };
  }, [props.message, editor]);

  React.useEffect(() => {
    if (!props.fillTemplateFromChat || !editor) return;
    const template = props.chat.getFilledTemplate();
    if (!template) return;
    // call as timeout since setEditorContent calls fullSync which can't be called inside of lifecycle methods (useEffect) so we delay it
    const timeout = setTimeout(() => {
      setEditorContent(template);
      setSelectedTemplate(props.chat.template?.variantID ?? null);
    });
    return () => {
      clearTimeout(timeout);
    };
  }, [
    props.chat,
    props.chat.template?.templateID,
    props.fillTemplateFromChat,
    editor
  ]);

  return (
    <Box
      sx={{
        // hide, not unmount when not showing
        display: props.hide ? 'none' : 'flex',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        flexDirection: 'column',
        pt: 0,
        mr: 2,
        borderRadius: 1,
        background: 'background.default'
      }}>
      {props.openRelation && (
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={() => props.openRelation?.()}
          sx={{
            mx: 2,
            mb: 2,
            // since margin on each side is 2 and we want the button to be full width
            width: (theme) => `calc(100% - ${theme.spacing(4)})`
          }}
          startIcon={
            <CheckIcon
              sx={{
                width: '28px',
                height: '28px'
              }}
            />
          }>
          {props.currentStep
            ? props.currentStep.name
            : language.text.select_relation_step}
        </Button>
      )}
      {props.showHeader && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              px: 2,
              mb: 2
            }}>
            <ContactAvatar
              contact={{
                firstname: props.chat.contact?.firstname ?? '',
                lastname: props.chat.contact?.lastname ?? '',
                name: props.chat.contact?.name ?? '',
                pictures: props.chat.contact?.pictures ?? {},
                priority: props.chat.contact?.priority
              }}
            />
            {props.chat.contact?.name}
          </Box>
          <Divider orientation="horizontal" sx={{ mx: 2, opacity: 0.6 }} />
        </>
      )}
      <Box
        sx={{
          flex: '1 0 0',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Box
          sx={{
            flex: '1 0 0',
            width: '100%',
            position: 'relative'
          }}>
          <Box position="absolute" top="0" left="0" width="100%" height="100%">
            <ChatHistory
              chat={props.chat}
              sendMessages={allSendMessages}
              disabled={props.disableChat}
            />
          </Box>
        </Box>
        <Box flex="0 0 auto" width="100%">
          <ChatTemplates
            templates={props.templates}
            selectedTemplate={selectedTemplate}
            onSelectTemplate={(template) => {
              const text = props.chat.getFilledTemplate(template.text);
              if (!text) return;
              props.chat.setTemplateVariant(template.variantID);
              setEditorContent(text);
              setSelectedTemplate(template.variantID);
            }}
          />
          <ChatTextField
            getEditor={(editor) => {
              setEditor(editor);
              props.getEditor?.(editor);
            }}
            info={
              errorInfo
                ? {
                    variant: 'error',
                    info: errorInfo
                  }
                : null
            }
            defaultMessage={props.message}
            autofocus
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- logical or is the intended behavior and NOT ??
            disabled={props.disableChat || loading}
            disabledMessage={
              loading ? undefined : language.text.cant_write_in_this_stage
            }
            onClear={() => {
              props.chat.setTemplateVariant(undefined);
              setSelectedTemplate(null);
            }}
            onSend={handleError(
              async (text) => {
                setLoading(true);
                setSendMessages((messages) => [
                  ...messages,
                  {
                    text,
                    createdAt: new Date(),
                    attachments: [],
                    deleted: false,
                    sendByYou: true,
                    reactions: []
                  }
                ]);

                // clear text field and disable editing to simulate sending message
                editor?.commands.clearContent();
                editor?.setOptions({ editable: false });

                await wait(200 + Math.random() * 350);

                if (!mounted.current) return false;
                await props.onSend?.(text);
                // reenable editing when simulated loading is done
                editor?.setOptions({ editable: true });

                return true;
              },
              false,
              () => setLoading(false)
            )}
          />
        </Box>
      </Box>
    </Box>
  );
}
