import MainConfig from '@common/config/MainConfig';

export default class Versions {
  static isMainThreadOutDated() {
    const currentMainVersion = APP_VERSION.split('.').map((i) => parseInt(i));
    const minmumMainVersion = MainConfig.minimumVersion
      .split('.')
      .map((i) => parseInt(i));

    return minmumMainVersion.some((v, i) => {
      return v > currentMainVersion[i];
    });
  }
}
