import React from 'react';
import { Avatar, AvatarProps } from '@mui/material';
import { useLinkedInAccount } from '@/data/LinkedIn/Account';
import { useRefSize } from 'ui-utils';

export default function AccountAvatar(props: AvatarProps) {
  const { linkedInAccount } = useLinkedInAccount();

  const name = React.useMemo(() => {
    if (linkedInAccount)
      return linkedInAccount.firstName + ' ' + linkedInAccount.lastName;
    return '';
  }, [linkedInAccount]);

  const ref = React.useRef<HTMLImageElement>(null);

  const { height } = useRefSize(ref);

  const image = React.useMemo(() => {
    if (!linkedInAccount || !linkedInAccount.profilePictureUrl || !height)
      return null;
    const sizes = Object.keys(linkedInAccount.profilePictureUrl)
      .map((key) => parseInt(key))
      // filter out invalid keys
      .filter((key) => !isNaN(key))
      // sort by largest first
      .sort((a, b) => b - a);

    // get first size larger than height
    const fittingSize = sizes.reduce<number | null>((prev, curr) => {
      if (prev === null) return curr;
      if (curr < height && curr < prev) return prev;
      if (curr > height && curr > prev) return prev;
      return curr;
    }, null);

    return linkedInAccount.profilePictureUrl[fittingSize || sizes[0]];
  }, [height, linkedInAccount]);

  return (
    <Avatar ref={ref} src={image || undefined} alt={name} {...props}>
      {getInitials(name)}
    </Avatar>
  );
}

function getInitials(name: string): string {
  const names = name.split(' ');
  let initials = names.map((name) => name[0]);
  if (initials.length > 2)
    initials = initials.slice(0, 1).concat(initials.slice(-1));
  return initials.join('');
}
