import React from 'react';
import { Box } from '@mui/material';
import { Done } from '@mui/icons-material';
import { language } from '@/index';
import { FancyButton } from 'ui-utils';

export interface FocusBarButton {
  fct: () => void | Promise<void>;
  label?: string;
  disabled?: boolean;
  disabledTooltip?: React.ReactNode;
  disableConfirmation?: boolean;
}

export interface HUDFocusBarProps {
  // complete current task
  complete?: Omit<FocusBarButton, 'disableConfirmation'> | null;
  children?: React.ReactNode | React.ReactNode[];
}

export default function HUDFocusBar(props: HUDFocusBarProps) {
  return (
    <Box
      sx={{
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        padding: (theme) => theme.spacing(0, 5),
        boxSizing: 'border-box',
        position: 'absolute',
        bottom: (theme) => theme.spacing(2.5),
        zIndex: 60,
        gap: 2
      }}>
      {props.children}

      {props.complete && (
        <FancyButton
          fType={{
            promise: true
          }}
          variant="contained"
          color="primary"
          size="large"
          disabled={props.complete?.disabled}
          onClick={props.complete?.fct.bind(null)}
          sx={{
            minWidth: {
              md: '130px',
              lg: '200px'
            },
            alignSelf: 'center',
            ...(props.complete?.disabled && {
              background: (theme) =>
                `${theme.palette.background.paper} !important`
            })
          }}
          endIcon={<Done />}>
          {props.complete?.label ?? language.text.continue}
        </FancyButton>
      )}
    </Box>
  );
}
