import { SvgIcon, SvgIconProps } from '@mui/material';
import LinkedIn from './svgs/linkedin.svg?react';
import LinkedInFull from './svgs/linkedin_full.svg?react';

export default function LinkedInIcon(
  props: SvgIconProps & { variant?: 'icon' | 'full' }
) {
  const { variant = 'icon', ...rest } = props;
  return (
    <SvgIcon
      component={variant === 'full' ? LinkedInFull : LinkedIn}
      viewBox={variant === 'full' ? '0 0 291 79.46' : '0 0 19.872 19.872'}
      {...rest}
    />
  );
}
