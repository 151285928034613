import React from 'react';
import { Button, ButtonProps, Tooltip, Typography } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';

export interface CopyTextButtonProps
  extends Omit<ButtonProps, 'startIcon' | 'endIcon' | 'ref'> {
  icon?: React.FC<{ sx?: React.CSSProperties }>;
  popupTime?: number;
  popupText: React.ReactNode;
  textToCopy?: string;
  disableLeftSpacing?: boolean;
}

export default function CopyTextButton(props: CopyTextButtonProps) {
  const {
    icon,
    popupTime,
    popupText,
    textToCopy,
    disableLeftSpacing,
    ...buttonProps
  } = props;

  const [codeCopied, setCodeCopied] = React.useState(false);
  React.useEffect(() => {
    if (!codeCopied) return;
    const timeout = setTimeout(() => {
      setCodeCopied(false);
    }, props.popupTime ?? 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [codeCopied]);

  const buttonRef = React.useRef<HTMLElement | null>(null);

  const Icon = props.icon ?? ContentCopy;

  return (
    <Tooltip
      title={
        <Typography
          onClick={() => setCodeCopied(false)}
          sx={{ cursor: 'pointer' }}>
          {props.popupText}
        </Typography>
      }
      placement="top"
      open={codeCopied}>
      <Button
        variant="contained"
        color="primary"
        {...buttonProps}
        ref={(ref) => {
          buttonRef.current = ref;
        }}
        onClick={(e) => {
          const text = props.textToCopy ?? props.children?.toString();
          if (!text) return;
          setCodeCopied(true);
          navigator.clipboard.writeText(text);
          props.onClick?.(e);
        }}
        sx={{
          'svg': {
            color: 'currentColor',
            opacity: 0,
            transition: (theme) => theme.transitions.create('opacity')
          },
          '&:hover svg': {
            opacity: 1
          },
          ...props.sx
        }}
        startIcon={
          !disableLeftSpacing && <Icon sx={{ visibility: 'hidden' }} />
        }
        endIcon={<Icon />}
      />
    </Tooltip>
  );
}
