import React from 'react';
import PulsatingButton, {
  PulsatingButtonProps
} from '../PulsatingButton/PulsatingButton';
import ProgressButton from './ProgressButton';

export type PulsatingProgressButtonProps = PulsatingButtonProps<
  typeof ProgressButton
>;

const PulsatingProgressButton = React.forwardRef(
  (props: PulsatingProgressButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    return <PulsatingButton component={ProgressButton} {...props} ref={ref} />;
  }
);

export default PulsatingProgressButton;
