import {
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
  stepConnectorClasses,
  styled
} from '@mui/material';
import { Check, CheckCircle } from '@mui/icons-material';
import { language } from '@/index';

export interface ProgressionReferrerStepperProps {
  current: number;
  max: number;
}

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
    left: 'calc(-50% + 32px)',
    right: 'calc(50% + 32px)'
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.neutral.main,
    borderTopWidth: 3,
    borderRadius: 1
  }
}));

const CustomStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    'color': theme.palette.neutral.main,
    'display': 'flex',
    'height': 44,
    'alignItems': 'center',
    ...(ownerState.active && {
      color: theme.palette.primary.main
    }),
    '& .CustomStep-completedIcon': {
      color: theme.palette.primary.main,
      zIndex: 1,
      fontSize: 32
    },
    '& .CustomStep-circle': {
      width: 16,
      height: 16,
      borderRadius: '50%',
      backgroundColor: theme.palette.neutral.main
    }
  })
);

function CustomStepIcon(props: StepIconProps) {
  const { active, completed, className, icon } = props;

  return (
    <CustomStepIconRoot ownerState={{ active }} className={className}>
      {typeof icon !== 'string' && typeof icon !== 'number' && icon ? (
        icon
      ) : completed ? (
        <Check className="CustomStep-completedIcon" />
      ) : (
        <div className="CustomStep-circle" />
      )}
    </CustomStepIconRoot>
  );
}

export default function ProgressionReferrerStepper(
  props: ProgressionReferrerStepperProps
) {
  return (
    <Stepper
      alternativeLabel
      activeStep={props.current}
      connector={<CustomConnector />}>
      {Array.from({ length: props.max }, (_, i) => i).map((index) => (
        <Step key={index}>
          <StepLabel
            StepIconComponent={CustomStepIcon}
            icon={
              index === props.max - 1 ? (
                <CheckCircle
                  sx={{
                    color:
                      props.current < props.max
                        ? (theme) => theme.palette.neutral.main
                        : (theme) => theme.palette.primary.main,
                    fontSize: 32
                  }}
                />
              ) : undefined
            }>
            <Typography>
              {Math.round((100 / props.max) * (index + 1))}%{' '}
              {language.text.discount}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
