import { language } from '@/index';
import {
  ChevronLeft,
  Close,
  HelpOutline,
  Pause,
  PlayArrow,
  SpatialAudioOff
} from '@mui/icons-material';
import {
  Box,
  Button,
  Fab,
  Grow,
  IconButton,
  Slide,
  Typography
} from '@mui/material';
import { useScheduler } from 'focus-mode-scheduler';
import React from 'react';
import { GenericVideoModal, GenericVideoPlayer } from 'ui-utils';
import { isYouTubeVideo } from 'ui-utils/src/components/GenericVideoPlayer/VideoHelper';
import {
  YouTubePlayerEle,
  YouTubePlayerState
} from 'ui-utils/src/components/GenericVideoPlayer/YouTubePlayer/YouTubePlayer';

export interface ManagedOnboardingVideoProps {
  video?: Video | null;
  fullscreen?: boolean;
  offset?: Partial<{ x: number; y: number }>;
  onEnd?: () => void;
  onClosed?: () => void;
  pause?: boolean;
}

export default function ManagedOnboardingVideo(
  props: ManagedOnboardingVideoProps
) {
  const { loading } = useScheduler();
  React.useEffect(() => {
    if (props.video) setHideVideo(false);
  }, [props.video]);
  // THIS IS ONLY USED FOR AN ICON
  // not always accurate -> only updates when player is open or closed/minimized
  const [playing, setPlaying] = React.useState(false);
  const [hideVideo, setHideVideo] = React.useState(false);
  const videoRef = React.useRef<YouTubePlayerEle | null>(null);
  const isVideoYT = React.useMemo(
    () => (props.video ? isYouTubeVideo(props.video.videoID) : false),
    [props.video]
  );
  const [modalOpen, setModalOpen] = React.useState(true);
  React.useEffect(() => {
    if (props.video && props.fullscreen) setModalOpen(true);
  }, [props.video]);

  React.useEffect(() => {
    if (!loading) return;
    videoRef.current?.pauseVideo();
    setPlaying(false);
  }, [loading]);

  const lastPausedProp = React.useRef(props.pause);
  React.useEffect(() => {
    if (props.pause && !lastPausedProp.current) {
      videoRef.current?.pauseVideo();
      setPlaying(false);
    } else if (lastPausedProp.current) {
      videoRef.current?.playVideo();
      setPlaying(true);
    }
    lastPausedProp.current = props.pause;
  }, [props.pause]);

  if (!props.video) return null;

  return (
    <>
      {!props.fullscreen ? (
        <>
          <Box
            sx={{
              position: 'fixed',
              left: (theme) =>
                `calc(${theme.spacing(2)} + ${props.offset?.x ?? 0}px)`,
              top: 100 + (props.offset?.y ?? 0),
              zIndex: 10,
              pointerEvents: hideVideo ? 'none' : undefined
            }}>
            <Grow
              in={Boolean(props.video) && !hideVideo}
              style={{
                transformOrigin: '0 50%'
              }}
              // unmount to stop playback
              // youtube video can be paused via ref
              unmountOnExit={!isVideoYT}>
              <Box
                sx={{
                  p: 2,
                  pl: 6,
                  background: (theme) => theme.palette.background.paper,
                  borderRadius: 1,
                  position: 'relative',
                  boxShadow: (theme) => theme.shadows[3]
                }}>
                <IconButton
                  onClick={() => {
                    setHideVideo(true);
                    if (isVideoYT) {
                      videoRef.current?.pauseVideo();
                      setPlaying(false);
                    }
                    props.onClosed?.();
                  }}
                  sx={{
                    top: (theme) => theme.spacing(1),
                    right: (theme) => theme.spacing(1),
                    position: 'absolute'
                  }}>
                  <Close />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setHideVideo(true);
                    if (isVideoYT) {
                      const currentState = videoRef.current?.getPlayerState();
                      setPlaying(currentState === YouTubePlayerState.PLAYING);
                    }
                  }}
                  sx={{
                    left: (theme) => theme.spacing(1),
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)'
                  }}>
                  <ChevronLeft />
                </IconButton>
                <Typography variant="h6" textAlign="center" mb={1}>
                  {props.video.title}
                </Typography>
                <Box
                  sx={{
                    minHeight: 'min(90vh, 300px)',
                    aspectRatio: '16/9'
                  }}>
                  <GenericVideoPlayer
                    videoID={props.video.videoID}
                    ref={videoRef}
                    onEnd={props.onEnd}
                  />
                </Box>
              </Box>
            </Grow>
          </Box>
          <Box
            sx={{
              position: 'fixed',
              left: (theme) =>
                `calc(${theme.spacing(2)} + ${props.offset?.x ?? 0}px)`,
              top: 100 + (props.offset?.y ?? 0),
              zIndex: 10,
              pointerEvents: hideVideo ? undefined : 'none'
            }}>
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                top: 100,
                zIndex: -1
              }}>
              <Slide
                direction="right"
                in={Boolean(props.video) && hideVideo}
                mountOnEnter
                unmountOnExit>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Fab
                    onClick={() => {
                      setHideVideo(false);
                      if (isVideoYT) {
                        videoRef.current?.playVideo();
                        setPlaying(true);
                      }
                    }}
                    color="primary">
                    {playing ? <SpatialAudioOff /> : <HelpOutline />}
                  </Fab>
                  {isVideoYT && (
                    <Fab
                      onClick={() => {
                        if (playing) {
                          videoRef.current?.pauseVideo();
                          setPlaying(false);
                        } else {
                          videoRef.current?.playVideo();
                          setPlaying(true);
                        }
                      }}
                      color="primary">
                      {playing ? <Pause /> : <PlayArrow />}
                    </Fab>
                  )}
                </Box>
              </Slide>
            </Box>
          </Box>
        </>
      ) : (
        <GenericVideoModal
          open={modalOpen}
          onClose={() => {
            props.onClosed?.();
          }}
          title={props.video.title}
          videoID={props.video.videoID}
          ref={videoRef}
          onEnd={props.onEnd}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (isVideoYT) videoRef.current?.pauseVideo();
              setModalOpen(false);
            }}>
            {language.text.continue}
          </Button>
        </GenericVideoModal>
      )}
    </>
  );
}
