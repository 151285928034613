import React from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import useIPC from '../hooks/IPCHook';

/**
 * Handle IPC messages from main process
 * e.g. show snackbar, open url
 */
export default function IPCHandler(props: { children?: React.ReactNode }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useIPC('snackbar:add', (_, options) => enqueueSnackbar(options));

  useIPC('url:open', (_, url) => navigate(url));

  return <>{props.children ?? null}</>;
}
