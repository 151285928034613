import React from 'react';
import { CircularProgress, IconButton, IconButtonProps } from '@mui/material';

export interface PromiseIconButtonProps
  extends Omit<IconButtonProps, 'onClick'> {
  onClick: () => Promise<any> | any;
  loading?: boolean;
}

const PromiseIconButton = React.forwardRef<
  HTMLButtonElement,
  PromiseIconButtonProps
>(function PromiseIconButton(props, ref) {
  const { onClick, loading: _loading, children, ...rest } = props;

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  React.useEffect(() => {
    if (!error) return;
    let timeout = setTimeout(() => setError(false), 5000);
    return () => clearTimeout(timeout);
  }, [error]);

  return (
    <IconButton
      {...rest}
      ref={ref}
      onClick={async () => {
        try {
          setLoading(true);
          await onClick();
        } catch (e) {
          setError(true);
          throw e;
        } finally {
          setLoading(false);
        }
      }}
      disabled={loading || _loading || rest.disabled}>
      {_loading || loading ? <CircularProgress size="1.5rem" /> : children}
    </IconButton>
  );
});

export default PromiseIconButton;
