import { LinkedInRequestService } from '../services/LinkedInRequestService';
import {
  ConversationId,
  DashProfileUrn,
  GetCategoryConversationResponse,
  GetConversationResponse,
  GetConversationsResponse,
  GetKeywordConversationsResponse,
  LinkedInConversationCategory,
  MessageConversationUrn
} from 'linkedin-domain-types';
import { Logger, Options, TaskQueue, trace } from '../client/Utils';
import { CONVERSATION_TIMEOUT, REQUEST_TIMEOUT } from '../client/Config';

@trace()
export class ConversationRequest {
  private requestService: LinkedInRequestService;

  private conversationQueue = new TaskQueue(
    CONVERSATION_TIMEOUT - REQUEST_TIMEOUT,
    'Conversation'
  );

  constructor({ requestService }: { requestService: LinkedInRequestService }) {
    this.requestService = requestService;
  }

  /**
   * Sets the category for conversations
   * @param conversationUrns the conversation urns
   * @param category the category for the conversations
   * @param action whether to add or remove the category
   * @param options
   */
  setCategoryForConversations(
    conversationUrns: MessageConversationUrn[],
    category: LinkedInConversationCategory,
    action: 'addCategory' | 'removeCategory',
    options: Options
  ): Promise<boolean> {
    Logger.debug(
      `${action} ${category} for conversations ${conversationUrns
        .map((urn) => urn.getFullUrn().toUpperCase())
        .map((urn) => `..${urn.substring(urn.length - 4)}`)
        .join(', ')}`
    );
    return this.requestService
      .post(
        `voyagerMessagingDashMessengerConversations?${new URLSearchParams({
          action
        }).toString()}`,
        {
          conversationUrns: conversationUrns.map((urn) => urn.getFullUrn()),
          category
        },
        options,
        undefined,
        undefined,
        action + category + (Date.now() % 1000).toString()
      )
      .then(() => true)
      .catch(() => false);
  }

  /**
   * Gets the conversations for a given conversation id
   * @param conversationId the id of the conversation
   * @param options the options to use for the request
   */
  getConversation(conversationId: ConversationId, options: Options) {
    Logger.debug(`Getting conversation ${conversationId}`);
    return this.requestService.get<GetConversationResponse>(
      `messaging/conversations/${conversationId}`,
      options,
      {
        params: {
          keyVersion: 'LEGACY_INBOX'
        }
      },
      undefined,
      `GetConv${conversationId.substring(0, 8).toUpperCase()}${(
        Date.now() % 1000
      ).toString()}`
    );
  }

  /**
   * Gets all conversations before a given date
   * @param recipients the recipients to filter by (optional) (broken?)
   * @param createdBefore the date to filter by
   * @param options the options to use for the request
   */
  getConversations({
    recipients,
    createdBefore = new Date(),
    options
  }: {
    recipients?: string | string[];
    createdBefore: Date;
    options: Options;
  }): Promise<GetConversationsResponse | null> {
    Logger.debug(`Getting conversations before ${createdBefore.toISOString()}`);
    const { requestService } = this;
    return this.conversationQueue.add(
      `getConversations${createdBefore.getDate()}`,
      function () {
        return requestService.get<GetConversationsResponse>(
          'messaging/conversations',
          options,
          {
            params: {
              keyVersion: 'LEGACY_INBOX',
              ...(recipients && {
                q: 'participants',
                recipients: [...recipients]
              }),
              ...(createdBefore && { createdBefore: createdBefore.getTime() })
            }
          },
          undefined,
          this.identifier
        );
      },
      {
        overrideTimeout: CONVERSATION_TIMEOUT,
        addFront: options.prioritize
      }
    );
  }

  /**
   * Gets all conversations with a given keyword
   * @param mailboxUrn the mailbox urn
   * @param keyword the keyword to search for
   * @param options the options to use for the request
   * @param categories the categories to search in
   * @param firstDegreeConnections whether to only search first degree connections (NYI)
   * @param nextCursor the next cursor
   * @param count the number of conversations to get
   */
  getConversationsWithKeyword({
    mailboxUrn,
    keyword,
    options,
    categories = ['INBOX', 'SPAM', 'ARCHIVE'],
    firstDegreeConnections = false,
    nextCursor,
    count = 20
  }: {
    mailboxUrn: DashProfileUrn;
    categories?: LinkedInConversationCategory[];
    keyword: string;
    options: Options;
    firstDegreeConnections?: boolean;
    nextCursor?: string;
    count?: number;
  }): Promise<GetKeywordConversationsResponse | null> {
    Logger.debug(
      `Getting conversations with keyword ${keyword} in categories ${categories.join(
        ', '
      )}${nextCursor ? ` with next cursor ${nextCursor}` : ''}`
    );
    const { requestService } = this;
    return this.conversationQueue.add(
      `getConvKeyword${keyword.substring(0, 8)}`,
      function () {
        return requestService.get<GetKeywordConversationsResponse>(
          `voyagerMessagingGraphQL/graphql` +
            `?queryId=messengerConversations.18b3c38b9fbc0e961d0aa525f76a9c2c` +
            `&variables=(categories:List(${categories.join(',')})` +
            `,count:${count},mailboxUrn:${encodeURIComponent(
              mailboxUrn.getFullUrn()
            )}${
              nextCursor ? `,nextCursor:${nextCursor}` : ''
            },keywords:${encodeURIComponent(keyword)})`,
          options,
          {},
          CONVERSATION_TIMEOUT,
          this.identifier
        );
      },
      {
        overrideTimeout: 5000,
        addFront: options.prioritize
      }
    );
  }

  /**
   * Gets all conversations with a given category
   * @param mailboxUrn the mailbox urn
   * @param category the category to search for
   * @param count the number of conversations to get
   * @param options the options to use for the request
   * @param nextCursor the next cursor
   */
  getConversationsWithCategory({
    mailboxUrn,
    category,
    count = 20,
    options,
    nextCursor = ''
  }: {
    mailboxUrn: DashProfileUrn;
    category: LinkedInConversationCategory;
    count?: number;
    options: Options;
    nextCursor?: string;
  }): Promise<GetCategoryConversationResponse | null> {
    Logger.debug(
      `Getting conversations with category ${category}${
        nextCursor ? ` with next cursor ${nextCursor}` : ''
      }`
    );
    const { requestService } = this;
    return this.conversationQueue.add(
      `getConvCategory${category}${nextCursor}`,
      function () {
        return requestService.get<GetCategoryConversationResponse>(
          `voyagerMessagingGraphQL/graphql` +
            `?queryId=messengerConversations.d09ae0cfca0e05b26fa8853cc98f16f4` +
            `&variables=(category:${category}` +
            `,count:${count},mailboxUrn:${encodeURIComponent(
              mailboxUrn.getFullUrn()
            )}${nextCursor ? `,nextCursor:${nextCursor}` : ''})`,
          options,
          {},
          CONVERSATION_TIMEOUT,
          this.identifier
        );
      },
      {
        overrideTimeout: 5000,
        addFront: options.prioritize
      }
    );
  }

  /**
   * Marks a conversation as read
   * @param conversationId the id of the conversation
   * @param state whether to mark as read or unread
   * @param options the options to use for the request
   */
  markConversationAsRead(
    conversationId: ConversationId,
    state = true,
    options: Options
  ): Promise<unknown> {
    Logger.debug(
      `Marking conversation ${conversationId} as ${state ? 'read' : 'unread'}`
    );
    return this.requestService.post<unknown>(
      `messaging/conversations/${conversationId}`,
      {
        patch: {
          $set: {
            read: state
          }
        }
      },
      options,
      undefined,
      undefined,
      `${state ? '' : 'un'}readConv${conversationId.substring(0, 8)}|${
        Date.now() % 1000
      }`
    );
  }
}
