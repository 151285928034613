import React from 'react';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

type MouseEvent = Parameters<Required<LoadingButtonProps>['onClick']>[0];
export interface PromiseButtonProps extends LoadingButtonProps {
  onClick?: (event: MouseEvent) => Promise<void> | void;
}

const PromiseButton = React.forwardRef<HTMLButtonElement, PromiseButtonProps>(
  (props, ref) => {
    const { onClick, color, ...rest } = props;

    const [loading, setLoading] = React.useState(false);
    const [loadingError, setLoadingError] = React.useState(false);

    const mounted = React.useRef(false);

    React.useEffect(() => {
      mounted.current = true;

      return () => {
        mounted.current = false;
      };
    });

    const promiseAction = async (e: MouseEvent) => {
      if (mounted.current) {
        // Initiate loading
        setLoading(true);
        setLoadingError(false);
      }

      // Perform action
      try {
        await onClick?.(e);
      } catch (e) {
        console.error('Error in PromiseButton:', e);
        if (mounted.current) setLoadingError(true);
        // forward error
        throw e;
      } finally {
        // Complete loading
        if (mounted.current) setLoading(false);
      }
    };

    return (
      <LoadingButton
        {...rest}
        ref={ref}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- we handle the promise with the loading state
        onClick={promiseAction}
        color={loadingError ? 'error' : color}
        loading={loading || rest.loading}
      />
    );
  }
);

export default PromiseButton;
