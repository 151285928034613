import { ConversationUrn, EventUrn, MiniProfileUrn } from './Urns';
import {
  LinkedInEvent,
  LinkedInMessagingMember,
  LinkedInParticipantReceipts,
  MESSAGE_EVENT_TYPE,
  MESSAGING_MEMBER_TYPE,
  ReactionSummary
} from './Messaging';
import { LinkedInMiniProfile } from './Identity';
import { LinkedInVectorImage } from './Common';
import { ActionResponse, ReferenceActionResponse } from './RestliCommon';

export enum EventTopic {
  conversations = 'conversationsTopic',
  messageSeenReceipts = 'messageSeenReceiptsTopic',
  messages = 'messagesTopic',
  replySuggestion = 'replySuggestionTopicV2',
  tabBadgeUpdate = 'tabBadgeUpdateTopic',
  typingIndicators = 'typingIndicatorsTopic',
  invitations = 'invitationsTopic',
  inAppAlerts = 'inAppAlertsTopic',
  messageReactionSummaries = 'messageReactionSummariesTopic',
  socialPermissionsPersonal = 'socialPermissionsPersonalTopic',
  jobPostingPersonal = 'jobPostingPersonalTopic',
  messagingProgressIndicator = 'messagingProgressIndicatorTopic',
  messagingDataSync = 'messagingDataSyncTopic',
  conversationDeletes = 'conversationDeletesTopic',
  messagingSecondaryPreviewBanner = 'messagingSecondaryPreviewBannerTopic',
  eventToasts = 'eventToastsTopic',
  realtimeSearchResultClusters = 'realtimeSearchResultClustersTopic',
  generatedJobDescriptions = 'generatedJobDescriptionsTopic',
  premiumGeneratedContents = 'premiumGeneratedContentsTopic',
  coachStreamingResponses = 'coachStreamingResponsesTopic'
}

export type EventTopicUrn =
  `urn:li-realtime:${EventTopic}:urn:li-realtime:myself`;

interface RealtimeData {
  leftServerAt: number;
  id: string;
}

export interface ClientConnectionData extends RealtimeData {
  personalTopics: EventTopic[];
  serverProcessingTime: number;
}

export interface HeartbeatData {}

export interface DecoratedEventData<
  U extends EventTopicUrn,
  T = unknown,
  I = unknown
> extends RealtimeData {
  topic: U;
  payload: {
    data: T;
    included: I[];
  };
  trackingId: string;
}

export type TabBadgeUpdateTopicData = DecoratedEventData<
  'urn:li-realtime:messagesTopic:urn:li-realtime:myself',
  ActionResponse<RealtimeTabBadgesEvent>
>;

export const REALTIME_TAB_BADGES_EVENT_TYPE =
  'com.linkedin.voyager.growth.communications.RealtimeTabBadgesEvent';

export interface RealtimeTabBadgesEvent {
  $type: typeof REALTIME_TAB_BADGES_EVENT_TYPE;
  tabBadges: TabBadge[];
}

export type Tab = 'NOTIFICATIONS' | 'MESSAGING' | 'MY_NETWORK';
export const TAB_BADGE_TYPE =
  'com.linkedin.voyager.common.communications.TabBadge' as const;
export interface TabBadge {
  $type: typeof TAB_BADGE_TYPE;
  tab: Tab;
  count: number;
}

export type MessagesTopicData = DecoratedEventData<
  'urn:li-realtime:messagesTopic:urn:li-realtime:myself',
  ActionResponse<RealtimeMessageEvent>,
  LinkedInEvent | LinkedInMiniProfile | LinkedInMessagingMember
>;

export const REALTIME_MESSAGE_EVENT_TYPE =
  'com.linkedin.voyager.messaging.realtime.RealtimeEvent';

export interface RealtimeMessageEvent {
  '$type': typeof REALTIME_MESSAGE_EVENT_TYPE;
  'inboxType': 'PRIMARY';
  'unreadConversationsCount': number;
  'conversationUnreadCount': number;
  'conversationLastActivityAt': number;
  'previousEventInConversationUrn': EventUrn;
  '*event': EventUrn;
  'event'?: LinkedInEvent;
  'participantReceipts': LinkedInParticipantReceipts[];
}

export interface RealtimeLinkedInEvent
  extends Omit<LinkedInEvent, 'from' | 'eventContent'> {
  eventContent: {
    [MESSAGE_EVENT_TYPE]: RealtimeMessageEvent;
  };
  from: {
    [MESSAGING_MEMBER_TYPE]: RealtimeMessagingMember;
  };
}

export interface RealtimeMessagingMember
  extends Omit<LinkedInMessagingMember, 'miniProfile'> {
  miniProfile: {
    'com.linkedin.voyager.identity.shared.MiniProfile': RealtimeMiniProfile;
  };
}

export interface RealtimeMiniProfile
  extends Omit<LinkedInMiniProfile, 'backgroundImage' | 'picture'> {
  backgroundImage: {
    'com.linkedin.common.VectorImage': LinkedInVectorImage;
  };
  picture: {
    'com.linkedin.common.VectorImage': LinkedInVectorImage;
  };
}

export type MessageSeenReceiptsTopicData = DecoratedEventData<
  'urn:li-realtime:messageSeenReceiptsTopic:urn:li-realtime:myself',
  ActionResponse<RealtimeMessageSeenReceipt>
>;

export const REALTIME_SEEN_RECEIPT_TYPE =
  'com.linkedin.voyager.messaging.realtime.RealtimeSeenReceipt';

export interface RealtimeMessageSeenReceipt
  extends Omit<LinkedInParticipantReceipts, '$type'> {
  $type: typeof REALTIME_SEEN_RECEIPT_TYPE;
}

export type TypingIndicatorsTopicData = DecoratedEventData<
  'urn:li-realtime:typingIndicatorsTopic:urn:li-realtime:myself',
  ActionResponse<RealtimeTypingIndicator>
>;

export const REALTIME_TYPING_INDICATOR_TYPE =
  'com.linkedin.voyager.messaging.realtime.RealtimeTypingIndicator';

export interface RealtimeTypingIndicator {
  $type: typeof REALTIME_TYPING_INDICATOR_TYPE;
  fromEntity: MiniProfileUrn;
  conversation: ConversationUrn;
}

export const REALTIME_CONVERSATION_TYPE =
  'com.linkedin.voyager.messaging.realtime.RealtimeConversation';

export interface RealtimeConversation {
  $type: typeof REALTIME_CONVERSATION_TYPE;
  entityUrn: ConversationUrn;
  action: 'CREATE' | 'UPDATE' | 'DELETE';
  conversationBundle: unknown;
  legacyInboxEntityUrn: unknown;
  mtInboxEntityUrn: unknown;
  mtOlympusEntityUrn: unknown;
  starred: boolean;
  unreadConversationCount: number;
}

export type MessageReactionSummariesTopicData = DecoratedEventData<
  'urn:li-realtime:messageReactionSummariesTopic:urn:li-realtime:myself',
  ActionResponse<RealtimeMessageReactionSummary>
>;

export const REALTIME_MESSAGE_REACTION_SUMMARY_TYPE =
  'com.linkedin.voyager.messaging.realtime.RealtimeMessageReactionSummary';

export interface RealtimeMessageReactionSummary {
  $type: typeof REALTIME_MESSAGE_REACTION_SUMMARY_TYPE;
  actorMiniProfileUrn: MiniProfileUrn;
  eventUrn: EventUrn;
  reactionAdded: boolean;
  reactionSummary: ReactionSummary;
}

export type ConversationTopicData = DecoratedEventData<
  'urn:li-realtime:conversationsTopic:urn:li-realtime:myself',
  ReferenceActionResponse<ConversationUrn>,
  RealtimeConversation | LinkedInMessagingMember | LinkedInMiniProfile
>;
