import React from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import { Money } from '@idot-digital/generic-helpers';
import { language } from '../../Config';

export interface MoneyCircleProps {
  // money in cents
  value: number;
  total?: number;
  // filled percentage from 0-1
  filled?: number;
  title?: React.ReactNode;
  sx?: SxProps;
  format?: (value: number, currency?: string, punctuation?: string) => string;
  currency?: string;
}

export default function MoneyCircle(props: MoneyCircleProps) {
  return (
    <Box
      sx={{
        aspectRatio: '1',
        p: 2,
        boxSizing: 'border-box',
        border: (theme) =>
          `${theme.spacing(0.5)} solid ${theme.palette.primary.main}`,
        borderRadius: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
        ...((props.filled !== undefined || props.total) && {
          '&::before': {
            content: '""',
            width: '100%',
            height: '100%',
            background: (theme) => theme.palette.primary.main,
            position: 'absolute',
            left: 0,
            top: `${(1 - (props.filled ?? props.value / props.total!)) * 100}%`,
            opacity: 0.2
          }
        })
      }}>
      <Typography variant="h4" textAlign="center" zIndex={1}>
        {(props.format ?? Money.stringifyCents)(
          props.value,
          props.currency,
          language.text.number_punctuation
        )}
      </Typography>
      {props.title && (
        <Typography
          variant="h6"
          sx={{
            color: (theme) => theme.palette.action.disabled,
            textAlign: 'center',
            fontWeight: 'normal',
            zIndex: 1
          }}>
          {props.title}
        </Typography>
      )}
    </Box>
  );
}
