import React from 'react';
import { PROGRESSION_ROUTES } from './Progression';
import SettingsSubheader from '../SettingsSubheader';
import { APP_PATHS, MANUAL_MODE_PATHS, SETTINGS_PATHS } from '@/globals/Paths';
import AffiliateActions from '@/data/DataServer/Affiliate';

export default function ProgressionHeader() {
  const { userProgression, status } = AffiliateActions.useUserProgression();

  const routes = React.useMemo(
    () =>
      PROGRESSION_ROUTES.map((route) => ({
        ...route,
        path: `/${APP_PATHS.Manual}/${MANUAL_MODE_PATHS.Settings}/${SETTINGS_PATHS.Progression}/${route.path}`,
        disabled:
          !userProgression ||
          !AffiliateActions.isUnlocked(userProgression, route.status)
      })),
    [userProgression]
  );

  return <SettingsSubheader loading={status === 'loading'} routes={routes} />;
}
