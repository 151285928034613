import { Box, Button, Divider, Typography } from '@mui/material';
import ChatTextField from '@/components/Chat/ChatTextField';
import { CheckIcon, Loading } from 'ui-utils';
import { Message, MessageTemplate } from '@common/types/ApiTypes';
import { language } from '@/index';
import { PipelineStep } from '@common/PipelineManager/PipelineTypes';
import { Contact } from '@/data/Classes/Contact';
import ChatHistoryStatic from '@/components/Chat/ChatHistoryStatic';
import ContactAvatar from '@/components/Avatar/ContactAvatar';

export interface StaticContactChatProps {
  profile: Pick<Contact, 'firstname' | 'lastname' | 'profileID'> &
    Partial<Pick<Contact, 'priority'>> & {
      pictures?: unknown;
    };
  messages: Message[];
  unreadCount: number;
  loading?: boolean;
  hide?: boolean;
  openRelation?: () => void;
  currentStep?: PipelineStep | null;
  templates?: MessageTemplate[];
  showHeader?: boolean;
}

export default function StaticContactChat(props: StaticContactChatProps) {
  return (
    <Box
      sx={{
        // hide, not unmount when not showing
        display: props.hide ? 'none' : 'flex',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        flexDirection: 'column',
        pt: 0,
        mr: 2,
        borderRadius: 1,
        background: 'background.default'
      }}>
      {props.openRelation && (
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={() => props.openRelation?.()}
          sx={{
            mx: 2,
            mb: 2,
            // since margin on each side is 2 and we want the button to be full width
            width: (theme) => `calc(100% - ${theme.spacing(4)})`
          }}
          startIcon={
            <CheckIcon
              sx={{
                width: '28px',
                height: '28px'
              }}
            />
          }>
          {props.currentStep
            ? props.currentStep.name
            : language.text.select_relation_step}
        </Button>
      )}
      {props.showHeader && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              px: 2,
              mb: 2
            }}>
            <ContactAvatar
              contact={{
                firstname: props.profile.firstname,
                lastname: props.profile.lastname,
                name: props.profile.firstname + ' ' + props.profile.lastname,
                pictures: props.profile.pictures || {},
                priority: props.profile.priority
              }}
            />
            {props.profile.firstname + ' ' + props.profile.lastname}
          </Box>
          <Divider light orientation="horizontal" sx={{ mx: 2 }} />
        </>
      )}
      <Box
        sx={{
          flex: '1 0 0',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Box
          sx={{
            flex: '1 0 0',
            width: '100%',
            position: 'relative'
          }}>
          <Box position="absolute" top="0" left="0" width="100%" height="100%">
            {props.loading ? (
              <Loading />
            ) : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- to display error
            props.messages ? (
              <ChatHistoryStatic
                unreadCount={props.unreadCount}
                participants={[
                  {
                    firstName: props.profile.firstname,
                    lastName: props.profile.lastname,
                    profileID: props.profile.profileID,
                    profilePictureUrl:
                      (props.profile.pictures as
                        | { [resolution: string]: string }
                        | undefined) ?? {}
                  }
                ]}
                hasNextPage={false}
                messages={props.messages}
              />
            ) : (
              <Typography color="error">
                {language.text.error_while_opening_chat}
              </Typography>
            )}
          </Box>
        </Box>
        <Box flex="0 0 auto" width="100%">
          <ChatTextField
            autofocus
            disabled={true}
            disabledMessage={language.text.cant_write_in_this_stage}
            onSend={() => Promise.resolve(false)}
          />
        </Box>
      </Box>
    </Box>
  );
}
