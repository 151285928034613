import { language } from '@/index';
import { Box, Typography } from '@mui/material';
import { AvaIcon, Background, FancyButton, Fireworks } from 'ui-utils';

export default function CelebrateUpdate() {
  return (
    <Background
      variant="frame"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 2,
        height: '100vh',
        p: 4,
        boxSizing: 'border-box'
      }}>
      <Fireworks
        sx={{
          position: 'absolute',
          pointerEvents: 'none',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          height: '100%',
          width: '100%',
          overflow: 'hidden'
        }}
      />
      <Box>
        <AvaIcon width={200} style={{ marginLeft: 10, marginRight: 10 }} />
      </Box>
      <Typography variant="h1" textAlign="center">
        {language.text.full_version_released}
      </Typography>
      <Typography variant="body1" textAlign="center">
        {language.text.full_version_released_description}
      </Typography>
      <FancyButton
        fType={{
          promise: true
        }}
        size="large"
        variant="contained"
        color="primary"
        onClick={() =>
          window.api.send(
            'url:open_ext',
            'https://social-selling-cockpit.com/#download'
          )
        }>
        {language.text.download_new_version}
      </FancyButton>
    </Background>
  );
}
