import LocalKeyValue from '@common/LocalKeyValue/LocalKeyValue.renderer';
import MainConfig from '@common/config/MainConfig';

const db = new LocalKeyValue<string, undefined>(
  MainConfig.localCredentialsFilePath,
  undefined
);
//@ts-ignore
window.db = db;

export const dbLoaded = db.load().then(() => db!);

export default db;
