import avaFull from './imgs/ava.png';
import avaSquare from './imgs/ava-square.png';

export interface AvaIconProps extends React.HTMLAttributes<HTMLImageElement> {
  variant?: 'square' | 'full';
  width?: number | string;
}

export default function AvaIcon(props: AvaIconProps) {
  const { variant = 'full', width, ...rest } = props;
  const ava = variant === 'square' ? avaSquare : avaFull;
  return (
    <img
      {...rest}
      alt="Ava"
      style={{
        width: width ?? 200,
        height: '100%',
        objectFit: 'contain',
        ...rest.style
      }}
      src={ava}
    />
  );
}

AvaIcon.src = { square: avaSquare, full: avaFull };
