import { Button, ButtonProps, SxProps, Theme } from '@mui/material';
import React from 'react';

export type PulsatingButtonProps<
  Ele extends React.FunctionComponent<
    Pick<ButtonProps, 'sx'> & {
      onClick?: (...args: any[]) => any;
      disabled?: boolean;
    }
  > = typeof Button
> = React.ComponentProps<Ele> & {
  component?: Ele;
  pulsate?: (pulsate: () => void) => void;
  animations?: {
    activate?: SxProps<Theme>;
    click?: SxProps<Theme>;
    disableElevation?: boolean;
    disableOnActive?: boolean;
    color?: string | ((theme: Theme) => string);
  };
};

// differenciate between click state, that changes scale, callback pulsate, that only pulsates and does not change scale and idle state
type PulsateState = 'clicked' | 'idle' | 'pulsating';

const PulsatingButton = React.forwardRef(
  <
    Ele extends React.FunctionComponent<
      Pick<ButtonProps, 'sx'> & {
        onClick?: (...args: any[]) => any;
        disabled?: boolean;
      }
    > = typeof Button
  >(
    props: PulsatingButtonProps<Ele>,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const {
      pulsate: pulsateCallback,
      component,
      animations,
      pulsate: propsPulsate,
      ...rest
    } = props;
    const [clicked, setClicked] = React.useState<PulsateState>('idle');
    const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

    const Component = props.component ?? Button;

    const pulsate = React.useCallback((click = false) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setClicked(click ? 'clicked' : 'pulsating');
      timeoutRef.current = setTimeout(() => {
        setClicked('idle');
      }, 700);
    }, []);

    React.useEffect(() => {
      pulsateCallback?.(() => pulsate());
    }, [pulsate]);

    /**
     * Prevent pulsate when button is default not disabled
     */
    const lastDisabled = React.useRef<boolean | undefined | null>(null);
    React.useEffect(() => {
      if (
        lastDisabled.current &&
        !props.disabled &&
        !props.animations?.disableOnActive
      )
        pulsate();

      lastDisabled.current = props.disabled;
    }, [props.disabled]);

    return (
      <Component
        {...rest}
        ref={ref}
        onClick={(...args) => {
          pulsate();

          if ('onClick' in props && typeof props.onClick === 'function')
            return props.onClick(...args);
        }}
        sx={{
          'transition': (theme) =>
            theme.transitions.create(['transform', 'box-shadow']),
          '&:hover': {
            transform: props.animations?.disableElevation
              ? null
              : (theme) => `translateY(${theme.spacing(-1)})`,
            boxShadow: (theme) => theme.shadows[4]
          },
          ...(clicked === 'clicked'
            ? (props.animations?.click ?? {
                'animation': 'pulse .7s',
                '@keyframes pulse': {
                  '0%': {
                    transform: 'scale(1)',
                    boxShadow: (theme) =>
                      `0 0 0 0 ${
                        props.animations?.color
                          ? typeof props.animations.color === 'string'
                            ? props.animations.color
                            : props.animations.color(theme)
                          : theme.palette.primary.main
                      }`
                  },
                  '30%': {
                    transform: 'scale(.9)'
                  },
                  '70%': {
                    boxShadow: (theme) =>
                      `0 0 0 ${theme.spacing(1.5)} rgba(0, 0, 0, 0)`
                  },
                  '100%': {
                    transform: 'scale(1)',
                    boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)'
                  }
                }
              })
            : clicked === 'pulsating'
              ? (props.animations?.activate ?? {
                  'animation': 'pulse .7s',
                  '@keyframes pulse': {
                    '0%': {
                      boxShadow: (theme) =>
                        `0 0 0 0 ${
                          props.animations?.color
                            ? typeof props.animations.color === 'string'
                              ? props.animations.color
                              : props.animations.color(theme)
                            : theme.palette.primary.main
                        }`
                    },
                    '70%': {
                      boxShadow: (theme) =>
                        `0 0 0 ${theme.spacing(1.5)} rgba(0, 0, 0, 0)`
                    },
                    '100%': {
                      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)'
                    }
                  }
                })
              : {}),
          ...props.sx
        }}
      />
    );
  }
);

export default PulsatingButton;
