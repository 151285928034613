import { Button, Popover } from '@mui/material';
import React from 'react';
import { FilterIcon as FilterIconElement } from 'ui-utils';

export interface FilterIconProps {
  children?: React.ReactNode | React.ReactNode[];
  active?: boolean;
  icon?: React.ReactNode;
  closeOnSelect?: boolean;
  onOpen?: () => void;
  disabled?: boolean;
}

export default function FilterIcon(props: FilterIconProps) {
  const [open, setOpen] = React.useState(false);
  const anchorEl = React.useRef<HTMLButtonElement | null>(null);

  React.useEffect(() => {
    if (props.onOpen && open) props.onOpen();
  }, [open]);

  React.useEffect(() => {
    if (open && props.disabled) setOpen(false);
  }, [props.disabled]);

  return (
    <>
      <Button
        disabled={props.disabled}
        ref={anchorEl}
        onClick={() => setOpen(true)}
        variant="contained"
        color={props.active ? 'primary' : 'neutral'}
        sx={{ p: 0.75, minWidth: 'unset' }}>
        {props.icon ?? <FilterIconElement />}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={() => setOpen(false)}
        onClick={props.closeOnSelect ? () => setOpen(false) : undefined}>
        {props.children}
      </Popover>
    </>
  );
}
