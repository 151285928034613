import { Cached } from '@mui/icons-material';
import SettingsGenericInfo from '../SettingsGenericInfo';
import { language } from '@/index';

export function ProgressionNewUser() {
  return (
    <SettingsGenericInfo
      header={language.text.come_back_tomorrow}
      icon={Cached}>
      {language.text.you_the_ssc_further_to_unlock_training}
    </SettingsGenericInfo>
  );
}
