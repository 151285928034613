import { useIdleTimer } from 'react-idle-timer';
import tracking from 'tracking';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { APP_PATHS } from '@/globals/Paths';
import posthog from 'posthog-js';

const useTrackUserActivity = () => {
  // only track on main pages (not debugger and not login)
  const { pathname } = useLocation();
  const shouldTrack = React.useMemo(
    () =>
      !pathname.startsWith(`/${APP_PATHS.Debugger}`) &&
      !pathname.startsWith(`/${APP_PATHS.Login}`),
    [pathname]
  );

  // pause capture when user is idle
  useIdleTimer({
    // detect idle time after 1 minute
    timeout: 60 * 1000,
    onPresenceChange: (presence) => {
      if (!shouldTrack) return;
      posthog.capture(`presense-change:${presence.type}`);
      tracking.captureUserStateChange(presence.type);
    }
  });

  // start/end capture on window open/close
  React.useEffect(() => {
    if (shouldTrack) tracking.captureUserStateChange('active');
    return () => {
      tracking.captureUserStateChange('idle');
    };
  }, [shouldTrack]);
};

export default useTrackUserActivity;
