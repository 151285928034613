import React from 'react';
import { useSetup } from '../../SetupContext';
import { Loading } from 'ui-utils';

export default function SetupLoading() {
  const { loadingComplete, currentStep } = useSetup();
  React.useEffect(() => {
    if (currentStep && currentStep.handler === 'loading') loadingComplete();
  }, [currentStep]);
  return <Loading />;
}
