import Auth from '@common/AuthManager/Auth.renderer';
import MainConfig from '@common/config/MainConfig';
import apiRetry from '@common/FetchRetry/FetchRetry.renderer';
import { statistics } from '@digital-sun-solutions/cloud-functions';
import { EventEmitter } from '@idot-digital/generic-helpers';
import tracking from 'tracking';
import { UsageCategory, UserUsageClass } from './UsageTrendsType';
import queryClient from '@/other/QueryClient';
import { createQueryHook } from '@/data/DataServer/QueryHelper';
import { language } from '@/index';

export interface UsageTrendsMonitorEvents {
  usageWarning: {
    category: UsageCategory;
    count: number;
    firstWarning: boolean;
  };
}

class UsageTrendsMonitorClass extends EventEmitter<UsageTrendsMonitorEvents> {
  constructor() {
    super();
    this.getCurrentStats();

    tracking.on('eventCaptured', ({ event, count }) => {
      const key = ((): UsageCategory | null => {
        switch (event) {
          case 'ProfileVisit':
            return 'profile_visits';
          case 'ConnectionRequestSend':
            return 'connect';
          case 'MessageSend':
            return 'message';
          default:
            return null;
        }
      })();

      if (!key) return;

      const current = this.sessionState.get(key) ?? 0;
      this.sessionState.set(key, current + count);

      this.checkForTrendWarning(key);
    });
  }

  private LOCAL_STORAGE_KEY = 'usage_trends_monitor_class';
  private currentClass: UserUsageClass | null = null;
  async getUserClass(): Promise<UserUsageClass> {
    if (this.currentClass) return Promise.resolve(this.currentClass);
    const stored = localStorage.getItem(
      this.LOCAL_STORAGE_KEY
    ) as UserUsageClass | null;
    if (!stored) return Promise.resolve(UserUsageClass.medium);
    this.currentClass = stored;
    return Promise.resolve(stored);
  }

  async setUserClass(userClass: UserUsageClass) {
    localStorage.setItem(this.LOCAL_STORAGE_KEY, userClass);
    this.currentClass = userClass;
    queryClient.invalidateQueries(this.userClassQueryKey);
    return Promise.resolve();
  }

  userClassQueryKey = ['user_usage_class'];
  useUserClass = createQueryHook(() => this.getUserClass(), 'userUsageClass', {
    staticQueryKey: () => this.userClassQueryKey
  });

  /**
   * State at the beginning of the session
   */
  private initialState = new Map<UsageCategory, number>();
  /**
   * The change in the current session \
   * Saved to not fetch state again from server after event capture
   */
  private sessionState = new Map<UsageCategory, number>();

  /**
   * Fetches the current stats from the server and resets the session state
   */
  private async getCurrentStats() {
    const initial = await apiRetry(() =>
      Auth.execRoute((token) => statistics.today({}, { token }))
    );

    this.initialState.set('profile_visits', initial.ProfileVisit?.current ?? 0);
    this.initialState.set(
      'connect',
      initial.ConnectionRequestSend?.current ?? 0
    );
    this.initialState.set('message', initial.MessageSend?.current ?? 0);

    // reset session state
    Array.from(this.sessionState.keys()).forEach((key) =>
      this.sessionState.set(key, 0)
    );
  }

  /**
   * Track if the first warning was already sent
   */
  private firstWarningMap = new Map<UsageCategory, boolean>();

  private async checkForTrendWarning(category?: UsageCategory) {
    const categories = category
      ? [category]
      : (['profile_visits', 'connect', 'message'] satisfies UsageCategory[]);

    const userLimits = await this.getUserLimits();

    for (const category of categories) {
      const current =
        (this.sessionState.get(category) ?? 0) +
        (this.initialState.get(category) ?? 0);
      const limit = userLimits[category];

      if (current < limit) continue;

      const firstWarning = this.firstWarningMap.get(category) ?? true;
      this.firstWarningMap.set(category, false);

      this.internalEmit('usageWarning', {
        category,
        count: current,
        firstWarning
      });
    }
  }

  private async getUserLimits(): Promise<{
    [key in UsageCategory]: number;
  }> {
    const currentClass = await this.getUserClass();
    if (currentClass === UserUsageClass.unlimited)
      return {
        connect: Infinity,
        message: Infinity,
        profile_visits: Infinity
      };
    return MainConfig.usageTypeLimits[currentClass];
  }

  public listAllUserUsageClasses() {
    return [
      {
        id: UserUsageClass.low,
        name: language.text.usage_low,
        description: language.text.usage_low_description
      },
      {
        id: UserUsageClass.medium,
        name: language.text.usage_medium,
        description: language.text.usage_medium_description
      },
      {
        id: UserUsageClass.high,
        name: language.text.usage_high,
        description: language.text.usage_high_description
      },
      {
        id: UserUsageClass.fulltime,
        name: language.text.usage_fulltime,
        description: language.text.usage_fulltime_description
      },
      {
        id: UserUsageClass.unlimited,
        name: language.text.usage_unlimited,
        description: language.text.usage_unlimited_description
      }
    ] as const;
  }
}

const UsageTrendsMonitor = new UsageTrendsMonitorClass();
export default UsageTrendsMonitor;
