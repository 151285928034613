import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  IconButtonProps,
  Typography
} from '@mui/material';
import MicIcon from '../../icons/MicIcon';
import { Delete, KeyboardVoice, Pause } from '@mui/icons-material';
import { language } from '../../Config';

export interface VoiceMessageProps extends IconButtonProps {
  onConfirm: (blob: Blob) => void;
}

export default function VoiceMessage(props: VoiceMessageProps) {
  const { onConfirm, ...buttonProps } = props;

  const [ready, setReady] = React.useState(false);

  const [open, setOpen] = React.useState(false);

  const recording = React.useRef<MediaRecorder | null>(null);
  const [recordingState, setRecordingState] = React.useState<
    'idle' | 'recording' | 'paused'
  >('idle');

  const [recordingChunks, setRecordingChunks] = React.useState<Blob[]>([]);

  const [recordingError, setRecordingError] = React.useState<string | null>(
    null
  );

  const mounted = React.useRef(true);
  React.useEffect(() => {
    (async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true
        });

        if (!mounted.current) {
          return;
        }
        recording.current = new MediaRecorder(stream);
        recording.current.ondataavailable = (e) => {
          if (mounted.current)
            setRecordingChunks((chunks) => [...chunks, e.data]);
        };
        setReady(true);
      } catch (e) {
        console.error(e);
        setRecordingError(language.text.recording_error_mic_settings);
      }
    })();

    return () => {
      mounted.current = false;
    };
  }, []);

  const startRecording = () => {
    if (!recording.current) return;
    recording.current.start();
    setRecordingState('recording');
  };

  const pauseRecording = () => {
    if (recordingState !== 'recording') return;

    recording.current?.stop();
    setRecordingState('paused');
  };

  const resetRecording = () => {
    try {
      // throws error is recording is already stopped
      recording.current?.stop();
    } catch (e) {
      // do nothing
    }
    setRecordingState('idle');
    setRecordingChunks([]);
    setRecordingError(null);
  };

  const confirmRecording = () => {
    const blob = new Blob(recordingChunks, { type: 'audio/webm' });
    props.onConfirm(blob);
  };

  const playState = (() => {
    if (recordingState === 'recording')
      return {
        icon: <Pause />,
        function: pauseRecording
      };
    return {
      icon: <KeyboardVoice />,
      function: startRecording
    };
  })();

  return (
    <>
      <IconButton
        {...buttonProps}
        onClick={(e) => {
          setOpen(true);
          buttonProps.onClick?.(e);
        }}>
        <MicIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{language.text.voice_recording}</DialogTitle>
        <DialogContent>
          {recordingError ? (
            <Typography color="error">{recordingError}</Typography>
          ) : ready ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                <IconButton onClick={playState.function}>
                  {playState.icon}
                </IconButton>
                <IconButton onClick={resetRecording}>
                  <Delete />
                </IconButton>
              </Box>
              <audio
                src={URL.createObjectURL(
                  new Blob(recordingChunks, { type: 'audio/webm' })
                )}
                controls
                style={
                  recordingState !== 'paused'
                    ? {
                        display: 'none'
                      }
                    : undefined
                }
              />
            </>
          ) : (
            <CircularProgress />
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            {language.text.cancel}
          </Button>
          <Button variant="contained" onClick={confirmRecording}>
            {language.text.send}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
