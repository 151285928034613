import LinkedInActions from '@common/Webview.renderer/LinkedInActions';
import AbstractLinkedInLoadingEngine from './AbstractLinkedInLoadingEngine';
import log from 'electron-log';

export default class WebviewLoadingEngine extends AbstractLinkedInLoadingEngine {
  async firstLoad() {
    log.info('WebviewLoadingEngine: starting first load');
    await this.loadNetwork();
    log.info('WebviewLoadingEngine: finished first load');
  }

  private async loadNetwork() {
    await LinkedInActions.execute(['open-network', 'scroll-entire-network'], {
      forceBackground: true
    });
  }

  async startupLoad() {
    log.info('WebviewLoadingEngine: starting startup load');
    await this.loadNewNetworkParticipants();
    await this.loadNewChatMessages();
    log.info('WebviewLoadingEngine: finished startup load');
  }

  private async loadNewNetworkParticipants() {
    await LinkedInActions.execute(['open-network', 'scroll-new-network'], {
      forceBackground: true
    });
  }

  private async loadNewChatMessages() {
    await LinkedInActions.execute(['check-for-new-messages'], {
      forceBackground: true
    });
  }
}
