import {
  InfiniteData,
  InvalidateOptions,
  InvalidateQueryFilters,
  QueryClient,
  QueryKey
} from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

// Force filter to be exact false if not provided -> otherwise behavior is not as expected
const invalidateQueries = queryClient.invalidateQueries.bind(queryClient);

queryClient.invalidateQueries = <TPageData = unknown>(
  key?: InvalidateQueryFilters<TPageData> | QueryKey,
  filters?: InvalidateOptions | InvalidateQueryFilters<TPageData>,
  options?: InvalidateOptions
) => {
  if (!key) key = { exact: false };
  else if (typeof key === 'object' && !Array.isArray(key)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- due to function overload, ts is not able to recognize the types
    //@ts-ignore
    if (key.exact === undefined) key.exact = false;
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- due to function overload, ts is not able to recognize the types
    //@ts-ignore
    if (filters) filters.exact = false;
    else {
      filters = { exact: false };
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any -- due to function overload, ts is not able to recognize the types
  return invalidateQueries<TPageData>(key as any, filters as any, options);
};

function optimisticUpdateInfiniteData<T>(
  func: ((...args: never[]) => Promise<T[]> | T[]) & {
    getQueryKey?: () => string[];
  },
  updater: (data: T) => T,
  queryKey = func.getQueryKey?.() ?? []
) {
  queryClient.setQueryData<InfiniteData<T[]>>(queryKey, (data) => {
    if (!data)
      return {
        pageParams: [],
        pages: []
      };

    return {
      ...data,
      pages: data.pages.map((page) => page.map((item) => updater(item)))
    };
  });
}
function optimisticUpdate<T>(
  func: ((...args: never[]) => Promise<T> | T) & {
    getQueryKey?: () => string[];
  },
  updater: (data: T) => T,
  queryKey = func.getQueryKey?.() ?? []
) {
  queryClient.setQueryData<T>(queryKey, (data) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (!data) return undefined!;

    return updater(data);
  });
}

export const QueryUpdates = {
  optimisticUpdate,
  optimisticUpdateInfiniteData
};

export default queryClient;
