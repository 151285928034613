import React from 'react';
import { Box, Chip, Typography } from '@mui/material';
import LinkedInWebview from '@common/Webview.renderer/Base/LinkedInWebview';
import { LinkedInWebviewElement } from '@common/Webview.renderer/Base/ContentScriptTypes';
import { useSnackbar } from 'notistack';
import CommentTask from './CommentTask';
import { language } from '@/index';
import { CSComment, CSEventData, CSEventType } from 'webview-preload';
import { useScheduler } from 'focus-mode-scheduler';
import BrokenAudienceHolders from '../_general/BorkenAudienceHolders/BrokenAudienceHolders';
import OnboardingVideo from '../_general/OnboardingVideo/OnboardingVideo';

export interface CommentTaskUIProps {
  task: CommentTask;
}

export default function CommentTaskUI(props: CommentTaskUIProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { task } = props;
  const { constraints } = useScheduler();

  const [comments, setComments] = React.useState<CSComment[]>([]);

  const [audienceHoldersBroken, setAudienceHoldersBroken] =
    React.useState(false);
  const [linkedInKey, forceReloadLinkedIn] = React.useReducer<
    (x: number) => number
  >((x) => x + 1, 0);

  const messageHandler = React.useCallback(
    <Event extends CSEventType>(channel: Event, data: CSEventData<Event>) => {
      if (task.id !== 'comment') return;
      switch (channel) {
        case 'linkedin:save-comment':
          const comment = data as CSComment;
          setComments((prev) => {
            constraints.setItemsDone(prev.length + 1);
            return [...prev, comment];
          });
          task.commentPosted(comment);
          break;
        case 'linkedin:invalid-search':
          setAudienceHoldersBroken(true);
          break;
      }
    },
    [task]
  );

  const webviewRef = React.useRef<LinkedInWebviewElement | null>(null);

  const headerTextColor = React.useMemo(() => {
    if (!(task instanceof CommentTask)) return undefined;
    const peopleLength = comments.length;
    const keys = Object.keys(CommentTask.PHASES).map((k) => Number(k));
    const phase = keys.reduce((prev, curr) => {
      if (peopleLength >= curr && curr > prev) return curr;
      return prev;
    }, keys[0]);
    return CommentTask.PHASES[phase];
  }, [comments]);

  const constraint = constraints.useCurrentConstraint();
  const constraintText = React.useMemo(() => {
    if (constraint?.type !== 'items') return comments.length;
    return `${constraint.itemsDone} / ${constraint.items}`;
  }, [comments, constraint]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%'
      }}>
      <OnboardingVideo
        onboardingStep="focus:comments"
        offset={{ y: 60 }}
        completeOn="finish"
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: (theme) => theme.palette.secondary.main,
          p: 1,
          borderBottomLeftRadius: (theme) => theme.shape.borderRadius,
          borderBottomRightRadius: (theme) => theme.shape.borderRadius
        }}>
        <Chip
          label={constraintText}
          sx={{
            color: headerTextColor,
            pointerEvents: 'none',
            fontSize: '1.2em'
          }}
        />
        <Typography
          sx={{
            ml: 1,
            pointerEvents: 'none'
          }}>
          {language.text.comments_written}
        </Typography>
      </Box>
      <LinkedInWebview
        key={linkedInKey}
        ref={webviewRef}
        messageHandler={messageHandler}
        features={[
          'disable-connecting',
          'focus-mode-style',
          'highlight-audience-holders-posts'
        ]}
        onOpenChat={() => {
          enqueueSnackbar(language.text.no_chatting_comments_task, {
            variant: 'info'
          });
          return false;
        }}
        startURL={task.startLink}
      />
      <BrokenAudienceHolders
        audienceHoldersBroken={audienceHoldersBroken}
        audienceHolders={task.audienceHolders}
        onResolved={(audienceHolders) => {
          task.setAudienceHolders(audienceHolders);
          setAudienceHoldersBroken(false);
          forceReloadLinkedIn();
        }}
      />
    </Box>
  );
}
