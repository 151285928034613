import {
  Message,
  MessageTemplate,
  PipelineEvent
} from '@common/types/ApiTypes';
import ChatTask, {
  ChatTaskIncomingEventMap,
  ChatTaskOutgoingEventMap
} from '../Chat/ChatTask';
import { language } from '@/index';
import { PipelineStep } from '@common/PipelineManager/PipelineTypes';
import { Pipelines } from '@common/PipelineManager/Pipelines';
import { TaskConfig, TaskHeader } from 'focus-mode-scheduler';
import { SSCChat } from '@/data/Classes/Chat/SSCChat';
import { Contact } from '@/data/Classes/Contact';
import Task, { TaskIncomingEventMap } from 'focus-mode-scheduler/src/Task/Task';

export type EstablishRelationTaskIncomingEventMap = ChatTaskIncomingEventMap;
export type EstablishRelationTaskOutgoingEventMap = ChatTaskOutgoingEventMap;

export default class EstablishRelationTask extends Task<
  EstablishRelationTaskIncomingEventMap,
  EstablishRelationTaskOutgoingEventMap
> {
  public static get config(): TaskConfig {
    return {
      id: 'establish-relation',
      info: {
        title: language.text.establish_relation,
        description: language.text.build_a_personal_relation
      },
      hide: false,
      constraintBreachedDialogContent:
        ChatTask.config.constraintBreachedDialogContent
    };
  }

  protected type: 'new' | 'existing' = 'new';
  protected static _pipeline_steps: PipelineStep[] | null = null;
  protected static _active_step: PipelineStep | null = null;

  protected _header: TaskHeader = { title: '' };

  protected _template: MessageTemplate | null = null;
  protected _pipeline_steps: PipelineStep[] | null = null;
  protected _activeStep: PipelineStep | null = null;

  constructor(
    public readonly chat: SSCChat,
    public readonly contact: Contact,
    public readonly templates: MessageTemplate[],
    public readonly pipeline_history: PipelineEvent[],
    protected readonly messages: Message[]
  ) {
    super();
    this._template = templates[0] ?? null;

    this._pipeline_steps = Pipelines.getCurrentStepsToDisplay(
      contact.lastPipelineEvent?.currentStep
    );

    this._activeStep = this.contact.lastPipelineEvent?.currentStep
      ? (Pipelines.getStepToDisplay(
          this.contact.lastPipelineEvent.currentStep,
          this.pipeline_history.map((h) => h.currentStep)
        ) ?? null)
      : null;

    this._header = {
      title: language.text.establish_relation,
      subtitle: language.text.establish_relation_to_person.replace(
        '{name}',
        this.contact.name
      ),
      skip: {
        instance: {
          enable: true,
          text: language.text.skip_name(this.contact.name),
          // when skipping via header we automatically open the skip until so we don't need another confirmation
          omitConfirmationDialog: true
        },
        category: {
          // when skipping via header we automatically open the skip until so we don't need another confirmation
          omitConfirmationDialog: true
        }
      }
    };

    this._pipeline_steps = Pipelines.getCurrentStepsToDisplay(
      this.contact.lastPipelineEvent?.currentStep
    );

    EstablishRelationTask._active_step =
      this._pipeline_steps.find((s) => s.type === 'buildRelationship') ?? null;

    this.type =
      this.contact.hasChat && messages.length > 0 ? 'existing' : 'new';
  }

  private shouldSkipSkipUntil = false;
  public omitSkipUntil() {
    this.shouldSkipSkipUntil = true;
  }

  protected async finishSkipUntil(date: Date) {
    await this.contact.skipUntil(date);
    this.internalEmit('finished', undefined);
  }
  protected currentMessage = '';
  public setCurrentMessage(message: string) {
    this.currentMessage = message;
  }

  protected async handleEvent<
    Type extends keyof (TaskIncomingEventMap & ChatTaskIncomingEventMap)
  >(type: Type, data: (TaskIncomingEventMap & ChatTaskIncomingEventMap)[Type]) {
    switch (type) {
      case 'skip-until-date':
        const date = data as ChatTaskIncomingEventMap['skip-until-date'];
        if (date.type === 'complete') await this.finishSkipUntil(date.date);
        else if (date.type === 'close')
          this.internalEmit('finish-aborted', undefined);
        else if (date.type === 'skip') this.internalEmit('finished', undefined);
        break;
      case 'skip':
        if (this.shouldSkipSkipUntil) this.internalEmit('finished', undefined);
        else this.internalEmit('get-skip-until-date', undefined);
        break;
      case 'complete':
        await this.complete();
    }
  }

  protected isRelationEstablished: boolean | null = null;
  public setRelationEstablished(isEstablished: boolean) {
    this.isRelationEstablished = isEstablished;
  }

  private async complete(): Promise<void> {
    if (this.isRelationEstablished) {
      await this.contact.gotoNextPipelineStep();
    }

    if (this.currentMessage) await this.chat.sendMessage(this.currentMessage);
    this.currentMessage = '';

    this.messages.push({
      attachments: [],
      createdAt: new Date(),
      deleted: false,
      messageID: 'new',
      reactions: [],
      sendByYou: true,
      text: this.currentMessage
    });

    this.internalEmit('finished', undefined);
  }

  public get steps() {
    return EstablishRelationTask._pipeline_steps;
  }

  public get activeStep() {
    return EstablishRelationTask._active_step;
  }
}
