import { Button, Dialog, DialogContent } from '@mui/material';
import { Contact } from '@/data/Classes/Contact';
import { language } from '@/index';
import CategorySelector from '@/Routes/Focus/_general/ContactView/CategorySelector';
import { ContactType } from '@common/types/enums';
import { Pipelines } from '@common/PipelineManager/Pipelines';
import log from 'electron-log';
import ContactActions from '@/data/DataServer/Contact';
import { useChatContext } from '../ChatContext';

export type ChatRecategorizeProps = {
  open: boolean;
  onClose: () => void;
  onRecategorize?: (category: ContactType) => void;
} & (
  | { contact: Contact; profileID?: string }
  | { profileID: string; contact?: Contact }
);

export default function ChatRecategorize(props: ChatRecategorizeProps) {
  const { loadedChatData } = useChatContext() ?? {};
  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogContent
        sx={{
          minWidth: 'min(600px, 95vw)',
          boxSizing: 'border-box',
          background: (theme) => theme.palette.background.default
        }}>
        <CategorySelector
          noConfirmation
          pt={0}
          onFinished={async (category) => {
            try {
              const step =
                category === ContactType.POTENTIAL_CUSTOMER
                  ? Pipelines.getCategorizationSteps()[0]
                  : undefined;
              let contact = props.contact;
              if (
                !contact ||
                contact.type === ContactType.UNCATEGORIZED ||
                contact.type === ContactType.MARKED
              ) {
                contact =
                  (await ContactActions.createContactFromLinkedInAPI(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- either contact of profileID is defined
                    props.profileID!,
                    loadedChatData
                  )) ?? undefined;
              }
              if (!contact)
                throw new Error(
                  `Could not create contact for profileID ${props.profileID ?? '[MISSING]'}`
                );
              await Contact.categorize(contact, category, step);
              props.onRecategorize?.(category);
            } catch (e) {
              log.error(
                'Error while recategorizing contact in chat cockpit',
                e
              );
              throw e;
            } finally {
              props.onClose();
            }
          }}
          defaultValue={props.contact?.type}>
          <Button
            onClick={() => {
              props.onClose();
            }}
            variant="contained"
            color="inherit">
            {language.text.cancel}
          </Button>
        </CategorySelector>
      </DialogContent>
    </Dialog>
  );
}
