import { Box, Typography } from '@mui/material';
import { language } from '@/index';
import { useSetup } from '../../SetupContext';
import { AvaIcon, Background, FancyButton, Logo } from 'ui-utils';

export default function AvaTutorial() {
  const { completeAvaTurotial } = useSetup();
  return (
    <Background
      variant="frame"
      sx={{
        height: '100%',
        display: 'flex',
        width: '100%'
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          margin: 'auto',
          textAlign: 'center'
        }}>
        <Box
          sx={{
            height: '100%',
            maxWidth: '840px',
            margin: 'auto',
            textAlign: 'center'
          }}>
          <Logo
            variant="blue_text"
            sx={{
              width: '200px',
              height: 'auto',
              marginBottom: (theme) => theme.spacing(8),
              marginTop: (theme) => theme.spacing(8)
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%'
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                padding: 0,
                boxSizing: 'border-box'
              }}>
              <AvaIcon
                width={200}
                style={{ marginLeft: 10, marginRight: 10 }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                padding: (theme) => theme.spacing(4),
                backgroundColor: (theme) => theme.palette.background.paper,
                borderRadius: (theme) => theme.spacing(2, 2, 2, 0),
                boxSizing: 'border-box'
              }}>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 'bold',
                  marginBottom: (theme) => theme.spacing(2),
                  textAlign: 'center'
                }}>
                {language.text.hi_im_ava}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  marginBottom: (theme) => theme.spacing(1),
                  textAlign: 'center'
                }}>
                {language.text.ava_greeting}
              </Typography>
              <FancyButton
                fType={{
                  promise: true
                }}
                variant="contained"
                onClick={() => completeAvaTurotial()}
                sx={{
                  marginTop: (theme) => theme.spacing(2),
                  width: '290px',
                  height: '48px',
                  borderRadius: 1,
                  fontSize: '18px',
                  backgroundColor: (theme) => theme.palette.primary.main,
                  textTransform: 'capitalize'
                }}>
                {language.text.continue}
              </FancyButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Background>
  );
}
