import React from 'react';
import ChatHistoryStatic, { ChatHistoryStaticProps } from './ChatHistoryStatic';
import ChatActions from '@/data/DataServer/Chat';
import { Message } from '@common/types/ApiTypes';
import { AbstractChat } from '@/data/Classes/Chat/AbstractChat';

export type SendMessage = Omit<Message, 'messageID'> & Partial<Message>;

export interface ChatHistoryProps
  extends Omit<
    ChatHistoryStaticProps,
    | 'participants'
    | 'isFetching'
    | 'error'
    | 'messages'
    | 'callbackLoadMore'
    | 'unreadCount'
  > {
  chat: AbstractChat;
  /**
   * If you pass an array of sendMessages make sure, that the newest message is the first in the array
   */
  sendMessages?: SendMessage | SendMessage[] | null;
}

export default function ChatHistory(props: ChatHistoryProps) {
  const { chat, ...rest } = props;
  const [loadMore, setLoadMore] = React.useState(false);

  const {
    messages: data = [],
    status,
    hasNextPage
  } = ChatActions.useMessages(props.chat, {
    loadMore
  });

  const messages = React.useMemo(() => {
    const messages = data.map((m) => ({
      ...m,
      new: false
    }));
    if (props.sendMessages)
      messages.unshift(
        ...(Array.isArray(props.sendMessages)
          ? props.sendMessages.map((m, index) => ({
              messageID: `custom-message-id-${index}`,
              ...m,
              new: true
            }))
          : [
              {
                messageID: `custom-message-id-${-1}`,
                ...props.sendMessages,
                new: true
              }
            ]
        )
          // remove duplicate messages
          .filter(
            (m) =>
              !messages.some((m2) => {
                if (m2.messageID === m.messageID) return true;
                // if message is new and the same as the existing message which were both sent within 3 seconds
                if (
                  m2.text === m.text &&
                  Math.abs(m2.createdAt.getTime() - m.createdAt.getTime()) <
                    3000
                )
                  return true;
                // filter out messages which were sent within 200ms -> text might not be the same (conversion of nb spaces to spaces or similar)
                if (
                  Math.abs(m.createdAt.getTime() - m2.createdAt.getTime()) <
                    200 &&
                  m2.sendByYou === m.sendByYou
                )
                  return true;
                return false;
              })
          )
      );

    return messages.sort(
      (a, b) => b.createdAt.getTime() - a.createdAt.getTime()
    );
  }, [data, props.sendMessages]);

  return (
    <ChatHistoryStatic
      {...rest}
      unreadCount={chat.unreadCount}
      participants={
        chat.participants.length
          ? chat.participants
          : chat.contact
            ? [
                {
                  firstName: chat.contact.firstname,
                  lastName: chat.contact.lastname,
                  profileID: chat.contact.profileID,
                  profilePictureUrl: chat.contact.pictures as {
                    [resolution: number]: string;
                  }
                }
              ]
            : []
      }
      setMessageReaction={chat.sendMessageReaction.bind(chat)}
      error={status === 'error'}
      // hasNextPage is undefined while loading first page
      hasNextPage={hasNextPage ?? true}
      messages={messages}
      callbackLoadMore={(loadMore) => setLoadMore(loadMore)}
    />
  );
}
