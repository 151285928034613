import { EventTypes } from './MessageBusTypes';

export type EventName = EventTypes['name'];
export type EventPayload<Name extends EventName> = Extract<
  EventTypes,
  { name: Name }
>['payload'];
export type EventHandler<Name extends EventName> = (
  payload: EventPayload<Name>,
  id: string
) => void | Promise<void>;

export type FullEventData<Name extends EventName = EventName> = {
  id: string;
  eventName: Name;
  payload: EventHandler<Name>;
  persistence: MessagePersistence;
};

export enum MessagePersistence {
  TEMPORARY = 0
  // PERSISTENT = 1
}
