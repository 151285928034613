import { Box, BoxProps } from '@mui/material';
import './RainbowLoadingAnimation.css';

export interface RainbowLoadingAnimationProps extends BoxProps {
  rainbow?: boolean;
}

export default function RainbowLoadingAnimation(
  props: RainbowLoadingAnimationProps
) {
  const { rainbow, ...rest } = props;
  return (
    <Box {...rest} className="loader-inner">
      <Box className="loader-line-wrap">
        <Box
          className="loader-line"
          sx={{
            '--color': props.rainbow
              ? null
              : (theme) => theme.palette.primary.main
          }}></Box>
      </Box>
      <Box className="loader-line-wrap">
        <Box
          className="loader-line"
          sx={{
            '--color': props.rainbow
              ? null
              : (theme) => theme.palette.primary.main
          }}></Box>
      </Box>
      <Box className="loader-line-wrap">
        <Box
          className="loader-line"
          sx={{
            '--color': props.rainbow
              ? null
              : (theme) => theme.palette.primary.main
          }}></Box>
      </Box>
      <Box className="loader-line-wrap">
        <Box
          className="loader-line"
          sx={{
            '--color': props.rainbow
              ? null
              : (theme) => theme.palette.primary.main
          }}></Box>
      </Box>
      <Box className="loader-line-wrap">
        <Box
          className="loader-line"
          sx={{
            '--color': props.rainbow
              ? null
              : (theme) => theme.palette.primary.main
          }}></Box>
      </Box>
    </Box>
  );
}
