import { Logger, trace } from '../client/Utils';
import { ApiEvent, Event } from 'linkedin-domain-types';

/**
 * Class for providing an interface for events in the LinkedIn api.
 */
@trace()
export class EventService {
  /**
   * List of all listeners
   */
  private readonly listeners: {
    [P in Event]: ((event: ApiEvent<P>) => void)[];
  };

  constructor() {
    Logger.debug('Creating new event service');
    // WARN: listeners[*] = undefined; without the type system knowing
    const listeners: {
      [P in Event]: ((event: ApiEvent<P>) => void)[];
    } = {} as {
      [P in Event]: ((event: ApiEvent<P>) => void)[];
    };
    for (const [, value] of Object.entries(Event)) {
      listeners[value] = [];
    }
    // \WARN
    this.listeners = listeners;
  }

  /**
   * Adds a listener for a specific event
   */
  public addListener<T extends Event, U extends ApiEvent<T>>(
    event: T,
    callback: (event: U) => void
  ): void {
    Logger.debug(`Adding listener for event ${event}`);
    this.listeners[event].push(callback as (event: ApiEvent<T>) => void);
  }

  /**
   * Removes a listener for a specific event
   */
  public removeListener<T extends Event>(
    event: T,
    callback: (event: ApiEvent<T>) => void
  ): void {
    Logger.debug(`Removing listener for event ${event}`);
    const eventListeners = this.listeners[event];
    const index = eventListeners.indexOf(callback);
    if (index === -1) {
      Logger.debug(`Listener for event ${event} not found`);
      return;
    }
    eventListeners.splice(index, 1);
  }

  /**
   * Emits an event to all listeners
   */
  public emit<T extends Event>(event: ApiEvent<T>): void {
    Logger.debug(`Emitting event ${event.type}`);
    void Promise.all(
      this.listeners[Event.ALL]?.map((listener) =>
        listener(event as ApiEvent<Event.ALL>)
      ) ?? []
    );
    void Promise.all(
      this.listeners[event.type]?.map((listener) => listener(event)) ?? []
    );
  }
}
