import { Message, MessageTemplate } from '@common/types/ApiTypes';
import { ChatTutorialIDs } from './Tutorials.types';
import { AvaIcon } from 'ui-utils';
import { Question } from '@common/PipelineManager/PipelineTypes';

export interface ChatTutorial {
  id: ChatTutorialIDs;
  title: string;
  profile: {
    firstname: string;
    lastname: string;
    profile_picture_urls?: {
      800: string;
    };
    headline: string;
  };
  initial: Partial<{
    messages:
      | Partial<Pick<Message, 'attachments' | 'text' | 'sendByYou'>>[]
      | null;
    video: Video | null;
    templates: Pick<MessageTemplate, 'name' | 'text'>[] | null;
    stepID?: string;
  }> | null;
  messages: Partial<{
    messages: Partial<Pick<Message, 'attachments' | 'text' | 'sendByYou'>>[];
    video: Video & { fullscreen?: boolean };
    templates: Pick<MessageTemplate, 'name' | 'text'>[] | null;
    stepID?: string;
  }>[];
}

const Tutorials = {
  chats: [
    {
      id: 'kennenlernen-pipeline',
      title: 'Kennenlernen Pipeline Flow',
      profile: {
        firstname: 'Ava',
        lastname: 'AI',
        profile_picture_urls: {
          800: AvaIcon.src.square
        },
        headline: 'Deine virtuelle Assistentin'
      },
      initial: {
        video: {
          title: 'Begrüßungsnachricht',
          videoID: 'ummzD-PaTBw'
        },
        templates: [
          {
            name: 'Begrüßungsnachricht',
            text: `Grüß dich Ava und schön, dass wir connected sind. 

Wenn ich das richtig sehe, bist du nicht nur eine People Person, sondern auch noch Expertin darin, Menschen hier auf LinkedIn erfolgreich zu machen, richtig?`
          }
        ]
      },
      messages: [
        {
          messages: [
            {
              text: `Hey Name schön dass du dich meldest. YES dass hast du absolut Richtig erkannt. My Job is to make other Succesfull and reach their goals.

Sorry ich drifte gerne mal ins englisch ab. Luftfahrt Jargon. 

Freut mich dass wir connected sind Name und wenn ich das richtig erkenne, willst du hier auf LinkedIn jetzt auch voll durch starten, richtig?

Freue mich von dir zu lesen und bis bald.`
            }
          ],
          video: {
            title: 'Beziehungsnachricht',
            videoID: 'BTj27E6J4Uk'
          },
          templates: [
            {
              name: 'Beziehung 1',
              text: `Danke für die Antwort <ssc-variable>firstname</ssc-variable>.
<ssc-placeholder>PLATZHALTER</ssc-placeholder>
Kurze noch eine kleine Warnung zu mir: Ich nehme kein Blatt vor den Mund.
Wenn du für direkte und klare Worte stehst, könntest das mit uns interessant werden.
Beste Grüße`
            }
          ],
          stepID: 'beziehungsaufbau'
        },
        {
          messages: [
            {
              text: `Hey,
ich liebe auch Klartext in der digitalen Welt nehme ich auch keinen Schlüssel vor die Daten.
So wie ich das sehe, startest du auch gerade auf LinkedIn durch, korrekt? Finde ich sehr cool. Die Plattform hat echt viel Potential.
Vielleicht können wir ja mal ein paar Tipps und Tricks austauschen. Freut mich auf jeden Fall, dass du jetzt in meinem Netzwerk bist.
LG AVA`
            },
            {
              text: `Ich habe jetzt auch LinkedIn für mich entdeckt und dort meine Geheimwaffe gefunden. Ich verwende seit kurzem das Social Selling Cockpit wodurch LinkedIn Kundengewinnung
endlich einfach und strukturiert wird. Kann ich dir wirklich nur wärmstens empfehlen.
LG`,
              sendByYou: true
            },
            {
              text: `Hey du, wenn es etwas gibt, dass mich interessiert, dann sind es Menschen, die klar und direkt sind. Und die brennen wofür sie rausgehen. Denn ich mache nichts anderes. 

Schönen Tag noch! AVA`
            }
          ],
          video: {
            title: 'Meeting Pitch',
            videoID: 'cT21iZJ1FrY'
          },
          templates: [
            {
              name: 'Meeting Pitch',
              text: `Hey <ssc-variable>firstname</ssc-variable>, mega spannend!
Ich hab da mal einen Vorschlag. Ich sehe ja, dass du wirklich für dein Business brennst 🔥und das finde ich stark. Hättest du Lust auf einen Call?
Keine Ahnung, ob mein Angebot für dich passend ist oder ob deins wirklich auf mich Matched - aber ich hätte Lust dich kennenzulernen.
PS: Bist du eher der Kaffee oder eher der Tee-Typ?`
            }
          ],
          stepID: 'meeting-pitch'
        },
        {
          messages: [
            {
              text: `Das letzte Mal als ich Kaffee oder Tee getrunken habe, hatte ich danach einen Kurzschluss und musste in die Werkstatt...🙄
Ich bevorzuge ja Gleichstrom.`
            }
          ],
          video: {
            title: 'Kalendar Link',
            videoID: '3DhpRiz7NRU'
          },
          templates: [
            {
              name: 'Kalendar Link',
              text: `Hey AVA, du bist ja echt der Hammer!
<ssc-placeholder>Individuell auf die Nachricht eingehen</ssc-placeholder>
Buch dir hier am besten gleich einen Termin und lass uns loslegen:
https://calendly.com/dein-calendly
Ich freu mich wie Bolle darauf, dich live zu sehen!
Sonnige Grüße ☀️ und bis ganz bald.`
            }
          ],
          stepID: 'calendar-link'
        },
        {
          video: {
            title: 'Terminbestätigung und Ergebnis',
            videoID: '9Q_gRnT4R3w',
            fullscreen: true
          },
          stepID: 'meeting-accepted'
        },
        {
          stepID: 'meeting-done'
        },
        {
          video: {
            title: 'Hausaufgaben',
            videoID: '8xYkx7AFcLk'
          },
          templates: [
            {
              name: 'Terminbestätigung und Ergebnis',
              text: `Hey AVA
  Danke nochmal für das coole Gespräch! Bevor wir in die Vollen gehen, müssen wir uns aber noch vorbereiten. Und dafür brauche ich deine Antworten auf diese 3 Fragen:
  <ssc-placeholder>INDIVIDUELLE AUFGABE STELLEN</ssc-placeholder>
  Ich danke dir schon im Voraus für deine Antworten! 🙏🏼
  Cheers! 🔥`
            }
          ],
          stepID: 'homework'
        }
      ]
    },
    {
      id: 'follow-up',
      title: 'Follow Up Nachrichten',
      profile: {
        firstname: 'Berta',
        lastname: 'Beschäftigt',
        headline: 'Deine virtuelle Assistentin'
      },
      initial: {
        messages: [],
        video: {
          title: 'Follow up',
          videoID: '4Wu-uX7cIIE'
        },
        templates: [
          {
            name: 'Follow up 1',
            text: `Hallo <ssc-variable>firstname</ssc-variable>,

Ich hoffe, du hattest bis jetzt eine gute Woche und dass es dir gut geht!

Ich wollte mal nachfragen, ob meine Nachricht bei dir untergegangen ist.

Passiert ja manchmal 😎

Freu mich, von dir zu hören.

Sonnige Grüße ☀️`
          }
        ],
        stepID: 'beziehungsaufbau'
      },
      messages: []
    },
    {
      id: 'no-show',
      title: 'Nicht erschienen',
      profile: {
        firstname: 'Florin',
        lastname: 'Fergesslich',
        headline: 'Deine virtuelle Assistentin'
      },
      initial: {
        messages: [],
        video: {
          title: 'Nicht erschienen',
          videoID: 'gHKCUsqZpmw'
        },
        templates: [
          {
            name: 'Nicht erschienen 1',
            text: `Hey <ssc-variable>firstname</ssc-variable>,

Irgendwas ist anscheinend dazwischengekommen. Wir hatten uns doch verabredet, oder?

Ich hoffe, es ist nichts Ernstes passiert?

Aber Absagen und einen neuen Termin machen gehört zum guten Ton, findest du nicht?

Beste Grüße`
          }
        ],
        stepID: 'meeting-done'
      },
      messages: []
    },
    {
      id: 'wrong-time',
      title: 'Nicht erschienen',
      profile: {
        firstname: 'Gert',
        lastname: 'Gestresst',
        headline: 'Deine virtuelle Assistentin'
      },
      initial: {
        messages: [],
        video: {
          title: 'Falscher Zeitpunkt',
          videoID: 'tiDg6v0MIKo'
        },
        templates: [
          {
            name: 'Falscher Zeitpunkt 1',
            text: `Danke für das intensive und echte Gespräch! 🙏

Wir machen das genauso wie besprochen: Du sorgst dafür, dass XYZ passiert und funktioniert, und wir beide hören uns ganz entspannt in 3-6 Monaten nochmal. 🗓️

Und wenn du vorher Fragen hast oder bereit bist, loszulegen: Meine Tür steht dir immer offen! 🚪`
          }
        ],
        stepID: 'meeting-done'
      },
      messages: []
    }
  ] satisfies ChatTutorial[]
} as const;

export default Tutorials;
