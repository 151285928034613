import UsageTypeSelector from '@/components/UsageTypeSelector/UsageTypeSelector';
import { useSetup } from '../../SetupContext';
import { Center, FancyButton } from 'ui-utils';
import { language } from '@/index';
import { Typography } from '@mui/material';
import { ArrowForward, LinkedIn } from '@mui/icons-material';

export default function SetupUsage() {
  const { currentStep, usageClassSet } = useSetup();

  if (currentStep?.handler !== 'usage') return null;

  return (
    <Center height="100%">
      <LinkedIn color="primary" sx={{ fontSize: '8rem' }} />
      <Typography variant="h4">{language.text.linkedin_usage}</Typography>
      <Typography variant="body1" maxWidth={700}>
        {language.text.linkedin_usage_description}
      </Typography>
      <UsageTypeSelector />
      <Typography variant="body1" maxWidth={700}>
        {language.text.you_can_change_your_selection_in_the_settings}
      </Typography>
      <FancyButton
        sx={{ mt: 2 }}
        size="large"
        fType={{ promise: true }}
        onClick={usageClassSet}
        variant="contained"
        color="primary"
        endIcon={<ArrowForward />}>
        {language.text.continue}
      </FancyButton>
    </Center>
  );
}
