import React from 'react';
import { Box } from '@mui/material';
import { ArrowForward, Redo } from '@mui/icons-material';
import { useSetup } from '../../SetupContext';
import { language } from '@/index';
import { FancyButton, GenericVideoPlayer } from 'ui-utils';

const NEXT_TIMEOUT = 0;

/**
 * Simple video page in focus mode, gets videoID from focus mode context
 */
export default function VideoPlayer() {
  const { currentStep, videoWatchedButRewatch, videoCompleted } = useSetup();

  const [finished, setFinished] = React.useState(false);

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [pulsate, setPulsate] = React.useState<() => void>(() => {});

  // pulsate button when next is available
  React.useEffect(() => {
    if (finished) pulsate();
  }, [finished]);

  if (!currentStep || currentStep.handler !== 'video') return null;

  return (
    <Box
      padding={(theme) => theme.spacing(4, 4, 2, 4)}
      height="100%"
      maxHeight="100%"
      display="flex"
      flexDirection="column"
      boxSizing="border-box"
      overflow="auto">
      <Box overflow="hidden" flexGrow={1}>
        <GenericVideoPlayer
          videoID={currentStep.video}
          onEnd={() => {
            setFinished(true);
            if (NEXT_TIMEOUT > 0)
              setTimeout(() => {
                if (currentStep.rewatchable) {
                  if (mounted.current && currentStep.handler === 'video')
                    videoWatchedButRewatch();
                }
                if (mounted.current && currentStep.handler === 'video')
                  videoCompleted();
              }, NEXT_TIMEOUT);
          }}
        />
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        gap={2}
        justifyContent="flex-end"
        padding={2}
        boxSizing="border-box">
        {currentStep.skipable && !currentStep.rewatchable && !finished && (
          <FancyButton
            fType={{
              promise: true
            }}
            endIcon={<Redo />}
            onClick={() => videoCompleted()}
            size="large">
            {language.text.skip}
          </FancyButton>
        )}
        {currentStep.rewatchable && (
          <FancyButton
            fType={{
              pulsating: 'none',
              promise: true
            }}
            endIcon={<ArrowForward />}
            onClick={() => videoWatchedButRewatch()}
            variant="contained"
            pulsate={(fct) => setPulsate(() => fct)}
            size="large">
            {language.text.understood}
          </FancyButton>
        )}
        <FancyButton
          fType={{
            pulsating: 'ring',
            promise: true
          }}
          endIcon={<ArrowForward />}
          onClick={() => videoCompleted()}
          variant="contained"
          disabled={!finished && !currentStep.rewatchable}
          pulsate={(fct) => setPulsate(() => fct)}
          size="large">
          {currentStep.rewatchable
            ? language.text.understood_and_dont_show_again
            : language.text.lets_go}
        </FancyButton>
        {currentStep.extraButtons?.map((button, i) => (
          <FancyButton
            key={i}
            fType={{
              promise: true
            }}
            endIcon={button.icon ? <button.icon /> : undefined}
            onClick={async () => {
              await button.onClick();
              videoCompleted();
            }}
            variant="contained"
            size="large">
            {button.text}
          </FancyButton>
        ))}
      </Box>
    </Box>
  );
}
