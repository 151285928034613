import { LanguageObject } from '@common/LanguageManager/LanguageTypes';
import lang_de from './de';
import LanguageManager from '@common/LanguageManager/LanguageManager';
// import { p } from '@mui/material';
// import AvaYoutubeVideo from '../../../renderer/src/components/Ava/AvaYoutubePlayer';

const lang_en: LanguageObject = {
  ...lang_de,
  switch_profile: 'Switch profile',
  install_profile_switcher: 'Install profile switcher',
  profile_switcher_installed: 'Profile switcher installed',
  number_punctuation: '.',
  searching_for_update: 'Search for updates',
  successfully_updated: 'Update successfully installed',
  no_update_available: 'No update available',
  downloading_new_update: 'Update available, will be downloaded',
  installing_new_update: 'Update is being installed',
  network_error_while_updating:
    'Your internet connection is too weak. Please try again later.',
  update_error_mac_access: 'Update failed. Mac authorization error',
  update_error_read_only_volume: 'Update failed. Error: Read only volume.',
  update_error_generic: 'Error when updating',
  update_available: 'Update available',
  ask_user_to_update:
    'There is a new version of the Social Selling Cockpit. Do you want to restart and install the update now?',
  install_update_now: 'Install update now',
  install_update_later: 'Install later',
  create_ticket: 'Report error',
  avas_tip: "Ava's Tipp",
  ticket_submitted: 'Your ticket has been created successfully!',
  ticket_we_will_solve_the_ticket_soon:
    "We look forward to your input on what doesn't work, what you expect or want! We will process your request as soon as possible and get back to you by e-mail. Please also check your spam folder if you don't hear from us.",
  ticket_back_to_ssc: "Let's move on!",
  is_there_a_problem: 'Is there a problem?',
  ticket_title_name: 'Title',
  ticket_description_placeholder: "Tell us what doesn't work",
  ticket_submit: 'Submit ticket',
  report_problem: 'Report a problem',
  production_flag_text:
    "This is currently still a BETA version, so there may still be errors or unexpected behavior. If anything doesn't work the way you want it to, you can simply click on Ava and write us a message.",
  build_flag_text: 'This is a {RELEASE_CHANNEL} Build',
  account: 'Account',
  zoom: 'Zoom',
  cancel: 'Cancel',
  continue: 'Next',
  apply: 'Apply',
  skip: 'Skip',
  skip_once: 'Skip once',
  ok: 'OK',
  delete: 'Delete',
  discard: 'Discard',
  close: 'Close',
  send: 'Send',
  back: 'Back',
  retry: 'Try again',
  no: 'No',
  yes: 'Ja',
  save: 'Save',
  dont_save: 'Do not save',
  understood: 'Understood',
  understood_and_dont_show_again: 'Understood and do not show more',
  are_you_sure_irreversible_deletion:
    'Are you sure? The deletion cannot be undone.',
  thanks_for_your_support: 'Thanks for your help!',
  we_will_solve_the_ticket_soon:
    'We will take care of your ticket as soon as possible and get back to you if we need any further information.',
  quit_ssc: 'Close the SSC',
  something_went_wrong: 'Oops! Something went wrong here :(',
  unknown_error: 'Unknown error',
  we_will_be_up_and_running_soon:
    'We will make sure that everything runs smoothly again as quickly as possible!',
  please_explain_the_error:
    'Can you tell us what you were doing before the error occurred?',
  error_title: 'Title',
  error_description: 'Error description',
  send_error_report: 'Submit',
  download_file: 'Download file',
  error_while_loading_chat: 'An error occurred while loading the chat',
  start_of_the_chat_with: 'Start the chat with {name}. Good luck!',
  new_message: 'New message',
  message_deleted: 'Message deleted',
  react: 'React',
  not_all_variables_replaced_in_message:
    'Not all variables have been replaced yet. Please replace them before you send the message.',
  on_send_placeholders_are_removed: 'All placeholders are removed when sending',
  confirm_send_with_variables: 'Send anyway',
  profile_picture: 'Profile picture',
  text_successfully_copied: 'Text copied',
  meeting_with: 'Meeting mit {name}',
  no_selection: 'No selection',
  emojis_person: 'People',
  emojis_nature: 'Nature',
  emojis_food: 'Food & drink',
  emojis_activities: 'Activities',
  emojis_places: 'Travel & location',
  emojis_objects: 'Objects',
  emojis_symbols: 'Symbols',
  favorites: 'Favoriten',
  upload_files_here: 'Upload files here',
  placeholder: 'Placeholder',
  fullname: 'Full name',
  firstname: 'First name',
  lastname: 'Surname',
  fullname_explanation:
    'The full name of your chat partner is entered here. (e.g. Max Mustermann)',
  firstname_explanation:
    'The first name of your chat partner is entered here. (e.g. Max)',
  lastname_explanation:
    'The surname of your chat partner is entered here. (e.g. Mustermann)',
  type_a_message: 'Write a message...',
  recording_error_mic_settings: 'Please check your microphone settings.',
  voice_recording: 'Voice message',
  skip_step: 'Skip step',
  confirm_skip_step:
    "Are you sure you want to skip this step? You can't go back later.",
  skip_task: 'Skip task',
  confirm_skip_task:
    "Are you sure you want to skip this task? You can't come back to it later.",
  get_a_coffee: "It's best to get yourself a coffee",
  we_are_processing:
    'We are currently processing circa one twelfth of the Internet. So that could take a few minutes.',
  login_to_your_account: 'Log in with your account',
  userid: 'User ID',
  email: 'E-mail',
  login_code: 'Code',
  wrong_email_or_code: 'Incorrect e-mail or code',
  please_wait_seconds_before_resending:
    'Please wait {seconds} seconds before trying again.',
  generic_error_try_again: 'An error has occurred. Please try again.',
  code_sent: 'Code has been sent',
  email_not_registered: 'This e-mail is not registered',
  error_requesting_code: 'Error when requesting the code',
  please_wait_seconds_before_requesting_code:
    'Please wait {seconds} before requesting a new code.',
  no_email_delivered: 'No email received?',
  request_new_code: 'Request a new code',
  no_account_yet: 'No account yet?',
  register_now: 'Register now',
  terminal: 'Terminal',
  changelog: 'Update',
  faq_call: 'FAQ Call',
  faq_call_wrong_time: 'The meetings are always on Mondays at 18:00',
  start_now_on_linkedin: 'Lift off on LinkedIn now',
  already_logged_in: 'You are already logged in',
  greeting_with_name: (name: string) => `Hi ${name}!`,
  general_greeting: 'Hi!',
  logout: 'Logout',
  we_are_working_on_it: 'We are working on it!',
  today_no_video: 'No video today - today is implementation!',
  come_back_tomorrow: 'Check back tomorrow!',
  are_you_sure: 'Are you sure?',
  no_persons_added_yet: 'You have not added a person yet',
  error_while_opening_chat:
    'Unfortunately there was an error opening the chat. Please try again later.',
  reload: 'Reload',
  image: 'Image',
  file: 'File',
  generic_error: 'An error has occurred',
  personal_contact: 'Personal contact',
  audience_holder: 'Audience holder',
  potential_customer: 'Potential customer',
  customer: 'Customer',
  no_match: 'No suitable customer',
  uncategorized: 'Uncategorized',
  select_relation_step: 'Select relationship step',
  cant_write_in_this_stage: 'You cannot write messages in this step',
  make_notes_about_person: 'You can make notes about the person here',
  categorization: 'Categorization',
  categorize: 'Categorize',
  categorize_person: (name) =>
    `Please sort ${name} into one of the categories.`,
  find_audience_holders:
    'Find audience holders for your customers to work more efficiently later.',
  enough_audience_holder_selected: 'You have chosen enough audience holders!',
  enough_audience_holder_selected_goto_next_step:
    "You have selected enough audience holders. If you know of any others, you can select them quickly, otherwise it's best to move on to the next step.",
  select_more: 'Select more',
  next_step: 'Next step',
  do_not_chat_while_searching_audience_holders:
    'Search for audience holders first, there will be time to chat later.',
  categorize_existing_contacts: 'Categorize your existing contacts',
  open_chat: 'Open chat',
  no_chat_available: 'No chat available',
  recategorize: 'Recategorize',
  write_person_later: 'Message {name} later',
  close_profile: 'Close profile',
  open_profile: 'Open profile',
  message_already_sent: 'Message already sent',
  skip_person: 'Skip Person',
  skip_name: (name) => `Skip ${name}`,
  skip_name_until: (name) => `Until when should ${name} be skipped?`,
  chat: 'Chat',
  chat_followup: 'Chat Follow-Up',
  chat_task_description:
    'Write messages to your contacts, build relationships and arrange meetings.',
  chat_tutorial: 'Chat Tutorial',
  mitch_explains: 'Mitch explains',
  cant_recategorize_in_tutorial:
    'It is not possible to reclassify a contact in the tutorial. However, you can still see the different categories here and think about how you would categorize the contact.',
  skip_tutorial: 'Skip tutorial',
  write_comments: 'Write comments',
  write_comments_description:
    'Write comments on posts to expand your\n network and expand your expert status.',
  time_is_up: 'Time is up!',
  you_reached_the_max_time_for_commenting:
    'You have reached the recommended maximum time for this task. You can quickly write the last comment or go straight to the next step.',
  one_more_comment: 'Just one more comment',
  maximum_reached: 'Maximum reached',
  comments_written: 'Comments written',
  no_chatting_comments_task:
    "Write a few comments first, we'll get to the cover letter later.",
  change_audience_holder: 'Change audience holder',
  connect: 'Networking',
  connect_task_description:
    'Decide whether a contact on the list is a potential customer and network.',
  send_connection_request: 'Send connection request',
  check_if_fitting_to_connect:
    'Check whether {name} is a\nis a suitable customer for you and you would like to network.',
  connect_via_sidebar_buttons:
    'To connect, simply select the type of contact you are connecting with the person.',
  connect_later: 'Connect later',
  dont_connect: 'Do not connect',
  is_relation_established:
    'Have you already established a relationship with {name}?',
  relation_established: 'Relationship established',
  relation_not_established: 'Relationship not yet established',
  establish_relation: 'Build a relationship',
  establish_relation_to_person: 'Build a personal relationship with {name}.',
  you_reached_max_time_for_marking_contacts:
    'You have reached the recommended maximum time for this task. You can quickly search for the last new contact or go straight to the next step.',
  one_more_contact: 'Only one more contact',
  max_marked_contacts_reached_warning:
    'You can add a maximum of {MAX} contacts per day. Remove a contact first before adding a new one.',
  x_marked_profiles: 'marked profiles',
  find_potential_customers_before_chatting:
    "Find a few interesting people first, we'll get to the cover letter later.",
  expand_network: 'Expand network',
  expand_network_description:
    'Look for new, interesting contacts\nwith whom you would potentially like to network later.',
  onboarding_video: 'Onboarding Video',
  onboarding_video_title: 'Onboarding: {title}',
  skip_question: 'Skip question',
  relationship_questions: 'Status questions',
  relationship_questions_description:
    'The SSC will ask you questions about your relationship status in order to categorize your contacts correctly.',
  one_step: '1 step',
  multiple_steps: (count: number) => `${count} steps`,
  time_needed: 'Time required',
  resume_last_session: 'Continue last session',
  restart_focus_mode: 'Restart focus mode',
  tasks: 'Tasks',
  lets_go: "Let's go!",
  welcome_to_focus_mode: 'Welcome to the focus mode of the SSC!',
  focus_mode_description:
    "In focus mode, you can concentrate specifically on one task. The SSC helps you to find out what is important and how best to tackle it. When you start focus mode, it's best to take your time and be calm so that you can concentrate fully on the task at hand.",
  back_to_manual_mode: 'Back to manual mode',
  sprint: 'Sprint',
  medium: 'Medium',
  intensive: 'Intensiv',
  done_for_today: "You're done for today!",
  continue_in_manual_mode: 'Continue in manual mode',
  summary: 'Summary',
  summary_of_focus_mode: 'Summary of the focus mode',
  leave_focus_mode_confirmation:
    'If you exit focus mode, unsaved data may be lost. In addition, you will not be able to continue from this point if you restart focus mode. Do you really want to exit focus mode?',
  leave_focus_mode: 'Exit focus mode',
  linkedin_login: 'LinkedIn Login',
  wrong_linkedin_account: 'Wrong LinkedIn account',
  processing_linkedin_data: 'Process LinkedIn data',
  getting_login_status: 'Retrieve login status',
  please_log_in_to_linkedin:
    "Please log in with your LinkedIn account. Please note that you must also accept LinkedIn's guidelines.",
  logging_out: 'Log out',
  login_error_wrong_format:
    'A formatting error has occurred. Please check that you are using the latest version of the SSC.',
  login_error_not_paid:
    'There was a problem with your payment, please check your payment information or contact us.',
  login_error_internal_server_error:
    'There was a problem with the server. Please try again later.',
  login_error_too_many_requests:
    'Please wait 30 seconds before requesting a new code.',
  we_are_working_on_the_ssc: 'We are currently working on the SSC',
  server_maintenance_explanation:
    'We are currently maintaining and updating our servers, which is why you cannot use the Social Selling Cockpit today. Check back tomorrow so you can get started again!',
  even_the_busiest_bees_need_a_break:
    'Even the busiest bee needs a break from time to time.',
  not_yet_categorized: 'Not yet categorized',
  chat_inbox: 'Inbox',
  search: 'Search',
  error_loading_contacts: 'Error when loading the contacts',
  error_while_loading: 'Loading error',
  load_more_entries: 'Load more',
  no_more_entries: 'No further entries',
  mark_as_unread: 'Mark as unread',
  mark_as_read: 'Mark as read',
  archive: 'Archive',
  unarchive: 'Dearchive',
  close_pipeline: 'Close pipeline',
  did_you_close_contact:
    'Have you successfully completed {name} as a customer?',
  contact_is_alredy_customer:
    '{name} is already a successful customer of yours. Good work.',
  contact_is_no_match:
    "{name} has not qualified as a customer. Maybe you'll check back in 3-6 months.",
  no_chat_selected: 'No chat selected',
  categorize_before_chatting:
    'You need to categorize your contacts before they appear here in your Chat Cockpit.',
  open_chat_with: 'Open chat with {name}',
  no_chat_with: "You don't have a chat with {name} yet.",
  open_profile_in_browser: 'Open profile in browser',
  pipeline: 'Potential Customer',
  waiting: 'Waiting',
  no_contacts_categorize_first: 'Start the categorization to add contacts',
  no_fitting_contacts_found: 'No suitable contacts found',
  no_more_contacts: 'No further contacts',
  confirm_mark_as_audience_holder:
    'Do you no longer want to mark {name} as a audience holder?',
  create_discount_coupon: 'Create a new discount coupon',
  discount_coupon_name_explanation:
    'This is the name of your discount coupon. This is not only for them, but is also displayed to your customers when they have entered the discount coupon.',
  coupon_name: 'Name',
  code: 'Rabattcode',
  code_already_in_use: 'This discount code is already taken.',
  code_not_allowed: 'This discount code is not allowed.',
  code_available: 'This code is still available.',
  code_explanation:
    'This is the discount code that your customers enter when ordering in order to be assigned to you.',
  disocunt_coupon_provision_explanation:
    'You can give your customers up to 30% discount. However, any discount you give your customers will be deducted from your commission!',
  discount: 'Rabatt',
  provision: 'Provision',
  unsaved_changes: 'Unsaved changes',
  you_have_unsaved_changes:
    'You still have unsaved changes. Are you sure you want to cancel? The changes will be permanently deleted.',
  no_discount_coupons: 'No discount coupons',
  no_freeToUse_coupons: 'No Free To Use coupons',
  create_f2u_coupon: 'Create new Free To Use coupon',
  f2u_coupon_name_explanation:
    'This is the name of your Free To Use coupon. This is not only for them, but is also displayed to your customers when they have entered the Free To Use coupon.',
  free_months: 'Gratismonate',
  create_coupon: 'Create coupon',
  active: 'Active',
  name: 'Name',
  number_of_active_users: 'Active Users',
  number_of_uses: 'Code uses',
  number_of_not_confirmed_users: 'Not confirmed users',

  number_of_active_users_explanation:
    'This number of users using the coupon and currently has an active subscription. These users are therefore actively earning you commissions.',
  number_of_uses_explanation:
    'This is the number of all uses of the code. Every user who enters the code on the website is counted here, regardless of whether they have confirmed the subscription and whether it is still active.',
  number_of_not_confirmed_users_explanation:
    'This is the number of users who entered the code but did not confirm the subscription. These users clicked on buy on the website, entered their data, but then did not enter their payment information and completed the subscription.',

  code_copied: 'Code copy',
  confirm_deletion: 'Confirm deletion',
  number_of_free_months: 'Number of months',
  total_costs: 'Total costs',
  discount_coupons: 'Discount-Codes',
  f2u_coupons: 'Free To Use-Codes',
  error_loading_balance: 'Error when loading the credit',
  balance: 'Credit balance',
  balance_explanation:
    "All the money that your customers have already paid this month is displayed here, as well as any free months that you have given your customers. This sum will then be paid out or withdrawn at the end of the month. If your customers have not yet paid this month, your commission will not yet be displayed here. So don't worry if it's still less than you expected.",
  customers: 'Customers',
  new: 'Neu',
  basic: 'Basic',
  referrer: 'Referrer',
  use_ssc_to_become_basic: 'Use the SSC for 10 days to become a basic user.',
  finish_training_to_become_adavanced:
    'Once you have completed the pilot training, you will become an advanced user.',
  ambassador: 'Ambassador',
  invite_users_to_become_ambassador:
    'If you have invited 3 friends, you become an ambassador.',
  affiliate: 'Affiliate',
  apply_to_become_affiliate: 'To become an affiliate, you must apply to us.',
  error: 'Error',
  ssc_is_free_for_you: 'The SSC is now free for you!',
  apply_as_affiliate: 'Apply now as an affiliate',
  nice_that_you_use_ssc: "It's great that you're using the SSC!",
  to_the_pilot_training: 'Click here for pilot training',
  default_price: 'Standard price',
  price: 'Price',
  money_saved: 'Saved',
  your_referral_code: 'Your referral code',
  referral_code_inactive: 'Your referral code is inactive',
  referrer_status: 'Referrer status',
  here_you_can_edit_your_subscription: 'You can manage your subscription here:',
  edit_subscription: 'Manage subscription',
  you_are_using_ssc_without_subscription:
    'You are currently using the SSC without a subscription.',
  subscription: 'Subscription',
  progress: 'Progress',
  settings: 'Settings',
  edit_template: 'Edit message',
  select_ssc_template: 'Select template',
  confirm_delete_template:
    'Are you sure you want to delete this message template?',
  insert_placeholder: 'Insert placeholder',
  new_template: 'New template',
  onboarding_video_overview: 'Onboarding video overview',
  onboarding: 'Onboarding',
  reset_onboarding: 'Reset onboarding',
  confirm_reset: 'Confirm reset',
  reset: 'Reset',
  welcome: 'welcome',
  pilot_training: 'Pilot Training',
  module: 'Module',
  messages: 'Messages',
  content: 'Content',
  comments: 'Comments',
  sell: 'Selling',
  certificat: 'Certificate',
  coming_soon: 'Coming Soon',
  this_section_is_under_construction:
    'This section is still under construction, check back later',
  loading: 'Loading',
  ssc_training: 'SSC Training',
  use_ssc_to_unlock_training:
    "Keep using the SSC and you'll soon be able to train here!",
  training_program: 'training programs',
  linkedin: 'LinkedIn',
  dashboard: 'Dashboard',
  chat_cockpit: 'Chat Cockpit',
  templates: 'Templates',
  community: 'Community',
  focus_mode: 'Focus mode',
  inmail_not_supported:
    'We do not currently have a chat with this person. Please categorize the person as a potential customer and start a chat with them in focus mode.',
  hi_im_ava: "Hi, I'm Ava",
  ava_greeting:
    "I am Ava, your personal assistant. I will help you make the most of your time. I'll guide you through the setup process and be there for you during your time in the Social Selling Cockpit. All you have to do is click on me and I'll be right there for you!",
  starting_soon: "It's about to start!",
  we_recomment_linkedin_premium:
    'We recommend that you use LinkedIn Premium to make your use of the SSC as smooth and efficient as possible.',
  i_dont_use_external_tools:
    "I don't use any external tools, bots or extensions with LinkedIn except the SSC",
  im_ready_to_find_customers: 'I am ready to win customers',
  linkedin_detecting_warning:
    'LinkedIn has a strict detection of bots and external tools. The SSC is not a bot and only helps you to optimize your workflow, but it can still happen that LinkedIn soft-bans us, i.e. blocks access for a period of time. We recommend that you buy LinkedIn Premium, as this will then no longer happen, but you can also use the SSC without LinkedIn Premium.',
  ssc_now_setup: 'The Social Selling Cockpit is now set up',
  setup_finished: 'Setup completed',
  terms_of_use: 'Terms of use',
  i_accept_tos: 'I accept the %%terms of use%%',
  i_accept_data_privacy: 'I accept the %%Privacy Policy%%',
  you_cant_use_without_accepting_tos:
    'Please agree to the terms of use and the privacy policy, otherwise you will not be able to use the software.',
  tell_us_about_yourself: 'Tell us a bit about yourself!',
  ava_hint_setup_survey:
    'To maximize the efficiency of our cockpit, we need your feedback! 📝 Let us know what you would like to use the SSC for. This will help us to help you better and further improve your Social Selling Cockpit.',
  hiding_already_known_profiles: 'Already known profiles are hidden',
  reposts: 'Reposts',
  add_audience_holder: 'Add audience holder',
  remove_audience_holder: 'Remove audience holder',
  mark_profile: 'Mark profile',
  unmark_profile: 'Unmark profile',
  pipe_meet_name: 'Get to know',
  pipe_meet_description:
    'The first pipeline. Here you can meet new people and exchange ideas with them.',
  welcome_and_establish_relation: 'Greeting and relationship building',
  welcome_and_establish_relation_description:
    "This is about getting to know the person and building an authentic relationship. You don't want to sell here yet.",
  meeting_pitch: 'Meeting Pitch',
  meeting_pitch_description:
    "You've already gotten to know each other in a few messages. Since this works even better in a conversation, invite the person to a meeting now.",
  waiting_for_pitch_meeting: 'Waiting for an answer',
  waiting_for_pitch_meeting_description:
    'You have sent the meeting pitch. Now you have to wait for an answer.',
  calendar_link: 'Calendar link',
  calendar_link_description:
    'The person has agreed to a meeting. Now you send them a calendar link (e.g. Calendly) and they can enter the appointment directly in your calendar.',
  meeting_accepted: 'Discussion agreed',
  meeting_accepted_description:
    'Has the call already been booked? If so, enter it here now. The Social Selling Cockpit will then ask you the next day how it went.',
  when_is_the_meeting: 'When does the interview take place?',
  waiting_for_meeting: 'Waiting for the meeting',
  waiting_for_meeting_description:
    'The meeting is booked. Now you just have to wait until the meeting takes place.',
  meeting_done: 'Conversation held',
  meeting_done_description:
    'And how was the interview? Did you learn anything? Did you get on well?',
  how_was_meeting_result: 'What was the outcome of the conversation?',
  strategy_call_scheduled: 'Strategy meeting agreed',
  you_scheduled_a_strategy_call: 'You have agreed the strategy meeting.',
  no_match_explanation:
    "The contact is simply not the right customer for you. Either because your offer doesn't fit or because it doesn't match personally. If it's just not big enough yet, the 'Wrong time' option may be the better one.",
  wrong_time: 'Wrong time',
  canceled: 'Canceled',
  did_not_show_up: 'Did not show up',
  wrong_time_explanation:
    "It's not the right time for a collaboration right now.",
  did_not_show_up_explanation:
    'The contact did not show up for the meeting. Maybe something came up.',
  thanks_for_the_meeting_and_homework: 'Thanks for the interview + homework',
  thanks_for_the_meeting_and_homework_description:
    'You have arranged a strategy meeting. Thank the person for the last meeting and send them material or homework if necessary.',
  prepare_for_strategy_meeting:
    'The interview is booked and registered. Prepare for the interview now.',
  when_is_the_strategy_meeting: 'When does the strategy meeting take place?',
  waiting_for_strategy_meeting: 'Waiting for the strategy meeting',
  waiting_for_strategy_meeting_description:
    'The strategy meeting is booked. Now you just have to wait until the meeting takes place.',
  strategy_meeting_done: 'Strategy meeting held',
  strategy_meeting_done_description: 'So, sold?',
  messages_after_no_show: 'Message after non-appearance',
  messages_after_no_show_description:
    'The person did not show up for the meeting. Write them a message to ask what happened.',
  waiting_for_response: 'Waiting for an answer',
  waiting_for_response_to_no_show_message_description:
    'You have asked why the person did not show up for the meeting. Now you have to wait for an answer.',
  where_do_we_go_from_here: 'What happens now?',
  you_got_no_answer_what_now: 'You have received an answer. What happens now?',
  new_appointment: 'New date',
  new_appointment_explanation:
    'You are ready to give the contact a second chance. The next step is to make a new appointment.',
  make_new_appointment: 'Arrange a new appointment',
  make_new_appointment_description:
    'You have decided that the customer should make a new appointment. Send them your calendar link again (e.g. Calendly).',
  message_after_wrong_time: 'Message after the wrong time',
  message_after_wrong_time_description:
    "The person has said that it is not suitable at the moment. Send them a message now to ask when it's more convenient.",
  time_to_retry: 'Time to follow up',
  this_is_the_wrong_time:
    "You've realized that it's the wrong time. Now we're just about to decide when we'll get in touch again.",
  when_do_you_want_to_message_again:
    'When should we contact you again? (Normally 1 month later)',
  waiting_for_first_followup: 'Waiting for the first follow-up',
  we_wait_for_first_followup:
    'We are waiting for the first follow-up date to be reached.',
  first_followup: 'First follow-up',
  first_followup_description:
    'We have reached the first follow-up date. Now we have to get in touch again.',
  first_followup_sent_waiting_for_response:
    'We have sent the first follow-up message. Now we have to wait for an answer.',
  response_to_first_followup: 'Response to the first follow-up',
  we_got_a_response_to_first_followup:
    'We have received a response to the first follow-up. Now we have to decide what to do next.',
  right_time: 'The right time',
  the_time_is_right_we_continue:
    'The time is now right and you can continue with the sales process.',
  second_follow_up: 'Second follow-up',
  we_reached_second_follow_up:
    'We have reached the second follow-up date. Now we have to get in touch again.',
  waiting_for_second_followup: 'Waiting for the second follow-up',
  we_wait_for_second_followup:
    'We are waiting for the second follow-up date to be reached.',
  we_send_second_followup:
    'We have sent the second follow-up message. Now we have to wait for an answer.',
  response_to_second_followup: 'Response to the second follow-up',
  we_got_a_response_to_second_followup:
    'We have received a response to the second follow-up. Now we have to decide what to do next.',
  waiting_for_third_followup: 'Waiting for the third follow-up',
  we_wait_for_third_followup:
    'We are waiting for the third follow-up date to be reached.',
  third_followup: 'Dritter Follow-Up',
  we_reached_third_followup:
    'We have reached the third follow-up date. Now we have to get in touch again.',
  waiting_for_third_followup_response:
    'We have sent the third follow-up message. Now we have to wait for an answer.',
  response_to_third_followup: 'Response to the third follow-up',
  we_tried_three_times_did_it_work:
    "We have now followed up three times. Either the customer has time now or it's not a match. The Social Selling Cockpit will not follow up any further.",
  you_got_a_customer: 'The Konakt is now your customer. Hooray!',
  lift_off: 'Lift off now!',
  notes: 'Notes',
  focused: 'Focused',
  archived: 'Archived',
  full_focus_mode_title: 'FULL FOCUS: The entire focus mode in one go',
  recommended: 'Recommendation!',
  this_mode_has_no_focus:
    'This mode has no focus, all tasks are displayed here.',
  tidy_network: 'Clean up the network',
  categorize_your_contacts: 'Categorize your contacts to keep an overview.',
  this_mode_focuses_on_categorizes_existing_contacts:
    'The focus when cleaning up your network is on categorizing your contacts and connections.',
  strengthen_relations: 'Expand relationships',
  chat_with_contacts_and_strengthen_relations:
    'Chat with your existing contacts and build relationships.',
  the_most_important_part_of_social_selling_are_relations:
    'The most important thing in social selling is to be authentic and build relationships. Chat with your contacts and build a relationship with them.',
  find_new_network_participants: 'Find new contacts for your network',
  to_gain_customers_you_need_to_expand_your_network:
    'To keep gaining new customers, you should always expand your network to get in touch with new people. Search for potential customers on LinkedIn and then take a closer look at them before you network with them',
  restart_due_to_error: (
    <>
      Due to the error, the Social Selling Cockpit unfortunately has to land
      once. Please restart the SSC to continue.
      <br />
      Have a good flight!
    </>
  ),
  only_solutions_quote: (
    <>
      There are no problems in aviation, only{' '}
      <span style={{ color: '#0062ff' }}>solutions</span>.
    </>
  ),
  maximum_comment_reached: (
    <>
      <strong>Look at that!</strong> You have reached the recommended number of
      comments for today! <br />
      If you still want to write more comments, you are welcome to do so.
      otherwise continue to the next step.
    </>
  ),
  connect_as_potential_customer: (
    <>
      Connect as{' '}
      <span
        style={{
          fontWeight: 'bold',
          display: 'inline',
          wordBreak: 'break-word'
        }}>
        potential Customer
      </span>
    </>
  ),
  connect_as_audience_holder: (
    <>
      Connect as{' '}
      <span
        style={{
          fontWeight: 'bold',
          display: 'inline',
          wordBreak: 'break-word'
        }}>
        Audience
        <wbr />
        holder
      </span>
    </>
  ),
  maximum_marked_contacts_reached: (
    <>
      <strong>Looks good!</strong> You have reached the maximum number of
      potential contacts for today! <br />
      If you would like to sort through the profiles again, you are welcome to
      do so. otherwise continue to the next step.
    </>
  ),
  wrong_linkedin_account_explanation: (
    <>
      Please log in with your linked LinkedIn account!
      <br />
      <br />
      The Social Selling Cockpit currently only supports one LinkedIn account.
      <br />
      Please log in with your original LinkedIn account.
    </>
  ),
  customer_acquisition_process: <>Customer&shy;acquisition&shy;process</>,
  f2u_free_months_explanation: ({ price }: { price: number | string }) => (
    <>
      With the code you can give your customers a few free months.{' '}
      <b>However, you have to pay for these free months!</b> However, you only
      pay only 50% of the full price of the software, i.e. {price}.
    </>
  ),
  create_f2u_payment_warning: ({ price }: { price: number | string }) => (
    <>
      If you create this code, <b>you</b> will pay 50% of the cost for each
      month! This means that for every customer who uses this code, you pay you
      pay {price}. Are you sure you want to create this code? Be be especially
      careful not to share the code publicly!
    </>
  ),
  confirm_delete_coupon: ({ code }: { code?: string }) => (
    <>
      Are you sure you want to delete the code "
      <b>{code ?? 'NO CODE SELECTED'}</b>" ?
    </>
  ),
  you_have_maxed_out_referring: (
    <>
      You have invited the maximum number of friends and are now an ambassador!
      Congratulations!
      <br />
      This means that the SSC is now free for you! <br />
      If you want to continue, you can now apply as an affiliate and even earn
      commissions!
    </>
  ),
  congratulations_you_are_a_basic_user: (
    <>
      Since you have been using the SSC for 10 days now, you are a basic user!
      <br />
      From now on you have activated the pilot training and can receive your SSC
      pilot certificate.
      <br />
      When you have mastered your certificate, you can upgrade to advanced user
      level.
    </>
  ),
  you_the_ssc_further_to_unlock_training: (
    <>
      Continue to use the Social Selling Cockpit so that you can unlock the
      pilot training.
      <br />
      Then you can also receive your SSC pilot certificate.
    </>
  ),
  confirm_reset_description: (
    <>
      {' '}
      Do you really want to reset all the onboarding videos that are displayed
      during onboarding? <br />
      This means, for example, that the next time you open the dashboard, you
      will you will see the onboarding video again. <br />
      You can also find all onboarding videos under "Onboarding video overview".
    </>
  ),
  you_need_to_accept_tos: (
    <>
      To use the <strong>Social Selling Cockpit</strong> you must accept the
      Terms of Use and the Privacy Policy.
    </>
  ),
  ava_hint_setup_term: (
    <span>
      <strong>Hey, welcome aboard the Social Selling Cockpit! ✈</strong>
      <br />
      Before we can lift off, please take a quick look at the terms of use. Yes,
      I know it sounds like bureaucratic small print, but it's important. Please
      take a moment, and if everything is clear, we're ready to go. we're ready
      to go
    </span>
  ),
  ava_hint_manual: (
    <span>
      <strong>Welcome to manual mode!</strong> <br />
      Here you can write more precisely with individual contacts in the Chat
      Cockpit edit your message templates, maintain your personal contacts or
      post contacts or write posts and comments on LinkedIn. The Manual mode
      does not guide you from task to task like Focus mode task, but it is very
      good if you want to concentrate on individual contacts. contacts.
    </span>
  ),
  ava_hint_focus_expand_network: (
    <>
      {/* <AvaYoutubeVideo videoID="OhAVGztMuQI" /> */}
      <span>
        This step is not about perfection, but about{' '}
        <strong style={{ marginLeft: '.25em' }}>speed.</strong>
      </span>
      <span style={{ marginTop: '8px' }}>
        Does the profile picture meet your expectations? Does the slogan of the
        profile suggest that this could be a potential customer? could be a
        potential customer?
      </span>
      <span style={{ marginTop: '8px' }}>
        If you are unsure, simply mark the profile. We will take a closer take a
        closer look.
      </span>
      <span style={{ marginTop: '8px' }}>
        Your goal should be to tag 50 potential customers in less than three
        minutes. in less than three minutes. Can you master this challenge?
      </span>
    </>
  ),
  ava_hint_focus_categorize: (
    <>
      <span>
        What's the point of having a huge network if you don't know which of
        your contacts is a potential customer, a networker or just an
        acquaintance? is?
      </span>
      <span style={{ marginTop: '8px' }}>
        Especially if you have more than 500 contacts. And when do you with them
        again?
      </span>
      <span style={{ marginTop: '8px' }}>
        The heart of the Social Selling Cockpit is the categorization of your
        contacts. Only when it is clear with whom it is worth continuing to
        write or enter into dialog, your customer acquisition will be clear and
        effective.
      </span>
      <span style={{ marginTop: '8px' }}>
        Don't skip this step and take the time to work thoroughly here. work
        here.
      </span>
      <span style={{ marginTop: '8px' }}>
        Your sales will thank you for it.
      </span>
    </>
  ),
  ava_hint_focus_chat: (
    <>
      <span>
        What is important when chatting? How do you make appointments and how do
        you how do you get the customer in the mood for a collaboration at this
        stage?
      </span>
      <span style={{ marginTop: '8px' }}>By following these three steps:</span>
      <ul style={{ listStyle: 'numeric', paddingInlineStart: 2 }}>
        <li>
          Keep in mind that you are talking to a human being - not a computer.
        </li>
        <li>
          Build a relationship. Give your conversation partner space and time,
          to open up.
        </li>
        <li>
          Have an interesting conversation. Do not bore your your conversation
          partner with monotonous questions and answers.
        </li>
      </ul>
      <span style={{ marginTop: '8px' }}>
        And the pro tip: develop curiosity, fun and a sense of adventure. You're
        about to lift off and make your life better too. your life.
      </span>
      <span style={{ marginTop: '8px' }}>
        Wouldn't it be ideal if you could enjoy it too?
      </span>
    </>
  ),
  ava_hint_focus_connect_profile: (
    <>
      <span>
        That's right! Not every LinkedIn user fits as your customer, and that's
        okay. If you're still unsure who your ideal customer on LinkedIn might
        be try to identify it based on these key data:
      </span>
      <ul style={{ listStyleType: '-', paddingInlineStart: 2 }}>
        <li>What industry does your ideal customer work in?</li>
        <li>What position does he/she hold?</li>
        <li>How long has he/she been in this position?</li>
      </ul>
      <span style={{ marginTop: '8px' }}>
        <strong style={{ marginRight: '.25em' }}>IMPORTANT:</strong> Your ideal
        customer on LinkedIn should be regularly active on the platform. This
        does not necessarily mean that he/she posts posts, but he/she should
        respond to or comment on posts. comment on posts.
      </span>
      <span style={{ marginTop: '8px' }}>
        <strong style={{ marginRight: '.25em' }}>Tip:</strong> With a little
        practice you can often tell within seconds from the profile picture,
        cover picture, slogan and the profile description to recognize whether
        someone could be a potential customer. could be a potential customer.
      </span>
      <span style={{ marginTop: '8px' }}>Trust your gut feeling.</span>
    </>
  ),
  ava_hint_focus_question: (
    <>
      <span>
        The Social Selling Cockpit would like to support you in making your
        customer acquisition process successfully.
      </span>
      <span style={{ marginTop: '8px' }}>
        To ensure this, we need some information, e.g. how the relationship with
        your customer or how your meetings went. have gone.
      </span>
      <span style={{ marginTop: '8px' }}>
        This information will help us to remind you to either follow up or take
        the next steps in the process.
      </span>
      <span style={{ marginTop: '8px' }}>
        This is how we ensure that you never forget to contact your potential
        customer
      </span>
      <span style={{ marginTop: '8px' }}>
        Stay tuned as long as you use the SSC
      </span>
    </>
  ),
  ava_hint_focus_audience_holder: (
    <>
      <span>
        Now it's getting exciting! Your next task is to select at least 5 target
        group holders.
      </span>
      <span
        style={{ fontWeight: 'bold', marginTop: '16px', marginBottom: '8px' }}>
        What is the best way to do this?
      </span>
      <ul style={{ listStyleType: '-', paddingInlineStart: 2 }}>
        <li>
          Go on a journey of discovery through your LinkedIn newsfeed. Maybe
          you'll find one or two suitable candidates.
        </li>
        <li>
          Use the search function to search specifically for potential target
          group holders.
        </li>
        <li>
          Or try a keyword search! Enter a relevant keyword such as "Social
          Selling" and click on the "Posts" tab.
        </li>
      </ul>
      <span style={{ marginTop: '8px' }}>
        <strong style={{ marginRight: '.25em' }}>
          A little tip from co-pilot Ava:
        </strong>{' '}
        A good audience holder has an average of over 30 reactions, at least 5
        comments and posts relevant posts at least 2-3 times a week. relevant
        posts.
      </span>
    </>
  ),
  you_referred_x_users: ({
    count,
    max
  }: {
    count: string | number;
    max: string | number;
  }) => (
    <>
      You have recommended the SSC {count} to {max} users.
    </>
  ),

  language: 'Language',
  you_need_to_restart_to_update_language:
    'You need to restart the SSC to update the language.',
  save_and_restart: 'Save and restart',

  welcome_message: 'Welcome message',
  welcome_message_desciption:
    'This is the first message that you send a new contact. It is best if you introduce yourself real quick and ask a question to start a conversation.',
  relation_building_message: 'Relation building message',
  relation_building_message_description:
    'This is the time to build meaningful relations. It is always important to engage with the responses to keep the conversation interesting.',

  homework_for_strategy_call: 'Homework for strategy call',
  homework_for_strategy_call_description:
    'You just had your first talk with with your contact and you just scheduled a schedule call. Now send them a message with a small homework so that they can prepare for your next meeting.',

  calendar_link_no_show: 'Calendar link no show',
  calendar_link_no_show_description:
    'The contact did not show up to your first meeting. Now you want to give them a second chance and send them the calendar link again.',
  follow_up: 'Follow-Up',
  follow_up_description:
    'The contact did not answer your last message. Maybe the message went unnoticed, find out with this message.',
  uncategorized_template_description:
    'This contact is not yet categorized. Write them a message to get to know them better and to introduce yourself them.',

  about_ssc: 'About the Social Selling Cockpit',
  quit: 'Quit',
  undo: 'Undo',
  redo: 'Redo',
  cut: 'Cut',
  copy: 'Copy',
  paste: 'Paste',
  paste_and_match_style: 'Paste and match style',
  select_all: 'Select all',
  fullscreen: 'Fullscreen',
  minimize: 'Minimize',
  to_front: 'Move to front',
  window: 'Window',
  help: 'Help',
  ssc_terminal: 'SSC-Terminal',
  website: 'Website',
  version: 'Version',
  support_email: 'Support Email',
  open_link_in_browser: 'Open link in browser',
  copy_link: 'Copy link',
  search_with_google: 'Search with google',
  view: 'View',
  devtools: 'Devtools',
  debugger: 'Debugger',
  edit: 'Edit',
  advanced_options: 'Advanced options',
  web_auth: 'Web Auth',
  web_auth_description:
    'If you use a hardware security key, facial recognition or fingerprint scanning for your linkedin login, this settings needs to be enabled.',
  account_locked_payment_required:
    'Your last payment was not successful. Please check your payment information and then log in again.',
  payment_missing: 'Payment missing',
  payment_missing_description:
    'There was a problem with your payment. Please check resubscribe to the SSC or contact us.',
  server_error: 'Server error',
  login: 'Login',
  request_code: 'Request code',
  max_discount_coupons_reached: 'Maximum amount of discount coupons reached',
  max_free_to_use_coupons_reached:
    'Maximum amount of Free To Use coupons reached',

  webview_overtaken: 'Webview in use',
  webview_overtaken_description:
    'We are currently loading some data from LinkedIn. Please wait a moment.',
  no_templates: 'No templates',
  no_tags: 'No tags',
  add_tag: 'Add tag',
  edit_tags: 'Edit tags',
  tags: 'Tags',
  tag_name: "Tag's name",
  tag_name_already_taken: 'This tag name is already taken',
  delete_tag: 'Delete tag',
  delete_tag_confirm:
    'Do you really want to delete the tag? When you delete this tag, it will be removed from every contact that has it applied. This actions can not be undone.',
  tag_deleted: 'Tag deleted',
  manage_tags: 'Manage tags',
  opening_network_page: 'Opening network page',
  waiting_for_network_page_load: 'Waiting for network page to load',
  getting_network_count: 'Getting network count',
  got_network_count: 'Loaded network count',
  scrolling_network_page: 'Scrolling current network page',
  scrolled_network_page: 'Scrolled current network page',
  scrolling_entire_network: 'Scanning entire network',
  scrolled_entire_network: 'Finished scanning entire network',
  scrolling_new_network: 'Searching for new network participants',
  open_chats_page: 'Open chats page',
  started_checking_chats: 'Checking chats',
  waiting_for_chat_to_load: 'Waiting for chat to load',
  chat_loaded: 'Chat loaded',
  scrolling_chat: 'Scrolling chats',
  checking_for_new_messages: 'Checking for new messages',
  finished_current_chat: 'Finished loading current chat',
  loading_next_chat_messages: 'Loading next chat messages',
  finished_checking_for_new_messages: 'Finished checking for new messages',
  chat_popup_loaded: 'Chat popup loaded',
  opening_profile_page: 'Opening profile page',
  checking_profile_distance: 'Checking connection status with profile',
  opening_message_popup: 'Opening message popuop',
  category: 'Category',
  all: 'All',
  no_filter: 'No filter',
  skipped: 'Skipped',
  not_skipped: 'Not skipped',
  pipeline_step: 'Pipeline step',
  unanswered_messages: 'Unanswered messages',
  no_unanswered_messages: 'No unanswered messages',
  unread_messages: 'Unread messages',
  no_unread_messages: 'No unread messages',
  n_tags_selected: (n: number) => `${n} tags selected`,
  open_filter: 'Open filter',
  close_filter: 'Close filter',
  error_linkedin_loading: 'Error loading data from LinkedIn',
  explanation_webview_loading_error:
    'The Social Selling Cockpit normaly accesses data directly from the LinkedIn website to make sure that our users cannot be detected and to protect your account.',
  webview_loading_not_working_explanation:
    'Sadly this method does not seem to currently work. This can be due to a slow internet connection, a slow PC or a changed structure of LinkedIn we have yet to adapt. Please try to load again or contact us.',
  retry_load: 'Retry loading data',
  general: 'General',
  third_party_licenses: 'Third party licenses',
  loading_settings: 'Settings for loading data',
  safe_loading: 'Safe data loading',
  safe_loading_description:
    'Through intercepting the data from the LinkedIn website, it is impossible for LinkedIn to detect the usage of the SSC. This is the safest way to use the SSC and protect you LinkedIn Account.',
  api_loading: 'Data loading via LinkedIn API',
  api_loading_description:
    "Data is directly requested from LinkedIn. This process is faster but also involves a higher risk. If you use this option we can't guarantee you, that your LinkedIn Account won't be banned.",
  loading_network_participant: (name: string) =>
    `Loading network participant ${name}`,
  finished_loading_network: 'Network loaded successfully',
  loading_messages_with: (name: string) => `Loading messages with ${name}`,
  loading_message_from_chat_at_date: (
    name: string,
    date: Date,
    number?: number
  ) =>
    `Loading message ${
      number ? `#${number} ` : ''
    }from chat with ${name}, send at ${new Date(date).toLocaleDateString(
      LanguageManager.getInstance().getLanguage(),
      {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }
    )}`,
  finished_loading_messages: 'Messages loaded successfully',
  error_while_loading_linkedin_data:
    'There was a problem while loading data from LinkedIn.',
  error_while_loading_categorization_data_choice:
    'You can skip the person and try again later or delete them and not use them inside the SSC.',
  count: 'Count',
  time: 'Time',
  next: 'Next',
  options: 'Options',
  task_options_explanation:
    'How many iteration do you want to do or how long do you want to do this task?',
  question: 'Question',
  max_reached: 'Maximum reached',
  goal_reached: 'You reached your goal. Well done!',
  one_more: 'One more',
  you_reached_max_time: 'Your time is up. Well done!',
  one_more_minute: 'One more minute',
  error_while_saving_audience_holder_data:
    'There was an error while saving the audience holder data.',
  max_connections_explanation:
    'LinkedIn only allows you to send a limited amount of connection request. We limited your maximum amount of connections to protect you from getting soft-banned by LinkedIn.',
  build_a_personal_relation: 'Build a personal relation with your contacts.',
  write_post: 'Write a post',
  write_post_description:
    'You can greatly increase your outreach on LinkedIn with posts. Write a post that appeals to your target group and post it on LinkedIn.',
  write_post_time_explanation:
    'How much time do you want to dedicate to write a post?',
  open_linkedin: 'Open LinkedIn',
  contea_description:
    'Contea is an AI tool to instantly create authentic text for your LinkedIn Posts',
  person: 'Person',
  confirm_relation_established: (name) =>
    `Have you established a relation with ${name}?`,
  close_chat: 'Close chat',
  audience_holder_saved: (name: string) => `Audience holder ${name} saved`,
  audience_holder_removed: (name: string) => `Audience holder ${name} removed`,
  audience_holder_not_saved: 'Could not save audience holder',
  newest_version_installed: 'Newest version installed',
  error_reaching_linkedin: 'Error reaching LinkedIn',
  linkedin_error_restart_or_logout:
    'We were not able to load LinkedIn. This can have two reasons. Either you currently have no internet or you got logged out of Linkedin.\nPlease check if you are currently connected to the internet. After that either restart the SSC or log out of LinkedIn.',
  restart: 'Restart',
  confirm_delete_mode: (modeTitle: string) => `Delete focus mode ${modeTitle}`,
  confirm_delete_mode_description:
    "Are you sure you want to delete this mode? This can't be undone.",
  save_as_preset: 'Save as preset',
  save_as_preset_description: 'Save this mode as a preset for later use.',
  description: 'Description',
  confirm: 'Confirm',
  checking_login_status: 'Checking login status',
  unknown_server_error_description:
    'An unknown error occurred on the server. Please try again later.',
  unknown_error_description:
    'An unknown error occurred. Please try again later.',
  invalid_linkedin_account: 'Invalid LinkedIn account',

  ssc_login_linkedin_account_missmatch: 'LinkedIn account error',
  ssc_login_linkedin_account_missmatch_explanation:
    'You are logged in to LinkedIn on this device with a different account than the one that is linked to your SSC account. You need to log out of LinkedIn or log in with a different SSC Account.',
  payment_required: 'Payment required',
  invalid_refresh_token: 'Invalid login session',
  invalid_refresh_token_description:
    'Your login session has expired. Please log in again.',
  payment_failed: 'Payment failed',
  payment_warning_description:
    'There was a problem with your payment. Please check your payment information and try again. For now you can still continue to use the SSC.',
  payment_error_description:
    'There was a problem with your payment. Please check your payment information and try again. To continue using the SSC, please resubscribe.',
  subscription_cancelled: 'Subscription cancelled',
  subscription_cancelled_description:
    'Your subscription has been cancelled. Please resubscribe to the SSC to continue using it.',
  subscription_expired: 'Subscription expired',
  subscription_expired_description:
    'Your subscription has expired. Please resubscribe to the SSC to continue using it.',
  subscription_paused: 'Subscription paused',
  subscription_paused_description:
    'Your subscription has been paused. Please resubscribe to the SSC continue using it.',
  no_subscription: 'No subscription found',
  no_subscription_description:
    'We could not find a subscription for your account. Please resubscribe to the SSC to continue using it.',

  resubscribe: 'Resubscribe',
  subscribe: 'Subscribe',
  linkedin_logout: 'Logout of LinkedIn',
  ssc_logout: 'Logout of the SSC',
  back_to_login: 'Back to login',

  issue_with_audience_holders:
    'We encoutered an issue with your audience holders',
  trying_to_fix_the_issue:
    'We are trying to fix the problem, please wait a moment.',
  following_audience_holders_are_broken:
    'With the following audience holders we encountered problems:',
  what_do_you_want_to_do_with_the_broken_audience_holders:
    'How do you want to proceed with the broken audience holders?',
  ignore_this_time: 'Ignore this time',
  delete_these_audience_holders: 'Delete these audience holders',
  save_profile: 'Save profile',
  no_connection: 'No connection',
  no_connection_desc:
    'We could not establish a connection to the SSC server. Please check your internet connection and try again.',
  server_unavailable: 'Server unavailable',
  server_unavailable_desc:
    'The SSC server is currently unavailable. We are working to resolve the issue. Please try again later.',
  marked: 'Marked',

  new_version_available: 'New version available',
  update_required_explanation:
    'There are some big changes in the new version. Please update to the newest version to continue using the SSC.',
  download_new_version: 'Download new version',

  confirm_clear_filter: 'Do you want to clear the filter?',
  clear_filter: 'Reset filter',

  cant_use_filters_with_linkedin_chat:
    'You can not use filters with LinkedIn chat',
  normal: 'Normal',

  max_reactions_for_message: 'Maximum reactions for message reached',
  load_posts: 'Advanced loading',
  loading_posts: 'Loading posts',
  load_posts_description:
    'To track the interactions of your contacts with your posts and content, we need to load your latest posts. The process can take a few minutes, especially if you get a lot of reactions or comments on your posts, this can take longer. With the data we get from this, we can help you to prioritize potential customers, who engange with your content, making them more likely to be interested in your topic and more likely to become your customers.',
  confirm_leave_load_posts:
    'Are you sure, that you want to quit the loading of the posts? If you leave now, you need the start again from the beginning for the next load.',
  post_loading_finished: 'Post loading finished',
  error_while_loading_posts: 'Error while loading posts',
  error_while_loading_posts_description:
    'During the loading of posts an error occured. We are looking into the issue and will try to resolve it as soon as possible. For now you can try again or go back to the manual mode.',
  try_again: 'Try again',
  noInstances: (taskname: string) => `${taskname} - nothing to do`,
  noInstancesDescription:
    'There is nothing to do in this task right now. Just continue and check back later.',
  noMoreInstances: (taskname: string) => `${taskname} - you're done`,
  noMoreInstancesDescription:
    'There are no more contacts, so this task is now already done.',
  comments_posted: 'Comments posted',
  connection_requests_sent: 'Connection requests sent',
  messages_sent: 'Messages sent',
  profiles_categorized: 'Profiles categorized',
  profiles_marked: 'Profiles marked',
  your_achivements: 'Your achievements',
  your_focus_mode_progress: 'Your focus mode progress',
  stats: 'Stats',
  last_focus_mode_stats: 'Last focus mode',
  today_stats: 'Today',
  historic_stats: 'Historic stats',
  last_week: 'Last week',
  week: 'Week',
  last_month: 'Last month',
  month: 'Month',
  last_quarter: 'Last quarter',
  quarter: 'Quarter',
  last_year: 'Last year',
  year: 'Year',
  see_my_stats: 'Exit focus mode and see my stats',
  goals: 'Goals',
  skip_all_tutorials: 'Skip all tutorials',
  first_time_getting_data_might_take_a_while:
    'On the first start we need to load data from LinkedIn. This might take a while. Please have patience. For any subsequent starts this will be much faster.',
  start_focus_mode: 'Start focus mode',
  pause_tutorial_continue_in_manual_mode:
    'Pause tutorial and continue in manual mode',
  error_loading_video: 'Error loading video',
  restart_ssc: 'Restart SSC',
  periodic_restart_ssc_explanation:
    'To ensure that the SSC runs smoothly, you should restart it periodically. We detected that is has been running since yesterday, please restart it now.',
  remind_in_2_min: 'Remind me in 2 minutes',
  full_version_released: 'Social Selling Cockpit 1.0 is out!',
  full_version_released_description:
    'The beta phase is over and Social Selling Cockpit 1.0 is here! We have new features and many small improvements and fixes. Have fun!',
  template_name_already_used:
    'The name for the template is already used for another template.',
  apply_zoom_settings: 'Confirm zoom setting',
  confirm_zoom_settings: 'Do you want to apply the zoom settings?',
  home: 'Home',
  scrolling_profile_visits: 'Scanning profile visitors',
  loading_more_profile_visits: 'Loading more profile visitors',
  usage_time: 'Usage time',
  customer_count: 'Customer count',
  personal_contact_count: 'Personal contact count',
  no_match_count: 'No match contact count',
  audience_holder_count: 'Audience holder count',
  marked_count: 'Marked contact count',
  potential_customer_count: 'Potential customer count',
  build_relationship_count: 'Contacts in relation building step',
  meeting_pitch_count: 'Contacts in meeting pitch step',
  calendar_link_count: 'Contacts in calendar link step',
  meeting_booked_count: 'Contacts in meeting booked step',
  activities: 'Activities',
  categories: 'Categories',
  pipelines: 'Pipelines',
  there_are_no_stats_for_today_or_future:
    'No stats available for today or future',
  response_rate: 'Response rate',
  this_template_has_a_response_rate_of: (rate: number) =>
    `This template has a response rate of ${rate}%`,
  not_enough_message_with_template_sent_for_response_rate_calculation:
    "There aren't enough messages sent with this template to calculate a response rate.",
  load_entire_network: 'Load entire network',
  confirm_leave_load_entire_network:
    'Are you sure you want to quit the loading of the entire network? If you leave now, you need the start again from the beginning for the next load.',
  load_network_finished: 'Network loading finished',
  error_while_loading_network: 'Error while loading network',
  error_while_loading_network_description:
    'During the loading of the network an error occured. We are looking into the issue and will try to resolve it as soon as possible. For now you can try again or go back to the manual mode.',
  load_entire_network_description:
    'Here you can load your entire network. We normally only save your somewhat recent contacts to minimize loading times. If you do want to have your entire network loaded in the SSC, you can do that here. CAUTION: Loading your entire network can take a long time, espacially if you have a large network.',
  load_network: 'Load network',
  usage_warning: 'Usage warning',
  continue_anyways: 'Continue anyways',
  usage_warning_profile_visits: (count: number) =>
    `You have already visited ${count} profiles today. Based on your set daily LinkedIn usage we recommend to not visit anymore profiles today as it may result in an account suspension.`,
  usage_warning_connect: (count: number) =>
    `You have already connected yourself with ${count} profiles today. Based on your set daily LinkedIn usage we recommend to not send anymore connection requests today as it may result in an account suspension.`,
  usage_warning_message: (count: number) =>
    `You have already sent ${count} messages today. Based on your set daily LinkedIn usage we recommend to not send anymore messages today as it may result in an account suspension.`,
  usage_low: 'Low',
  usage_low_description:
    "You've only used LinkedIn from time to time and only for a short time.",
  usage_medium: 'Medium',
  usage_medium_description:
    'You use LinkedIn regularly, but not every day and not more than 1-2h per day.',
  usage_high: 'High',
  usage_high_description:
    'You use LinkedIn daily and also several hours per day.',
  usage_fulltime: 'Fulltime',
  usage_fulltime_description: 'You use LinkedIn all day, almost every day.',
  usage_unlimited: 'Unlimited',
  usage_unlimited_description:
    "When you don't want to receive any preventive warnings to protect your LinkedIn account, you can choose this option.",
  linkedin_usage: 'LinkedIn usage',
  linkedin_usage_description:
    "Please choose how much you've used LinkedIn so far. Based on your selection, we will give you preventive warnings to protect your LinkedIn account.",
  you_can_change_your_selection_in_the_settings:
    'You can change your selection at any time in the settings.',
  restart_due_to_inactivity:
    'You have been inactive for a while. To protect your account, we closed the connection to LinkedIn. Since the connection is now closed, you need to restart the SSC, so that we can be sure that we have loaded the newest data without missing anything.'
} as LanguageObject;

export default lang_en;
