import React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  SvgIconProps,
  Tooltip,
  Typography
} from '@mui/material';
import { Bookmark, Close, Groups, Person, SkipNext } from '@mui/icons-material';
import { MoneyIcon, SpeakerIcon, TargetHitIcon } from 'ui-utils';
import { language } from '@/index';
import { ContactType } from '@common/types/enums';
import type { ContactFilter } from '@/data/DataServer/Contact';
import { Pipelines } from '@common/PipelineManager/Pipelines';
import TagFilter from '@/components/TagFilter/TagFilter';
import FilterMenuIcon from './FilterMenuIcon';

const GET_TYPES = (showMarked: boolean) =>
  [
    ...(showMarked
      ? ([
          {
            name: language.text.marked,
            type: ContactType.MARKED,
            icon: Bookmark,
            color: 'primary'
          }
        ] as const)
      : []),
    {
      name: language.text.pipeline,
      type: ContactType.POTENTIAL_CUSTOMER,
      icon: TargetHitIcon,
      color: 'primary'
    },
    {
      name: language.text.customer,
      type: ContactType.CUSTOMER,
      icon: MoneyIcon,
      color: 'success'
    },
    {
      name: language.text.audience_holder,
      type: ContactType.AUDIENCE_HOLDER,
      icon: SpeakerIcon,
      color: 'secondary'
    },
    {
      name: language.text.no_match,
      type: ContactType.NO_MATCH,
      icon: Close,
      color: 'error'
    },
    {
      name: language.text.personal_contact,
      type: ContactType.PERSONAL,
      icon: Person,
      color: 'tertiary'
    }
  ] satisfies {
    name: string;
    type: ContactType;
    icon?: React.FC<SvgIconProps>;
    color?: ButtonProps['color'];
  }[];

const LOCAL_STORAGE_KEY = 'contactListFilter';

type StorageValue = ContactFilter;

type Filters = 'type' | 'step' | 'tags' | 'skipped' | 'unanswered';

export interface ContactFilterProps {
  filter: ContactFilter;
  onChange: (filter: ContactFilter) => void;
  localStorageKey?: string;
  children?: React.ReactNode | React.ReactNode[];
  hide?: Filters[];
  disabled?: boolean;
  showMarked?: boolean;
}

export default function ContactFilter(props: ContactFilterProps) {
  const { filter } = props;
  const TYPES = React.useMemo(() => GET_TYPES(props.showMarked ?? false), []);

  // load advanced state from local storage on mount
  React.useEffect(() => {
    const stored = localStorage.getItem(
      props.localStorageKey ?? LOCAL_STORAGE_KEY
    );
    if (stored)
      try {
        const parsed = JSON.parse(stored) as StorageValue;
        props.onChange(parsed);
      } catch (_) {
        // ignore
      }
  }, []);

  // save advanced state to local storage on change
  const preventSaveOnLoad = React.useRef(true);
  React.useEffect(() => {
    if (preventSaveOnLoad.current) {
      preventSaveOnLoad.current = false;
      return;
    }
    localStorage.setItem(
      props.localStorageKey ?? LOCAL_STORAGE_KEY,
      JSON.stringify(filter)
    );
  }, [filter]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 1,
        flexWrap: 'wrap'
      }}>
      {/* category selector */}
      {!props.hide?.includes('type') && (
        <>
          {[
            {
              type: 'all',
              name: language.text.all,
              icon: Groups,
              color: undefined
            } as const,
            ...TYPES
          ].map((option) => {
            const selected =
              (props.disabled ?? false) ||
              (filter.values?.type ?? 'all') === option.type;
            return (
              <Tooltip
                key={option.type}
                title={<Typography>{option.name}</Typography>}>
                <span>
                  <Button
                    disabled={props.disabled}
                    variant={selected ? 'contained' : 'outlined'}
                    color={option.color ?? 'primary'}
                    sx={{
                      minWidth: 'unset',
                      ...(selected
                        ? {
                            p: 1
                          }
                        : { borderWidth: '2px !important', p: 0.75 })
                    }}
                    onClick={() => {
                      props.onChange({
                        ...filter,
                        values: {
                          ...filter.values,
                          // if the type is not POTENTIAL_CUSTOMER, reset the stepType and currentStep -> not applicable
                          ...(option.type !==
                            ContactType.POTENTIAL_CUSTOMER && {
                            stepType: undefined,
                            currentStep: undefined
                          }),
                          type: option.type === 'all' ? undefined : option.type
                        }
                      });
                    }}>
                    {
                      <option.icon
                        sx={{
                          verticalAlign: 'middle',
                          color: selected
                            ? undefined
                            : (theme) => theme.palette.text.primary
                        }}
                      />
                    }
                  </Button>
                </span>
              </Tooltip>
            );
          })}
        </>
      )}
      <Box flexGrow={1} />
      {/* pipeline step */}
      {!props.hide?.includes('step') && (
        <FilterMenuIcon
          disabled={props.disabled}
          closeOnSelect
          options={Pipelines.getCategorizationSteps().map((step) => ({
            value: step.id,
            label: step.name
          }))}
          current={filter.values?.currentStep ?? null}
          onChange={(value) => {
            const step = value === 'all' ? undefined : value;
            props.onChange({
              ...filter,
              values: {
                ...filter.values,
                ...(step && {
                  type: 'POTENTIAL_CUSTOMER'
                }),
                currentStep: step ?? undefined
              }
            });
          }}
        />
      )}
      {/* select tags */}
      {!props.hide?.includes('tags') && (
        <TagFilter
          disabled={props.disabled}
          include={filter.tags?.include ?? []}
          exclude={filter.tags?.exclude ?? []}
          onChange={({ include, exclude }) => {
            props.onChange({
              ...filter,
              tags: {
                include:
                  include.length === 0 ? undefined : include.map((t) => t.name),
                exclude:
                  exclude.length === 0 ? undefined : exclude.map((t) => t.name)
              }
            });
          }}
        />
      )}
      {/* is skipped */}
      {!props.hide?.includes('skipped') && (
        <FilterMenuIcon
          disabled={props.disabled}
          icon={<SkipNext />}
          closeOnSelect
          current={filter.values?.skipped ?? null}
          options={[
            {
              label: language.text.skipped,
              value: true
            },
            {
              label: language.text.not_skipped,
              value: false
            }
          ]}
          onChange={(value) => {
            props.onChange({
              ...filter,
              values: {
                ...filter.values,
                skipped: value ?? undefined
              }
            });
          }}
        />
      )}
      {/* has unanswered messages */}
      {!props.hide?.includes('unanswered') && (
        <FilterMenuIcon
          disabled={props.disabled}
          icon={<SkipNext />}
          closeOnSelect
          current={filter.has?.unansweredMessages ?? null}
          options={[
            {
              label: language.text.unanswered_messages,
              value: true
            },
            {
              label: language.text.no_unanswered_messages,
              value: false
            }
          ]}
          onChange={(value) => {
            props.onChange({
              ...filter,
              has: {
                ...filter.has,
                unansweredMessages: value ?? undefined
              }
            });
          }}
        />
      )}
      {props.children}
    </Box>
  );
}
