import React from 'react';
import { Box, BoxProps, Grid } from '@mui/material';

export interface ContactLayoutProps extends BoxProps {
  children: React.ReactNode;
}

export default function ContactLayout(props: ContactLayoutProps) {
  const { children, ...boxProps } = props;

  return (
    <Box
      {...boxProps}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        ...boxProps.sx
      }}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          marginTop: 0,
          backgroundColor: 'background.default',
          border: 0,
          boxSizing: 'border-box',
          p: (theme) => ({
            md: theme.spacing(0, 0),
            lg: theme.spacing(0, 0)
          })
        }}>
        <Grid
          container
          spacing={0}
          sx={{
            width: '100%',
            height: '100%',
            margin: 'auto'
          }}>
          <Grid
            item
            sm={12}
            sx={{
              maxHeight: '100%',
              overflowY: 'auto',
              p: (theme) => ({
                md: theme.spacing(0, 3, 0, 0),
                lg: theme.spacing(0, 3, 0, 0)
              })
            }}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
