import React from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import { SkipNext, ReplayOutlined } from '@mui/icons-material';
import { language } from '@/index';
import { ContactType } from '@common/types/enums';
import {
  ConfirmingButton,
  ConfirmingButtonGroup,
  DelayedSkeleton,
  FancyButton
} from 'ui-utils';

export interface CategorySelectorProps extends Omit<BoxProps, 'children'> {
  onFinished: (result: ContactType) => void | Promise<void>;
  onSkip?: () => void | Promise<void>;
  children?: React.ReactNode | React.ReactNode[];
  omit?: ContactType[];
  hasError?: boolean;
  hideError?: () => void;
  disabled?: boolean;
  defaultValue?: ContactType;
  loading?: boolean;
  // toggles usage of normal button instead of confirming button
  noConfirmation?: boolean;
}

export default function CategorySelector(props: CategorySelectorProps) {
  const CATEGORIES: {
    id: ContactType;
    label: string;
  }[] = [
    {
      id: ContactType.PERSONAL,
      label: language.text.personal_contact
    },
    {
      id: ContactType.AUDIENCE_HOLDER,
      label: language.text.audience_holder
    },
    {
      id: ContactType.POTENTIAL_CUSTOMER,
      label: language.text.potential_customer
    },
    {
      id: ContactType.CUSTOMER,
      label: language.text.customer
    },
    {
      id: ContactType.NO_MATCH,
      label: language.text.no_match
    }
  ];

  const [highlighted, setHighlighted] = React.useState<ContactType | undefined>(
    props.defaultValue
  );

  const {
    onFinished,
    onSkip,
    omit,
    hideError,
    hasError,
    disabled,
    children,
    loading,
    noConfirmation,
    ...rest
  } = props;

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      boxSizing="border-box"
      pt={2}
      {...rest}>
      <Box
        sx={{
          width: '100%',
          boxSizing: 'border-box',
          px: 2,
          py: 1,
          mb: 2,
          borderRadius: 1,
          backgroundColor: 'secondary.main',
          color: 'secondary.contrastText'
        }}>
        <Typography variant="h6" textAlign="center">
          {language.text.categorization}
        </Typography>
      </Box>
      {noConfirmation ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
            maxWidth: '560px',
            width: '100%'
          }}>
          {loading ? (
            <DelayedSkeleton
              variant="rounded"
              width="100%"
              height="36.5px"
              quantity={CATEGORIES.length}
            />
          ) : (
            CATEGORIES.filter((c) => !omit?.includes(c.id)).map((category) => (
              <FancyButton
                key={category.id}
                fType={{
                  promise: true
                }}
                onClick={async () => {
                  await onFinished(category.id);
                  setHighlighted(category.id);
                }}
                variant={highlighted === category.id ? 'contained' : 'outlined'}
                disableElevation
                disabled={disabled}
                fullWidth>
                {category.label}
              </FancyButton>
            ))
          )}
        </Box>
      ) : (
        <ConfirmingButtonGroup
          sx={{
            alignItems: 'center',
            maxWidth: '560px',
            width: '100%'
          }}>
          {loading ? (
            <DelayedSkeleton
              variant="rounded"
              width="100%"
              height="36.5px"
              quantity={CATEGORIES.length}
            />
          ) : (
            CATEGORIES.filter((c) => !omit?.includes(c.id)).map((category) => (
              <ConfirmingButton
                key={category.id}
                onClick={async () => {
                  await onFinished(category.id);
                  setHighlighted(category.id);
                }}
                variant={highlighted === category.id ? 'outlined' : 'contained'}
                disableElevation
                disabled={disabled}
                fullWidth
                confirmationButtonProps={{
                  sx: {
                    '&:hover': {
                      background: 'white'
                    },
                    'background': 'white'
                  }
                }}>
                {category.label}
              </ConfirmingButton>
            ))
          )}
        </ConfirmingButtonGroup>
      )}
      <Box
        display="flex"
        alignSelf="flex-end"
        sx={{
          flexDirection: 'row',
          width: '100%',
          marginTop: 2
        }}
        gap={2}>
        {children}
        {hasError ? (
          <FancyButton
            key={'errorButton'}
            sx={{
              height: '42px',
              flex: '1 1 50%'
            }}
            fullWidth
            variant="contained"
            color="error"
            endIcon={<ReplayOutlined />}
            onClick={() => hideError && hideError()}>
            {language.text.generic_error_try_again}
          </FancyButton>
        ) : (
          <>
            {onSkip && (
              <FancyButton
                fType={{
                  promise: true
                }}
                onClick={() => onSkip()}
                fullWidth
                color="secondary"
                variant="contained"
                startIcon={<SkipNext />}>
                {language.text.skip}
              </FancyButton>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
