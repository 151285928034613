import { Contact } from '@/data/Classes/Contact';
import {
  UncategorizedProfile,
  Message,
  ProfileInfo
} from '@common/types/ApiTypes';
import { getSelf } from '@/data/LinkedIn/Account';
import { Iterators } from '@idot-digital/generic-helpers';
import { language } from '@/index';
import Auth from '@common/AuthManager/Auth.renderer';
import { LinkedInContact } from 'linkedin-domain-types';
import PrintableError from '@common/PrintableError/PrintableError';
import { ContactType } from '@common/types/enums';
import { Pipelines } from '@common/PipelineManager/Pipelines';
import { chats, contacts } from '@digital-sun-solutions/cloud-functions';
import WebviewLinkedIn from '@common/Webview.renderer/WebviewLinkedIn';
import log from 'electron-log';
import { Message as LinkedInMessage } from 'linkedin-domain-types';
import Logger from 'electron-log';
import { Task, TaskHeader } from 'focus-mode-scheduler';
import {
  TaskConfig,
  TaskIncomingEventMap
} from 'focus-mode-scheduler/src/Task/Task';
import { mergeObjects } from '@common/Functions';
import { Arrays } from '@/other/Arrays';

export interface CategorizeTaskOutgoingEventMap {
  'chat-loaded': undefined;
  'chat-error': undefined;
}

export default class CategorizeTask extends Task<
  object,
  CategorizeTaskOutgoingEventMap
> {
  public static get config(): TaskConfig {
    return {
      id: 'categorize',
      info: {
        title: language.text.categorize,
        description: language.text.categorize_existing_contacts,
        avaContent: language.text.ava_hint_focus_categorize
      },
      hide: false,
      constraintBreachedDialogContent: mergeObjects(
        Task.DEFAULT_CONTRAINT_BREACHED_DIALOG_TEXTS,
        {
          items: {
            buttons: {
              oneMore: undefined
            }
          }
        }
      )
    };
  }

  protected _profile: UncategorizedProfile;
  protected _pipeline_steps = Pipelines.getCategorizationSteps();
  protected _header: TaskHeader;

  constructor(profile: UncategorizedProfile) {
    super();
    this._profile = profile;

    const fullName = this._profile.firstname + ' ' + this._profile.lastname;
    this._header = {
      title: language.text.categorize,
      subtitle: language.text.categorize_person(fullName),
      skip: {
        instance: {
          enable: true,
          text: language.text.skip_name(fullName)
        }
      }
    };

    this.loadProfileData();
  }

  private profileDataPromise: ReturnType<
    CategorizeTask['loadProfileData']
  > | null = null;
  private profileData: Awaited<ReturnType<
    CategorizeTask['loadProfileData']
  > | null> = null;
  private errorWhileLoading = false;
  private _chatLoaded = false;
  public get chatLoaded() {
    return this._chatLoaded;
  }
  public get chatError() {
    return this.errorWhileLoading;
  }
  private async loadProfileData(): Promise<{
    profile: ProfileInfo;
    conversation?: {
      conversationID: string;
      unreadCount: number;
      archived: boolean;
      profileID: string;
    };
    messages?: Message[];
  }> {
    if (this.profileDataPromise) return this.profileDataPromise;

    this.profileDataPromise = (async () => {
      console.debug(
        '[CategorizeTask:loadProfileData] starting loadProfileData',
        this.profile.firstname,
        this.profile.lastname
      );
      try {
        const self = await getSelf(true, true);
        console.debug('[CategorizeTask:loadProfileData] got self');
        const fullProfile:
          | (LinkedInContact & {
              conversationID?: string | undefined;
            })
          | null =
          (await WebviewLinkedIn.getFullProfile(this.profile.profileID)) ??
          (await WebviewLinkedIn.getProfile(this.profile.profileID));
        console.debug(
          '[CategorizeTask:loadProfileData] got profile',
          this.profile.firstname,
          this.profile.lastname,
          fullProfile
        );
        if (!fullProfile)
          throw new PrintableError('Could not get full profile');
        const res: Awaited<ReturnType<CategorizeTask['loadProfileData']>> = {
          profile: {
            firstname: fullProfile.firstName,
            lastname: fullProfile.lastName,
            headline: fullProfile.infoText,
            profileID: fullProfile.profileID,
            publicIdentifier: fullProfile.publicIdentifier,
            pictures: fullProfile.profilePictureUrl
          }
        };
        const conversationID = fullProfile.conversationID;

        if (conversationID) {
          const conversation =
            await WebviewLinkedIn.getConversation(conversationID);
          console.debug(
            '[CategorizeTask:loadProfileData] got conversationID',
            this.profile.firstname,
            this.profile.lastname
          );
          res.conversation = {
            conversationID,
            unreadCount: conversation?.unreadCount ?? 0,
            archived: conversation?.archived ?? false,
            profileID: fullProfile.profileID
          };

          const messages = await Iterators.toArray(
            Iterators.fromApiV2<LinkedInMessage>((cursor) =>
              WebviewLinkedIn.listMessages(conversationID, cursor ?? undefined)
            )
          );
          console.debug(
            '[CategorizeTask:loadProfileData] got messages',
            this.profile.firstname,
            this.profile.lastname
          );
          res.messages = messages.map((message) => ({
            messageID: message.messageID.split(',')[1].slice(0, -1),
            sendByYou:
              message.sentFrom === self.publicIdentifier ||
              message.sentFrom === self.profileID,
            deleted: message.deleted,
            attachments: message.attachments,
            createdAt: message.createdAt,
            reactions: message.reactions,
            text: message.text
          }));
        }

        this.profileData = res;
        this.internalEmit('chat-loaded', undefined);
        console.debug(
          '[CategorizeTask:loadProfileData] got self',
          this.profile.firstname,
          this.profile.lastname
        );
        return res;
      } catch (e) {
        console.error(
          '[CategorizeTask:loadProfileData] error',
          this.profile.firstname,
          this.profile.lastname,
          e
        );
        Logger.error('[CategorizeTask:loadProfileData] error', e);
        this.errorWhileLoading = true;
        this.internalEmit('chat-error', undefined);
        return {
          profile: {
            city: '',
            country: '',
            firstname: this.profile.firstname,
            lastname: this.profile.lastname,
            headline: '',
            profileID: this.profile.profileID,
            publicIdentifier: this.profile.publicIdentifier,
            pictures: this.profile.pictures
          }
        };
      } finally {
        this._chatLoaded = true;
      }
    })();

    return this.profileDataPromise;
  }

  private selectedType: ContactType | null = null;
  public setSelected(type: ContactType) {
    this.selectedType = type;
  }
  protected async handleEvent<Type extends keyof TaskIncomingEventMap>(
    type: Type
  ) {
    switch (type) {
      case 'skip':
        // do not save profile data when skipping
        this.internalEmit('finished', undefined);
        break;
      case 'complete':
        await this.saveContact();
        this.internalEmit('finished', undefined);
        break;
    }
  }

  protected async saveContact() {
    if (!this.selectedType) return;
    const profileData = await this.loadProfileData();

    log.log('[CategorizeTask:complete] saving profile', profileData.profile);

    await Contact.categorize(profileData.profile, this.selectedType);

    if (profileData.conversation) {
      log.log(
        '[CategorizeTask:complete] create conversation',
        profileData.conversation
      );
      await Auth.execRoute((token) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked above
        chats.create(profileData.conversation!, { token })
      );

      if (profileData.messages) {
        log.log(
          '[CategorizeTask:complete] create messages',
          profileData.messages
        );
        await Promise.all(
          Arrays.split(profileData.messages, 50).map((messages) =>
            Auth.execRoute((token) =>
              chats.addMessages(
                {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked above
                  conversationID: profileData.conversation!.conversationID,
                  messages
                },
                { token }
              )
            )
          )
        );
      }
    }

    await Auth.execRoute((token) =>
      contacts.uncategorized.remove(
        { profileID: this.profile.profileID },
        { token }
      )
    );
  }

  public async removeContact() {
    await Auth.execRoute((token) =>
      contacts.uncategorized.remove(
        { profileID: this.profile.profileID },
        { token }
      )
    );
  }

  public get profile() {
    return this._profile;
  }
  public get steps() {
    return this._pipeline_steps;
  }

  public hasProfileChat() {
    return Boolean(this.profileData?.conversation);
  }

  public getProfileURL(useProfileID = false) {
    return `https://linkedin.com/in/${
      useProfileID
        ? this.profile.profileID
        : (this.profile.publicIdentifier ?? this.profile.profileID)
    }/`;
  }

  public getMessages() {
    return this.profileData?.messages;
  }

  public get unreadCount() {
    return this.profileData?.conversation?.unreadCount ?? 0;
  }
}
