const TEMPLATES = {
  'begrueßungsnachricht-kennenlernen': [
    {
      name: 'Digital Sun',
      text: 'Moin <ssc-variable>firstname</ssc-variable>,\n\nich danke dir fürs Connecten! Ich bin Mitch, der LinkedIn-Vulkan, und freue mich darauf, dich kennenzulernen.\n\nFalls du dich fragen solltest, was ich hier so treibe, schau doch einfach mal auf meinem Profil vorbei oder frag mich direkt.\n\nAber Vorsicht, bei mir geht es um echte Kommunikation zwischen Menschen - kein Roboter-Sprech oder Bullshit-Bingo!\n\nJetzt aber zu dir: Wer zum Himmel bist du eigentlich, <ssc-variable>firstname</ssc-variable>? Und was hat dich dazu gebracht, <ssc-placeholder>DEINEN WEG / BERUF / BUSINESS</ssc-placeholder> einzuschlagen?\n\nIch bin gespannt auf deine Antworten und schicke sonnige Grüße ☀️\nCheers 🔥'
    },
    {
      name: 'Humorvoll',
      text: 'Hallo <ssc-variable>firstname</ssc-variable>,\nwow, wir sind jetzt verbunden - das heißt, dass wir zusammen durch dick und dünn gehen, wie Batman und Robin, nur ohne Kostüme (oder vielleicht doch...?).\n\nIch bin Mitch, dein neuer bester Freund auf LinkedIn und der Beste im Spiel des Social Sellings!\n\nIch verspreche dir, dass du mit mir an deiner Seite wie ein Rockstar durchstarten wirst. \n\nUnd wenn du Hilfe bei etwas brauchst, sag einfach Bescheid - ich stehe dir zur Seite wie ein persönlicher Bodyguard (ohne die coole Sonnenbrille, leider).\n\nAber genug von mir, lass uns über dich sprechen! Was hat dich auf LinkedIn gebracht und wo willst du hin? Lass uns gemeinsam deine Ziele rocken und dabei jede Menge Spaß haben!\n\nFreue mich darauf, von dir zu hören und schicke dir sonnige Grüße ☀️\nStay awesome! 🤘'
    },
    {
      name: 'Förmlich',
      text: 'Sehr geehrte/r Herr/Frau <ssc-variable>lastname</ssc-variable>,\n\nvielen Dank für die Verbindung auf LinkedIn. Mein Name ist Mitch und ich freue mich, Ihnen meine Expertise im Bereich Social Selling zur Verfügung zu stellen.\n\nIch lade Sie herzlich ein, mein Profil zu besuchen oder mich direkt zu kontaktieren, um mehr darüber zu erfahren, wie ich Ihnen bei Ihrem Geschäftserfolg helfen kann. Ich stehe für eine offene und ehrliche Kommunikation, die auf klaren und effektiven Strategien basiert.\n\nIch würde gerne mehr über Sie erfahren und was Sie auf LinkedIn suchen. \n\nWelche Ziele möchten Sie erreichen und wie kann ich Ihnen dabei helfen? Bitte zögern Sie nicht, mich zu kontaktieren, wenn Sie Fragen haben oder meine Hilfe benötigen.\n\nVielen Dank für Ihre Zeit und ich freue mich darauf, von Ihnen zu hören.\n\nMit freundlichen Grüßen,\nMitch'
    }
  ],
  'beziehungsnachricht-kennenlernen': [
    {
      name: 'Digital Sun',
      text: 'Hi <ssc-variable>firstname</ssc-variable>, danke für deine Nachricht.\n\n<ssc-placeholder>INDIVIDUELL DRAUF EINGEHEN</ssc-placeholder>\n\nIch bin Träumer, Visionär, Andersdenker, Vater, Unternehmer und manchmal auch etwas drüber 😂. \n\nNach zwei Pleiten, einem Burnout und einer Depression hab ich beschlossen, alles anders zu machen.\n\nSo wurde Digital Sun geboren - meine Art, anderen dabei zu helfen, erfolgreich zu sein und dabei ich selbst zu bleiben.\n\nDas war die Kurzversion. \n\nAber genug von mir, jetzt bist du dran: Wer bist du eigentlich, <ssc-variable>firstname</ssc-variable>? Und was sind deine Ziele für dieses Jahr?\n\nIch bin gespannt auf deine Antwort und schicke sonnige Grüße ☀️\nCheers 🔥'
    },
    {
      name: 'Humorvoll',
      text: 'Hi <ssc-variable>firstname</ssc-variable>,\n\nerstmal danke für deine Nachricht! Ich bin Mitch, der einzigartige LinkedIn Vulkan der dir hilft, dein Geschäft auf die nächste Stufe zu bringen - ohne Zauberei, versprochen!\n\nIch bin auch ein Vater, Unternehmer und allgemeiner Lebenskünstler, der gerne das Leben in vollen Zügen genießt (manchmal zu vollen Zügen - aber shh, sag das niemandem!).\n\nNachdem ich zwei Pleiten, einen Burnout und eine Depression durchgemacht habe, habe ich beschlossen, dass es an der Zeit ist, meine eigene Sonne aufgehen zu lassen.\n\nDigital Sun war geboren und seitdem helfe ich anderen, erfolgreich zu sein und dabei das Leben zu genießen!\n\nAber genug von mir - was ist mit dir, <ssc-variable>firstname</ssc-variable>? Wie lautet deine Geschichte und was sind deine Ziele für dieses Jahr? \n\nIch bin gespannt auf deine Antwort und schicke dir sonnige Grüße ☀️\nRock on! 🤘'
    },
    {
      name: 'Förmlich',
      text: 'Sehr geehrte/r Herr/Frau <ssc-variable>lastname</ssc-variable>,\n\nvielen Dank für Ihre Nachricht. Ich bin Mitch, ein Unternehmer und Vater, der sich auf LinkedIn auf das Social Selling spezialisiert hat.\n\nNachdem ich zwei Pleiten, einen Burnout und eine Depression durchgemacht hatte, entschied ich mich, eine neue Richtung einzuschlagen und gründete Digital Sun - eine Plattform, auf der ich anderen helfe, erfolgreich zu sein und dabei ihre Lebensqualität zu verbessern.\n\nIch würde gerne mehr über Sie und Ihre Geschäftsziele erfahren. Was treibt Sie auf LinkedIn an und wie kann ich Ihnen dabei helfen, Ihre Ziele zu erreichen? \n\nBitte zögern Sie nicht, mich zu kontaktieren, wenn Sie weitere Fragen haben oder meine Unterstützung benötigen.\n\nVielen Dank und ich freue mich darauf, von Ihnen zu hören.\n\nMit freundlichen Grüßen,\nMitch'
    },
    {
      name: 'Digital Sun - Vertiefung',
      text: 'Hi <ssc-variable>firstname</ssc-variable>,\n\nerstmal danke für deine Nachricht!\n\n<ssc-placeholder>INDIVIDUELL AUF DIE NACHRICHT EINGEHEN</ssc-placeholder>\n\nsonnige Grüße ☀️\n\nCheers 🔥'
    },
    {
      name: 'Förmlich - Vertiefung',
      text: 'Sehr geehrte/r Herr/Frau <ssc-variable>lastname</ssc-variable>,\n\nvielen Dank für Ihre Nachricht.\n\n<ssc-placeholder>INDIVIDUELL AUF DIE NACHRICHT EINGEHEN</ssc-placeholder>\n\nMit freundlichen Grüßen,\n\nMitch'
    }
  ],
  'meeting-pitch-kennenlernen': [
    {
      name: 'Digital Sun',
      text: 'Hey <ssc-variable>firstname</ssc-variable>, sehr spannend!\n\nIch hab da mal einen Vorschlag. Ich sehe ja, dass du wirklich für dein Business brennst 🔥und das finde ich stark. Hättest du Lust auf einen Call? \n\nKeine Ahnung ob mein Angebot für dich passend ist oder ob deins wirklich auf mich Matched - aber ich hätte Lust dich kennenzulernen.\n\nUnd wer weiß - vielleicht verstehen wir uns sogar? 😁\n\nPS: Wenn du Fragen zu LinkedIn hast, kannst du die dann auch alle stellen, muss aber nicht sein.'
    },
    {
      name: 'Humorvoll',
      text: 'Hey <ssc-variable>firstname</ssc-variable>,\n\nich muss sagen, dein Business hat mich voll in Flammen gesetzt - du bist ein heißes Eisen, das ist sicher! Ich bin Mitch, der einzigartige LinkedIn-Vulkan, und ich denke, es ist Zeit, dass wir einen Call machen!\n\nWer weiß, vielleicht passen wir zusammen wie ein Arsch auf einem heißen Stein (oder so ähnlich)? 😄 Aber im Ernst, ich denke, dass wir gemeinsam Großes erreichen können!\n\nUnd wenn nicht, ist es auch okay - wir haben uns zumindest kennengelernt und das ist doch auch was wert, oder? \n\nPS: Keine Sorge, du musst mir nicht alle Fragen zu LinkedIn stellen - aber wenn du welche hast, bin ich gerne für dich da!\n\nIch freue mich darauf, von dir zu hören und schicke dir sonnige Grüße ☀️\n\nCheers! 🔥'
    },
    {
      name: 'Förmlich',
      text: 'Sehr geehrte/r Herr/Frau <ssc-variable>lastname</ssc-variable>,\n\nich habe mir Ihr Business angesehen und bin sehr beeindruckt von dem, was Sie erreicht haben. Mein Name ist Mitch und ich bin auf LinkedIn auf das Social Selling spezialisiert.\n\nIch denke, es könnte für uns beide von Vorteil sein, uns zu einem kurzen Anruf zu verabreden.\n\nEs ist immer hilfreich, neue Perspektiven zu erhalten und neue Möglichkeiten zu erkunden.\n\nIch würde mich freuen, Ihnen meine Expertise im Bereich Social Selling näherzubringen und gemeinsam zu sehen, ob es Möglichkeiten gibt, von unserer Zusammenarbeit zu profitieren.\n\nWenn Sie interessiert sind, lassen Sie es mich bitte wissen und wir können einen Termin vereinbaren. \n\nIch stehe Ihnen auch gerne zur Verfügung, falls Sie Fragen oder Anliegen haben.\n\nVielen Dank und ich freue mich darauf, von Ihnen zu hören.\n\nMit freundlichen Grüßen,\nMitch'
    }
  ],
  'calendar-link-kennenlernen': [
    {
      name: 'Digital Sun',
      text: 'Hey <ssc-variable>firstname</ssc-variable>, du bist ja echt der Hammer! \n\n<ssc-placeholder>Individuell auf die Nachricht eingehen</ssc-placeholder>\n\nWenn du konkrete Fragen hast zu LinkedIn, Kundengewinnung oder irgendetwas anderem, dann schick sie mir am besten vorab zu. \nSo kann ich mich perfekt auf unser Gespräch vorbereiten - und du bekommst die volle Dröhnung Mitch-Power! 💪\n\nBuch dir hier am besten gleich einen Termin und lass uns loslegen:\n\nCALENDLY LINK\n\nIch freu mich wie Bolle darauf, dich live zu sehen!\n\nSonnige Grüße ☀️ und bis ganz bald.'
    },
    {
      name: 'Humorvoll',
      text: 'Heyyy <ssc-variable>firstname</ssc-variable>,\n\n<ssc-placeholder>Individuell auf die Nachricht eingehen</ssc-placeholder>\n\ndu bist ja der Kracher schlechthin - ich bin kurz davor, eine Statue von dir zu bauen! 😂 \n\nIch bin Mitch, der LinkedIn-Superstar und wenn es um Kundengewinnung geht, dann hast du mit mir den Jackpot gezogen!\n\nWenn du konkrete Fragen hast, dann schick sie mir am besten vorab zu - ich werde mich vorbereiten wie ein Olympionike vor dem Finale und du bekommst die volle Dröhnung Mitch-Power! 💪\n\nAlso, buche dir hier am besten gleich einen Termin und lass uns gemeinsam durchstarten - ich kann es kaum erwarten, dich live zu sehen und unsere Superkräfte zu vereinen!\n\nCALENDLY LINK\n\nSonnige Grüße ☀️ und bis ganz bald - ich werde hier auf dich warten, wie ein Hund auf sein Leckerli!\nCheers 🤘'
    },
    {
      name: 'Förmlich',
      text: 'Sehr geehrte/r Herr/Frau <ssc-variable>lastname</ssc-variable>,\n\n<ssc-placeholder>Individuell auf die Nachricht eingehen</ssc-placeholder>\n\nFalls Sie konkrete Fragen haben, lassen Sie es mich bitte vorab wissen, damit ich mich optimal auf unser Gespräch vorbereiten kann. \n\nIch stehe Ihnen gerne zur Verfügung und werde Ihnen meine volle Expertise zur Verfügung stellen.\n\nBitte buchen Sie hier Ihren Termin und ich freue mich darauf, Ihnen weiterzuhelfen. \n\nCALENDLY LINK\n\nIch sende Ihnen sonnige Grüße ☀️ und bis bald!\n\nMitch'
    }
  ],
  'homework-kennenlernen': [
    {
      name: 'Digital Sun',
      text: 'Hey <ssc-variable>firstname</ssc-variable>!\n          \nDanke nochmal für das coole Gespräch! Bevor wir in die Vollen gehen, müssen wir uns aber noch vorbereiten. Und dafür brauche ich deine Antworten auf diese 3 Fragen:\n\nWas sind gerade deine größten Herausforderungen im Business und speziell bei der Kundengewinnung über LinkedIn?\n\nWohin soll sich dein Business entwickeln und was möchtest du mit LinkedIn erreichen? Was ist dein Ziel?\n\nWas sind für dich die wichtigsten Punkte, um in eine Zusammenarbeit zu starten und die Themen von 1 und 2 umzusetzen?\n\nIch danke dir schon im Voraus für deine Antworten! 🙏🏼\nCheers! 🔥'
    },
    {
      name: 'Humorvoll',
      text: 'Heyyy <ssc-variable>firstname</ssc-variable>!\nDanke nochmal für das mega-galaktische Gespräch - du bist ja der Burner schlechthin! 🔥 Bevor wir uns aber aufs Treppchen kämpfen, brauchen wir noch deine Antworten auf diese 3 Fragen:\n\nWas sind deine größten Herausforderungen im Business und speziell bei der Kundengewinnung über LinkedIn? Sag es mir ruhig, ich beiße nicht - höchstens in meine Brezel! 😂\nWohin soll sich dein Business entwickeln und was möchtest du mit LinkedIn erreichen? Was ist dein Ziel? Sag es mir ruhig, ich bin wie eine Mumie, ich bewahre alles gut auf! 🤐\nWas sind für dich die wichtigsten Punkte, um in eine Zusammenarbeit zu starten und die Themen von 1 und 2 umzusetzen? Sag es mir ruhig, ich bin wie ein Vogel, ich verbreite es nicht weiter! 🐦\n\nIch danke dir schon im Voraus für deine Antworten, ich werde sie wie einen Schatz hüten! 🏆\n\nCheers! 🤘'
    },
    {
      name: 'Förmlich',
      text: 'Sehr geehrte/r Herr/Frau <ssc-variable>lastname</ssc-variable>,\n\nich möchte mich nochmals für das informative Gespräch bedanken. Bevor wir weitermachen, benötige ich noch Ihre Antworten auf die folgenden 3 Fragen:\n\nWas sind Ihre größten Herausforderungen im Business und speziell bei der Kundengewinnung über LinkedIn?\nWohin soll sich Ihr Business entwickeln und was möchten Sie mit LinkedIn erreichen? Was ist Ihr Ziel?\nWas sind für Sie die wichtigsten Punkte, um in eine Zusammenarbeit zu starten und die Themen von 1 und 2 umzusetzen?\n\nIch danke Ihnen im Voraus für Ihre Antworten.\nMit freundlichen Grüßen,\nMitch'
    }
  ],
  'no-show-kennenlernen': [
    {
      name: 'Digital Sun',
      text: 'Hey <ssc-variable>firstname</ssc-variable>, \n\nIrgendwas ist anscheinend dazwischengekommen. Wir hatten uns doch verabredet, oder?\nIch hoffe, es ist nichts Ernstes passiert?\n\nAber Absagen und einen neuen Termin machen gehört zum guten Ton, findest du nicht?\n\nBeste Grüße\nMitch'
    },
    {
      name: 'Humorvoll',
      text: "Hey <ssc-variable>firstname</ssc-variable>,\n\nDa wir unseren letzten Tanz verpasst haben, nehme ich an, du wurdest plötzlich in eine super geheime Mission gerufen. \n\nKann passieren! 😅 \n\nWie wär's, wenn wir einen neuen Termin planen?\n\nGib mir Bescheid und hoffe, es ist alles gut bei dir!\n\nBeste Grüße,\nMitch\n"
    },
    {
      name: 'Höflich',
      text: 'Hallo <ssc-variable>firstname</ssc-variable>,\n\nUnsere letzte Verabredung scheint irgendwie untergegangen zu sein. Ich hoffe, alles ist gut bei dir und nichts Unvorhergesehenes ist passiert. \n\nFür die Zukunft wäre es hilfreich, wenn wir Termine absagen und einen neuen vereinbaren könnten, sollte etwas dazwischenkommen.\n\nLass mich bitte wissen, wann du bereit bist, unser Gespräch fortzusetzen.\n\nMit besten Grüßen,\nMitch\n'
    }
  ],
  'no-show-calendar-link-kennenlernen': [
    {
      name: 'Digital Sun',
      text: 'Hallo <ssc-variable>firstname</ssc-variable>,\n\n<ssc-placeholder>INDIVIDUELL AUF DIE NACHRICHT EINGEHEN</ssc-placeholder>\n\nIch verstehe, dass manchmal unerwartete Ereignisse auftreten, die uns daran hindern, geplante Termine einzuhalten. Aus diesem Grund gebe ich dir gerne eine zweite Chance.\n\nBitte lass uns einen neuen Termin finden, der dir passt. Deine Zeit ist wertvoll und ich möchte sicherstellen, dass wir sie optimal nutzen können.\n\nHier ist der Link, um einen neuen Termin zu buchen: [Link zum Kalender]\n\n\nIch freue mich darauf, dich bald zu treffen.\n\n\nBeste Grüße,\n\n[Dein Name]'
    },
    {
      name: 'Humorvoll',
      text: 'Hey <ssc-variable>firstname</ssc-variable>,\n\n<ssc-placeholder>INDIVIDUELL AUF DIE NACHRICHT EINGEHEN</ssc-placeholder>\n\nUnd da du mir nicht erzählt hast, dass du von wilden Lamas entführt wurdest passt es. \n\nIch bin ein großer Fan von zweiten Chancen. Klick auf den Link und such dir einen neuen Termin aus: [Link zum Kalender]\n\nBis dahin pass auf dich auf und halte dich fern von problematischen Lamas.\n\nBeste Grüße,\n\n[Dein Name]'
    },
    {
      name: 'Förmlich',
      text: 'Sehr geehrte/r Herr/Frau <ssc-variable>lastname</ssc-variable>,\n\n<ssc-placeholder>INDIVIDUELL AUF DIE NACHRICHT EINGEHEN</ssc-placeholder>\n\nIch verstehe, dass es Momente gibt, in denen unvorhergesehene Umstände unsere Pläne ändern können. Aus diesem Grund bin ich gerne bereit, einen weiteren Termin zu vereinbaren.\n\nBitte folgen Sie diesem Link, um einen neuen Termin zu buchen: [Link zum Kalender]\n\nIch freue mich auf die Gelegenheit, Sie bald zu treffen.\n\nMit freundlichen Grüßen,\n\n[Dein Name]'
    }
  ],
  'wrong-time-kennenlernen': [
    {
      name: 'Später nochmal',
      text: 'Danke für das intensive und echte Gespräch! 🙏\nWir machen das genauso wie besprochen: Du sorgst dafür, dass XYZ passiert und funktioniert, und wir beide hören uns ganz entspannt in 3-6 Monaten nochmal. 🗓️\nUnd wenn du vorher Fragen hast oder bereit bist, loszulegen: Meine Tür steht dir immer offen! 🚪'
    },
    {
      name: 'Zeit richtig investieren',
      text: 'Hey <ssc-variable>firstname</ssc-variable>,\nich merke, dass es dir wichtig ist, aber jetzt passt es einfach nicht. Das kommt vor.\n(Persönliche Geschichte)\nIch habe einmal ein Event geplant, musste es aber absagen, um mich auf meine 1:1 Begleitung zu konzentrieren.\nDas war damals die richtige Entscheidung. ✅\nLass uns in 3-6 Monaten nochmal sprechen, okay? 🗓️'
    }
  ],
  'no-match-kennenlernen': [
    {
      name: 'Direkte Ansprache',
      text: "Hey <ssc-variable>firstname</ssc-variable>, ich will's direkt ansprechen:\nAktuell passt es zwischen uns einfach nicht.\nAber das ist okay! 👌\nDenn jetzt können wir unsere Zeit respektieren und woanders investieren.\nHab einen tollen Tag!"
    },
    {
      name: 'Tür offen halten',
      text: 'Hey <ssc-variable>firstname</ssc-variable>,\naus Respekt vor deiner Zeit muss ich dir sagen, dass ich bei (THEMA XYZ) gerade keine Priorität sehe.\nODER\nIch sehe, dass du bei (THEMA XYZ) gerade keine Priorität setzt.\nWie wäre es, wenn wir uns in 3-6 Monaten nochmal zusammensetzen und schauen, wie sich die Situation entwickelt hat? 📅'
    },
    {
      name: 'Falsches Gefühl',
      text: 'Hey <ssc-variable>firstname</ssc-variable>,\n\nvielen Dank für deine Zeit.\nIch habe da nochmal reingespürt, und muss dir sagen, es fühlt sich für mich nicht richtig an.\nGerade der Punkt (A) und Punkt (B) stimmen für mich nicht.\nIch danke dir für deine Zeit, aber ich glaube, wir kommen nicht zusammen.'
    }
  ],
  'follow-up-kennenlernen': [
    {
      name: 'Digital Sun Generic',
      text: 'Hallo <ssc-variable>firstname</ssc-variable>, \n\nIch hoffe, du hattest bis jetzt eine gute Woche und dass es dir gut geht!\n\nIch wollte mal nachfragen, ob meine Nachricht bei dir untergegangen ist.\n\nPassiert ja manchmal 😎\n\nFreu mich, von dir zu hören.\n\nSonnige Grüße ☀️'
    },
    {
      name: 'Digital Sun Nordpol',
      text: 'Hallo <ssc-variable>firstname</ssc-variable>\n\nIch hoffe Du hattest bis jetzt eine gute Woche und dass es Dir gut geht!\n\nIch wollte mal nachfragen, ob meine Nachricht bei Dir untergangen ist oder bist Du gerade am Nordpol und hast kein Internet? 🥶😉\n\nSonnige Grüsse'
    },
    {
      name: 'Digital Sun Aliens',
      text: 'Hey <ssc-variable>firstname</ssc-variable>\n\nIch hoffe dir gehts und wollte nochmal nachfragen, ob meine Nachricht einfach untergegangen ist?\n\nOder wurdest du vielleicht von Aliens entführt👽 😜?\n\nSonnige Grüße ✌️\n\nDein Name'
    },
    {
      name: 'Förmlich Generic',
      text: 'Sehr geehrte/r <ssc-variable>firstname</ssc-variable>,\n\nich hoffe, diese Nachricht erreicht Sie bei bester Gesundheit und Sie hatten eine erfolgreiche Woche.\n\nIch wollte mich erkundigen, ob Sie meine vorherige Nachricht erhalten haben, da ich leider keine Antwort erhalten habe. \n\nFalls sie in Ihrem Posteingang untergegangen ist, bitte ich um Entschuldigung für die Unannehmlichkeiten.\n\nIch freue mich auf Ihre Antwort und wünsche Ihnen einen sonnigen Tag.\n\nMit freundlichen Grüßen, Mitch'
    },
    {
      name: 'Förmlich Nordpol',
      text: 'Hey <ssc-variable>firstname</ssc-variable>,\n\nich hoffe, du frierst dir bei deinem Aufenthalt am Nordpol nicht die Finger ab! ❄️🥶 \n\nAber mal im Ernst: Hast du meine letzte Nachricht vielleicht im Eis verloren? \n\nOder ist dein Posteingang einfach nur so voll, dass sie untergegangen ist? 😅\n\nKein Problem, ich wollte nur mal nachfragen, wie es dir geht und ob ich dir irgendwie weiterhelfen kann.\n\nMelde dich gerne bei mir zurück, ich bin gespannt auf deine Antwort!\n\nSonnige Grüße ☀️'
    },
    {
      name: 'Förmlich Aliens',
      text: 'Hey <ssc-variable>firstname</ssc-variable>,\n\nwenn du meine letzte Nachricht nicht erhalten hast, kann das ja nur zwei Gründe haben: \n\nEntweder ist sie im Bermudadreieck verschwunden oder du wurdest von Aliens entführt! 👽😂\n\nAber im Ernst: Wie geht es dir und hast du schon eine Antwort für mich? \n\nLass es mich gerne wissen, ich bin gespannt auf deine Nachricht!\n\nSonnige Grüße ✌️'
    },
    {
      name: 'Humorvoll Generic',
      text: 'Hey <ssc-variable>firstname</ssc-variable>,\n\nna, hast du meine letzte Nachricht etwa im LinkedIn-Dschungel verloren? 🌴\n\nKein Problem, ich kenne das nur zu gut - manchmal verirren sich die besten Nachrichten in den Untiefen des Internets. 😅\n\nAber jetzt mal im Ernst: Ich hoffe, es geht dir gut und du hattest eine erfolgreiche Woche bisher. Melde dich gerne bei mir zurück, ich bin gespannt auf deine Antwort!\n\nSonnige Grüße ☀️'
    },
    {
      name: 'Humorvoll Nordpol',
      text: "Hey <ssc-variable>firstname</ssc-variable>,\n\nich hoffe, du hast dir genügend Frierfeste mitgenommen für deinen Aufenthalt am Nordpol! ❄️🥶\n\nAber mal ehrlich: Wenn ich deine letzte Antwort nicht bald sehe, werde ich selbst zum Yeti und dich höchstpersönlich besuchen kommen! 😂\n\nSpaß beiseite, ich wollte nur mal nachfragen, wie es dir geht und ob ich dir irgendwie weiterhelfen kann. \n\nFalls deine letzte Nachricht wirklich im Eis verloren gegangen ist, kein Problem - ich schicke einfach einen Eisbrecher hinterher!\n\nAlso, wie schaut's aus? \nBist du bereit, die nächste Stufe deines Business zu erklimmen? Dann lass uns gemeinsam den Berg erklimmen und das Gipfelkreuz erklimmen!\n\nIch warte gespannt auf deine Antwort und schicke sonnige Grüße ☀️ (obwohl bei mir gerade eher Schneeflocken vom Himmel fallen)!\n\nCheers, Mitch - der LinkedIn-Yeti 🦧"
    },
    {
      name: 'Humorvoll Aliens',
      text: 'Hey <ssc-variable>firstname</ssc-variable>,\n\nwenn du meine letzte Nachricht nicht erhalten hast, dann gibt es dafür nur zwei mögliche Erklärungen:\n\nEntweder hast du gerade eine Weltraumreise unternommen und bist von Aliens entführt worden, die sich für dein LinkedIn-Profil interessieren. \n\nOder aber, meine Nachricht wurde von einem schwarzen Loch verschluckt und befindet sich jetzt irgendwo im Universum!\n\nAber mal im Ernst: Wie geht es dir? Ich hoffe, du bist wohlauf und nicht von Außerirdischen an einen unerforschten Ort gebracht worden! 😅\n\nFalls du meine Nachricht wirklich vermisst haben solltest, kein Problem. \n\nIch wollte nur mal nachfragen, ob ich dir irgendwie weiterhelfen kann oder ob du konkrete Fragen zum Social Selling auf LinkedIn hast.\n\nLass es mich gerne wissen, ich bin gespannt auf deine Antwort und schicke sonnige Grüße ✌️ (hoffentlich nicht in eine andere Galaxie)!\n\nBeste Grüße, Mitch - der LinkedIn-Astronaut 🚀'
    }
  ],
  'uncategorized': []
} satisfies {
  [key: string]: {
    name: string;
    text: string;
  }[];
};

export default TEMPLATES;
