import { createQueryHook } from './QueryHelper';
import queryClient from '@/other/QueryClient';
import { subscription } from '@digital-sun-solutions/cloud-functions';
import Auth from '@common/AuthManager/Auth.renderer';
import PrintableError from '@common/PrintableError/PrintableError';
import { DiscountCoupon, FreeToUseCoupon } from '@common/types/ApiTypes';
import SetupActions from './Setup';
import MainConfig from '@common/config/MainConfig';
import { TRAINING_CERTIFICATE_KEY } from '@/Routes/ManualMode/Training/Training';
import posthog from 'posthog-js';

export enum UserProgression {
  New = 'new',
  User = 'user',
  Referrer = 'referrer',
  Ambassador = 'ambassador',
  Affiliate = 'affiliate'
}

async function getUserProgression(): Promise<{
  [key in UserProgression]: boolean;
}> {
  const unlocked = {
    [UserProgression.New]: false,
    [UserProgression.User]: false,
    [UserProgression.Referrer]: false,
    [UserProgression.Ambassador]: false,
    [UserProgression.Affiliate]: false
  };

  unlocked[UserProgression.New] = true;
  const usageDays = Auth.getSessionInfo().usageDays ?? 0;
  if (usageDays >= MainConfig.progression.usageDaysToUnlockTraining)
    unlocked[UserProgression.User] = true;

  const setupSteps = await SetupActions.getSetupSteps();
  if (setupSteps.includes(TRAINING_CERTIFICATE_KEY))
    unlocked[UserProgression.Referrer] = true;

  const referralInfo = await getReferralCode();
  if (referralInfo.count.current >= referralInfo.count.max)
    unlocked[UserProgression.Ambassador] = true;

  const isAffiliateRes = await Auth.execRoute((token) =>
    subscription.affiliates.getAffiliateStatus({}, { token })
  );

  if (isAffiliateRes.code !== 200)
    throw new PrintableError('Could not get affiliate status');

  if (isAffiliateRes.data.active) unlocked[UserProgression.Affiliate] = true;

  switch (true) {
    case unlocked[UserProgression.Ambassador]:
      posthog.capture('user-progression-ambassador');
      break;
    case unlocked[UserProgression.Referrer]:
      posthog.capture('user-progression-referrer');
      break;
    case unlocked[UserProgression.User]:
      posthog.capture('user-progression-user');
      break;
    case unlocked[UserProgression.New]:
      posthog.capture('user-progression-new');
      break;
  }

  if (unlocked[UserProgression.Affiliate]) {
    posthog.capture('user-progression-affiliate');
  }

  return unlocked;
}
getUserProgression.getQueryKey = () => ['referral', 'status'];

const useUserProgression = createQueryHook(
  getUserProgression,
  'userProgression'
);

async function getReferralCode() {
  const res = await Auth.execRoute((token) =>
    subscription.affiliates.getReferralInfo({}, { token })
  );

  if (res.code === 500)
    throw new PrintableError(
      'Could not get referral code due to internal server error'
    );

  return res.data;
}
getReferralCode.getQueryKey = () => ['referral', 'code'];

const useReferralCode = createQueryHook(getReferralCode, 'referralCode');

function isUnlocked(
  userStatus: { [key in UserProgression]: boolean },
  statusToCheck: UserProgression
): boolean {
  return userStatus[statusToCheck] ?? false;
}

async function getDiscountCoupons(): Promise<DiscountCoupon[]> {
  const res = await Auth.execRoute((token) =>
    subscription.affiliates.coupons.get({}, { token })
  );

  if (res.code !== 200) throw new PrintableError('Could not get codes');

  return res.data.filter((c) => c.type === 'provision') as DiscountCoupon[];
}
getDiscountCoupons.getQueryKey = () => ['affiliate', 'coupons', 'discount'];

const useDiscountCodes = createQueryHook(getDiscountCoupons, 'discountCoupons');

async function isCodeAvailable(
  code: string
): Promise<{ available: boolean; allowed: boolean }> {
  const res = await Auth.execRoute((token) =>
    subscription.affiliates.coupons.isAvailable({ code }, { token })
  );

  if (res.code !== 200) throw new PrintableError('Could not check code');

  return res.data;
}

async function getAppPrice() {
  const res = await Auth.execRoute((token) =>
    subscription.affiliates.getProductPrice({}, { token })
  );
  if (res.code !== 200) throw new PrintableError('Could not get app price');
  return res.data;
}
getAppPrice.getQueryKey = () => ['app-price'];

const useAppPrice = createQueryHook(getAppPrice, 'appPrice');

async function changeCodeActiviation(code: string, active: boolean) {
  queryClient.setQueryData(
    getDiscountCoupons.getQueryKey(),
    (old: DiscountCoupon[] | undefined) => {
      return (
        old?.map((c) => {
          if (c.code === code) {
            return {
              ...c,
              active
            };
          }
          return c;
        }) ?? []
      );
    }
  );
  queryClient.setQueryData(
    getFreeToUseCoupons.getQueryKey(),
    (old: DiscountCoupon[] | undefined) => {
      return (
        old?.map((c) => {
          if (c.code === code) {
            return {
              ...c,
              active
            };
          }
          return c;
        }) ?? []
      );
    }
  );

  const res = await Auth.execRoute((token) =>
    subscription.affiliates.coupons.toggle({ code, active }, { token })
  );

  if (res.code !== 200)
    throw new PrintableError('Could not change code activation');

  queryClient.invalidateQueries(getDiscountCoupons.getQueryKey());
  queryClient.invalidateQueries(getFreeToUseCoupons.getQueryKey());
}

async function deleteCode(code: string) {
  queryClient.setQueryData(
    getDiscountCoupons.getQueryKey(),
    (old: DiscountCoupon[] | undefined) => {
      return old?.filter((c) => c.code !== code) ?? [];
    }
  );
  queryClient.setQueryData(
    getFreeToUseCoupons.getQueryKey(),
    (old: DiscountCoupon[] | undefined) => {
      return old?.filter((c) => c.code !== code) ?? [];
    }
  );

  const res = await Auth.execRoute((token) =>
    subscription.affiliates.coupons.remove({ code }, { token })
  );

  if (res.code !== 200) throw new PrintableError('Could not delete code');

  queryClient.invalidateQueries(getFreeToUseCoupons.getQueryKey());
  queryClient.invalidateQueries(getDiscountCoupons.getQueryKey());
}

async function createDiscountCoupon(
  code: Omit<
    DiscountCoupon,
    | 'number_of_uses'
    | 'id'
    | 'type'
    | 'active'
    | 'number_of_active_users'
    | 'number_of_not_confirmed_users'
  >
) {
  const res = await Auth.execRoute((token) =>
    subscription.affiliates.coupons.create(
      {
        ...code,
        type: 'provision'
      },
      { token }
    )
  );

  if (res.code !== 200) throw new PrintableError('Could not create code');

  queryClient.invalidateQueries(getDiscountCoupons.getQueryKey());
}

async function getFreeToUseCoupons(): Promise<FreeToUseCoupon[]> {
  const res = await Auth.execRoute((token) =>
    subscription.affiliates.coupons.get({}, { token })
  );

  if (res.code !== 200) throw new PrintableError('Could not get codes');

  return res.data.filter((c) => c.type === 'freeToUse') as FreeToUseCoupon[];
}
getFreeToUseCoupons.getQueryKey = () => ['affiliate', 'coupons', 'freeToUse'];

const useFreeToUseCoupons = createQueryHook(
  getFreeToUseCoupons,
  'freeToUseCoupons'
);

async function createFreeToUseCoupon(
  code: Omit<
    FreeToUseCoupon,
    | 'number_of_uses'
    | 'number_of_active_users'
    | 'number_of_not_confirmed_users'
    | 'type'
    | 'id'
    | 'active'
  >
) {
  const res = await Auth.execRoute((token) =>
    subscription.affiliates.coupons.create(
      {
        ...code,
        type: 'freeToUse'
      },
      { token }
    )
  );

  if (res.code !== 200) throw new PrintableError('Could not create code');

  queryClient.invalidateQueries(getFreeToUseCoupons.getQueryKey());
}

async function getTotalEarnings(): Promise<number> {
  const res = await Auth.execRoute((token) =>
    subscription.affiliates.getConfirmedPayout({}, { token })
  );
  if (res.code !== 200) throw new PrintableError('Could not get earnings');
  return Number(res.data);
}
getTotalEarnings.getQueryKey = () => ['affiliate', 'earnings', 'total'];

const useTotalEarnings = createQueryHook(getTotalEarnings, 'earnings');

const AffiliateActions = {
  getUserProgression,
  useUserProgression,
  isUnlocked,

  getReferralCode,
  useReferralCode,

  isCodeAvailable,
  changeCodeActiviation,
  deleteCode,

  getDiscountCoupons,
  useDiscountCodes,
  createDiscountCoupon,

  getFreeToUseCoupons,
  useFreeToUseCoupons,
  createFreeToUseCoupon,

  getAppPrice,
  useAppPrice,

  getTotalEarnings,
  useTotalEarnings
};

export default AffiliateActions;
