import React from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import { useSetup } from '../../SetupContext';
import { language } from '@/index';
import { Background, FancyButton, Logo } from 'ui-utils';

export default function Setup() {
  const { currentStep, termsAccepted } = useSetup();
  const [checked, setChecked] = React.useState<boolean[]>([]);

  const allChecked = React.useMemo(() => {
    return checked.every((c) => c);
  }, [checked]);

  React.useEffect(() => {
    if (!currentStep) return;
    if (currentStep.handler !== 'term') return;
    setChecked(currentStep.terms.map(() => false));
  }, [currentStep]);

  if (currentStep && currentStep.handler !== 'term') return null;

  return (
    <Background
      variant="door"
      sx={{
        'height': '100%',
        'boxSizing': 'border-box',
        'display': 'flex',
        'width': '100vw',
        'flexDirection': 'column',
        'backgroundSize': '100% auto',
        'backgroundPosition': 'bottom center',
        'backgroundRepeat': 'no-repeat',
        'pb': 7,
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: (theme) => theme.palette.background.default,
          zIndex: 1,
          opacity: 0.5
        }
      }}>
      <Box
        sx={{
          height: '100%',
          maxWidth: '640px',
          margin: 'auto',
          textAlign: 'center',
          overflowY: 'auto',
          overflowX: 'hidden',
          zIndex: 2
        }}>
        <Logo
          variant="blue_text"
          sx={{
            width: '200px',
            height: 'auto',
            marginBottom: (theme) => theme.spacing(4),
            marginTop: (theme) => theme.spacing(5)
          }}
        />
        <Typography
          variant="h3"
          sx={{
            fontWeight: 'bold',
            marginBottom: (theme) => theme.spacing(2),
            textAlign: 'center'
          }}>
          {language.text.terms_of_use}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: (theme) => theme.spacing(1),
            textAlign: 'center'
          }}>
          {language.text.you_need_to_accept_tos}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            marginTop: (theme) => theme.spacing(2),
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Box
            sx={{
              textAlign: 'left'
            }}>
            {currentStep &&
              currentStep.terms.map((term, index) => (
                <Box
                  sx={{ mb: 2, display: 'flex', alignItems: 'center' }}
                  key={term.name + term.url}>
                  <Checkbox
                    checked={checked[index] ?? false}
                    onChange={(_: any, checked: boolean) =>
                      setChecked((prev) => {
                        const newChecked = [...prev];
                        newChecked[index] = checked;
                        return newChecked;
                      })
                    }
                  />
                  <Typography variant="body1">
                    {term.name.split('%%').map((text, index) => {
                      if (index % 2 === 0) return text;
                      return (
                        <Typography
                          component="a"
                          target="_blank"
                          color="primary.main"
                          href={term.url}
                          key={index}>
                          {text}
                        </Typography>
                      );
                    })}
                  </Typography>
                </Box>
              ))}
          </Box>
        </Box>
        <FancyButton
          variant="contained"
          disabled={!allChecked}
          fType={{
            pulsating: 'default',
            promise: true
          }}
          onClick={async () => {
            await termsAccepted(true);
            // return a promise that never resolves to have the button load until redirect
            return new Promise(() => {});
          }}
          sx={{
            marginTop: (theme) => theme.spacing(2),
            width: '290px',
            height: '48px',
            borderRadius: 1,
            fontSize: '18px',
            backgroundColor: (theme) => theme.palette.primary.main,
            textTransform: 'capitalize'
          }}>
          {language.text.continue}
        </FancyButton>
      </Box>
    </Background>
  );
}
