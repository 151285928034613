import { Box, Button } from '@mui/material';
import TemplateActions from '@/data/DataServer/Templates';
import { Add } from '@mui/icons-material';
import { MessageTemplate } from '@common/types/ApiTypes';
import { language } from '@/index';
import { DelayedSkeleton, FancyButton } from 'ui-utils';
import React from 'react';

export interface TemplateSelectorProps {
  onSelect: (template: MessageTemplate) => void;
  templateID: string | null;
  selectedTemplate: MessageTemplate | null;
}

export default function TemplateSelector(props: TemplateSelectorProps) {
  const { template, status: userTemplateStatus } = TemplateActions.useTemplate(
    props.templateID
  );

  const addTemplate = React.useCallback(async () => {
    if (!template) return;
    if (!props.templateID) throw new Error('[TemplateSelector] No templateID');
    const name = (() => {
      const existingNames = template.templates.map((t) =>
        t.name.toLocaleLowerCase()
      );
      if (
        !existingNames.includes(language.text.new_template.toLocaleLowerCase())
      )
        return language.text.new_template;
      let i = 1;
      const nameTemplate = (i: number) =>
        `${language.text.new_template} (${i})`;
      while (template.templates.some((t) => t.name === nameTemplate(i))) i++;
      return nameTemplate(i);
    })();
    const id = await TemplateActions.setTemplate({
      name,
      templateID: props.templateID,
      text: ''
    });
    props.onSelect({
      variantID: id,
      name,
      templateID: props.templateID,
      text: ''
    });
  }, [props.templateID, props.onSelect, template]);

  React.useEffect(() => {
    if (userTemplateStatus !== 'success' || !template) return;
    if (template.templates.length === 0) {
      addTemplate();
    } else if (template.templates[0].variantID < 0) {
      // use has no templates yet -> commit predefined templates to db
      const templates = template.templates;
      Promise.all(
        templates.map((t) =>
          TemplateActions.setTemplate({
            name: t.name,
            templateID: t.templateID,
            text: t.text
          })
        )
      ).then((ids) =>
        props.onSelect({
          ...templates[0],
          variantID: ids[0]
        })
      );
    }
  }, [template, userTemplateStatus]);

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="flex-start"
      gap={1}>
      {!props.templateID || userTemplateStatus === 'loading' ? (
        <DelayedSkeleton quantity={3} delay={300} />
      ) : (
        <>
          {template?.templates.map((template) => (
            <Button
              variant="contained"
              fullWidth
              color={
                props.selectedTemplate?.variantID === template.variantID
                  ? 'primary'
                  : 'neutral'
              }
              key={template.variantID}
              onClick={() => props.onSelect(template)}>
              {template.name}
            </Button>
          ))}
          <FancyButton
            fType={{
              promise: true
            }}
            variant="contained"
            fullWidth
            color="neutral"
            key="new"
            onClick={addTemplate}
            startIcon={<Add />}>
            {language.text.new_template}
          </FancyButton>
        </>
      )}
    </Box>
  );
}
