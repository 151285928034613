import { FocusMode, TaskBuilder } from 'focus-mode-scheduler';
import { language } from '@/index';
import AudienceHolderTaskBuilder from './AudienceHolder/AudienceHolderTaskBuilder';
import CommentTaskBuilderOld from './CommentsOld/CommentTaskBuilder';
import CommentTaskBuilder from './Comments/CommentTaskBuilder';
import ExpandNetworkTaskBuilderOld from './ExpandNetworkOld/ExpandNetworkTaskBuilder';
import ExpandNetworkTaskBuilder from './ExpandNetwork/ExpandNetworkTaskBuilder';
import ConnectProfileTaskBuilder from './ConnectProfile/ConnectProfileTaskBuilder';
import CategorizeTaskBuilder from './Categorize/CategorizeTaskBuilder';
import ChatTutorialTaskBuilder from './ChatTutorial/ChatTutorialTaskBuilder';
import EstablishRelationTaskBuilder from './EstablishRelation/EstablishRelationTaskBuilder';
import ChatTaskBuilder from './Chat/ChatTaskBuilder';
import QuestionTaskBuilder from './Question/QuestionTaskBuilder';
import SummaryTaskBuilder from './Summary/SummaryTaskBuilder';
import WritePostTaskBuilder from './WritePosts/WritePostTaskBuilder';
import { checkFeatureFlag } from '@/globals/featureFlags';
import { createQueryHook } from '@/data/DataServer/QueryHelper';
import { Constraint } from 'focus-mode-scheduler';
import { focusmode } from '@digital-sun-solutions/cloud-functions';
import Auth from '@common/AuthManager/Auth.renderer';
import { ConstraintPreset } from 'focus-mode-scheduler/src/components/Constraints';
import queryClient from '@/other/QueryClient';

function getBuilderMap() {
  const BUILDERS = [
    AudienceHolderTaskBuilder,
    checkFeatureFlag('focusMode_audienceHolder_manualSelection')
      ? CommentTaskBuilderOld
      : CommentTaskBuilder,
    checkFeatureFlag('focusMode_audienceHolder_manualSelection')
      ? ExpandNetworkTaskBuilderOld
      : ExpandNetworkTaskBuilder,
    WritePostTaskBuilder,
    ConnectProfileTaskBuilder,
    CategorizeTaskBuilder,
    ChatTutorialTaskBuilder,
    EstablishRelationTaskBuilder,
    ChatTaskBuilder,
    QuestionTaskBuilder,
    SummaryTaskBuilder
  ];

  return BUILDERS.reduce<{
    [id: TaskBuilder['id']]:
      | (new (defaultConstraint?: ConstraintPreset | null) => TaskBuilder)
      | undefined;
  }>((map, builder) => {
    const instance = new builder();
    map[instance.id] = builder;
    return map;
  }, {});
}

export interface SavedFocusMode {
  id: number;
  title: string;
  excerpt: string;
  description: string;
  tasks: { id: TaskBuilder['id']; constraint: ConstraintPreset }[];
}

async function getFocusModes(): Promise<(FocusMode & { custom: boolean })[]> {
  const res = await Auth.execRoute((token) =>
    focusmode.templates.get({}, { token })
  );

  if (res.code !== 200) throw new Error(res.data);

  const BUILDER_MAP = getBuilderMap();
  const modes: (FocusMode & { custom: boolean })[] = res.data.map((m) => ({
    ...m,
    custom: true,
    tasks: (
      m.tasks
        .map((t) => {
          const Builder = BUILDER_MAP[t.id];
          if (Builder) return new Builder(t.constraint);
          return null;
        })
        .filter(Boolean) as TaskBuilder[]
    ).concat(new SummaryTaskBuilder())
  }));

  return [
    {
      id: -1,
      title: language.text.full_focus_mode_title,
      excerpt: language.text.recommended,
      description: language.text.this_mode_has_no_focus,
      custom: false,
      tasks: [
        new AudienceHolderTaskBuilder(),
        checkFeatureFlag('focusMode_audienceHolder_manualSelection')
          ? new CommentTaskBuilderOld()
          : new CommentTaskBuilder(),
        checkFeatureFlag('focusMode_audienceHolder_manualSelection')
          ? new ExpandNetworkTaskBuilderOld()
          : new ExpandNetworkTaskBuilder(),
        new WritePostTaskBuilder(),
        new ConnectProfileTaskBuilder(),
        new CategorizeTaskBuilder(),
        new ChatTutorialTaskBuilder(),
        new EstablishRelationTaskBuilder(),
        new ChatTaskBuilder(),
        new QuestionTaskBuilder(),
        new SummaryTaskBuilder()
      ]
    },
    ...modes
  ];
}
getFocusModes.getQueryKey = () => ['focus', 'modes', 'list'];

const useFocusModes = createQueryHook(getFocusModes, 'modes', {
  cacheTime: 0,
  staleTime: 0
});

async function addFocusMode(
  constraints: Map<TaskBuilder['id'], Constraint | null>,
  infos: Partial<Omit<SavedFocusMode, 'tasks' | 'id'>> &
    Pick<SavedFocusMode, 'title'>
) {
  const mode: Omit<SavedFocusMode, 'id'> = {
    description: '',
    excerpt: '',
    ...infos,
    tasks: Array.from(constraints.entries())
      .map(([id, constraint]) => ({
        id,
        constraint
      }))
      .filter((t) => t.constraint) as SavedFocusMode['tasks']
  };

  const res = await Auth.execRoute((token) =>
    focusmode.templates.add(mode, { token })
  );
  queryClient.invalidateQueries(getFocusModes.getQueryKey());
  if (res.code !== 200)
    throw new Error('Could not save focus mode: ' + res.data);
}

async function removeFocusMode(id: SavedFocusMode['id']) {
  await Auth.execRoute((token) =>
    focusmode.templates.remove({ id }, { token })
  );
  queryClient.invalidateQueries(getFocusModes.getQueryKey());
}

const FocusModeActions = {
  getFocusModes,
  useFocusModes,
  addFocusMode,
  removeFocusMode
};

export default FocusModeActions;
