import { language } from '@/index';

export const getPredefinedTemplateNames = () => ({
  'begrueßungsnachricht-kennenlernen': {
    name: language.text.welcome_message,
    description: language.text.welcome_message_desciption
  },
  'beziehungsnachricht-kennenlernen': {
    name: language.text.relation_building_message,
    description: language.text.relation_building_message_description
  },
  'meeting-pitch-kennenlernen': {
    name: language.text.meeting_pitch,
    description: language.text.meeting_pitch_description
  },
  'calendar-link-kennenlernen': {
    name: language.text.calendar_link,
    description: language.text.calendar_link_description
  },
  'homework-kennenlernen': {
    name: language.text.homework_for_strategy_call,
    description: language.text.homework_for_strategy_call_description
  },
  'no-show-kennenlernen': {
    name: language.text.messages_after_no_show,
    description: language.text.messages_after_no_show_description
  },
  'no-show-calendar-link-kennenlernen': {
    name: language.text.calendar_link_no_show,
    description: language.text.calendar_link_no_show_description
  },
  'wrong-time-kennenlernen': {
    name: language.text.wrong_time,
    description: language.text.wrong_time_explanation
  },
  'no-match-kennenlernen': {
    name: language.text.no_match,
    description: language.text.no_match_explanation
  },
  'follow-up-kennenlernen': {
    name: language.text.follow_up,
    description: language.text.follow_up_description
  },
  'uncategorized': {
    name: language.text.uncategorized,
    description: language.text.uncategorized_template_description
  }
});
