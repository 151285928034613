import { Theme } from '@emotion/react';
import { Box, SxProps, Typography, useTheme } from '@mui/material';
import React from 'react';

export interface CircularProgressIndicatorProps {
  value?: number | null;
  label?: React.ReactNode;
  sx?: SxProps<Theme>;
  min?: number;
  max?: number;
  width?: number;
}

export default function CircularProgressIndicator(
  props: CircularProgressIndicatorProps
) {
  const { min = 0, max = 1 } = props;

  const theme = useTheme();

  const percentage = React.useMemo(
    () => (props.value ?? 0 - min) / (max - min),
    [props.value, min, max]
  );

  return (
    <Box
      sx={{
        ...props.sx,
        aspectRatio: 1,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        m: 1,
        borderRadius: '50%'
      }}>
      {/* label + spacing */}
      <Box
        sx={{
          m: 2,
          p: 1,
          boxSizing: 'border-box',
          borderRadius: '50%',
          aspectRatio: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1,
          minWidth: (theme) => theme.spacing(5),
          minHeight: (theme) => theme.spacing(5)
        }}>
        {props.label
          ? (() => {
              const label = props.label;
              if (typeof label === 'string' || typeof label === 'number')
                return (
                  <Typography
                    textAlign="center"
                    fontSize="1em"
                    className="circularProgressIndicator__label">
                    {label}
                  </Typography>
                );
              return label;
            })()
          : null}
      </Box>
      {/* selector */}
      <Box
        sx={{
          position: 'absolute',
          inset: 0,
          borderRadius: '50%',
          color: (theme) => theme.palette.primary.main,
          zIndex: 0,
          overflow: 'hidden'
        }}>
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle
            cx="50"
            cy="50"
            r="45"
            strokeLinecap="round"
            stroke={theme.palette.background.paper}
            strokeWidth={props.width ?? 4}
            fill="none"
            pathLength={10}
          />
          <circle
            cx="50"
            cy="50"
            r="45"
            strokeLinecap="round"
            stroke="currentColor"
            strokeWidth={props.width ?? 4}
            fill="none"
            pathLength={10}
            strokeDasharray={`${percentage * 10}, ${10 - percentage * 10}`}
            strokeDashoffset={2.5}
          />
        </svg>
      </Box>
    </Box>
  );
}
