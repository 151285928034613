import React from 'react';
import { Box, Divider, Modal, Slide, Typography } from '@mui/material';
import GenericVideoPlayer, {
  GenericVideoPlayerProps
} from './GenericVideoPlayer';
import { language } from '../../Config';
import FancyButton from '../FancyButtons/FancyButton';

export interface GenericVideoModalProps extends GenericVideoPlayerProps {
  open: boolean;
  onClose: (videoEnded: boolean) => void;
  title: string;
  children?: React.ReactNode | React.ReactNode[];
}

const GenericVideoModal = React.forwardRef<
  React.ElementRef<typeof GenericVideoPlayer>,
  GenericVideoModalProps
>(function GenericVideoModal(props, ref) {
  const { open, onClose, children, ...rest } = props;
  const [videoEnded, setVideoEnded] = React.useState(false);

  return (
    <Modal open={open} onClose={() => onClose(videoEnded)}>
      <Box
        onClick={(e) => {
          if (e.currentTarget !== e.target) return;
          props.onClose(videoEnded);
        }}
        sx={{
          'position': 'absolute',
          'display': 'flex',
          'justifyContent': 'center',
          'alignItems': 'center',
          'width': '100vw',
          'height': '100vh',
          'zIndex': 100000,
          'maxWidth': '100vw',
          'maxHeight': '100vh',
          'transition':
            'max-width 0.6s cubic-bezier(0, 0, 0.2, 1) 0ms, max-height 0.6s cubic-bezier(0, 0, 0.2, 1) 0ms',
          '&:focus-visible': {
            outline: 'none'
          }
        }}>
        <Slide in={props.open} timeout={300} direction={'down'}>
          <Box
            sx={{
              'width': '80%',
              'minHeight': '80%',
              // 'position': 'absolute',
              'display': 'flex',
              'flexDirection': 'column',
              'borderRadius': 1,
              '&:focus-visible': {
                outline: 'none'
              },
              'border': 0,
              'backgroundColor': (theme) => theme.palette.background.default,
              'overflow': 'auto'
            }}>
            <Box
              sx={{
                position: 'relative',
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                backgroundColor: (theme) => theme.palette.primary.main,
                color: (theme) => theme.palette.primary.contrastText,
                textAlign: 'center',
                padding: (theme) => theme.spacing(2, 2, 2, 2)
              }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold'
                }}>
                {props.title}
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: 'calc(80% - 16px)',
                width: '80%',
                margin: 'auto'
              }}>
              <Box
                sx={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  maxHeight: '70vh',
                  aspectRatio: '16/9'
                }}>
                <GenericVideoPlayer
                  {...rest}
                  ref={ref}
                  onEnd={() => {
                    setVideoEnded(true);
                    rest.onEnd?.();
                  }}
                />
              </Box>
            </Box>
            <Divider orientation="horizontal" />
            <Box
              sx={{
                position: 'relative',
                px: 2,
                py: 1,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center'
              }}>
              {children ?? (
                <FancyButton
                  variant="outlined"
                  sx={{
                    px: 4
                  }}
                  onClick={() => props.onClose(videoEnded)}>
                  {language.text.close}
                </FancyButton>
              )}
            </Box>
          </Box>
        </Slide>
      </Box>
    </Modal>
  );
});

export default GenericVideoModal;
