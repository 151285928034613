import { Box } from '@mui/material';
import React from 'react';
import Loading from './Loading';

const LOADING_STYLES = [
  {
    type: 'rainbow',
    props: {
      rainbow: false
    }
  },
  { type: 'floating', props: {} }
  //, { type: 'circular', props: {} }
] as const;

export interface RandomAnimatedLoadingProps {
  show?: boolean;
}

/**
 * Loading page in focus mode, hides itself when not in loading state
 * Fades in and out
 * Randomly chooses loading style
 */
export default function RandomAnimatedLoading(
  props: RandomAnimatedLoadingProps
) {
  // get random loading style every time to component is mounted
  const [style, updateStyle] = React.useReducer(
    () => LOADING_STYLES[Math.floor(Math.random() * LOADING_STYLES.length)],
    LOADING_STYLES[Math.floor(Math.random() * LOADING_STYLES.length)]
  );

  React.useEffect(() => {
    if (props.show) updateStyle();
  }, [props.show]);

  return (
    <Box
      sx={{
        position: 'absolute',
        inset: 0,
        background: (theme) => theme.palette.background.paper,
        opacity: props.show ? 1 : 0,
        pointerEvents: props.show ? 'all' : 'none',
        transition: (theme) =>
          theme.transitions.create('opacity', {
            duration: theme.transitions.duration.enteringScreen
          }),
        zIndex: 1000
      }}>
      <Loading type={style.type} {...style.props} />
    </Box>
  );
}
