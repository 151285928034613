import React from 'react';
import CategorizeTask from './CategorizeTask';
import CategorySelector from '../_general/ContactView/CategorySelector';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { ArrowBackIcon, CommentAltIcon, FancyButton } from 'ui-utils';
import HUDSidePanel from '@/components/HUD/HUDSidePanel';
import LinkedInWebview from '@common/Webview.renderer/Base/LinkedInWebview';
import HUDFocusBar from '@/components/HUD/HUDFocusBar';
import ContactDisplay from '../_general/ContactView/ContactDisplay';
import { language } from '@/index';
import StaticContactChat from '../_general/ContactView/StaticContactChat';
import Logger from 'electron-log';
import { useScheduler } from 'focus-mode-scheduler';
import { wait } from '@idot-digital/generic-helpers';

export interface CategorizeTaskUIProps {
  task: CategorizeTask;
}

export default function CategorizeTaskUI(props: CategorizeTaskUIProps) {
  const { task } = props;
  const { complete, skip } = useScheduler();

  const [page, setPageState] = React.useState<'chat' | 'categorize'>(
    'categorize'
  );
  const setPage = (page: 'chat' | 'categorize') => {
    setPageState((oldPage) => {
      lastPage.current = oldPage;
      return page;
    });
  };
  const lastPage = React.useRef(page);

  const [chatReady, setChatReady] = React.useState(false);
  const [error, setError] = React.useState(false);
  React.useEffect(() => {
    if (task.chatLoaded) {
      setChatReady(true);
      setError(task.chatError);
      return;
    }

    let mounted = true;
    const onChatLoaded = () => {
      if (mounted) setChatReady(true);
    };
    const onError = () => {
      if (mounted) setError(true);
    };

    task.on('chat-loaded', onChatLoaded);
    task.on('chat-error', onError);
    return () => {
      mounted = false;
      task.off('chat-loaded', onChatLoaded);
      task.off('chat-error', onError);
    };
  }, [task]);

  const couldNotLoadLinkedInPage = React.useRef(false);

  return (
    <>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          width: '100%',
          background: 'background.paper'
        }}>
        <HUDSidePanel>
          <Box flex={1} />
          <ContactDisplay
            contact={{
              name: task.profile.firstname + ' ' + task.profile.lastname,
              firstname: task.profile.firstname,
              lastname: task.profile.lastname,
              pictures: task.profile.pictures as {
                [resolution: string]: string;
              }
            }}
            onClick={() => setPage(page === 'chat' ? 'categorize' : 'chat')}
          />
          <CategorySelector
            onFinished={async (result) => {
              task.setSelected(result);
              await complete({ itemsDone: 1 });
            }}
            onSkip={skip.bind(null, 'instance')}
            loading={!chatReady}
            sx={{ pt: 0, height: 'auto' }}
          />
          <Box sx={{ flex: '0 1 72px' }} />
          <Box flex={1} />
        </HUDSidePanel>
        <HUDFocusBar>
          <CategorizeFocusBarButtons
            chatLoading={!chatReady}
            hasChat={task.hasProfileChat()}
            page={page}
            setPage={(page) => setPage(page)}
          />
        </HUDFocusBar>

        <Box
          width="100%"
          flex="1"
          sx={{
            boxSizing: 'border-box',
            paddingRight: {
              md: '180px',
              lg: '180px',
              xl: '180px'
            }
          }}>
          {page === 'chat' && task.hasProfileChat() && (
            <StaticContactChat
              messages={task.getMessages() ?? []}
              profile={task.profile}
              unreadCount={task.unreadCount}
            />
          )}
          <LinkedInWebview
            visible={page === 'categorize'}
            startURL={task.getProfileURL()}
            features={[
              'prevent-leave-profile',
              'disable-connecting',
              'hide-connect-button',
              'focus-mode-style'
            ]}
            onOpenChat={() => {
              // enqueueSnackbar(
              //   'Du kannst keine Nachrichten schreiben, während du dich vernetzt.',
              //   { variant: 'info' }
              // );
              return false;
            }}
            on404={(webview) => {
              if (couldNotLoadLinkedInPage.current) {
                Logger.error(
                  `[FocusMode:Categorize] Could not load LinkedIn page of ${task.profile.profileID} | ${task.profile.publicIdentifier ?? 'undefined'}`
                );
                // already tried opening page with profileID -> remove contact and skip
                task.removeContact();
                skip('instance');
              } else {
                const newURL = task.getProfileURL(true);
                Logger.info(
                  `[FocusMode:Categorize] Could not load LinkedIn page with publicIdentifier (${task.profile.publicIdentifier ?? 'undefined'}), trying with profileID: ${newURL}`
                );
                couldNotLoadLinkedInPage.current = true;
                webview.loadURL(newURL);
              }
            }}
          />
        </Box>
      </Box>
      <Dialog open={error}>
        <DialogTitle>{language.text.error_while_loading}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {language.text.error_while_loading_linkedin_data}
            {language.text.error_while_loading_categorization_data_choice}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FancyButton
            fType={{ promise: true }}
            variant="contained"
            color="primary"
            onClick={() => {
              skip('instance');
              return wait(5000);
            }}>
            {language.text.skip}
          </FancyButton>
          <FancyButton
            fType={{ promise: true }}
            variant="contained"
            color="error"
            onClick={async () => {
              await task.removeContact();
              skip('instance');
              return wait(5000);
            }}>
            {language.text.delete}
          </FancyButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

// small helper component to render the buttons in the bottom focus bar
// just to keep the code of the main component more compact and clean
function CategorizeFocusBarButtons(props: {
  hasChat: boolean;
  chatLoading?: boolean;
  page: 'chat' | 'categorize';
  setPage: (page: 'chat' | 'categorize') => void;
}) {
  let buttons: React.ReactNode;
  const openChatButton = (
    <FancyButton
      fType={{ promise: true }}
      loading={props.chatLoading}
      // keys are needed to prevent react from reusing the buttons -> color change has a transition and looks weird
      key={'openChatButton'}
      size="large"
      sx={{
        'flex': '1 1 50%',
        '&.MuiButton-contained.Mui-disabled': {
          backgroundColor: (theme) => theme.palette.secondary.main
        }
      }}
      variant="contained"
      color="secondary"
      onClick={() => props.setPage('chat')}
      startIcon={<CommentAltIcon />}
      disabled={!props.hasChat}>
      {props.hasChat
        ? language.text.open_chat
        : language.text.no_chat_available}
    </FancyButton>
  );

  const backToCategoriesButton = (
    <Button
      key={'backToCategoriesButton'}
      size="large"
      sx={{
        backgroundColor: '#e0e0e0',
        color: (theme) => theme.palette.secondary.contrastText,
        ml: 4,
        flexGrow: 1
      }}
      color="inherit"
      variant="contained"
      onClick={() => props.setPage('categorize')}
      startIcon={<ArrowBackIcon />}>
      {language.text.back}
    </Button>
  );

  if (props.page === 'chat') buttons = <>{backToCategoriesButton}</>;
  else buttons = <>{openChatButton}</>;

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      sx={{ mr: 2 }}
      gap={2}>
      {buttons}
    </Box>
  );
}
