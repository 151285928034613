import { Iterators as IteratorsClass } from '@idot-digital/generic-helpers';

export class Iterators extends IteratorsClass {
  public static async *merge<
    T extends { [key in Key]: number | string },
    Key extends string
  >(key: Key, ...i: AsyncGenerator<T>[]): AsyncGenerator<T> {
    const iterators = await Promise.all(
      i.map(async (i) => ({
        current: await i.next(),
        iterator: i
      }))
    );

    while (true) {
      const max = iterators.reduce<{
        current: IteratorResult<T>;
        iterator: AsyncGenerator<T>;
      } | null>((max, current) => {
        if (current.current.done) return max;
        if (!max) return current;
        if (max.current.done) return current;
        return current.current.value[key] > max.current.value[key]
          ? current
          : max;
      }, null);

      if (!max) break;

      yield max.current.value;

      max.current = await max.iterator.next();
    }
  }
}
