import React from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import { wait } from '@idot-digital/generic-helpers';

export interface ProgressBarProps extends BoxProps {
  /**
   * percentage from 0 to 1
   */
  from?: number;
  to?: number;
  onFinished?: (percentage: number) => void;
  delay?: number;
  getLabel?: (percent: number) => string;
  disableWaveAnimation?: boolean;
  speedModifier?: number;
  colors?: Partial<{
    bar: string;
    background: string;
    label: string;
  }>;
}

const WIDTH_ANIMATION_DURATION = 3000;
const FPS = 30;
// for some weird reason the animation only takes 1/2 of the time (so we double the frames)
const TOTAL_FRAMES = (WIDTH_ANIMATION_DURATION / 1000) * FPS * 2;

// https://easings.net
// function ease(x: number): number {
//   return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2;
// }
function ease(x: number): number {
  return -(Math.cos(Math.PI * x) - 1) / 2;
}

const ProgressBar = React.forwardRef<HTMLDivElement, ProgressBarProps>(
  (props, ref) => {
    const {
      from: _from,
      to: _to,
      onFinished,
      delay,
      getLabel,
      disableWaveAnimation,
      speedModifier,
      colors,
      ...rest
    } = props;
    // init with precentage and not to to enable width animation
    const to = React.useRef(_from ?? 0);
    const [current, setCurrent] = React.useState(_from ?? 0);
    const from = React.useRef(_from ?? 0);
    const frame = React.useRef<number>(0);

    const mounted = React.useRef(true);
    React.useEffect(() => {
      mounted.current = true;
      return () => {
        mounted.current = false;
      };
    }, []);

    React.useEffect(() => {
      if (typeof _to === 'number') {
        to.current = _to;
        frame.current = 0;
      }
    }, [_to]);
    React.useEffect(() => {
      if (typeof _from === 'number') {
        from.current = _from;
        frame.current = 0;
        setCurrent(_from);
      }
    }, [_from]);

    React.useEffect(() => {
      const frames = TOTAL_FRAMES / (speedModifier ?? 1);
      let interval: number | null = null;
      (async () => {
        if (delay) await wait(delay);

        interval = setInterval(() => {
          const interval_length = to.current - from.current;

          if (
            Math.round(interval_length * 10000) === 0 ||
            frame.current >= frames ||
            !mounted.current
          )
            return;

          // increase which frame we are on
          frame.current++;
          if (frame.current === frames && onFinished) {
            onFinished(to.current);
          }

          const progress = frame.current / frames;
          const easedProgress = ease(progress);

          const newValue = from.current + easedProgress * interval_length;

          const clampedValue = Math.min(Math.max(newValue, 0), 1);

          setCurrent(clampedValue);
        }, 1000 / FPS) as unknown as number;
      })();

      return () => {
        if (interval) clearInterval(interval);
      };
    }, []);

    return (
      <Box
        {...rest}
        ref={ref}
        sx={{
          width: '100%',
          minWidth: (theme) => theme.spacing(12),
          height: (theme) => theme.spacing(10),
          boxShadow: (theme) => theme.shadows[3],
          padding: (theme) => theme.spacing(1),
          borderRadius: 1.5,
          boxSizing: 'border-box',
          animation: 'g 5000ms infinite cubic-bezier(0.87, 0, 0.13, 1)',
          backgroundColor:
            colors?.background ?? ((theme) => theme.palette.primary.main),
          ...rest.sx
        }}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden'
          }}>
          <Box
            sx={{
              width: `${current * 100}%`,
              transition: `width 100ms ease-in-out`,
              position: 'absolute',
              left: 0,
              top: 0,
              height: '100%',
              backgroundColor:
                colors?.bar ?? ((theme) => theme.palette.background.default),
              borderRadius: 1,
              zIndex: 2,

              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            {!disableWaveAnimation && (
              <Box
                sx={{
                  'zIndex': 1,
                  'width': (theme) => theme.spacing(11),
                  'height': (theme) => theme.spacing(11),
                  'animation':
                    'r 6000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5)',
                  'position': 'absolute',
                  'right': (theme) => theme.spacing(-2),
                  'top': (theme) => theme.spacing(-1.5),
                  'backgroundColor':
                    colors?.bar ??
                    ((theme) => theme.palette.background.default),
                  'borderRadius': '40%',
                  '@keyframes r': {
                    from: { transform: 'rotate(0deg)' },
                    to: { transform: 'rotate(360deg)' }
                  }
                }}
              />
            )}

            <Typography
              component="span"
              variant="h4"
              sx={{
                zIndex: 2,
                color: colors?.label ?? ((theme) => theme.palette.primary.main)
              }}>
              {getLabel ? getLabel(current) : `${current * 100}%`}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
);

export default ProgressBar;
