import { auth } from '@digital-sun-solutions/cloud-functions';
import CodeLogin from './CodeLogin';
import Auth from '@common/AuthManager/Auth.renderer';
import { language } from '@/index';
import { AuthError, AuthWarning } from '@common/AuthManager/AuthErrors';
import { Box } from '@mui/material';
import queryClient from '@/other/QueryClient';
import AccountActions from '@/data/DataServer/Account';

export interface SSCCodeLoginProps {
  onError: (error: AuthError) => void;
  onWarning: (warning: AuthWarning) => void;
  next: () => void;
}

export default function SSCCodeLogin(props: SSCCodeLoginProps) {
  return (
    <Box maxWidth="800px" mx="auto">
      <CodeLogin
        onLogin={async (email, code) => {
          const result = await auth.login(
            {
              email,
              code: code.toLocaleUpperCase()
            },
            {}
          );

          switch (result.code) {
            case 400:
              return language.text.wrong_email_or_code;
            case 429:
              return language.text.login_error_too_many_requests;
            case 500:
              return language.text.login_error_internal_server_error;
            case 200:
              break;
            default:
              return language.text.generic_error;
          }

          Auth.setRefreshToken(result.data);

          queryClient.invalidateQueries(
            AccountActions.getUserData.getQueryKey()
          );

          const { error, warning } = await Auth.checkAccountValidity();
          if (error) {
            props.onError(error);
            return false;
          }
          if (warning) {
            props.onWarning(warning);
          }

          props.next();
          return true;
        }}
        requestCode={async (email) => {
          const res = await auth.requestLoginCode(
            {
              email
            },
            {}
          );
          if (res.code === 500)
            return language.text.login_error_internal_server_error;
          if (res.code === 429)
            return language.text.login_error_too_many_requests;
          return res.code === 200;
        }}
      />
    </Box>
  );
}
