import React from 'react';
import {
  Badge,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Styles } from '@/other/UtilTypes';
import { Theme } from '@mui/material';
import { useAutoAnimate } from '@formkit/auto-animate/react';

export type RouteButtonProps = {
  icon: React.ReactNode;
  label: React.ReactNode;
  activeColor?: string | ((theme: Theme) => string);
  disabled?: boolean;
  badge?: number;
  onlyIcon?: boolean;
  rootPath?: string;
  marginLeft?: number;
  iconStyle?: object;
} & (
  | {
      link: string;
    }
  | {
      active: boolean;
      link?: string;
      // if returned true the link will be navigated to
      onClick: (active: boolean) => boolean | undefined;
    }
);

const styles = {
  iconWrapper: {
    padding: (theme) => theme.spacing(1),
    svg: {
      height: (theme) => theme.spacing(3.25),
      width: (theme) => theme.spacing(3.25)
    }
  },
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
} satisfies Styles;

export default function RouteButton(props: RouteButtonProps) {
  const navigate = useNavigate();
  const { pathname: path } = useLocation();

  const [animationRef] = useAutoAnimate();

  const active = React.useMemo(() => {
    if ('active' in props) return props.active;
    const completeLink = props.link.startsWith('/')
      ? props.link
      : (props.rootPath ?? '') + '/' + props.link;
    return path.startsWith(completeLink);
  }, [path, props]);

  const click = React.useCallback(() => {
    // if onClick is defined, call it and if it returns true, navigate to link (if link is defined)
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- onClick can be undefined
    if ('onClick' in props && props.onClick) if (!props.onClick(active)) return;
    if (props.link)
      navigate(
        props.link.startsWith('/')
          ? props.link
          : (props.rootPath ?? '') + '/' + props.link
      );
  }, [props, navigate]);

  return (
    <ListItemButton
      onClick={click}
      sx={{
        ...(active && {
          'color': props.activeColor ?? ((theme) => theme.palette.primary.main),
          '&:hover': {
            color: props.activeColor ?? ((theme) => theme.palette.primary.main)
          }
        }),
        marginLeft: `${props.marginLeft ?? 0}px`,
        borderRadius: (theme) => theme.shape.borderRadius,
        p: 1,
        m: 1,
        ...(!props.onlyIcon && {
          'background': (theme) => theme.palette.background.default,
          '&:hover': {
            background: (theme) => theme.palette.background.paper
          }
        })
      }}
      ref={animationRef}
      disabled={props.disabled}>
      <ListItemIcon
        sx={{
          ...styles.iconWrapper,
          ...props.iconStyle,
          minWidth: 0,
          color: active
            ? 'currentColor'
            : (theme) => theme.palette.action.disabled
        }}>
        <Badge
          badgeContent={props.badge ?? 0}
          color="error"
          invisible={!props.badge}>
          {props.icon}
        </Badge>
      </ListItemIcon>
      {!props.onlyIcon && (
        <ListItemText sx={{ ml: 1 }}>
          <Typography sx={styles.label} fontWeight={active ? 600 : 500}>
            {props.label}
          </Typography>
        </ListItemText>
      )}
    </ListItemButton>
  );
}
