import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slider,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Check, Error, Info } from '@mui/icons-material';
import { language } from '@/index';
import { DiscountCoupon } from '@common/types/ApiTypes';
import AffiliateActions from '@/data/DataServer/Affiliate';
import { FancyButton, useDebounced } from 'ui-utils';
import { Money } from '@idot-digital/generic-helpers';

const MAX_NAME_LENGTH = 30;
const MAX_CODE_LENGTH = 30;

const DISCOUNT_MAX = 30;
const DISCOUNT_STEP = 5;

export interface AffiliateCreateDiscountCodeProps {
  open: boolean;
  onClose: () => void;
}

export default function AffiliateCreateDiscountCode(
  props: AffiliateCreateDiscountCodeProps
) {
  const { appPrice } = AffiliateActions.useAppPrice();

  const [name, setName] = React.useState('');
  const [code, setCode] = React.useState('');
  const [percentage_off, setPercentage_off] =
    React.useState<DiscountCoupon['percentage_off']>(0);

  const { value: debouncedCode } = useDebounced(code, 500);
  const [isCodeAvailable, setIsCodeAvailable] = React.useState<{
    available: boolean;
    allowed: boolean;
  }>({
    available: true,
    allowed: true
  });
  const [codeAvailableLoading, setCodeAvailableLoading] = React.useState(false);

  React.useEffect(() => {
    let mounted = true;

    (async () => {
      if (debouncedCode === '')
        return setIsCodeAvailable({ available: true, allowed: true });
      try {
        setCodeAvailableLoading(true);
        const isAvailable =
          await AffiliateActions.isCodeAvailable(debouncedCode);
        if (mounted) setIsCodeAvailable(isAvailable);
      } finally {
        if (mounted) setCodeAvailableLoading(false);
      }
    })();

    return () => {
      mounted = false;
    };
  }, [debouncedCode]);

  const changed = React.useMemo(() => {
    if (name !== '') return true;
    if (code !== '') return true;
    if (percentage_off !== 0) return true;
    return false;
  }, [name, code, percentage_off]);

  const canSave = (() => {
    if (name === '') return false;
    if (code === '') return false;
    if (code !== debouncedCode) return false;
    if (codeAvailableLoading) return false;
    if (!isCodeAvailable.available) return false;
    if (!isCodeAvailable.allowed) return false;
    return true;
  })();

  const [confirmQuit, setConfirmQuit] = React.useState(false);
  const onClose = () => {
    if (!changed) props.onClose();
    else setConfirmQuit(true);
  };

  React.useEffect(() => {
    if (props.open) return;
    setName('');
    setCode('');
    setPercentage_off(0);
  }, [props.open]);

  return (
    <>
      <Dialog open={props.open} onClose={onClose}>
        <DialogTitle>{language.text.create_discount_coupon}</DialogTitle>
        <Box
          sx={{
            my: 2,
            mx: 4,
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            minWidth: 'min(80vw, 500px)'
          }}>
          <TextField
            label={language.text.coupon_name}
            value={name}
            variant="standard"
            onChange={(event) => {
              setName(event.target.value.slice(0, MAX_NAME_LENGTH));
            }}
            InputProps={{
              endAdornment: (
                <>
                  <Typography variant="caption">
                    {`${name.length}/${MAX_NAME_LENGTH}`}
                  </Typography>
                  <Tooltip
                    enterDelay={500}
                    placement="right"
                    title={
                      <Typography>
                        {language.text.discount_coupon_name_explanation}
                      </Typography>
                    }>
                    <Info
                      color="disabled"
                      fontSize="small"
                      sx={{ cursor: 'pointer', ml: 1 }}
                    />
                  </Tooltip>
                </>
              )
            }}
          />
          <TextField
            label={language.text.code}
            value={code}
            variant="standard"
            onChange={(event) => {
              setCode(
                event.target.value
                  .toLocaleUpperCase()
                  .replace(/[^A-Z0-9\-]/g, '')
                  .slice(0, MAX_CODE_LENGTH)
              );
            }}
            error={!isCodeAvailable.available || !isCodeAvailable.allowed}
            InputProps={{
              endAdornment: (
                <>
                  {code === '' ? undefined : codeAvailableLoading ||
                    code !== debouncedCode ? (
                    <CircularProgress size={20} />
                  ) : !isCodeAvailable.available || !isCodeAvailable.allowed ? (
                    <Tooltip
                      placement="right"
                      title={
                        <Typography>
                          {!isCodeAvailable.available
                            ? language.text.code_already_in_use
                            : language.text.code_not_allowed}
                        </Typography>
                      }>
                      <Error
                        color="error"
                        fontSize="small"
                        sx={{ cursor: 'pointer' }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      placement="right"
                      title={
                        <Typography>{language.text.code_available}</Typography>
                      }>
                      <Check
                        color="success"
                        fontSize="small"
                        sx={{ cursor: 'pointer' }}
                      />
                    </Tooltip>
                  )}
                  <Typography variant="caption" sx={{ width: 40, ml: 1 }}>
                    {`${code.length}/${MAX_CODE_LENGTH}`}
                  </Typography>
                  <Tooltip
                    enterDelay={500}
                    placement="right"
                    title={
                      <Typography>{language.text.code_explanation}</Typography>
                    }>
                    <Info
                      color="disabled"
                      fontSize="small"
                      sx={{ cursor: 'pointer', ml: 1 }}
                    />
                  </Tooltip>
                </>
              )
            }}
          />
          <Box
            pt={1}
            boxSizing="border-box"
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="flex-end"
            gap={2}>
            <Typography variant="caption" sx={{ mb: 1, textAlign: 'center' }}>
              <Tooltip
                enterDelay={500}
                placement="left"
                title={
                  <Typography>
                    {language.text.disocunt_coupon_provision_explanation}
                  </Typography>
                }>
                <Info
                  color="disabled"
                  fontSize="small"
                  sx={{ cursor: 'pointer' }}
                />
              </Tooltip>
              <br />
              {language.text.discount} <br />
              {language.text.provision}
            </Typography>
            <Slider
              sx={{ flexGrow: 1, mx: 4, mb: 6 }}
              value={percentage_off}
              step={DISCOUNT_STEP}
              marks={Array.from({
                length: DISCOUNT_MAX / DISCOUNT_STEP + 1
              }).map((_, index) => ({
                value: index * DISCOUNT_STEP,
                label: (
                  <Box
                    sx={{
                      userSelect: 'none',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}>
                    <Typography
                      variant="caption"
                      sx={{
                        color:
                          percentage_off === index * DISCOUNT_STEP
                            ? (theme) => theme.palette.text.primary
                            : (theme) => theme.palette.action.disabled
                      }}>
                      {`${index * DISCOUNT_STEP}%`}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        color:
                          percentage_off === index * DISCOUNT_STEP
                            ? (theme) => theme.palette.text.primary
                            : (theme) => theme.palette.action.disabled
                      }}>
                      {`${Money.stringifyCents(
                        appPrice
                          ? (0.5 - (index * DISCOUNT_STEP) / 100) *
                              appPrice.amount
                          : 0,
                        appPrice?.currency
                      )}`}
                    </Typography>
                  </Box>
                )
              }))}
              min={0}
              max={DISCOUNT_MAX}
              onChange={(_, value) => {
                setPercentage_off(value as DiscountCoupon['percentage_off']);
              }}
            />
          </Box>
        </Box>
        <DialogActions>
          <Button variant="contained" color="neutral" onClick={onClose}>
            {language.text.cancel}
          </Button>
          <FancyButton
            fType={{ promise: true }}
            variant="contained"
            color="primary"
            disabled={!canSave}
            onClick={async () => {
              try {
                await AffiliateActions.createDiscountCoupon({
                  code,
                  name,
                  percentage_off
                });
                props.onClose();
              } catch (e) {
                throw e;
              }
            }}>
            {language.text.save}
          </FancyButton>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmQuit} onClose={() => setConfirmQuit(false)}>
        <DialogTitle>{language.text.unsaved_changes}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {language.text.you_have_unsaved_changes}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="neutral"
            onClick={() => setConfirmQuit(false)}>
            {language.text.back}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setConfirmQuit(false);
              props.onClose();
            }}>
            {language.text.dont_save}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
