import React from 'react';
import { Box, BoxProps, Button, ButtonProps } from '@mui/material';
import OptionalTooltip from '../../OptionalTooltip/OptionalTooltip';

export type DisabledTooltipProps<
  Ele extends React.FunctionComponent<
    Partial<Pick<ButtonProps, 'disabled' | 'fullWidth'>>
  > = typeof Button
> = React.ComponentProps<Ele> & {
  component?: Ele;
  /**
   * Tooltip to show when button is disabled
   */
  disabledTooltip?: React.ReactNode;
  /**
   * Tooltip to show when button is enabled (also shown when disabled when not disabledTooltip is passed)
   */
  tooltip?: React.ReactNode;
  /**
   * Box is wrapped around Button to fire mouse over events, here you can pass props to the Box
   */
  boxProps?: BoxProps;
};

const DisabledTooltip = React.forwardRef(
  <
    Ele extends React.FunctionComponent<
      Partial<Pick<ButtonProps, 'disabled' | 'fullWidth'>>
    > = typeof Button
  >(
    props: DisabledTooltipProps<Ele>,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const { component, disabledTooltip, tooltip, boxProps, ...buttonProps } =
      props;

    const Component = component || Button;

    return (
      <OptionalTooltip
        title={props.disabled ? (disabledTooltip ?? tooltip) : tooltip}>
        {/* box is needed as element to fire mouse over events since disabled buttons have "pointer-events: none" and don't fire the events */}
        <Box
          {...boxProps}
          sx={{
            width: 'fullWidth' in props && props.fullWidth ? '100%' : undefined,
            ...boxProps?.sx
          }}>
          <Component {...buttonProps} ref={ref} />
        </Box>
      </OptionalTooltip>
    );
  }
);

export default DisabledTooltip;
