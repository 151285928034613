import { LinkedInRequestService } from '../services/LinkedInRequestService';
import { Options } from '../client/Utils';

export class GraphQLRequest {
  constructor(readonly requestInstance: LinkedInRequestService) {}

  public async graphQLRequest<T>({
    variables,
    queryId,
    options
  }: {
    variables: Record<string, unknown>;
    queryId: string;
    options: Options;
  }): Promise<T | null> {
    return this.requestInstance.get<T>('graphql', options, {
      params: {
        'variables': GraphQLRequest.linkedInStringify(variables),
        '': '',
        queryId
      },
      headers: {
        accept: 'application/graphql'
      }
    });
  }

  private static linkedInStringify(obj: Record<string, unknown>): string {
    return JSON.stringify(obj)
      .replaceAll('"', '')
      .replaceAll('{', '(')
      .replaceAll('}', ')')
      .replaceAll('[', 'List(')
      .replaceAll(']', ')');
  }
}
