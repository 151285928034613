import { LinkedInAccount } from 'linkedin-domain-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Contact } from '@/data/Classes/Contact';

export interface ManualModeContextType {
  openLinkedIn: (
    url:
      | string
      | Pick<LinkedInAccount, 'publicIdentifier'>
      | Pick<Contact, 'profileID'>
  ) => void;
  openChat: (contact: Pick<Contact, 'profileID' | 'conversationID'>) => void;
}

export const ManualModeContext = React.createContext<ManualModeContextType>(
  undefined!
);

export function useManualMode() {
  return React.useContext(ManualModeContext);
}

export function ManualModeProvider(props: { children: JSX.Element }) {
  const navigate = useNavigate();

  const exportValue: ManualModeContextType = {
    openLinkedIn: async (contact) => {
      const id =
        typeof contact === 'object'
          ? 'publicIdentifier' in contact
            ? contact.publicIdentifier
            : 'profileID' in contact
              ? contact.profileID
              : null
          : contact;

      if (!id) return;
      contact = `https://www.linkedin.com/in/${id}`;
      const webview = document.querySelector('.manualModeFrame webview');
      if (webview)
        //@ts-ignore
        webview.src = contact;
      navigate('linkedin');
    },
    openChat: (contact) => {
      const conversationID = contact.conversationID;
      if (conversationID)
        navigate(
          `/manual/chat?conversationID=${encodeURIComponent(
            conversationID
          )}&profileID=${encodeURIComponent(contact.profileID)}`
        );
      else
        navigate(
          `/manual/chat?profileID=${encodeURIComponent(contact.profileID)}`
        );
    }
  };

  return (
    <ManualModeContext.Provider value={exportValue}>
      {props.children}
    </ManualModeContext.Provider>
  );
}
