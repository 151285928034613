import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';
import Menu from '../Items/Menu';

import { ArrowBack, Delete } from '@mui/icons-material';
import MainConfig from '@common/config/MainConfig';
import { Objects } from '@idot-digital/generic-helpers';
import SetupActions from '@/data/DataServer/Setup';
import { language } from '@/index';
import {
  BinocularsIcon,
  FancyButton,
  GenericVideoModal,
  GenericVideoThumb,
  RemoteControlIcon
} from 'ui-utils';

export default function Onboarding() {
  const [showVideos, setShowVideos] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [currentVideoID, setCurrentVideoID] = React.useState('');
  const [currentVideoTitle, setCurrentVideoTitle] = React.useState('');

  const [resetWarningOpen, setResetWarningOpen] = React.useState(false);

  return (
    <>
      {showVideos ? (
        <>
          <Typography
            variant="h5"
            sx={{
              textAlign: 'center',
              fontWeight: 'bolder'
            }}>
            <ArrowBack
              sx={{
                cursor: 'pointer',
                fontSize: '1.3em',
                marginLeft: '-1.5em',
                marginRight: '0.5em',
                marginBottom: '-0.2em'
              }}
              onClick={() => {
                setShowVideos(false);
              }}
            />
            {language.text.onboarding_video_overview}
          </Typography>
          <Box
            sx={{
              mt: 2,
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
              gap: 2
            }}>
            {Objects.values(MainConfig.videos.onboarding).map(
              (video) =>
                video && (
                  <GenericVideoThumb
                    key={video.videoID}
                    videoID={video.videoID}
                    title={video.title}
                    onClick={() => {
                      setCurrentVideoID(video.videoID);
                      setCurrentVideoTitle(video.title);
                      setOpen(true);
                    }}
                  />
                )
            )}
          </Box>
        </>
      ) : (
        <>
          <Typography
            variant="h5"
            sx={{
              textAlign: 'center',
              fontWeight: 'bolder'
            }}>
            {language.text.onboarding}
          </Typography>
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              justifyContent: 'center',
              gap: 2
            }}>
            <Menu
              icon={<BinocularsIcon sx={{ height: '100%', width: 'auto' }} />}
              onClick={() => {
                setShowVideos(true);
              }}
              title={language.text.onboarding_video_overview}
            />
            <Menu
              icon={
                <RemoteControlIcon sx={{ height: '100%', width: 'auto' }} />
              }
              title={language.text.reset_onboarding}
              onClick={() => setResetWarningOpen(true)}
            />
          </Box>
        </>
      )}
      <GenericVideoModal
        open={open}
        onClose={() => setOpen(false)}
        videoID={currentVideoID}
        title={currentVideoTitle}
      />
      <Dialog
        open={resetWarningOpen}
        onClose={() => setResetWarningOpen(false)}>
        <DialogTitle>{language.text.confirm_reset}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {language.text.confirm_reset_description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="neutral"
            onClick={() => setResetWarningOpen(false)}>
            {language.text.cancel}
          </Button>
          <FancyButton
            fType={{ promise: true }}
            color="error"
            variant="contained"
            startIcon={<Delete />}
            onClick={async () => {
              await SetupActions.resetOnboardingSteps();
              setResetWarningOpen(false);
            }}>
            {language.text.reset}
          </FancyButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
