import { TextEditorHelper } from 'ui-utils';

export interface TextBlock {
  type: TextType;
  value: string;
}

export type TextType = 'text' | 'link' | 'mention' | 'linebreak';

const PARSER = [
  {
    type: 'link',
    regex: /(https?:\/\/[^\s]+)/g
  },
  {
    type: 'mention',
    // mention is @[firstname] [lastname]
    regex: /(@[^\s]+(\s)[^\s]+)/g,
    // split with regex always returns " " after each match
    modifyBlocks: (blocks: string[]) => {
      const newBlocks: string[] = [];
      for (const [index, block] of blocks.entries()) {
        if (index % 3 === 2) continue;
        newBlocks.push(block);
      }
      return newBlocks;
    }
  },
  {
    type: 'linebreak',
    regex: /(\n)/g,
    replace: (_: string) => ''
  }
] as const;

export function parseText(text: string): TextBlock[] {
  // parse non breaking spaces and U+2028 line separators
  text = TextEditorHelper.replaceSpecialChars(text);

  let textBlocks: TextBlock[] = [{ type: 'text', value: text }];

  for (const parser of PARSER) {
    let newTextBlocks: TextBlock[] = [];
    for (const textBlock of textBlocks) {
      if (textBlock.type !== 'text') {
        newTextBlocks.push(textBlock);
        continue;
      }

      let splitText = textBlock.value.split(parser.regex);

      if ('modifyBlocks' in parser) splitText = parser.modifyBlocks(splitText);

      for (const [index, text] of splitText.entries()) {
        if (text === '') continue;
        if (index % 2 === 0) {
          newTextBlocks.push({
            type: 'text',
            value: text
          });
        } else {
          newTextBlocks.push({
            type: parser.type,
            value: 'replace' in parser ? parser.replace(text) : text
          });
        }
      }
    }
    textBlocks = newTextBlocks;
  }

  return textBlocks;
}
