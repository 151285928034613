import { language } from '@/index';
import { Task, TaskHeader, TaskConfig } from 'focus-mode-scheduler';
import { TaskIncomingEventMap } from 'focus-mode-scheduler/src/Task/Task';

export default class AudienceHolderTask extends Task {
  public static get config(): TaskConfig {
    return {
      id: 'audience-holder',
      hide: true,
      info: {
        title: language.text.audience_holder,
        description: language.text.find_audience_holders,
        avaContent: language.text.ava_hint_focus_audience_holder,
        hideNoMoreInstancesInfo: true,
        hideNoInstancesInfo: true
      },
      constraintBreachedDialogContent: {
        time: Task.DEFAULT_CONTRAINT_BREACHED_DIALOG_TEXTS.time,
        items: {
          header: language.text.enough_audience_holder_selected,
          body: language.text.enough_audience_holder_selected_goto_next_step,
          buttons: {
            next: {
              text: language.text.next_step
            },
            oneMore: {
              amount: 1,
              text: language.text.one_more
            }
          }
        }
      }
    };
  }

  protected get _header(): TaskHeader {
    return {
      title: language.text.audience_holder,
      subtitle: language.text.find_audience_holders
    };
  }

  protected async handleEvent<Type extends keyof TaskIncomingEventMap>(
    type: Type
  ) {
    switch (type) {
      case 'complete':
      case 'skip':
        this.internalEmit('finished', undefined);
        break;
    }
  }
}
