import React from 'react';

export interface AvaContextType {
  modalHeight: number;
  setModalHeight: (value: number) => void;
}

export const ManualModeContext = React.createContext<AvaContextType>(
  undefined!
);

export function useAvaContext() {
  return React.useContext(ManualModeContext);
}

export function AvaContextProvider(
  props: { children: JSX.Element } & AvaContextType
) {
  const { children, ...rest } = props;
  const exportValue: AvaContextType = {
    ...rest
  };

  return (
    <ManualModeContext.Provider value={exportValue}>
      {children}
    </ManualModeContext.Provider>
  );
}
