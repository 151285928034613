import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import { GlobalStyles, ThemeProvider } from '@mui/material';
import { QueryClientProvider } from 'react-query';
import { HashRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { WaitFor } from 'ui-utils';
import queryClient from './other/QueryClient';
import declareGlobalVars from '@/globals/globalVars';
import MainRouter from './Routes/MainRouter';
import IPCHandler from './globals/IPCHandler';
import BetaFlag from './components/BetaFlag/BetaFlag';
import ErrorPage from './Routes/Error/ErrorPage';
import AudienceHolderSnackBar from './components/AudienceHolderSnackBar/AudienceHolderSnackBar';
import muiTheme from './globals/mui';
import init from './other/Init';

// load fonts from assets
import './assets/fonts.css';

const { language, uploadFiles } = init();
export { language, uploadFiles };

const declaseGlobalsPromise = declareGlobalVars();

const root = document.getElementById('root');
if (root && !root.hasChildNodes())
  createRoot(root).render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={muiTheme}>
          {/* custom scrollbar styles */}
          <GlobalStyles
            styles={{
              '*': {
                '&::-webkit-scrollbar': {
                  width: muiTheme.spacing(1),
                  height: muiTheme.spacing(1)
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: muiTheme.palette.grey[200],
                  borderRadius: `min(${muiTheme.spacing(0.5)}, ${
                    muiTheme.shape.borderRadius
                  }px)`
                }
              }
            }}
          />

          <HashRouter>
            <SnackbarProvider
              style={{
                fontFamily: muiTheme.typography.fontFamily,
                fontSize: muiTheme.typography.body1.fontSize,
                lineHeight: muiTheme.typography.body1.lineHeight
              }}
              Components={{
                audienceHolder: AudienceHolderSnackBar
              }}
              maxSnack={3}>
              <WaitFor
                errorComponent={ErrorPage}
                errorMessage="Error while loading global variables (declaseGlobalsPromise)"
                promise={declaseGlobalsPromise}
                // use arrow function to make sure that the component is only rendered when the promise is resolved
                component={() => (
                  <>
                    {/* <ConversationRefetcher /> */}
                    <BetaFlag />
                    <IPCHandler />
                    <MainRouter />
                  </>
                )}
              />
            </SnackbarProvider>
          </HashRouter>
        </ThemeProvider>
      </QueryClientProvider>
    </StrictMode>
  );
