import React from 'react';
import PulsatingButton, {
  PulsatingButtonProps
} from '../PulsatingButton/PulsatingButton';
import ProgressPromiseButton from './ProgressPromiseButton';

export type PulsatingProgressPromiseButtonProps = PulsatingButtonProps<
  typeof ProgressPromiseButton
>;

const PulsatingProgressPromiseButton = React.forwardRef(
  (
    props: PulsatingProgressPromiseButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    return (
      <PulsatingButton
        ref={ref}
        //@ts-ignore
        component={ProgressPromiseButton}
        {...props}
      />
    );
  }
);

export default PulsatingProgressPromiseButton;
