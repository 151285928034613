import React from 'react';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import { Contact } from '@/data/Classes/Contact';
import { CommentAltIcon, FancyButton } from 'ui-utils';
import { Delete, LinkedIn } from '@mui/icons-material';
import { language } from '@/index';
import { ContactType } from '@common/types/enums';
import ContactTags from '@/components/Contact/ContactTags';
import ContactAvatar from '@/components/Avatar/ContactAvatar';

export interface ContactItemProps {
  contact: Contact;
  onClick: () => void;
  currentType: ContactType;
  onOpenChat: () => void;
  onDelete: () => void;
  itemsPerLine: number;
  marked?: boolean;
}

const ContactItem = React.forwardRef<HTMLButtonElement, ContactItemProps>(
  function ContactItem(props, ref) {
    return (
      <Grid item xs={12 / props.itemsPerLine}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: (theme) => theme.spacing(1),
            height: '100%',
            boxSizing: 'border-box',
            cursor: props.marked ? undefined : 'pointer'
          }}
          onClick={props.marked ? undefined : () => props.onClick()}>
          <ContactAvatar
            contact={props.contact}
            sx={{
              width: (theme) => theme.spacing(12),
              height: (theme) => theme.spacing(12),
              cursor: props.marked ? undefined : 'pointer'
            }}
            rootSx={{
              mb: (theme) => theme.spacing(-5)
            }}
            borderSizeModifier={1.5}
          />
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
              borderRadius: 1.5,
              padding: (theme) => theme.spacing(5, 1, 1, 1),
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '100%',
              minHeight: (theme) => theme.spacing(21),
              width: '100%',
              marginTop: (theme) => theme.spacing(1),
              boxSizing: 'border-box'
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%'
              }}>
              <Typography
                variant="h6"
                textAlign="center"
                component="p"
                fontSize="1.1rem !important">
                {props.contact.name}
              </Typography>

              {!props.marked && (
                <ContactTags
                  contact={props.contact}
                  width="100%"
                  overflow="hidden"
                  flexWrap="wrap"
                  justifyContent="center"
                  gap={1}
                  mb={1}
                />
              )}

              {props.currentType === ContactType.POTENTIAL_CUSTOMER && (
                <Typography variant="caption" textAlign="center">
                  {props.contact.getActiveStep()?.name || '-'}
                </Typography>
              )}
            </Box>

            <Box
              display="flex"
              gap={1}
              alignItems="center"
              justifyContent="center">
              {!props.marked && (
                <Tooltip
                  title={
                    props.contact.hasChat
                      ? language.text.open_chat_with.replace(
                          '{name}',
                          props.contact.name
                        )
                      : language.text.no_chat_with.replace(
                          '{name}',
                          props.contact.name
                        )
                  }>
                  <Box
                    component="span"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}>
                    <Button
                      disabled={!props.contact.hasChat}
                      variant="text"
                      size="small"
                      color="primary"
                      sx={{
                        padding: (theme) => theme.spacing(0.5),
                        minWidth: 0
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.onOpenChat();
                      }}>
                      <CommentAltIcon
                        sx={{
                          fontSize: '1.5em',
                          boxSizing: 'border-box'
                        }}
                      />
                    </Button>
                  </Box>
                </Tooltip>
              )}
              <Tooltip title={language.text.open_profile_in_browser}>
                <FancyButton
                  fType={{
                    promise: true
                  }}
                  variant="text"
                  size="small"
                  color="primary"
                  sx={{
                    padding: (theme) => theme.spacing(0.5),
                    minWidth: 0
                  }}
                  onClick={async (e) => {
                    e.stopPropagation();
                    await window.api.send(
                      'url:open_ext',
                      props.contact.profileURL
                    );
                  }}>
                  <LinkedIn
                    sx={{
                      fontSize: '2em'
                    }}
                  />
                </FancyButton>
              </Tooltip>
              {props.currentType === ContactType.AUDIENCE_HOLDER && (
                <Button
                  ref={ref}
                  variant="text"
                  size="small"
                  color="error"
                  sx={{
                    padding: (theme) => theme.spacing(0.5),
                    minWidth: 0
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.onDelete();
                  }}>
                  <Delete
                    sx={{
                      fontSize: '2em'
                    }}
                  />
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  }
);

export default ContactItem;
