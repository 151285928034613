import WebViewLinkedIn from '@common/Webview.renderer/WebviewLinkedIn';
import { createQueryHook } from './QueryHelper';

export const useLinkedInData = createQueryHook(
  async (url: string) => {
    const base64 = await WebViewLinkedIn.fetchData(url);
    const blob = await fetch(base64).then((res) => res.blob());
    return blob;
  },
  'blob',
  {
    staticQueryKey(...args) {
      return ['linkedin', 'fetch-data', ...args];
    }
  }
);
