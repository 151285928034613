import { LanguageTexts } from './LanguageTexts';
import {
  EventHandler,
  EventPayload,
  MessageBusMessages
} from './MessageBusMessages';

export interface GenericUtilLanguageManager {
  text: LanguageTexts;
  getLanguage(): string;
}

let language: GenericUtilLanguageManager = {
  text: {} as any,
  getLanguage: () => 'en'
};

export interface GenericUtilMessageBus {
  use<Event extends MessageBusMessages['name']>(
    type: Event,
    callback: EventHandler<Event>
  ): void;
  emit<Event extends MessageBusMessages['name']>(
    type: Event,
    payload: EventPayload<Event>,
    id?: string
  ): void;
}

let messageBus: GenericUtilMessageBus = {
  use: () => undefined,
  emit: () => undefined
};

const GenericComponents = {
  setLanguageManager(manager: GenericUtilLanguageManager) {
    language = manager;
  },
  setMessageBus(bus: GenericUtilMessageBus) {
    messageBus = bus;
  }
};

export default GenericComponents;
export { language, messageBus };
