import { StartCountIterator } from './StartCountIterator';
import { LinkedInConnection } from 'linkedin-domain-types';
import { Options, trace } from '../client/Utils';

export type FetchConnections = (
  start: number,
  count: number,
  sortType: 'RECENTLY_ADDED' | 'LASTNAME_FIRSTNAME' | 'FIRSTNAME_LASTNAME',
  options: Options,
  keyword?: string
) => Promise<Iterable<LinkedInConnection>>;

@trace()
export class ConnectionIterator implements AsyncIterator<LinkedInConnection> {
  private readonly pageIterator: ConnectionPageIterator;

  private entries: LinkedInConnection[] = [];
  private index = 0;

  constructor(
    start: number,
    count: number,
    readonly fetch: FetchConnections,
    sortType: 'RECENTLY_ADDED' | 'LASTNAME_FIRSTNAME' | 'FIRSTNAME_LASTNAME',
    options: Options,
    keyword?: string
  ) {
    this.pageIterator = new ConnectionPageIterator(
      start,
      count,
      fetch,
      sortType,
      options,
      keyword
    );
  }

  async next(): Promise<
    IteratorResult<LinkedInConnection, LinkedInConnection>
  > {
    if (this.index >= this.entries.length) {
      const { done, value } = await this.pageIterator.next();
      if (done) {
        return { done: true, value: {} as LinkedInConnection };
      }
      this.entries.push(...value);
    }
    return { done: false, value: this.entries[this.index++] };
  }

  async prev(): Promise<
    IteratorResult<LinkedInConnection, LinkedInConnection>
  > {
    if (this.index <= 0) {
      const { done, value } = await this.pageIterator.prev();
      if (done) {
        return { done: true, value: {} as LinkedInConnection };
      }
      this.entries.unshift(...value);
      this.index = this.entries.length;
    }
    return { done: false, value: this.entries[--this.index] };
  }
}

@trace()
class ConnectionPageIterator extends StartCountIterator<LinkedInConnection> {
  constructor(
    start: number,
    count: number,
    readonly fetchConnections: FetchConnections,
    readonly sortType:
      | 'RECENTLY_ADDED'
      | 'LASTNAME_FIRSTNAME'
      | 'FIRSTNAME_LASTNAME',
    readonly options: Options,
    readonly keyword?: string
  ) {
    super({
      start,
      count
    });
  }

  protected async fetch(): Promise<Iterable<LinkedInConnection>> {
    return this.fetchConnections(
      this.start,
      this.count,
      this.sortType,
      this.options,
      this.keyword
    );
  }
}
