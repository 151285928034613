import { apiRetry as _apiRetry } from './FetchRetryFunctions';
import log from 'electron-log';

const apiRetry = <
  Fct extends (...args: any[]) => Promise<any>,
  R extends { code: number; data: any } = Awaited<ReturnType<Fct>>,
  Args extends Parameters<Fct> = Parameters<Fct>,
  AcceptCodes extends number = 200
>(
  fct: Fct,
  options?: {
    tries?: number;
    acceptCodes?: AcceptCodes[];
    rejectCodes?: number[];
  },
  ...args: Args
) =>
  _apiRetry<R, Args, AcceptCodes>(
    {
      error: (message: string, ...args: any[]) => {
        log.error(message, ...args);
        console.error(message, ...args);
      }
    },
    fct,
    options,
    ...args
  );

export default apiRetry;
