import { LinkedInRequestService } from '../services/LinkedInRequestService';
import {
  ConversationId,
  DashProfileUrn,
  GetMessagesResponse,
  MessageMessageUrn,
  SendMessageResponse
} from 'linkedin-domain-types';
import {
  generateTrackingId,
  generateUUID,
  Logger,
  Options,
  trace
} from '../client/Utils';

@trace()
export class MessageRequest {
  private requestService: LinkedInRequestService;

  constructor({ requestService }: { requestService: LinkedInRequestService }) {
    this.requestService = requestService;
  }

  getMessages({
    conversationId,
    createdBefore = new Date(Date.now()),
    options
  }: {
    conversationId: ConversationId;
    createdBefore: Date;
    options: Options;
  }): Promise<GetMessagesResponse | null> {
    Logger.debug(
      `Getting messages for ${conversationId}, before ${createdBefore.getDate()}`
    );
    return this.requestService.get<GetMessagesResponse>(
      `messaging/conversations/${conversationId}/events`,
      options,
      {
        params: {
          keyVersion: 'LEGACY_INBOX',
          ...(createdBefore && { createdBefore: createdBefore.getTime() })
        }
      },
      undefined,
      `GetMessages${conversationId.substring(0, 8).toUpperCase()}${
        Date.now() % 1000
      }`
    );
  }

  sendMessage({
    conversationId,
    text,
    options
  }: {
    conversationId: ConversationId;
    text: string;
    options: Options;
  }): Promise<SendMessageResponse | null> {
    Logger.debug(`Sending message to ${conversationId}: ${text.slice(0, 8)}…`);
    return this.requestService.post<SendMessageResponse>(
      `messaging/conversations/${conversationId}/events`,
      {
        eventCreate: {
          originToken: generateUUID(),
          value: {
            'com.linkedin.voyager.messaging.create.MessageCreate': {
              attributedBody: {
                text,
                attributes: []
              },
              attachments: []
            }
          }
        },
        dedupeByClientGeneratedToken: false
      },
      options,
      {
        params: {
          action: 'create'
        },
        fullResponse: false
      },
      undefined,
      `SendMessage${conversationId.substring(0, 8).toUpperCase()}|${
        text.length > 8 ? `${text.substring(0, 8)}…` : text
      }`
    );
  }

  sendDirectMessage({
    profileId,
    text,
    options
  }: {
    profileId: DashProfileUrn;
    text: string;
    options: Options;
  }): Promise<SendMessageResponse | null> {
    Logger.debug(
      `Sending message to ${profileId.getContent()}: ${text.slice(0, 8)}…`
    );
    return this.requestService.post<SendMessageResponse>(
      `messaging/conversations`,
      {
        keyVersion: 'LEGACY_INBOX',
        conversationCreate: {
          eventCreate: {
            originToken: generateUUID(),
            value: {
              'com.linkedin.voyager.messaging.create.MessageCreate': {
                attributedBody: {
                  text,
                  attributes: []
                },
                attachments: [],
                body: text,
                mediaAttachments: []
              }
            },
            trackingId: generateTrackingId()
          },
          dedupeByClientGeneratedToken: false,
          recipients: [profileId.stripPrefixes()],
          subtype: 'MEMBER_TO_MEMBER'
        }
      },
      options,
      {
        params: {
          action: 'create'
        }
      },
      undefined,
      `DirectMsg${profileId.getContent().slice(-8).toUpperCase()}|${
        text.length > 8 ? `${text.substring(0, 8)}…` : text
      }`
    );
  }

  setMessageReaction({
    messageUrn,
    emoji,
    status,
    options
  }: {
    messageUrn: MessageMessageUrn;
    emoji: string;
    status: boolean;
    options: Options;
  }): Promise<boolean> {
    Logger.debug(
      `${status ? 'Reacting' : 'Unreacting'} to message ${messageUrn
        .getContent()
        .slice(-8)} with ${emoji}`
    );
    return this.requestService
      .post(
        `voyagerMessagingDashMessengerMessages?${new URLSearchParams({
          action: `${status ? '' : 'un'}reactWithEmoji`
        }).toString()}`,
        {
          messageUrn: messageUrn.getFullUrn(),
          emoji
        },
        options,
        undefined,
        undefined,
        `${status ? '' : 'un'}react${messageUrn
          .getContent()
          .slice(-8)}|${emoji}`
      )
      .then(() => true)
      .catch(() => false);
  }
}
