export default class LocalKeyValue<T, N = T> {
  private path: string;
  private defaultValue: N;
  private data: { [key: string]: T };
  constructor(path: string, defaultValue: N) {
    this.path = path;
    this.defaultValue = defaultValue;
    this.data = {};
    window.api.handle('lkv:updated', this.listener.bind(this));
  }
  public async load(): Promise<void> {
    this.data = await window.api.send('lkv:load', this.path);
  }
  public get(key: string): T | N {
    if (this.data[key] === undefined) return this.defaultValue;
    return this.data[key];
  }
  public async set(key: string, value: T): Promise<void> {
    this.data[key] = value;
    await this.save();
  }
  public async delete(key: string): Promise<void> {
    delete this.data[key];
    await this.save();
  }
  private async save(): Promise<void> {
    await window.api.send('lkv:save', {
      subPath: this.path,
      data: this.data
    });
  }
  private listener(
    _: any,
    {
      subPath,
      data
    }: {
      subPath: string;
      data: { [key: string]: T };
    }
  ) {
    if (subPath !== this.path) return;
    this.data = data;
  }

  public unmount() {
    window.api.remove('lkv:updated', this.listener);
  }
}
