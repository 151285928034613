import { Box, Chip, Tooltip, Typography } from '@mui/material';
import { language } from '@/index';
import AppActions from '@/data/DataServer/App';

export default function VersionSettings() {
  const { hash } = AppActions.useCurrentVersionHash();
  const { hash: latestHash } = AppActions.useLatestVersionHash();
  const isLatest = hash && latestHash && hash === latestHash;
  const isDev = globalThis.RELEASE_CHANNEL === 'DEV';
  return (
    <Box width="100%" boxSizing="border-box">
      <Typography variant="h4">
        {language.text.version}

        <Chip
          sx={{ ml: 2 }}
          label={AppActions.getVersion()}
          variant="outlined"
          color="primary"
        />
        {isDev ? null : isLatest ? (
          <Chip
            sx={{ ml: 1 }}
            label={language.text.newest_version_installed}
            variant="outlined"
            color="success"
          />
        ) : (
          <Tooltip title={language.text.install_update_now}>
            <Chip
              onClick={() => window.location.reload()}
              sx={{ ml: 1 }}
              label={language.text.update_available}
              variant="outlined"
              color="warning"
            />
          </Tooltip>
        )}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          maxWidth: 500
        }}>
        Build: {(hash ?? '').slice(0, 6)}
      </Typography>
    </Box>
  );
}
