import React from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import { useSetup } from '../../SetupContext';
import { language } from '@/index';
import { FancyButton } from 'ui-utils';

export default function SetupConfirmations() {
  const { completeConfirmations } = useSetup();

  const [premiumChecked, setPremiumChecked] = React.useState(false);
  const [toolsChecked, setToolsChecked] = React.useState(false);
  const [readyChecked, setReadyChecked] = React.useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        p: 2,
        boxSizing: 'border-box',
        overflow: 'auto'
      }}>
      <Box flex="1 0 0" display="flex" alignItems="flex-end">
        <Check color="primary" sx={{ fontSize: '5rem' }} />
      </Box>
      <Typography variant="h2">{language.text.starting_soon}</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 2
        }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={premiumChecked}
              onChange={(_, checked) => setPremiumChecked(checked)}
            />
          }
          label={language.text.we_recomment_linkedin_premium}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={toolsChecked}
              onChange={(_, checked) => setToolsChecked(checked)}
            />
          }
          label={language.text.i_dont_use_external_tools}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={readyChecked}
              onChange={(_, checked) => setReadyChecked(checked)}
            />
          }
          label={language.text.im_ready_to_find_customers}
        />
      </Box>
      <FancyButton
        disabled={!premiumChecked || !toolsChecked || !readyChecked}
        variant="contained"
        color="primary"
        fType={{ promise: true, pulsating: 'default' }}
        onClick={() => {
          completeConfirmations();
          return new Promise(() => {});
        }}
        size="large">
        {language.text.lets_go}
      </FancyButton>
      <Box flex="1 0 0" display="flex" alignItems="flex-end">
        <Typography
          variant="body2"
          sx={{
            justifySelf: 'flex-end',
            maxWidth: 'min(80vw, 600px)',
            textAlign: 'justify'
          }}>
          {language.text.linkedin_detecting_warning}
        </Typography>
      </Box>
    </Box>
  );
}
