import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Skeleton
} from '@mui/material';
import { FancyButton, Loading } from 'ui-utils';
import { Constraints } from './Constraints';
import { TaskBuilder } from '../lib';
import React from 'react';
import { language } from '../Config';

export interface ConstraintDialogsProps {
  constraints: Pick<Constraints, 'use' | 'emit'>;
  dialogs: TaskBuilder['constraintBreachedDialogContent'] | null;
}

export interface DialogContent {
  header: string;
  body: React.ReactNode;
  buttons: {
    next: {
      text: string;
      icon?: React.ReactNode;
    };
    oneMore?: {
      text: string;
      amount: number;
      icon?: React.ReactNode;
    };
  };
}

export default function ConstraintDialogs(props: ConstraintDialogsProps) {
  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [showDialog, setShowDialog] = React.useState(false);
  // stores current dialog content to be displayed
  // no state needed since opening and closing of dialog (showDialog) rerenders anyways
  const dialogContent = React.useRef<DialogContent | null>(null);
  props.constraints.use('constraint:breach', (constraint) => {
    dialogContent.current = props.dialogs?.[constraint.type] ?? null;
    setShowDialog(true);
  });
  props.constraints.use('no-instances', ({ taskname, hadInstances }) => {
    dialogContent.current = {
      header:
        language.text[hadInstances ? 'noMoreInstances' : 'noInstances'](
          taskname
        ),
      body: language.text[
        hadInstances ? 'noMoreInstancesDescription' : 'noInstancesDescription'
      ],
      buttons: {
        next: {
          text: language.text.ok
        }
      }
    };
    setShowDialog(true);
  });

  return (
    <Dialog open={showDialog}>
      <DialogTitle>
        {dialogContent.current?.header ?? language.text.loading}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {dialogContent.current?.body ?? <Loading />}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {dialogContent.current?.buttons ? (
          <>
            {dialogContent.current.buttons.oneMore && (
              <FancyButton
                variant="outlined"
                color="primary"
                startIcon={dialogContent.current.buttons.oneMore.icon}
                onClick={() => {
                  if (!dialogContent.current?.buttons.oneMore) return;
                  props.constraints.emit('constraint:choice', {
                    type: 'increase',
                    amount: dialogContent.current.buttons.oneMore.amount
                  });
                  setShowDialog(false);
                }}>
                {dialogContent.current.buttons.oneMore.text}
              </FancyButton>
            )}
            <FancyButton
              fType={{ promise: true }}
              variant="contained"
              color="primary"
              startIcon={dialogContent.current.buttons.next.icon}
              onClick={() => {
                if (!dialogContent.current?.buttons.next) return;
                props.constraints.emit('constraint:choice', { type: 'skip' });
                setShowDialog(false);
              }}>
              {dialogContent.current.buttons.next.text}
            </FancyButton>
          </>
        ) : (
          <FancyButton fType={{ promise: true }} loading>
            <Skeleton variant="text" width={100} />
          </FancyButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
