import React from 'react';
import ContactChat from '../_general/ContactView/ContactChat';
import ContactView from '../_general/ContactView/ContactView';
import { Box, Typography } from '@mui/material';
import RelationshipMenu from '../_general/ContactView/RelationshipMenu';
import HUDSidePanel from '@/components/HUD/HUDSidePanel';
import ContactDisplay from '../_general/ContactView/ContactDisplay';
import ChatTutorialTask from './ChatTutorialTask';
import { SendMessage } from '@/components/Chat/ChatHistory';
import { language } from '@/index';
import { YouTubePlayerEle } from 'ui-utils/src/components/GenericVideoPlayer/YouTubePlayer/YouTubePlayer';
import { isYouTubeVideo } from 'ui-utils/src/components/GenericVideoPlayer/VideoHelper';
import { useScheduler } from 'focus-mode-scheduler';
import ManagedOnboardingVideo from '../_general/OnboardingVideo/ManagedOnboardingVideo';
import QuestionForm from '../Question/QuestionForm';
import { Question } from '@common/PipelineManager/PipelineTypes';
import { Center } from 'ui-utils';

export interface ChatTutorialUITaskProps {
  task: ChatTutorialTask;
}

export default function ChatTutorialTaskUI(props: ChatTutorialUITaskProps) {
  const { task } = props;
  const { complete } = useScheduler();

  const [page, setPage] = React.useState<'question' | 'chat'>('chat');

  const [responses, setResponses] = React.useState<SendMessage[]>([]);
  const [question, setQuestion] = React.useState<Question | null>(null);
  const [video, setVideo] = React.useState(task.initialVideo);
  const videoRef = React.useRef<YouTubePlayerEle | null>(null);
  const isVideoYT = React.useMemo(
    () => (video ? isYouTubeVideo(video.videoID) : false),
    [video]
  );
  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  React.useEffect(() => {
    if (responses.length) return;
    setResponses(task.initialMessages);
  }, [task]);

  const onSend = async (message: string) => {
    if (!video?.fullscreen) {
      // for fullscreen video, pauseVideo throws an error (unknown reason)
      if (isVideoYT) videoRef.current?.pauseVideo();
    }

    const response = await task.send(message);
    if (!mounted.current) return;
    if (!response) complete({ itemsDone: 1 });
    else {
      setResponses((responses) => [...responses, ...response.messages]);
      setVideo(response.video ?? null);
      setQuestion(response.question);
      if (response.question) setPage('question');
      else setPage('chat');
    }
  };

  return (
    <>
      <ContactView
        contact={task.contact}
        page={page}
        pages={[
          {
            id: 'chat',
            component: (
              <ContactChat
                fillTemplateFromChat
                chat={task.chat}
                onSend={onSend}
                currentStep={task.activeStep}
                templates={task.templates}
                sendMessages={responses}
              />
            ),
            keepMounted: true
          },
          {
            id: 'question',
            component: question ? (
              <QuestionForm
                title={question.question}
                type={question.type}
                options={
                  question.type === 'question-options' ? question.options : []
                }
                onFinished={() => onSend('')}
              />
            ) : (
              <Center fullHeight>
                <Typography color="error" variant="h6">
                  {language.text.generic_error}
                </Typography>
                <Typography variant="caption">
                  error-tutorial-no-question-available
                </Typography>
              </Center>
            ),
            keepMounted: true
          }
        ]}
        sx={{
          boxSizing: 'border-box',
          // extra spacing due to sidebar
          mr: 18,
          width: 'unset'
        }}
      />

      <ManagedOnboardingVideo video={video} fullscreen={video?.fullscreen} />

      <HUDSidePanel>
        <Box flex={1} />
        <ContactDisplay contact={task.chat.contact} />

        <RelationshipMenu
          steps={task.steps}
          currentStep={task.activeStep}
          tooltipPrefix={language.text.cant_recategorize_in_tutorial}
          disabled
          onComplete={async () => {}}
          readonly
        />
        <Box flex={4} />
      </HUDSidePanel>
    </>
  );
}
