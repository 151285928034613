import React from 'react';
import PrintableError from '@common/PrintableError/PrintableError';
import { language } from '..';
import { useTimer } from 'ui-utils';

export type HandleError = <
  Args extends any[] = [],
  ReturnType = void,
  ErrorReturnType = null
>(
  fct: (...args: Args) => Promise<ReturnType | Error | PrintableError>,
  defaultReturn?: ErrorReturnType,
  finallyFct?: () => void
) => (...args: Args) => Promise<ReturnType | ErrorReturnType>;

/**
 * Catch errors and return error as consumable state
 */
const useError = (options?: {
  onError?: (error: Error, errorInfo: string) => void;
  /**
   * Time in ms
   */
  resetAfter?: number;
  defaultErrorString?: string;
}) => {
  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [error, setError] = React.useState<Error | null>(null);
  const [errorInfo, setErrorInfo] = React.useState<string | null>(null);

  const handleError = React.useCallback(
    <Args extends any[], ReturnType, ErrorReturnType = null>(
      fct: (...args: Args) => Promise<ReturnType | Error | PrintableError>,
      defaultReturn?: ErrorReturnType,
      finallyFct?: () => void
    ): ((...args: Args) => Promise<ReturnType | ErrorReturnType>) => {
      const handleError = (error: Error | PrintableError | any) => {
        if (error instanceof PrintableError || error instanceof Error) {
          setError(error);
          const errorInfo =
            error instanceof PrintableError
              ? error.message
              : (options?.defaultErrorString ?? language.text.generic_error);
          setErrorInfo(errorInfo);
          options?.onError?.(error, errorInfo);
          console.error(error);
          return true;
        } else {
          setError(null);
          setErrorInfo(null);
          return false;
        }
      };
      return async (...args: Args) => {
        try {
          const res = await fct(...args);
          if (!mounted.current) return null as ErrorReturnType;
          const hasError = handleError(res ?? null);
          if (!hasError) return res as Awaited<ReturnType>;
          if (defaultReturn !== undefined)
            return defaultReturn as ErrorReturnType;
          return null as ErrorReturnType;
        } catch (error) {
          handleError(error as Error | PrintableError);
          if (defaultReturn !== undefined)
            return defaultReturn as ErrorReturnType;
          return null as ErrorReturnType;
        } finally {
          finallyFct?.();
        }
      };
    },
    []
  );

  const reset = React.useCallback(() => {
    setError(null);
    setErrorInfo(null);
  }, []);

  const { restart: restartTimer } = useTimer(options?.resetAfter ?? 5000, {
    callback: reset,
    startOnMount: false
  });

  React.useEffect(() => {
    if (error) restartTimer();
  }, [error]);

  return {
    error,
    errorInfo,
    handleError,
    reset
  };
};

export default useError;
