import { ConstraintPreset, TaskConfig, TaskOptionsConfig } from '../lib';
import Task from './Task';

interface TaskConstructor {
  config: TaskConfig;
}

export type NextTask<T extends Task = Task> = {
  task: T;
  UI: React.FC;
} | null;

export default abstract class TaskBuilder<T extends Task = Task> {
  constructor(
    public readonly defaultConstraint: ConstraintPreset | null = null
  ) {}

  protected abstract task: TaskConstructor;
  abstract getTask(): Promise<NextTask<T>>;

  public get id() {
    return this.task.config.id;
  }
  public get info() {
    return this.task.config.info;
  }
  public get hide() {
    return this.task.config.hide;
  }
  public get constraintBreachedDialogContent() {
    return this.task.config.constraintBreachedDialogContent;
  }
  public abstract getOptionsConfig(): Promise<TaskOptionsConfig | null>;
  public getStaticUI(): React.FC<{ loading?: boolean }> | null {
    return null;
  }
}
