import {
  EventEmitter,
  EventHandlerAndEmitter,
  Objects
} from '@idot-digital/generic-helpers';
import React from 'react';

const useEventEmitter = <EventMap extends { [event: string]: unknown }>(
  emitter:
    | EventEmitter<EventMap>
    | EventHandlerAndEmitter<object, EventMap>
    | null,
  listeners: Partial<{
    [Type in keyof EventMap]: (data: EventMap[Type]) => void;
  }>
) => {
  React.useEffect(() => {
    if (!emitter) return;
    for (const [type, listener] of Objects.entries(listeners)) {
      emitter.on(type, listener as any);
    }
    return () => {
      for (const [type, listener] of Objects.entries(listeners)) {
        emitter.off(type, listener as any);
      }
    };
  }, [listeners, emitter]);
};

export default useEventEmitter;
