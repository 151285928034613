import { Chip, ChipProps, CircularProgress, useTheme } from '@mui/material';
import { Tag } from '@common/types/ApiTypes';
import React from 'react';
import { Colors } from '@idot-digital/generic-helpers';

export interface TagChipProps extends Omit<ChipProps, 'onDelete'> {
  tag: Tag;
  onDelete?: () => void | Promise<void>;
}

export default function TagChip(props: TagChipProps) {
  const { tag, onDelete, ...chipProps } = props;

  const theme = useTheme();
  const contrastColor = Colors.getContrastColor(tag.color, {
    dark: theme.palette.text.primary,
    light: '#fff',
    background: theme.palette.background.default
  });

  const [loading, setLoading] = React.useState(false);

  return (
    <Chip
      label={props.tag.name}
      onDelete={
        onDelete
          ? async () => {
              try {
                setLoading(true);
                await props.onDelete?.();
              } catch (e) {
                throw e;
              } finally {
                setLoading(false);
              }
            }
          : undefined
      }
      deleteIcon={
        onDelete && loading ? (
          <CircularProgress size={chipProps.size === 'small' ? 16 : 22} />
        ) : undefined
      }
      {...chipProps}
      sx={{
        'color': contrastColor,
        'backgroundColor': props.tag.color,
        '.MuiChip-deleteIcon': {
          'color': contrastColor,
          '&:hover': {
            color: contrastColor,
            filter:
              contrastColor === '#fff' ? 'brightness(0.9)' : 'brightness(1.2)'
          }
        },
        ...chipProps.sx
      }}
    />
  );
}
