import { TaskBuilder, TaskOptionsConfig } from 'focus-mode-scheduler';
import EstablishRelationTask from './EstablishRelationTask';
import { language } from '@/index';
import { Dates } from '@idot-digital/generic-helpers';
import {
  Message,
  MessageTemplate,
  PipelineEvent
} from '@common/types/ApiTypes';
import { ServerPipelineStepType } from '@common/PipelineManager/PipelineTypes';
import { ContactType } from '@common/types/enums';
import ContactActions from '@/data/DataServer/Contact';
import TemplateActions from '@/data/DataServer/Templates';
import { SSCChat } from '@/data/Classes/Chat/SSCChat';
import { Contact } from '@/data/Classes/Contact';
import EstablishRelationTaskUI from './EstablishRelationTaskUI';
import { BindProps, MapProps } from 'ui-utils';
import { NextTask } from 'focus-mode-scheduler/src/Task/TaskBuilder';
import { Pipelines } from '@common/PipelineManager/Pipelines';
import OnboardingVideo from '../_general/OnboardingVideo/OnboardingVideo';
import { getSelf } from '@/data/LinkedIn/Account';
import { Iterators } from '@/other/Iterators';
import WebViewLinkedIn from '@common/Webview.renderer/WebviewLinkedIn';

interface InstanceData {
  chat: SSCChat;
  contact: Contact;
  templates: MessageTemplate[];
  pipelineHistory: PipelineEvent[];
  template: MessageTemplate | null;
  messages: Message[];
}

export default class EstablishRelationTaskBuilder extends TaskBuilder<EstablishRelationTask> {
  protected task = EstablishRelationTask;

  protected iterator: AsyncGenerator<InstanceData> | null = null;
  protected getIterator(): AsyncGenerator<InstanceData> {
    if (this.iterator) return this.iterator;
    const pipelineDef = Pipelines.getPipelineDefinition();
    const buildRelationStep = pipelineDef.main_flow.find(
      (s) => s.type === 'buildRelationship'
    );

    this.iterator = Iterators.filterNull(
      Iterators.map(
        Iterators.merge(
          'priority',
          // contact with a chat and unanswered messages
          Iterators.fromApiV2<Contact>(async (cursor) =>
            ContactActions.listContacts(cursor, {
              values: {
                stepType: ServerPipelineStepType.BuildRelationship,
                skipped: false,
                type: ContactType.POTENTIAL_CUSTOMER
              },
              has: {
                unansweredMessages: true,
                chat: true
              }
            })
          ),
          // contact without a chat
          // Iterators.filter(
          Iterators.fromApiV2<Contact>(async (cursor) => {
            return ContactActions.listContacts(cursor, {
              values: {
                stepType: ServerPipelineStepType.BuildRelationship,
                skipped: false,
                type: ContactType.POTENTIAL_CUSTOMER
              },
              has: {
                chat: false
              }
            });
          }),
          //   async (contact) => {
          //     // check if we did not miss a chat
          //     if (contact.hasChat) return false;
          //     const profile = await WebViewLinkedIn.getFullProfile(
          //       contact.profileID
          //     );
          //     if (!profile) return false;
          //     if (profile.conversationID) {
          //       // if we missed a chat, load it now and skip this time so it is loaded for next time
          //       await ContactActions.loadChatFromLinkedIn(
          //         profile.conversationID,
          //         {
          //           profile
          //         }
          //       );
          //       return false;
          //     }

          //     return true;
          //   }
          // ),
          // follow up
          Iterators.fromApiV2<Contact>(async (cursor) =>
            ContactActions.listContacts(cursor, {
              values: {
                stepType: ServerPipelineStepType.BuildRelationship,
                skipped: false,
                type: ContactType.POTENTIAL_CUSTOMER
              },
              has: {
                unansweredMessages: false,
                chat: true
              },
              dates: {
                lastMessage: {
                  before: Dates.add(new Date(), -7, 'day')
                }
              }
            })
          )
        ),
        async (contact) => {
          if (!contact.connected) return;
          const chat = await contact
            .getChat()
            // if no chat exists -> create one to start a conversation
            .catch(
              async () =>
                new SSCChat({
                  contact,
                  ownPublicIdentifier: (await getSelf(true, true))
                    .publicIdentifier
                })
            )
            .catch(() => null);
          if (!chat) return null;
          const messages = await Iterators.toArray(
            Iterators.fromApiV2<Message>((cursor) =>
              chat.listMessages(cursor ?? undefined)
            )
          );
          // make sure that last message is either sent by the other person or is older than 6 days (follow up)
          if (
            messages[0]?.sendByYou &&
            Date.now() - messages[0].createdAt.getTime() <
              6 * 24 * 60 * 60 * 1000
          )
            return null;

          const templates = (
            await TemplateActions.getBuildRelationTemplates()
          ).filter(
            (t) =>
              t.templateID ===
              buildRelationStep?.[
                messages.some((m) => m.sendByYou)
                  ? 'relationshipMessage'
                  : 'welcomeMessage'
              ]
          );
          const template = templates[0];
          chat.setTemplate(template);

          const pipelineHistory = await contact.getPipelineHistory();

          return {
            chat,
            contact,
            templates,
            template,
            pipelineHistory,
            messages
          };
        }
      )
    );

    return this.iterator;
  }

  public async getTask(): Promise<NextTask<EstablishRelationTask>> {
    const next = await this.getIterator().next();
    if (next.done) return null;
    const task = new EstablishRelationTask(
      next.value.chat,
      next.value.contact,
      next.value.templates,
      next.value.pipelineHistory,
      next.value.messages
    );
    return {
      task,
      UI: BindProps(EstablishRelationTaskUI, { task })
    };
  }

  public getStaticUI(): React.FC<{ loading?: boolean }> | null {
    return MapProps(
      BindProps(OnboardingVideo, {
        onboardingStep: 'focus:establishRelation',
        completeOn: 'finish'
      }),
      ({ loading }: { loading?: boolean }) => ({ pause: loading })
    );
  }

  public async getOptionsConfig(): Promise<TaskOptionsConfig | null> {
    return Promise.resolve({
      items: {
        defaultValue: 5,
        min: 1,
        max: 60,
        scale: 'logarithmic',
        step: 1
      },
      time: {
        defaultValue: 15,
        max: 60,
        min: 2,
        scale: 'logarithmic',
        step: 1
      },
      defaultValue: {
        type: 'items',
        items: 5
      },
      texts: { taskName: language.text.establish_relation }
    });
  }
}
