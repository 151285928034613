import { LinkedInRequestService } from '../services/LinkedInRequestService';
import {
  DashCompanyUrn,
  DashInvitationUrn,
  DashProfileUrn,
  GetReceivedInvitationsResponse,
  GetSentInvitationsResponse,
  InvitationCreationResponse,
  InvitationType,
  WithdrawConnectionInvitationResponse
} from 'linkedin-domain-types';
import { Logger, Options, trace } from '../client/Utils';

@trace()
export class InviteRequest {
  private requestService: LinkedInRequestService;

  constructor({ requestService }: { requestService: LinkedInRequestService }) {
    this.requestService = requestService;
  }

  sendInvite({
    profileId,
    trackingId,
    message,
    options
  }: {
    profileId: string;
    trackingId: string;
    message: string;
    options: Options;
  }): Promise<unknown> {
    Logger.debug(`Sending invite to profile ${profileId}: "${message}"`);
    return this.requestService.post<unknown>(
      `growth/normInvitations`,
      {
        trackingId,
        emberEntityName: 'growth/invitation/norm-invitation',
        invitee: {
          'com.linkedin.voyager.growth.invitation.InviteeProfile': {
            profileId
          }
        },
        ...(message && { message })
      },
      options,
      undefined,
      undefined,
      `txInv${profileId.slice(0, 32)}`
    );
  }

  getReceivedInvitations({
    start = 0,
    count = 10,
    options
  }: {
    start?: number;
    count?: number;
    options: Options;
  }): Promise<GetReceivedInvitationsResponse | null> {
    Logger.debug(`Getting received invitations ${count}@${start}`);
    return this.requestService.get<GetReceivedInvitationsResponse>(
      `relationships/invitationViews`,
      options,
      {
        params: {
          start,
          count,
          q: 'receivedInvitations'
        }
      },
      undefined,
      `rx<Inv${count}@${start}|${Date.now() % 100000}`
    );
  }

  getSentInvitations({
    start = 0,
    count = 10,
    options
  }: {
    start?: number;
    count?: number;
    options: Options;
  }): Promise<GetSentInvitationsResponse | null> {
    Logger.debug(`Getting sent invitations ${count}@${start}`);
    return this.requestService.get<GetSentInvitationsResponse>(
      `relationships/sentInvitationViewsV2`,
      options,
      {
        params: {
          start,
          count,
          invitationType: 'CONNECTION',
          q: 'invitationType'
        }
      },
      undefined,
      `txInv>${count}@${start}|${Date.now() % 100000}`
    );
  }

  sendInvitation({
    inviteeProfileUrn,
    customMessage,
    options
  }: {
    inviteeProfileUrn: DashProfileUrn;
    customMessage?: string;
    options: Options;
  }): Promise<InvitationCreationResponse | null> {
    Logger.debug(
      `Sending invitation to profile ${inviteeProfileUrn.stripPrefixes()}: "${customMessage}"`
    );
    return this.requestService.post<InvitationCreationResponse>(
      `voyagerRelationshipsDashMemberRelationships`,
      {
        customMessage,
        inviteeProfileUrn: inviteeProfileUrn.getFullUrn()
      },
      options,
      {
        params: {
          action: 'verifyQuotaAndCreate',
          decorationId:
            'com.linkedin.voyager.dash.deco.relationships.InvitationCreationResultWithInvitee-2'
        }
      },
      undefined,
      `txInv>${inviteeProfileUrn.stripPrefixes().slice(-8)}`
    );
  }

  withdrawInvitation({
    invitationUrn,
    invitationType,
    options
  }: {
    invitationUrn: DashInvitationUrn;
    invitationType: InvitationType;
    options: Options;
  }): Promise<WithdrawConnectionInvitationResponse | null> {
    Logger.debug(`Withdrawing invitation ${invitationUrn.stripPrefixes()}`);
    return this.requestService.post<WithdrawConnectionInvitationResponse>(
      `voyagerRelationshipsDashInvitations/${invitationUrn.getFullUrn()}`,
      {
        invitationType
      },
      options,
      {
        params: {
          action: 'withdraw'
        }
      },
      undefined,
      `cancelTxInv${invitationUrn.stripPrefixes().slice(-8)}`
    );
  }

  setFollow({
    urn,
    following = true,
    options
  }: {
    urn: DashCompanyUrn | DashProfileUrn;
    following?: boolean;
    options: Options;
  }): Promise<boolean> {
    Logger.debug(`${following ? 'Follow' : 'Unfollow'} ${urn.stripPrefixes()}`);
    return this.requestService
      .post<unknown>(
        `feed/dash/followingStates/urn:li:fsd_followingState:${urn.getFullUrn()}`,
        {
          patch: {
            $set: {
              following
            }
          }
        },
        options,
        undefined,
        undefined,
        `${following ? '' : 'un'}follow${urn.stripPrefixes().slice(-8)}`
      )
      .then(() => true)
      .catch(() => false);
  }
}
