import React from 'react';
import { Box, BoxProps } from '@mui/material';

import ContactLayout from './ContactLayout';
import { Contact } from '@/data/Classes/Contact';
import { useHistory } from 'ui-utils';

interface PageInfo<Page extends string> {
  id: Page;
  component: React.ReactNode;
  keepMounted?: boolean;
}

export interface ContactViewProps<Page extends string> {
  contact: Contact;
  page?: Page;
  pages?: (PageInfo<Page> | null)[];
  sidebarContent?: (page: Page) => React.ReactNode | React.ReactNode[];
  sx?: BoxProps['sx'];
}

export default function ContactView<Page extends string>(
  props: ContactViewProps<Page>
) {
  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  /*----------- Current open UI -------------*/
  // use pseudo routes instead of actual routing to keep chat loaded to not load again and not loose state of textfield and notes
  const [page, setPageState] = React.useState<Page | null>(
    props.pages?.[0]?.id ?? null
  );
  const history = useHistory(page);

  const setPage = React.useCallback(
    (page?: Page) => {
      page = page ?? history.pop() ?? undefined;
      setPageState(page ?? null);
    },
    [setPageState]
  );

  // react to props.page changes
  React.useEffect(() => {
    if (props.page) setPage(props.page);
  }, [props.page]);

  return (
    <Box
      sx={{
        mr: 17.5,
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...props.sx
      }}>
      <ContactLayout>
        {props.pages?.map((p) =>
          p && (p.keepMounted || p.id === page) ? (
            <Box
              key={p.id}
              display={page === p.id ? undefined : 'none'}
              height="100%"
              width="100%">
              {p.component}
            </Box>
          ) : null
        )}
      </ContactLayout>
    </Box>
  );
}
