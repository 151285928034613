import { Box, Typography, Paper } from '@mui/material';
import { language } from '@/index';
import { AvaIcon, Background, FancyButton } from 'ui-utils';

export default function Maintenance() {
  return (
    <Background
      variant="frame"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 9999,
        height: '100vh',
        display: 'flex',
        width: '100vw'
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          margin: 'auto',
          textAlign: 'center'
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            padding: 0,
            boxSizing: 'border-box'
          }}>
          <AvaIcon width={150} />
        </Box>
        <Paper
          sx={{
            p: 2,
            mb: 15
          }}>
          <Typography
            sx={{
              fontSize: '42px',
              fontWeight: 'bold',
              color: (theme) => theme.palette.primary.main,
              marginTop: (theme) => theme.spacing(3),
              marginBottom: (theme) => theme.spacing(1.5),
              textAlign: 'center'
            }}>
            {language.text.we_are_working_on_the_ssc}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: '20px',
              maxWidth: '500px',
              margin: 'auto',
              textAlign: 'center',
              mb: 2
            }}>
            {language.text.server_maintenance_explanation}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: '20px',
              maxWidth: '500px',
              margin: 'auto',
              textAlign: 'center',
              mb: 2
            }}>
            {language.text.even_the_busiest_bees_need_a_break}
          </Typography>
          <FancyButton
            variant="contained"
            color="primary"
            fType={{ promise: true }}
            onClick={() => window.api.send('app:quit')}>
            {language.text.quit_ssc}
          </FancyButton>
        </Paper>
      </Box>
    </Background>
  );
}
