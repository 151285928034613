import useDebounced from '../../hooks/Debouncer';
import React from 'react';
import CollapsingInput, { CollapsingInputProps } from './CollapsingInput';
import { language } from '../../Config';
import { IconButton } from '@mui/material';
import { Clear } from '@mui/icons-material';

export interface SearchFieldProps
  extends Omit<CollapsingInputProps, 'value' | 'onChange' | 'endAdorment'> {
  value: string;
  onChange: (value: string) => void;
  hideClearButton?: boolean;
}

const SearchField = React.forwardRef<HTMLInputElement, SearchFieldProps>(
  function SearchField(props, ref) {
    const { value, onChange, onKeyDown, hideClearButton, ...rest } = props;

    const [search, setSearch] = React.useState(value);
    React.useEffect(() => {
      setSearch(value);
    }, [value]);

    const { skipDebounce } = useDebounced(search, 500, onChange);

    return (
      <CollapsingInput
        placeholder={language.text.search}
        {...rest}
        ref={ref}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') skipDebounce();
          onKeyDown?.(e);
        }}
        endAdornment={
          !hideClearButton &&
          search.length > 0 && (
            <IconButton
              onClick={() => {
                setSearch('');
                skipDebounce('');
              }}>
              <Clear />
            </IconButton>
          )
        }
      />
    );
  }
);

export default SearchField;
