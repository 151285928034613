import { Box, BoxProps, Typography, Grid, Avatar } from '@mui/material';
import './PeopleGrid.css';
import { language } from '@/index';
import { DeleteOutlinedIcon, PromiseIconButton } from 'ui-utils';
import ContactAvatar from '../Avatar/ContactAvatar';

interface Person {
  profileID: string;
  firstName: string | null;
  lastName: string | null;
  profilePicture?: string | null;
  profilePictureUrl?: Record<number, string> | null;
  priority?: number;
}

export interface PeopleGridProps extends BoxProps {
  people: Person[];
  maxPerLine: number;
  onPersonRemove?: (person: Person) => void | Promise<void>;
}

export default function PeopleGrid(props: PeopleGridProps) {
  const { people, maxPerLine } = props;

  return (
    <Grid
      container
      justifyContent="flex-start"
      columnSpacing={0}
      rowSpacing={0}
      sx={{
        maxHeight: 'calc(100% - 12px)',
        overflowY: 'auto',
        alignContent: 'flex-start'
      }}>
      {people.map((person) => (
        <Grid
          item
          xs={12 / maxPerLine}
          key={person.profileID}
          className="card-person">
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              paddingTop: (theme) => theme.spacing(3)
            }}>
            <Box
              className="card-person-main"
              sx={{
                width: '100%',
                padding: (theme) => theme.spacing(0, 3),
                boxSizing: 'border-box',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
              {person.profilePictureUrl ? (
                <ContactAvatar
                  contact={{
                    pictures: person.profilePictureUrl,
                    firstname: person.firstName ?? '',
                    lastname: person.lastName ?? '',
                    name:
                      (person.firstName ?? '') + ' ' + (person.lastName ?? ''),
                    priority: person.priority
                  }}
                  sx={{
                    border: '3px solid #FFFFFF',
                    aspectRatio: '1'
                  }}
                />
              ) : person.profilePicture ? (
                <Avatar
                  alt={(person.firstName ?? '') + ' ' + (person.lastName ?? '')}
                  src={person.profilePicture ?? undefined}
                  sx={{
                    width: '50%',
                    border: '3px solid #FFFFFF',
                    height: 'unset',
                    aspectRatio: '1/1'
                  }}
                />
              ) : null}
              <Typography
                sx={{
                  marginTop: (theme) => theme.spacing(1),
                  fontWeight: '600',
                  fontSize: maxPerLine > 5 ? '14px' : '18px',
                  textAlign: 'center',
                  letterSpacing: '0.14px',
                  color: '#44444F',
                  opacity: 1
                }}>
                {person.firstName ?? ''} {person.lastName ?? ''}
              </Typography>
            </Box>
            {props.onPersonRemove && (
              <PromiseIconButton onClick={() => props.onPersonRemove?.(person)}>
                <DeleteOutlinedIcon
                  sx={{
                    color: '#44444F'
                  }}
                />
              </PromiseIconButton>
            )}
          </Box>
        </Grid>
      ))}
      {people.length === 0 && (
        <Typography
          sx={{
            color: 'secondary.contrastText',
            mt: (theme) => theme.spacing(1),
            mb: (theme) => theme.spacing(1),
            textAlign: 'center',
            fontSize: {
              md: '1.2rem',
              lg: '1.8rem'
            },
            flexGrow: 1
          }}>
          {language.text.no_persons_added_yet}
        </Typography>
      )}
    </Grid>
  );
}
