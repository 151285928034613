import { Navigate, Route, Routes } from 'react-router-dom';
import PostLoaderStart from './NetworkLoaderStart';
import { NETWORK_LOADER_PATHS } from '@/globals/Paths';
import PostLoaderRun from './NetworkLoaderRun';

export default function NetworkLoaderRouter() {
  return (
    <Routes>
      <Route index element={<Navigate to={NETWORK_LOADER_PATHS.Start} />} />
      <Route path={NETWORK_LOADER_PATHS.Start} element={<PostLoaderStart />} />
      <Route path={NETWORK_LOADER_PATHS.Run} element={<PostLoaderRun />} />
    </Routes>
  );
}
