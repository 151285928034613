import ContactActions from '@/data/DataServer/Contact';
import { Tag } from '@common/types/ApiTypes';
import { Box, CircularProgress, MenuItem } from '@mui/material';
import { language } from '@/index';
import React from 'react';
import { Arrays, Colors } from '@idot-digital/generic-helpers';
import FilterIcon from '../Contact/FilterIcon';
import { LocalOffer } from '@mui/icons-material';

export interface TagFilterProps {
  include: (Tag | Tag['name'])[];
  exclude: (Tag | Tag['name'])[];
  onChange: (tags: { include: Tag[]; exclude: Tag[] }) => void;
  disabled?: boolean;
}

export default function TagFilter(props: TagFilterProps) {
  const { tags, status } = ContactActions.useTags();

  const include = React.useMemo(
    () => props.include.map(getTagName),
    [props.include]
  );
  const exclude = React.useMemo(
    () => props.exclude.map(getTagName),
    [props.exclude]
  );

  const value = React.useMemo(() => {
    const tags: Tag['name'][] = [];
    tags.push(...include.map((tag) => `include|${tag}`));
    tags.push(...exclude.map((tag) => `exclude|${tag}`));
    if (tags.length === 0) tags.push('no_filter|');
    return tags;
  }, [include, exclude]);

  const onClickTag = React.useCallback(
    (tag: Tag['name']) => {
      function mapTags(names: Tag['name'][]): Tag[] {
        return Arrays.filterNull(
          names.map((name) => tags.find((tag) => tag.name === name))
        );
      }
      // is currently included -> move to exclude
      if (include.includes(tag)) {
        props.onChange({
          include: mapTags(include.filter((t) => t !== tag)),
          exclude: mapTags(exclude.concat(tag))
        });
        // is currently excluded -> remove from exclude
      } else if (exclude.includes(tag)) {
        props.onChange({
          include: mapTags(include),
          exclude: mapTags(exclude.filter((t) => t !== tag))
        });
        // is not included or excluded -> include
      } else {
        props.onChange({
          include: mapTags(include.concat(tag)),
          exclude: mapTags(exclude)
        });
      }
    },
    [include, exclude, tags, props.onChange]
  );

  return (
    <FilterIcon
      disabled={props.disabled}
      active={value.length === 0 || !value.includes('no_filter|')}
      icon={<LocalOffer />}>
      <MenuItem
        value="no_filter|"
        divider
        selected={props.include.length === 0 && props.exclude.length === 0}
        onClick={() => props.onChange({ include: [], exclude: [] })}>
        {language.text.no_filter}
      </MenuItem>
      {status === 'success' &&
        tags.map((tag) => {
          const included = props.include.some((t) =>
            typeof t === 'string' ? t === tag.name : t.name === tag.name
          );
          const excluded = props.exclude.some((t) =>
            typeof t === 'string' ? t === tag.name : t.name === tag.name
          );
          return (
            <MenuItem
              value={`tag|${tag.name}`}
              key={tag.name}
              onClick={() => onClickTag(tag.name)}
              sx={
                included || excluded
                  ? {
                      'backgroundColor': (theme) =>
                        Colors.opacify(
                          included
                            ? theme.palette.success.main
                            : theme.palette.error.main,
                          0.2
                        ),
                      '&:hover': {
                        backgroundColor: (theme) =>
                          Colors.opacify(
                            included
                              ? theme.palette.success.main
                              : theme.palette.error.main,
                            0.4
                          )
                      }
                    }
                  : undefined
              }>
              <Box
                sx={{
                  background: tag.color,
                  width: 16,
                  height: 16,
                  borderRadius: 8,
                  mr: 1,
                  verticalAlign: 'middle'
                }}
              />
              {tag.name}
            </MenuItem>
          );
        })}
      {status !== 'success' && (
        <MenuItem value="loading|" disabled>
          <CircularProgress size={16} sx={{ mr: 1 }} />
          {language.text.loading}
        </MenuItem>
      )}
    </FilterIcon>
  );
}

function getTagName(tag: Tag | Tag['name']): Tag['name'] {
  return typeof tag === 'string' ? tag : tag.name;
}
