import WebViewLinkedIn from '@common/Webview.renderer/WebviewLinkedIn';
import { SSCChat } from '../Classes/Chat/SSCChat';
import { getSelf } from '../LinkedIn/Account';
import ContactActions from './Contact';
import { RemoveFirst, createInfiniteQueryHook } from './QueryHelper';
import queryClient from '@/other/QueryClient';
import { LinkedInChat } from '../Classes/Chat/LinkedInChat';
import ChatActions from './Chat';
import TemplateActions from './Templates';
import { ContactType } from '@common/types/enums';

async function listChats(
  lastChat: Pick<SSCChat, 'cursor' | 'lastActivityAt'> | null | undefined,
  type: 'normal' | 'archived' | 'search' = 'normal',
  searchQuery?: string
): Promise<LinkedInChat[]> {
  const ownProfile = await getSelf(true, true);
  const { conversations, cursor } = await (async () => {
    if (type === 'search' && searchQuery) {
      return WebViewLinkedIn.listSearchConversations(
        searchQuery,
        lastChat?.cursor,
        {
          prioritize: true
        }
      );
    } else if (type === 'archived') {
      return WebViewLinkedIn.listArchivedConversations(lastChat?.cursor, {
        prioritize: true
      });
    } else {
      return {
        conversations: await WebViewLinkedIn.listConversations(
          lastChat?.lastActivityAt ?? undefined,
          {
            prioritize: true
          }
        ),
        cursor: undefined
      };
    }
  })();

  const filteredConversations = conversations.filter(
    (conversation) =>
      // disable groups
      conversation.participants.length === 1 &&
      // filter out conversations with unknown participants -> mostly chats with linkedin sales people
      conversation.participants.some((p) => p.profileID !== 'UNKNOWN')
  );

  const profileIDs = filteredConversations
    .filter((chat) => chat.participants.length === 1)
    .map((chat) => chat.participants[0].profileID);

  const contacts = await ContactActions.getContacts(profileIDs);

  const uncategorizedTemplate = (
    await TemplateActions.getUncategorizedTemplate()
  )?.[0];

  const chats = filteredConversations.map((conversation) => {
    const contact =
      conversation.participants.length === 1
        ? contacts.find(
            (contact) =>
              contact.profileID === conversation.participants[0].profileID
          )
        : undefined;

    const chat = new LinkedInChat({
      ownPublicIdentifier: ownProfile.publicIdentifier,
      conversationData: conversation,
      conversationID: conversation.conversationID,
      cursor,
      contact,
      template:
        !contact || contact.type === ContactType.UNCATEGORIZED
          ? uncategorizedTemplate
          : undefined
    });

    return chat;
  });

  return chats;
}
listChats.getQueryKey = (
  ...args: Partial<RemoveFirst<Parameters<typeof listChats>>>
) => [
  'conversation',
  'li',
  'list',
  ...(args[0] ? [args[0]] : []),
  ...(args[0] === 'search' && args[1] ? [args[1]] : [])
];

async function invalidateChatsCache(
  conversationID?: string,
  invalidateSSC = true
) {
  await queryClient.invalidateQueries(listChats.getQueryKey());
  await queryClient.invalidateQueries(
    LinkedInChat.getListMessagesQueryKey(conversationID)
  );
  if (invalidateSSC) ChatActions.invalidateChatsCache(conversationID, false);
}

const useChats = createInfiniteQueryHook(listChats, 'chats', {
  refetchOnMount: false,
  refetchOnWindowFocus: false
});

const LinkedInChatActions = {
  listChats,
  useChats,

  invalidateChatsCache
};

export default LinkedInChatActions;
