import React from 'react';
import { Contact } from '@/data/Classes/Contact';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';
import WebViewLinkedIn from '@common/Webview.renderer/WebviewLinkedIn';
import { language } from '@/index';
import { FancyButton } from 'ui-utils';
import { Delete, Redo } from '@mui/icons-material';

export interface BrokenAudienceHoldersProps {
  audienceHoldersBroken: boolean;
  audienceHolders: Contact[];
  onResolved: (fixedAudienceHolders: Contact[]) => void;
}

export default function BrokenAudienceHolders(
  props: BrokenAudienceHoldersProps
) {
  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [fixingInvalidSearchState, setFixingInvalidSearchState] =
    React.useState<'idle' | 'loading' | 'display'>('idle');
  const brokenAudienceHolderRef = React.useRef<Contact[]>([]);

  React.useEffect(() => {
    if (!props.audienceHoldersBroken) return;
    (async () => {
      if (fixingInvalidSearchState !== 'idle') return;
      setFixingInvalidSearchState('loading');
      const audienceHolders = await findBrokenAudienceHolders(
        props.audienceHolders
      );
      if (!mounted.current) return;
      brokenAudienceHolderRef.current = audienceHolders;
      setFixingInvalidSearchState('display');
    })();
  }, [props.audienceHoldersBroken]);

  const getOkAudienceHolders = () =>
    props.audienceHolders.filter(
      (a) =>
        !brokenAudienceHolderRef.current.some(
          (b) => a.profileID === b.profileID
        )
    );

  return (
    <Dialog
      open={
        fixingInvalidSearchState === 'loading' ||
        fixingInvalidSearchState === 'display'
      }>
      {fixingInvalidSearchState === 'display' ? (
        <>
          <DialogTitle>{language.text.issue_with_audience_holders}</DialogTitle>
          <DialogContent>
            <Typography>
              {language.text.following_audience_holders_are_broken}
            </Typography>
            <Typography component="ul" sx={{ my: 1 }}>
              {brokenAudienceHolderRef.current.map((audienceHolder) => (
                <Typography
                  key={audienceHolder.profileID}
                  component="li"
                  sx={{ listStyleType: '-' }}>
                  {audienceHolder.firstname + ' ' + audienceHolder.lastname}
                </Typography>
              ))}
            </Typography>
            <Typography>
              {
                language.text
                  .what_do_you_want_to_do_with_the_broken_audience_holders
              }
            </Typography>
          </DialogContent>
          <DialogActions>
            <FancyButton
              variant="contained"
              color="primary"
              startIcon={<Redo />}
              onClick={() => {
                props.onResolved(getOkAudienceHolders());
                setFixingInvalidSearchState('idle');
              }}>
              {language.text.ignore_this_time}
            </FancyButton>
            <FancyButton
              fType={{ promise: true }}
              variant="contained"
              color="error"
              startIcon={<Delete />}
              onClick={async () => {
                await Promise.all(
                  brokenAudienceHolderRef.current.map((audienceHolder) =>
                    audienceHolder.delete()
                  )
                );
                props.onResolved(getOkAudienceHolders());
                setFixingInvalidSearchState('idle');
              }}>
              {language.text.delete_these_audience_holders}
            </FancyButton>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>{language.text.issue_with_audience_holders}</DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2
            }}>
            <CircularProgress sx={{ fontSize: '5rem' }} />
            <Typography variant="caption" textAlign="center">
              {language.text.trying_to_fix_the_issue}
            </Typography>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

/**
 * When the profile of an audience holder gets deleted, it can cause issues. \
 * This function tries to find the audience holder that creates problems.
 */
async function findBrokenAudienceHolders(audienceHolders: Contact[]) {
  const brokenAudienceHolders: Contact[] = [];
  for (const audienceHolder of audienceHolders) {
    const profile = await WebViewLinkedIn.getProfile(audienceHolder.profileID);
    if (!profile) {
      brokenAudienceHolders.push(audienceHolder);
    }
  }
  return brokenAudienceHolders;
}
