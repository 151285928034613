import React from 'react';

/**
 * Track history of a value and automatically remove loops
 */
const useHistory = <T>(
  value: T,
  options?: { isEqual?: (a: T, b: T) => boolean }
) => {
  const isEqual = options?.isEqual ?? ((a: T, b: T) => a === b);
  const history = React.useRef<T[]>([]);
  const current = React.useRef<T | undefined>(value);

  React.useEffect(() => {
    if (current.current && isEqual(current.current, value)) return;

    if (current.current) history.current.push(current.current);
    current.current = value;

    // remove loops in history
    const length = history.current.length;
    for (let i = Math.floor(length / 2); i >= 1; i--) {
      // get ith last elements
      const loop = history.current.slice(length - i);
      // get i elements before `loop`
      const beforeLoop = history.current.slice(length - 2 * i, length - i);

      // check if two array parts are equal
      // if they are equal, then we have a loop
      let isLoop = true;
      for (let j = 0; j < i && isEqual; j++) {
        if (!isEqual(loop[j], beforeLoop[j])) isLoop = false;
      }

      // remove loop
      if (isLoop) {
        history.current = history.current.slice(0, length - i);
        break;
      }
    }
  }, [value]);

  return {
    pop: () => {
      const value = history.current.pop();
      current.current = value;
      return value;
    }
  };
};

export default useHistory;
