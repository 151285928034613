import React from 'react';
import { Box } from '@mui/material';
import { Divider, Tooltip, Typography } from '@mui/material';
import emoji_list, { EmojiName, getEmojiCategoryName } from './emoji_list';
import { language } from '../../Config';

const DEFAULT_FAVORITES = [
  '👍',
  '👎',
  '😂',
  '😍',
  '😭',
  '😡',
  '😢',
  '😊',
  '💗',
  '👏',
  '🙏',
  '🤣',
  '🤔',
  '🤷',
  '🤦',
  '🙄'
];

const COLUMNS = emoji_list.length + 1;

export interface EmojiListProps {
  onEmojiClick: (emoji: string) => void;
}

export default function EmojiList(props: EmojiListProps) {
  const [usedEmojis, setUsedEmojis] = React.useState<
    | {
        emoji: string;
        timesUsed: number;
        lastUsed: Date;
      }[]
    | null
  >(null);

  // load favorites from local storage
  React.useEffect(() => {
    const usedRaw = localStorage.getItem('used_emojis');
    if (!usedRaw) return;
    const used = (
      JSON.parse(usedRaw) as {
        emoji: string;
        timesUsed: number;
        lastUsed: string;
      }[]
    ).map((u) => ({
      ...u,
      lastUsed: new Date(u.lastUsed)
    }));
    setUsedEmojis(used);

    // set favorites
    const usedEmojisSorted = used.sort(
      (a, b) =>
        b.timesUsed - a.timesUsed || b.lastUsed.getTime() - a.lastUsed.getTime()
    );
    const favoritesOfUsed = usedEmojisSorted
      .slice(0, COLUMNS * 2)
      .map((u) => u.emoji);
    const rest = DEFAULT_FAVORITES.filter((e) => !favoritesOfUsed.includes(e));
    // fill up with default emojis
    setFavorites([...favoritesOfUsed, ...rest].slice(0, COLUMNS * 2));
  }, []);

  // use state and not memo to update favorites only when rendering to prevent emojis jumping when selecting
  const [favorites, setFavorites] = React.useState<string[]>(DEFAULT_FAVORITES);

  // save used emojis to local storage
  React.useEffect(() => {
    if (!usedEmojis) return;
    const usedRaw = JSON.stringify(
      usedEmojis.map((u) => ({
        ...u,
        lastUsed: u.lastUsed.toISOString()
      }))
    );
    localStorage.setItem('used_emojis', usedRaw);
  }, [usedEmojis]);

  const clickEmoji = React.useCallback(
    (emoji: string) => {
      props.onEmojiClick(emoji);
      setUsedEmojis((prev) => {
        if (!prev) prev = [];
        const existing = prev.find((e) => e.emoji === emoji);
        if (existing) {
          existing.timesUsed++;
          existing.lastUsed = new Date();
        } else {
          prev.push({
            emoji,
            timesUsed: 1,
            lastUsed: new Date()
          });
        }
        return [...prev];
      });
    },
    [props.onEmojiClick, setUsedEmojis]
  );

  const refs = React.useRef<{
    [key in EmojiName | 'Favoriten']: HTMLElement | null;
  }>(Object.fromEntries(emoji_list.map(({ name }) => [name, null])) as any);

  return (
    <React.Fragment>
      <Box
        sx={{
          maxHeight: '30vh',
          overflow: 'hidden overlay'
        }}>
        <React.Fragment key={'Favoriten'}>
          {/* bind ref to this ele since header is sticky */}
          <Box ref={(ref) => (refs.current.Favoriten = ref as HTMLElement)} />
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              backgroundColor: (theme) => theme.palette.background.paper
            }}>
            <Typography
              variant="h6"
              sx={{
                padding: (theme) => theme.spacing(0, 1)
              }}>
              {language.text.favorites}
            </Typography>
            <Divider />
          </Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: `repeat(${COLUMNS}, 1fr)`,
              padding: (theme) => theme.spacing(0, 1)
            }}>
            {favorites.map((emoji) => (
              <Box
                sx={{
                  width: (theme) => theme.spacing(4),
                  height: (theme) => theme.spacing(4),
                  display: 'inline-block',
                  cursor: 'pointer',
                  textAlign: 'center',
                  lineHeight: (theme) => theme.spacing(4)
                }}
                key={emoji}
                onClick={() => clickEmoji(emoji)}>
                {emoji}
              </Box>
            ))}
          </Box>
        </React.Fragment>

        {emoji_list.map(({ emojis, name }) => (
          <React.Fragment key={name}>
            {/* bind ref to divider since header is sticky */}
            <Divider ref={(ref) => (refs.current[name] = ref)} />
            <Box
              sx={{
                position: 'sticky',
                top: 0,
                backgroundColor: (theme) => theme.palette.background.paper
              }}>
              <Typography
                variant="h6"
                sx={{
                  padding: (theme) => theme.spacing(0, 1)
                }}>
                {getEmojiCategoryName(name)}
              </Typography>
              <Divider />
            </Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: `repeat(${COLUMNS}, 1fr)`,
                padding: (theme) => theme.spacing(0, 1)
              }}>
              {emojis.map((emoji) => (
                <Box
                  key={emoji}
                  sx={{
                    width: (theme) => theme.spacing(4),
                    height: (theme) => theme.spacing(4),
                    display: 'inline-block',
                    cursor: 'pointer',
                    textAlign: 'center',
                    lineHeight: (theme) => theme.spacing(4)
                  }}
                  onClick={() => clickEmoji(emoji)}>
                  {emoji}
                </Box>
              ))}
            </Box>
          </React.Fragment>
        ))}
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `repeat(${COLUMNS}, 1fr)`,
          padding: (theme) => theme.spacing(1, 1, 0, 1),
          backgroundColor: (theme) => theme.palette.background.default
        }}>
        <Tooltip title="Favoriten" key="Favoriten">
          <Box
            sx={{
              width: (theme) => theme.spacing(4),
              height: (theme) => theme.spacing(4),
              display: 'inline-block',
              cursor: 'pointer',
              textAlign: 'center'
            }}
            onClick={() => {
              refs.current.Favoriten?.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
              });
            }}>
            💗
          </Box>
        </Tooltip>
        {emoji_list.map(({ emojis, name }) => (
          <Tooltip title={name} key={name}>
            <Box
              sx={{
                width: (theme) => theme.spacing(4),
                height: (theme) => theme.spacing(4),
                display: 'inline-block',
                cursor: 'pointer',
                textAlign: 'center'
              }}
              onClick={() => {
                refs.current[name]?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start'
                });
              }}>
              {emojis[0]}
            </Box>
          </Tooltip>
        ))}
      </Box>
    </React.Fragment>
  );
}
