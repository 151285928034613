const MAX_ACCOUNTS_IN_URL = 25;

function getLinkedInSearchLink(profiles: { profileID: string }[]): string {
  if (profiles.length === 0)
    return 'https://www.linkedin.com/search/results/content/?origin=FACETED_SEARCH&sid=jYO&sortBy=%22date_posted%22';
  return `https://www.linkedin.com/search/results/content/?fromMember=${encodeURIComponent(JSON.stringify(profiles.slice(0, MAX_ACCOUNTS_IN_URL).map((a) => a.profileID)))}&origin=FACETED_SEARCH&sid=jYO&sortBy=%22date_posted%22`;
}

const LinkedInLinks = {
  getLinkedInSearchLink
};

export default LinkedInLinks;
