import React from 'react';
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';
import { navigate } from '@/Routes/MainRouter';
import SettingsRouter from './SettingsRouter';
import Progression from './Progression/Progression';
import { useLocation } from 'react-router-dom';
import { language } from '@/index';
import { APP_PATHS, MANUAL_MODE_PATHS, SETTINGS_PATHS } from '@/globals/Paths';
import TagSettings from './Tags/TagSettings';
import GeneralSettings from './General/GeneralSettings';
import ThirdPartyLicenses from './ThirdPartyLicenses/ThirdPartyLicenses';
import { checkFeatureFlag } from '@/globals/featureFlags';
import Goals from './Goals/Goals';

export const SETTINGS_MENU_LIST = [
  {
    name: () => language.text.general,
    path: SETTINGS_PATHS.General,
    element: GeneralSettings
  },
  {
    name: () => language.text.tags,
    path: SETTINGS_PATHS.Tags,
    element: TagSettings
  },
  {
    name: () => language.text.goals,
    path: SETTINGS_PATHS.Goals,
    element: Goals,
    hidden: () => !checkFeatureFlag('manualMode_stats_page')
  },
  {
    name: () => language.text.progress,
    path: SETTINGS_PATHS.Progression,
    element: Progression
  },
  {
    name: () => language.text.third_party_licenses,
    path: SETTINGS_PATHS.ThirdPartyLicenses,
    element: ThirdPartyLicenses
  }
] satisfies {
  name: () => string;
  path: string;
  element: React.FC;
  hidden?: () => boolean;
}[];

export default function Settings() {
  const ROOT_ROUTE = APP_PATHS.Manual + '/' + MANUAL_MODE_PATHS.Settings;
  const { pathname } = useLocation();
  const selectedPath = React.useMemo(
    () =>
      SETTINGS_MENU_LIST.find((item) => pathname.includes(item.path))?.path ??
      SETTINGS_MENU_LIST[0].path,
    [pathname]
  );

  const visibleMenuItems = React.useMemo(
    () => SETTINGS_MENU_LIST.filter((item) => !item.hidden?.()),
    []
  );

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'minmax(200px, auto) 1fr',
        gridTemplateRows: '1fr',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        boxSizing: 'border-box',
        p: (theme) => theme.spacing(0, 2)
      }}>
      <Box
        sx={{
          borderRight: (theme) => `1px solid ${theme.palette.divider}`
        }}>
        <Box
          sx={{
            boxSizing: 'border-box',
            padding: (theme) => theme.spacing(4, 2, 1, 2)
          }}>
          <Typography variant="h5">{language.text.settings}</Typography>
        </Box>
        <List disablePadding sx={{ overflow: 'auto' }}>
          {visibleMenuItems.map((menuItem, i) => (
            <ListItemButton
              key={i}
              selected={selectedPath === menuItem.path}
              onClick={() => navigate(`${ROOT_ROUTE}/${menuItem.path}`)}
              data-path={menuItem.path}
              sx={{
                'padding': (theme) => theme.spacing(1.5, 2),
                'display': 'relative',
                '&:hover .hoverWrapper': {
                  button: {
                    opacity: 1
                  },
                  span: {
                    opacity: 0
                  }
                }
              }}>
              <ListItemText>{menuItem.name()}</ListItemText>
            </ListItemButton>
          ))}
        </List>
      </Box>
      <Box
        sx={{
          boxSizing: 'border-box',
          padding: (theme) => theme.spacing(4, 2, 0, 2),
          overflow: 'auto',
          position: 'relative'
        }}>
        <SettingsRouter />
      </Box>
    </Box>
  );
}
