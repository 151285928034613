import { Box, Divider } from '@mui/material';
import AccountInformation from './AccountInformation';
import LanguageSettings from './LanguageSettings';
import LinkedInLoadingSettings from './LinkedInLoadingSettings';
import Subscription from './Subscription';
import VersionSettings from './VersionSettings';
import React from 'react';
import ZoomSettings from './WindowZoom';
import LoadEntireNetwork from './LoadEntireNetwork';
import { FeatureFlagKeys, useFeatureFlags } from '@/globals/featureFlags';
import UsageClassSettings from './UsageClassSettings';

const ITEMS = [
  { item: AccountInformation },
  { item: VersionSettings },
  { item: ZoomSettings },
  { item: LanguageSettings },
  { item: Subscription },
  { item: LoadEntireNetwork, featureFlag: 'entire_network_load' },
  { item: UsageClassSettings },
  { item: LinkedInLoadingSettings }
] satisfies { item: React.FC<undefined>; featureFlag?: FeatureFlagKeys }[];

export default function GeneralSettings() {
  const featureFlags = useFeatureFlags();
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {ITEMS.map(({ item: Item, featureFlag }, index) => {
        if (featureFlag && !featureFlags[featureFlag]) return null;
        return (
          <React.Fragment key={index}>
            {index !== 0 && <Divider />}
            <Item />
          </React.Fragment>
        );
      })}
    </Box>
  );
}
