import React from 'react';
import QuestionForm from './QuestionForm';
import QuestionTask from './QuestionTask';
import ContactView from '../_general/ContactView/ContactView';
import ContactChat from '../_general/ContactView/ContactChat';
import { Box } from '@mui/material';
import {
  ArrowBackIcon,
  CommentAltIcon,
  FancyButton,
  useEventEmitter
} from 'ui-utils';
import RelationshipMenu from '../_general/ContactView/RelationshipMenu';
import CategorySelector from '../_general/ContactView/CategorySelector';
import HUDSidePanel from '@/components/HUD/HUDSidePanel';
import HUDFocusBar from '@/components/HUD/HUDFocusBar';
import ContactDisplay from '../_general/ContactView/ContactDisplay';
import LinkedInWebview from '@common/Webview.renderer/Base/LinkedInWebview';
import { Person } from '@mui/icons-material';
import { language } from '@/index';
import { Contact } from '@/data/Classes/Contact';
import { ContactType } from '@common/types/enums';
import { useScheduler } from 'focus-mode-scheduler';
import SkipUntilDialog from '../_general/SkipUntilDialog/SkipUntilDialog';
import OnboardingVideo from '../_general/OnboardingVideo/OnboardingVideo';

export interface QuestionTaskUIProps {
  task: QuestionTask;
}

export default function QuestionTaskUI(props: QuestionTaskUIProps) {
  const { task } = props;
  const { complete, skip } = useScheduler();

  const [page, setPage] = React.useState<'question' | 'chat' | 'profile'>(
    'question'
  );
  const [sideView, setSideView] = React.useState<
    'categorize' | 'pipeline_steps'
  >('pipeline_steps');

  const [showDatePicker, setShowDatePicker] = React.useState(false);
  useEventEmitter(task, {
    'get-skip-until-date': () => setShowDatePicker(true)
  });

  return (
    <>
      <ContactView
        page={page}
        contact={task.contact}
        pages={[
          {
            id: 'question',
            component: (
              <QuestionForm
                title={task.question.question}
                type={task.question.type}
                options={
                  task.question.type === 'question-options'
                    ? task.question.options
                    : []
                }
                onFinished={async (choice) => {
                  task.setAnswer(choice);
                  return complete({ itemsDone: 1 });
                }}
              />
            ),
            keepMounted: true
          },
          {
            id: 'chat',
            component: (
              <ContactChat
                chat={task.chat}
                fillTemplateFromChat={false}
                disableChat
              />
            )
          },
          {
            keepMounted: true,
            id: 'profile',
            component: (
              <LinkedInWebview
                startURL={task.contact.profileURL}
                features={['focus-mode-style', 'hide-connect-button']}
              />
            )
          }
        ]}
        sx={{
          boxSizing: 'border-box',
          /* add spacing add bottom to make space between bottom bar buttons and textarea */
          pb: 2,
          // extra spacing due to sidebar
          mr: 18,
          width: 'unset'
        }}
      />

      <HUDSidePanel>
        <Box flex={1} />
        <ContactDisplay
          contact={task.contact}
          onClick={() =>
            setPage((page) => (page === 'profile' ? 'question' : 'profile'))
          }
        />
        {sideView === 'categorize' && (
          <CategorySelector
            defaultValue={ContactType.POTENTIAL_CUSTOMER}
            onFinished={async (result) => {
              if (result === ContactType.POTENTIAL_CUSTOMER)
                setSideView('pipeline_steps');
              else {
                await Contact.categorize(task.contact, result);
                task.omitSkipUntil();
                await skip('instance');
              }
            }}
          />
        )}
        {sideView === 'pipeline_steps' && (
          <RelationshipMenu
            goBackToCategorySelector={() => setSideView('categorize')}
            steps={task.pipelineSteps}
            currentStep={task.activeStep}
            completeText={language.text.recategorize}
            onComplete={async (pipeline_step) => {
              await Contact.categorize(
                task.contact,
                ContactType.POTENTIAL_CUSTOMER,
                pipeline_step
              );
              task.omitSkipUntil();
              await skip('instance');
            }}
          />
        )}
        <Box flex={4} />
      </HUDSidePanel>
      <HUDFocusBar>
        <FancyButton
          key="profile"
          size="large"
          variant="contained"
          color="secondary"
          onClick={() =>
            setPage((page) => (page === 'profile' ? 'question' : 'profile'))
          }
          sx={{
            minWidth: 190
          }}
          startIcon={<Person />}>
          {page === 'profile'
            ? language.text.close_profile
            : language.text.open_profile}
        </FancyButton>
        <FancyButton
          sx={{
            minWidth: 190
          }}
          size="large"
          variant="contained"
          color="secondary"
          onClick={() =>
            setPage((page) => (page === 'chat' ? 'question' : 'chat'))
          }
          startIcon={page === 'chat' ? <ArrowBackIcon /> : <CommentAltIcon />}>
          {page === 'chat' ? language.text.back : language.text.open_chat}
        </FancyButton>
      </HUDFocusBar>

      <SkipUntilDialog
        onFinish={(date) =>
          task.emit('skip-until-date', { date, type: 'complete' })
        }
        contact={task.contact}
        open={showDatePicker}
        onClose={() => {
          setShowDatePicker(false);
          task.emit('skip-until-date', { type: 'close' });
        }}
        onSkip={task.emit.bind(task, 'skip-until-date', { type: 'skip' })}
      />
    </>
  );
}
