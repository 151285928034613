import { Info } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';

export interface HUDTitleProps {
  title?: string;
  subtitle?: string;
}

export default function HUDTitle(props: HUDTitleProps) {
  const { title, subtitle } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}>
      <Typography
        variant="h4"
        sx={{
          color: (theme) => theme.palette.secondary.main,
          fontSize: {
            md: '1.4rem',
            lg: '1.7rem',
            xl: '1.9rem'
          },
          fontWeight: 'bold',
          m: 0,
          mb: subtitle ? 0 : undefined,
          textAlign: 'center'
        }}>
        {title}
        {subtitle && (
          <Tooltip title={<Typography>{subtitle}</Typography>}>
            <Info sx={{ ml: 1 }} />
          </Tooltip>
        )}
      </Typography>
    </Box>
  );
}
