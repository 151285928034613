import MessageBus from '@common/MessageBus/MessageBus.renderer';
import { LinkedInAccount } from 'linkedin-domain-types';

export default class ProfileCache {
  private static TIMEOUT = 1000 * 60 * 5;
  private static instance: ProfileCache | null = null;
  private cache = new Map<
    string,
    {
      lastUsed: number;
      profile: LinkedInAccount;
    }
  >();
  private publicIdentifierMap = new Map<string, string>();
  private constructor() {
    // clear cache every 5 minutes
    setInterval(() => {
      const now = Date.now();
      for (const [profileID, entry] of this.cache) {
        if (now - entry.lastUsed > ProfileCache.TIMEOUT) {
          this.cache.delete(profileID);
          this.publicIdentifierMap.delete(entry.profile.publicIdentifier);
        }
      }
    });
    MessageBus.getInstance().on('ReqIntersect:profile', ({ profiles }) => {
      profiles.forEach((profile) => this.setProfile(profile));
    });
    MessageBus.getInstance().on('ReqIntersect:fullProfile', ({ profiles }) => {
      profiles.forEach((profile) => this.setProfile(profile));
    });
  }
  static getInstance(): ProfileCache {
    if (!ProfileCache.instance) ProfileCache.instance = new ProfileCache();
    return ProfileCache.instance;
  }
  getProfile(linkedInIdentifier: string): LinkedInAccount | null {
    const profileID =
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- we also want to not use empty string
      this.publicIdentifierMap.get(linkedInIdentifier) || linkedInIdentifier;
    const entry = this.cache.get(profileID);
    if (!entry) return null;
    entry.lastUsed = Date.now();
    return entry.profile;
  }
  setProfile(profile: LinkedInAccount): void {
    this.cache.set(profile.profileID, {
      profile,
      lastUsed: Date.now()
    });
    this.publicIdentifierMap.set(profile.publicIdentifier, profile.profileID);
  }
}

window.profileCache = ProfileCache.getInstance();
