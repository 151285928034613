import React from 'react';
import { Box, Typography } from '@mui/material';
import useIPC from '@/hooks/IPCHook';
import { Logo } from 'ui-utils';

export default function Updater() {
  const [loading, setLoading] = React.useState(true);
  const [text, setText] = React.useState('Laden');

  // get state text from main thread
  useIPC('updater:status', (_, text, { isLoadingState }) => {
    setText(text);
    setLoading(isLoadingState);
  });

  // prevent site reload
  React.useEffect(() => {
    function keydownListener(event: KeyboardEvent) {
      if (event.key === 'r' && event.ctrlKey) event.preventDefault();
    }
    function unloadListener(event: BeforeUnloadEvent) {
      event.preventDefault();
    }

    window.addEventListener('keydown', keydownListener);
    window.addEventListener('beforeunload', unloadListener);
    return () => {
      window.removeEventListener('keydown', keydownListener);
      window.removeEventListener('beforeunload', unloadListener);
    };
  }, []);

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        background: (theme) => theme.palette.background.paper,
        display: 'grid',
        placeItems: 'center'
      }}>
      <Box p={2} width="80%">
        <Logo
          variant="blue_text"
          sx={{
            height: '100%',
            width: '100%'
          }}
        />
      </Box>
      <Box p={2}>
        <Typography
          variant="h4"
          textAlign="center"
          sx={
            loading
              ? {
                  '&::after': {
                    'content': "''",
                    'display': 'inline-block',
                    'minWidth': (theme) => theme.spacing(3),
                    'animation': 'loading 2s linear infinite',
                    'textAlign': 'left',
                    '@keyframes loading': {
                      '0%': {
                        content: "''"
                      },
                      '25%': {
                        content: "'.'"
                      },
                      '50%': {
                        content: "'..'"
                      },
                      '75%': {
                        content: "'...'"
                      },
                      '100%': {
                        content: "''"
                      }
                    }
                  }
                }
              : {}
          }>
          {text}
        </Typography>
      </Box>
    </Box>
  );
}
