/**
 * Calls a function if the variable is not null or undefined
 * @param variable nullable variable
 * @param func function to call if variable is not null or undefined
 */
export function applyIfNotNull<Type, ReturnType, Args extends unknown[]>(
  variable: Type | null | undefined,
  func: (variable: NonNullable<Type>, ...args: Args) => ReturnType,
  ...args: Args
): Type extends null | undefined ? unknown : ReturnType {
  if (variable !== null && variable !== undefined) {
    //@ts-ignore
    return func(variable, ...args);
  }
  //@ts-ignore
  return undefined;
}

/**
 * Checks if the function gets executed in main thread or renderer thread
 */
export function identifyProcess(): 'main' | 'renderer' {
  if (typeof window === 'undefined') return 'main';
  return 'renderer';
}

export function mergeObjects<T1 extends object, T2 extends object>(
  obj1: T1,
  obj2: T2
): T1 & T2 {
  for (const key in obj2) {
    //@ts-expect-error -- This is too weird for ts
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const value1 = obj1[key];
    const value2 = obj2[key];
    if (
      value2 instanceof Object &&
      value1 instanceof Object &&
      !Array.isArray(value2) &&
      !Array.isArray(value1) &&
      !(value1 instanceof Date) &&
      !(value2 instanceof Date)
    ) {
      //@ts-expect-error -- This is too weird for ts
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      obj1[key] = mergeObjects(value1, value2);
    } else {
      //@ts-expect-error -- This is too weird for ts
      obj1[key] = value2;
    }
  }
  return obj1 as T1 & T2;
}

export function tryCatch<T>(func: () => T):
  | {
      value: T;
      error: undefined;
    }
  | {
      value: undefined;
      error: Error;
    } {
  try {
    return {
      value: func(),
      error: undefined
    };
  } catch (error) {
    return {
      value: undefined,
      error: error as Error
    };
  }
}
