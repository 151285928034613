import { language } from '@/index';
import { Arrays, Objects } from '@idot-digital/generic-helpers';
import { Box, Divider, Typography } from '@mui/material';
import { DelayedSkeleton, FancyButton } from 'ui-utils';
import StatsHelper, { Stat } from '../../Routes/ManualMode/Stats/StatsHelper';
import React from 'react';
import StatCompareBlock from './StatCompareBlock';
import { Stats, StatTypes } from '@common/types/ApiTypes';

export interface StatsComparisonProps {
  stats?: { [key in StatTypes]?: Stats[StatTypes] };
  onFinish?: () => Promise<void> | void;
  nextText?: string;
  children?: React.ReactNode | React.ReactNode[];
  header: React.ReactNode;
}

export default function StatsComparison(props: StatsComparisonProps) {
  const { stats, onFinish, children, header, nextText } = props;

  const categories = React.useMemo(
    StatsHelper.getStatCategories.bind(StatsHelper),
    []
  );

  const mappedStats = React.useMemo<Map<
    StatTypes,
    Stat & { goal: number | null }
  > | null>(() => {
    if (!stats) return null;
    return new Map(
      Arrays.filterNull(
        Objects.entries(stats).map(([type, value]) =>
          value
            ? [
                type,
                {
                  type,
                  current: value.current,
                  avg: value.avg,
                  goal: value.goal,
                  change: StatsHelper.getChange(value.current, value.avg)
                }
              ]
            : null
        )
      )
    );
  }, [stats]);

  const mainStat = React.useMemo(() => {
    if (!mappedStats) return null;
    return [...mappedStats.values()].reduce<
      (Stat & { goal: number | null }) | null
    >((highest, current) => {
      if (!highest) return current;
      if (highest.change < current.change) return current;
      return highest;
    }, null);
  }, [mappedStats]);

  return (
    <>
      <Box
        maxWidth={1000}
        width="100%"
        mx="auto"
        display="flex"
        flexDirection="column"
        gap={4}
        p={2}
        boxSizing="border-box">
        {mainStat ? (
          <StatCompareBlock
            stat={mainStat}
            goal={mainStat.goal ?? undefined}
            main
          />
        ) : (
          <DelayedSkeleton height={200} width={400} sx={{ mx: 'auto' }} />
        )}
        <Typography variant="h4" textAlign="center">
          {header}
        </Typography>
        <Divider />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
            justifyContent: 'center',
            gap: 4
          }}>
          {!mappedStats && (
            <DelayedSkeleton
              sx={{ aspectRatio: 2, minWidth: 200 }}
              quantity={3}
            />
          )}
          {mappedStats &&
            categories.map((category, i) => (
              <React.Fragment key={category.title}>
                {i !== 0 && (
                  <Divider
                    sx={{
                      gridColumn: '1 / -1'
                    }}
                  />
                )}
                <Typography
                  variant="h5"
                  sx={{
                    gridColumn: '1 / -1'
                  }}>
                  {category.title}
                </Typography>
                {category.stats.map((type) => {
                  if (type === mainStat?.type) return null;
                  const stat = mappedStats.get(type);
                  if (!stat) return null;
                  return (
                    <StatCompareBlock
                      key={stat.type}
                      stat={stat}
                      goal={stat.goal ?? undefined}
                    />
                  );
                })}
              </React.Fragment>
            ))}
        </Box>
      </Box>
      {onFinish && (
        <>
          <Box flexGrow={1} />
          <Box
            sx={{
              position: 'sticky',
              bottom: 0,
              background: (theme) => theme.palette.background.default,
              width: '100%'
            }}>
            <Divider />
            <Box
              display="flex"
              justifyContent="center"
              alignContent="center"
              width="100%"
              py={4}
              gap={2}>
              {children}
              <FancyButton
                fType={{ promise: true }}
                onClick={() => onFinish()}
                variant="contained"
                color="primary"
                size="large"
                sx={{ minWidth: 200 }}>
                {nextText ?? language.text.next}
              </FancyButton>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
