import React from 'react';

const useRefreshed = <T>(getData: () => T, interval = 10_000) => {
  const [data, setData] = React.useState<T>(getData());
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setData(getData());
    }, interval);
    return () => clearInterval(intervalId);
  }, []);
  return data;
};

export default useRefreshed;
