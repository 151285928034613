import { TaskBuilder } from 'focus-mode-scheduler';
import AudienceHolderTask from './AudienceHolderTask';
import { NextTask } from 'focus-mode-scheduler/src/Task/TaskBuilder';
import ContactActions from '@/data/DataServer/Contact';
import { ContactType } from '@common/types/enums';
import { BindProps } from 'ui-utils';
import AudienceHolderTaskUI from './AudienceHolderTaskUI';

const TARGET_NUMBER_OF_AUDIENCE_HOLDERS = 5;

export default class AudienceHolderTaskBuilder extends TaskBuilder<AudienceHolderTask> {
  protected task = AudienceHolderTask;

  protected timeExecuted = 0;
  async getTask(): Promise<NextTask<AudienceHolderTask>> {
    if (this.timeExecuted > 0) return null;
    const audienceHolders = await ContactActions.listContacts(undefined, {
      values: {
        type: ContactType.AUDIENCE_HOLDER
      }
    });

    if (audienceHolders.length >= TARGET_NUMBER_OF_AUDIENCE_HOLDERS)
      return null;

    const task = new AudienceHolderTask();
    const UI = BindProps(AudienceHolderTaskUI, {
      defaultMax: TARGET_NUMBER_OF_AUDIENCE_HOLDERS,
      task
    });

    this.timeExecuted++;
    return {
      task,
      UI
    };
  }

  public async getOptionsConfig() {
    return null;
  }
}
