import { Account, MessageTemplate } from '@common/types/ApiTypes';
import TemplateActions from '@/data/DataServer/Templates';
import { Objects } from '@idot-digital/generic-helpers';
import { TutorialContact } from '@/data/Classes/TutorialContact';
import { ChatTutorial } from '@common/config/Tutorials.renderer';
import { SendMessage } from '@/components/Chat/ChatHistory';
import { TutorialChat } from '@/data/Classes/TutorialChat';
import SetupActions from '@/data/DataServer/Setup';
import { language } from '@/index';
import {
  PipelineStep,
  PipelineStepTypes,
  Question
} from '@common/PipelineManager/PipelineTypes';
import { Task, TaskHeader } from 'focus-mode-scheduler';
import {
  TaskConfig,
  TaskIncomingEventMap
} from 'focus-mode-scheduler/src/Task/Task';

export type TutorialMessage = ChatTutorial['messages'][number];

export default class ChatTutorialTask extends Task {
  public static get config(): TaskConfig {
    return {
      id: 'chat-tutorial',
      hide: true,
      info: {
        title: language.text.chat_tutorial,
        description: language.text.chat_task_description,
        hideNoMoreInstancesInfo: true,
        hideNoInstancesInfo: true
      },
      constraintBreachedDialogContent:
        Task.DEFAULT_CONTRAINT_BREACHED_DIALOG_TEXTS
    };
  }
  protected _header: TaskHeader = {
    // we mount skip button ourself
    title: language.text.chat_tutorial,
    skip: {
      category: {
        text: language.text.skip_tutorial
      }
    }
  };
  protected responses: TutorialMessage[] = [];

  protected static remainingChatTutorials: ChatTutorial[] | null = null;

  constructor(
    public readonly contact: TutorialContact,
    public readonly chat: TutorialChat,
    protected _template: MessageTemplate | null,
    protected _templates: MessageTemplate[],
    public readonly steps: PipelineStep[],
    protected _activeStep: PipelineStep | null,
    public readonly chatTutorial: ChatTutorial,
    private readonly self: Account
  ) {
    super();

    this.responses = Objects.deepClone(this.chatTutorial.messages);
    this._header.subtitle = this.chatTutorial.title;
  }

  public async send(_message: string): Promise<{
    messages: SendMessage[];
    video: (Video & { fullscreen?: boolean }) | null;
    question: Question | null;
  } | null> {
    const response = this.responses.shift();
    if (!response) return null;

    const video = response.video ?? null;
    const responseMessages: SendMessage[] =
      response.messages?.map((m) => ({
        createdAt: new Date(),
        deleted: false,
        reactions: [],
        sendByYou: false,
        attachments: [],
        ...m,
        text: this.replaceVarsInText(m.text)
      })) ?? [];

    // update activeStep
    const activeIndex = this.steps.findIndex(
      (step) => step.id === this._activeStep?.id
    );
    if (response.stepID)
      this._activeStep =
        this.steps.find((s) => s.id === response.stepID) ?? null;
    else if (activeIndex !== -1) {
      // get next message step
      this._activeStep =
        this.steps
          .slice(activeIndex + 1)
          .find((step) =>
            (
              [
                'message',
                'question-date',
                'question-options'
              ] as PipelineStepTypes[]
            ).includes(step.type)
          ) ?? null;
    }

    // update templates
    let templates: MessageTemplate[] = [];
    if (response.templates) {
      templates = response.templates.map((t, i) => ({
        ...t,
        // id must be unique over all templates otherwise key updates will not work and templates will not be autofilled
        variantID: this.responses.length * 100 + i + 1,
        templateID: this._activeStep?.id ?? 'template_id'
      }));
    } else if (this._activeStep) {
      templates =
        (
          await TemplateActions.getTemplatesOfPipelineStep(this._activeStep.id)
        )?.flatMap((t) => t.templates) ?? [];
    }

    let question: Question | null = null;
    if (
      this._activeStep?.type === 'question-date' ||
      this._activeStep?.type === 'question-options'
    ) {
      question = this._activeStep;
    }

    this._templates = templates;
    this._template = templates[0] ?? null;
    this.chat.setTemplate(this.template);

    return {
      messages: responseMessages,
      video,
      question
    };
  }

  protected async handleEvent<Type extends keyof TaskIncomingEventMap>(
    type: Type
  ) {
    switch (type) {
      case 'skip':
      case 'complete':
        await SetupActions.completeChatTutorial(this.chatTutorial);
        this.internalEmit('finished', undefined);
        break;
    }
  }

  public get activeStep() {
    return this._activeStep;
  }

  public get template() {
    return this._template;
  }

  public get templates() {
    return this._templates;
  }

  public get done() {
    return (
      this.responses.filter(
        (r) =>
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- we want to filter out empty arrays
          r.messages?.length || r.templates?.length
      ).length === 0 && this.templates.length === 0
    );
  }

  private task_created_time: Date | null = null;
  public get initialMessages(): SendMessage[] {
    if (!this.task_created_time) this.task_created_time = new Date();
    if (!this.chatTutorial.initial?.messages) return [];
    return (
      this.chatTutorial.initial.messages
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- we want non empty texts or non empty attachments
        .filter((m) => m.text || m.attachments?.length)
        .map((m) => ({
          attachments: m.attachments ?? [],
          text: this.replaceVarsInText(m.text),
          createdAt: this.task_created_time
            ? new Date(this.task_created_time)
            : new Date(),
          deleted: false,
          reactions: [],
          sendByYou: m.sendByYou ?? false
        }))
    );
  }

  private replaceVarsInText(text: string | undefined): string {
    if (!text) return '';
    return text
      .replaceAll('[[NAME]]', `${this.self.firstname} ${this.self.lastname}`)
      .replaceAll('[[FIRSTNAME]]', this.self.firstname)
      .replaceAll('[[LASTNAME]]', this.self.lastname);
  }

  public get initialVideo(): (Video & { fullscreen?: boolean }) | null {
    return this.chatTutorial.initial?.video ?? null;
  }

  public async setStep(step: PipelineStep): Promise<void> {
    await this.contact.setPipelineStep(step, this.chat);
    this._activeStep = step;
  }
}
