import { Client } from '../client/Client';
import { LINKEDIN_META_URL, REQUEST_HEADER } from '../client/Config';
import { getCookie, Logger, Options, trace } from '../client/Utils';
import { AuthRequest } from '../requests/AuthRequest';
import { Event, LinkedInUserMetadata } from 'linkedin-domain-types';

/**
 * The login service class that handles all login related requests
 * @class LoginService - The login service class that handles all login related requests
 */
@trace()
export class LoginService {
  /**
   * The client that instantiated this service
   */
  private readonly client: Client;

  /**
   * The auth request service that handles all requests to the auth endpoint
   * @private the auth request service
   */
  private authService: AuthRequest;

  /**
   * The login service constructor
   * @param client the client that instantiated this service
   */
  constructor(client: Client) {
    this.client = client;
    this.authService = new AuthRequest(client.requestService);
  }

  /**
   * Gets some metadata about the current user session
   */
  async getMetadata(options: Options): Promise<LinkedInUserMetadata | null> {
    return this.client.requestService.get<LinkedInUserMetadata>(
      LINKEDIN_META_URL,
      options,
      undefined,
      undefined,
      'getMetadata'
    );
  }

  /**
   * Adds the request headers to the request service
   * @param cookies the cookies to use for login (JSESSIONID and li_at)
   */
  addRequestHeaders(
    cookies: { JSESSIONID: string; li_at: string } & Record<string, string>
  ) {
    let cookieStr = '';
    for (const cookie in cookies) {
      cookieStr += `${cookie}=${cookies[cookie]};`;
    }

    this.client.requestService.addHeaders({
      ...REQUEST_HEADER,
      'cookie': cookieStr,
      'csrf-token': cookies.JSESSIONID
    });
  }

  /**
   * Sends a test request to LinkedIn to check if the login was successful
   */
  async testLogin(options: Options): Promise<boolean> {
    return this.getMetadata(options)
      .then((res) => res !== null && res.client.isUserLoggedIn)
      .catch(() => false);
  }

  /**
   * Logs out of LinkedIn
   * @param options the options to use for the request
   */
  async logout(options: Options): Promise<boolean> {
    return this.authService
      .logout(options)
      .then(() => this.client.eventService.emit({ type: Event.LOGOUT }))
      .then(() => true)
      .catch(() => false);
  }
}
