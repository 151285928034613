import React from 'react';
import { Delete, Edit, Save } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  MenuItemProps,
  Popover,
  TextField,
  TextFieldProps,
  Theme,
  Typography
} from '@mui/material';
import FancyButton from '../FancyButtons/FancyButton';
import { language } from '../../Config';

export interface EditableMenuItemProps
  extends Omit<MenuItemProps, 'onChange' | 'color'> {
  name: string;
  title?: string;
  onChange?: (value: string) => Promise<void>;
  editIcon?: React.ReactNode;
  color?: string | ((theme: Theme) => string);
  editOnSelect?: boolean;
  onDelete?: () => Promise<void>;
  disableDelete?: boolean;
  buttons?: React.ReactNode | React.ReactNode[];
  textfieldProps?: TextFieldProps;
  maxLength?: number;
}

const EditableMenuItem = React.forwardRef<HTMLLIElement, EditableMenuItemProps>(
  (props, ref) => {
    const {
      name: _name,
      title,
      onChange,
      editIcon,
      color,
      editOnSelect,
      onDelete,
      disableDelete,
      buttons,
      ...rest
    } = props;
    const mounted = React.useRef(true);
    React.useEffect(() => {
      mounted.current = true;
      return () => {
        mounted.current = false;
      };
    }, []);

    const [editing, setEditing] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [name, setName] = React.useState(props.name);
    const [error, setError] = React.useState(false);

    const anchorEl = React.useRef<HTMLLIElement | null>(null);
    const [deleteOpen, setDeleteOpen] = React.useState(false);

    React.useEffect(() => {
      if (props.name !== name) setName(props.name);
    }, [props.name]);

    const submit = async () => {
      if (loading) return;
      if (!editing) setEditing(true);
      else {
        try {
          setLoading(true);
          await props.onChange?.(name.slice(0, props.maxLength));
          setEditing(false);
        } catch (e) {
          setError(true);
          setTimeout(() => {
            if (mounted.current) setError(false);
          }, 5000);
        } finally {
          setLoading(false);
        }
      }
    };

    return (
      <>
        <MenuItem ref={ref} {...rest}>
          <Box
            ref={(ref: any) => (anchorEl.current = ref)}
            display="flex"
            width="100%"
            // prevent menu from changing focus when clicking or pressing keys
            onClick={(e) => {
              if (props.editOnSelect) {
                setEditing(true);
                e.stopPropagation();
              } else if (editing) e.stopPropagation();
            }}
            onKeyDown={(e) => {
              if (editing) e.stopPropagation();
            }}>
            {editing ? (
              <TextField
                {...props.textfieldProps}
                label={props.title}
                value={name}
                onChange={(e) =>
                  setName(e.target.value.slice(0, props.maxLength))
                }
                disabled={loading}
                variant="standard"
                autoFocus
                sx={{
                  width: (theme) => theme.spacing(32),
                  fontSize: 'inherit',
                  fontWeight: 'inherit',
                  fontFamily: 'inherit',
                  color: error ? 'error.main' : 'inherit',
                  flexGrow: 1
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.stopPropagation();
                    submit();
                  }
                }}
              />
            ) : (
              <Typography
                variant="caption"
                sx={{
                  width: (theme) => theme.spacing(32),
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: 'inherit',
                  fontWeight: 'inherit',
                  fontFamily: 'inherit',
                  color: error ? 'error.main' : props.color || 'inherit',
                  flexGrow: 1
                }}>
                {name}
              </Typography>
            )}
            <IconButton
              size="small"
              color={error ? 'error' : 'primary'}
              disabled={(editing && name === '') || loading}
              onClick={(e) => {
                e.stopPropagation();
                submit();
              }}
              sx={{
                '>*': {
                  height: '.75em',
                  width: '.75em'
                }
              }}>
              {loading ? (
                <CircularProgress size=".75em" />
              ) : editing ? (
                <Save />
              ) : (
                props.editIcon || <Edit />
              )}
            </IconButton>
            {!editing && props.buttons}
            {!editing && onDelete && (
              <IconButton
                size="small"
                color={error ? 'error' : 'primary'}
                disabled={disableDelete}
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteOpen(true);
                }}
                sx={{
                  '>*': {
                    height: '.75em',
                    width: '.75em'
                  }
                }}>
                <Delete />
              </IconButton>
            )}
          </Box>
        </MenuItem>
        <Popover
          anchorEl={anchorEl.current}
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}>
          <Box p={2}>
            <Typography>
              {language.text.are_you_sure_irreversible_deletion}
            </Typography>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <FancyButton
                fType={{
                  promise: true
                }}
                variant="contained"
                color="error"
                onClick={async () => {
                  try {
                    await onDelete?.();
                  } finally {
                    setDeleteOpen(false);
                  }
                }}>
                {language.text.delete}
              </FancyButton>
            </Box>
          </Box>
        </Popover>
      </>
    );
  }
);

export default EditableMenuItem;
