import { Favorite } from '@mui/icons-material';
import MainConfig from '@common/config/MainConfig';
import SettingsGenericInfo from '../SettingsGenericInfo';
import { language } from '@/index';

export default function ProgressionAmbassador() {
  return (
    <SettingsGenericInfo
      header={language.text.ssc_is_free_for_you}
      button={{
        label: language.text.apply_as_affiliate,
        onClick: () =>
          window.api.send('url:open_ext', MainConfig.applyAsAffiliateLink)
      }}
      icon={Favorite}>
      {language.text.you_have_maxed_out_referring}
    </SettingsGenericInfo>
  );
}
