import PrintableError from '@common/PrintableError/PrintableError';
import { getKennelernenPipeline } from './PipelineDefinitions';
import {
  Actions,
  DisplayPipelineStep,
  PipelineDefinition,
  PipelineStep,
  PipelineStepTypes,
  ServerPipelineStepType
} from './PipelineTypes';

type Subflows =
  ReturnType<typeof getKennelernenPipeline> extends PipelineDefinition<infer T>
    ? T
    : never;

export class Pipelines {
  static getPipelineDefinition() {
    return getKennelernenPipeline();
  }
  static getStepType(current_step: string): PipelineStepTypes | null {
    const StepDef = Pipelines.findStep(current_step);
    if (!StepDef) return null;
    return StepDef.type;
  }

  static getStepInfo(current_step: string): {
    step: PipelineStep<Subflows>;
    flow: Subflows[number] | 'main';
  } | null {
    const def = this.getPipelineDefinition();
    let result = def.main_flow.find((step) => step.id === current_step);
    if (result)
      return {
        step: result,
        flow: 'main'
      };
    const secondaryFlowKeys: Subflows[number][] = Object.keys(
      def.secondary_flows
    );
    for (const flow of secondaryFlowKeys) {
      result = def.secondary_flows[flow].find(
        (step) => step.id === current_step
      );
      if (result)
        return {
          step: result,
          flow
        };
    }
    return null;
  }

  static getCurrentStepsToDisplay(
    current_step?: string
  ): DisplayPipelineStep<Subflows>[] {
    const steps = (() => {
      const def = this.getPipelineDefinition();
      const { flow } = current_step
        ? (Pipelines.getStepInfo(current_step) ?? { flow: 'main' })
        : { flow: 'main' };
      if (flow === 'main') return def.main_flow;
      return def.secondary_flows[flow];
    })();

    return steps.filter(
      (step) =>
        step.type !== 'waitForDay' && step.type !== 'waitForMessageReceived'
    ) as DisplayPipelineStep<Subflows>[];
  }

  static getCategorizationSteps(): DisplayPipelineStep<Subflows>[] {
    return this.getCurrentStepsToDisplay();
  }

  static findStep(current_step: string): PipelineStep<Subflows> | null {
    const def = this.getPipelineDefinition();
    const allSteps = [
      ...def.main_flow,
      ...Object.values<PipelineStep<Subflows>[]>(def.secondary_flows).flat()
    ];
    return allSteps.find((step) => step.id === current_step) || null;
  }

  static findStepIndex(current_step: string): {
    index: number;
    flow: 'main' | Subflows[number];
  } {
    const def = this.getPipelineDefinition();
    const mainIndex = def.main_flow.findIndex(
      (step) => step.id === current_step
    );
    if (mainIndex !== -1) return { index: mainIndex, flow: 'main' };
    for (const flow of Object.keys(def.secondary_flows)) {
      const index = def.secondary_flows[flow].findIndex(
        (step) => step.id === current_step
      );
      if (index !== -1) return { index, flow: flow };
    }
    return { index: -1, flow: 'main' };
  }

  static getStepWithIndex(
    index: number,
    flow: Subflows[number] | 'main'
  ): PipelineStep<Subflows> | null {
    const def = this.getPipelineDefinition();
    if (flow === 'main') return def.main_flow[index];
    return def.secondary_flows[flow][index];
  }

  /**
   * Returns the current step array for the next step. If it is null, there was an error. If its empty, the pipeline is finished.
   */
  static getNextStep(current_steps: string[]):
    | {
        done: false;
        current_steps: string[];
        step: PipelineStep<Subflows>;
      }
    | {
        done: true;
      }
    | null {
    const { index, flow } = Pipelines.findStepIndex(current_steps[0]);
    if (index === -1) return null;
    let nextStep = Pipelines.getStepWithIndex(index + 1, flow);
    if (!nextStep && flow === 'main')
      return {
        done: true
      };
    if (!nextStep && flow !== 'main') {
      nextStep = Pipelines.findStep(current_steps[1]);
      if (!nextStep) return null;
      return {
        done: false,
        current_steps: current_steps.slice(1),
        step: nextStep
      };
    } else {
      if (!nextStep) return null;
      current_steps.shift();
      current_steps.unshift(nextStep.id);
      return {
        done: false,
        current_steps,
        step: nextStep
      };
    }
  }
  static executeAction(
    current_steps: string[],
    action: Actions<Subflows> | null
  ):
    | {
        done: true;
        result: boolean;
      }
    | { done: false; current_steps: string[]; step: PipelineStep<Subflows> }
    | null {
    const def = this.getPipelineDefinition();
    switch (action) {
      case 'exit-flow':
        current_steps.shift();
        const step = Pipelines.findStep(current_steps[0]);
        if (!step) return null;

        return {
          done: false,
          current_steps,
          step
        };
      case 'result:positive':
        return {
          done: true,
          result: true
        };
      case 'result:negative':
        return {
          done: true,
          result: false
        };
      default:
        if (!action) {
          const step = Pipelines.findStep(current_steps[0]);
          if (!step) return null;
          return {
            done: false,
            current_steps,
            step
          };
        }
        if (!action.startsWith('flow:')) return null;
        const flow = action.split(':')[1] as Subflows[number];
        current_steps.unshift(def.secondary_flows[flow][0].id);
        return {
          done: false,
          current_steps,
          step: def.secondary_flows[flow][0]
        };
    }
  }

  static getServerStepType(
    step: Pick<PipelineStep<Subflows>, 'type'>
  ): ServerPipelineStepType {
    switch (step.type) {
      case 'buildRelationship':
        return ServerPipelineStepType.BuildRelationship;
      case 'message':
        return ServerPipelineStepType.Message;
      case 'waitForDay':
        return ServerPipelineStepType.WaitForDay;
      case 'waitForMessageReceived':
        return ServerPipelineStepType.WaitForMessageReceived;
      case 'question-date':
        return ServerPipelineStepType.QuestionDate;
      case 'question-options':
        return ServerPipelineStepType.QuestionOptions;
      default:
        throw new PrintableError(
          `Trying to convert unknown step type to server pipeline type ${step.type}`
        );
    }
  }

  /**
   * Returns a list of all last steps for each flow in history (including the main flow) \
   * First step is latest step
   */
  static filterLevelsFromHistory(stepIDs: string[]): string[] {
    const res = [];
    let currentFlow = null;
    for (const stepID of stepIDs) {
      const { flow } = Pipelines.findStepIndex(stepID);
      if (flow !== currentFlow) {
        currentFlow = flow;
        res.push(stepID);
      }
    }
    return res;
  }

  static getStepToDisplay(
    currentStep: string,
    history: string[]
  ): DisplayPipelineStep<Subflows> | null {
    const shownSteps = Pipelines.getCurrentStepsToDisplay(currentStep);
    const shownStep = shownSteps.find((step) => step.id === currentStep);
    if (shownStep) return shownStep;
    const { flow, index } = Pipelines.findStepIndex(currentStep);

    const allSteps = Pipelines.getStepsOfFlow(flow);
    const previousSteps = allSteps.slice(0, index).reverse();
    return (
      (previousSteps.find((step) =>
        history.includes(step.id)
      ) as DisplayPipelineStep<Subflows> | null) ?? null
    );
  }

  static getStepsOfFlow(
    flow: 'main' | Subflows[number]
  ): PipelineStep<Subflows>[] {
    const def = this.getPipelineDefinition();
    if (flow === 'main') return def.main_flow;
    return def.secondary_flows[flow];
  }
}
