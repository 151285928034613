import { Country } from './CountryCode';
import {
  CollectionResponseUrn,
  CompanyUrn,
  DashCompanyUrn,
  DashConversationUrn,
  DashDegreeUrn,
  DashEmploymentTypeUrn,
  DashGeoUrn,
  DashIndustryUrn,
  DashMemberRelationshipUrn,
  DashProfileCertificationUrn,
  DashProfileEducationUrn,
  DashProfilePositionGroupUrn,
  DashProfilePositionUrn,
  DashProfileUrn,
  DashRegionUrn,
  DashSchoolUrn,
  MemberBadgesUrn,
  MemberUrn,
  Urn
} from './Urns';
import {
  LinkedInAudioMetadata,
  LinkedInDate,
  LinkedInDateRange,
  LinkedInGeo,
  LinkedInIndustry,
  LinkedInLocale,
  LinkedInVectorImage,
  MultiLocaleAudio,
  MultiLocaleString,
  PublicIdentifier,
  TextViewModel,
  TrackingId
} from './Common';
import { LinkedInCompany, LinkedInSchool } from './Organization';
import { CollectionResponse } from './responses/CollectionResponse';
import { LinkedInTreasuryMedia } from './Treasury';
import { LinkedInMemberRelationship } from './Relationships';

const DASH_PROFILE_PREFIX = 'com.linkedin.voyager.dash.identity.profile';
const PROFILE_PREFIX = 'com.linkedin.voyager.identity.profile';

export type ProfileId = string;
export const PROFILE_GEO_LOCATION_TYPE =
  `${DASH_PROFILE_PREFIX}.ProfileGeoLocation` as const;

interface LinkedInProfileGeoLocation {
  '$type': typeof PROFILE_GEO_LOCATION_TYPE;
  '*geo': string;
  '$recipeTypes': string[];
  'geoUrn': string;
}

export const PROFILE_LOCATION_TYPE =
  `${DASH_PROFILE_PREFIX}.ProfileLocation` as const;

interface LinkedInProfileLocation {
  $type: typeof PROFILE_LOCATION_TYPE;
  countryCode: Country;
}

export const PHOTO_FILTER_PICTURE_TYPE =
  `${DASH_PROFILE_PREFIX}.PhotoFilterPicture` as const;

export interface LinkedInPhotoFilterPicture {
  $type: typeof PHOTO_FILTER_PICTURE_TYPE;
  $recipeTypes: string[];
  displayImageReference?: {
    vectorImage: LinkedInVectorImage;
  };
  displayImageUrn: string;
  photoFilterEditInfo: unknown;
}

export const PHOTO_FILTER_EDIT_INFO_TYPE =
  `${DASH_PROFILE_PREFIX}.PhotoFilterEditInfo` as const;

export const PROFILE_TYPE = `${DASH_PROFILE_PREFIX}.Profile` as const;

export interface LinkedInProfile {
  '$type': typeof PROFILE_TYPE;
  'birthDateOn'?: LinkedInDate;
  'multiLocalePhoneticLastName'?: MultiLocaleString;
  'industryV2Urn'?: unknown;
  'objectUrn': MemberUrn;
  'multiLocaleLastName': MultiLocaleString;
  '*profilePositionGroups': CollectionResponseUrn;
  'profilePositionGroups'?: LinkedInPositionGroup[];
  '*profileVideoPreview': CollectionResponseUrn;
  'profileVideoPreview'?: unknown[]; // TODO: Add video type
  '*profileCertifications': CollectionResponseUrn;
  'profileCertifications'?: LinkedInCertification[];
  'pronounUnion'?: unknown;
  'multiLocaleFirstNamePronunciationHint': MultiLocaleString;
  'multiLocaleLastNamePronunciationHint': MultiLocaleString;
  '*profileTestScores': CollectionResponseUrn;
  'profileTestScores'?: unknown[]; // TODO: Add test score type
  'tempStatusExpiredAtUnion'?: unknown;
  'multiLocaleFirstName': MultiLocaleString;
  'multiLocaleTempStatus'?: MultiLocaleString;
  'companyNameOnProfileTopCardShown': boolean;
  '*profileLanguages': CollectionResponseUrn;
  'profileLanguages'?: unknown[]; // TODO: Add language type
  'state'?: unknown;
  'publicIdentifier': PublicIdentifier;
  'trackingId': TrackingId;
  'phoneticFirstName'?: string;
  'multiLocaleSummary': MultiLocaleString;
  'firstNamePronunciationHint'?: unknown;
  'lastNamePronunciationHint'?: unknown;
  'versionTag': string;
  '*profilePatents': CollectionResponseUrn;
  'profilePatents'?: unknown[]; // TODO: Add profile patent type
  'associatedHashtagsCopy': TextViewModel;
  'profilePicture'?: LinkedInPhotoFilterPicture;
  'firstName': string;
  '*profileOrganizations': CollectionResponseUrn;
  'profileOrganizations'?: unknown[]; // TODO: Add organization type
  'multiLocaleMaidenName'?: MultiLocaleString;
  '*profileHonors': CollectionResponseUrn;
  'profileHonors'?: unknown[]; // TODO: Add honor type
  '*profileSkills': CollectionResponseUrn;
  'profileSkills'?: LinkedInSkill[]; // TODO: check this
  'multiLocaleHeadline': MultiLocaleString;
  '*profileProjects': CollectionResponseUrn;
  'profileProjects'?: unknown[]; // TODO: Add project type
  '*profilePublications': CollectionResponseUrn;
  'profilePublications'?: unknown[]; // TODO: Add publication type
  'memorialized': boolean;
  'lastName': string;
  'multiLocalePhoneticFirstName'?: MultiLocaleString;
  'volunteerCauses': unknown;
  '*industry': DashIndustryUrn;
  'industry'?: LinkedInIndustry;
  'showPremiumSubscriberBadge': boolean;
  'tempStatusEmoji': unknown;
  'maidenName'?: string;
  'geoLocationBackfilled': boolean;
  'multiLocaleFullNamePronunciationAudio': MultiLocaleAudio;
  'phoneticLastName'?: string;
  'premium': boolean;
  'influencer': boolean;
  '*profileCourses': CollectionResponseUrn;
  'profileCourses'?: unknown[]; // TODO: Add course type
  'entityUrn': DashProfileUrn;
  '*profileRingStatusCollection': CollectionResponseUrn;
  'profileRingStatusCollection'?: unknown[]; // TODO: Add ring status type
  '*profileEducations': CollectionResponseUrn;
  'profileEducations'?: LinkedInEducation[];
  'headline': string;
  'fullNamePronunciationAudio': LinkedInAudioMetadata;
  'summary': string;
  'creator': true;
  'supportedLocales': LinkedInLocale[];
  'locationName'?: string;
  'address'?: string;
  'multiLocaleAddress'?: MultiLocaleString;
  '*profileVolunteerExperiences': CollectionResponseUrn;
  'profileVolunteerExperiences'?: unknown[]; // TODO: Add volunteer experience type
  'educationOnProfileTopCardShown': boolean;
  'industryUrn': DashIndustryUrn;
  'geoLocation': LinkedInProfileGeoLocation;
  'tempStatus'?: unknown; // TODO: Add temp status
  '*profileTreasuryMediaProfile': CollectionResponseUrn;
  'profileTreasuryMediaProfile'?: LinkedInTreasuryMedia[];
  'location': LinkedInProfileLocation;
  'backgroundPicture': LinkedInPhotoFilterPicture;
  'primaryLocale': LinkedInLocale;
  'memberIdentity'?: 'UNKNOWN';
  '*memberRelationship'?: DashMemberRelationshipUrn;
  'memberRelationship'?: LinkedInMemberRelationship;
  'conversationID'?: DashConversationUrn;
}

export const MEMBER_BADGES_TYPE = `${PROFILE_PREFIX}.MemberBadges` as const;

export interface LinkedInMemberBadges {
  $type: typeof MEMBER_BADGES_TYPE;
  premium: boolean;
  influencer: boolean;
  entityUrn: MemberBadgesUrn;
  openLink: boolean;
  jobSeeker: boolean;
}

export const CERTIFICATION_TYPE =
  `${DASH_PROFILE_PREFIX}.Certification` as const;

export interface LinkedInCertification {
  $type: typeof CERTIFICATION_TYPE;
  dateRange: LinkedInDateRange;
  multiLocaleLicenseNumber?: MultiLocaleString;
  companyUrn?: CompanyUrn;
  url?: URL;
  multiLocaleAuthority?: MultiLocaleString;
  entityUrn: DashProfileCertificationUrn;
  authority?: string;
  name: string;
  multiLocaleName: MultiLocaleString;
  licenseNumber?: unknown;
  displaySource?: unknown;
}

export const EDUCATION_TYPE = `${DASH_PROFILE_PREFIX}.Education` as const;

export interface LinkedInEducation {
  '$type': typeof EDUCATION_TYPE;
  'dateRange'?: LinkedInDateRange;
  'description'?: string;
  'multiLocaleDescription'?: MultiLocaleString;
  'companyUrn'?: DashCompanyUrn;
  '*company': DashCompanyUrn;
  'company'?: LinkedInCompany;
  '*profileTreasuryMediaEducation': CollectionResponseUrn;
  'profileTreasuryMediaEducation'?: unknown[]; // TODO: Add treasury media type
  'schoolUrn'?: DashSchoolUrn;
  '*school': DashSchoolUrn;
  'school'?: LinkedInSchool;
  'schoolName'?: string;
  'entityUrn': DashProfileEducationUrn;
  'standardizedFieldOfStudyUrn'?: Urn;
  'fieldOfStudy'?: string;
  'multiLocaleFieldOfStudy'?: MultiLocaleString;
  'degreeUrn'?: DashDegreeUrn;
  '*degree': DashDegreeUrn;
  'degree'?: LinkedInStandardizedDegree;
  'degreeName'?: string;
  'multiLocaleDegreeName'?: MultiLocaleString;
  'multiLocaleGrade'?: MultiLocaleString;
  'multiLocaleSchoolName'?: MultiLocaleString;
  'multiLocaleActivities'?: MultiLocaleString;
  'activities'?: string;
  'grade'?: string;
}

export const EMPLOYMENT_TYPE = `${DASH_PROFILE_PREFIX}.Employment` as const;

export interface LinkedInEmployment {
  $type: typeof EMPLOYMENT_TYPE;
  entityUrn: DashEmploymentTypeUrn;
  companyNameRequired: boolean;
  name: string;
}

export const POSITION_TYPE = `${DASH_PROFILE_PREFIX}.Position` as const;

export interface LinkedInPosition {
  '$type': typeof POSITION_TYPE;
  'dateRange': LinkedInDateRange;
  'multiLocaleCompanyName'?: MultiLocaleString;
  'multiLocaleGeoLocationName'?: MultiLocaleString;
  'multiLocaleTitle'?: MultiLocaleString;
  'multiLocaleDescription'?: MultiLocaleString;
  'multiLocaleLocationName'?: MultiLocaleString;
  'companyName': string;
  'description'?: string;
  'title': string;
  '*company': DashCompanyUrn;
  'company'?: LinkedInCompany;
  'companyUrn'?: DashCompanyUrn;
  '*employmentType': DashEmploymentTypeUrn;
  'employmentType'?: LinkedInEmploymentType;
  'employmentTypeUrn'?: DashEmploymentTypeUrn;
  'entityUrn': DashProfilePositionUrn;
  'shouldShowSourceOfHireBadge': boolean;
  'locationName'?: string;
  '*profileTreasuryMediaPosition': CollectionResponseUrn;
  'profileTreasuryMediaPosition'?: unknown[]; // TODO: Add treasury media type
  'regionUrn': DashRegionUrn;
  'geoUrn': DashGeoUrn;
  '*geo': DashGeoUrn;
  'geo'?: LinkedInGeo;
  'geoLocationName': string;
}

export const EMPLOYMENT_TYPE_TYPE =
  `${DASH_PROFILE_PREFIX}.EmploymentType` as const;

export interface LinkedInEmploymentType {
  $type: typeof EMPLOYMENT_TYPE_TYPE;
  entityUrn: DashEmploymentTypeUrn;
  companyNameRequired: boolean;
  name: string;
}

export const POSITION_GROUP_TYPE =
  `${DASH_PROFILE_PREFIX}.PositionGroup` as const;

export interface LinkedInPositionGroup {
  '$type': typeof POSITION_GROUP_TYPE;
  '*profilePositionInPositionGroup': CollectionResponseUrn;
  'profilePositionInPositionGroup'?: CollectionResponse<
    LinkedInPosition,
    unknown
  >;
  'dateRange': LinkedInDateRange;
  'multiLocaleCompanyName': MultiLocaleString;
  'companyName': string;
  '*company': DashCompanyUrn;
  'company'?: LinkedInCompany;
  'companyUrn': DashCompanyUrn;
  'entityUrn': DashProfilePositionGroupUrn;
}

export const SKILL_TYPE = `${DASH_PROFILE_PREFIX}.Skill` as const;

export interface LinkedInSkill {
  $type: typeof SKILL_TYPE;
  name: string;
  multiLocaleName: MultiLocaleString;
}

export const STANDARDIZED_DEGREE_TYPE =
  `${DASH_PROFILE_PREFIX}.StandardizedDegree` as const;

export interface LinkedInStandardizedDegree {
  $type: typeof STANDARDIZED_DEGREE_TYPE;
  entityUrn: DashDegreeUrn;
}
