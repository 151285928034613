import UsageTrendsMonitor from '@common/UsageTrendsMonitor/UsageTrendsMonitor.renderer';
import { UserUsageClass } from '@common/UsageTrendsMonitor/UsageTrendsType';
import { Box, ButtonGroup, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { DelayedSkeleton, FancyButton } from 'ui-utils';

export interface UsageTypeSelectorProps {
  afterChange?: (value: UserUsageClass) => void;
}

export default function UsageTypeSelector(props: UsageTypeSelectorProps) {
  const { userUsageClass, isLoading } = UsageTrendsMonitor.useUserClass();
  const classes = React.useMemo(
    () => UsageTrendsMonitor.listAllUserUsageClasses(),
    []
  );
  return (
    <Box>
      {isLoading && (
        <DelayedSkeleton quantity={classes.length} width={150} height={48} />
      )}
      {!isLoading && (
        <ButtonGroup
          sx={{
            display: 'grid',
            gridTemplateColumns: `repeat(${classes.length}, minmax(100px, 1fr))`
          }}>
          {classes.map((c) => (
            <Tooltip
              key={c.id}
              title={<Typography>{c.description}</Typography>}
              placement="bottom">
              <span>
                <FancyButton
                  fullWidth
                  fType={{ promise: true }}
                  onClick={async () => {
                    await UsageTrendsMonitor.setUserClass(c.id);
                    props.afterChange?.(c.id);
                  }}
                  variant={userUsageClass === c.id ? 'contained' : 'outlined'}
                  color="primary">
                  {c.name}
                </FancyButton>
              </span>
            </Tooltip>
          ))}
        </ButtonGroup>
      )}
    </Box>
  );
}
