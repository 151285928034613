function replaceSpecialChars(text: string): string {
  return (
    text
      .replace(/&#([0-9]{1,4});/gi, (_, numStr) => {
        const num = parseInt(numStr, 10); // read num as normal number
        return String.fromCharCode(num);
      })
      .replace(/&([a-z]{2,4});/gi, (match) => {
        switch (match) {
          case '&nbsp;':
            return ' ';
          case '&lt;':
            return '<';
          case '&gt;':
            return '>';
          case '&amp;':
            return '&';
          default:
            return '';
        }
      })
      // replace "lsep"
      // eslint-disable-next-line no-irregular-whitespace -- we specifically want to replace this character
      .replace(` `, '\n')
  );
}

const TextEditorHelper = {
  replaceSpecialChars
};

export default TextEditorHelper;
