import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Popover,
  TextField,
  Theme,
  Tooltip,
  Typography
} from '@mui/material';
import { Box } from '@mui/material';
import { Close, Delete } from '@mui/icons-material';
import TemplateActions from '@/data/DataServer/Templates';
import useError from '@/hooks/ErrorHook';
import { Editor } from '@tiptap/react';
import TemplateSelector from './TemplateSelector';
import { MessageTemplate } from '@common/types/ApiTypes';
import { language } from '@/index';
import {
  CircularProgressIcon,
  DelayedSkeleton,
  FancyButton,
  TextEditor
} from 'ui-utils';
import {
  VARIABLE_TYPES,
  getVariableName
} from 'ui-utils/src/components/TextEditor/CustomBlocks/Variable/VariableBlock';

const RESPONSE_RATE_THRESHOLDS = [
  { threshold: 0.9, color: (theme: Theme) => theme.palette.success.dark },
  { threshold: 0.6, color: (theme: Theme) => theme.palette.success.light },
  { threshold: 0.4, color: (theme: Theme) => theme.palette.warning.main },
  { threshold: 0.0, color: (theme: Theme) => theme.palette.error.main }
];

export interface EditPipelineTemplatesProps {
  open?: boolean;
  templateID: MessageTemplate['templateID'] | null;
  onClose?: () => void;
}

export default function EditPipelineTemplates(
  props: EditPipelineTemplatesProps
) {
  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const { errorInfo, handleError, reset: resetError } = useError();

  const editorRef = React.useRef<Editor | null>(null);

  const { template, status: userTemplateStatus } = TemplateActions.useTemplate(
    props.templateID
  );

  const loading = userTemplateStatus === 'loading';

  const [defaultTextAreaValue, setDefaultTextAreaValue] = React.useState('');
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [userTemplateName, setUserTemplateName] = React.useState('');

  // id is `-1` when template is not created yet
  const [selectedTemplate, setSelectedTemplate] =
    React.useState<MessageTemplate | null>(null);
  const [unsavedChangesOpen, setUnsavedChangesOpen] = React.useState(false);

  const changed = React.useMemo(() => {
    if (!selectedTemplate) return false;
    if (selectedTemplate.text !== textAreaValue) return true;
    if (selectedTemplate.name !== userTemplateName) return true;
    return false;
  }, [selectedTemplate, textAreaValue, userTemplateName]);

  const deleteRef = React.useRef<HTMLButtonElement | null>(null);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  // select first template when templates are loaded
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- logical OR is more readable here
    if (selectedTemplate || !props.open) return;
    if (template && template.templates.length > 0) {
      setSelectedTemplate(template.templates[0]);
    }
  }, [template, props.open]);

  // set values when template gets selected
  React.useEffect(() => {
    if (selectedTemplate && props.open) {
      setDefaultTextAreaValue(selectedTemplate.text);
      setTextAreaValue(selectedTemplate.text);
      setUserTemplateName(selectedTemplate.name);
    }
  }, [selectedTemplate, props.open]);

  // reset selected template when dialog is closed
  React.useEffect(() => {
    if (!props.open) {
      setTextAreaValue('');
      setDefaultTextAreaValue('');
      setSelectedTemplate(null);
      setUserTemplateName('');
      resetError();
    }
  }, [props.open]);

  const save = handleError(async (text: string) => {
    if (!selectedTemplate || !props.templateID || !template || nameError)
      return false;
    const newID = await TemplateActions.setTemplate({
      templateID: props.templateID,
      variantID:
        selectedTemplate.variantID < 0 ? undefined : selectedTemplate.variantID,
      name: userTemplateName.trim(),
      text
    });
    setSelectedTemplate({
      ...selectedTemplate,
      variantID: newID,
      name: userTemplateName,
      text
    });
    return true;
  });

  const deleteCurrentTemplate = handleError(async () => {
    if (!selectedTemplate || !userTemplateName || !template) return;
    await TemplateActions.deleteTemplate(selectedTemplate.variantID);
    setSelectedTemplate(
      template.templates.find(
        (t) => t.variantID !== selectedTemplate.variantID
      ) ?? null
    );
    setDeleteOpen(false);
  });

  const nameError = Boolean(
    userTemplateName.trim() === '' ||
      template?.templates.some(
        (template) =>
          template.name.toLocaleLowerCase().trim() ===
            userTemplateName.toLocaleLowerCase().trim() &&
          template.variantID !== selectedTemplate?.variantID
      )
  );

  const [nameFieldFocused, setNameFieldFocused] = React.useState(false);

  return (
    <>
      <Dialog
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- the behavior is intended
        open={props.open || false}
        onClose={() => {
          if (changed) setUnsavedChangesOpen(true);
          else props.onClose?.();
        }}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            marginTop: '110px',
            backgroundColor: (theme) => theme.palette.background.default
          }
        }}>
        <DialogTitle sx={{ textAlign: 'center', fontSize: '3em' }}>
          {template?.name ?? language.text.edit_template}
        </DialogTitle>
        {!loading && template ? (
          <>
            <DialogContent
              sx={{
                height: 'calc(100vh - 200px)',
                display: 'flex',
                flexDirection: 'column'
              }}>
              {!errorInfo ? (
                <>
                  <Typography mb={3}>{template.description}</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      height: '100%',
                      gap: 2
                    }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                      justifyContent="flex-start"
                      marginBottom={1}
                      gap={2}>
                      <Box
                        sx={{
                          width: '250px',
                          minWidth: '250px',
                          height: '100%'
                        }}>
                        <Typography
                          sx={{
                            textAlign: 'center',
                            fontSize: '16px',
                            mb: 1,
                            fontWeight: 'bold'
                          }}>
                          {language.text.select_ssc_template}
                        </Typography>
                        <TemplateSelector
                          onSelect={setSelectedTemplate}
                          templateID={props.templateID}
                          selectedTemplate={selectedTemplate}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        height: '100%',
                        gap: 1,
                        flexGrow: 1
                      }}>
                      <Box
                        display="flex"
                        gap={1}
                        width="100%"
                        boxSizing="border-box">
                        {selectedTemplate?.responseRate !== undefined ? (
                          <Tooltip
                            title={
                              <Typography>
                                {language.text.this_template_has_a_response_rate_of(
                                  Math.round(
                                    selectedTemplate.responseRate * 100
                                  )
                                )}
                              </Typography>
                            }
                            placement="top">
                            <Box display="flex">
                              <CircularProgressIcon
                                percentage={selectedTemplate.responseRate}
                                sx={{ height: '2rem', width: '2rem' }}
                                color={
                                  RESPONSE_RATE_THRESHOLDS.find(
                                    (r) =>
                                      r.threshold <=
                                      (selectedTemplate.responseRate ?? 0)
                                  )?.color
                                }
                              />
                            </Box>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={
                              <Typography>
                                {
                                  language.text
                                    .not_enough_message_with_template_sent_for_response_rate_calculation
                                }
                              </Typography>
                            }
                            placement="top">
                            <CircularProgressIcon
                              percentage={1}
                              color={(theme) => theme.palette.background.paper}
                              sx={{ height: '2rem', width: '2rem' }}
                            />
                          </Tooltip>
                        )}
                        <Tooltip
                          open={
                            userTemplateName.trim() !== '' &&
                            nameError &&
                            nameFieldFocused
                          }
                          title={
                            <Typography>
                              {language.text.template_name_already_used}
                            </Typography>
                          }>
                          <TextField
                            variant="standard"
                            color={nameError ? 'error' : undefined}
                            value={userTemplateName}
                            onChange={(e) =>
                              setUserTemplateName(e.target.value)
                            }
                            sx={{ flexGrow: 1, maxWidth: '300px' }}
                            error={nameError}
                            onFocus={() => setNameFieldFocused(true)}
                            onBlur={() => setNameFieldFocused(false)}
                          />
                        </Tooltip>
                        <Box flexGrow={1} />
                        <FancyButton
                          fType={{ promise: true }}
                          ref={deleteRef}
                          variant="outlined"
                          color="error"
                          startIcon={<Delete />}
                          disabled={!selectedTemplate || !userTemplateName}
                          onClick={async () => {
                            if (
                              selectedTemplate &&
                              !selectedTemplate.text &&
                              !textAreaValue
                            ) {
                              await deleteCurrentTemplate();
                            } else setDeleteOpen(true);
                          }}>
                          {language.text.delete}
                        </FancyButton>
                        <Popover
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                          }}
                          anchorEl={deleteRef.current}
                          open={deleteOpen}
                          onClose={() => setDeleteOpen(false)}>
                          <Box p={2}>
                            <Typography>
                              {language.text.confirm_delete_template}
                            </Typography>
                            <Box
                              display="flex"
                              gap={2}
                              justifyContent="flex-end"
                              mt={2}>
                              <Button
                                variant="contained"
                                color="neutral"
                                onClick={() => setDeleteOpen(false)}>
                                {language.text.cancel}
                              </Button>
                              <FancyButton
                                variant="outlined"
                                color="error"
                                fType={{ promise: true }}
                                disabled={
                                  !selectedTemplate || !userTemplateName
                                }
                                onClick={deleteCurrentTemplate}>
                                {language.text.delete}
                              </FancyButton>
                            </Box>
                          </Box>
                        </Popover>
                      </Box>
                      <TextEditor
                        margin="none"
                        disabledBorder
                        showEmojiInput
                        value={defaultTextAreaValue}
                        getEditor={(editor) => (editorRef.current = editor)}
                        onChange={({ text }) => {
                          setTextAreaValue(text);
                        }}
                        sx={{
                          flexGrow: 1,
                          width: '100%',
                          gap: 1
                        }}
                        maxHeight="calc(100vh - 440px)"
                        sxEditor={{
                          backgroundColor: (theme) =>
                            theme.palette.background.paper,
                          borderRadius: 1,
                          padding: (theme) => theme.spacing(1)
                        }}
                        placeholderEditable>
                        {VARIABLE_TYPES.map((v) => (
                          <Button
                            variant="outlined"
                            color="secondary"
                            sx={{
                              ml: 1,
                              color: 'secondary.contrastText',
                              borderColor: 'secondary.contrastText'
                            }}
                            key={v}
                            onClick={() => {
                              editorRef.current?.commands.setVariable({
                                id: v
                              });
                              editorRef.current?.commands.focus();
                            }}>
                            {getVariableName(v)}
                          </Button>
                        ))}
                        <Button
                          variant="outlined"
                          color="secondary"
                          sx={{
                            ml: 1,
                            color: 'secondary.contrastText',
                            borderColor: 'secondary.contrastText'
                          }}
                          key="placeholder"
                          onClick={() => {
                            editorRef.current?.commands.setPlaceholder({});

                            editorRef.current?.commands.focus();
                          }}>
                          {language.text.insert_placeholder}
                        </Button>
                      </TextEditor>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          width: '100%',
                          marginTop: (theme) => theme.spacing(1)
                        }}>
                        <Button
                          onClick={() => {
                            if (changed) setUnsavedChangesOpen(true);
                            else props.onClose?.();
                          }}
                          color="hud"
                          variant="contained">
                          {language.text.back}
                        </Button>
                        <FancyButton
                          sx={{
                            flex: 3,
                            marginLeft: (theme) => theme.spacing(1),
                            ...(nameError && { pointerEvents: 'none' })
                          }}
                          fType={{
                            promise: true
                          }}
                          onClick={async () => {
                            if (nameError) return;
                            await save(textAreaValue);
                          }}
                          disabled={!changed}
                          variant="contained"
                          color={nameError ? 'error' : 'secondary'}>
                          {language.text.save}
                        </FancyButton>
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <Typography>
                  {language.text.generic_error}
                  <br />
                  <Typography component="span" color="error">
                    {errorInfo}
                  </Typography>
                </Typography>
              )}
            </DialogContent>
          </>
        ) : (
          <Box paddingX={2.5} paddingBottom={3} overflow="hidden">
            <DelayedSkeleton height={191} width={522} variant="rectangular" />
          </Box>
        )}
        <IconButton
          color="default"
          onClick={() => {
            if (changed) setUnsavedChangesOpen(true);
            else props.onClose?.();
          }}
          sx={{
            position: 'absolute',
            top: (theme) => theme.spacing(1),
            right: (theme) => theme.spacing(1)
          }}>
          <Close color="primary" />
        </IconButton>
      </Dialog>
      <Dialog
        open={unsavedChangesOpen}
        onClose={() => setUnsavedChangesOpen(false)}>
        <DialogTitle>{language.text.unsaved_changes}</DialogTitle>
        <DialogContent>
          <Typography>{language.text.you_have_unsaved_changes}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUnsavedChangesOpen(false)}>
            {language.text.cancel}
          </Button>
          <Button
            onClick={() => {
              setUnsavedChangesOpen(false);
              props.onClose?.();
            }}
            variant="contained">
            {language.text.discard}
          </Button>
          <FancyButton
            fType={{
              promise: true
            }}
            onClick={async () => {
              await save(textAreaValue);
              setUnsavedChangesOpen(false);
              props.onClose?.();
            }}
            variant="contained"
            color="primary"
            disabled={nameError}>
            {language.text.save}
          </FancyButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
