import {
  DashConnectionUrn,
  DashMemberRelationshipUrn,
  DashProfileUrn,
  InvitationUrn
} from './Urns';
import { LinkedInProfile } from './Profile';

const RELATIONSHIPS_DASH_PREFIX = 'com.linkedin.voyager.dash.relationships';

export const CONNECTION_TYPE =
  `${RELATIONSHIPS_DASH_PREFIX}.Connection` as const;

export interface LinkedInConnection {
  '$type': typeof CONNECTION_TYPE;
  'entityUrn': DashConnectionUrn;
  'connectionMember': DashProfileUrn;
  '*connectedMemberResolutionResult'?: DashProfileUrn;
  'connectedMemberResolutionResult'?: LinkedInProfile;
  'createdAt': number;
}

export const NO_CONNECTION_TYPE =
  `${RELATIONSHIPS_DASH_PREFIX}.NoConnection` as const;

export const MEMBER_RELATIONSHIP_TYPE =
  `${RELATIONSHIPS_DASH_PREFIX}.MemberRelationship` as const;

export interface LinkedInMemberRelationship {
  $type: typeof MEMBER_RELATIONSHIP_TYPE;
  entityUrn: DashMemberRelationshipUrn;
  memberRelationshipData: {
    '*connection': DashConnectionUrn;
    'connection'?: LinkedInConnection;
  };
  memberRelationshipUnion: {
    '*connection': DashConnectionUrn;
    'connection'?: LinkedInConnection;
    'noConnection'?: {
      memberDistance: string;
      $type: typeof NO_CONNECTION_TYPE;
    };
  };
  memberRelationship?:
    | {
        '*connection': DashConnectionUrn;
        'connection'?: LinkedInConnection;
      }
    | {
        '*noConnection': {
          memberDistance: string;
          $type: typeof NO_CONNECTION_TYPE;
        };
        '*invitation': InvitationUrn;
        'invitation'?: unknown;
      };
}
