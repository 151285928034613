import React from 'react';
import { useInView } from 'react-intersection-observer';
import ChatMessage from './ChatMessage';
import { Box, BoxProps, CircularProgress, Typography } from '@mui/material';
import { Done, DoneAll } from '@mui/icons-material';
import { useRefSize } from 'ui-utils';
import { ConversationContact } from 'linkedin-domain-types';
import { useLinkedInAccount } from '@/data/LinkedIn/Account';
import { Message } from '@common/types/ApiTypes';
import { language } from '@/index';
import { Dates } from '@idot-digital/generic-helpers';

export interface ChatHistoryStaticProps {
  error?: boolean;
  hasNextPage?: boolean;
  messages?: (Message & { new?: boolean; sentFrom?: string })[];
  participants: (Pick<
    ConversationContact,
    'firstName' | 'lastName' | 'profileID' | 'profilePictureUrl'
  > &
    Partial<Pick<ConversationContact, 'publicIdentifier'>>)[];
  unreadCount?: number;
  callbackLoadMore?: (loadMore: boolean) => void;
  boxProps?: BoxProps;
  setMessageReaction?: (
    message: Message,
    reaction: string,
    status: boolean
  ) => void;
  disabled?: boolean;
}

export default function ChatHistoryStatic(props: ChatHistoryStaticProps) {
  const { linkedInAccount } = useLinkedInAccount();
  const { ref, inView } = useInView();
  // prevent scrolling to bottom when loading new messages
  const scrollContainer = React.useRef<HTMLElement>();
  const { height } = useRefSize(scrollContainer);
  const currentScrollBottom = React.useRef<number>(0);

  // keep scroll position from bottom, when height changes
  // height changes can come from new messages loaded, window resize, text editor height change, etc.
  React.useEffect(() => {
    if (!scrollContainer.current) return;
    scrollContainer.current.scrollTop =
      scrollContainer.current.scrollHeight -
      currentScrollBottom.current -
      scrollContainer.current.clientHeight;
  }, [height]);

  const scrolledToBottom = React.useRef<boolean>(false);

  // store current last message to check if new message was sent when messages change
  const lastMessageID = React.useRef<string | null>(
    props.messages?.at(-1)?.messageID ?? null
  );

  const messages = React.useMemo(() => {
    // update messages
    // copy first since .reverse() mutates array (reverses in place)
    if (!props.messages) return [];
    const mappedMessages = [...props.messages].reverse();

    const lastMessage = mappedMessages.at(-1);
    if (!lastMessage) return mappedMessages;

    // add new message indicator
    if (
      lastMessageID.current &&
      lastMessageID.current !== lastMessage.messageID &&
      lastMessage.new !== false
    )
      lastMessage.new = true;

    return mappedMessages.map((m) => ({
      ...m,
      new: m.new ?? false
    }));
  }, [props.messages]);

  // set lastMessageID to last message when messages change -> set AFTER useMemo of messages
  React.useEffect(() => {
    if (messages.length === 0) {
      // if no messages, set firstMessageID to artificial id to make first message animate
      lastMessageID.current = 'artifical_first_id';
      return;
    }
    lastMessageID.current = messages[messages.length - 1].messageID;
  }, [messages]);

  // keep scroll top when new messages are added
  React.useEffect(() => {
    // set scroll position to current since new messages were added
    // wait one frame for messages to be mounted
    setTimeout(() => {
      setTimeout(() => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollTop =
            scrollContainer.current.scrollHeight -
            scrollContainer.current.clientHeight -
            currentScrollBottom.current;
        }
      });
    });
  }, [messages]);

  // scroll to bottom when new message is sent
  const scrollToMessageID = React.useRef<string | null>(null);
  React.useEffect(() => {
    // wait one frame for messages to be mounted and then scroll to bottom
    setTimeout(() => {
      setTimeout(() => {
        if (messages.length === 0) return;
        if (
          messages[messages.length - 1].messageID === scrollToMessageID.current
        )
          return;
        if (!scrollContainer.current) {
          return;
        }
        scrollToMessageID.current = messages[messages.length - 1].messageID;
        scrollContainer.current.scrollTop =
          scrollContainer.current.scrollHeight;
        currentScrollBottom.current = 0;
      });
    });
  }, [messages]);

  const showNames = React.useMemo(
    () => props.participants.length > 1,
    [props.participants]
  );

  React.useEffect(() => props.callbackLoadMore?.(inView), [inView]);

  return (
    <Box
      {...props.boxProps}
      sx={{
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        ...props.boxProps?.sx
      }}
      // scroll to bottom on render
      ref={(ref: HTMLElement | null) => {
        if (!ref) return;
        scrollContainer.current = ref;
        if (!scrolledToBottom.current) {
          scrolledToBottom.current = true;
          ref.scrollTop = ref.scrollHeight;
        }
      }}
      onScroll={() => {
        // save scroll position from bottom
        if (!scrollContainer.current) return;
        const scrollBottom =
          scrollContainer.current.scrollHeight -
          scrollContainer.current.clientHeight -
          scrollContainer.current.scrollTop;
        currentScrollBottom.current = scrollBottom;
      }}>
      <Box
        ref={ref}
        sx={{
          height: (theme) => theme.spacing(8),
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          display: 'flex'
        }}>
        {props.error ? (
          <Typography variant="body2" color="error">
            {language.text.error_while_loading_chat}
          </Typography>
        ) : !props.hasNextPage ? (
          <Typography variant="body2">
            {language.text.start_of_the_chat_with.replace(
              '{name}',
              props.participants
                .map((p) => p.firstName + ' ' + p.lastName)
                .join(', ')
            )}
          </Typography>
        ) : (
          <CircularProgress />
        )}
      </Box>
      {messages.map((message, i) => {
        const sentByMe =
          ('sendByYou' in message && message.sendByYou) ||
          ('sentFrom' in message &&
            linkedInAccount?.publicIdentifier === message.sentFrom);
        return (
          <React.Fragment key={message.messageID}>
            {/* display unread divider if this is the first/oldest unread message */}
            {i === messages.length - (props.unreadCount ?? 0) && (
              <Box
                sx={{
                  width: (theme) => `calc(100% - ${theme.spacing(4)})`,
                  margin: (theme) => theme.spacing(1, 2),
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <Box
                  sx={{
                    height: '1px',
                    flex: '1 0 0',
                    background: (theme) => theme.palette.divider,
                    marginRight: (theme) => theme.spacing(1)
                  }}
                />
                <Typography
                  color="primary"
                  fontFamily="Roboto"
                  fontSize=".9em"
                  fontWeight={500}>
                  {language.text.new_message}
                </Typography>
                <Box
                  sx={{
                    height: '1px',
                    flex: '1 0 0',
                    background: (theme) => theme.palette.divider,
                    marginLeft: (theme) => theme.spacing(1)
                  }}
                />
              </Box>
            )}
            {/* display timestamp when either this is the first message (oldest currently loaded message) or the previous message is from a different day */}
            {(i === 0 ||
              !Dates.onSameDay(
                messages[i - 1].createdAt,
                message.createdAt
              )) && (
              <Typography
                sx={{
                  background: (theme) => theme.palette.background.default,
                  textAlign: 'center',
                  width: 'fit-content',
                  padding: (theme) => theme.spacing(0.5, 1),
                  borderRadius: 2,
                  position: 'sticky',
                  top: (theme) => theme.spacing(1),
                  margin: (theme) => theme.spacing(0, 'auto'),
                  fontSize: '.9em',
                  fontFamily: 'Roboto',
                  color: (theme) => theme.palette.text.secondary,
                  mb: 1,
                  zIndex: 1,
                  border: (theme) => `1px solid ${theme.palette.divider}`
                  // only apply box shadow to first timestamp -> all timestamp overlay each other and so the shadow would be applied multiple times
                  // boxShadow: i === 0 ? (theme) => theme.shadows[1] : undefined,
                }}>
                {new Date(message.createdAt).toLocaleDateString(
                  language.getLanguage()
                )}
              </Typography>
            )}
            {/* <ParticipantName
              message={message}
              participants={props.participants}
            /> */}
            <ChatMessage
              onLoad={() => {
                if (
                  scrollContainer.current &&
                  currentScrollBottom.current <
                    scrollContainer.current.clientHeight
                )
                  scrollContainer.current.scrollTop =
                    scrollContainer.current.scrollHeight;
              }}
              setReaction={props.setMessageReaction}
              message={message}
              participants={props.participants}
              contact={(() => {
                if (!showNames) return null;
                if (sentByMe) return null;
                if (!message.sentFrom) return null;

                const previousMessage = messages[i - 1] as
                  | (Message & { sentFrom?: string })
                  | undefined;
                // display name only if the previous message was not sent by the same person
                if (
                  previousMessage &&
                  previousMessage.sentFrom === message.sentFrom &&
                  Dates.onSameDay(previousMessage.createdAt, message.createdAt)
                )
                  return null;

                const participant =
                  props.participants.find(
                    (p) => p.publicIdentifier === message.sentFrom
                  ) ?? null;

                if (!participant) return null;

                return {
                  name: participant.firstName + ' ' + participant.lastName,
                  pictures: participant.profilePictureUrl
                };
              })()}
              disabled={props.disabled}
            />
            {/* time of message - hide when messages are not more than 1min appart */}
            <MessageTime
              i={i}
              message={message}
              messages={messages}
              unreadCount={props.unreadCount ?? 0}
            />
          </React.Fragment>
        );
      })}
    </Box>
  );
}

function MessageTime(props: {
  message: Message;
  i: number;
  messages: Message[];
  unreadCount: number;
}) {
  const { message, i, messages, unreadCount } = props;

  const shouldDisplayTime = (() => {
    if (message.sendByYou) return true;
    if (i === messages.length - 1) return true;
    const nextMessage = messages[i + 1];
    if (nextMessage.sendByYou) return true;
    return (
      messages[i + 1].createdAt.getTime() - message.createdAt.getTime() >
      1000 * 60
    );
  })();

  return (
    <Box
      display="flex"
      justifyContent={message.sendByYou ? 'flex-end' : 'flex-start'}
      width="100%"
      boxSizing="border-box"
      px={2}
      pb={1}>
      {shouldDisplayTime && (
        <Typography
          variant="caption"
          sx={{
            fontSize: '0.8em',
            color: (theme) => theme.palette.text.secondary
          }}>
          {new Date(message.createdAt).toLocaleTimeString(
            language.getLanguage(),
            {
              hour: '2-digit',
              minute: '2-digit'
            }
          )}
        </Typography>
      )}
      {message.sendByYou && (
        <Box
          sx={{
            marginLeft: (theme) => theme.spacing(0.5),
            marginTop: (theme) => theme.spacing(0.5),
            height: (theme) => theme.spacing(2),
            width: (theme) => theme.spacing(2)
          }}>
          {
            messages.length - i > unreadCount ? (
              <DoneAll
                fontSize="small"
                color="primary"
                sx={{
                  height: (theme) => theme.spacing(2),
                  width: (theme) => theme.spacing(2)
                }}
              />
            ) : (
              // code to potentially add state when message is sending
              // message.sent ?
              <Done
                fontSize="small"
                color="disabled"
                sx={{
                  height: (theme) => theme.spacing(2),
                  width: (theme) => theme.spacing(2)
                }}
              />
            )
            //  : (
            //   <AccessTime
            //     fontSize="small"
            //     color="disabled"
            //     sx={{
            //       height: (theme) => theme.spacing(2),
            //       width: (theme) => theme.spacing(2)
            //     }}
            //   />
            // )
          }
        </Box>
      )}
    </Box>
  );
}
