import { HistoricStatsFilter as HistoricStatsFilterType } from '@/data/DataServer/Stats';
import { language } from '@/index';
import { Dates } from '@/other/Dates';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { OptionalTooltip, SelectWrapper } from 'ui-utils';
import StatsHelper from '../StatsHelper';

export interface HistoricStatsFilterProps {
  filter: HistoricStatsFilterType;
  onChange: (filter: HistoricStatsFilterType) => void;
}

const getTimeIntervals = () => [
  {
    id: 'week',
    label: language.text.last_week,
    offsetLabel: language.text.week,
    value: 'days',
    interval: 1,
    daysLength: 6
  } as const,
  {
    id: 'month',
    label: language.text.last_month,
    offsetLabel: language.text.month,
    value: 'days',
    interval: 1,
    daysLength: 29
  } as const,
  {
    id: 'quarter',
    label: language.text.last_quarter,
    offsetLabel: language.text.quarter,
    value: 'weeks',
    interval: 1,
    daysLength: 13 * 7 - 1
  } as const,
  {
    id: 'year',
    label: language.text.last_year,
    offsetLabel: language.text.year,
    value: 'months',
    interval: 1,
    daysLength: 364
  } as const
];

export default function HistoricStatsFilter(props: HistoricStatsFilterProps) {
  const TIME_INTERVALS = React.useMemo(getTimeIntervals, []);
  const timeInterval = React.useMemo(() => {
    const filterDayLength =
      Dates.getDatesBetween(props.filter.startDate, props.filter.endDate)
        .length - 1;
    return (
      TIME_INTERVALS.find(
        (interval) => interval.daysLength === filterDayLength
      ) ?? null
    );
  }, [props.filter]);

  const [offset, setOffset] = React.useState(0);

  const changeInterval = React.useCallback(
    (interval = timeInterval ?? TIME_INTERVALS[0], _offset = offset) => {
      const startDate = Dates.add(new Date(), -1, 'day');
      startDate.setHours(0, 0, 0, 0);
      Dates.add(startDate, interval.daysLength * (_offset - 1), 'day');
      const endDate = new Date(startDate);
      Dates.add(endDate, interval.daysLength, 'day');
      endDate.setHours(23, 59, 59, 0);
      props.onChange({
        ...props.filter,
        startDate,
        endDate,
        interval: interval.value,
        intervalFactor: interval.interval
      });
    },
    [offset, timeInterval, props.filter]
  );

  // since we don't have data for the future, we can't show stats for today or beyond
  const reachedNewestDate = React.useMemo(() => {
    const normalizedEndDate = new Date(props.filter.endDate);
    normalizedEndDate.setHours(2, 0, 0, 0);
    const yesterday = Dates.add(new Date(), -1, 'day');
    yesterday.setHours(2, 0, 0, 0);
    return normalizedEndDate.getTime() >= yesterday.getTime();
  }, [props.filter.endDate]);

  return (
    <Box
      display="grid"
      gap={2}
      alignItems="center"
      gridTemplateColumns="1fr auto 1fr">
      <Box>
        <SelectWrapper
          sx={{ minWidth: 200 }}
          variant="outlined"
          value={props.filter.stats[0]}
          onChange={(value) => {
            props.onChange({
              ...props.filter,
              stats: [value]
            });
          }}
          options={StatsHelper.getStatCategories().flatMap((category) => [
            { divider: category.title },
            ...category.stats.map((type) => ({
              value: type,
              label: StatsHelper.getStatsAsset(type)?.title ?? '[UNKNOWN]'
            }))
          ])}
          hideUnderline
        />
      </Box>
      <Box ml={2} display="flex" alignItems="center" gap={1}>
        <Typography sx={{ minWidth: 100, textAlign: 'right' }}>
          {props.filter.startDate.toLocaleDateString()}
        </Typography>
        <IconButton
          onClick={() => {
            setOffset(offset - 1);
            changeInterval(undefined, offset - 1);
          }}>
          <ChevronLeft />
        </IconButton>
        <SelectWrapper
          sx={{ minWidth: 200 }}
          variant="outlined"
          value={(timeInterval ?? TIME_INTERVALS[0]).id}
          onChange={(id) => {
            setOffset(0);
            const interval = TIME_INTERVALS.find((i) => i.id === id);
            changeInterval(interval, 0);
          }}
          options={TIME_INTERVALS.map((interval) => ({
            value: interval.id,
            label: interval.label
          }))}
          renderValue={(value) => {
            const interval =
              TIME_INTERVALS.find((i) => i.id === value) ?? TIME_INTERVALS[0];
            if (reachedNewestDate) return interval.label;
            return interval.offsetLabel;
          }}
        />
        <OptionalTooltip
          title={
            reachedNewestDate &&
            language.text.there_are_no_stats_for_today_or_future
          }>
          <span>
            <IconButton
              disabled={reachedNewestDate}
              onClick={() => {
                setOffset(offset + 1);
                changeInterval(undefined, offset + 1);
              }}>
              <ChevronRight color="inherit" />
            </IconButton>
          </span>
        </OptionalTooltip>
        <Typography sx={{ minWidth: 100 }}>
          {props.filter.endDate.toLocaleDateString(language.getLanguage())}
        </Typography>
      </Box>
      <Box />
    </Box>
  );
}
