import { Route, Routes } from 'react-router-dom';
import Updater from './Updater';
import UpdateRequired from './UpdateRequired';
import { UPDATER_PATHS } from '@/globals/Paths';

const ROUTES = [
  {
    element: <Updater />,
    path: UPDATER_PATHS.Updater
  },
  {
    element: <UpdateRequired />,
    path: UPDATER_PATHS.Required
  }
] as const;

export default function UpdateRouter() {
  return (
    <Routes>
      {ROUTES.map(({ element, path }) => (
        <Route key={path} element={element} path={path} />
      ))}
    </Routes>
  );
}
