import { AudienceHolderSnackBarProps } from '../components/AudienceHolderSnackBar/AudienceHolderSnackBar';
import { grey } from '@mui/material/colors';
import { responsiveFontSizes, createTheme } from '@mui/material/styles';
import { PaletteColor, PaletteColorOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: PaletteColor;
    neutral: PaletteColor;
    hud: PaletteColor;
    linkedin: PaletteColor;
  }
  interface PaletteOptions {
    tertiary: PaletteColorOptions;
    neutral: PaletteColorOptions;
    hud: PaletteColorOptions;
    linkedin: PaletteColorOptions;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    neutral: true;
    hud: true;
    linkedin: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    tertiary: true;
    neutral: true;
    hud: true;
    linkedin: true;
  }
}

declare module 'notistack' {
  interface VariantOverrides {
    audienceHolder: AudienceHolderSnackBarProps;
  }
}

const { palette } = createTheme();
const globalTheme = createTheme({
  palette: {
    primary: {
      'main': '#0062ff',
      'contrastText': '#fff',
      'light': '#499eeb',
      'dark': '#2735ef',
      '100': '#fafafb'
    },
    secondary: {
      main: '#FADA6D',
      dark: '#dfc15f',
      contrastText: '#44444F'
    },
    tertiary: palette.augmentColor({
      color: {
        main: '#9933CC'
      }
    }),
    neutral: palette.augmentColor({
      color: {
        main: grey[300],
        dark: grey[500]
      }
    }),
    linkedin: palette.augmentColor({
      color: {
        main: '#2e65c5'
      }
    }),
    text: {
      primary: '#171725',
      secondary: '#707070',
      disabled: '#E3E7F2'
    },
    background: {
      // needs to be 6 digits to be able to add transparency
      default: '#ffffff',
      // background of linkedin
      paper: '#f3f2ef'
    },
    error: {
      main: '#be4b00',
      dark: '#d44c4c',
      light: '#FC5A5A',
      contrastText: '#fff'
    },
    warning: {
      main: '#f2be00'
    },
    success: {
      main: '#78dc74',
      dark: '#14950e'
    },
    divider: 'rgba(0,0,0,0.08)',
    action: {
      disabled: '#707070',
      selected: '#fbfdff'
    },
    grey: {
      '100': '#E4E4E4',
      '200': '#5E6C84'
    },
    hud: palette.augmentColor({
      color: {
        light: '#E9EDF0',
        main: '#1E2334'
      }
    })
  },
  shape: {
    // linkedin default is "0.8rem", would be 12.8px
    // we use custom border radius of 1rem = 16px
    borderRadius: 8
  },
  typography: {
    fontFamily: 'Roboto',
    h1: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '4rem'
    },
    h2: {
      fontFamily: 'Poppins'
    },
    h3: {
      fontFamily: 'Poppins'
    },
    h4: {
      fontFamily: 'Poppins'
    },
    h5: {
      fontFamily: 'Poppins'
    },
    h6: {
      fontFamily: 'Poppins'
    },
    caption: {
      fontFamily: 'Poppins'
    }
  }
});

let muiTheme = createTheme(
  {
    components: {
      MuiButtonBase: {
        styleOverrides: {
          root: {
            // disable button text to be all uppercase
            textTransform: 'none'
          }
        }
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true
        },
        styleOverrides: {
          root: {
            // disable button text to be all uppercase
            textTransform: 'none'
          }
        }
      },
      MuiInput: {
        defaultProps: {
          lang: 'de'
        }
      }
    }
  },
  globalTheme
);

muiTheme = responsiveFontSizes(muiTheme);

export default muiTheme;
