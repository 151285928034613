import { Route, Routes } from 'react-router-dom';
import AcceptTerms from './pages/terms/AcceptTerms';
import VideoPlayer from './pages/video/VideoPlayer';
import ShowWebsite from './pages/website/ShowWebsite';
import SetupSuccess from './pages/success/SetupSuccess';
import SetupLoading from './pages/loading/SetupLoading';
import AvaTutorial from './pages/ava/AvaTutorial';
import SetupConfirmations from './pages/confirmations/SetupConfirmations';
import { SETUP_PATHS } from '@/globals/Paths';
import SetupLinkedInLoading from './pages/loading/SetupLinkedInLoading';
import { Loading } from 'ui-utils';
import SetupUsage from './pages/usage/SetupUsage';

export default function SetupRouter() {
  return (
    <Routes>
      <Route index element={<Loading hidden={false} />} />
      <Route path={SETUP_PATHS.AvaTutorial} element={<AvaTutorial />} />
      <Route path={SETUP_PATHS.Terms} element={<AcceptTerms />} />
      <Route path={SETUP_PATHS.Video} element={<VideoPlayer />} />
      <Route path={SETUP_PATHS.Website} element={<ShowWebsite />} />
      <Route
        path={SETUP_PATHS.Confirmations}
        element={<SetupConfirmations />}
      />
      <Route path={SETUP_PATHS.Loading} element={<SetupLoading />} />
      <Route
        path={SETUP_PATHS.LinkedInLoading}
        element={<SetupLinkedInLoading />}
      />
      <Route path={SETUP_PATHS.Success} element={<SetupSuccess />} />
      <Route path={SETUP_PATHS.Usage} element={<SetupUsage />} />
    </Routes>
  );
}
