import { wait } from '@idot-digital/generic-helpers';

type CodeResult<
  Result extends { code: number; data: any },
  Code extends number = 200
> = Extract<Result, { code: Code }>['data'];

export async function retry<T, Args extends any[]>(
  fct: (...args: Args) => Promise<T>,
  options?: { tries?: number },
  ...args: Args
): Promise<T> {
  const tries = options?.tries ?? 3;
  for (let i = 1; i < tries; i++) {
    try {
      if (i > 1) {
        await wait(500);
      }
      return await fct(...args);
    } catch (e) {
      if (i === tries - 1) throw e;
    }
  }
  throw new Error('This should never happen (FetchRetry.ts:retry)');
}

export async function apiRetry<
  T extends {
    code: number;
    data: any;
  },
  Args extends any[] = any[],
  AcceptCodes extends number = 200
>(
  logger: { error: (message: string, ...args: any[]) => Promise<void> | void },
  fct: (...args: Args) => Promise<T>,
  options?: {
    tries?: number;
    acceptCodes?: AcceptCodes[];
    rejectCodes?: number[];
  },
  ...args: Args
): Promise<CodeResult<T, AcceptCodes | 200>> {
  const res = await retry<CodeResult<T, AcceptCodes | 200>, Args>(
    async (...args: Args) => {
      const res = await fct(...args);
      if (options?.acceptCodes) {
        if ((options.acceptCodes as number[]).includes(Number(res.code)))
          return res.data;
      } else if (res.code === 200) return res.data;

      if (options?.rejectCodes) {
        if (options.rejectCodes.includes(Number(res.code))) {
          logger.error(
            `Rejected code ${res.code.toString()} calling "${fct.name}": ${
              res.data
            }`,
            args
          );
          // on code that is explicitly rejected, return error to bypass catch and throw error without retrying
          return new Error(`Rejected code ${res.code.toString()}: ${res.data}`);
        }
      } else if (res.code !== 200) {
        logger.error(
          `Rejected code ${res.code.toString()} calling "${fct.name}": ${
            res.data
          }`,
          args
        );
        throw new Error(`Rejected code ${res.code.toString()}: ${res.data}`);
      }

      return res.data;
    },
    options,
    ...args
  );

  if ((res as any) instanceof Error) throw res;
  return res;
}
