import { TaskBuilder, TaskOptionsConfig } from 'focus-mode-scheduler';
import ExpandNetworkTask from './ExpandNetworkTask';
import { language } from '@/index';
import { NextTask } from 'focus-mode-scheduler/src/Task/TaskBuilder';
import ContactActions from '@/data/DataServer/Contact';
import { ContactType } from '@common/types/enums';
import { Contact } from '@/data/Classes/Contact';
import PrintableError from '@common/PrintableError/PrintableError';
import Auth from '@common/AuthManager/Auth.renderer';
import { contacts } from '@digital-sun-solutions/cloud-functions';
import { BindProps } from 'ui-utils';
import ExpandNetworkTaskUI from './ExpandNetworkTaskUI';

export default class ExpandNetworkTaskBuilder extends TaskBuilder<ExpandNetworkTask> {
  protected task = ExpandNetworkTask;

  protected audienceHolders: Contact[] | null = null;
  protected async getAudienceHolders() {
    if (this.audienceHolders) return this.audienceHolders;

    this.audienceHolders = await ContactActions.listAllContacts({
      values: {
        type: ContactType.AUDIENCE_HOLDER
      }
    });

    return this.audienceHolders;
  }

  protected async getExistingContacts() {
    const res = await Auth.execRoute((token) =>
      contacts.marked.get({}, { token })
    );
    if (res.code !== 200)
      throw new PrintableError(`Could not get marked contacts: ${res.data}`);

    return res.data;
  }

  async getTask(): Promise<NextTask<ExpandNetworkTask>> {
    const audienceHolders = await this.getAudienceHolders();
    const existingContacts = await this.getExistingContacts();

    const task = new ExpandNetworkTask(audienceHolders, existingContacts);
    return {
      task,
      UI: BindProps(ExpandNetworkTaskUI, { task })
    };
  }

  public async getOptionsConfig(): Promise<TaskOptionsConfig | null> {
    return {
      items: {
        defaultValue: 40,
        min: 1,
        max: 100,
        scale: 'logarithmic',
        step: 1
      },
      time: {
        defaultValue: 10,
        max: 60,
        min: 2,
        scale: 'logarithmic',
        step: 1
      },
      defaultValue: {
        type: 'items',
        items: 20
      },
      texts: {
        taskName: language.text.expand_network
      }
    };
  }
}
