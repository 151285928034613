import { Box } from '@mui/material';
import { useSetup } from '../../SetupContext';
import { language } from '@/index';
import { FancyButton } from 'ui-utils';

export default function ShowWebsite() {
  const { currentStep, websiteFinished } = useSetup();

  if (!currentStep || currentStep.handler !== 'url') return null;

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: '1fr auto',
        height: '100%',
        width: '100%'
      }}>
      <Box>
        <webview
          src={currentStep.url}
          style={{ height: '100%', width: '100%' }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        p={2}
        sx={{
          background: 'background.default'
        }}>
        <FancyButton
          fType={{ promise: true }}
          variant="contained"
          onClick={async () => {
            await websiteFinished();
          }}>
          {language.text.continue}
        </FancyButton>
      </Box>
    </Box>
  );
}
