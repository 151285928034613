import { auth } from '@digital-sun-solutions/cloud-functions';
import Auth from '@common/AuthManager/Auth.renderer';
import PrintableError from '@common/PrintableError/PrintableError';
import { createQueryHook } from './QueryHelper';

async function getUserData() {
  if (!Auth.sscLoggedIn) return null;
  const res = await Auth.execRoute((token) => auth.getUserData({}, { token }));

  if (res.code === 500)
    throw new PrintableError('Server error while getting user data');
  return res.data;
}
getUserData.getQueryKey = () => ['account', 'user-data'];

const useUserData = createQueryHook(getUserData, 'user');

const AccountActions = {
  getUserData,
  useUserData
};

export default AccountActions;
