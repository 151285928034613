import FocusModeFrame from './FocusModeFrame';
import {
  FocusModeSchedulerConfig,
  FocusModeScheduler
} from 'focus-mode-scheduler';
import { useNavigate } from 'react-router-dom';
import { APP_PATHS } from '@/globals/Paths';
import { language } from '@/index';
import Start from './StartTask/Start';

export default function FocusMode() {
  FocusModeSchedulerConfig.setLanguageManager(language);
  FocusModeSchedulerConfig.setReturnPath(`/${APP_PATHS.Manual}`);
  const navigate = useNavigate();

  return (
    <FocusModeScheduler
      frame={FocusModeFrame}
      basePath={'/' + APP_PATHS.Focus}
      onQuit={() => navigate(`/${APP_PATHS.Manual}`)}
      onError={(error) =>
        navigate(`/${APP_PATHS.Error}/${encodeURIComponent(error)}`)
      }
      startUI={Start}
    />
  );
}
